import React, { useState } from "react";
import { Link } from "react-router-dom";
import Previousmodal from "./Previousmodal";
import { MultiSelect } from "react-multi-select-component";

function Taxdetails({
  setTab,
  addInputFieldtax,
  removeInputFieldstax,
  handleChangetax,
  selectedcurrency,
  handleChangetoggtax,
  taxnext,
  formerror8,
  tax,
  setTax,
  hoteldetails,
  taxupdatestatus,
  tax_info_status,
  taxprevtab,
  taxprev,
  setTaxprev,
  Prvfunction,
  closetab,
  roomtype,
  roomtypelist,
  setRoomtypelist,
  handleChangeesroomtypetax,
  taxchecke,
  setTaxChecked,
  setTaxupdatestatus,
  taxbutton,
  loaderspin,
  handleChangetaxtogg,
}) {
  if (Array.isArray(tax) && tax.length === 0) {
    setTax([
      {
        amount_type: "percentage",
        increment_type: "",
        room_type: [],
        service_charge_status: false,
        service_charge_applicable: false,
      },
    ]);
  }

  // if(hoteldetails.tax_include_status===true || taxchecke ==='yes'){
  //   setTaxChecked('yes')
  // }
  // else if (hoteldetails.tax_include_status===false || taxchecke==='no'){
  //   setTaxChecked('no')

  // }
  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [symbolsrate] = useState(["e", "E", "+", "-"]);

  console.log(hoteldetails);
  console.log(tax);
  return (
    <div id="TaxDetails" className="tabcontent">
      <div className="card-body">
        <div className="live-preview">
          <div className="col-md-12 be-form-tax-title">
            <label
              for="firstNameinput"
              className="form-label be-form-tax-title-status be_form_label"
            >
              {/* Do you want to display Tax Included Rate on your Booking Engine{" "} */}
              Display rate on the first page of your booking engine
              <span className="form-validation-required">*</span>
            </label>

            <div className="switch-field">
              <input
                type="radio"
                id="radio-one"
                name="tax_include_status"
                value={true}
                checked={hoteldetails.tax_include_status === true}
                onChange={handleChangetoggtax}
              />
              <label htmlFor="radio-one">Tax Included</label>

              <input
                type="radio"
                id="radio-two"
                name="tax_include_status"
                value={false}
                checked={hoteldetails.tax_include_status === false}
                onChange={handleChangetoggtax}
              />
              <label htmlFor="radio-two">Tax Excluded</label>
            </div>
          </div>
          <div className="col-md-12"></div>
          <div className="be_form_tax_inclue_details">
            <div className="addmore_tax_details new_tax_options">
              {tax.map((data, index) => {
                console.log(data.service_charge_status);
                return (
                  <div key={index} className="row">
                    <span className="room_info_count mb-1">
                      <span className="room_sect_no be_form_roomsec_1">
                        {index + 1}
                      </span>
                    </span>
                    <span className="col-md-4 vat-siz">
                      <label
                        for="firstNameinput"
                        className="form-label be_form_label"
                      >
                        Tax Name{" "}
                        <span className="form-validation-required">*</span>
                        <div class="info-tag-new">i</div>
                        <span className="tooltiptext-new">eg: Vat 5%</span>
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        onChange={(e) => handleChangetax(index, e)}
                        value={data.title}
                        name="title"
                      />

                      {formerror8.map((err, i) => {
                        return (
                          <>
                            {index === i && err && err.tax_name ? (
                              <label style={{ color: "red" }}>
                                {err.tax_name}
                              </label>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </span>

                    <span className="col-md-3">
                      <label
                        for="firstNameinput"
                        className="form-label be_form_label"
                      >
                        Rate
                        <span className="form-validation-required">*</span>
                        <div class="info-tag-new">i</div>
                        <span className="tooltiptext-new">
                          eg: rate 5% or 100 AED
                        </span>
                      </label>

                      <input
                        type="number"
                        onKeyDown={(e) =>
                          symbolsrate.includes(e.key) && e.preventDefault()
                        }
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                        className="form-control"
                        placeholder=""
                        id="emailidInput"
                        onChange={(e) => handleChangetax(index, e)}
                        value={data.value}
                        name="value"
                        min={0}
                      />

                      {formerror8.map((err, i) => {
                        return (
                          <>
                            {index === i && err && err.tax_dis ? (
                              <label style={{ color: "red" }}>
                                {err.tax_dis}
                              </label>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                      <span className="be_form_tax_info_label_10 tax_discount_type_section">
                        <select
                          id="ForminputState"
                          className="form-select"
                          onChange={(e) => handleChangetax(index, e)}
                          value={data.amount_type}
                          name="amount_type"
                        >
                          <option value="percentage" selected>
                            %
                          </option>
                          <option value="currency">
                            {selectedcurrency.code}
                          </option>
                        </select>
                      </span>
                    </span>
                    <span className="col-md-2 adj-siz">
                      <label
                        for="firstNameinput"
                        className="form-label be_form_label"
                      >
                        Applicable to
                        <span className="form-validation-required">*</span>
                        <div class="info-tag-new">i</div>
                        <span className="tooltiptext-new">
                          eg: 5% discount per person or 5% discount per room
                        </span>
                      </label>
                      <select
                        id="ForminputState"
                        className="form-select"
                        onChange={(e) => handleChangetax(index, e)}
                        value={data.increment_type}
                        name="increment_type"
                      >
                        <option value="">Choose</option>
                        <option value="per_person">Per Person</option>
                        <option value="per_room">Per Room</option>
                      </select>

                      {formerror8.map((err, i) => {
                        return (
                          <>
                            {index === i && err && err.increment_type ? (
                              <label style={{ color: "red" }}>
                                {err.increment_type}
                              </label>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </span>
                    <span className="col-md-3">
                      <label
                        for="firstNameinput"
                        className="form-label be_form_label"
                      >
                        Room type
                        <span className="form-validation-required">*</span>
                        <div class="info-tag-new">i</div>
                        <span className="tooltiptext-new">
                          This tax is applied based on the room type selected
                        </span>
                      </label>
                      <MultiSelect
                        options={roomtype}
                        value={data.room_type}
                        onChange={(e) =>
                          handleChangeesroomtypetax(index, e, "room_type")
                        }
                        labelledBy="Select"
                        selectionType="counter"
                        closeOnChangedValue={false}
                        aria-expanded="true"
                      />
                      {formerror8.map((err, i) => {
                        return (
                          <>
                            {index === i && err && err.roomtype ? (
                              <label style={{ color: "red" }}>
                                {err.roomtype}
                              </label>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </span>

                    <div className="" style={{ marginTop: "20px" }}></div>
                    <span className="col-md-2">
                      <div className="mb-4">
                        <label
                          for="firstNameinput"
                          className="form-label be_form_label"
                        >
                          Frequency
                          <span className="form-validation-required">*</span>
                          {/* <span className="info-tag">
                                          i
                                          <span className="tooltiptext">
                                            eg: 5% discount per person or 5%
                                            discount per room
                                          </span>
                                        </span> */}
                        </label>
                        <select
                          id="ForminputState"
                          className="form-select"
                          onChange={(e) => handleChangetax(index, e)}
                          value={data.increment_frequency}
                          name="increment_frequency"
                        >
                          <option>Choose</option>
                          <option value="once">Once</option>
                          <option value="per_night">Per Night</option>
                        </select>
                        {formerror8.map((err, i) => {
                          return (
                            <>
                              {index === i && err && err.increment_frequency ? (
                                <label style={{ color: "red" }}>
                                  {err.increment_frequency}
                                </label>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      </div>
                    </span>
                    <span className="col-md-2" style={{ left: "3%" }}>
                      <div className="mb-4">
                        <div>
                          <label
                            for="firstNameinput"
                            className="form-label be_form_label"
                          >
                            Service Charge
                            {/* <span className="form-validation-required">
                                            *
                                          </span> */}
                          </label>
                        </div>
                        <div
                          className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                          dir="ltr"
                        >
                          <input
                            type="checkbox"
                            className="form-check-input offer_checkbox1 check-box-green checkbox1_wid"
                            id="inlineswitch5"
                            name="service_charge_status"
                            value={data.service_charge_status}
                            checked={
                              data.service_charge_status === true ? true : false
                            }
                            onChange={(e) => handleChangetaxtogg(index, e)}
                            style={{
                              width: "50px",
                              height: "25px",
                            }}
                          />
                        </div>
                      </div>
                    </span>
                    <span className="col-md-3">
                      <div className="mb-4">
                        <label
                          for="firstNameinput"
                          className="form-label be_form_label"
                        >
                          Service Charge Applicable
                          {/* <span className="form-validation-required">
                                            *
                                          </span> */}
                        </label>

                        <span
                          className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                          dir="ltr"
                        >
                          <input
                            type="checkbox"
                            className="form-check-input offer_checkbox1 check-box-green checkbox1_wid"
                            id="inlineswitch5"
                            name="service_charge_applicable"
                            value={data.service_charge_applicable}
                            checked={
                              data.service_charge_applicable === true
                                ? true
                                : false
                            }
                            onChange={(e) => handleChangetaxtogg(index, e)}
                            style={{
                              width: "50px",
                              height: "25px",
                            }}
                          />
                        </span>
                      </div>
                    </span>
                    <div className="row mt-1">
                      <span className="col-md-11 ">
                        <label
                          for="firstNameinput"
                          className="form-label be_form_label "
                        >
                          Tax Description
                          <span className="form-validation-required">*</span>
                          <div class="info-tag-new">i</div>
                          <span className="tooltiptext-new">
                            eg: vat 5% must be apply to the room rate + 10%
                            service charges
                          </span>
                        </label>

                        <input
                          type="text"
                          className="form-control tax_des"
                          placeholder=""
                          id="emailidInput"
                          onChange={(e) => handleChangetax(index, e)}
                          value={data.message}
                          name="message"
                        />

                        {formerror8.map((err, i) => {
                          return (
                            <>
                              {index === i && err && err.tax_comment ? (
                                <label style={{ color: "red" }}>
                                  {err.tax_comment}
                                </label>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      </span>
                      <span className="col-md-1">
                        <Link
                          className={
                            index === 0
                              ? "remove_more d-none"
                              : "remove_more remove_more_tax"
                          }
                          to="#"
                          onClick={(e) => removeInputFieldstax(index)}
                        >
                          X
                        </Link>
                      </span>
                    </div>
                    <div
                      className={
                        index === tax.length - 1 ? "boder1 d-none" : "boder1"
                      }
                    ></div>
                  </div>
                );
              })}
            </div>
            <div className="taxappendmore"></div>

            <div className="be-form-tax_add-anthor-section">
              <Link
                className="taxinfo_add_more_button"
                id="taxinfo_add_more_button"
                data-count="1"
                to="#"
                onClick={addInputFieldtax}
              >
                Add Another
              </Link>
              <div className="add-more-bottom-space"></div>
            </div>
          </div>
          <div className="tab_nav_section">
            <div className="tab_nav_section_btns_form">
              <button
                className="btn btn-be-form-cancel btn-be-back"
                // onClick={(e) => setTab("Freequency")}
                onClick={taxprevtab}
              >
                Prev
              </button>
              {taxupdatestatus === true && taxbutton === true ? (
                <button
                  className="btn btn-success btn-be-common"
                  // onClick={(e) => setTab("Tax")}
                  onClick={taxnext}
                  disabled
                >
                  Save & Next
                </button>
              ) : taxupdatestatus === true && taxbutton === false ? (
                <button
                  className="btn btn-success btn-be-common"
                  // onClick={(e) => setTab("Tax")}
                  onClick={taxnext}
                >
                  Save & Next
                </button>
              ) : taxupdatestatus === false && tax_info_status === true ? (
                <button
                  className="btn btn-success btn-be-common"
                  onClick={(e) => setTab("Loyalty")}
                >
                  Next
                </button>
              ) : taxbutton === true ? (
                <button
                  className="btn btn-success btn-be-common"
                  // onClick={() => setTab("Room-info")}
                  onClick={taxnext}
                  disabled
                >
                  Save & Next
                </button>
              ) : (
                <button
                  className="btn btn-success btn-be-common"
                  // onClick={() => setTab("Room-info")}
                  onClick={taxnext}
                >
                  Save & Next
                </button>
              )}
              {/* <button
                className="btn btn-success btn-be-common"

                // onClick={(e) => setTab("Loyalty")}
                onClick={taxnext}

              >
                Next
              </button> */}
            </div>
          </div>
          {taxprev === true ? (
            <Previousmodal
              message="Tax"
              roomprev={taxprev}
              setRoomprev={setTaxprev}
              tag="tax"
              Prvfunction={Prvfunction}
              closetab={closetab}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Taxdetails;
