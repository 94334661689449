import React, { useContext, useEffect, useRef, useState } from "react";

import Commonheader from "../../Common/Commonheader";
import Footer from "../../Common/Footer";
import Extrastep1 from "./Extrastep1";
import Extrastep2 from "./Extrastep2";
import Extrastep3 from "./Extrastep3";
import Extrastep4 from "./Extrastep4";
import Extrastep5 from "./Extrastep5";
import Config from "../../Config";
import Cookies from "js-cookie";
import axios from "axios";
import moment from "moment";
import Alerts from "../../Alerts";

import { Link, useNavigate, useParams } from "react-router-dom";
import Toastmodal from "../../Common/Toastmodal";
import { useAuthHandling } from "../../Common/useAuthHandling";
import { UserContext } from "../../Contexts/UserContext";

function Extracreate() {
  let navigate = useNavigate();
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const [tab, setTab] = useState("step1");
  const [extra1Valid, setExtra1Valid] = useState(false);
  const [extra2valid, setExtra2valid] = useState(false);
  const [extra3valid, setExtra3valid] = useState(false);

  let params = useParams();

  const [id, setId] = useState(params.hotel_id);

  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [sub_addon_status, setSubaddon_status] = useState(false);
  const [newboard, setNewboard] = useState([]);
  const [newLang, setNewLang] = useState("");
  const [extraStep1, setExtraStep1] = useState({
    extraCheck: true,
    provider: "hotel",
    notify_email: "",
    availabilty_status: false,
    availabilty_type: "all_between",
  });
  const [extraName, setExtraName] = useState("");
  const [subAddonName, setSubAddonName] = useState("");
  const [extra1Error, setExtra1Error] = useState({});
  const [formerror, setError] = useState([{}]);
  const [selectedDatesstay, setSelectedDatesstay] = useState([]);
  const fpstay = useRef(null);
  const [activestaydateopen, setActivestaydateopen] = useState(false);
  const [openclosestay, setOpenclosestay] = useState("open");
  const [datefromstay, setDatefromstay] = useState("");
  const [datetostay, setDatetostay] = useState("");
  const [selectedWeekdaysstay, setSelectedWeekdaysstay] = useState([]);
  const [clickedDaysstay, setClickedDaysstay] = useState([]);
  const [language, setLanguage] = useState([]);
  const [selectedMulti, setselectedMulti] = useState([]);
  const [multilang, setMultilang] = useState([]);
  const [offerstatus, setOfferstatus] = useState({ only_offer: false });

  const [extra2Room, setExtra2Room] = useState([]);
  const [extra2Board, setExtra2Board] = useState([]);
  const [extra2Ratelist, setExtra2Ratelist] = useState([]);
  const [extra2error, setExtra2error] = useState({});
  const [extra3error, setExtra3error] = useState([]);
  const [activeroom, setActiveroom] = useState(false);
  const [activeboard, setActiveboard] = useState(false);
  const [activerate, setActiverate] = useState(false);
  const [selectedroomocc, setSelectedroomocc] = useState([]);
  const [optionimage, setOptionimage] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState([]);
  const [selectedRate, setSelectedRate] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [offers, setOffers] = useState([]);
  const [extra_include_status, setExtra_include_status] = useState(false);
  const [loyalty, setLoyalty] = useState([]);
  const [otherloyal_status, setOtherloyal_status] = useState(false);
  const [otherloyal, setOtherloyal] = useState([
    {
      loyalty_name: "",
      lang: [],
    },
  ]);
  const [alllanguage, setAlllanguage] = useState([]);
  const [loyallang, setLoyallang] = useState("");

  const [extraStep3, setExtraStep3] = useState({
    price_type: "free",
    extra_price: "",
    extra3Check: false,
    price_age_category: "person",
    client_preference_status: false,
    price: "",
  });
  const [numberOfGuests, setNumberOfGuests] = useState([
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
  ]);
  const [numberOfguestsub, setNumberOfGuestsub] = useState([
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
  ]);
  const [currency, setCurrency] = useState([]);
  const [availableboard, setAvailbleboard] = useState([]);
  const [imagechoose, setImagechoose] = useState("");

  const [extraStep4, setExtraStep4] = useState({
    min_stay_status: false,
    max_stay_status: false,
    days_in_advance_status: false,
    loyalty_club: false,
    min_stay_days: "1",
    max_stay_days: "1",
    days_in_advance: "",
  });

  const [Loyaltyclublist, setLoyaltyclublist] = useState([]);

  const [extraStep5, setExtraStep5] = useState({
    subtitle: "",
    description: "",
    extra_condition: "",
  });
  const [image, setImage] = useState("");

  //step1
  const handleExtraSubaddonToggle = (isChecked, name) => {
    setSubaddon_status({ ...sub_addon_status, [name]: isChecked });
  };
  const handleExtraToggle = (isChecked, name) => {
    setExtraStep1({ ...extraStep1, [name]: isChecked });
  };
  const handleExtraRadio = (isChecked, name) => {
    setExtraStep1({ ...extraStep1, [name]: isChecked });
  };
  const handleOpenclosestay = (e) => {
    setActivestaydateopen(false);
  };
  const handlechangeExtra1 = (e) => {
    setExtraStep1({ ...extraStep1, [e.target.name]: e.target.value });
  };
  const handleApplyToCalendarstay = () => {
    if (openclosestay === "open") {
      if (fpstay.current) {
        fpstay.current.flatpickr.clear();
      }
      if (datefromstay && datetostay && selectedWeekdaysstay.length > 0) {
        const dates = [];
        let currentDate = new Date(datefromstay);

        while (currentDate <= datetostay) {
          const dayOfWeek = currentDate.getDay();
          if (!clickedDaysstay.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatesstay(dates);
      }
    } else {
      if (fpstay.current) {
        fpstay.current.flatpickr.clear();
      }

      if (
        datefromstay &&
        datetostay &&
        selectedWeekdaysstay.length > 0 &&
        clickedDaysstay.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromstay);

        while (currentDate <= datetostay) {
          const dayOfWeek = currentDate.getDay();
          if (clickedDaysstay.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
        setSelectedDatesstay(dates);
      }
    }
  };

  const toggleSelectedDaystay = (dayIndex) => {
    if (selectedWeekdaysstay.includes(dayIndex)) {
      if (clickedDaysstay.includes(dayIndex)) {
        setClickedDaysstay((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayIndex)
        );
      } else {
        setClickedDaysstay((prevClickedDays) => [...prevClickedDays, dayIndex]);
      }
    }
  };

  const handleCalendarChangeFrom = (selectedDates) => {
    setDatefromstay(selectedDates[0]);
  };

  const handleCalendarChangeTo = (selectedDates) => {
    setDatetostay(selectedDates[0]);
  };

  //   const handleLanguageChange = (selectedOption) => {
  //     setselectedMulti((prevLanguages) => {
  //       const languageIndex = prevLanguages.findIndex((language) => language.value === selectedOption.value);
  //       console.log(prevLanguages);
  // console.log(selectedOption);
  //       if (languageIndex !== -1) {
  //         // If the language is already in selectedMulti, keep the existing values
  //         return prevLanguages;
  //       } else {
  //         // If the language is not in selectedMulti, add it with the default or existing addon value
  //         return [...prevLanguages, { ...selectedOption, addon: selectedOption.addon || '' }];
  //       }
  //     });
  //   };

  const handleLanguageChange = (selectedOption) => {
    console.log(selectedOption);
    setNewLang(selectedOption);
    const updatedLanguages = [...selectedMulti];

    if (!updatedLanguages.some((item) => item.lang_code === selectedOption)) {
      updatedLanguages.push({ lang_code: selectedOption, addon: "" });
    }
    setselectedMulti(updatedLanguages);
  };

  // const handleAddonChange = (index, addonValue) => {
  //   setselectedMulti((prevLanguages) => {
  //     const updatedLanguages = [...prevLanguages];
  //     updatedLanguages[index].addon = addonValue;
  //     return updatedLanguages;
  //   });
  // };
  const handleAddonChange = (addonValue) => {
    const updatedList = [...selectedMulti];
    const matchingIndex = updatedList.findIndex(
      (language) => language.lang_code === newLang
    );
    console.log(matchingIndex);
    if (matchingIndex !== -1) {
      updatedList[matchingIndex]["addon"] = addonValue;
    } else {
      const newLanguage = { lang_code: newLang };
      newLanguage["addon"] = addonValue;
      updatedList.push(newLanguage);
    }
    setselectedMulti(updatedList);
  };

  const handleLanguageChangesub = (index, selectedOption) => {
    const updatedLanguages = [...inputFields];

    updatedLanguages[index]["newlangsub"] = selectedOption;
    if (!updatedLanguages.some((item) => item.lang_code === selectedOption)) {
      updatedLanguages[index].multi_lang.push({
        lang_code: selectedOption,
        subaddon: "",
      });
    }
    setInputFields(updatedLanguages);
  };

  const handleAddonChangesub = (index, addonValue) => {
    const updatedList = [...inputFields];
    const matchingIndex = updatedList[index].multi_lang.findIndex(
      (language) => language.lang_code === inputFields[index].newlangsub
    );
    console.log(matchingIndex);
    if (matchingIndex !== -1) {
      updatedList[index]["multi_lang"][matchingIndex]["subaddon"] = addonValue;
    } else {
      const newLanguage = { lang_code: inputFields[index].newlangsub };
      newLanguage["subaddon"] = addonValue;
      updatedList[index]["multi_lang"].push(newLanguage);
    }
    console.log(updatedList);

    setInputFields(updatedList);
  };

  const handlelanglo = (val) => {
    setLoyallang(val);
    const updatedList = [...otherloyal];
    for (let i = 0; i < updatedList.length; i++) {
      if (!updatedList[i].lang.some((item) => item.lang_code === val)) {
        updatedList[i].lang.push({ lang_code: val, loyalty_lang_name: "" });
      }
    }
    setOtherloyal(updatedList);
  };

  const hangleotherloyallang = (index, val, name) => {
    console.log(name);
    console.log(val);
    const updatedList = [...otherloyal];
    //   const matchingIndex = updatedList[index].findIndex((item) =>
    //   item.lang.some((language) => language.lang_code === loyallang)
    // );
    const matchingIndex = updatedList[index].lang.findIndex(
      (language) => language.lang_code === loyallang
    );
    console.log(matchingIndex);
    if (matchingIndex !== -1) {
      updatedList[index]["lang"][matchingIndex][name] = val;
    } else {
      const newLanguage = { lang_code: loyallang };
      newLanguage[name] = val;
      updatedList[index].lang.push(newLanguage);
    }
    setOtherloyal(updatedList);
  };

  const [tab2, setTab2] = useState("addon");

  const nextExtrastep1 = () => {
    let isFormValid = true;
    let isFormValid1 = true;
    let isFormValid2 = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-,/,_,%,@]+$/;

    const errors = {};
    if (!extraName) {
      isFormValid = false;
      errors.extraName = "Add-on name is required!";
    }
    else if (!noSpecialCharRegex.test(extraName)) {
      isFormValid = false;
      errors.extraName = "Add-on name should not contain this special character";
    }
    else if (extraName.length > 100) {
      isFormValid = false;
      errors.extraName = `Add-on name cannot be more than 100 characters!`;
    }
    if (extraStep4.days_in_advance_status === true) {
      if (!extraStep4.days_in_advance) {
        isFormValid1 = false;

        errors.days_in_advance = "This field is required!";
      }
    }
    // if(extraStep1.provider==='external'){
    //    if (!extraStep1.notify_email) {
    //     isFormValid1 = false;
    //       errors.notify_email = "Email is required!";
    //    }
    // }
    if (extraStep1.provider === "external") {
      if (!extraStep1.notify_email) {
        isFormValid1 = false;

        errors.notify_email = "Email is required!";
      } else if (!regex.test(extraStep1.notify_email)) {
        isFormValid1 = false;

        errors.notify_email = "This is not a valid email format!";
      }
    }
    if (
      extraStep3.price_type === "free" ||
      extraStep3.price_type === "person_range_once"
    ) {
      isFormValid2 = true;
    } else {
      if (!extraStep3.extra_price) {
        isFormValid2 = false;
        errors.extra_price = "Price field is required!";
      }
    }
    setExtra1Error(errors);
    if (
      isFormValid === true &&
      isFormValid1 === true &&
      isFormValid2 === true
    ) {
      setTab2("subaddon");
      setExtra1Valid(true);
    }
  };

  const nextExtrastep1sub = () => {
    let isFormValid = true;
    let isFormValid1 = true;
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-,/,_,%,@]+$/;

    const errors = {};
    let arr = [];
    if (sub_addon_status.sub_addon_status === true) {
      for (let i = 0; i < inputFields.length; i++) {
        const error = {};
        if (!inputFields[i].subaddons) {
          isFormValid = false;
          error.subaddons = "Sub-addon is required!";
        }
        else if (!noSpecialCharRegex.test(inputFields[i].subaddons)) {
          isFormValid = false;
          error.subaddons = "Sub-addon should not contain this special character";
        }
        else if (inputFields[i].subaddons.length > 100) {
          isFormValid = false;
          error.subaddons = `Sub-addon cannot be more than 100 characters!`;
        }
        // if(inputFields[i].price_type !=="free" || inputFields[i].price_type !=="person_range_once"){
        //     if(!inputFields[i].extra_price){
        //       isFormValid1=false;
        //       error.extra_price = "Price field is required!";
        //     }
        // }
        if (
          inputFields[i].price_type === "free" ||
          inputFields[i].price_type === "person_range_once"
        ) {
          isFormValid1 = true;
        } else {
          if (!inputFields[i].extra_price) {
            isFormValid1 = false;
            error.extra_price = "Price field is required!";
          }
        }
        arr.push(error);
      }
    }
    setError(arr);
    setExtra1Error(errors);
    if (isFormValid === true && isFormValid1 === true) {
      setTab2("inclusion");
      setExtra1Valid(true);
    }
  };

  const nextExtrastep1inc = () => {
    if (extra_include_status === false) {
      setTab("step2");
      setExtra1Valid(true);
    } else {
      let isFormValid = true;
      const errors = {};

      const hasSelectedFalse = loyalty.some((item) => item.selected === true);
      if (!hasSelectedFalse && otherloyal_status === false) {
        isFormValid = false;
        errors.loyalty = "Please choose one field";
      }
      if (otherloyal_status === true) {
        const hasSelectedFalse = otherloyal.some(
          (item) => item.loyalty_name !== ""
        );
        if (!hasSelectedFalse) {
          isFormValid = false;
          errors.loyalty_name = "Please enter at least one name.";
        }
      }
      setExtra1Error(errors);
      if (isFormValid === true) {
        setTab("step2");
        setExtra2valid(true);
      }
    }
  };
  // const nextExtrastep1 = () => {
  //   let isFormValid = true;
  //   const errors = {};
  //   if (!extraName) {
  //     isFormValid = false;
  //   errors.extraName = "Extra name is required!";
  //   }
  //   if(extraStep1.provider==='external'){
  //     if (!extraStep1.notify_email) {
  //       isFormValid = false;
  //     errors.notify_email = "Email is required!";
  //     }
  //   }
  //   let arr=[];
  //   if(sub_addon_status.sub_addon_status===true){
  //   for(let i=0;i<inputFields.length;i++){
  //     const errors = {};
  //     if (!inputFields[i].subaddons) {
  //      isFormValid = false;
  //       errors.subaddons = "Sub-addon is required!";
  //     }
  //     if(inputFields[i].price_type !=="free"){
  //         if(!inputFields[i].extra_price){
  //           isFormValid=false;
  //           errors.extra_price = "Price field is required!";
  //         }
  //     }
  //     arr.push(errors);
  //     }
  //   }
  //     setError(arr);
  //   setExtra1Error(errors);
  //   if (
  //     isFormValid===true
  //   ) {
  //     setTab('step2');
  //     setExtra1Valid(true);
  //   }
  // };
  const [inputFields, setInputFields] = useState([
    {
      subaddons: "",
      multi_lang: [],
      newlangsub: "",
      price_type: "free",
      person_range_price: Array(12).fill({ price: "" }),
      extra_price: "",
      extra3Check: false,
      price_age_category: "person",
      client_preference_status: false,
    },
  ]);
  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        subaddons: "",
        multi_lang: [],
        person_range_price: Array(12).fill({ price: "" }),
        newlangsub: "",
        price_type: "free",
        extra_price: "",
        extra3Check: false,
        price_age_category: "person",
        client_preference_status: false,
      },
    ]);
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const removeInputFieldnew = (index) => {
    const rows = [...otherloyal];
    rows.splice(index, 1);
    setOtherloyal(rows);
  };
  let handleChangeSubaddon = (i, e) => {
    let newval = [...inputFields];
    inputFields[i]["subaddons"] = e.target.value;
    setInputFields(newval);
  };
  let handleChangeslang = (i, data, name) => {
    let newval = [...inputFields];
    inputFields[i][name] = data;
    setInputFields(newval);
  };

  let handleLang = (i, ind, e) => {
    let newval = [...inputFields];
    inputFields[i]["multi_lang"][ind][e.target.name] = e.target.value;
    setInputFields(newval);
  };

  // const handleOptionChangeExtrastep1 = (value, name) => {
  //   if (name === 'price_type') {
  //     setExtraStep1((prevState) => ({
  //       ...prevState,
  //       [name]: value,
  //       extra_price: '',
  //     }));
  //   } else {
  //     setExtraStep1((prevState) => ({
  //       ...prevState,
  //       [name]: value,
  //     }));
  //   }
  // };

  // const handlechangeStep1 =(e)=>{
  //   setExtraStep1({...extraStep1,[e.target.name]:e.target.value});
  // }
  // const handleExtra1Check =(isChecked,name)=>{
  //   setExtraStep1({...extraStep1,[name]:isChecked});
  // }

  const handleOptionChangeExtrastep1 = (index, name, value) => {
    console.log(inputFields);
    console.log(index);
    let newInputFields = [...inputFields];
    newInputFields[index][name] = value;
    setInputFields(newInputFields);
  };

  const handlechangeStep1 = (index, e) => {
    let newInputFields = [...inputFields];
    newInputFields[index][e.target.name] = e.target.value;
    setInputFields(newInputFields);
  };

  const handleExtra1Check = (index, name, isChecked) => {
    let newInputFields = [...inputFields];
    newInputFields[index][name] = isChecked;
    setInputFields(newInputFields);
  };
  // const handlePriceChangeStep1 = (index, event) => {
  //   const updatedGuests = [...numberOfguestsub];
  //   updatedGuests[index] = { price: event.target.value };
  //   setNumberOfGuestsub(updatedGuests);
  // };
  const handlePriceChangeStep1 = (outerIndex, innerIndex, event) => {
    setInputFields((prevInputFields) => {
      const updatedInputFields = [...prevInputFields];
      const price = event.target.value;

      // Ensure the outerIndex exists
      if (!updatedInputFields[outerIndex]) {
        updatedInputFields[outerIndex] = {};
      }

      // Ensure person_range_price array exists and make a deep copy
      updatedInputFields[outerIndex].person_range_price = updatedInputFields[
        outerIndex
      ].person_range_price
        ? [...updatedInputFields[outerIndex].person_range_price]
        : Array(12).fill({ price: "" });

      // Update the price property
      updatedInputFields[outerIndex].person_range_price[innerIndex] = {
        ...updatedInputFields[outerIndex].person_range_price[innerIndex],
        price: price,
      };

      return updatedInputFields;
    });
  };

  console.log(inputFields);

  const addInputFieldinclusion = () => {
    setOtherloyal([
      ...otherloyal,
      {
        loyalty_name: "",
        lang: [],
      },
    ]);
  };
  const removeInputFieldinclusion = (index) => {
    const rows = [...otherloyal];
    rows.splice(index, 1);
    setOtherloyal(rows);
  };

  const handleloyalchange = (isChecked, index) => {
    const updatedList = [...loyalty];
    updatedList[index]["selected"] = isChecked;
    setLoyalty(updatedList);
  };

  const hangleotherloyal = (index, val, name) => {
    const updatedList = [...otherloyal];
    updatedList[index][name] = val;
    setOtherloyal(updatedList);
  };

  //step2

  const getListall = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/extra/all`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setNewboard(data.data[0].hotel_board);
        setExtra2Room(data.data[0].rooms_occupancies);
        setExtra2Ratelist(data.data[0].rate_lists);
        setCurrency(data.data[0].currency);
        setLanguage(data.data[0].optional_language);
        setOptionimage(data.data[0].option_images);
        setOffers(data.data[0].offers);
        setLoyalty(data.data[0].inclusions);
        setLoyaltyclublist(data.data[0].loyalty_club);
        setAlllanguage(data.data[0].optional_language);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getListall();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getListall();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;
    // if (data1.status_code !== "200" || !data) {
    //   console.log("error");
    // }
    //  else {
    //   setNewboard(data[0].hotel_board);
    //   setExtra2Room(data[0].rooms_occupancies);
    //   setExtra2Ratelist(data[0].rate_lists);
    //   setCurrency(data[0].currency);
    //   setLanguage(data[0].optional_language);
    //   setOptionimage(data[0].option_images);
    //   setOffers(data[0].offers);
    //   setLoyalty(data[0].inclusions);
    //   setLoyaltyclublist(data[0].loyalty_club);
    //   setAlllanguage(data[0].optional_language);

    // }
  };

  const handlesingleroomselect = (i, isChecked) => {
    const updatedList = [...extra2Room];
    const updatedroomocc = [...selectedroomocc];
    updatedList[i]["selected"] = isChecked;
    const updatedboard = [...extra2Board];
    if (isChecked === true) {
      if (availableboard.length > 0) {
        const updatedAvailableBoard = [...availableboard];

        updatedList[i].display_boards.forEach((newItem) => {
          const newItemId = newItem;
          const existingItem = updatedAvailableBoard.find(
            (item) => item.id === newItemId
          );
          if (existingItem) {
            existingItem.count += existingItem.count;
          } else {
            updatedAvailableBoard.push({ id: newItemId, count: 1 });
          }

          const existingboard = updatedboard.find(
            (item) => item.value === newItemId
          );
          if (!existingboard) {
            for (let i = 0; i < newboard.length; i++) {
              if (newboard[i].value === newItemId) {
                updatedboard.push({
                  value: newItemId,
                  label: newboard[i].label,
                });
              }
            }
          }
          console.log(existingboard);
        });

        setAvailbleboard(updatedAvailableBoard);
        setExtra2Board(updatedboard);
      } else {
        const newAvailableBoard = updatedList[i].display_boards.map(
          (newItem) => ({
            id: newItem,
            count: 1,
          })
        );
        setAvailbleboard(newAvailableBoard);
      }
      updatedroomocc.push({
        key: updatedList[i].key,
        label: updatedList[i].label,
        value: updatedList[i].value,
      });
      setSelectedroomocc(updatedroomocc);

      updatedList[i].display_boards.forEach((newItem) => {
        const newItemId = newItem;
        const existingboard = updatedboard.find(
          (item) => item.value === newItemId
        );
        if (!existingboard) {
          for (let i = 0; i < newboard.length; i++) {
            if (newboard[i].value === newItemId) {
              updatedboard.push({ value: newItemId, label: newboard[i].label });
            }
          }
        }
        console.log(existingboard);
        setExtra2Board(updatedboard);
      });
    } else {
      updatedList[i]["based_on"] = "";
      updatedList[i]["all_label"] = false;
      if (availableboard.length > 0) {
        const updatedAvailableBoard = [...availableboard];
        console.log(updatedList[i]);
        updatedList[i]?.display_boards?.forEach((newItem) => {
          const newItemId = newItem;
          const existingItem = updatedAvailableBoard.find(
            (item) => item.id === newItemId
          );
          if (existingItem) {
            // Decrease the count when isChecked is false
            existingItem.count -= 1;

            // If count is zero or less, remove the item
            if (existingItem.count <= 0) {
              updatedAvailableBoard.splice(
                updatedAvailableBoard.indexOf(existingItem),
                1
              );
              const existingboard = updatedboard.find(
                (item) => item.value === newItemId
              );
              console.log(existingboard);
              updatedboard.splice(updatedboard.indexOf(existingboard), 1);
            }
            setExtra2Board(updatedboard);
          } else {
            console.error("Item not found with id:", newItemId);
          }
        });

        setAvailbleboard(updatedAvailableBoard);
      } else {
        const updatedAvailableBoard = [...availableboard];

        updatedList[i]?.display_board?.forEach((newItem) => {
          const newItemId = newItem;
          const existingIteml = updatedAvailableBoard.find(
            (item) => item.id === newItemId
          );
          console.log(existingIteml);
          if (existingIteml) {
            // Decrease the count when isChecked is false
            existingIteml.count -= 1;

            // If count is zero or less, remove the item
            if (existingIteml.count <= 0) {
              const existingboard = updatedboard.find(
                (item) => item.value === newItemId
              );
              console.log(existingboard);
              updatedboard.splice(updatedboard.indexOf(existingboard), 1);
            }
            setExtra2Board(updatedboard);
          } else {
            console.error("Item not found with id:", newItemId);
          }
        });
      }
      const updatedroomoccFiltered = updatedroomocc.filter((item) => {
        return item.key !== updatedList[i].key;
      });

      setSelectedroomocc(updatedroomoccFiltered);
    }
    updatedList[i].occupancies.forEach((occ) => {
      occ.selected = false;
    });

    setExtra2Room(updatedList);

    if (isChecked === false) {
      setActiveroom(isChecked);
    } else {
      const hasSelectedFalse = updatedList.some(
        (item) => item.selected === false || !("selected" in item)
      );
      if (!hasSelectedFalse) {
        setActiveroom(true);
      }
    }
  };
  // const handlesingleroomselect=(i,isChecked)=>{

  //   const updatedList = [...extra2Room];
  //   const updatedroomocc = [...selectedroomocc];
  //   updatedList[i]['selected'] = isChecked;
  //   if(isChecked===true){

  //       updatedroomocc.push({ key: updatedList[i].key, label: updatedList[i].label ,value:updatedList[i].value });
  //       setSelectedroomocc(updatedroomocc);
  //   }
  //   else{
  //     updatedList[i]['based_on'] = '';
  //     updatedList[i]['all_label'] = false;
  //       const updatedroomoccFiltered = updatedroomocc.filter(item => {
  //         return item.key !== updatedList[i].key;
  //       });

  //   setSelectedroomocc(updatedroomoccFiltered);
  //   }
  //   updatedList[i].occupancies.forEach(occ=>{
  //     occ.selected=false;
  //   })

  // setExtra2Room(updatedList);

  //  if(isChecked===false){
  //   setActiveroom(isChecked);

  //  }
  //  else{
  //   const hasSelectedFalse = updatedList.some((item) => item.selected === false || !('selected' in item));
  //   if (!hasSelectedFalse) {
  //     setActiveroom(true);
  //   }
  //  }
  // }
  const handlesingleoccupancyselect = (index, indx, isChecked) => {
    const updatedList = [...extra2Room];
    const updatedroomocc = [...selectedroomocc];
    if (isChecked === true) {
      //occupancy id remove to new list
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          if (!Array.isArray(updatedroomocc[i].occupancies)) {
            updatedroomocc[i].occupancies = [];
          }
          updatedroomocc[i].occupancies.push(
            updatedList[index].occupancies[indx]
          );
          break; // Exit the loop after finding and updating the matching room
        }
      }
      //room select after board select
      if (extra2Board.find((item) => item.selected === true)) {
        updatedList.forEach((newone) => {
          extra2Board.forEach((newItem) => {
            for (let j = 0; j < updatedroomocc.length; j++) {
              if (newone.value === updatedroomocc[j].value) {
                if (newone.display_boards.includes(newItem.value)) {
                  for (
                    let k = 0;
                    k < updatedroomocc[j].occupancies.length;
                    k++
                  ) {
                    if (!updatedroomocc[j].occupancies[k].boards) {
                      updatedroomocc[j].occupancies[k].boards = [];
                    }
                    updatedroomocc[j].occupancies[k].boards.push({
                      hotel_board: newItem.hotel_borad,
                      label: newItem.label,
                      value: newItem.value,
                    });
                  }
                }
              }
            }
          });
        });
      }
      setSelectedroomocc(updatedroomocc);
    } else {
      //occupancy id remove to new list
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          updatedroomocc[i].occupancies = updatedroomocc[i].occupancies.filter(
            (item) =>
              item.occupancy_id !==
              updatedList[index].occupancies[indx].occupancy_id
          );
          break;
        }
      }
      setSelectedroomocc(updatedroomocc);
    }
    updatedList[index]["occupancies"][indx]["selected"] = isChecked;

    if (isChecked === false) {
      updatedList[index]["all_label"] = isChecked;
    } else {
      const hasSelectedFalse = updatedList[index].occupancies.some(
        (item) => item.selected === false || !("selected" in item)
      );
      if (!hasSelectedFalse) {
        updatedList[index]["all_label"] = isChecked;
      }
    }
    setExtra2Room(updatedList);
  };

  const handleChangeroomExtra2 = (isChecked) => {
    setActiveroom(isChecked);
    const updatedList = [...extra2Room];
    const updatedAvailableBoard = [...availableboard];
    const updatedroomocc = [...selectedroomocc];
    const updatedboard = [...extra2Board];

    if (isChecked === true) {
      setActiveboard([]);

      updatedList.forEach((newone) => {
        newone.display_boards.forEach((newItem) => {
          const newItemId = newItem;
          const existingItem = updatedAvailableBoard.find(
            (item) => item.id === newItemId
          );
          if (existingItem) {
            existingItem.count += existingItem.count;
          } else {
            updatedAvailableBoard.push({ id: newItemId, count: 1 });
          }

          const existingboard = updatedboard.find(
            (item) => item.value === newItemId
          );
          if (!existingItem) {
            for (let i = 0; i < newboard.length; i++) {
              if (newboard[i].value === newItemId) {
                updatedboard.push({
                  value: newItemId,
                  label: newboard[i].label,
                });
              }
            }
          }
          console.log(existingboard);
          setExtra2Board(updatedboard);
        });

        updatedroomocc.push({
          key: newone.key,
          label: newone.label,
          value: newone.value,
        });
      });

      setAvailbleboard(updatedAvailableBoard);
      setSelectedroomocc(updatedroomocc);
    } else {
      setAvailbleboard([]);
      setSelectedroomocc([]);
      setExtra2Board([]);
    }
    updatedList.forEach((room) => {
      room.selected = isChecked;
    });
    setExtra2Room(updatedList);
  };

  const handlebasedon = (index, val) => {
    const updatedroomocc = [...selectedroomocc];

    const updatedList = [...extra2Room];
    updatedList[index]["based_on"] = val;
    setExtra2Room(updatedList);

    if (val === "per_room") {
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          updatedroomocc[i]["based_on"] = val;

          if (!Array.isArray(updatedroomocc[i].occupancies)) {
            updatedroomocc[i].occupancies = [];
          }
          for (let j = 0; j < updatedList[index].occupancies.length; j++) {
            updatedroomocc[i].occupancies.push(
              updatedList[index].occupancies[j]
            );
          }
        }
      }
      //room select after board select
      if (extra2Board.find((item) => item.selected === true)) {
        updatedList.forEach((newone) => {
          extra2Board.forEach((newItem) => {
            for (let j = 0; j < updatedroomocc.length; j++) {
              if (newone.value === updatedroomocc[j].value) {
                if (newone.display_board.includes(newItem.value)) {
                  for (
                    let k = 0;
                    k < updatedroomocc[j].occupancies.length;
                    k++
                  ) {
                    if (!updatedroomocc[j].occupancies[k].boards) {
                      updatedroomocc[j].occupancies[k].boards = [];
                      updatedroomocc[j].occupancies[k].boards.push({
                        hotel_board: newItem.hotel_borad,
                        label: newItem.label,
                        value: newItem.value,
                      });
                    } else {
                      if (
                        !updatedroomocc[j].occupancies[k].boards.some(
                          (item) => item.hotel_board === newItem.hotel_borad
                        )
                      ) {
                        updatedroomocc[j].occupancies[k].boards.push({
                          hotel_board: newItem.hotel_borad,
                          label: newItem.label,
                          value: newItem.value,
                        });
                      }
                    }
                  }
                }
              }
            }
          });
        });
      }
      setSelectedroomocc(updatedroomocc);
    } else {
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          updatedroomocc[i]["based_on"] = val;

          updatedroomocc[i].occupancies = [];
        }
      }
    }
  };

  const handleChangeroomextra2all_label = (index, isChecked) => {
    const updatedList = [...extra2Room];
    const updatedroomocc = [...selectedroomocc];

    updatedList[index]["all_label"] = isChecked;
    if (isChecked === true) {
      updatedList[index].occupancies.forEach((occ) => {
        occ.selected = true;
      });

      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          if (!Array.isArray(updatedroomocc[i].occupancies)) {
            updatedroomocc[i].occupancies = [];
          }
          for (let j = 0; j < updatedList[index].occupancies.length; j++) {
            updatedroomocc[i].occupancies.push(
              updatedList[index].occupancies[j]
            );
          }
        }
      }

      if (extra2Board.find((item) => item.selected === true)) {
        updatedList.forEach((newone) => {
          extra2Board.forEach((newItem) => {
            for (let j = 0; j < updatedroomocc.length; j++) {
              if (newone.value === updatedroomocc[j].value) {
                if (newone.display_boards.includes(newItem.value)) {
                  for (
                    let k = 0;
                    k < updatedroomocc[j].occupancies.length;
                    k++
                  ) {
                    if (!updatedroomocc[j].occupancies[k].boards) {
                      updatedroomocc[j].occupancies[k].boards = [];
                    }
                    updatedroomocc[j].occupancies[k].boards.push({
                      hotel_board: newItem.hotel_borad,
                      label: newItem.label,
                      value: newItem.value,
                    });
                  }
                }
              }
            }
          });
        });
      }
    } else {
      updatedList[index].occupancies.forEach((occ) => {
        occ.selected = false;
      });

      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          updatedroomocc[i].occupancies = [];
        }
      }
    }
    setExtra2Room(updatedList);
    setSelectedroomocc(updatedroomocc);
  };

  const nextofferstep2 = () => {
    let isFormValid = false;
    let isFormValid1 = true;
    let isFormValid2 = true;
    let isFormValid3 = true;

    const errors = {};

    const hasSelectedFalse = extra2Room.some((item) => item.selected === true);
    if (hasSelectedFalse) {
      isFormValid = true;
    }
    for (let i = 0; i < extra2Room.length; i++) {
      if (extra2Room[i].selected === true) {
        for (let j = 0; j < extra2Room[i].occupancies.length; j++) {
          if (extra2Room[i].based_on === "per_person") {
            if (extra2Room[i].occupancies[j].selected === true) {
              isFormValid = true;
            }
          } else if (extra2Room[i].based_on === "per_room") {
            isFormValid = true;
          } else {
            isFormValid = false;
          }
        }
      }
    }
    if (isFormValid === false) {
      errors.room = "Please select one room and occupancy!";
    }
    if (offerstatus.only_offer === false) {
      if (selectedBoard.length === 0) {
        isFormValid1 = false;
        errors.selectedBoard = "Please select any board!";
      }
    }
    if (offerstatus.only_offer === false) {
      if (selectedRate.length === 0) {
        isFormValid2 = false;
        errors.selectedRate = "Please select any rate!";
      }
    }
    if (offerstatus.only_offer === true) {
      if (selectedOffers.length === 0) {
        isFormValid3 = false;
        errors.selectedOffers = "Please select any Offer!";
      }
    }
    setExtra2error(errors);

    if (
      isFormValid === true &&
      isFormValid1 === true &&
      isFormValid2 === true &&
      isFormValid3 === true
    ) {
      setTab("step3");
      setExtra2valid(true);
    }
  };
  // const nextoffterstep2 = () => {
  //     let isFormValid = false;
  //     let isFormValid1 = false;
  //     let isFormValid2 = false;

  //     const errors = {};
  //     for(let i=0;i<extra2Room.length;i++){
  //       if(extra2Room[i].selected===true){
  //         for(let j=0;j<extra2Room[i].occupancies.length;j++){
  //           if(!extra2Room[i].based_on==='per_room'){
  //             if(extra2Room[i].occupancies[j].selected===true){
  //               isFormValid = true;

  //            }

  //           }
  //           else{
  //             isFormValid = true;
  //            }

  //         }
  //       }
  //   }
  //      if(isFormValid===false){
  //      errors.room = "Select any Room and Occupancy!";
  //     }

  //     for(let i=0;i<extra2Board.length;i++){
  //       if(extra2Board[i].selected===true){
  //              isFormValid1 = true;
  //         }
  //       }

  //       if(isFormValid1===false){
  //         errors.board = "Select any Board!";
  //         }

  //     for(let i=0;i<extra2Ratelist.length;i++){
  //       if(extra2Ratelist[i].selected===true){
  //              isFormValid2 = true;
  //         }
  //       }
  //       if(isFormValid2===false){
  //         errors.rate = "Select any Rate!";
  //         }
  //         setExtra2error(errors)
  //     if (
  //       isFormValid===true && isFormValid1===true && isFormValid2===true

  //     ) {
  //       setTab('step3');
  //       setExtra2valid(true);
  //     }
  //   };

  //step3
  console.log(selectedroomocc);

  let handleOfferstatusToggle = (e) => {
    let value1 = e.target.value;
    let name = e.target.name;

    if (value1 === "false") {
      const value = false;
      setOfferstatus({ ...offerstatus, [name]: value });
    } else if (value1 === "true") {
      const value = true;
      setOfferstatus({ ...offerstatus, [name]: value });
    }
  };

  const handleOptionChangeExtrastep3 = (val, name) => {
    setExtraStep3({ ...extraStep3, [name]: val });
  };

  const handlechangeStep3 = (e) => {
    setExtraStep3({ ...extraStep3, [e.target.name]: e.target.value });
  };
  const handleExtra3Check = (isChecked, name) => {
    setExtraStep3({ ...extraStep3, [name]: isChecked });
  };
  // const nextextrastep3 = () => {
  //   let isFormValid = false;

  //   const errors = {};

  //     if(extraStep3.price_type==="free"){
  //       isFormValid=true;
  //     }
  //     else{
  //       if(extraStep3.extra_price){
  //         isFormValid=true;
  //       }

  //       if(isFormValid===false){
  //         errors.extra_price = "Price field is required!";
  //       }
  //     }
  //   setExtra3error(errors)
  //   if (isFormValid===true) {
  //     setTab('step3');
  //     setExtra3valid(true);
  //   }
  // };
  //step4

  const handleExtra4Check = (isChecked, name) => {
    setExtraStep4({ ...extraStep4, [name]: isChecked });
  };
  const handleloyalchangeExtra4 = (isChecked, index) => {
    const updatedList = [...Loyaltyclublist];
    updatedList[index]["selected"] = isChecked;
    setLoyaltyclublist(updatedList);
  };

  const handlePriceChangeStep3 = (index, event) => {
    const updatedGuests = [...numberOfGuests];
    updatedGuests[index] = { price: event.target.value };
    setNumberOfGuests(updatedGuests);
  };

  const handlechangeSelectStep4 = (e) => {
    setExtraStep4({ ...extraStep4, [e.target.name]: e.target.value });
  };

  const handlechangeStep4 = (e) => {
    setExtraStep4({ ...extraStep4, [e.target.name]: e.target.value });
  };

  //step5

  const handlechangeExtra5 = (e) => {
    setExtraStep5({ ...extraStep5, [e.target.name]: e.target.value });
  };

  console.log(inputFields);
  console.log(selectedMulti);
  console.log(otherloyal);

  const Save = async () => {
    const formData = new FormData();
    formData.append("hotel_id", id);
    formData.append("title", extraName);
    if (extraStep5.subtitle) {
      formData.append("subtitle", extraStep5.subtitle);
    } else {
      formData.append("subtitle", "");
    }
    if (extraStep5.description) {
      formData.append("description", extraStep5.description);
    } else {
      formData.append("description", "");
    }
    if (extraStep5.extra_condition) {
      formData.append("extra_condition", extraStep5.extra_condition);
    } else {
      formData.append("extra_condition", "");
    }

    if (extraStep1.extraCheck === true) {
      formData.append("status", 1);
    } else {
      formData.append("status", 0);
    }

    if (sub_addon_status.sub_addon_status === true) {
      for (let i = 0; i < inputFields.length; i++) {
        formData.append("sub_addon_status", 1);
        formData.append(`sub_addons[${i}][title]`, inputFields[i].subaddons);
        formData.append(
          `sub_addons[${i}][price_type]`,
          inputFields[i].price_type
        );

        if (
          inputFields[i].price_type === "room_once" ||
          inputFields[i].price_type === "reserve_once"
        ) {
          formData.append(
            `sub_addons[${i}][sub_price]]`,
            inputFields[i].extra_price
          );
        }
        if (inputFields[i].price_type === "room_per_night") {
          formData.append(
            `sub_addons[${i}][sub_price]`,
            inputFields[i].extra_price
          );
          if (inputFields[i].extra3Check === true) {
            formData.append(`sub_addons[${i}][client_preference_status]`, 1);
          } else {
            formData.append(`sub_addons[${i}][client_preference_status]`, 0);
          }
        }
        if (inputFields[i].price_type === "person_once") {
          formData.append(
            `sub_addons[${i}][sub_price]`,
            inputFields[i].extra_price
          );
          formData.append(
            `sub_addons[${i}][price_age_category]`,
            inputFields[i].price_age_category
          );
        }
        if (inputFields[i].price_type === "person_per_night") {
          formData.append(
            `sub_addons[${i}][sub_price]`,
            inputFields[i].extra_price
          );
          formData.append(
            `sub_addons[${i}][price_age_category]`,
            inputFields[i].price_age_category
          );
          if (inputFields[i].client_preference_status === true) {
            formData.append(`sub_addons[${i}][client_preference_status]`, 1);
          } else {
            formData.append(`sub_addons[${i}][client_preference_status]`, 0);
          }
        }
        if (inputFields[i].price_type === "person_range_once") {
          let count = 0;
          for (let k = 0; k < inputFields[i].person_range_price.length; k++) {
            if (inputFields[i].person_range_price[k].price) {
              formData.append(
                `sub_addons[${i}][person_range_price][${count}][price]`,
                inputFields[i].person_range_price[k].price
              );
              formData.append(
                `sub_addons[${i}][person_range_price][${count}][person]`,
                k + 1
              );
              count += 1;
            }
          }
        }
        if (inputFields[i].price_type === "reserve_per_night") {
          formData.append(
            `sub_addons[${i}][sub_price]`,
            inputFields[i].extra_price
          );
          if (inputFields[i].client_preference_status === true) {
            formData.append(`sub_addons[${i}][client_preference_status]`, 1);
          } else {
            formData.append(`sub_addons[${i}][client_preference_status]`, 0);
          }
        }
        for (let j = 0; j < inputFields[i].multi_lang.length; j++) {
          formData.append(
            `sub_addons[${i}][multi_lang][${j}][lang_code]`,
            inputFields[i].multi_lang[j].lang_code
          );
          formData.append(
            `sub_addons[${i}][multi_lang][${j}][sub_addon_title]`,
            inputFields[i].multi_lang[j].subaddon
          );
        }
      }
    } else {
      formData.append("sub_addon_status", 0);
    }

    if (extra_include_status === true) {
      formData.append("inclusion_add_status", 1);
      let count = 0;
      for (let i = 0; i < loyalty.length; i++) {
        if (loyalty[i].selected && loyalty[i].selected === true) {
          formData.append(`inclusions[${count}]`, loyalty[i].value);
          count += 1;
        }
      }

      if (otherloyal_status === true) {
        formData.append("extra_include_list_other_status", 1);
        // formData.append("extra_include_list_other_language_code", loyallang);

        for (let i = 0; i < otherloyal.length; i++) {
          // for(let j=0;j<otherloyal[i].lang.length;j++){

          if (otherloyal[i].loyalty_name) {
            formData.append(
              `extra_include_other[${i}][title]`,
              otherloyal[i].loyalty_name
            );
          }

          for (let j = 0; j < otherloyal[i].lang.length; j++) {
            if (otherloyal[i].lang[j].loyalty_lang_name) {
              if (
                otherloyal[i].lang[j].lang_code &&
                otherloyal[i].lang[j].loyalty_lang_name
              ) {
                formData.append(
                  `extra_include_other[${i}][multi_lang][${j}][lang_code]`,
                  otherloyal[i].lang[j].lang_code
                );
                formData.append(
                  `extra_include_other[${i}][multi_lang][${j}][title]`,
                  otherloyal[i].lang[j].loyalty_lang_name
                );
              }
            }
          }
        }
      }
    } else {
      formData.append("inclusion_add_status", 0);
    }
    for (let i = 0; i < selectedMulti.length; i++) {
      if (selectedMulti[i].addon) {
        formData.append(
          `multi_lang[${i}][lang_code]`,
          selectedMulti[i].lang_code
        );
        if (selectedMulti[i].addon) {
          formData.append(`multi_lang[${i}][title]`, selectedMulti[i].addon);
        } else {
          formData.append(`multi_lang[${i}][title]`, "");
        }
      }
    }

    for (let i = 0; i < multilang.length; i++) {
      if (
        multilang[i].subtitle ||
        multilang[i].description ||
        multilang[i].extra_condition
      ) {
        formData.append(`multi_lang[${i}][lang_code]`, multilang[i].lang_code);
        if (multilang[i].subtitle) {
          formData.append(`multi_lang[${i}][subtitle]`, multilang[i].subtitle);
        } else {
          formData.append(`multi_lang[${i}][subtitle]`, "");
        }
        if (multilang[i].description) {
          formData.append(
            `multi_lang[${i}][description]`,
            multilang[i].description
          );
        } else {
          formData.append(`multi_lang[${i}][description]`, "");
        }
        if (multilang[i].condition) {
          formData.append(
            `multi_lang[${i}][extra_condition]`,
            multilang[i].condition
          );
        } else {
          formData.append(`multi_lang[${i}][extra_condition]`, "");
        }
      }
    }

    formData.append("provider", extraStep1.provider);
    if (extraStep1.provider === "external") {
      formData.append("notify_email", extraStep1.notify_email);
    }
    if (extraStep1.availabilty_status === true) {
      formData.append("availabilty_status", 1);
      formData.append("availabilty_type", extraStep1.availabilty_type);
    } else {
      formData.append("availabilty_status", 0);
    }
    if (image instanceof File) {
      formData.append("image", image);
    } else {
      formData.append("choosen_image", image);
    }
    if (extraStep1.availabilty_status === true) {
      for (let i = 0; i < selectedDatesstay.length; i++) {
        const formattedDate = moment(selectedDatesstay[i]).format("DD-MM-YYYY");
        formData.append(`reserve_dates[${i}]`, formattedDate);
      }
    }

    for (let i = 0; i < selectedroomocc.length; i++) {
      formData.append(`rooms[${i}][room]`, selectedroomocc[i].value);
      for (let j = 0; j < selectedroomocc[i].occupancies.length; j++) {
        formData.append(
          `rooms[${i}][occupancy][${j}][occupancy_id]`,
          selectedroomocc[i].occupancies[j].occupancy_id
        );
      }
      formData.append(`rooms[${i}][type]`, selectedroomocc[i].based_on);
    }
    let count = 0;
    for (let i = 0; i < selectedBoard.length; i++) {
      formData.append(`boards[${count}]`, selectedBoard[i].value);
      count += 1;
    }

    let count1 = 0;
    for (let i = 0; i < selectedRate.length; i++) {
      formData.append(`rates[${count1}]`, selectedRate[i].value);
      count1 += 1;
    }
    if (offerstatus.only_offer === true) {
      let count2 = 0;
      for (let i = 0; i < selectedOffers.length; i++) {
        formData.append(`offers[${count2}]`, selectedOffers[i].value);
        count2 += 1;
      }
      formData.append(`only_offer`, 1);
    } else {
      formData.append(`only_offer`, 0);
    }

    formData.append("price_type", extraStep3.price_type);
    if (
      extraStep3.price_type === "room_once" ||
      extraStep3.price_type === "reserve_once"
    ) {
      formData.append("extra_price", extraStep3.extra_price);
    }
    if (extraStep3.price_type === "room_per_night") {
      formData.append("extra_price", extraStep3.extra_price);
      if (extraStep3.extra3Check === true) {
        formData.append("client_preference_status", 1);
      } else {
        formData.append("client_preference_status", 0);
      }
    }
    if (extraStep3.price_type === "person_once") {
      formData.append("extra_price", extraStep3.extra_price);
      formData.append("price_age_category", extraStep3.price_age_category);
    }
    if (extraStep3.price_type === "person_per_night") {
      formData.append("extra_price", extraStep3.extra_price);
      formData.append("price_age_category", extraStep3.price_age_category);
      if (extraStep3.client_preference_status === true) {
        formData.append("client_preference_status", 1);
      } else {
        formData.append("client_preference_status", 0);
      }
    }
    if (extraStep3.price_type === "person_range_once") {
      let count = 0;
      for (let i = 0; i < numberOfGuests.length; i++) {
        if (numberOfGuests[i].price) {
          formData.append(
            `person_range_price[${count}][price]`,
            numberOfGuests[i].price
          );
          formData.append(`person_range_price[${count}][person]`, i + 1);
          count += 1;
        }
      }
    }
    if (extraStep3.price_type === "reserve_per_night") {
      formData.append("extra_price", extraStep3.extra_price);
      if (extraStep3.client_preference_status === true) {
        formData.append("client_preference_status", 1);
      } else {
        formData.append("client_preference_status", 0);
      }
    }
    if (extraStep4.min_stay_status === true) {
      formData.append("min_stay_status", 1);
      formData.append("min_stay_days", extraStep4.min_stay_days);
    } else {
      formData.append("min_stay_status", 0);
    }
    if (extraStep4.max_stay_status === true) {
      formData.append("max_stay_status", 1);
      formData.append("max_stay_days", extraStep4.max_stay_days);
    } else {
      formData.append("max_stay_status", 0);
    }
    if (extraStep4.days_in_advance_status === true) {
      formData.append("days_in_advance_status", 1);
      formData.append("days_in_advance", extraStep4.days_in_advance);
    } else {
      formData.append("days_in_advance_status", 0);
    }
    // if(extraStep4.loyalty_active_status === true){
    //   formData.append("loyalty_active_status",1);
    //   for(let i=0;i<Loyaltyclublist.length;i++){
    //       formData.append(`loyality_clubs[${i}]`,Loyaltyclublist[i].selected );
    //   }
    // }
    // else{
    //   formData.append("loyalty_active_status",0);
    // }

    if (extraStep4.loyalty_active_status === true) {
      formData.append("loyalty_active_status", 1);
      let count = 0;
      for (let i = 0; i < Loyaltyclublist.length; i++) {
        if (Loyaltyclublist[i].selected === true) {
          formData.append(`loyality_clubs[${count}]`, Loyaltyclublist[i].value);
          count += 1;
        }
      }
    } else {
      formData.append("loyalty_active_status", 0);
    }
    axios.post(`${Config.apiurl}hotel/extra/add`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    }).then((res) => {
      if (res.status_code !== "200") {
        setToastmodal(true);
        setToastmessage(res.message);
        setToasttype("error");
        setToastlarge(true);
      } else if (res.reauth === true) {
        handleUnauthorized();
        Save();
      } else {
        setToastmodal(true);
        setToastmessage(res.message);
        setToasttype("success");
        setToastlarge(true);
        setTimeout(() => navigate("/add-on"), 3000);
      }
      // if (res.status_code !== "200" || !res) {
      //   setToastmodal(true);
      //   setToastmessage(res.message);
      //   setToasttype("error");
      //   setToastlarge(true);
      // } else {
      //   setToastmodal(true);
      //   setToastmessage(res.message);
      //   setToasttype("success");
      //   setToastlarge(true);
      //   setTimeout(() => navigate("/add-on"), 3000);
      // }
    });
  };

  useEffect(() => {
    const getWeekdays = (startDate, endDate) => {
      const weekdays = [];
      const currentDay = new Date(startDate);
      const lastDay = new Date(endDate);

      while (currentDay <= lastDay) {
        const dayOfWeek = currentDay.getDay();
        if (dayOfWeek >= 0 && dayOfWeek <= 6) {
          weekdays.push(dayOfWeek);
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }

      return weekdays;
    };

    const getWeekdaysBetweenSelectedDates = (datefromstay, datetostay) => {
      const weekdays = getWeekdays(datefromstay, datetostay);
      setSelectedWeekdaysstay(weekdays);
      setClickedDaysstay([]);
    };

    if (datefromstay && datetostay) {
      getWeekdaysBetweenSelectedDates(datefromstay, datetostay);
    } else {
      setSelectedWeekdaysstay([]);
    }
  }, [datefromstay, datetostay]);

  useEffect(() => {
    const updatedList = [...Loyaltyclublist];

    updatedList.forEach((loyal) => {
      loyal.selected = true;
    });
    setLoyaltyclublist(updatedList);
  }, [Loyaltyclublist.length]);

  useEffect(() => {
    getListall();
  }, []);

  return (
    <div className="main-content">
      <Commonheader />
      <div className="page-content">
        <div className="container-fluid">
          <div className="row project-wrapper">
            <div className="offer-list-main">
              <div className="offer-list-top">
                <div
                  className="offer-list-title"
                  style={{ marginLeft: "23px" }}>
                  <h6 className="offer-list-head">Create New Add-on</h6>
                </div>
                <Link
                    type="button"
                    className="btn db-other-button"
                    to="/add-on"
                    style={{ marginRight: "1%" }}>
                    Add-on list
                  </Link>
              </div>
              <div className="col-md-1">
                <ul
                  className="offer-create-list block_by_block"
                  style={{ display: "contents" }}
                >
                  {extra1Valid === true ? (
                    <li
                      className={
                        tab === "step1"
                          ? "offer-list_new  offer-list-dummy active"
                          : "offer-list_new offer-list-dummy"
                      }
                      onClick={(e) => setTab("step1")}
                    >
                      Step 1<div style={{ fontSize: "12px" }}>Availability</div>
                    </li>
                  ) : (
                    <li
                      className={
                        tab === "step1"
                          ? "offer-list_new offer-list-dummy active"
                          : "offer-list_new offer-list-dummy"
                      }
                    >
                      Step 1<div style={{ fontSize: "12px" }}>Availability</div>
                    </li>
                  )}

                  {extra1Valid === true ? (
                    <li
                      className={
                        tab === "step2"
                          ? "offer-list_new  offer-list-dummy active"
                          : "offer-list_new offer-list-dummy"
                      }
                      onClick={(e) => setTab("step2")}
                    >
                      Step 2<div style={{ fontSize: "12px" }}>Room Types</div>
                    </li>
                  ) : (
                    <li
                      className={
                        tab === "step2"
                          ? "offer-list_new offer-list-dummy active"
                          : "offer-list_new offer-list-dummy"
                      }
                    >
                      Step 2<div style={{ fontSize: "12px" }}>Room Types</div>
                    </li>
                  )}

                  {extra1Valid === true && extra2valid === true ? (
                    <li
                      className={
                        tab === "step3"
                          ? "offer-list_new  offer-list-dummy dummy-delet active"
                          : "offer-list_new offer-list-dummy dummy-delet"
                      }
                      onClick={(e) => setTab("step3")}
                    >
                      Step 3<div style={{ fontSize: "12px" }}>Marketing</div>
                    </li>
                  ) : (
                    <li
                      className={
                        tab === "step3"
                          ? "offer-list_new offer-list-dummy  dummy-delet active"
                          : "offer-list_new offer-list-dummy dummy-delet"
                      }
                    >
                      {" "}
                      Step 3<div style={{ fontSize: "12px" }}>Marketing</div>
                    </li>
                  )}
                </ul>
              </div>

              {tab === "step1" ? (
                <Extrastep1
                  setTab={setTab}
                  extraStep1={extraStep1}
                  handleExtraToggle={handleExtraToggle}
                  extraName={extraName}
                  setExtraName={setExtraName}
                  extra1Error={extra1Error}
                  nextExtrastep1={nextExtrastep1}
                  handleExtraRadio={handleExtraRadio}
                  selectedDatesstay={selectedDatesstay}
                  setSelectedDatesstay={setSelectedDatesstay}
                  fpstay={fpstay}
                  handleOpenclosestay={handleOpenclosestay}
                  activestaydateopen={activestaydateopen}
                  setActivestaydateopen={setActivestaydateopen}
                  datefromstay={datefromstay}
                  datetostay={datetostay}
                  selectedWeekdaysstay={selectedWeekdaysstay}
                  clickedDaysstay={clickedDaysstay}
                  openclosestay={openclosestay}
                  setOpenclosestay={setOpenclosestay}
                  handleApplyToCalendarstay={handleApplyToCalendarstay}
                  handleCalendarChangeFrom={handleCalendarChangeFrom}
                  handleCalendarChangeTo={handleCalendarChangeTo}
                  setSelectedWeekdaysstay={setSelectedWeekdaysstay}
                  toggleSelectedDaystay={toggleSelectedDaystay}
                  language={language}
                  selectedMulti={selectedMulti}
                  setselectedMulti={setselectedMulti}
                  handlechangeExtra1={handlechangeExtra1}
                  extraStep4={extraStep4}
                  handleExtra4Check={handleExtra4Check}
                  Loyaltyclublist={Loyaltyclublist}
                  handleloyalchangeExtra4={handleloyalchangeExtra4}
                  handlechangeSelectStep4={handlechangeSelectStep4}
                  handlechangeStep4={handlechangeStep4}
                  addInputField={addInputField}
                  removeInputFields={removeInputFields}
                  inputFields={inputFields}
                  handleChangeslang={handleChangeslang}
                  handleLang={handleLang}
                  subAddonName={subAddonName}
                  setSubAddonName={setSubAddonName}
                  currency={currency}
                  numberOfGuests={numberOfGuests}
                  numberOfguestsub={numberOfguestsub}
                  handleOptionChangeExtrastep1={handleOptionChangeExtrastep1}
                  handlechangeStep1={handlechangeStep1}
                  handleExtra1Check={handleExtra1Check}
                  handlePriceChangeStep1={handlePriceChangeStep1}
                  sub_addon_status={sub_addon_status}
                  setSubaddon_status={setSubaddon_status}
                  handleChangeSubaddon={handleChangeSubaddon}
                  extra_include_status={extra_include_status}
                  setExtra_include_status={setExtra_include_status}
                  loyalty={loyalty}
                  otherloyal_status={otherloyal_status}
                  setOtherloyal_status={setOtherloyal_status}
                  otherloyal={otherloyal}
                  addInputFieldinclusion={addInputFieldinclusion}
                  removeInputFieldinclusion={removeInputFieldinclusion}
                  alllanguage={alllanguage}
                  loyallang={loyallang}
                  setLoyallang={setLoyallang}
                  hangleotherloyal={hangleotherloyal}
                  handleloyalchange={handleloyalchange}
                  formerror={formerror}
                  handleExtraSubaddonToggle={handleExtraSubaddonToggle}
                  tab2={tab2}
                  nextExtrastep1sub={nextExtrastep1sub}
                  setTab2={setTab2}
                  extraStep3={extraStep3}
                  setExtraStep3={setExtraStep3}
                  handleOptionChangeExtrastep3={handleOptionChangeExtrastep3}
                  handlechangeStep3={handlechangeStep3}
                  handleExtra3Check={handleExtra3Check}
                  handlePriceChangeStep3={handlePriceChangeStep3}
                  setCurrency={setCurrency}
                  handleLanguageChange={handleLanguageChange}
                  handleAddonChange={handleAddonChange}
                  handlelanglo={handlelanglo}
                  hangleotherloyallang={hangleotherloyallang}
                  // nextextrastep3={nextextrastep3}
                  extra3error={extra3error}
                  newLang={newLang}
                  removeInputFieldnew={removeInputFieldnew}
                  nextExtrastep1inc={nextExtrastep1inc}
                  handleLanguageChangesub={handleLanguageChangesub}
                  handleAddonChangesub={handleAddonChangesub}
                />
              ) : tab === "step2" ? (
                <Extrastep2
                  setTab={setTab}
                  extra2Room={extra2Room}
                  extra2Board={extra2Board}
                  extra2Ratelist={extra2Ratelist}
                  extra2error={extra2error}
                  handlesingleroomselect={handlesingleroomselect}
                  handlesingleoccupancyselect={handlesingleoccupancyselect}
                  handleChangeroomExtra2={handleChangeroomExtra2}
                  activeroom={activeroom}
                  activeboard={activeboard}
                  activerate={activerate}
                  nextofferstep2={nextofferstep2}
                  handlebasedon={handlebasedon}
                  handleChangeroomextra2all_label={
                    handleChangeroomextra2all_label
                  }
                  availableboard={availableboard}
                  selectedBoard={selectedBoard}
                  setSelectedBoard={setSelectedBoard}
                  selectedRate={selectedRate}
                  setSelectedRate={setSelectedRate}
                  offers={offers}
                  selectedOffers={selectedOffers}
                  setSelectedOffers={setSelectedOffers}
                  offerstatus={offerstatus}
                  handleOfferstatusToggle={handleOfferstatusToggle}
                />
              ) : // tab==="step3"?
              // <Extrastep3 setTab={setTab}
              // extraStep3={extraStep3}
              // setExtraStep3={setExtraStep3}
              // handleOptionChangeExtrastep3={handleOptionChangeExtrastep3}
              // handlechangeStep3={handlechangeStep3}
              // handleExtra3Check={handleExtra3Check}
              // numberOfGuests={numberOfGuests}
              // handlePriceChangeStep3={handlePriceChangeStep3}
              // currency={currency}
              // setCurrency={setCurrency}
              // nextextrastep3={nextextrastep3}
              // extra3error={extra3error}
              // />:
              tab === "step3" ? (
                <Extrastep5
                  setTab={setTab}
                  extraStep5={extraStep5}
                  language={language}
                  multilang={multilang}
                  setMultilang={setMultilang}
                  handlechangeExtra5={handlechangeExtra5}
                  Save={Save}
                  image={image}
                  setImage={setImage}
                  imagechoose={imagechoose}
                  setImagechoose={setImagechoose}
                  optionimage={optionimage}
                />
              ) : (
                ""
              )}
              {/* </div>
                   </div>
                  </div> */}
            </div>
          </div>
        </div>
        {toastmodal === true ? (
          <Toastmodal
            setToast={setToastmodal}
            message={toastmessage}
            type={toasttype}
            large={toastlarge}
          />
        ) : (
          ""
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Extracreate;
