import React from "react";
import Commonheader from "../../../Common/Commonheader";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import { useState } from "react";
import Footer from "../../../Common/Footer";
import Config from "../../../Config";
import { useEffect } from "react";
import Alerts from "../../../Alerts";
import Select from "react-select";
import Toastmodal from "../../../Common/Toastmodal";
import { useAuthHandling } from "../../../Common/useAuthHandling";
import Commonimagepath from "../../../Commonimagepath";
import axios from "axios";
import Loading from "../../../Loading";

function Rateplanedit() {
  const location = useLocation();
  let navigate = useNavigate();
  let params = useParams();
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const [id, setId] = useState(params.type_id);
  const [hotelid, setHotelid] = useState(params.hotel_id);
  const [language, setLanguage] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [country, setCountry] = useState([]);
  const [roomtype, setRoomtype] = useState([]);
  const [board, setBoard] = useState([]);
  const [policy, setPolicy] = useState([]);
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [formerror, setError] = useState({});
  const [rateplan, setRateplan] = useState([]);
  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [image, setImage] = useState([]);

  const [selectedCurrency, setselectedCurrency] = useState([]);
  const [selectedCountry, setselectedCountry] = useState([]);
  const [selectedRooms, setselectedRooms] = useState([]);
  const [selectedBoards, setselectedBoards] = useState([]);
  const [selectedPolicy, setselectedPolicy] = useState([]);
  const [defaultPolicy, setDefaultPolicy] = useState([]);
  const [selectedMulti, setselectedMulti] = useState([]);
  const [loader, setLoader] = useState(false);

  const [tab, setTab] = useState("EN");
  const [tab1, setTab1] = useState("");

  const getValue = async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code === "200") {
      setLanguage(data.data);
    } else if (data.reauth === true) {
      handleUnauthorized();
      getValue();
    } else {
      console.log("error");
    }
  };

  const getCurrencies = async (e) => {
    const res = await fetch(`${Config.apiurl}currencies-symbols`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code === "200") {
      const currencyOptions = data.data.map((currency) => {
        const labelParts = currency.label.split(" - ");
        const currencyCode = labelParts[0]; // Extract the currency code
        return {
          label: currencyCode, // Set the label to the currency code only
          value: currency.value,
        };
      });
      setCurrency(currencyOptions);
    } else if (data.reauth === true) {
      handleUnauthorized();
      getCurrencies();
    } else {
      console.log("error");
    }
  };

  const getCountries = async (e) => {
    const res = await fetch(`${Config.apiurl}country-list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code === "200") {
      setCountry(data.data);
    } else if (data.reauth === true) {
      handleUnauthorized();
      getCountries();
    } else {
      console.log("error");
    }
  };

  const getRoomtype = async () => {
    const res = await fetch(`${Config.apiurl}active-rooms/${hotelid}`, {
      method: "POST",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setRoomtype(data);
    }
  };

  const getBoard = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/hotel-boards`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: [hotelid],
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setBoard(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getBoard();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getBoard();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;
    // if (data1.status_code === "200") {
    //   setBoard(data);
    // } else if (data1.reauth === true) {
    //   handleUnauthorized();
    //   getBoard();
    // } else {
    //   console.log("error");
    // }
  };

  const getPolicy = async (e) => {
    const res = await fetch(
      `${Config.apiurl}hotel/cancelation-policy/${hotelid}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const data1 = await res.json();
    const data = data1.data;
    if (data1.status_code === "200") {
      setPolicy(data);
    } else if (data1.reauth === true) {
      handleUnauthorized();
      getPolicy();
    } else {
      console.log("error");
    }
  };

  const handlechange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setRateplan({ ...rateplan, [name]: value });
  };
  let handleChangetogg = (e) => {
    let value = e.target.checked;
    let name = e.target.name;
    setRateplan({ ...rateplan, [name]: value });
  };

  const getinfo = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/rate-list/view`, {
      method: "POST",
      body: JSON.stringify({
        rate_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200" || !data.data) {
      setselectedCurrency(data.data.currency);
      setselectedCountry(data.data.markets);
      setselectedRooms(data.data.rooms);
      setselectedBoards(data.data.boards);
      setselectedPolicy(data.data.policies);
      setRateplan(data.data.rate_list);
      setDefaultPolicy(data.data.default_policy);
      setselectedMulti(data.data.multi_lang);
      setTab1(data.data.rate_list.stay_reference);
      setLoader(true);
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getinfo();
    } else {
      console.log("error");
    }
    // const data = await res.json();
    // if (data.status_code === "200") {
    //   setselectedCurrency(data.data.currency);
    //   setselectedCountry(data.data.markets);
    //   setselectedRooms(data.data.rooms);
    //   setselectedBoards(data.data.boards);
    //   setselectedPolicy(data.data.policies);
    //   setRateplan(data.data.rate_list);
    //   setDefaultPolicy(data.data.default_policy);
    //   setselectedMulti(data.data.multi_lang);
    //   setTab1(data.data.rate_list.stay_reference);
    // } else if (data.reauth === true) {
    //   handleUnauthorized();
    //   getinfo();
    // }
  };

  const onFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const PostData = async (e) => {
    e.preventDefault();

    let isFormValid = true;
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-]+$/;

    const errors = {};
    if (!rateplan.public_name) {
      isFormValid = false;
      errors.public_name = "Public Name is required!";
    }
    else if (!noSpecialCharRegex.test(rateplan.en_public_name)) {
      isFormValid = false;
      errors.en_public_name = "Public name should not contain this special character";
    }
    else if (rateplan.en_public_name.length > 50) {
      isFormValid = false;
      errors.en_public_name = `Public name cannot be more than 50 characters!`;
    }

    if (!rateplan.internal_name) {
      isFormValid = false;
      errors.internal_name = "Internal Name is required!";
    }
    else if (!noSpecialCharRegex.test(rateplan.internal_name)) {
      isFormValid = false;
      errors.internal_name = "Internal name should not contain this special character";
    }
    else if (rateplan.internal_name.length > 50) {
      isFormValid = false;
      errors.internal_name = `Internal name cannot be more than 50 characters!`;
    }

    if (!rateplan.min_lead_days) {
      if (rateplan.min_lead_days === 0) {
        console.log("");
      } else {
        isFormValid = false;
        errors.min_lead_days = "This field is required!";
      }
    }
    if (!selectedCurrency) {
      isFormValid = false;
      errors.selectedCurrency = "Currency is required!";
    }
    if (selectedCountry.length === 0) {
      isFormValid = false;

      errors.selectedCountry = "Market is required!";
    }
    if (selectedBoards.length === 0) {
      isFormValid = false;

      errors.selectedBoards = "Board is required!";
    }
    if (selectedRooms.length === 0) {
      isFormValid = false;

      errors.selectedRooms = "Room is required!";
    }
    if (selectedPolicy.length === 0) {
      isFormValid = false;

      errors.selectedPolicy = "Assigned policy is required!";
    }
    if (!defaultPolicy) {
      isFormValid = false;

      errors.defaultPolicy = "Default policy is required!";
    }

    if (!rateplan.status) {
      isFormValid = false;

      errors.status = "Status is required!";
    }
    setError(errors);

    // let lang = [];

    // for (let i = 0; i < selectedMulti.length; i++) {
    //   if (selectedMulti[i].name) {
    //     if (selectedMulti[i].code !== "en") {
    //       let multiarray = {};
    //       multiarray.lang_code = selectedMulti[i].code;
    //       multiarray.public_name = selectedMulti[i].name;

    //       lang.push(multiarray);
    //     }
    //   }
    // }

    // let countryVal = [];
    // for (let i = 0; i < selectedCountry.length; i++) {
    //   countryVal.push(selectedCountry[i].value);
    // }

    // let roomVal = [];
    // for (let i = 0; i < selectedRooms.length; i++) {
    //   roomVal.push(selectedRooms[i].value);
    // }

    // let boardVal = [];
    // for (let i = 0; i < selectedBoards.length; i++) {
    //   boardVal.push(selectedBoards[i].hotel_borad);
    // }

    // let policyVal = [];
    // for (let i = 0; i < selectedPolicy.length; i++) {
    //   policyVal.push(selectedPolicy[i].value);
    // }

    if (isFormValid === true) {
      const formData = new FormData();
      formData.append("hotel_id", hotelid);
      formData.append("en_public_name", rateplan.public_name);
      formData.append("internal_name", rateplan.internal_name);
      formData.append("minimum_lead_days", rateplan.min_lead_days);
      formData.append("currency", selectedCurrency.value);
      for (let i = 0; i < selectedCountry.length; i++) {
        formData.append(`markets[${i}]`, selectedCountry[i].value);
      }
      for (let i = 0; i < selectedRooms.length; i++) {
        formData.append(`rooms[${i}]`, selectedRooms[i].value);
      }
      for (let i = 0; i < selectedBoards.length; i++) {
        formData.append(`boards[${i}]`, selectedBoards[i].hotel_borad);
      }
      for (let i = 0; i < selectedPolicy.length; i++) {
        formData.append(`policy[${i}]`, selectedPolicy[i].value);
      }
      if(rateplan.confidential_status===true){
        formData.append("confidential_status", 1);
      }
      else{
        formData.append("confidential_status", 0);
      }
      if(rateplan.loyalty_member_status===true){
        formData.append("loyalty_member_status", 1);
      }
      else{
        formData.append("loyalty_member_status", 0);
      }
      if(rateplan.general_increment_status){
        formData.append("general_increment_status",1);
      }
      else{
        formData.append("general_increment_status",0);
      }

      if(rateplan.basic_rateplan_status===true){
        formData.append("basic_rateplan_status", 1);
       }
      else{
        formData.append("basic_rateplan_status", 0);
      }

      // formData.append("rooms", roomVal);
      // formData.append("boards", boardVal);
      // formData.append("policy", policyVal);
      formData.append("default_policy", defaultPolicy.value);
      formData.append("stay_reference", tab1);
      formData.append("status", rateplan.status);
      let count = 0;
      for (let i = 0; i < selectedMulti.length; i++) {
        if (selectedMulti[i].name) {
          if (selectedMulti[i].code !== "en") {
            formData.append(
              `multi_lang[${count}][lang_code]`,
              selectedMulti[i].code
            );
            formData.append(
              `multi_lang[${count}][public_name]`,
              selectedMulti[i].name
            );
            count += 1;
          }
        }
      }
      // formData.append("multi_lang", lang);
      formData.append("image", image);
      axios
        .post(`${Config.apiurl}hotel/rate-list/update/${id}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((res) => {
          if (res.status_code === "200") {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(false);
            setTimeout(() => navigate("/rate-plan-list"), 3000);
          } else if (res.reauth === true) {
            handleUnauthorized();
            PostData();
          } else {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(false);
          }
        });
      // const res = await fetch(`${Config.apiurl}hotel/rate-list/update/${id}`, {
      //   method: "POST",
      //   body: JSON.stringify({
      //     hotel_id: hotelid,
      //     en_public_name: rateplan.public_name,
      //     internal_name: rateplan.internal_name,
      //     minimum_lead_days: rateplan.min_lead_days,
      //     currency: selectedCurrency.value,
      //     markets: countryVal,
      //     confidential_status: rateplan.confidential_status,
      //     loyalty_member_status: rateplan.loyalty_member_status,
      //     general_increment_status: rateplan.general_increment_status,
      //     rooms: roomVal,
      //     boards: boardVal,
      //     policy: policyVal,
      //     default_policy: defaultPolicy.value,
      //     stay_reference: tab1,
      //     status: rateplan.status,
      //     multi_lang: lang,
      //   }),
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("token")}`,
      //     "Content-type": "application/json; charset=UTF-8",
      //   },
      // });
      // const data = await res.json();
      // if (data.status_code !== "200" || !data) {
      //   setToastmodal(true);
      //   setToastmessage(data.message);
      //   setToasttype("error");
      //   setToastlarge(false);
      // } else {
      //   setToastmodal(true);
      //   setToastmessage(data.message);
      //   setToasttype("success");
      //   setToastlarge(false);
      //   setTimeout(() => navigate("/rate-plan-list"), 3000);
      // }
    }
  };

  useEffect(() => {
    getinfo();
    getValue();
    getCurrencies();
    getCountries();
    getRoomtype();
    getBoard();
    getPolicy();
  }, []);

  useEffect(() => {
    if (selectedBoards.length > 0) {
      const filteredBoards = board.filter((board) =>
        selectedBoards.some(
          (selectedBoard) => selectedBoard.value === board.value
        )
      );
      setselectedBoards(filteredBoards);
    }
  }, [selectedBoards.length, board.length]);

  useEffect(() => {
    if (selectedRooms.length > 0) {
      const filteredRooms = roomtype.filter((room) =>
        selectedRooms.some((selectRoom) => selectRoom.value === room.value)
      );
      setselectedRooms(filteredRooms);
    }
  }, [selectedRooms.length, roomtype.length]);

  useEffect(() => {
    if (selectedPolicy.length > 0) {
      const filteredPolicy = policy.filter((pol) =>
        selectedPolicy.some(
          (selectedBoard) => selectedBoard.value === pol.value
        )
      );
      setselectedPolicy(filteredPolicy);
    }
  }, [selectedPolicy.length, policy.length]);

  useEffect(() => {
    if (defaultPolicy.length > 0) {
      const filteredBoards = policy.filter((board) =>
        defaultPolicy.some(
          (selectedBoard) => selectedBoard.value === board.value
        )
      );
      setDefaultPolicy(filteredBoards);
    }
  }, [defaultPolicy.length, policy.length]);

  return (
    <div className="main-content">
      <Commonheader />
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between"></div>
            </div>
          </div>

          <div className="row project-wrapper">
            <div className="row dashboard-top-tab-style">
              <div className="col-xxl-12 dashboard-main-style">
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center gy-3">
                      <div className="col-sm">
                        <h5 className="card-title mb-0">Edit Rate List</h5>
                      </div>
                      <div className="col-sm-auto">
                        <div className="d-flex gap-1 flex-wrap">
                          <Link
                            className="btn  db-other-button"
                            to="/rate-plan-list"
                          >
                            Rate List
                          </Link>
                          {/* <button
                            type="button"
                            className="btn db-cancel-button"
                          >
                            Cancel
                          </button> */}
                          <button
                            className="btn db-save-button"
                            onClick={PostData}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {loader===false?
                  <Loading/>
                  :
                  <div className="card-body">
                    <div className="live-preview">
                      <form action="#">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-translation-style">
                              <ul>
                                <li
                                  className={tab === "EN" ? "active" : ""}
                                  onClick={(e) => setTab("EN")}
                                >
                                  EN
                                </li>
                                <li
                                  className={tab === "TRANS" ? "active" : ""}
                                  onClick={(e) => setTab("TRANS")}
                                >
                                  Translations
                                </li>
                              </ul>
                            </div>
                          </div>

                          {
                            tab === "EN" ? (
                              <>
                                <div class="col-lg-3">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Public Name
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="public_name"
                                      value={rateplan.public_name}
                                      onChange={handlechange}
                                    />
                                    <label style={{ color: "red" }}>
                                      {formerror.public_name}
                                    </label>
                                  </div>
                                </div>
                                <div class="col-lg-3">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                      style={{ width: "120px" }}
                                    >
                                      Internal Name
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="internal_name"
                                      value={rateplan.internal_name}
                                      onChange={handlechange}
                                    />
                                    <label style={{ color: "red" }}>
                                      {formerror.internal_name}
                                    </label>
                                  </div>
                                </div>
                                <div class="col-lg-3">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Default release
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                      <span
                                        className="info-tag"
                                        style={{ backgroundColor: "black" }}
                                      >
                                        i
                                        <span className="tooltiptext">
                                          Minimum lead days
                                        </span>
                                      </span>
                                    </label>
                                    <input
                                      onKeyDown={(e) =>
                                        symbolsArr.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      type="number"
                                      className="form-control"
                                      name="min_lead_days"
                                      value={rateplan.min_lead_days}
                                      onChange={handlechange}
                                      min={0}
                                    />
                                    <label style={{ color: "red" }}>
                                      {formerror.min_lead_days}
                                    </label>
                                  </div>
                                </div>
                                <div class="col-lg-3">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Currency
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <div className="col-md-7">
                                      <Select
                                        className="form-control"
                                        id="exampleInputUsername1"
                                        options={currency}
                                        value={selectedCurrency}
                                        // onChange={(val)=>setselectedCurrency(val)}
                                      />
                                    </div>
                                    <label style={{ color: "red" }}>
                                      {formerror.selectedCurrency}
                                    </label>
                                  </div>
                                </div>
                                <div className="row g-3">
                                  <div class="col-lg-3">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                      >
                                        Market
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <div className="col-md-7">
                                        <MultiSelect
                                          options={country}
                                          value={selectedCountry}
                                          onChange={(val) =>
                                            setselectedCountry(val)
                                          }
                                          labelledBy="Select"
                                          selectionType="counter"
                                          allowCustomValue={true}
                                        />
                                      </div>
                                      <label style={{ color: "red" }}>
                                        {formerror.selectedCountry}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="mb-3">
                                      <label
                                        for="firstNameinput"
                                        className="form-label"
                                      >
                                        Confidential{" "}
                                        <span className="form-validation-required"></span>
                                        <span className="info-tag">
                                          i
                                          <span className="tooltiptext">
                                            with promotional code
                                          </span>
                                        </span>
                                      </label>
                                      <div
                                        className="form-check form-switch form-switch-lg mb-3"
                                        dir="ltr"
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="inlineswitch5"
                                          value={rateplan.confidential_status}
                                          name="confidential_status"
                                          checked={
                                            rateplan.confidential_status ===
                                            true
                                              ? true
                                              : false
                                          }
                                          onChange={handleChangetogg}
                                          style={{
                                            width: "50px",
                                            height: "25px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="mb-3">
                                      <label
                                        for="firstNameinput"
                                        className="form-label"
                                      >
                                        Loyalty club{" "}
                                        <span className="form-validation-required"></span>
                                      </label>
                                      <div
                                        className="form-check form-switch form-switch-lg mb-3"
                                        dir="ltr"
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="inlineswitch5"
                                          name="loyalty_member_status"
                                          value={rateplan.loyalty_member_status}
                                          checked={
                                            rateplan.loyalty_member_status ===
                                            true
                                              ? true
                                              : false
                                          }
                                          onChange={handleChangetogg}
                                          style={{
                                            width: "50px",
                                            height: "25px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="mb-3">
                                      <label
                                        for="firstNameinput"
                                        className="form-label"
                                      >
                                        Apply general increment{" "}
                                        <span className="form-validation-required"></span>
                                      </label>
                                      <span
                                        className="info-tag"
                                        style={{
                                          marginLeft: "165px",
                                          bottom: "30px",
                                        }}
                                      >
                                        i
                                        <span className="tooltiptext">
                                          If an offer exists, the offer setting
                                          will prevail
                                        </span>
                                      </span>
                                      <div
                                        className="form-check form-switch form-switch-lg mb-3"
                                        dir="ltr"
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="inlineswitch5"
                                          name="general_increment_status"
                                          value={
                                            rateplan.general_increment_status
                                          }
                                          checked={
                                            rateplan.general_increment_status ===
                                            true
                                              ? true
                                              : false
                                          }
                                          onChange={handleChangetogg}
                                          style={{
                                            width: "50px",
                                            height: "25px",
                                            bottom: "20px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="mb-3">
                                      <label
                                        for="firstNameinput"
                                        className="form-label"
                                      >
                                        Basic Rate Plan Status{" "}
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <div
                                        className="form-check form-switch form-switch-lg mb-3"
                                        dir="ltr"
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="inlineswitch5"
                                          name="basic_rateplan_status"
                                          value={rateplan.basic_rateplan_status}
                                          checked={
                                            rateplan.basic_rateplan_status ===
                                            true
                                              ? true
                                              : false
                                          }
                                          onChange={handleChangetogg}
                                          style={{
                                            width: "50px",
                                            height: "25px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="padding-10"></div>
                                <div className="col-ms-12">
                                  <div className="form-translation-style">
                                    <h6 className="sub-title-form">
                                      Assign to Rooms / Occupancy and Boards
                                    </h6>
                                  </div>
                                </div>
                                <div className="row">
                                  <div class="col-lg-6">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                        style={{ width: "160px" }}
                                      >
                                        Rooms and Occupancy
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <div className="col-md-8">
                                        <MultiSelect
                                          options={roomtype}
                                          value={selectedRooms}
                                          onChange={(val) =>
                                            setselectedRooms(val)
                                          }
                                          labelledBy="Select"
                                          selectionType="counter"
                                          allowCustomValue={true}
                                          aria-expanded="true"
                                        />
                                      </div>
                                      <label style={{ color: "red" }}>
                                        {formerror.selectedRooms}
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-lg-6">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                      >
                                        Boards
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <div className="col-md-8">
                                        <MultiSelect
                                          options={board}
                                          value={selectedBoards}
                                          onChange={(val) =>
                                            setselectedBoards(val)
                                          }
                                          labelledBy="Select"
                                          selectionType="counter"
                                          allowCustomValue={true}
                                          aria-expanded="true"
                                        />
                                      </div>
                                      
                                    </div>
                                    <label style={{ color: "red" }}>
                                        {formerror.selectedBoards}
                                      </label>
                                  </div>
                                </div>
                                <div className="padding-10"></div>
                                <div className="col-ms-12">
                                  <div className="form-translation-style">
                                    <h6 className="sub-title-form">
                                      Cancellation Payment Policies
                                    </h6>
                                  </div>
                                </div>
                                <div className="row">
                                  <div class="col-lg-6">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                        style={{ width: "160px" }}
                                      >
                                        Assigned Policies
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <div className="col-md-8">
                                        <MultiSelect
                                          options={policy}
                                          value={selectedPolicy}
                                          onChange={(val) =>
                                            setselectedPolicy(val)
                                          }
                                          labelledBy="Select"
                                          selectionType="counter"
                                          allowCustomValue={true}
                                          aria-expanded="true"
                                        />
                                      </div>
                                      <label style={{ color: "red" }}>
                                        {formerror.selectedPolicy}
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-lg-6">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                        style={{ width: "103px" }}
                                      >
                                        Default policy
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <div className="col-md-8">
                                        <Select
                                          className="form-control"
                                          id="exampleInputUsername1"
                                          options={policy}
                                          value={defaultPolicy}
                                          onChange={setDefaultPolicy}
                                        />
                                      </div>
                                      <label style={{ color: "red" }}>
                                        {formerror.defaultPolicy}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="padding-10"></div>
                                <div className="col-ms-12">
                                  <div className="form-translation-style">
                                    <h6 className="sub-title-form">
                                      Other Configuration{" "}
                                    </h6>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-5">
                                    <div className="mb-3">
                                      <label className="form-label">
                                        Reference for calculating minimum /
                                        maximum stay{" "}
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <ul
                                        className="stay_type_form"
                                        id="referencr_stay_type"
                                      >
                                        <li
                                          className={
                                            tab1 === "by_arrival"
                                              ? "active"
                                              : ""
                                          }
                                          value={rateplan.stay_reference}
                                          onClick={(e) => setTab1("by_arrival")}
                                        >
                                          By Arrival
                                        </li>
                                        <li
                                          className={
                                            tab1 === "by_stay" ? "active" : ""
                                          }
                                          value={rateplan.stay_reference}
                                          onClick={(e) => setTab1("by_stay")}
                                        >
                                          By Stay
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-lg-3">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                      >
                                        Status
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <div className="col-md-6">
                                        <select
                                          id="ForminputState"
                                          className="form-select"
                                          value={rateplan.status}
                                          onChange={handlechange}
                                          name="status"
                                        >
                                          <option selected>Choose...</option>
                                          <option
                                            {...(rateplan.status === true
                                              ? "selected"
                                              : "")}
                                            value={true}
                                          >
                                            Active
                                          </option>
                                          <option
                                            {...(rateplan.status === false
                                              ? "selected"
                                              : "")}
                                            value={false}
                                          >
                                            Inactive
                                          </option>
                                        </select>
                                      </div>
                                      <label style={{ color: "red" }}>
                                        {formerror.status}
                                      </label>
                                    </div>
                                  </div>

                                  <div class="col-lg-4">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                      >
                                        Image
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        placeholder=""
                                        onChange={onFileChange}
                                        accept=".jpg, .jpeg, .webp, .png"
                                      />
                                      <label style={{ color: "red" }}>
                                        {formerror.image}
                                      </label>
                                    </div>
                                    <img
                                      src={`${Commonimagepath.apiurl}${rateplan.image}`}
                                      alt=""
                                      style={{
                                        width: "50px",
                                        marginTop: "5px",
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="padding-20"></div>
                              </>
                            ) : (
                              <>
                                <div
                                  className="col-lg-4"
                                  style={{ marginTop: "20px" }}
                                >
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                      style={{ width: "130px" }}
                                    >
                                      {" "}
                                      Choose Language
                                    </label>
                                    <div className="col-md-7">
                                      <MultiSelect
                                        options={language}
                                        value={selectedMulti}
                                        onChange={(val) =>
                                          setselectedMulti(val)
                                        }
                                        labelledBy="Select"
                                        selectionType="counter"
                                        allowCustomValue={true}
                                        aria-expanded="true"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="appendmore"
                                  style={{ marginTop: "20px" }}
                                ></div>
                                {selectedMulti.length > 0
                                  ? selectedMulti.map((element, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="col-md-12 translation-fill-section"
                                          id={`lang_${element.value}`}
                                          data-value="1"
                                          style={{ marginTop: "20px" }}
                                        >
                                          <div className="translation-selected-lang">
                                            <p>
                                              <span className="translation-selected-lang-head">
                                                {element.code}
                                              </span>
                                            </p>
                                          </div>

                                          <div className="col-lg-4">
                                            <div className="input-group">
                                              <label
                                                className="input-group-text input-joint-color"
                                                id="inputGroup-sizing-default"
                                              >
                                                Public Name
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                value={element.name}
                                                onChange={(e) => {
                                                  const updatedSelectedMulti = [
                                                    ...selectedMulti,
                                                  ];
                                                  updatedSelectedMulti[index] =
                                                    {
                                                      ...element,
                                                      name: e.target.value,
                                                    };
                                                  setselectedMulti(
                                                    updatedSelectedMulti
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : ""}
                              </>
                            )
                            //          <>
                            //         <div class="col-lg-4" style={{marginTop:"20px"}}>
                            //         <div className="input-group">
                            //           <label className="input-group-text input-joint-color"
                            //             id="inputGroup-sizing-default" style={{width:"130px"}}> Choose Language</label>
                            //         <div className="col-md-7">
                            //         <MultiSelect
                            //                options={language}
                            //                value={selectedMulti}
                            //                onChange={(val)=>setselectedMulti(val)}
                            //                labelledBy="Select"
                            //                selectionType="counter"
                            //                closeOnChangedValue={true}
                            //                aria-expanded="true"
                            //              />
                            //             </div>
                            // </div>
                            //      </div>
                            //          <div className="appendmore" style={{marginTop:"20px"}}></div>
                            //          {selectedMulti.length > 0
                            //            ? selectedMulti.map((element, index) => {
                            //                return (
                            //                 <div
                            //                 key={index}
                            //                 className="col-md-12 translation-fill-section"
                            //                 id={`lang_${element.value}`}
                            //                 data-value="1"
                            //                 style={{ marginTop: "20px" }}
                            //               >
                            //                    <div className="translation-selected-lang">
                            //                      <p>
                            //                        <span className="translation-selected-lang-head">
                            //                          {element.code}
                            //                        </span>
                            //                      </p>
                            //                    </div>

                            //                    <div class="col-lg-4">
                            //              <div className="input-group">
                            //             <label className="input-group-text input-joint-color"
                            //                 id="inputGroup-sizing-default">Public Name</label>
                            //                  <input type="text" className="form-control" placeholder=""
                            //                   value={element.name}
                            //                  onChange={(e) =>
                            //                   selectedMulti((prevMulti) =>
                            //                     prevMulti.map((item, i) =>
                            //                       i === index ? { ...item, name: e.target.value } : item
                            //                     )
                            //                   )
                            //                 }
                            //               />
                            //                 {/* <input type="text" className="form-control" placeholder="" value={selectedMulti[index].name} onChange={(e) =>(selectedMulti[index].name =e.target.value)}/> */}
                            //               </div>
                            //             </div>
                            //                  </div>
                            //                );
                            //              })
                            //            : ""}
                            //          </>
                          }
                        </div>
                      </form>
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
      <Footer />
    </div>
  );
}

export default Rateplanedit;
