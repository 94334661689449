import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";

function TempTaxdetails({ setTab,
tax,
hoteldetails,
currency
}) {
 
  // const [title, description] = currency.split(' - ');
  return (
    <div id="TaxDetails" className="tabcontent">
      <div className="card-body">
        <div className="live-preview">
          <div className="col-md-12 be-form-tax-title">
            <label
              for="firstNameinput"
              className="form-label be-form-tax-title-status be_form_label"
            >
              Do you want to display Tax Included Rate on your Booking Engine{" "}
             
            </label>
            <div className="tax_include_status_btn">

            <div className="switch-field">
                              <input
                                type="radio"
                                id="radio-one"
                                name="tax_include_status"
                                value={true}
                                checked={hoteldetails.tax_include_status === true}
                             
                              />
                              <label htmlFor="radio-one">Tax Included</label>

                              <input
                                type="radio"
                                id="radio-two"
                                name="tax_include_status"
                                value={false}
                                checked={hoteldetails.tax_include_status === false}
                              
                              />
                              <label htmlFor="radio-two">Tax Excluded</label>
                          </div>
           
{/* <span
                        className="form-check form-switch form-switch-lg mb-3"
                        dir="ltr"
                      >
                  <input
                          type="checkbox"
                          name="taxstatus"
                          className="form-check-input loyalty_expiration"
                          value={hoteldetails.tax_include_status}
                           checked={hoteldetails.tax_include_status === true ? true : false}
                        />
                        </span> */}
            
            </div>
          </div>
          <div className="col-md-12"></div>
          <div className="be_form_tax_inclue_details">
            {/* <div className="addmore_tax_details">
              <span className="be_form_tax_info_title_60">
                <label for="firstNameinput" className="form-label be_form_label">
                  Tax Name 
                 
                </label>
              </span>
              <span className="be_form_tax_info_title_20">
                <label for="firstNameinput" className="form-label be_form_label">
                  Discount
                
                </label>
              </span>
              <span className="be_form_tax_info_title_15">
                <label for="firstNameinput" className="form-label be_form_label">
                  Applicable to
                 
                </label>
              </span>
              <span className="be_form_tax_info_title">
                <label for="firstNameinput" className="form-label be_form_label">
                  Comment
                 
                </label>
              </span>
            </div> */}
            <div className="addmore_tax_details new_tax_options">
            {tax.map((data, index) => {
                              return (
                                <div key={index} className="row">
              <span className="room_info_count mb-1">
                <span className="room_sect_no be_form_roomsec_1">{index+1}</span>
              </span>

              <span className="col-md-4">
              <label for="firstNameinput" className="form-label be_form_label">
                  Tax Name 
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={data.title}
                />

           
              </span>

              <span className="col-md-3">
              <label for="firstNameinput" className="form-label be_form_label">
                  Rate
                
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id="emailidInput"
                  value={data.value}
                />

            
                <span className="be_form_tax_info_label_10 tax_discount_type_section">
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id="emailidInput"
                  value={data.amount_type}
                /> */}
                <select id="ForminputState" className="form-select"  
                  value={data.amount_type}
                  name="amount_type">
                    
                    <option value="percentage" selected >%</option>
                    <option value="currency">{currency.code}</option>
                  </select>
                </span>
              </span>
              <span className="col-md-2">
              <label for="firstNameinput" className="form-label be_form_label">
                  Applicable to
                 
                </label>
                <select id="ForminputState" className="form-select" 
                value={data.increment_type}
                name="increment_type">
                   <option value="">Choose</option>
                  <option value='per_person'>Per Person</option>
                  <option value='per_room'>Per Room</option>
                  
                </select>
              {/* <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id="emailidInput"
                  value={data.increment_type}
                /> */}

               
              </span>

              <span className="col-md-3">
                <label for="firstNameinput" className="form-label be_form_label">
                  Room type
                </label>
             
                <MultiSelect
                  labelledBy="Select"
                  selectionType="counter"
                  allowCustomValue={true} 
                  id="exampleInputUsername1"
                  options={data.room_type}
                  value={data.room_type}
                    />
              </span>
              <div className="" style={{ marginTop: "20px" }}></div>
              <div className="col-md-2">
                      <div className="mb-4">
                        <label
                          for="firstNameinput"
                          className="form-label be_form_label"
                        >
                          Frequency
                          <span className="form-validation-required">*</span>
                         
                        </label>
                        <select
                          id="ForminputState"
                          className="form-select"
                          value={data.increment_frequency}
                          name="increment_frequency"
                        >
                          <option>Choose</option>
                          <option value="once">Once</option>
                          <option value="per_night">Per Night</option>
                        </select>
                     
                      </div>
                    </div>
                    <div className="col-md-2" style={{ left: "3%" }}>
                      <div className="mb-4">
                        <div>
                          <label
                            for="firstNameinput"
                            className="form-label be_form_label"
                          >
                            Service Charge
                          </label>
                        </div>
                        <div
                          className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                          dir="ltr"
                        >
                          <input
                            type="checkbox"
                            className="form-check-input offer_checkbox1 check-box-green checkbox1_wid"
                            id="inlineswitch5"
                            name="service_charge_status"
                            value={data.service_charge_status}
                            checked={
                              data.service_charge_status === true ? true : false
                            }
                            style={{
                              width: "50px",
                              height: "25px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-4">
                        <div>
                        <label
                          for="firstNameinput"
                          className="form-label be_form_label"
                        >
                          Service Charge Applicable
                        </label>
                        </div>
                        <span
                          className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                          dir="ltr"
                        >
                          <input
                            type="checkbox"
                            className="form-check-input offer_checkbox1 check-box-green checkbox1_wid"
                            id="inlineswitch5"
                            name="service_charge_applicable"
                            value={data.service_charge_applicable}
                            checked={
                              data.service_charge_applicable === true ? true : false
                            }
                            style={{
                              width: "50px",
                              height: "25px",
                            }}
                          />
                        </span>
                      </div>
                    </div>
             <div className="row mt-1">
              <span className="col-md-12">
                <label for="firstNameinput" className="form-label be_form_label">
                  Comment
                </label>
             
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id="emailidInput"                 
                  value={data.message}
                />
              </span>
            </div>
             
              <div className={index===tax.length-1 ? "boder1 d-none":"boder1"} ></div>

        </div>)})}
            </div>
            <div className="taxappendmore"></div>

            
           
          </div>
          <div className="tab_nav_section mt-2">
            <div className="tab_nav_section_btns_form">
              <button
              type="button"
                className="btn btn-be-form-cancel btn-be-back"
                onClick={(e) => setTab("Freequency")}
              >
                Prev
              </button>
              <button
              type="button"
                className="btn btn-success btn-be-common"

                onClick={(e) => setTab("Loyalty")}

              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TempTaxdetails;
