import React, { useContext, useEffect } from 'react'
import Header from '../../../Layouts/Header';
import HotelSidebar from './Sidebars/HoletSidebar';
import Sidebar from './Sidebars/Sidebar';
import { useState } from 'react';
import Sidebarconditions from './Sidebars/Sidebarconditions';
import { UserContext } from '../Contexts/UserContext';
import LZString from 'lz-string';
import CryptoJS from "crypto-js";

function Conditionsheader() {
    const {updateHotel_select, hotel_select} = useContext(UserContext);

    const [showhotel,setShowhotel]=useState(false);

    const [dashboard,setDashboard]=useState(true);
    const[pagename,setPagename]=useState("condition");
    const [user_type, setUser_type] = useState("");

    const secretKey ="fTh76vpd&nxs#94PzAr49Hy6q*!MbLR7";

    useEffect(() => {
      const encryptedString = localStorage.getItem("user_type");
      if (encryptedString) {
        const bytes = CryptoJS.AES.decrypt(encryptedString, secretKey);
        const decryptedUserType = bytes.toString(CryptoJS.enc.Utf8); 
        setUser_type(decryptedUserType); 
      }
    }, []);

useEffect(()=>{
  setShowhotel(hotel_select.length>0?true:false);
},[hotel_select])

    return (
      <div>
        <Header setShowhotel={setShowhotel} dashboard={dashboard} pagename={pagename} user_type={user_type} showhotel={showhotel}/>
       
          <Sidebarconditions showhotel={showhotel}/>
         
      </div>
    )
}

export default Conditionsheader