import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../Common/Footer";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import Config from "../../Config";
import axios from "axios";
import Alerts from "../../Alerts";
import Loading from "../../Loading";
import Commonheader from "../../Common/Commonheader";

function Roleedit() {
  let { id } = useParams();
  let navigate = useNavigate();

  const [selectedMulti, setselectedMulti] = useState([]);
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }
  const [permission, setPermission] = useState([]);
  const [permissionval,setPermissionval]=useState([]);
  const [language, setLanguage] = useState([]);
  const [selectedPermission, setselectedPermission] = useState([]);
  const [rolename, setRolename] = useState("");
  const [status, setStatus] = useState("");
  const [active, setActive] = useState("en");
  const [roletrans, setRoletrans] = useState({});
  const [alert, setAlert] = useState(false);
  const [datas, setDatas] = useState([]);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState([]);
const [multilang,setMultilang]=useState([]);
  const [formerror, setError] = useState({});
  const [formerror2, setError2] = useState({});
  const [formerror3, setError3] = useState({});

  const getinfo = async (e) => {
    const res = await fetch(`${Config.apiurl}admin/roles/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (res.status === 404 || !data) {
      console.log("error");
    } else {
      setRole(data.data);
      setRolename(data.data.role.name);
      setStatus(data.data.role.status);
      setMultilang(data.data.multi_lang);
      setPermissionval(data.data.permissions);
    }
  };

  const getData = async (e) => {
    const res = await fetch(`${Config.apiurl}admin/permissions`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setPermission(data);
    }
  };

  const getDatalang = async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setLanguage(data);
    }
  };
  const trnsarray = [];

  function Reset() {
    setPermission("");
    setLanguage("");
    setselectedPermission("");
    setRolename("");
    setStatus("");
    setselectedMulti("");
  }

  const validate = (values) => {
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-]+$/;

    const errors = {};
    if (!values) {
      errors.rolename = "Name is required!";
    }
    else if (!noSpecialCharRegex.test(values)) {
      errors.rolename = "Name should not contain this special character";
    }
    else if (values.length > 50) {
      errors.rolename = `Name cannot be more than 50 characters!`;
    }
    return errors;
  };
  const validate2 = (values) => {
    const errors = {};
    if (values.length === 0) {
      errors.selectedPermission = "Permission is required!";
    }
    return errors;
  };
  const validate1 = (values) => {
    const errors = {};
    if (!values) {
      errors.status = "Status is required!";
    }
    return errors;
  };

  const PostData = async (e) => {
    e.preventDefault();
    const ress = validate(rolename);
    const ress2 = validate1(status);
    const ress3 = validate2(permissionval);
    let per = [];
    let lang = [];

    for (let i = 0; i < permissionval.length; i++) {
      let arr = {};
      arr = permissionval[i].id;
      per.push(arr);
    }
    for (let i = 0; i < multilang.length; i++) {
      if (multilang[i].title) {
        if (multilang[i].code !== "en") {
          let multiarray = {};
          let array=[];
          let multi={};
          multiarray.lang_code = multilang[i].code;
          
          multi.name = multilang[i].title;
          array.push(multi)
          multiarray.fields=array;
          // multiarray.lang_code = multilang[i].code;
          // multiarray.name = multilang[i].title;
          lang.push(multiarray);
        }
      }
    }
    setError(ress);
    setError2(ress3);
    setError3(ress2);
    if (!ress.rolename && !ress2.status) {
      // const formData = new FormData();
      // formData.append("en_name", rolename);

      // formData.append("permissions", per);

      // formData.append("status", status);
      // formData.append("multi_lang", lang);

      // axios
      //   .post(`${Config.apiurl}admin/roles`, {
      const res = await fetch(`${Config.apiurl}admin/roles/${id}`, {
        method: "PATCH",
        body: JSON.stringify({
          en_name: rolename,
          permissions: per,
          status: status,
          multi_lang: lang,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
      if (data.status_code !== "200" || !data) {
        setAlert(true);
        setAlertmessage(data.message);
        setOpen(true);
        setAlerttype("error");
      } else {
        setAlert(true);
        setAlertmessage(data.message);
        setOpen(true);
        setAlerttype("success");
        getData();
        Reset();
        setTimeout(() => navigate("/role_listing"), 3000);

      }
      // });
    }
  };

  useEffect(() => {
    getData();
    getDatalang();
    getinfo();
  }, []);
  console.log("lang",multilang);
  return (
    <div id="layout-wrapper">
     <Commonheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">EDIT ROLE </h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">DR Super Admin </Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="/role_listing">Role</Link>
                      </li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>
            {alert ? (
              <Alerts type={alerttype} message={alertmessage} open={open} />
            ) : (
              ""
            )}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header flex-column align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">Edit Role</h4>
                      <div className="flex-shrink-0">
                        <Link
                          type="button"
                          className="btn db-other-button btn-role"
                          to="/role_listing"
                          style={{ marginRight: "5px" }}
                        >
                          Role
                        </Link>
                        <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                          onClick={Reset}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target=""
                          onClick={PostData}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-translation-style">
                                <ul>
                                  <li
                                    className={active === "en" ? "active" : ""}
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => setActive("en")}
                                      className={
                                        active === "en" ? "active" : ""
                                      }
                                    >
                                      EN
                                    </Link>
                                  </li>
                                  <li
                                    className={
                                      active === "trans" ? "active" : ""
                                    }
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => setActive("trans")}
                                      className={
                                        active === "trans" ? "active" : ""
                                      }
                                    >
                                      Translations
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {role!==undefined ?
                            <>
                            {active === "en" ? (
                              <>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Name
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={rolename}
                                      onChange={(e) =>
                                        setRolename(e.target.value)
                                      }
                                    />
                                    <label style={{ color: "red" }}>
                                      {formerror.rolename}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Permission
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <MultiSelect
                                      options={permission}
                                      value={permissionval}
                                      onChange={setPermissionval}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      allowCustomValue={true}
                                    />
                                    <label style={{ color: "red" }}>
                                      {formerror2.selectedPermission}
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Status{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <select
                                      id="ForminputState"
                                      className="form-select"
                                      value={status}
                                      onChange={(e) =>
                                        setStatus(e.target.value)
                                      }
                                    >
                                      <option selected>Choose...</option>
                                      <option   {...(status === true
                                        ? "selected"
                                        : "")}
                                      value={true}>Active</option>
                                      <option  {...(status === false
                                        ? "selected"
                                        : "")}
                                      value={false}>Inactive</option>
                                    </select>
                                    <label style={{ color: "red" }}>
                                      {formerror3.status}
                                    </label>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="form-translation-choose-lang">
                                  <span
                                    for="firstNameinput"
                                    className="form-label db-lang-choose"
                                  >
                                    Choose Language:{" "}
                                  </span>
                                  <div className="lang-choose-select-option">
                                    <MultiSelect
                                      options={language}
                                      value={multilang}
                                      onChange={setMultilang}
                                      // labelledBy="Select"
                                      // selectionType="counter"
                                      // closeOnChangedValue={true}
                                      // aria-expanded="true"
                                      hasSelectAll={false}
                                    />
                                  </div>
                                </div>
                                <div className="appendmore"></div>
                                {multilang.length > 0
                                  ? multilang.map((element, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="col-md-12 translation-fill-section"
                                          id="lang_ +val"
                                          data-value="1"
                                        >
                                          <div className="translation-selected-lang">
                                            <p>
                                              <span className="translation-selected-lang-head">
                                                {element.code}
                                              </span>
                                            </p>
                                          </div>
                                          <div className="col-md-4">
                                            <div className="mb-3">
                                              <label
                                                for="firstNameinput"
                                                className="form-label"
                                              >
                                                Name
                                                <span className="form-validation-required">
                                                  *
                                                </span>
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={
                                                  element.title
                                                }
                                                onChange={
                                                  (e) =>
                                                    (element.title =
                                                      e.target.value)
                                                  // handletrans(
                                                  //   index,
                                                  //   e,
                                                  //   element.code
                                                  // )
                                                  // setRoletrans({
                                                  //   code: element.code,
                                                  //   names: e.target.value,
                                                  // })
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : ""}
                              </>
                            )}
                            </>
                            :<Loading/>}
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default Roleedit;
