import React, { useContext, useEffect, useState } from "react";
import Loyaltyheader from "../../Common/Loyaltyheader";
import Footer from "../../Common/Footer";
import Toastmodal from "../../Common/Toastmodal";
import Config from "../../Config";
import axios from "axios";
import { useAuthHandling } from "../../Common/useAuthHandling";
import { Link } from "react-router-dom";
import { UserContext } from "../../Contexts/UserContext";
import Nodatafound from "../../Common/Nodatafound";
import { MultiSelect } from "react-multi-select-component";
import Loading from "../../Loading";

function Loyaltydeduction() {
  const [deduction, setDeduction] = useState("");
  const [loyalpoint, setLoyalpoint] = useState("");
  const [cashback, setCashback] = useState("");
  const [cashvalue, setCashvalue] = useState("percentage");
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);

  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [error, setError] = useState({});
  const [error1, setError1] = useState({});
  const [currency, setCurrency] = useState("");
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [toastmodal, setToastmodal] = useState(false);
  const [list, setList] = useState([]);
  const [showFullData, setShowFullData] = useState([]);
  const [hotel, setHotel] = useState([]);
  const [selectedhotel, setSelectedHotel] = useState([]);
  const [hotelnew, setHotelnew] = useState([]);
  const [active, setActive] = useState(true);
  const [loader, setLoader] = useState(false);

  const handleDropdownClickhotel = (i) => {
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  const handleChange = (e, i) => {
    const { name, value } = e.target;
    const updatedList = [...list];
    updatedList[i] = {
      ...updatedList[i],
      loyalty_club: {
        ...updatedList[i].loyalty_club,
        [name]: value,
      },
    };
    setList(updatedList);
  };

  const getHotel = async (e) => {
    const res = await fetch(`${Config.apiurl}hotels`, {
      method: "GET",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setHotelnew(data);
    }
  };

  const Save = async (e) => {
    e.preventDefault();

    let isFormValid = true;

    const errors = {};
    if (selectedhotel.length === 0) {
      isFormValid = false;
      errors.selectedhotel = "This field is required!";
    }
    if (!loyalpoint) {
      isFormValid = false;
      errors.loyalpoint = "This field is required!";
    }
    if (!cashback) {
      isFormValid = false;
      errors.cashback = "This field is required!";
    }
    setError(errors);
    if (isFormValid === true) {
      let hotelId = [];
      for (let i = 0; i < selectedhotel.length; i++) {
        hotelId.push(selectedhotel[i].value);
      }

      const res = await fetch(`${Config.apiurl}hotel/loyalty-points/set`, {
        method: "POST",
        body: JSON.stringify({
          hotels: hotelId,
          loyalty_converter: loyalpoint,
          loyalty_convert_type: "percentage",
          cash_back: cashback,
          cash_back_type: "percentage",
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
      if (data.status_code !== "200" || !data) {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(false);
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(false);
        getview();
      }
    }
  };

  const SaveOneHotel = async (e) => {
    e.preventDefault();

    let isFormValid = true;

    const errors = {};
    for (let i = 0; i < list.length; i++) {
      if (!list[i].loyalty_club.loyalty_converter) {
        isFormValid = false;
        errors.loyalty_converter = "This field is required!";
      }
      if (!list[i].loyalty_club.cash_back) {
        isFormValid = false;
        errors.cash_back = "This field is required!";
      }
    }
    setError1(errors);

    if (isFormValid === true) {
      let loyal_point;
      let cashbacknew;
      for (let i = 0; i < list.length; i++) {
        loyal_point = list[i].loyalty_club.loyalty_converter;
        cashbacknew = list[i].loyalty_club.cash_back;
      }

      const res = await fetch(`${Config.apiurl}hotel/loyalty-points/set`, {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
          loyalty_converter: loyal_point,
          loyalty_convert_type: "percentage",
          cash_back: cashbacknew,
          cash_back_type: "percentage",
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
      if (data.status_code !== "200" || !data) {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(false);
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(false);
        getview();
      }
    }
  };

  // const Save = async () => {
  //   let isFormValid = true;

  //   const errors = {};
  //   if (!deduction) {
  //     isFormValid = false;
  //     errors.deduction = "Value is required!";
  //   }
  //   if (!loyalpoint) {
  //     isFormValid = false;
  //     errors.loyalpoint = "Value is required!";
  //   }
  //   setError(errors);
  //   if (isFormValid === true) {
  //     const formData = new FormData();
  //     formData.append("hotel_id", id);
  //     formData.append("value", deduction);
  //     formData.append("points", loyalpoint);
  //     if (cashback) {
  //       formData.append("cash_back", cashback);
  //       formData.append("unit_value", cashvalue);
  //     }

  //     axios
  //       .post(`${Config.apiurl}hotel/loyalty/deduction/update`, formData, {})
  //       .then((res) => {
  //         if (res.status_code !== "200" || !res) {
  //           setToastmodal(true);
  //           setToastmessage(res.message);
  //           setToasttype("error");
  //           setToastlarge(true);
  //           getview();
  //         } else {
  //           setToastmodal(true);
  //           setToastmessage(res.message);
  //           setToasttype("success");
  //           setToastlarge(true);
  //           getview();
  //         }
  //       });
  //   }
  // };

  const getview = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/loyalty-points/settings`, {
      method: "POST",
      body: JSON.stringify({
        hotels: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await res.json();
    if (data.status_code === "200") {
      setList(data.data);
      setLoader(true);
    } else if (data.reauth === true) {
      handleUnauthorized();
      getview();
    }
  };

  useEffect(() => {
    getview();
    getHotel();
  }, [id]);

  useEffect(() => {
    const matchedHotels = [];

    id.forEach((ids) => {
      const newHotel = hotelnew.find((hotel) => hotel.hotel_id === ids);
      if (newHotel) {
        matchedHotels.push({
          value: newHotel.hotel_id,
          label: newHotel.hotel_name,
        });
      }
    });

    setHotel(matchedHotels);
  }, [id, hotelnew]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (list.length > 0) {
      const initialState = list.map((item, index) => ({
        hotel: index === 0,
      }));
      setShowFullData(initialState);
    }
  }, [list.length]);

  const [symbolsrate] = useState(["e", "E", "+", "-"]);

  return (
    <div className="main-content">
      <Loyaltyheader />
      <div className="page-content">
        <h4 className="db-page-title" style={{ marginLeft: "6%" }}>
          Loyalty Point Settings
        </h4>
        <div className="container-fluid">
          <div style={{ marginTop: "1%" }}>
            <div className="card">
              {loader===false?
              <Loading/>
              :
              <div className="card-body">
                <div className="live-preview">
                  {/* <div className="page-title-box  align-items-center justify-content-between">
              <h4 className="db-page-title">Loyalty Point Settings</h4>
             </div>
            */}
                  <div className="row" style={{ display: "contents" }}>
                    {list.length > 1 ? (
                      <div
                        className="row"
                        style={{
                          borderBottom: "1px solid #d2d2d2",
                          paddingBottom: "2%",
                        }}
                      >
                        <div>
                          <span
                            className="bulk_update_service"
                            onClick={() => setActive(!active)}
                          >
                            BULK UPDATE
                          </span>
                          <span
                            className="bx bxs-chevron-down service_update_down_arrow"
                            onClick={() => setActive(!active)}
                          ></span>
                        </div>
                        {active === true ? (
                          <>
                            <div
                              className="col-md-4"
                              style={{ marginTop: "20px" }}
                            >
                              <div className="mb-3">
                                <div className="input-group">
                                  <label
                                    className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"
                                  >
                                    Hotels{" "}
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-9">
                                    <MultiSelect
                                      options={hotel}
                                      value={selectedhotel}
                                      onChange={setSelectedHotel}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      closeOnChangedValue={false}
                                      aria-expanded="true"
                                    />
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {error.selectedhotel}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-md-3"
                              style={{ marginTop: "20px" }}
                            >
                              <div className="input-group">
                                <div className="input-group-text input-joint-color">
                                  Loyalty Points
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                <span className="info-tag" style={{ backgroundColor: "black" }}>
                                        i
                                        <span className="tooltiptext">
                                        (the value you entered is converted to the loyalty points of total amount of reservation with taxes)
                                        </span>
                                      </span>
                                </div>
                                <input
                                  type="number"
                                  onKeyDown={(e) =>
                                    symbolsrate.includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  min={0}
                                  className="loyal_deduction_input"
                                  placeholder="Enter value"
                                  value={loyalpoint}
                                  onChange={(e) =>
                                    setLoyalpoint(e.target.value)
                                  }
                                  onFocus={(e) =>
                                    e.target.addEventListener(
                                      "wheel",
                                      function (e) {
                                        e.preventDefault();
                                      },
                                      { passive: false }
                                    )
                                  }
                                />
                                <div className="input-group-text input-joint-color loyal_deduction_country">
                                  %
                                </div>
                                <label style={{ color: "red" }}>
                                  {error.loyalpoint}
                                </label>
                                <br></br>
                                <br></br>
                              </div>
                            </div>
                            <div
                              className="col-md-3"
                              style={{ marginTop: "20px" }}
                            >
                              <div className="input-group input_loyal_deduction_con_2">
                                <div className="input-group-text input-joint-color">
                                  Cashback
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                  <span className="info-tag" style={{ backgroundColor: "black" }}>
                                          i
                                          <span className="tooltiptext">
                                          (the value you entered is converted to the cashback of that loyalty points)
                                          </span>
                                        </span>
                                </div>
                                <input
                                  type="number"
                                  onKeyDown={(e) =>
                                    symbolsrate.includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  min={0}
                                  className="loyal_deduction_input"
                                  style={{ padding: "0px 16px 0px 0px" }}
                                  placeholder="Enter value"
                                  value={cashback}
                                  onChange={(e) => setCashback(e.target.value)}
                                  onFocus={(e) =>
                                    e.target.addEventListener(
                                      "wheel",
                                      function (e) {
                                        e.preventDefault();
                                      },
                                      { passive: false }
                                    )
                                  }
                                />
                                <div className="input-group-text input-joint-color loyal_deduction_country">
                                  %
                                </div>
                                <label style={{ color: "red" }}>
                                  {error.cashback}
                                </label>
                                <br></br>
                                <br></br>
                              </div>
                            </div>
                           
                            <div
                              className="col-md-1"
                              style={{ marginTop: "30px" }}
                            >
                              <div
                                className="flex-shrink-0 d-flex justify-content-between"
                                style={{ float: "right" }}
                              >
                                <Link
                                  type="button"
                                  to="#"
                                  className="btn db-save-button"
                                  style={{ height: "30px" }}
                                  onClick={Save}
                                >
                                  Save
                                </Link>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {list?.map((data, i) => {
                      return (
                        <>
                          <div
                            className={
                              list.length > 1 ? "row hotel_single_list" : "row"
                            }
                            key={i}
                          >
                            {list.length > 1 ? (
                              <div className="hotel_service_display hotel_service_display_12">
                                <div className="hotel_service_display_14">
                                  <div className="hk_service_all_hotel">
                                    <span className=""> {i + 1}.</span>
                                    <span style={{ marginLeft: "10px" }}>
                                      {data.hotel_name}
                                    </span>
                                  </div>
                                </div>
                                {showFullData[i]?.hotel === true ? (
                                  <span
                                    className="db-modify_toggle_btn"
                                    onClick={() => handleDropdownClickhotel(i)}
                                  >
                                    <span
                                      className="bx bxs-chevron-up"
                                      style={{ fontSize: "20px" }}
                                    ></span>
                                  </span>
                                ) : (
                                  <span
                                    className="db-modify_toggle_btn"
                                    onClick={() => handleDropdownClickhotel(i)}
                                  >
                                    <span
                                      className="bx bxs-chevron-down"
                                      style={{ fontSize: "20px" }}
                                    ></span>
                                  </span>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            <div
                              className={`row ${
                                showFullData[i]?.hotel === true ? "" : "d-none"
                              }`}
                              style={{ marginTop: "1%", marginBottom: "2%" }}
                            >
                              {/* <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <h4 className="db-page-title">Loyalty Point Settings</h4>
                    </div>
                    <br></br>
                    <br></br> */}
                              {data.loyalty_club ? (
                                <>
                                  {list.length > 1 ? (
                                    <>
                                      <div className="d-flex justify-content-center">
                                        {/* <div>
                        <div className="input-group input_loyal_deduction_con_1">
                          <div
                            className="input-group-text input-joint-color"
                            style={{ width: "105px" }}
                          >
                            Loyalty Points
                          </div>
                          <input
                            type="number"
                            onKeyDown={(e) =>
                              symbolsrate.includes(e.key) && e.preventDefault()
                            }
                            min={0}
                            className="loyal_deduction_input"
                            placeholder="Enter value"
                            value={loyalpoint}
                            onChange={(e) => setLoyalpoint(e.target.value)}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </div>
                        <label style={{ color: "red", marginLeft: "20px" }}>
                          {error.deduction}
                        </label>
                      </div> */}
                                        <div>
                                          <div className="input-group input_loyal_deduction_con">
                                            <div className="input-group-text input-joint-color">
                                              Loyalty Points
                                            </div>
                                            <input
                                              type="number"
                                              onKeyDown={(e) =>
                                                symbolsrate.includes(e.key) &&
                                                e.preventDefault()
                                              }
                                              min={0}
                                              className="loyal_deduction_input"
                                              placeholder="Enter value"
                                              name="loyalty_converter"
                                              value={
                                                data.loyalty_club
                                                  .loyalty_converter
                                              }
                                              // onChange={(e)=>handleChange(e,i)}
                                              onFocus={(e) =>
                                                e.target.addEventListener(
                                                  "wheel",
                                                  function (e) {
                                                    e.preventDefault();
                                                  },
                                                  { passive: false }
                                                )
                                              }
                                            />
                                            <div className="input-group-text input-joint-color loyal_deduction_country">
                                              %
                                            </div>
                                            {/* <div className="common_discount_parts">
                        <div className="common_discount_select_loyalty_new">
                          <select
                            id="ForminputState"
                            className="form-select box_style_is"
                            name="loyalty_convert_type"
                            value={data.loyalty_club.loyalty_convert_type}
                            onChange={(e)=>handleChange(e,i)}
                            style={{ height: "44px" }}
                          >
                            <option value="currency">{data.currency.code}</option>
                            <option value="percentage" selected>%</option>
                          </select>
                        </div>
                      </div> */}
                                            <br></br>
                                            <br></br>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="d-flex justify-content-center"
                                        style={{ marginTop: "3%" }}
                                      >
                                        <h4
                                          className=""
                                          style={{ textTransform: "uppercase" }}
                                        >
                                          Guest Online Portal
                                        </h4>
                                      </div>

                                      <div
                                        className="d-flex justify-content-center"
                                        style={{ marginTop: "2%" }}
                                      >
                                        <div
                                          className="input-group input_loyal_deduction_con_2"
                                          style={{ marginLeft: "20px" }}
                                        >
                                          <div className="input-group-text input-joint-color">
                                            Cashback
                                          </div>
                                          <input
                                            type="number"
                                            onKeyDown={(e) =>
                                              symbolsrate.includes(e.key) &&
                                              e.preventDefault()
                                            }
                                            min={0}
                                            className="loyal_deduction_input"
                                            style={{
                                              padding: "0px 16px 0px 0px",
                                            }}
                                            placeholder="Enter value"
                                            name="cash_back"
                                            value={data.loyalty_club.cash_back}
                                            // onChange={(e)=>handleChange(e,i)}
                                            onFocus={(e) =>
                                              e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                  e.preventDefault();
                                                },
                                                { passive: false }
                                              )
                                            }
                                          />
                                          <div className="input-group-text input-joint-color loyal_deduction_country">
                                            %
                                          </div>
                                          {/* <div className="common_discount_parts">
                        <div className="common_discount_select_loyalty_new">
                          <select
                            id="ForminputState"
                            className="form-select box_style_is"
                            name="cash_back_unit_value"
                            value={data.loyalty_club.cash_back_unit_value}
                            onChange={(e)=>handleChange(e,i)}
                            style={{ height: "44px" }}
                          >
                            <option value="currency">{data.currency.code}</option>
                            <option value="percentage" selected>%</option>
                          </select>
                        </div>
                      </div> */}
                                          <br></br>
                                          <br></br>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div>
                                        <div
                                          className="flex-shrink-0 d-flex justify-content-between"
                                          style={{ float: "right" }}
                                        >
                                          <Link
                                            type="button"
                                            to="#"
                                            className="btn db-save-button"
                                            style={{ height: "30px" }}
                                            onClick={SaveOneHotel}
                                          >
                                            Save
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="d-flex" style={{marginTop:"3%",marginLeft:"15%"}}>
                                        <div>
                                          <div className="input-group input_loyal_deduction_con">
                                            <div className="input-group-text input-joint-color">
                                              Loyalty Points
                                            </div>
                                            <input
                                              type="number"
                                              onKeyDown={(e) =>
                                                symbolsrate.includes(e.key) &&
                                                e.preventDefault()
                                              }
                                              min={0}
                                              className="loyal_deduction_input"
                                              placeholder="Enter value"
                                              name="loyalty_converter"
                                              value={
                                                data.loyalty_club
                                                  .loyalty_converter
                                              }
                                              onChange={(e) =>
                                                handleChange(e, i)
                                              }
                                              onFocus={(e) =>
                                                e.target.addEventListener(
                                                  "wheel",
                                                  function (e) {
                                                    e.preventDefault();
                                                  },
                                                  { passive: false }
                                                )
                                              }
                                            />
                                            <div className="input-group-text input-joint-color loyal_deduction_country">
                                              %
                                            </div>

                                            <label style={{ color: "red" }}>
                                              {error1.loyalty_converter}
                                            </label>

                                            <br></br>
                                            <br></br>
                                          </div>
                                        </div>
                                        <span className="loyalty_points_desc">(the value you entered is converted to the loyalty points of total amount of reservation with taxes)</span>
                                      </div>
                                      <div
                                        className=""
                                        style={{ marginTop: "3%" ,marginLeft:"18%"}}
                                      >
                                        <h4
                                          className=""
                                          style={{ textTransform: "uppercase" }}
                                        >
                                          Guest Online Portal
                                        </h4>
                                      </div>

                                      <div
                                        className="d-flex"
                                        style={{ marginTop: "2%",marginLeft:"15%" }}
                                      >
                                        <div
                                          className="input-group input_loyal_deduction_con_2"
                                          style={{ marginLeft: "20px" }}
                                        >
                                          <div className="input-group-text input-joint-color">
                                            Cashback
                                          </div>
                                          <input
                                            type="number"
                                            onKeyDown={(e) =>
                                              symbolsrate.includes(e.key) &&
                                              e.preventDefault()
                                            }
                                            min={0}
                                            className="loyal_deduction_input"
                                            style={{
                                              padding: "0px 16px 0px 0px",
                                            }}
                                            placeholder="Enter value"
                                            name="cash_back"
                                            value={data.loyalty_club.cash_back}
                                            onChange={(e) => handleChange(e, i)}
                                            onFocus={(e) =>
                                              e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                  e.preventDefault();
                                                },
                                                { passive: false }
                                              )
                                            }
                                          />
                                          <div className="input-group-text input-joint-color loyal_deduction_country">
                                            %
                                          </div>
                                          <label style={{ color: "red" }}>
                                            {error1.cash_back}
                                          </label>
                                          <br></br>
                                          <br></br>
                                        </div>
                                        <span className="loyalty_points_desc">(the value you entered is converted to the cashback of that loyalty points)</span>
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : (
                                <Nodatafound />
                              )}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            }
            </div>
          </div>
        </div>
      </div>
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
      <Footer />
    </div>
  );
}

export default Loyaltydeduction;
