import React from 'react';

const CustomOption = ({ value, label, onClick }) => (
  <div className="custom-option" onClick={() => onClick(value)}>
    <label>
      <input type="checkbox" checked={value.isSelected} readOnly /> {label}
    </label>
  </div>
);

export default CustomOption;