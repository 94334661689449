import React, { useState } from "react";
import Footer from "../../Common/Footer";
import { Link } from "react-router-dom";
import Commonheader from "../../Common/Commonheader";
import Basicsettingsheader from "../../Common/Basicsettingsheader";
function Servicefrequencylist() {
  return (
    <div id="layout-wrapper">
      <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Service Frequencies</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Basic Settings</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Service Frequencies
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create Page
                      </h4>
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target=""
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-5">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Service{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label
                                  for="lastNameinput"
                                  className="form-label"
                                >
                                  Frequency
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-5">
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                min="1"
                                max="5"
                                value="Cleaning"
                              />
                            </div>
                            <div className="col-md-4">
                              <select
                                id="ForminputState"
                                className="form-select"
                              >
                                <option>Choose..</option>
                                <option selected>By Request</option>
                                <option>Daily</option>
                                <option>Weekly</option>
                                <option>Once in a week </option>
                              </select>
                            </div>
                          </div>
                          <div className="padding-10"></div>
                          <div className="row">
                            <div className="col-md-5">
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                min="1"
                                max="5"
                                value="Change Towels"
                              />
                            </div>
                            <div className="col-md-4">
                              <select
                                id="ForminputState"
                                className="form-select"
                              >
                                <option>Choose..</option>
                                <option>By Request</option>
                                <option selected>Daily</option>
                                <option>Weekly</option>
                                <option>Once in a week </option>
                              </select>
                            </div>
                          </div>
                          <div className="padding-10"></div>
                          <div className="row">
                            <div className="col-md-5">
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                min="1"
                                max="5"
                                value="Change of bed linen"
                              />
                            </div>
                            <div className="col-md-4">
                              <select
                                id="ForminputState"
                                className="form-select"
                              >
                                <option>Choose..</option>
                                <option selected>By Request</option>
                                <option>Daily</option>
                                <option>Weekly</option>
                                <option>Once in a week </option>
                              </select>
                            </div>
                          </div>
                          <div className="padding-10"></div>
                          <div className="row">
                            <div className="col-md-5">
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                min="1"
                                max="5"
                                value="Pillow menu"
                              />
                            </div>
                            <div className="col-md-4">
                              <select
                                id="ForminputState"
                                className="form-select"
                              >
                                <option>Choose..</option>
                                <option>By Request</option>
                                <option selected>Daily</option>
                                <option>Weekly</option>
                                <option>Once in a week </option>
                              </select>
                            </div>
                          </div>
                          <div className="padding-10"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default Servicefrequencylist;
