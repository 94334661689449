import React, { useContext, useEffect, useState } from "react";
import Commonheader from "../../Common/Commonheader";
import Config from "../../Config";
import Select from "react-select";
import { Line } from "react-chartjs-2";
import LinebarChart from "../../../chart/LinebarChart";

function BI() {
  const [formerror, setError] = useState({});
  const [bookingperiod, setBookingperiod] = useState([]);
  const [comparisonPeriod, setComparisonPeriod] = useState([]);
  const bookingOptions = [
    { value: "Last_7_days", label: "Last 7 days" },
    { value: "Last_30_days", label: "Last 30 days" },
    { value: "Last_90_days", label: "Last 90 days" },
    { value: "Last_180_days", label: "Last 180 days" },
    { value: "Last_365_days", label: "Last 365 days" },
  ];
  const comparisonOptions = [
    { value: "Previous_week", label: "Previous week" },
    { value: "Previous_month", label: "Previous month" },
    { value: "Same_period ", label: "Same period last year" },
    { value: "Last_calendar_year ", label: "Last calendar year" },
  ];

  const timeOptions = [
    { value: "days", label: "Days" },
    { value: "months", label: "Months" },
    { value: "years", label: "Years" },
  ];

  useEffect(() => {
    // Simulate fetching data or any updates needed for the chart
    const fetchData = async () => {
      // Fetch or compute your data here, then update the state
      // Example: const newData = await fetch('/api/chart-data');
      // setChartData(newData);
    };
    fetchData();
  }, []);
  // Handler for Booking dropdown change
  const handleBookingChange = (selectedOption) => {
    setBookingperiod(selectedOption);
  };

  // Handler for Comparison dropdown change
  const handleComparisonChange = (selectedOption) => {
    setComparisonPeriod(selectedOption);
  };

  return (
    <div>
      <Commonheader />{" "}
      <div className="main-content" style={{ backgroundColor: "white" }}>
        <div className="page-content">
          <div className="container">
            <div className="mt-10">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="db-page-title" style={{ color: "#008080" }}>
                      Analytics Dashboard
                    </h4>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="card">
                <div className="card-body">
                  <div className="live-preview">
                    <div className="col-12">
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          alignItems: "center",
                        }}
                      >
                        <div className="col-lg-4">
                          <div className="input-group">
                            <label
                              className="input-group-text input-joint-color"
                              id="inputGroup-sizing-default"
                            >
                              Booking Within :
                            </label>
                            <div className="col-md-7">
                              <Select
                                placeholder="Select..."
                                className="form-control multiselect_extra_based"
                                data-choices
                                name="choices-single-default"
                                id="choices-single-default"
                                value={bookingperiod}
                                onChange={handleBookingChange}
                                options={bookingOptions}
                              ></Select>
                            </div>
                            <label style={{ color: "red", fontSize: "14px" }}>
                              {formerror.username}
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="input-group">
                            <label
                              className="input-group-text input-joint-color"
                              id="inputGroup-sizing-default"
                            >
                              Comparison Within :
                            </label>
                            <div className="col-md-7">
                              <Select
                                placeholder="Select..."
                                className="form-control multiselect_extra_based"
                                data-choices
                                name="choices-single-default"
                                id="choices-single-default"
                                options={comparisonOptions}
                                value={comparisonPeriod}
                                onChange={handleComparisonChange}
                              ></Select>
                            </div>
                            <label style={{ color: "red", fontSize: "14px" }}>
                              {formerror.username}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h6 className="db-page-title" style={{ color: "#008080" }}>
                    Performance Overview
                  </h6>
                </div>
              </div>
              <div className="col-xxl-12">
                <div className="reservation-filter-results">
                  <table className="table reservation-list-table">
                    <thead>
                      <tr>
                        <th></th>
                        <th
                          className="reserv-style-header-new"
                          title="Reservations"
                        >
                          Based on bookings in the past 365 days
                        </th>
                        <th
                          className="reserv-style-header-new"
                          title="Average Nights per Room"
                        >
                          Based on bookings for next 365 days
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p className="bottom-0 ">Room nights</p>
                          <p className="small-silv">(Last year)</p>
                        </td>
                        <td>
                          <p className="bottom-0 ">12,391</p>
                          <p className="small-green">
                            (Last year){" "}
                            <span className="small-silv">(1,934)</span>
                          </p>
                        </td>
                        <td>
                          <p className="bottom-0 ">540</p>
                          <p className="small-red">
                            -34.33% <span className="small-silv">(1,934)</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="bottom-0 ">Room revenue</p>
                          <p className="small-silv">(Last year)</p>
                        </td>
                        <td>
                          <p className="bottom-0 ">AED 4,234,040.99</p>
                          <p className="small-red">
                            -0.33%{" "}
                            <span className="small-silv">
                              (AED 4,634,040.99)
                            </span>
                          </p>
                        </td>

                        <td>
                          <p className="bottom-0 ">AED 4,234,040.99</p>
                          <p className="small-red">
                            -0.33%{" "}
                            <span className="small-silv">
                              (AED 4,634,040.99)
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="bottom-0 ">Average daily rate</p>
                          <p className="small-silv">(Last year)</p>
                        </td>

                        <td>
                          <p className="bottom-0 ">AED 4,234,040.99</p>
                          <p className="small-red">
                            -70.33%{" "}
                            <span className="small-silv">(AED 444,040.99)</span>
                          </p>
                        </td>
                        <td>
                          <p className="bottom-0 ">AED 4,234,040.99</p>
                          <p className="small-red">
                            -10.33%{" "}
                            <span className="small-silv">(AED 534,040.99)</span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <h4 className="db-page-title pad-20-0" style={{ color: "#008080" }}>
              Pace Report
            </h4>
            <div className="mar-top-graph">
              <div>
                {/* <Line data={chartData} options={options} /> */}
                <LinebarChart />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BI;
