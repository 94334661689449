import React, { useEffect, useState } from 'react'
import Footer from '../../Common/Footer';
import { MultiSelect } from 'react-multi-select-component';
import { Link, useNavigate } from 'react-router-dom';
import Alerts from "../../Alerts";
import Config from "../../Config";
import Commonheader from '../../Common/Commonheader';
import Basicsettingsheader from '../../Common/Basicsettingsheader';

function Paymentmethodcreate() {
  let navigate = useNavigate();

    const [selectedMulti, setselectedMulti] = useState([]);
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }
  const [language, setLanguage] = useState([]);
  const [method, setMethod] = useState("");
  const [status, setStatus] = useState("");
  const [active, setActive] = useState("en");
  const [roletrans, setRoletrans] = useState({});
  const [alert, setAlert] = useState(false);
  const [datas, setDatas] = useState([]);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState("");

  const [formerror, setError] = useState({});
  const [formerror3, setError3] = useState({});

  const getDatalang = async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setLanguage(data);
    }
  };
  const trnsarray = [];
  function handletrans(i, e, val) {
    const trnas = roletrans;
    setRoletrans({
      code: val,
      name: e.target.value,
    });
    trnas.code = val;
    trnas.name = e.target.value;
    trnsarray.push(trnas);
  }

  function Reset() {
    setLanguage("");
    setMethod("");
    setStatus("");
    setFlag("");
    setselectedMulti("");
  }

  const validate = (values) => {
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-,/,%,(,)]+$/;

    const errors = {};
    if (!values) {
      errors.method = "Name is required!";
    }
    else if (!noSpecialCharRegex.test(values)) {
      errors.method = "Name should not contain this special character";
    }
    else if (values.length > 50) {
      errors.method = `Name cannot be more than 50 characters!`;
    }
    return errors;
  };
  
  const validate1 = (values,flag) => {
    const errors = {};
    if (!values) {
      errors.status = "Status is required!";
    }
    if (!flag) {
      errors.flag = "Flag is required!";
    }
    return errors;
  };

  const PostData = async (e) => {
    e.preventDefault();
    const ress = validate(method);
    const ress2 = validate1(status,flag);
   
    let lang = [];

    for (let i = 0; i < selectedMulti.length; i++) {
      if (selectedMulti[i].name) {
        if (selectedMulti[i].code !== "en") {
          let multiarray = {};
          multiarray.lang_code = selectedMulti[i].code;
          multiarray.payment_method = selectedMulti[i].name;

          lang.push(multiarray);
        }
      }
    }
    setError(ress);
    setError3(ress2);

    if (!ress.method && !ress2.status && !ress2.flag) {
   
      const res = await fetch(`${Config.apiurl}payment-method/add`, {
        method: "POST",
        body: JSON.stringify({
          en_payment_method: method,
          cancalation_flag:flag,
          is_active: status,
          multi_lang: lang,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
      if (data.status_code !== "200" || !data) {
        setAlert(true);
        setAlertmessage(data.message);
        setOpen(true);
        setAlerttype("error");
      } else {
        setAlert(true);
        setAlertmessage(data.message);
        setOpen(true);
        setAlerttype("success");
        setTimeout(() => navigate("/basic_settings/payment-method-list"), 3000);
        
        Reset();
      }
      
    }
  };

  useEffect(() => {
   
    getDatalang();
  }, []);
  return (
    <div id="layout-wrapper">
      <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Payment Method </h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Settings</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Basic Settings </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Payment Settings</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="/role_listing">Payment Method</Link>
                      </li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>
            {alert ? (
              <Alerts type={alerttype} message={alertmessage} open={open} />
            ) : (
              ""
            )}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create New Payment Method
                      </h4>
                      <div className="flex-shrink-0">
                        <Link
                          type="button"
                          className="btn db-other-button "
                          to="/basic_settings/payment-method-list"
                          style={{ marginRight: "5px" }}
                        >
                         Payment Method
                        </Link>
                        <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                          onClick={Reset}
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target=""
                          onClick={PostData}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-translation-style">
                                <ul>
                                  <li
                                    className={active === "en" ? "active" : ""}
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => setActive("en")}
                                      className={
                                        active === "en" ? "active" : ""
                                      }
                                    >
                                      EN
                                    </Link>
                                  </li>
                                  <li
                                    className={
                                      active === "trans" ? "active" : ""
                                    }
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => setActive("trans")}
                                      className={
                                        active === "trans" ? "active" : ""
                                      }
                                    >
                                      Translations
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {active === "en" ? (
                              <>
                              <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Name
                            <span className="form-validation-required">*</span>
                              </label>
                              <input type="text" className="form-control" placeholder="" value={method} onChange={(e) =>setMethod(e.target.value) }/>
                               </div>
                               <label style={{ color: "red" }}>{formerror.method}</label>                             
                            </div>

                            <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Cancellation Flag
                            <span className="form-validation-required">*</span>
                              </label>
                              <select id="ForminputState" className="form-select" value={flag} onChange={(e) =>setFlag(e.target.value)}>
                                      <option selected>Choose...</option>
                                      <option value="1">Active</option>
                                      <option value="0">Inactive</option>
                                    </select>             
                               </div>
                               <label style={{ color: "red" }}>{formerror3.flag} </label> 
                            </div>

                            <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Status
                            <span className="form-validation-required">*</span>
                              </label>
                              <select id="ForminputState" className="form-select" value={status} onChange={(e) =>setStatus(e.target.value)}>
                                      <option selected>Choose...</option>
                                      <option value="1">Active</option>
                                      <option value="0">Inactive</option>
                                    </select>
                               </div>
                               <label style={{ color: "red" }}>{formerror3.status} </label>                            

                            </div>
                                {/* <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Name
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input type="text" className="form-control" placeholder="" value={method} onChange={(e) =>setMethod(e.target.value) }/>
                                    <label style={{ color: "red" }}>{formerror.method}</label>
                                  </div>
                                </div> */}
                            

                                {/* <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Status{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <select id="ForminputState" className="form-select" value={status} onChange={(e) =>setStatus(e.target.value)}>
                                      <option selected>Choose...</option>
                                      <option value="1">Active</option>
                                      <option value="0">Inactive</option>
                                    </select>
                                    <label style={{ color: "red" }}>{formerror3.status} </label>
                                  </div>
                                </div> */}
                              </>
                            ) : (
                              <>
                                {/* <div className="form-translation-choose-lang">
                                  <span
                                    for="firstNameinput"
                                    className="form-label db-lang-choose"
                                  >
                                    Choose Language:{" "}
                                  </span>
                                  <div className="lang-choose-select-option">
                                    <MultiSelect
                                      options={language}
                                      value={selectedMulti}
                                      onChange={setselectedMulti}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      closeOnChangedValue={true}
                                      aria-expanded="true"
                                    />
                                  </div>
                                </div> */}
                                 <div class="col-lg-4" style={{marginTop:"20px"}}>
                                        <div className="input-group">
                                          <label className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default" style={{width:"130px"}}> Choose Language:</label>
                                        <div className="col-md-7">
                                        <MultiSelect
                                      options={language}
                                      value={selectedMulti}
                                      onChange={setselectedMulti}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      closeOnChangedValue={true}
                                      aria-expanded="true"
                                    />
                                      </div>
                                </div>
                                </div>
                                <div className="appendmore"></div>
                                {selectedMulti.length > 0
                                  ? selectedMulti.map((element, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="col-md-12 translation-fill-section"
                                          id="lang_ +val" data-value="1" style={{marginTop:"20px"}}>
                                          <div className="translation-selected-lang">
                                            <p>
                                              <span className="translation-selected-lang-head">
                                                {element.code}
                                              </span>
                                            </p>
                                          </div>
                                          <div class="col-lg-4">
                                          <div className="input-group">
                                        <label className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default">Name
                                        <span className="form-validation-required">*</span>
                                          </label>
                                          <input type="text" className="form-control" value={selectedMulti[index].name} onChange={(e) =>(selectedMulti[index].name =e.target.value)
                                          }/>
                                          </div>
                                        </div>
                                          {/* <div className="col-md-4">
                                            <div className="mb-3">
                                              <label
                                                for="firstNameinput"
                                                className="form-label"
                                              >
                                                Name
                                                <span className="form-validation-required">
                                                  *
                                                </span>
                                              </label>
                                              <input type="text" className="form-control" value={selectedMulti[index].name} onChange={(e) =>(selectedMulti[index].name =e.target.value)
                                                  handletrans(
                                                    index,
                                                    e,
                                                    element.code
                                                  )
                                                  setRoletrans({
                                                    code: element.code,
                                                    names: e.target.value,
                                                  })
                                                }
                                              />
                                            </div>
                                          </div> */}
                                        </div>
                                      );
                                    })
                                  : ""}
                              </>
                            )}
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  )
  
}

export default Paymentmethodcreate