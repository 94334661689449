import React, { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import { useState } from "react";
import { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useAuthHandling } from "../../../Common/useAuthHandling";
import { UserContext } from "../../../Contexts/UserContext";
import Config from "../../../Config";
import Commonheader from "../../../Common/Commonheader";
import Toastmodal from "../../../Common/Toastmodal";
import Footer from "../../../Common/Footer";
import Loading from "../../../Loading";

function Childpolicyedit() {
  let navigate = useNavigate();
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const { updateHotel_select, hotel_select } = useContext(UserContext);

  const [language, setLanguage] = useState([]);
  const [selectedMulti1, setselectedMulti1] = useState([]);
  const [tab, setTab] = useState("EN");
  const [active, setActive] = useState(false);
  const [childpolicy, setChildpolicy] = useState('');
  const [status, setStatus] = useState(true);

  let params = useParams();
  const [id, setId] = useState(params.type_id);
  const [hotelid, setHotelid] = useState(params.hotel_id);

  const [formerror, setError] = useState({});

  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [loader, setLoader] = useState(false);

  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [symbolsrate] = useState(["e", "E", "+", "-"]);
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);

  function tog_animationFlip() {
    setmodal_animationFlip(!modal_animationFlip);
  }



 
 

  const getValue = async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setLanguage(data.data);
    }
  };


  const getinfo = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/child/policy/view`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: hotelid,
        policy_id:id
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data1 = await res.json();

    if (data1.status_code === "200") {
      const data = data1.data;
      setChildpolicy(data.policy.description);
      setStatus(data.policy.status);
      setselectedMulti1(data.multi_lang);
      setLoader(true);
    } else if (data1.reauth === true) {
      handleUnauthorized();
      getinfo();
    } else {
      console.log("error");
    }
  };


  useEffect(() => {
    getValue();
    getinfo();
  }, []);
console.log(status);
console.log(childpolicy);
console.log(selectedMulti1);

  
  let Update = async () => {
    let isFormValid = true;

    const errors = {};
    if (!childpolicy) {
      isFormValid = false;

      errors.childpolicy = "Child policy is required!";
    }
    setError(errors);

    if (isFormValid === true) {
     
      let lang = [];
      for (let i = 0; i < selectedMulti1.length; i++) {
        if (selectedMulti1[i].name) {
          if (selectedMulti1[i].lang_code !== "en") {
            let multiarray = {};
            multiarray.lang_code = selectedMulti1[i].lang_code;
            multiarray.policy_text = selectedMulti1[i].description;

            lang.push(multiarray);
          }
        }
      }
      const res = await fetch(`${Config.apiurl}hotel/child/policy/update`, {
        method: "POST",
        body: JSON.stringify({
            hotel_id:hotelid,
            policy_id: id,
            policy_data:childpolicy,
            status:status,
            multi_lang:lang
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
      if (data.status_code === "200") {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(false);
        setTimeout(() => navigate("/child-policies"), 3000);
      } else if (data.reauth === true) {
        handleUnauthorized();
        Update();
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(false);
      }
    }
  };



  return (
    <div className="main-content">
      <Commonheader />
      <div className="page-content">
        <div className="container-fluid">
          <div className="row project-wrapper">
            <div className="row dashboard-top-tab-style">
              <div className="col-xxl-12 dashboard-main-style">
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center gy-3">
                      <div className="col-sm">
                        <h5 className="card-title mb-0">Edit Child policy</h5>
                      </div>
                      <div className="col-sm-auto">
                        <div className="d-flex gap-1 flex-wrap">
                          <Link className="btn  db-other-button" to="/child-policies">
                            Child policy
                          </Link>
                          {/* <button
                            type="button"
                            className="btn db-cancel-button"
                          >
                            Cancel
                          </button> */}
                          <button
                            className="btn db-save-button"
                            onClick={Update}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="live-preview">
                      <form action="#">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-translation-style">
                              <ul>
                                <li
                                  className={tab === "EN" ? "active" : ""}
                                  onClick={(e) => setTab("EN")}
                                >
                                  EN
                                </li>
                                <li
                                  className={tab === "TRANS" ? "active" : ""}
                                  onClick={(e) => setTab("TRANS")}
                                >
                                  Translations
                                </li>
                              </ul>
                            </div>
                          </div>

                          {tab === "EN" ? (
                            <>
                              <div className="row g-3">
                               
                                {loader===false?
                                <Loading/>
                                :
                                <>
                                <div className="col-lg-8">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Child policy
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="childpolicy"
                                      value={childpolicy}
                                      onChange={(e)=>setChildpolicy(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Status
                            <span className="form-validation-required">*</span>
                              </label>
                                        <select
                                          id="ForminputState"
                                          className="form-select"
                                          value={status}
                                          onChange={(e)=>setStatus(e.target.value)}
                                         
                                        >
                                          <option selected>Choose...</option>
                                          <option {...(status === true
                                        ? "selected"
                                        : "")}
                                      value={true}  >Active</option>
                                          <option {...(status === false
                                        ? "selected"
                                        : "")}
                                      value={false} >Inactive</option>
                                        </select>
                                       
                                      </div>
                                 </div>
                                 </>
                                }
                              
                              </div>
                             
                            </>
                          ) : (
                            <>
                              <div className="col-lg-4">
                                <div className="input-group">
                                  <label
                                    className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"
                                    style={{ width: "130px" }}
                                  >
                                    Choose Language
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-7">
                                    <MultiSelect
                                      options={language}
                                      value={selectedMulti1}
                                      onChange={(val) => setselectedMulti1(val)}
                                   
                                      labelledBy="Select"
                                      selectionType="counter"
                                      closeOnChangedValue={true}
                                      aria-expanded="true"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="appendmore"></div>
                              {selectedMulti1.length > 0
                                ? selectedMulti1.map((element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="col-md-12 translation-fill-section"
                                        id="lang_ +val"
                                        data-value="1"
                                        style={{ marginTop: "20px" }}
                                      >
                                        <div className="translation-selected-lang">
                                          <p>
                                            <span className="translation-selected-lang-head">
                                              {element.code}
                                            </span>
                                          </p>
                                        </div>
                                        <div className="col-lg-4">
                                          <div className="input-group">
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                            >
                                              Child policy
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              value={element.description}
                                              onChange={(e) =>
                                                setselectedMulti1(
                                                  (prevsetselectedMulti1) =>
                                                    prevsetselectedMulti1.map(
                                                      (item, i) =>
                                                        i === index
                                                          ? {
                                                              ...item,
                                                              description: e.target
                                                                .value,
                                                            }
                                                          : item
                                                    )
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : ""}
                            </>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <Footer />

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Childpolicyedit;
