import React, { useEffect } from 'react'
import { MultiSelect } from "react-multi-select-component";
import { useState } from 'react';
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import Footer from '../../Common/Footer';
import { Link } from 'react-router-dom';
import Commonheader from '../../Common/Commonheader';
import { FaArrowTurnDown } from "react-icons/fa6";
import Config from '../../Config';
import ReactPaginate from 'react-paginate';
import Select from "react-select";
import Loading from '../../Loading';
import Nodatafound from '../../Common/Nodatafound';

function Individual() {

  const [list, setList] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [page,setPage]=useState(1);
  const [pageindex,setPageindex]=useState(1);
  const [star,setStar]=useState([]);
  const [selectedcategory,setSelectedcategory]=useState([]);
  const [searchlist,setSearchlist]=useState([]);
  const [loader,setLoader]=useState(true);

  const handleSelectChange = async(selectedOption) => {
      setSelectedcategory(selectedOption);
        const response = await fetch(
           `${Config.apiurl}hotel/category/search`,{
         
            method:'POST',
            body: JSON.stringify({
              category: selectedOption.value
            }),
            headers:{
              Authorization:`Bearer ${localStorage.getItem('token')}`,
              "Content-type": "application/json; charset=UTF-8",

            }
           }
        );  
           if(response.status===200){
            setLoader(false);
           const data = await response.json();

            setSearchlist(data.data);
          }
           else{console.log("error");
          }
  };

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}individual-hotels/list?page=${currentPage}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const data = await res.json();
    return data.data.data;
  };


  const handlePageClick = async (data) => {
    if(data===1){
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
      setList(commentsFormServer);
    }
    else{
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
      setList(commentsFormServer);
    }
  };

  const getstar = async (e) => {
    const res = await fetch(`${Config.apiurl}categories`, {
      method: "GET",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setStar(data);
    }
  }



const fetchPost = async () => {
  const response = await fetch(
       `${Config.apiurl}individual-hotels/list?page=1`,{
        method:'POST',
        headers:{
          Authorization:`Bearer ${localStorage.getItem('token')}`
        }
       }
    );
    const data = await response.json();
    console.log(data);
       if(data.status_code==="200")
       { 
        setLoader(false);

        setList(data.data.data);
        setPaginate(data.data.last_page);
      }
       else{console.log("error");}
 };

    const [modal_backdrop, setmodal_backdrop] = useState(false);
      function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop);
      }

      const [modal_animationFlip, setmodal_animationFlip] = useState(false);
      function tog_animationFlip() {
        setmodal_animationFlip(!modal_animationFlip);
      }
    
      useEffect(() => {
        fetchPost();
        getstar();
        }, []);

function hotelset(name,id){
    localStorage.setItem("selected-hotel",name)
    localStorage.setItem("selected-hotel-id",id)
}
console.log(selectedcategory);

console.log(searchlist);
  return (
    <>
    <Commonheader/>
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                       
                    </div>
                </div>
            </div>
            <div className="row project-wrapper">
                <div className="row dashboard-top-tab-style">
                    <div className="col-lg-12">
                        <div className="card" id="orderList">
                            <div className="card-header border-0">
                                <div className="row align-items-center gy-3">
                                    <div className="col-sm">
                                        <h5 className="card-title mb-0">List Of Individual Hotel</h5>
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="input-group">
                                        <label className="input-group-text input-joint-color">Select Category</label>
                                           <Select className="form-control" id="exampleInputUsername1"
                                              options={star}
                                              value={selectedcategory}
                                              onChange={handleSelectChange}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <div><ul className="nav nav-tabs nav-tabs-custom nav-success mb-3" role="tablist"></ul>
                                    <div className="table-responsive table-card mb-1">
                                        <table id="fixed-header orderTable" className="table table-nowrap">
                                            <thead className="text-muted table-light">
                                                <tr className="text-uppercase">
                                                    <th className="" data-sort="id">SL</th>
                                                    <th className="chain_group_size" data-sort="customer_name">Hotel</th>
                                                    <th className="" data-sort="status">Status</th>
                                                    <th></th>
                                                    {/* <th className="" data-sort="city">Action</th> */}
                                                </tr>
                                            </thead>
                                            {loader===true?
                                             <tr style={{ border: "0px #ffff"}}>
                                             <td colSpan="4" style={{ textAlign: 'center' }}>  <Loading/></td>
                                           </tr>
                                          
                                    :                                      
                            <tbody className="list form-check-all">
                                  {selectedcategory.label?

                                    searchlist.length>0?

                                searchlist.map((data, i) => {
                                       return (
                                      <tr key={i}>
                                        <td className="id">{pageindex===1 ? i+1: (pageindex-1) * 10 + i +1}</td>
                                        <td className="hotel-new" onClick={(e)=>hotelset(data.hotel_name,data.hotel_id)}>{data.hotel_name}</td>
                                        <td className="status">
                                           {data.hotel_status ===  false ? (
                                           <span class="badge badge-soft-danger text-uppercase">
                                            {" "}
                                            INACTIVE
                                           </span>
                                            ) : (
                                           <span className="badge badge-soft-success text-uppercase">
                                            ACTIVE
                                            </span>
                                             )}
                                        </td>
                                      {/* <td><ul className="list-inline hstack gap-2 mb-1">
                                        <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Remove">
                                          <Link
                                            className="text-danger d-inline-block remove-item-btn"
                                            data-bs-toggle="modal"
                                            to="#"
                                            onClick={(e) =>tog_animationFlip()}>
                                            <i className="ri-delete-bin-5-fill fs-16"></i>
                                          </Link>
                                        </li>
                                      </ul>
                                    </td> */}
                                  </tr>
                                );
                              }):
                             <Nodatafound/>
                              :

                              list.length>0?
                                list.map((user, index) => {
                                       return (
                                      <tr key={index}>
                                        <td className="id">{pageindex===1 ? index+1: (pageindex-1) * 10 + index +1}</td>
                                        <td className="hotel-new" onClick={(e)=>hotelset(user.hotel_name,user.hotel_id)}>{user.hotel_name}</td>
                                        <td className="status">
                                           {user.hotel_status ===  false ? (
                                           <span class="badge badge-soft-danger text-uppercase">
                                            {" "}
                                            INACTIVE
                                           </span>
                                            ) : (
                                           <span className="badge badge-soft-success text-uppercase">
                                            ACTIVE
                                            </span>
                                             )}
                                        </td>
                                      <td><ul className="list-inline hstack gap-2 mb-1">
                                        {/* <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Remove">
                                          <Link
                                            className="text-danger d-inline-block remove-item-btn"
                                            data-bs-toggle="modal"
                                            to="#"
                                            onClick={(e) =>tog_animationFlip()}>
                                            <i className="ri-delete-bin-5-fill fs-16"></i>
                                          </Link>
                                        </li> */}
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              }):
                             <Nodatafound/>

                                  }
                              
                            </tbody>
                            }
                          </table>
                        {paginate > 1 ?
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={paginate}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                           :""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>

    <Modal
        id="flipModal"
        isOpen={modal_animationFlip}
        toggle={() => {
          tog_animationFlip();
        }}
        modalClassName="flip"
        centered
        style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalBody className="modal-body p-5 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#405189,secondary:#f06548"
            style={{ width: "90px", height: "90px" }}
          ></lord-icon>

          <div className="mt-4 text-center">
            <h4>You are about to delete a page ?</h4>
            <p className="text-muted fs-15 mb-4">
              Deleting your page will remove all of your information from our
              database.
            </p>
          </div>

          <button
            className="btn btn-link link-success fw-medium text-decoration-none"
            onClick={() => {
              tog_animationFlip();
            }}
          >
            {" "}
            <i className="ri-close-line me-1 align-middle"></i>
            Close{" "}
          </button>
          <Button color="danger"> Yes, Delete It </Button>
        </ModalBody>
      </Modal>     
</div>
</>
  )
}

export default Individual