import React, { useContext, useEffect, useState } from 'react'
import { Link,useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../Contexts/UserContext';
import { useAuthHandling } from '../../../Common/useAuthHandling';
import Config from '../../../Config';
import Commonheader from '../../../Common/Commonheader';
import { MultiSelect } from "react-multi-select-component";
import Toastmodal from '../../../Common/Toastmodal';
import Footer from '../../../Common/Footer';

function Childpolicycreate() {

    let navigate = useNavigate();
    let { selecthotelid } = useParams();
    const { updateHotel_select, hotel_select } = useContext(UserContext);
    const { isAuthorized, handleUnauthorized } = useAuthHandling();
  
    const [language, setLanguage] = useState([]);
    const [selectedMulti, setselectedMulti] = useState([]);
    const [selectedMulti1, setselectedMulti1] = useState([]);
    const [board, setBoard] = useState([]);
    const [tab, setTab] = useState("EN");
    const [active, setActive] = useState(false);
    const [childpolicy, setChildpolicy] = useState('');
    const [occupancy, setOccupancy] = useState([]);
    let params = useParams();
    // const [id, setId] = useState(params.type_id)
    const [formerror, setError] = useState({});
    const [hotelid, setHotelid] = useState(
      hotel_select ? hotel_select[0]?.value : ""
    );
  
    const [toastmodal, setToastmodal] = useState(false);
    const [toastmessage, setToastmessage] = useState("");
    const [toasttype, setToasttype] = useState("");
    const [toastlarge, setToastlarge] = useState(false);
  
    const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
    const [symbolsrate] = useState(["e", "E", "+", "-"]);
  
   
  
   
  
    const getValue = async (e) => {
      const res = await fetch(`${Config.apiurl}optional-languages`, {
        method: "GET",
      });
      const data = await res.json();
      if (data.status_code === "200") {
        setLanguage(data.data);
      } else {
        console.log("error");
      }
    };
  
    const [modal_animationFlip, setmodal_animationFlip] = useState(false);
    function tog_animationFlip() {
      setmodal_animationFlip(!modal_animationFlip);
    }
  
   
   
    useEffect(() => {
      getValue();
    }, []);
    // let Update = () => {}
  
   
  
    let Save = async () => {
      let isFormValid = true;
  
      const errors = {};
      if (!childpolicy) {
        isFormValid = false;
  
        errors.childpolicy = "Child policy is required!";
      }
  
     
  
      setError(errors);
  
      if (isFormValid === true) {
      
        let lang = [];
        for (let i = 0; i < selectedMulti1.length; i++) {
          if (selectedMulti1[i].name) {
            if (selectedMulti1[i].code !== "en") {
              let multiarray = {};
              multiarray.lang_code = selectedMulti1[i].code;
              multiarray.policy_text = selectedMulti1[i].name;

              lang.push(multiarray);
            }
          }
        }
        const res = await fetch(`${Config.apiurl}hotel/child/policy`, {
          method: "POST",
          body: JSON.stringify({
            hotels: [selecthotelid],
            policy_data: childpolicy,
            multi_lang:lang
          
            }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });
        if (res.status === 200) {
          const data = await res.json();
          if (data.status_code === "200" || !data.data) {
            setToastmodal(true);
            setToastmessage(data.message);
            setToasttype("success");
            setToastlarge(true);
            setTimeout(() => navigate("/child-policies"), 3000);
          } else if (data.status_code === 403) {
            tog_animationFlip();
          } else {
            console.log("error");
            setToastmodal(true);
            setToastmessage(data.message);
            setToasttype("error");
            setToastlarge(true);
          }
        } else if (res.status === 401) {
          handleUnauthorized();
          Save();
        } else {
          console.log("error");
        }
       
      }
    };
  
  return (
    <div>
        <Commonheader/>
        <div className="page-content">
        <div className="container-fluid">
          <div className="row project-wrapper">
            <div className="row dashboard-top-tab-style">
              <div className="col-xxl-12 dashboard-main-style">
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center gy-3">
                      <div className="col-sm">
                        <h5 className="card-title mb-0">Edit Child Policy</h5>
                      </div>
                      <div className="col-sm-auto">
                        <div className="d-flex gap-1 flex-wrap">
                          <Link className="btn  db-other-button" to="/child-policies">
                            Child Policy
                          </Link>
                          {/* <button
                            type="button"
                            className="btn db-cancel-button"
                          >
                            Cancel
                          </button> */}
                          <button className="btn db-save-button" onClick={Save}>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="live-preview">
                      <form action="#">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-translation-style">
                              <ul>
                                <li
                                  className={tab === "EN" ? "active" : ""}
                                  onClick={(e) => setTab("EN")}
                                >
                                  EN
                                </li>
                                <li
                                  className={tab === "TRANS" ? "active" : ""}
                                  onClick={(e) => setTab("TRANS")}
                                >
                                  Translations
                                </li>
                              </ul>
                            </div>
                          </div>

                          {tab === "EN" ? (
                            <>
                              <div className="row g-3">
                                <div className="col-lg-12">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                     Child Policy
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="childpolicy"
                                      value={childpolicy}
                                      onChange={(e)=>setChildpolicy(e.target.value)}
                                    />
                                    <label style={{ color: "red" }}>
                                      {formerror.childpolicy}
                                    </label>
                                  </div>
                                </div>
                              </div>
                             
                            </>
                          ) : (
                            <>
                              <div className="col-lg-4">
                                <div className="input-group">
                                  <label
                                    className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"
                                    style={{ width: "130px" }}
                                  >
                                    Choose Language
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-7">
                                    <MultiSelect
                                      options={language}
                                      value={selectedMulti1}
                                      onChange={(val) => setselectedMulti1(val)}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      closeOnChangedValue={true}
                                      aria-expanded="true"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="appendmore"></div>
                              {selectedMulti1.length > 0
                                ? selectedMulti1.map((element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="col-md-12 translation-fill-section"
                                        id="lang_ +val"
                                        data-value="1"
                                        style={{ marginTop: "20px" }}
                                      >
                                        <div className="translation-selected-lang">
                                          <p>
                                            <span className="translation-selected-lang-head">
                                              {element.code}
                                            </span>
                                          </p>
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-12">
                                            <div className="input-group">
                                              <label
                                                className="input-group-text input-joint-color"
                                                id="inputGroup-sizing-default"
                                              >
                                                Child Policy
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={element.name}
                                                name="name"
                                                onChange={(e) =>
                                                  setselectedMulti1(
                                                    (prevsetselectedMulti1) =>
                                                      prevsetselectedMulti1.map(
                                                        (item, i) =>
                                                          i === index
                                                            ? {
                                                                ...item,
                                                                name: e.target
                                                                  .value,
                                                              }
                                                            : item
                                                      )
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                         
                                        </div>
                                      </div>
                                    );
                                  })
                                : ""}
                            </>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}

      <Footer/>
    </div>
  )
}

export default Childpolicycreate
