import React from "react";

function Footer() {
  const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  window.onscroll = function () {
    scrollFunction();
  };
  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };
  return (
    <>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-sm-6">{new Date().getFullYear()} © Winway.</div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block">
                Design & Develop by Winway
              </div>
            </div> */}
          </div>
        </div>
      </footer>
      <button
        onClick={() => toTop()}
        className="btn new_top_btn btn-icon"
        id="back-to-top"
      >
        <i className="ri-arrow-up-line"></i>
      </button>
    </>
  );
}

export default Footer;
