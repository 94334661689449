import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MultiSelect } from "react-multi-select-component";
import Config from "../Config";
import Select from "react-select";
import Commonimagepath from "../Commonimagepath";

function Hotelinformation({
  setTab,
  country,
  be_lang,
  property,
  star,
  currency,
  creditcard,
  selectedCard,
  setselectedCard,
  hoteldetails,
  setHoteldetails,
  handlechange,
  handleChangeselect,
  onChange,
  pms,
  channel,
  selectedBookinglang,
  selectedExtanet,
  setSelectedBookinglang,
  setSelectedExtranet,
  onChangelogo,
  onChangehotelroom,
  handleChangeselectcountry,
  formerror,
  countrytime,
  calling,
  yesorno,
  propertytypehotel,
  setPropertytypehotel,
  handleChangetogghotel,
  // hotelinfonext,
  formerror1,
  formerror2,
  formerror3,
  formerror4,
  formerror5,
  ResetHotelinformation,
  setSelectedPMS,
  selectedpms,
  setSelectedchannelmanager,
  selectedchannelmanager,
  setSelectedcategory,
  selectedcategory,
  setSelectedcountry,
  selectedcountry,
  setSelectedcurrency,
  selectedcurrency,
  setSelectedtimezone,
  selectedtimezone,
  formerrorall,
  selectedoptionalimage,
  selectedhotelimage,
  removeImage,
  removeImagehotel,
  oldlogo,
  setHotelinfoupdatestatus,
  hotelinfoupdatestatus,
  hotelinfosavenext,
  hotel_info_status,
  hotelbutton,
  setHotelbutton,
  loaderspin,
  time,
  checkintime,
  setCheckintime,
  checkouttime,
  setCheckouttime,
}) {
  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [symbolsrate] = useState(["e", "E", "+", "-"]);

  console.log(checkintime);
  console.log(checkouttime);
  return (
    <div id="BasicInfo" className="tabcontent">
      <div className="card-body">
        <div className="live-preview">
          <form action="#">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="firstNameinput"
                    className="form-label be_form_label"
                  >
                    Name of the Hotel
                    <span class="form-validation-required">*</span>
                    <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new">The name of the hotel</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={hoteldetails.hotel_name}
                    onChange={handlechange}
                    name="hotel_name"
                  />

                  <label style={{ color: "red" }}>
                    {formerror1.hotel_name}
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="lastNameinput"
                    className="form-label be_form_label"
                  >
                    Corporate Name
                    <span class="form-validation-required">*</span>
                    <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new">
                      The legal name of the company
                    </span>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    id="lastNameinput"
                    value={hoteldetails.corporate_name}
                    onChange={handlechange}
                    name="corporate_name"
                  />

                  <label style={{ color: "red" }}>
                    {formerror1.corporate_name}
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label be_form_label">
                    Country <span class="form-validation-required">*</span>
                    <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new">
                      The name of the country where the hotel is located.
                    </span>
                  </label>

                  <Select
                    className="form-control"
                    id="exampleInputUsername1"
                    options={country}
                    value={selectedcountry}
                    onChange={(val) => {
                      handleChangeselectcountry(val);
                    }}
                    name="country"
                  />

                  <label style={{ color: "red" }}>{formerrorall.country}</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label be_form_label">
                    Timezone <span class="form-validation-required">*</span>
                    <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new">
                      The name of the timezone where the hotel is located
                    </span>
                  </label>
                  {countrytime.length > 1 ? (
                    <Select
                      className="form-control"
                      id="exampleInputUsername1"
                      options={countrytime}
                      value={selectedtimezone}
                      onChange={(val) => {
                        setSelectedtimezone(val);
                        setHotelinfoupdatestatus(true);
                        setHotelbutton(false);
                      }}
                    />
                  ) : (
                    <Select
                      className="form-control"
                      id="exampleInputUsername1"
                      options={countrytime}
                      selected={selectedtimezone}
                      value={selectedtimezone}
                      onChange={(val) => {
                        setSelectedtimezone(val);
                        setHotelinfoupdatestatus(true);
                        setHotelbutton(false);
                      }}
                    />
                  )}

                  <label style={{ color: "red" }}>
                    {formerrorall.timezone}
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="phonenumberInput"
                    className="form-label be_form_label"
                  >
                    Phone Number <span class="form-validation-required">*</span>
                    <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new">
                      The primary phone number for the hotel
                    </span>
                  </label>

                  <div className="input-group" data-input-flag>
                    {hoteldetails.calling_code === null ? (
                      <button
                        class="btn btn-light border calling-btn"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <select
                          value={hoteldetails.calling_code}
                          onChange={handlechange}
                          name="calling_code"
                          className="calling-select"
                        >
                          {calling.map((calling_code, index) => (
                            <option key={index} value={calling_code}>
                              {calling_code}
                            </option>
                          ))}
                        </select>
                      </button>
                    ) : (
                      <button
                        class="btn btn-light border calling-btn"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {hoteldetails.calling_code}
                      </button>
                    )}
                    <input
                      type="number"
                      class="form-control rounded-end flag-input"
                      placeholder="Enter number"
                      value={hoteldetails.phone_no}
                      onChange={handlechange}
                      name="phone_no"
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                    />
                  </div>
                  <label style={{ color: "red" }}>{formerror1.phone_no}</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Reservation Email{" "}
                    <span class="form-validation-required">*</span>
                    <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new">
                      Email address for getting in touch with the hotel directly
                    </span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="example@gmail.com"
                    id="emailidInput"
                    value={hoteldetails.reservation_email}
                    onChange={handlechange}
                    name="reservation_email"
                  />

                  <label style={{ color: "red" }}>
                    {formerror1.reservation_email}
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label be_form_label">
                    Checkin Time{" "}
                    <span className="form-validation-required">*</span>
                  </label>
                  <Select
                    className="form-control"
                    options={time}
                    value={checkintime}
                    onChange={(val) => setCheckintime(val)}
                  />

                  <label style={{ color: "red" }}>
                    {formerror1.checkintime}
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label be_form_label">
                    Checkout Time{" "}
                    <span className="form-validation-required">*</span>
                  </label>
                  <Select
                    className="form-control"
                    options={time}
                    value={checkouttime}
                    onChange={(val) => setCheckouttime(val)}
                  />
                  <label style={{ color: "red" }}>
                    {formerror1.checkouttime}
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Address of Hotel{" "}
                    <span class="form-validation-required">*</span>
                    <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new">
                      Physical street address where the hotel is located.
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    id="emailidInput"
                    value={hoteldetails.address}
                    onChange={handlechange}
                    name="address"
                  />

                  <label style={{ color: "red" }}> {formerror1.address} </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label be_form_label">
                    Extranet Languages{" "}
                    <span style={{ color: "#818181" }}>
                      (Please select only four languages.)
                    </span>
                    <span class="form-validation-required">*</span>
                    <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new">
                      The choice of languages available for the extranet
                      platform, you have to choose upto 4 languages
                    </span>
                  </label>

                  <MultiSelect
                    options={be_lang}
                    value={selectedExtanet}
                    onChange={(val) => {
                      setSelectedExtranet(val);
                      setHotelinfoupdatestatus(true);
                      setHotelbutton(false);
                    }}
                    labelledBy="Select"
                    selectionType="counter"
                    allowCustomValue={true}
                    hasSelectAll={false}
                    id="exampleInputUsername1"
                  />

                  <label style={{ color: "red" }}>
                    {" "}
                    {formerror2.selectedExtanet}{" "}
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label be_form_label">
                    Booking engine Languages{" "}
                    <span class="form-validation-required">*</span>
                    <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new">
                      The choice of languages available for the booking engine
                      platform{" "}
                    </span>
                  </label>
                  <MultiSelect
                    options={be_lang}
                    value={selectedBookinglang}
                    onChange={(val) => {
                      setSelectedBookinglang(val);
                      setHotelinfoupdatestatus(true);
                      setHotelbutton(false);
                    }}
                    labelledBy="Select"
                    selectionType="counter"
                    allowCustomValue={true}
                    id="exampleInputUsername1"
                  />
                  <label style={{ color: "red" }}>
                    {" "}
                    {formerror3.selectedBookinglang}{" "}
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    License Number
                    <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new">
                      The unique identification number or code assigned to the
                      hotel by the relevant licensing or regulatory authorities.
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    id="emailidInput"
                    value={hoteldetails.licence_number}
                    onChange={handlechange}
                    name="licence_number"
                  />
                  <label style={{ color: "red" }}>
                    {" "}
                    {formerror1.licence_number}{" "}
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3 ">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Vat Registration Number <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new ">
                      The unique identification number assigned to the hotel by
                      the relevant tax authorities for the purpose of VAT
                      compliance.
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    id="emailidInput"
                    value={hoteldetails.vat_registration_number}
                    onChange={handlechange}
                    name="vat_registration_number"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label be_form_label">
                    Property Type{" "}
                    <span class="form-validation-required">*</span>
                    <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new">
                      Select Property Type for Your Hotel
                    </span>
                  </label>

                  <Select
                    className="form-control"
                    id="exampleInputUsername1"
                    options={property}
                    value={propertytypehotel}
                    onChange={(val) => {
                      setPropertytypehotel(val);
                      setHotelinfoupdatestatus(true);
                      setHotelbutton(false);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label be_form_label">
                    Category <span class="form-validation-required">*</span>
                    <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new">
                      Select Star category for Your Hotel
                    </span>
                  </label>

                  <Select
                    className="form-control"
                    id="exampleInputUsername1"
                    options={star}
                    value={selectedcategory}
                    onChange={(val) => {
                      setSelectedcategory(val);
                      setHotelinfoupdatestatus(true);
                      setHotelbutton(false);
                    }}
                  />
                  <label style={{ color: "red" }}>
                    {" "}
                    {formerrorall.category}{" "}
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label be_form_label">
                    Default Currency{" "}
                    <span class="form-validation-required">*</span>
                    <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new">
                      The primary currency that the hotel uses for its financial
                      transactions
                    </span>
                  </label>
                  <Select
                    className="form-control"
                    id="exampleInputUsername1"
                    options={currency}
                    value={selectedcurrency}
                    onChange={(val) => {
                      setSelectedcurrency(val);
                      setHotelinfoupdatestatus(true);
                      setHotelbutton(false);
                    }}
                  />
                  <label style={{ color: "red" }}>
                    {" "}
                    {formerrorall.currency}{" "}
                  </label>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-4">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Hotel Image
                    <span class="form-validation-required">*</span>
                    <div class="info-tag-new mar_1">i</div>
                    <span class="tooltiptext-new">
                      Hotel image to be displayed on the booking confirmation
                      for the guest, only accept jpg,jpeg,png,webp format
                    </span>
                    <span className="span-img-size">( 1024 px * 270px)</span>
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    onChange={onChange}
                    multiple
                    name="hotel_image"
                    accept=".jpg, .jpeg, .webp, .png"
                  />
                  <label className="label_image_crop">
                    {" "}
                    (<span class="form-validation-required">*</span>if you do
                    not resize it as per the mentioned size, it can affect the
                    quality of the images)
                  </label>

                  <label style={{ color: "red" }}>
                    {" "}
                    {formerrorall.hotelimage}{" "}
                  </label>

                  <div className="row">
                    {selectedhotelimage.length !== 0
                      ? selectedhotelimage.map((image, i) => {
                          return (
                            <div
                              className="col-sm-6 col-6 col-md-6 col-lg-4 mt-2"
                              key={i}
                            >
                              <img
                                src={`${Commonimagepath.apiurl}${image}`}
                                alt=""
                                className="position-relative"
                                style={{
                                  width: "100px",

                                  marginTop: "5px",
                                  marginLeft: "5px",
                                  display: "block",
                                }}
                              />

                              <button
                                className="remov-button"
                                type="button"
                                onClick={(e) => removeImagehotel(image)}
                              >
                                X
                              </button>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-4">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Optional Image
                    <span class="form-validation-required">*</span>
                    <div class="info-tag-new mar_1">i</div>
                    <span class="tooltiptext-new">
                      Room image to be displayed on the booking confirmation for
                      the guest, example your best room image, only accept
                      jpg,jpeg,png,webp format
                    </span>
                    <span className="span-img-size">( 1024 px * 270px)</span>
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    onChange={onChangehotelroom}
                    name="room_image"
                    multiple
                    accept=".jpg, .jpeg, .webp, .png"
                  />
                  <label className="label_image_crop">
                    {" "}
                    (<span class="form-validation-required">*</span>if you do
                    not resize it as per the mentioned size, it can affect the
                    quality of the images)
                  </label>

                  <label style={{ color: "red" }}>
                    {" "}
                    {formerrorall.optionalimage}{" "}
                  </label>
                  <div className="row">
                    {selectedoptionalimage.length !== 0
                      ? selectedoptionalimage.map((image, i) => {
                          return (
                            <div
                              className="col-sm-6 col-md-6 col-lg-4 col-6 mt-2"
                              key={i}
                            >
                              <img
                                src={`${Commonimagepath.apiurl}${image}`}
                                alt=""
                                className="position-relative"
                                style={{
                                  width: "100px",

                                  marginTop: "5px",
                                  marginLeft: "5px",
                                  display: "block",
                                }}
                              />

                              <button
                                className="remov-button"
                                type="button"
                                onClick={(e) => removeImage(image)}
                              >
                                X
                              </button>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Logo <span class="form-validation-required">*</span>
                    <div class="info-tag-new mar_1">i</div>
                    <span class="tooltiptext-new">
                      Upload Hotel logo image and only accept jpg,jpeg,png
                      format
                    </span>
                    <span className="span-img-size">( 140 px * 40px)</span>
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    onChange={onChangelogo}
                    name="hotel_logo"
                    accept=".jpg, .jpeg, .webp, .png"
                  />
                  <label className="label_image_crop">
                    {" "}
                    (<span class="form-validation-required">*</span>if you do
                    not resize it as per the mentioned size, it can affect the
                    quality of the images)
                  </label>

                  <label style={{ color: "red" }}>
                    {formerror1.hotel_logo}
                  </label>
                  {oldlogo ? (
                    <div className="row">
                      <div className="col-md-6">
                        <img
                          src={`${Commonimagepath.apiurl}${oldlogo}`}
                          alt=""
                          className="position-relative"
                          style={{
                            width: "100px",

                            marginTop: "5px",
                            marginLeft: "5px",
                            display: "block",
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    No Of Rooms
                    <span class="form-validation-required">*</span>
                    <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new">
                      Total count of rooms available for booking in the hotel.
                    </span>
                  </label>
                  <input
                    type="number"
                    onKeyDown={(e) =>
                      symbolsArr.includes(e.key) && e.preventDefault()
                    }
                    min={0}
                    className="form-control"
                    placeholder=""
                    id="emailidInput"
                    value={hoteldetails.no_of_rooms}
                    onChange={handlechange}
                    name="no_of_rooms"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                  />
                  <label style={{ color: "red" }}>
                    {" "}
                    {formerror1.no_of_rooms}{" "}
                  </label>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Minimum Room rate{" "}
                    <span class="form-validation-required">*</span>
                    <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new">
                      The lowest price at which a hotel room can be booked for a
                      particular date or period.
                    </span>
                  </label>
                  <input
                    type="number"
                    onKeyDown={(e) =>
                      symbolsrate.includes(e.key) && e.preventDefault()
                    }
                    min={0}
                    className="form-control"
                    placeholder=""
                    id="emailidInput"
                    value={hoteldetails.min_room_rate}
                    onChange={handlechange}
                    name="min_room_rate"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                  />
                  <label style={{ color: "red" }}>
                    {" "}
                    {formerror1.min_room_rate}{" "}
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Maximum Room rate{" "}
                    <span class="form-validation-required">*</span>
                    <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new">
                      The highest price at which a hotel room can be booked for
                      a particular date or period
                    </span>
                  </label>
                  <input
                    type="number"
                    min={0}
                    onKeyDown={(e) =>
                      symbolsrate.includes(e.key) && e.preventDefault()
                    }
                    className="form-control"
                    placeholder=""
                    id="emailidInput"
                    value={hoteldetails.max_room_rate}
                    onChange={handlechange}
                    name="max_room_rate"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                  />
                  <label style={{ color: "red" }}>
                    {" "}
                    {formerror1.max_room_rate}{" "}
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Website URL
                    <span class="form-validation-required">*</span>
                    <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new">
                      Hotel official website address
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    id="emailidInput"
                    value={hoteldetails.website_url}
                    onChange={handlechange}
                    name="website_url"
                  />
                  <label style={{ color: "red" }}>
                    {" "}
                    {formerror1.website_url}{" "}
                  </label>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Extranet Admin Email
                    <span class="form-validation-required">*</span>
                    <div class="info-tag-new">i</div>
                    <span class="tooltiptext-new">
                      For create extranet login
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    id="emailidInput"
                    value={hoteldetails.extranet_admin_email}
                    onChange={handlechange}
                    name="extranet_admin_email"
                  />
                  <label style={{ color: "red" }}>
                    {" "}
                    {formerror1.extranet_admin_email}{" "}
                  </label>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="mb-3">
                  <label for="emailidInput" className="form-label be_form_label">
                  How many extranet login{" "}
                    <span
                       class="form-validation-required">*</span><span class="info-tag">i<span class="tooltiptext-new">How many extranet logins do you need.</span></span></label>                  
                  <input
                    type="number"
                    min={0}

                    className="form-control"
                    placeholder=""
                    id="emailidInput"
                    value={hoteldetails.extranet_login_nos}
                    onChange={handlechange}
                    name="extranet_login_nos"
                  />
                        <label style={{ color: "red" }}> {formerror1.extranet_login_nos} </label>
              
                </div>
              </div> */}
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="firstNameinput"
                    className="form-label be_form_label"
                  >
                    Do you use a channel manager?{" "}
                    <span className="form-validation-required">*</span>
                  </label>
                  <span
                    className="form-check form-switch form-switch-lg mb-3"
                    dir="ltr"
                  >
                    <input
                      type="checkbox"
                      name="channel_manager_status"
                      className="form-check-input loyalty_expiration"
                      value={hoteldetails.channel_manager_status}
                      checked={
                        hoteldetails.channel_manager_status === true
                          ? true
                          : false
                      }
                      onChange={handleChangetogghotel}
                    />
                  </span>
                </div>
              </div>

              {hoteldetails.channel_manager_status === true ? (
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      for="emailidInput"
                      className="form-label be_form_label"
                    >
                      Name of Channel Manager
                      <span className="form-validation-required">*</span>
                    </label>

                    <Select
                      className="form-control"
                      id="exampleInputUsername1"
                      options={channel}
                      value={selectedchannelmanager}
                      onChange={(val) => {
                        setSelectedchannelmanager(val);
                        setHotelinfoupdatestatus(true);
                        setHotelbutton(false);
                      }}
                    />
                    <label style={{ color: "red" }}>
                      {" "}
                      {formerrorall.channel_manager_name}{" "}
                    </label>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="firstNameinput"
                    className="form-label be_form_label"
                  >
                    Do you use a PMS{" "}
                    <span className="form-validation-required">*</span>
                  </label>
                  <span
                    className="form-check form-switch form-switch-lg mb-3"
                    dir="ltr"
                  >
                    <input
                      type="checkbox"
                      name="pms_status"
                      className="form-check-input loyalty_expiration"
                      value={hoteldetails.pms_status}
                      checked={hoteldetails.pms_status === true ? true : false}
                      onChange={handleChangetogghotel}
                    />
                  </span>
                </div>
              </div>
              {hoteldetails.pms_status === true ? (
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      for="emailidInput"
                      className="form-label be_form_label"
                    >
                      Name of the PMS
                      <span className="label-sub">
                        {" "}
                        ( Property managment system )
                      </span>
                      <span className="form-validation-required">*</span>
                    </label>

                    <Select
                      className="form-control"
                      id="exampleInputUsername1"
                      options={pms}
                      value={selectedpms}
                      onChange={(val) => {
                        setSelectedPMS(val);
                        setHotelinfoupdatestatus(true);
                        setHotelbutton(false);
                      }}
                    />
                    <label style={{ color: "red" }}>
                      {" "}
                      {formerrorall.pms_name}{" "}
                    </label>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="firstNameinput"
                    className="form-label be_form_label"
                  >
                    Do you accepts credit card ?{" "}
                    <span className="form-validation-required">*</span>
                  </label>

                  <span
                    className="form-check form-switch form-switch-lg mb-3"
                    dir="ltr"
                  >
                    <input
                      type="checkbox"
                      name="credit_card_status"
                      className="form-check-input loyalty_expiration"
                      value={hoteldetails.credit_card_status}
                      checked={
                        hoteldetails.credit_card_status === true ? true : false
                      }
                      onChange={handleChangetogghotel}
                    />
                  </span>
                </div>
              </div>
              {hoteldetails.credit_card_status === true ? (
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      for="emailidInput"
                      className="form-label be_form_label"
                    >
                      Accepted credit cards ?{" "}
                      <span class="form-validation-required">*</span>
                      <div class="info-tag-new">i</div>
                      <span class="tooltiptext-new">
                        Select Accepted Credit cards
                      </span>
                    </label>
                    <MultiSelect
                      options={creditcard}
                      value={selectedCard}
                      onChange={(val) => {
                        setselectedCard(val);
                        setHotelinfoupdatestatus(true);
                        setHotelbutton(false);
                      }}
                      labelledBy="Select"
                      selectionType="counter"
                      allowCustomValue={true}
                    />
                    <label style={{ color: "red" }}>
                      {" "}
                      {formerror5.selectedCard}{" "}
                    </label>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="padding-10"></div>
          </form>
          <div className="tab_nav_section">
            <div className="tab_nav_section_btns">
              <button
                className="btn btn-be-form-cancel btn-be-back"
                onClick={ResetHotelinformation}
              >
                Cancel
              </button>
              {hotelinfoupdatestatus === true && hotelbutton === true ? (
                <button
                  className="btn btn-success btn-be-common"
                  // onClick={() => setTab("Room-info")}
                  onClick={hotelinfosavenext}
                  disabled
                >
                  Save & Next
                </button>
              ) : hotelinfoupdatestatus === true && hotelbutton === false ? (
                <button
                  className="btn btn-success btn-be-common"
                  // onClick={() => setTab("Room-info")}
                  onClick={hotelinfosavenext}
                >
                  Save & Next
                </button>
              ) : hotelinfoupdatestatus === false &&
                hotel_info_status === true ? (
                <button
                  className="btn btn-success btn-be-common"
                  onClick={() => setTab("Room-info")}
                  //onClick={hotelinfonext}
                >
                  Next
                </button>
              ) : hoteldetails.hotel_form_status === 3 &&
                hotelbutton === true ? (
                <button
                  className="btn btn-success btn-be-common"
                  // onClick={() => setTab("Room-info")}
                  onClick={hotelinfosavenext}
                  disabled
                >
                  Save & Next
                </button>
              ) : hoteldetails.hotel_form_status === 3 &&
                hotelbutton === false ? (
                <button
                  className="btn btn-success btn-be-common"
                  // onClick={() => setTab("Room-info")}
                  onClick={hotelinfosavenext}
                >
                  Save & Next
                </button>
              ) : (
                <button
                  className="btn btn-success btn-be-common"
                  // onClick={() => setTab("Room-info")}
                  onClick={hotelinfosavenext}
                >
                  Save & Next
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hotelinformation;
