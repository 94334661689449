import React, { useContext, useEffect, useState } from "react";
import Commonheader from "../../../Common/Commonheader";
import ReactPaginate from "react-paginate";
import { UserContext } from "../../../Contexts/UserContext";
import { useAuthHandling } from "../../../Common/useAuthHandling";
import Config from "../../../Config";
import { Link } from "react-router-dom";
import Footer from "../../../Common/Footer";
import DeleteModal from "../../../DeleteModal";
import Toastmodal from "../../../Common/Toastmodal";
import Loading from "../../../Loading";

function Childpolicylist() {
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [columnsDisplayed, setColumnsDisplayed] = useState(6);

  const [list, setList] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [page, setPage] = useState(1);
  const [pageindex, setPageindex] = useState(1);
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [selehotel, setSelechotel] = useState(0);
  const [showFullData, setShowFullData] = useState([]);
  const [hotelId, setHotelId] = useState('');
  const [policyId, setPoliyId] = useState('');
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [loader, setLoader] = useState(false);

  function tog_animationFlip(hotel_id,policy_id) {
    setmodal_animationFlip(!modal_animationFlip);
    setHotelId(hotel_id);
    setPoliyId(policy_id);
  }

  const handleDropdownClickhotel = (i) => {
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  // const [confirm, setConfirm] = useState("");
  // const [loop, setLoop] = useState("");
  // const [loopindex, setLoopindex] = useState("");

  // const [modal_animationFlipbasic, setmodal_animationFlipbasic] = useState(false);

  // function tog_animationFlipbasic(i, index) {
  //   console.log(i);
  //   console.log(index);
  //   setLoop(i);
  //   setLoopindex(index);
  //   setmodal_animationFlipbasic(!modal_animationFlipbasic);
  // }

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}hotel/child/policy/list?page=${currentPage}`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.data) {
        return data.data;
      } else if (data?.reauth === true) {
        handleUnauthorized();
        fetchComments(currentPage);
      }
    }
  };

  const handlePageClick = async (data) => {
    if (data === 1) {
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
      setList(commentsFormServer);
    } else {
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
      setList(commentsFormServer);
    }
  };

  const fetchPost = async () => {
    const response = await fetch(
      `${Config.apiurl}hotel/child/policy/list?page=1`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (response.status === 200) {
      const data = await response.json();
      if (data.data) {
        setList(data.data);
        setPaginate(data.last_page);
        setLoader(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        fetchPost();
      }
    } else {
      console.log("error");
    }
  };

  const DeleteData = async () => {
    const res = await fetch(
      `${Config.apiurl}hotel/child/policy/delete`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id:hotelId,
          policy_id:policyId
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200" || !data) {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
        setmodal_animationFlip(false);
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(true);
        setmodal_animationFlip(false);
        handlePageClick(page);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      DeleteData();
    } else {
      console.log("error");
  }
};

  useEffect(() => {
    fetchPost();
  }, [id]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (list.length > 0) {
      const initialState = list.map((item, index) => ({
        hotel: index === 0,
        rooms: Array(item.policy_data.length).fill(false),
      }));
      setShowFullData(initialState);
    }
  }, [list.length]);

  console.log(list);
  return (
    <div className="">
      <Commonheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box-in d-sm-flex align-items-center justify-content-between">
                  <Link
                    type="button"
                    to={`/child-policy-create/${list[selehotel]?.hotel_id}`}
                    className="btn db-save-button "
                  >
                    Create
                  </Link>
                </div>
              </div>
            </div>
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card" style={{ marginTop: "5px" }}>
                    <div className="card-body">
                      <div>
                        {/* <ul className="nav nav-tabs nav-tabs-custom nav-success mb-3" role="tablist">
                          </ul> */}
                          {loader===false?
                          <Loading/>
                          :
                        <div className="">
                          {list.map((li, i) => {
                            return (
                              <>
                                <div
                                  className={
                                    list.length > 1
                                      ? "row hotel_single_list"
                                      : "row"
                                  }
                                  key={i}
                                >
                                  {list.length > 1 ? (
                                    <div className="hotel_service_display hotel_service_display_12">
                                      <div className="hotel_service_display_14">
                                        <input
                                          type="radio"
                                          id="radio-one"
                                          className="hotel_service_display_14_radio form-check-input per_room_once_check"
                                          value={true}
                                          checked={
                                            i === selehotel ? true : false
                                          }
                                          onChange={(e) => setSelechotel(i)}
                                          // onChange={loyaltycommontogg}
                                          style={{
                                            position: "relative",
                                            top: "8px",
                                          }}
                                        />

                                        <div className="hk_service_all_hotel">
                                          <span className="">
                                            {" "}
                                            {pageindex === 1
                                              ? i + 1
                                              : (pageindex - 1) * 10 + i + 1}
                                            .
                                          </span>
                                          <span style={{ marginLeft: "10px" }}>
                                            {li.hotel_name}
                                          </span>
                                        </div>
                                      </div>
                                      {showFullData[i]?.hotel === true ? (
                                        <span
                                          className="db-modify_toggle_btn"
                                          onClick={() =>
                                            handleDropdownClickhotel(i)
                                          }
                                        >
                                          <span
                                            className="bx bxs-chevron-up"
                                            style={{ fontSize: "20px" }}
                                          ></span>
                                        </span>
                                      ) : (
                                        <span
                                          className="db-modify_toggle_btn"
                                          onClick={() =>
                                            handleDropdownClickhotel(i)
                                          }
                                        >
                                          <span
                                            className="bx bxs-chevron-down"
                                            style={{ fontSize: "20px" }}
                                          ></span>
                                        </span>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <table
                                    id="fixed-header orderTable"
                                    className={`table table-nowrap align-middle ${
                                      showFullData[i]?.hotel === true
                                        ? ""
                                        : "d-none"
                                    }`}
                                  >
                                    <thead
                                      className="text-muted table-light"
                                      key={i}
                                    >
                                      <tr className="text-uppercase ">
                                        <th scope="col"></th>
                                        <th className="" data-sort="id">
                                          Sl No
                                        </th>
                                        <th
                                          className=""
                                          data-sort="customer_name"
                                        >
                                          Child Policy
                                        </th>

                                        <th className="" data-sort="status">
                                          Status
                                        </th>
                                        <th className="" data-sort="city">
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                      {li.policy_data.length > 0 ? (
                                        li?.policy_data?.map((user, index) => {
                                          return (
                                            <tr key={index}>
                                              <td></td>
                                              <td className="id">
                                                {pageindex === 1
                                                  ? index + 1
                                                  : (pageindex - 1) * 10 +
                                                    index +
                                                    1}
                                              </td>
                                              <td className="customer_name">
                                                {user.policy_text.length >
                                                50 ? (
                                                  <>
                                                    {user.policy_text?.slice(
                                                      0,
                                                      50
                                                    )}
                                                    ...
                                                  </>
                                                ) : (
                                                  user.policy_text
                                                )}
                                              </td>

                                              <td className="status">
                                                {user.status === false ? (
                                                  <span class="badge badge-soft-danger text-uppercase">
                                                    {" "}
                                                    INACTIVE
                                                  </span>
                                                ) : (
                                                  <span className="badge badge-soft-success text-uppercase">
                                                    ACTIVE
                                                  </span>
                                                )}
                                              </td>
                                              <td>
                                                <ul className="list-inline hstack gap-2 mb-1">
                                                  <li
                                                    className="list-inline-item edit"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-trigger="hover"
                                                    data-bs-placement="top"
                                                    title="Edit"
                                                  >
                                                    <Link
                                                      to={`/child-policy-edit/${user.policy_id}/${li.hotel_id}`}
                                                      state={{}}
                                                      data-bs-toggle="modal"
                                                      className="text-primary d-inline-block edit-item-btn"
                                                    >
                                                      <i className="ri-pencil-fill fs-16"></i>
                                                    </Link>
                                                  </li>
                                                  <li
                                                    className="list-inline-item"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-trigger="hover"
                                                    data-bs-placement="top"
                                                    title="Remove"
                                                  >
                                                    <Link
                                                      className="text-danger d-inline-block remove-item-btn"
                                                      data-bs-toggle="modal"
                                                      to="#"
                                                      onClick={(e) =>
                                                        tog_animationFlip(li.hotel_id,user.policy_id)
                                                      }
                                                    >
                                                      <i className="ri-delete-bin-5-fill fs-16"></i>
                                                    </Link>
                                                  </li>
                                                </ul>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td
                                            style={{ textAlign: "center" }}
                                            colSpan={columnsDisplayed - 1}
                                          >
                                            No data found
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            );
                          })}

                          {paginate > 1 ? (
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              pageCount={paginate}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination justify-content-end"
                              }
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                         }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}

      {modal_animationFlip ? (
        <DeleteModal
          modal_animationFlip={modal_animationFlip}
          tog_animationFlip={tog_animationFlip}
          pageid={policyId}
          DeleteData={DeleteData}
          message={"Child policy"}
        />
      ) : (
        ""
      )}
      <Footer />
    </div>
  );
}

export default Childpolicylist;
