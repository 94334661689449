import React from "react";
import { MultiSelect } from "react-multi-select-component";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";

function Chainedit({
    modal_backdrop,
    tog_backdrop,
    options,
    selected,
    setSelected
   }) {
  return (
    <Modal
    isOpen={modal_backdrop}
    toggle={() => {
      tog_backdrop();
    }}
    backdrop={"static"}
    id="staticBackdrop"
    centered
  >
    <ModalHeader
      className="modal-title modal-header bg-light p-3"
      id="staticBackdropLabel"
      toggle={() => {
        tog_backdrop();
      }}
    ></ModalHeader>
    <ModalBody className="">
      <div className="mt-4">
        <label for="orderId" className="form-label">
           Name
        </label>
        <input
          type="text"
          className="form-control"

        />

        <label for="orderId" className="form-label mt-2">
          Hotel
        </label>
        <MultiSelect
                                  options={options}
                                  value={selected}
                                  onChange={setSelected}
                                  labelledBy="Select"
                                  selectionType="counter"
                                  closeOnChangedValue={true}
                                  aria-expanded="true"
                                />
        <label for="orderId" className="form-label mt-2">
          Property Type
        </label>
        <select id="ForminputState" className="form-select">
                                                        <option selected>Choose...</option>
                                                        <option>Hotel</option>
                                                        <option>Hotel Apartments</option>
                                                        <option>Holyday Home</option>
                                                    </select>
        <label for="orderId" className="form-label mt-2">
          Status
        </label>
        <select
          className="form-control "
          name="status"
         
        >
          <option>Choose...</option>
          <option name="status" value={1}>
            Active
          </option>
          <option name="status" value={0}>
            InActive
          </option>
        </select>

        <div className="modal-footer p-3">
          <div className="hstack gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
              onClick={() => {
                tog_backdrop();
              }}
            >
              Close{" "}
            </button>
            {/* <!-- <button type="submit" className="btn btn-success" id="add-btn">Save</button> --> */}
            <button
              type="button"
              className="btn db-save-single-button"
              id="edit-btn"
             
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </ModalBody>
  </Modal>
  )
}

export default Chainedit

