import React, { useContext, useEffect, useState } from "react";
import Commonheader from "../../Common/Commonheader";
import Footer from "../../Common/Footer";
import Select from "react-select";
import { Link } from "react-router-dom";
import Config from "../../Config";
import axios from "axios";
import Alerts from "../../Alerts";
import ReactPaginate from "react-paginate";
import { UserContext } from "../../Contexts/UserContext";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Toastmodal from "../../Common/Toastmodal";
import { FaArrowTurnDown, FaArrowTurnUp } from "react-icons/fa6";
import { MultiSelect } from "react-multi-select-component";
import Loading from "../../Loading";

function Hotelservices() {
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [list, setList] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [page, setPage] = useState(1);
  const [pageindex, setPageindex] = useState(1);
  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [frequency, setFrequency] = useState([]);
  const [showFullData, setShowFullData] = useState([]);
  const [changeddata, setChangeddata] = useState([]);
  const [service, setService] = useState([]);
  const [hotel, setHotel] = useState([]);
  const [hotelnew, setHotelnew] = useState([]);
  const [formerror, setError] = useState([{}]);
  const [formerror1, setError1] = useState({});
  const [selectedhotel, setSelectedHotel] = useState([]);

  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [active, setActive] = useState(true);
  const [loader, setLoader] = useState(false);
  const [inputFields, setInputFields] = useState([
    {
      services: [],
      frequencies: [],
    },
  ]);

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        services: [],
        frequencies: [],
      },
    ]);
  };

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  let handleChangeselect = (i, data, name) => {
    let newval = [...inputFields];
    inputFields[i][name] = data;
    setInputFields(newval);
  };

  function clickEventHandler() {
    setActive(!active);
  }

  const getFrequency = async (e) => {
    const res = await fetch(`${Config.apiurl}active-frequencies`, {
      method: "POST",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setFrequency(data);
    }
  };

  const getHotel = async (e) => {
    const res = await fetch(`${Config.apiurl}hotels`, {
      method: "GET",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setHotelnew(data);
    }
  };

  const getService = async (e) => {
    const res = await fetch(`${Config.apiurl}active-services`, {
      method: "POST",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setService(data);
    }
  };

  // let handleChange = (li, user, e) => {
  //   let newval = [...list];
  //   newval[li]['rooms'][user]['frequencies'] = e;
  //   setList(newval);

  //   let changedDataCopy = [...changeddata];
  //   changedDataCopy[li] = {...changedDataCopy[li],
  //     hotel_id:list[li]?.hotel_id,
  //     rooms: [{
  //      ...changedDataCopy[li]?.rooms?.[user],
  //         frequencies: e.value,
  //         services:list[li]?.rooms?.[user].services.value,
  //         hotel_service_id:list[li]?.rooms?.[user].hotel_service_id,
  //       }]
  //   };
  //   setChangeddata(changedDataCopy);
  // };

  let handleChange = (li, user, e) => {
    let newval = [...list];
    newval[li]["rooms"][user]["frequencies"] = e;
    setList(newval);

    let changedDataCopy = [...changeddata];
    // let newVal=list[li];
    // if(!newval[li].rooms[user]){
    //   changedDataCopy.rooms = [];
    // }
    // else{
    //   changedDataCopy.push({
    //     hotel_id: newval[li].hotel_id,
    //     rooms: newval[li].rooms[user]
    //   })
    // }
    // setChangeddata(changedDataCopy);
    // console.log(newVal);

    changedDataCopy[li] = {
      ...changedDataCopy[li],
      hotel_id: list[li]?.hotel_id,
      rooms: {
        ...changedDataCopy[li]?.rooms,
        [user]: {
          ...changedDataCopy[li]?.rooms?.[user],
          frequencies: e.value,
          services: list[li]?.rooms?.[user].services.value,
          hotel_service_id: list[li]?.rooms?.[user].hotel_service_id,
        },
      },
    };
    setChangeddata(changedDataCopy);
  };

  const handleDropdownClickhotel = (i) => {
    // Toggle the showFullData state for the corresponding item
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}hotel/service-list?page=${currentPage}`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const data = await res.json();
    if (data.status_code === "200") {
      return data.data.data;
    } else if (data.reauth === true) {
      handleUnauthorized();
      fetchComments(currentPage);
    }
  };

  const handlePageClick = async (data) => {
    if (data === 1) {
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
      setList(commentsFormServer);
    } else {
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
      setList(commentsFormServer);
    }
  };

  const fetchPost = async () => {
    const res = await fetch(`${Config.apiurl}hotel/service-list?page=1`, {
      method: "POST",
      body: JSON.stringify({
        hotels: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setList(data.data.data);
        setPaginate(data.data.last_page);
        setLoader(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        fetchPost();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      fetchPost();
    } else {
      console.log("error");
    }
    // const data = await response.json();
    // if (response.status === 200) {
    //   if (data.status_code === "200") setList(data.data.data);
    //   setPaginate(data.data.last_page);
    // } else if (data.reauth === true) {
    //   handleUnauthorized();
    //   fetchPost();
    // } else {
    //   console.log("error");
    // }
  };


  const PostData = async (e) => {
    e.preventDefault();

    let isFormValid = true;
    let array = [];
    for (let i = 0; i < inputFields.length; i++) {
      const errors = {};
      if (inputFields[i].services.length === 0) {
        isFormValid = false;

        errors.services = "Service is required!";
      }
      if (inputFields[i].frequencies.length === 0) {
        isFormValid = false;

        errors.frequencies = "Frequency is required!";
      }
      array.push(errors);
    }
    const error = {};
    if (hotel.length > 1) {
      if (selectedhotel.length === 0) {
        isFormValid = false;
        error.selectedhotel = "Hotel is required!";
      }
    }
    setError(array);
    setError1(error);

    let hotellist = [];
    if (hotel.length > 1) {
      for (let j = 0; j < selectedhotel.length; j++) {
        let multiarray = {};
        let mularr = [];
        for (let i = 0; i < inputFields.length; i++) {
          let multiarray1 = {};
          for (let k = 0; k < list.length; k++) {
            for (let l = 0; l < list[k].rooms.length; l++) {
              if (selectedhotel[j].value === list[k].hotel_id) {
                if (
                  inputFields[i].services.value === list[k].rooms[l].service_id
                ) {
                  multiarray1.hk_service_id = list[k].rooms[l].hotel_service_id;
                }
              }
            }
          }
          multiarray1.service_id = inputFields[i].services.value;
          multiarray1.frequency_id = inputFields[i].frequencies.value;

          mularr.push(multiarray1);
        }
        multiarray.hotel_id = selectedhotel[j].value;

        multiarray.services = mularr;
        hotellist.push(multiarray);
      }
    } else {
      let multiarray = {};
      let mularr = [];
      for (let i = 0; i < inputFields.length; i++) {
        let multiarray1 = {};
        for (let k = 0; k < list.length; k++) {
          for (let l = 0; l < list[k].rooms.length; l++) {
            if (id[0] === list[k].hotel_id) {
              if (
                inputFields[i].services.value === list[k].rooms[l].service_id
              ) {
                multiarray1.hk_service_id = list[k].rooms[l].hotel_service_id;
              }
            }
          }
        }
        multiarray1.service_id = inputFields[i].services.value;
        multiarray1.frequency_id = inputFields[i].frequencies.value;

        mularr.push(multiarray1);
      }
      multiarray.hotel_id = id[0];

      multiarray.services = mularr;
      hotellist.push(multiarray);
    }
    // let arr = [];
    // for (let i = 0; i < changeddata.length; i++) {
    //   let arry = {};
    //   if (changeddata[i]) {
    //     arry.rooms = Object.values(changeddata[i].rooms);
    //     arry.hotel_id = changeddata[i].hotel_id;
    //     arr.push(arry);
    //   }
    // }
    // for (let i = 0; i < arr.length; i++) {
    //   let multiarray = {};
    //   let mularr = [];
    //   for (let j = 0; j < arr[i].rooms.length; j++) {
    //     let multiarray1 = {};
    //     multiarray1.hk_service_id = arr[i].rooms[j].hotel_service_id;
    //     multiarray1.service_id = arr[i].rooms[j].services;
    //     multiarray1.frequency_id = arr[i].rooms[j].frequencies;
    //     mularr.push(multiarray1);
    //   }
    //   multiarray.hotel_id = arr[i].hotel_id;
    //   multiarray.services = mularr;
    //   hotellist.push(multiarray);
    // }

    if (isFormValid === true) {
      const res = await fetch(`${Config.apiurl}hotel/services/bulk-update`, {
        method: "POST",
        body: JSON.stringify({
          hotel: hotellist,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code === "200") {
              setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(false);
        setSelectedHotel([]);
        fetchPost();
        } else if (data.reauth === true) {
          handleUnauthorized();
          PostData();
        } else {
           setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(false);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        PostData();
      } else {
        console.log("error");
      }
      // const data = await res.json();
      // if (data.status_code !== "200" || !data) {
      //   setToastmodal(true);
      //   setToastmessage(data.message);
      //   setToasttype("error");
      //   setToastlarge(false);
      // } else {
      //   setToastmodal(true);
      //   setToastmessage(data.message);
      //   setToasttype("success");
      //   setToastlarge(false);
      //   setSelectedHotel([]);
      //   fetchPost();
      // }
    }
  };

  useEffect(() => {
    fetchPost();
  }, [id]);

  useEffect(() => {
    getFrequency();
    getHotel();
    getService();
  }, []);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (list.length > 0) {
      const initialState = list.map((item, index) => ({
        hotel: index === 0,
        rooms: Array(item.rooms.length).fill(false),
      }));
      setShowFullData(initialState);
    }
  }, [list.length]);

  // useEffect(() => {
  //   if (hotelnew) {
  //     for (let i = 0; i < hotelnew.length; i++) {
  //       hotel.push({
  //         label: hotelnew[i].hotel_name,
  //         value: hotelnew[i].hotel_id,
  //       });
  //     }
  //   }
  // }, [hotelnew?.length, hotel]);
  useEffect(() => {
    const matchedHotels = [];

    id.forEach((ids) => {
      const newHotel = hotelnew?.find((hotel) => hotel.hotel_id === ids);
      if (newHotel) {
        matchedHotels.push({
          value: newHotel.hotel_id,
          label: newHotel.hotel_name,
        });
      }
    });

    setHotel(matchedHotels);
  }, [id, hotelnew]);

  return (
    <div className="main-content">
      <Commonheader />
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="db-page-title">Hotel Services</h4>
              </div>
            </div>
          </div>
          <div className="row project-wrapper">
            <div className="row dashboard-top-tab-style">
              <div className="col-xxl-12 dashboard-main-style">
                <div className="card">
                  <div className="card-body">
                    {loader===false?
                    <Loading/>
                    :
                    <div className="live-preview">
                      <form action="#">
                        <div className="row">
                          <div>
                            <div
                              className=""
                              style={{
                                borderBottom: "1px solid #d2d2d2",
                                padding: "15px 0px",
                              }}
                            >
                              <span className="db-modify_toggle_btn"></span>
                              <div>
                                <span
                                  className="bulk_update_service"
                                  onClick={clickEventHandler}
                                >
                                  BULK UPDATE
                                </span>
                                <span
                                  className="bx bxs-chevron-down service_update_down_arrow"
                                  onClick={clickEventHandler}
                                ></span>
                              </div>
                              <div
                                className={
                                  active === false ? "row d-none" : "row"
                                }
                              >
                                {hotel.length > 1 ? (
                                  <div
                                    className="col-lg-6"
                                    style={{ marginTop: "20px" }}
                                  >
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                      >
                                        Hotels{" "}
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <div className="col-md-10">
                                        <MultiSelect
                                          options={hotel}
                                          value={selectedhotel}
                                          onChange={setSelectedHotel}
                                          labelledBy="Select"
                                          selectionType="counter"
                                          closeOnChangedValue={false}
                                          aria-expanded="true"
                                        />
                                      </div>
                                      <label style={{ color: "red" }}>
                                        {formerror1.selectedhotel}
                                      </label>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {inputFields.map((data, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="row"
                                      style={{ marginTop: "15px" }}
                                    >
                                      {/* <div
                                        className="row"
                                        style={{ marginTop: "20px" }}
                                      > */}

                                      <div className="col-lg-4">
                                        <div className="input-group">
                                          <label
                                            className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default"
                                          >
                                            Services{" "}
                                            <span className="form-validation-required">
                                              *
                                            </span>
                                          </label>
                                          <Select
                                            placeholder="Select..."
                                            className="form-control"
                                            data-choices
                                            name="choices-single-default"
                                            id="choices-single-default"
                                            options={service}
                                            value={data.services}
                                            onChange={(e) =>
                                              handleChangeselect(
                                                index,
                                                e,
                                                "services"
                                              )
                                            }
                                          ></Select>
                                        </div>
                                        {formerror.map((err, i) => {
                                          return (
                                            <>
                                              {index === i &&
                                              err &&
                                              err.services ? (
                                                <label style={{ color: "red" }}>
                                                  {err.services}
                                                </label>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          );
                                        })}
                                      </div>
                                      <div className="col-lg-4">
                                        <div className="input-group">
                                          <label
                                            className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default"
                                          >
                                            Frequencies{" "}
                                            <span className="form-validation-required">
                                              *
                                            </span>
                                          </label>
                                          <Select
                                            placeholder="Select..."
                                            className="form-control"
                                            data-choices
                                            name="choices-single-default"
                                            id="choices-single-default"
                                            options={frequency}
                                            value={data.frequencies}
                                            onChange={(e) =>
                                              handleChangeselect(
                                                index,
                                                e,
                                                "frequencies"
                                              )
                                            }
                                          ></Select>
                                        </div>
                                        {formerror.map((err, i) => {
                                          return (
                                            <>
                                              {index === i &&
                                              err &&
                                              err.frequencies ? (
                                                <label style={{ color: "red" }}>
                                                  {err.frequencies}
                                                </label>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          );
                                        })}
                                      </div>
                                      <div className="col-md-1">
                                        <span
                                          className={
                                            index === inputFields.length - 1
                                              ? "extrarate-room-link "
                                              : "extrarate-room-link d-none"
                                          }
                                        >
                                          <Link
                                            className="extra-rate-add"
                                            to="#"
                                            onClick={addInputField}
                                          >
                                            +
                                          </Link>
                                        </span>
                                        <Link
                                          className={
                                            index === 0
                                              ? "remove_more d-none"
                                              : "remove_more "
                                          }
                                          to="#"
                                          onClick={(e) =>
                                            removeInputFields(index)
                                          }
                                        >
                                          X
                                        </Link>
                                      </div>
                                      <div
                                        className={
                                          index === inputFields.length - 1
                                            ? "col-md-1 page-title-box-in d-sm-flex align-items-center justify-content-between"
                                            : "d-none"
                                        }
                                        style={{ right: "20px" }}
                                      >
                                        <Link
                                          type="button"
                                          to="#"
                                          className="btn db-save-button "
                                          onClick={PostData}
                                        >
                                          Update
                                        </Link>
                                      </div>
                                    </div>
                                    // </div>
                                  );
                                })}
                              </div>
                            </div>
                            {list.map((li, i) => {
                              return (
                                <>
                                  <React.Fragment key={i}>
                                    {list.length > 0 ? (
                                      <div
                                        className={
                                          list.length > 1
                                            ? "row hotel_single_list"
                                            : "row"
                                        }
                                      >
                                        {list.length > 1 ? (
                                          <div className="hotel_service_display hotel_service_display_12">
                                            <div className="hk_service_all_hotel">
                                              <span className="">
                                                {" "}
                                                {pageindex === 1
                                                  ? i + 1
                                                  : (pageindex - 1) * 10 +
                                                    i +
                                                    1}
                                                .
                                              </span>
                                              <span
                                                style={{ marginLeft: "10px" }}
                                              >
                                                {li.hotel_name}
                                              </span>
                                            </div>
                                            {showFullData[i]?.hotel === true ? (
                                              <span
                                                className="db-modify_toggle_btn"
                                                onClick={() =>
                                                  handleDropdownClickhotel(i)
                                                }
                                              >
                                                <span
                                                  className="bx bxs-chevron-up"
                                                  style={{ fontSize: "20px" }}
                                                ></span>
                                              </span>
                                            ) : (
                                              <span
                                                className="db-modify_toggle_btn"
                                                onClick={() =>
                                                  handleDropdownClickhotel(i)
                                                }
                                              >
                                                <span
                                                  className="bx bxs-chevron-down"
                                                  style={{ fontSize: "20px" }}
                                                ></span>
                                              </span>
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <div
                                          className={
                                            showFullData[i]?.hotel === true
                                              ? ""
                                              : "d-none"
                                          }
                                        >
                                          <div className="be_form_hk_frequency_title">
                                            <span className="be_form_hk_frequency_label30">
                                              <label
                                                for="firstNameinput"
                                                className="form-label be_form_label"
                                              >
                                                Services
                                              </label>
                                            </span>
                                            <span className="be_form_hk_frequency_label30">
                                              <label
                                                for="lastNameinput"
                                                className="form-label be_form_label"
                                              >
                                                Frequencies
                                              </label>
                                            </span>
                                          </div>
                                          {li.rooms.map((user, index) => {
                                            return (
                                              <>
                                                <div
                                                  className="be_form_hk_frequency"
                                                  key={index}
                                                >
                                                  <span className="be_form_hk_frequency_label30">
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder=""
                                                      value={
                                                        user.services.label
                                                      }
                                                      name="services"
                                                    />
                                                  </span>
                                                  <span className="be_form_hk_frequency_label30">
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder=""
                                                      value={
                                                        user.frequencies.label
                                                      }
                                                      name="frequencies"
                                                    />
                                                    {/* <Select
                                                placeholder="Select..."
                                                className="form-control"
                                                data-choices
                                                name="choices-single-default"
                                                id="choices-single-default"
                                                options={frequency}
                                                value={user.frequencies}
                                                onChange={(e)=>handleChange(i,index,e)}
                                                >
                                           </Select> */}
                                                  </span>
                                                </div>
                                              </>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </React.Fragment>
                                </>
                              );
                            })}
                          </div>
                          <div className="padding-20"></div>
                        </div>
                        {paginate > 1 ? (
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={paginate}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        ) : (
                          ""
                        )}
                      </form>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Hotelservices;
