import React, { useState } from 'react'
import Footer from '../../Common/Footer'
import { Link, useLocation } from 'react-router-dom'
import Roomdetails from './Roomdetails'
import Roomphotos from './Roomphotos'
import Roomdescriptions from './Roomdescriptions'
import Roomconfiguration from './Roomconfiguration'
import Roommainfacilities from './Roommainfacilities'
import Commonheader from '../../Common/Commonheader'

function Rooms() {
   
    
    
  return (
    <Roomdetails/>

  )
}

export default Rooms