import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";

function Extrastep1({
  setTab,
  extraStep1,
  handleExtraToggle,
  handleExtraSubaddonToggle,
  extraName,
  setExtraName,
  extra1Error,
  formerror,
  nextExtrastep1,
  handleExtraRadio,
  selectedDatesstay,
  setSelectedDatesstay,
  fpstay,
  handleOpenclosestay,
  activestaydateopen,
  setActivestaydateopen,
  openclosestay,
  setOpenclosestay,
  datefromstay,
  datetostay,
  selectedWeekdaysstay,
  clickedDaysstay,
  handleApplyToCalendarstay,
  toggleSelectedDaystay,
  handleCalendarChangeFrom,
  handleCalendarChangeTo,
  selectedMulti,
  nextExtrastep1inc,
  language,
  handlechangeExtra1,
  extraStep4,
  handleExtra4Check,
  Loyaltyclublist,
  handleloyalchangeExtra4,
  handlechangeSelectStep4,
  handlechangeStep4,
  inputFields,
  addInputField,
  removeInputFields,
  removeInputFieldnew,
  sub_addon_status,
  currency,
  numberOfGuests,
  handlePriceChangeStep1,
  numberOfguestsub,
  handleOptionChangeExtrastep1,
  handleExtra1Check,
  handlechangeStep1,
  handleChangeSubaddon,
  extra_include_status,
  setExtra_include_status,
  loyalty,
  otherloyal_status,
  setOtherloyal_status,
  otherloyal,
  addInputFieldinclusion,
  removeInputFieldinclusion,
  alllanguage,
  loyallang,
  hangleotherloyal,
  handleloyalchange,
  tab2,
  setTab2,
  nextExtrastep1sub,
  extraStep3,
  handleOptionChangeExtrastep3,
  handlechangeStep3,
  handleExtra3Check,
  handlePriceChangeStep3,
  handleLanguageChange,
  handleAddonChange,
  handlelanglo,
  hangleotherloyallang,
  newLang,
  handleLanguageChangesub,
  handleAddonChangesub,
  selectedMultisub,
}) {
  const [tab1, setTab1] = useState("EN");
  const [isMatchingLanguage, setisMatchingLanguage] = useState("");
  const [isMatchingLanguagesub, setisMatchingLanguagesub] = useState("");

  const handleCalendarIconClick = (calendarId) => {
    const calendar = document.querySelector(`#flatpickr-${calendarId}`);
    if (calendar) {
      const isCalendarOpen = calendar._flatpickr.isOpen;
      const icon = document.querySelector(`#calendar-icon-${calendarId}`);
      if (icon) {
        icon.style.display = isCalendarOpen ? "none" : "inline-block";
      }
      calendar._flatpickr[isCalendarOpen ? "close" : "open"]();
    }
  };

  useEffect(() => {
    const isMatching = selectedMulti.findIndex(
      (language) => language.lang_code === newLang
    );
    setisMatchingLanguage(isMatching);
  }, [newLang]);

  console.log(newLang);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {/* <div className="offer-create-section">
       <div className="offer-create-details"> */}
          {/* <div className="col-md-12">
         <div className="form-translation-style">
          <ul>
          <li className={tab1==="EN"?"active":""} onClick={(e)=>setTab1("EN")}>EN</li>
          <li className={tab1==="TRANS"?"active":""} onClick={(e)=>setTab1("TRANS")}>Translations</li>
          </ul>
           </div>
           </div>
           {tab1==="EN"?
           <> */}
          <div className="offer-content-section">
            <span
              className={
                tab2 === "addon"
                  ? "offer-status offer-status2-addon"
                  : "offer-status d-none"
              }
            >
              <div
                className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                dir="ltr"
              >
                <input
                  type="checkbox"
                  className="form-check-input offer_checkbox1 check-box-green checkbox1_wid"
                  id="inlineswitch5"
                  style={{ width: "50px", height: "25px" }}
                  value={extraStep1.extraCheck}
                  checked={extraStep1.extraCheck === true ? true : false}
                  onChange={(e) =>
                    handleExtraToggle(e.target.checked, "extraCheck")
                  }
                />
              </div>
            </span>

            <div className="col-md-12">
              <ul className="offer-create-list_rate">
                <li
                  className={
                    tab2 === "addon"
                      ? "extra-step-list  steplist-two active"
                      : "extra-step-list steplist-two"
                  }
                  onClick={(e) => setTab2("addon")}
                >
                  Add-ons
                </li>
                <li
                  className={
                    tab2 === "subaddon"
                      ? "extra-step-list  steplist-two active"
                      : "extra-step-list steplist-two"
                  }
                  onClick={(e) => setTab2("subaddon")}
                  style={{
                    borderLeft: "1px solid #d2d2d2",
                    borderRight: "1px solid #d2d2d2",
                  }}
                >
                  Sub Add-ons
                </li>
                <li
                  className={
                    tab2 === "inclusion"
                      ? "extra-step-list steplist-two  active"
                      : "extra-step-list steplist-two"
                  }
                  onClick={(e) => setTab2("inclusion")}
                >
                  Inclusions
                </li>
              </ul>
            </div>

            {tab2 === "addon" ? (
              <>
                <div className="offer-content-section">
                  <div className="offer-form-status-section">
                    <p
                      className="offer-form-label"
                      style={{ fontSize: "14px" }}
                    >
                      Enter the addon you want to display in booking engine
                      search
                    </p>
                    <input
                      type="text"
                      className="form-control"
                      name="extra_name"
                      placeholder="Enter Add-on Here"
                      value={extraName}
                      onChange={(e) => setExtraName(e.target.value)}
                      style={{ textTransform: "capitalize" }}
                    />

                    <label style={{ color: "red" }}>
                      {extra1Error.extraName}
                    </label>
                    <div className="col-md-12">
                      <div className="col-lg-6  add-edit-col">
                        <div className="d-flex">
                          {/* <label
                      className="input-group-text input-joint-color"
                      id="inputGroup-sizing-default"
                      style={{ width: "130px", borderBottomRightRadius: "0px", borderTopRightRadius: "0px" }}>
                      Choose Language:
                    </label>
                    <div className="col-md-12" style={{ marginLeft: "-1px" }}> */}
                          {/* <Select
                        options={language}
                        value={selectedMulti.length > 0 ? selectedMulti[selectedMulti.length - 1] : null}
                        onChange={handleLanguageChange}
                        placeholder="Select..."
                        className="form-control"
                        name="choices-single-default"
                        id="choices-single-default"
                      /> */}
                          {/* <select
                      className="form-control"
                      data-choices
                      data-choices-search-false
                      name="choices-single-default"
                      id="idPayment"
                      style={{ width: "130px", height: "35px", marginLeft: "30px" }}
                      value={selectedMulti.length > 0 ? selectedMulti[selectedMulti.length - 1].value : ''}
                      onChange={(e) => handleLanguageChange(language.find(lang => lang.value === e.target.value))}
                  >
                      <option>Choose language</option>

                      {language.map((lang, inx) => {
                          return (
                              <option key={inx} value={lang.value}>
                                  {lang.label}
                              </option>
                          );
                      })}
                  </select> */}

                          <label
                            className="input-group-text input-joint-color"
                            id="inputGroup-sizing-default"
                            style={{
                              width: "130px",
                              borderBottomRightRadius: "0px",
                              borderTopRightRadius: "0px",
                            }}
                          >
                            Add/edit in
                          </label>
                          <select
                            className="form-control addeditin"
                            data-choices
                            data-choices-search-false
                            name="choices-single-default"
                            id="idPayment"
                            style={{
                              width: "130px",
                              height: "38px",
                              marginLeft: "-1px",
                            }}
                            value={newLang}
                            onChange={(e) =>
                              handleLanguageChange(e.target.value)
                            }
                          >
                            <option value="">Choose language</option>

                            {language.map((lang, inx) => {
                              return (
                                <option key={inx} value={lang.lang_code}>
                                  {lang.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div
                        className="appendmore"
                        style={{ marginTop: "7px" }}
                      ></div>
                      {newLang ? (
                        selectedMulti.length > 0 ? (
                          <>
                            {selectedMulti.some(
                              (item) => item.lang_code === newLang
                            ) ? (
                              <div
                                className="col-md-12 translation-fill-section"
                                data-value="1"
                              >
                                <div className="col-lg-6">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Add-on:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={
                                        selectedMulti[isMatchingLanguage]?.addon
                                      }
                                      onChange={(e) =>
                                        handleAddonChange(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="col-md-12 translation-fill-section"
                                data-value="1"
                              >
                                <div className="col-lg-12">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Add-on:
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={
                                        selectedMulti[selectedMulti?.length - 1]
                                          ?.addon
                                      }
                                      onChange={(e) =>
                                        handleAddonChange(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="padding-5"></div>
                    <div className="col-md-2">
                      <h6 className="offer-form-title">Rates</h6>
                    </div>
                    <ul className="offer-create--rate-list cancellation-table-scroll">
                      <li>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input per_room_once_check"
                            type="radio"
                            id="WithoutinlineRadio1"
                            name="free"
                            value={"free"}
                            checked={
                              extraStep3.price_type === "free" ? true : false
                            }
                            onChange={() =>
                              handleOptionChangeExtrastep3("free", "price_type")
                            }
                          />
                          <label>Free</label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input per_room_once_check"
                            type="radio"
                            id="WithoutinlineRadio1"
                            name="room_once"
                            value={"room_once"}
                            checked={
                              extraStep3.price_type === "room_once"
                                ? true
                                : false
                            }
                            onChange={() =>
                              handleOptionChangeExtrastep3(
                                "room_once",
                                "price_type"
                              )
                            }
                          />
                          <label>
                            Per room / once:
                            <span className="extra-label-sub">
                              ex. flowers, chocolate, late checkout,{" "}
                            </span>
                          </label>
                        </div>

                        <div
                          className={
                            extraStep3.price_type === "room_once"
                              ? "per_room_once_show"
                              : "per_room_once_show d-none"
                          }
                        >
                          <span className="extra_freenight_discount_sec">
                            <div className="extra_freenight_discount">
                              <input
                                type="number"
                                className="form-control"
                                value={extraStep3.extra_price}
                                name="extra_price"
                                onChange={handlechangeStep3}
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                              />
                              <span className="offer_freenight_option">
                                {currency.code}
                              </span>
                            </div>
                          </span>
                        </div>
                      </li>

                      <li>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input per_room_per_night_check"
                            type="radio"
                            id="WithoutinlineRadio1"
                            name="room_per_night"
                            value={"room_per_night"}
                            checked={
                              extraStep3.price_type === "room_per_night"
                                ? true
                                : false
                            }
                            onChange={() =>
                              handleOptionChangeExtrastep3(
                                "room_per_night",
                                "price_type"
                              )
                            }
                          />
                          <label>
                            Per room / per night:
                            <span className="extra-label-sub">
                              ex. cleaning, crib, etc.
                            </span>
                          </label>
                        </div>

                        <div
                          className={
                            extraStep3.price_type === "room_per_night"
                              ? "per_room_per_night_show"
                              : "per_room_per_night_show d-none"
                          }
                        >
                          <span className="extra_freenight_discount_sec">
                            <div className="extra_freenight_discount">
                              <input
                                type="number"
                                className="form-control"
                                value={extraStep3.extra_price}
                                name="extra_price"
                                onChange={handlechangeStep3}
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                              />
                              <span className="offer_freenight_option">
                                {currency.code}
                              </span>
                            </div>
                          </span>
                        </div>

                        <div
                          className={
                            extraStep3.price_type === "room_per_night"
                              ? "per_room_per_night_show extra-left-padding-5 form-check form-check-inline"
                              : "per_room_per_night_show extra-left-padding-5 form-check form-check-inline d-none"
                          }
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="formCheck1"
                            value={extraStep3.extra3Check}
                            checked={
                              extraStep3.extra3Check === true ? true : false
                            }
                            onChange={(e) =>
                              handleExtra3Check(e.target.checked, "extra3Check")
                            }
                          />
                          <label className="offer-step-label">
                            <span className="offer_freeningt_join">
                              The client can choose the number of nights he
                              wants to enjoy it
                            </span>
                          </label>
                        </div>
                      </li>

                      <li>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input per_person_once_check"
                            type="radio"
                            id="WithoutinlineRadio1"
                            name="person_once"
                            value={"person_once"}
                            checked={
                              extraStep3.price_type === "person_once"
                                ? true
                                : false
                            }
                            onChange={() =>
                              handleOptionChangeExtrastep3(
                                "person_once",
                                "price_type"
                              )
                            }
                          />
                          <label>
                            Per person / once:
                            <span className="extra-label-sub">
                              ex. tickets, tours, etc.
                            </span>
                          </label>
                        </div>

                        <div
                          className={
                            extraStep3.price_type === "person_once"
                              ? "per_person_once_show"
                              : "per_person_once_show d-none"
                          }
                        >
                          <span className="extra_freenight_discount_sec">
                            <div className="extra_freenight_discount">
                              <input
                                type="number"
                                className="form-control"
                                value={extraStep3.extra_price}
                                name="extra_price"
                                onChange={handlechangeStep3}
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                              />
                              <span className="offer_freenight_option">
                                {currency.code}
                              </span>
                            </div>
                          </span>
                        </div>

                        <ul
                          className={
                            extraStep3.price_type === "person_once"
                              ? "offer-create-lists per_person_once_show"
                              : "offer-create-lists per_person_once_show d-none"
                          }
                        >
                          <li>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input "
                                type="radio"
                                id="WithoutinlineRadio2"
                                name="person"
                                value={"person"}
                                checked={
                                  extraStep3.price_age_category === "person"
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  handleOptionChangeExtrastep3(
                                    "person",
                                    "price_age_category"
                                  )
                                }
                              />
                              <label className="offer-step-label">
                                <span className="offer_freeningt_join">
                                  Price per person{" "}
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input "
                                type="radio"
                                id="WithoutinlineRadio2"
                                name="adult"
                                value={"adult"}
                                checked={
                                  extraStep3.price_age_category === "adult"
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  handleOptionChangeExtrastep3(
                                    "adult",
                                    "price_age_category"
                                  )
                                }
                              />
                              <label className="offer-step-label">
                                <span className="offer_freeningt_join">
                                  Price per adult{" "}
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input "
                                type="radio"
                                id="WithoutinlineRadio2"
                                name="child"
                                value={"child"}
                                checked={
                                  extraStep3.price_age_category === "child"
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  handleOptionChangeExtrastep3(
                                    "child",
                                    "price_age_category"
                                  )
                                }
                              />
                              <label className="offer-step-label">
                                <span className="offer_freeningt_join">
                                  Price per child{" "}
                                </span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input per_person_per_night_check"
                            type="radio"
                            id="WithoutinlineRadio1"
                            name="person_per_night"
                            value={"person_per_night"}
                            checked={
                              extraStep3.price_type === "person_per_night"
                                ? true
                                : false
                            }
                            onChange={() =>
                              handleOptionChangeExtrastep3(
                                "person_per_night",
                                "price_type"
                              )
                            }
                          />
                          <label>
                            Per person / per night:
                            <span className="extra-label-sub">
                              ex. VIP treatment, etc.
                            </span>
                          </label>
                        </div>

                        <div
                          className={
                            extraStep3.price_type === "person_per_night"
                              ? "per_person_per_night_show"
                              : "per_person_per_night_show d-none"
                          }
                        >
                          <span className="extra_freenight_discount_sec">
                            <div className="extra_freenight_discount">
                              <input
                                type="number"
                                className="form-control"
                                value={extraStep3.extra_price}
                                name="extra_price"
                                onChange={handlechangeStep3}
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                              />
                              <span className="offer_freenight_option">
                                {currency.code}
                              </span>
                            </div>
                          </span>
                        </div>
                        <ul
                          className={
                            extraStep3.price_type === "person_per_night"
                              ? "offer-create-lists per_person_per_night_show"
                              : "offer-create-lists per_person_per_night_show d-none"
                          }
                        >
                          <li>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input "
                                type="radio"
                                id="WithoutinlineRadio2"
                                name="person"
                                value={"person"}
                                checked={
                                  extraStep3.price_age_category === "person"
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  handleOptionChangeExtrastep3(
                                    "person",
                                    "price_age_category"
                                  )
                                }
                              />
                              <label className="offer-step-label">
                                <span className="offer_freeningt_join">
                                  Price per person{" "}
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input "
                                type="radio"
                                id="WithoutinlineRadio2"
                                name="adult"
                                value={"adult"}
                                checked={
                                  extraStep3.price_age_category === "adult"
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  handleOptionChangeExtrastep3(
                                    "adult",
                                    "price_age_category"
                                  )
                                }
                              />
                              <label className="offer-step-label">
                                <span className="offer_freeningt_join">
                                  Price per adult{" "}
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input "
                                type="radio"
                                id="WithoutinlineRadio2"
                                name="child"
                                value={"child"}
                                checked={
                                  extraStep3.price_age_category === "child"
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  handleOptionChangeExtrastep3(
                                    "child",
                                    "price_age_category"
                                  )
                                }
                              />
                              <label className="offer-step-label">
                                <span className="offer_freeningt_join">
                                  Price per child{" "}
                                </span>
                              </label>
                            </div>
                          </li>
                        </ul>
                        {/* <div className={extraStep3.price_type === 'person_per_night'?"per_person_per_night_show":"per_person_per_night_show d-none"}>
          </div> */}

                        <div
                          className={
                            extraStep3.price_type === "person_per_night"
                              ? "per_person_per_night_show extra-left-padding-5 form-check form-check-inline"
                              : "per_person_per_night_show extra-left-padding-5 form-check form-check-inline d-none"
                          }
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="formCheck1"
                            value={extraStep3.client_preference_status}
                            checked={
                              extraStep3.client_preference_status === true
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              handleExtra3Check(
                                e.target.checked,
                                "client_preference_status"
                              )
                            }
                          />
                          <label className="offer-step-label">
                            <span className="offer_freeningt_join">
                              The client can choose the number of nights he
                              wants to enjoy it
                            </span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input no_person_once_check"
                            type="radio"
                            name="person_range_once"
                            id="WithoutinlineRadio1"
                            value={"person_range_once"}
                            checked={
                              extraStep3.price_type === "person_range_once"
                                ? true
                                : false
                            }
                            onChange={() =>
                              handleOptionChangeExtrastep3(
                                "person_range_once",
                                "price_type"
                              )
                            }
                          />
                          <label>
                            Per number of persons / once:
                            <span className="extra-label-sub">
                              ex. airport shuttle, etc.
                            </span>
                          </label>
                        </div>
                        <div
                          className={
                            extraStep3.price_type === "person_range_once"
                              ? "no_person_once_show no_person_once_tabel_sec"
                              : "no_person_once_show no_person_once_tabel_sec d-none"
                          }
                        >
                          <table className="extra-table-style">
                            <tbody>
                              <tr>
                                <td>
                                  <span className="extra-table-label">
                                    Total Price:
                                  </span>
                                </td>
                                {numberOfGuests.map((pric, index) => {
                                  return (
                                    <td key={index}>
                                      <input
                                        className="form-control extra-table-td"
                                        style={{
                                          width: "60px",
                                          textAlign: "center",
                                        }}
                                        name="price"
                                        value={pric.price}
                                        onChange={(e) =>
                                          handlePriceChangeStep3(index, e)
                                        }
                                      />
                                    </td>
                                  );
                                })}
                                <td>{currency.code}</td>
                              </tr>
                              <tr>
                                <td>guests</td>
                                {/* <td><span className="extra-table-label-sub">for:</span></td> */}
                                {numberOfGuests.map((guest, index) => {
                                  return <td key={index}>{index + 1}</td>;
                                })}
                                {/* <td>guest</td> */}
                              </tr>
                              <tr>
                                <td></td>
                              </tr>
                              <tr>
                                <td
                                  colSpan="14"
                                  className="extra_table_info_note"
                                >
                                  You may leave prices empty (not available for
                                  that number of people) or set the price to 0
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {/* <table className="extra-table-style">
              <tr>
                <td><span className="extra-table-label">Total Price:</span></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td>AED</td>
              </tr>
              <tr>
                <td><span className="extra-table-label-sub">for:</span></td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
                <td>8</td>
                <td>9</td>
                <td>10</td>
                <td>11</td>
                <td>12</td>
                <td>guest</td>
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td colspan="14" className="extra_table_info_note">You may leave prices empty (not available for that number of people) or set the price to 0 (free)</td>
              </tr>
            </table> */}
                        </div>
                      </li>
                      <li>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input once_per_reservation_check"
                            type="radio"
                            name="reserve_once"
                            id="WithoutinlineRadio1"
                            value={"reserve_once"}
                            checked={
                              extraStep3.price_type === "reserve_once"
                                ? true
                                : false
                            }
                            onChange={() =>
                              handleOptionChangeExtrastep3(
                                "reserve_once",
                                "price_type"
                              )
                            }
                          />
                          <label>
                            Once per reservation:
                            <span className="extra-label-sub">
                              ex.personal shopper{" "}
                            </span>
                          </label>
                        </div>
                        <div
                          className={
                            extraStep3.price_type === "reserve_once"
                              ? "once_per_reservation_show"
                              : "once_per_reservation_show d-none"
                          }
                        >
                          <span className="extra_freenight_discount_sec">
                            <div className="extra_freenight_discount">
                              <input
                                type="number"
                                className="form-control"
                                value={extraStep3.extra_price}
                                name="extra_price"
                                onChange={handlechangeStep3}
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                              />
                              <span className="offer_freenight_option">
                                {currency.code}
                              </span>
                            </div>
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input reservation_per_night_check"
                            type="radio"
                            id="WithoutinlineRadio1"
                            name="reserve_per_night"
                            value={"reserve_per_night"}
                            checked={
                              extraStep3.price_type === "reserve_per_night"
                                ? true
                                : false
                            }
                            onChange={() =>
                              handleOptionChangeExtrastep3(
                                "reserve_per_night",
                                "price_type"
                              )
                            }
                          />
                          <label>
                            One unit per reservation / per night:
                            <span className="extra-label-sub">
                              One unit per reservation / per night
                            </span>
                          </label>
                        </div>

                        <div
                          className={
                            extraStep3.price_type === "reserve_per_night"
                              ? "reservation_per_night_show"
                              : "reservation_per_night_show d-none"
                          }
                        >
                          <span className="extra_freenight_discount_sec">
                            <div className="extra_freenight_discount">
                              <input
                                type="number"
                                className="form-control"
                                value={extraStep3.extra_price}
                                name="extra_price"
                                onChange={handlechangeStep3}
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                              />
                              <span className="offer_freenight_option">
                                {currency.code}
                              </span>
                            </div>
                          </span>
                        </div>
                        <div
                          className={
                            extraStep3.price_type === "reserve_per_night"
                              ? "reservation_per_night_show extra-left-padding-5 form-check form-check-inline"
                              : "reservation_per_night_show extra-left-padding-5 form-check form-check-inline d-none"
                          }
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="formCheck1"
                            value={extraStep3.client_preference_status}
                            checked={
                              extraStep3.client_preference_status === true
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              handleExtra3Check(
                                e.target.checked,
                                "client_preference_status"
                              )
                            }
                          />
                          <label className="offer-step-label">
                            <span className="offer_freeningt_join">
                              The client can choose the number of nights he
                              wants to enjoy it
                            </span>
                          </label>
                        </div>
                      </li>
                      <label style={{ color: "red", fontWeight: "500" }}>
                        {extra1Error.extra_price}
                      </label>
                    </ul>
                    <div
                      className={
                        extraStep1.extraCheck === true
                          ? "extra-conditions"
                          : "extra-conditions d-none"
                      }
                      style={{ marginTop: "35px" }}
                    >
                      <div className="offer-condition-check">
                        <input
                          className="form-check-input form-stay-check"
                          type="checkbox"
                          id="formCheck1"
                          onChange={(e) =>
                            handleExtraToggle(
                              e.target.checked,
                              "availabilty_status"
                            )
                          }
                          value={extraStep1.availabilty_status}
                          checked={
                            extraStep1.availabilty_status === true
                              ? true
                              : false
                          }
                        />
                        <label className="form-check-label" for="formCheck1">
                          Only offer in reservations:
                        </label>
                      </div>

                      <div
                        className={
                          extraStep1.availabilty_status === true
                            ? "offer-condition-select form-extra-check-show"
                            : "offer-condition-select form-extra-check-show d-none"
                        }
                      >
                        <select
                          className="form-select offer-create-select"
                          name="availabilty_type"
                          value={extraStep1.availabilty_type}
                          onChange={handlechangeExtra1}
                        >
                          <option value="all_between">
                            With all days within
                          </option>
                          <option value="any_in">
                            With at least one day in
                          </option>
                          <option value="check_in">That checkin</option>
                          <option value="check_out">That checkout</option>
                        </select>
                      </div>
                      <div
                        className={
                          extraStep1.availabilty_status === true
                            ? "offer-condition-head-sec"
                            : "offer-condition-head-sec d-none"
                        }
                      >
                        <span className="info-tag">
                          i
                          <span className="tooltiptext">
                            Would you like to make the offer available for users
                            only if it coincides with particular check-in dates?<br></br>
                            Enable and choose the check-in dates.
                          </span>
                        </span>
                        <p
                          className="offer-condition-calendar-choose checkin_choose_status ad_on_open_close"
                          onClick={(e) =>
                            setActivestaydateopen(!activestaydateopen)
                          }
                        >
                          Open/Close period
                        </p>
                      </div>
                      <br></br>
                      <br></br>
                      <div className="col-md-12">
                        <div
                          className={
                            extraStep1.availabilty_status === true
                              ? "offer-condition-calendar-pic  form-extra-check-show calender-pic-mar"
                              : " d-none"
                          }
                        >
                          <div className="clearfix"></div>
                          <div
                            className="offer-condition-calendar  form-calender-add form-stay-check-show open-close-period-wid flatpickr-wid-adj"
                            style={{ width: "310px", height: "315px" }}
                          >
                            <Flatpickr
                              ref={fpstay}
                              className="offer_flat"
                              value={selectedDatesstay}
                              onChange={(date) => setSelectedDatesstay(date)}
                              options={{
                                mode: "multiple",
                                inline: true,
                              }}
                            />
                          </div>
                          <p
                            className="offer-condition-calendar-choose checkin_choose_status ad_on_open_close_res "
                            onClick={(e) =>
                              setActivestaydateopen(!activestaydateopen)
                            }
                          >
                            Open/Close period
                          </p>

                          <div
                            className={
                              activestaydateopen === true
                                ? "offer-condition-apply offer-apply-extras stay_choose_open "
                                : "offer-condition-apply  stay_choose_open  d-none"
                            }
                          >
                            <span className="offer-condition-close">
                              <Link to="" onClick={handleOpenclosestay}>
                                X
                              </Link>
                            </span>
                            <div className="offer-condition-apply-inner-section">
                              <select
                                className="form-select"
                                value={openclosestay}
                                onChange={(e) =>
                                  setOpenclosestay(e.target.value)
                                }
                              >
                                <option value="open">Open</option>
                                <option value="close">Close</option>
                              </select>
                              <div className="offer-condition-apply-calender">
                                <div className="offer-condition-check">
                                  <form action="#">
                                    <div className="input-group ">
                                      <Flatpickr
                                        id="flatpickr-from"
                                        className="form-control dash-filter-picker shadow   fs-13 form-control flatpickr-input active"
                                        placeholder="FROM"
                                        options={{
                                          mode: "single",
                                          dateFormat: "d M, Y",
                                        }}
                                        value={datefromstay}
                                        onChange={handleCalendarChangeFrom}
                                      />
                                      <span
                                        className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                        onClick={() =>
                                          handleCalendarIconClick("from")
                                        }
                                      ></span>
                                    </div>
                                  </form>
                                </div>
                                <div className="offer-condition-check">
                                  <form action="#">
                                    <div className="input-group mar-top">
                                      <Flatpickr
                                        id="flatpickr-to"
                                        className="form-control dash-filter-picker  shadow fs-13 form-control flatpickr-input active"
                                        placeholder="UNTIL"
                                        options={{
                                          mode: "single",
                                          dateFormat: "d M, Y",
                                        }}
                                        value={datetostay}
                                        onChange={handleCalendarChangeTo}
                                      />
                                      <span
                                        className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                        onClick={() =>
                                          handleCalendarIconClick("to")
                                        }
                                      ></span>
                                    </div>
                                  </form>
                                </div>
                              </div>
                              <div className="offer-condition-week">
                                <ul>
                                  <li>
                                    <p>Mon</p>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox6"
                                      value="option1"
                                      checked={
                                        selectedWeekdaysstay.includes(1) &&
                                        !clickedDaysstay.includes(1)
                                          ? true
                                          : false
                                      }
                                      onChange={() => toggleSelectedDaystay(1)}
                                    />
                                  </li>
                                  <li>
                                    <p>Tue</p>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox6"
                                      value="option1"
                                      checked={
                                        selectedWeekdaysstay.includes(2) &&
                                        !clickedDaysstay.includes(2)
                                          ? true
                                          : false
                                      }
                                      onChange={() => toggleSelectedDaystay(2)}
                                    />
                                  </li>
                                  <li>
                                    <p>Wed</p>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox6"
                                      value="option1"
                                      checked={
                                        selectedWeekdaysstay.includes(3) &&
                                        !clickedDaysstay.includes(3)
                                          ? true
                                          : false
                                      }
                                      onChange={() => toggleSelectedDaystay(3)}
                                    />
                                  </li>
                                  <li>
                                    <p>Thu</p>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox6"
                                      value="option1"
                                      checked={
                                        selectedWeekdaysstay.includes(4) &&
                                        !clickedDaysstay.includes(4)
                                          ? true
                                          : false
                                      }
                                      onChange={() => toggleSelectedDaystay(4)}
                                    />
                                  </li>
                                  <li>
                                    <p>Fri</p>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox6"
                                      value="option1"
                                      checked={
                                        selectedWeekdaysstay.includes(5) &&
                                        !clickedDaysstay.includes(5)
                                          ? true
                                          : false
                                      }
                                      onChange={() => toggleSelectedDaystay(5)}
                                    />
                                  </li>
                                  <li>
                                    <p>Sat</p>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox6"
                                      value="option1"
                                      checked={
                                        selectedWeekdaysstay.includes(6) &&
                                        !clickedDaysstay.includes(6)
                                          ? true
                                          : false
                                      }
                                      onChange={() => toggleSelectedDaystay(6)}
                                    />
                                  </li>
                                  <li>
                                    <p>Sun</p>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox6"
                                      value="option1"
                                      checked={
                                        selectedWeekdaysstay.includes(0) &&
                                        !clickedDaysstay.includes(0)
                                          ? true
                                          : false
                                      }
                                      onChange={() => toggleSelectedDaystay(0)}
                                    />
                                  </li>
                                </ul>
                              </div>
                              <div className="condition-apply-calendar-btn-sec">
                                <Link
                                  className="condition-apply-calendar-btn"
                                  onClick={handleApplyToCalendarstay}
                                >
                                  Apply to calendar
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="offer-create-details">
                      <div className="reserve-wid-add" style={{ width: "32%" }}>
                        <h6 className="offer-form-title">
                          Only offer in reservations with
                        </h6>
                        <div className="padding-5"></div>
                        <ul className="offer-create--rate-list">
                          <li>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input extra_min_stay_check"
                                type="checkbox"
                                id="formCheck1"
                                value={extraStep4.min_stay_status}
                                checked={
                                  extraStep4.min_stay_status === true
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  handleExtra4Check(
                                    e.target.checked,
                                    "min_stay_status"
                                  )
                                }
                              />
                              <label className="offer-step-label">
                                <span className="offer_freeningt_join">
                                  Minimum stay
                                </span>
                                <span
                                  className={
                                    extraStep4.min_stay_status === true
                                      ? "offer_freeningt_join extra_min_stay_show"
                                      : "offer_freeningt_join extra_min_stay_show d-none"
                                  }
                                >
                                  <select
                                    className="form-select box_style"
                                    value={extraStep4.min_stay_days}
                                    name="min_stay_days"
                                    onChange={handlechangeSelectStep4}
                                  >
                                    {[...Array(30).keys()].map((value) => (
                                      <option key={value + 1} value={value + 1}>
                                        {value + 1}
                                      </option>
                                    ))}
                                  </select>
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input extra_max_stay_check"
                                type="checkbox"
                                id="formCheck1"
                                value={extraStep4.max_stay_status}
                                checked={
                                  extraStep4.max_stay_status === true
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  handleExtra4Check(
                                    e.target.checked,
                                    "max_stay_status"
                                  )
                                }
                              />
                              <label className="offer-step-label">
                                <span className="offer_freeningt_join">
                                  Maximum stay
                                </span>
                                <span
                                  className={
                                    extraStep4.max_stay_status === true
                                      ? "offer_freeningt_join extra_max_stay_show"
                                      : "offer_freeningt_join extra_max_stay_show d-none"
                                  }
                                >
                                  <select
                                    className="form-select box_style"
                                    value={extraStep4.max_stay_days}
                                    name="max_stay_days"
                                    onChange={handlechangeSelectStep4}
                                  >
                                    {[...Array(30).keys()].map((value) => (
                                      <option key={value + 1} value={value + 1}>
                                        {value + 1}
                                      </option>
                                    ))}
                                  </select>
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input extra_advance_days_check"
                                type="checkbox"
                                id="formCheck1"
                                value={extraStep4.days_in_advance_status}
                                checked={
                                  extraStep4.days_in_advance_status === true
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  handleExtra4Check(
                                    e.target.checked,
                                    "days_in_advance_status"
                                  )
                                }
                              />
                              <label className="offer-step-label">
                                <span className="offer_freeningt_join">
                                  Days in advance
                                </span>
                                <span
                                  className={
                                    extraStep4.days_in_advance_status === true
                                      ? "offer_freeningt_join extra_advance_days_show"
                                      : "offer_freeningt_join extra_advance_days_show d-none"
                                  }
                                >
                                  <input
                                    className="form-control extra-input-field box_style_is"
                                    value={extraStep4.days_in_advance}
                                    name="days_in_advance"
                                    onChange={handlechangeStep4}
                                  />
                                  <label style={{ color: "red" }}>
                                    {extra1Error.days_in_advance}
                                  </label>
                                </span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input restr_loyalty_check"
                                type="checkbox"
                                id="formCheck1"
                                onChange={(e) =>
                                  handleExtra4Check(
                                    e.target.checked,
                                    "loyalty_active_status"
                                  )
                                }
                                value={extraStep4.loyalty_active_status}
                                checked={
                                  extraStep4.loyalty_active_status === true
                                    ? true
                                    : false
                                }
                              />
                              <label> Only for the loyalty club </label>
                            </div>
                            <div
                              className={
                                extraStep4.loyalty_active_status === true
                                  ? "restr_loyalty_expand"
                                  : "restr_loyalty_expand d-none"
                              }
                            >
                              <ul
                                className="rest_list_inline grid_addon"
                                style={{ width: "520px" }}
                              >
                                {Loyaltyclublist.map((loyal, index) => {
                                  return (
                                    <li key={index}>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck1"
                                        value={loyal.selected}
                                        checked={
                                          loyal.selected === true ? true : false
                                        }
                                        onChange={(e) =>
                                          handleloyalchangeExtra4(
                                            e.target.checked,
                                            index
                                          )
                                        }
                                      />
                                      <label style={{ marginLeft: "8px" }}>
                                        {" "}
                                        {loyal.label}
                                      </label>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="padding-5"></div>

                    <div className="col-md-12">
                      <div
                        className="extra-provide-sec"
                        style={{ marginTop: "12px" }}
                      >
                        <label className="form-check-label" for="formCheck1">
                          Who provides this extra?
                        </label>
                        <br></br>

                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input provide_hotel_check"
                            type="radio"
                            name="inlineRadioOptions"
                            id="WithoutinlineRadio1"
                            onChange={(e) =>
                              handleExtraToggle("hotel", "provider")
                            }
                            value={"hotel"}
                            checked={
                              extraStep1.provider === "hotel" ? true : false
                            }
                          />
                          My hotel
                        </div>
                        <br></br>

                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input external_provider_check"
                            type="radio"
                            name="inlineRadioOptions"
                            id="WithoutinlineRadio1"
                            onChange={(e) =>
                              handleExtraRadio("external", "provider")
                            }
                            value={"external"}
                            checked={
                              extraStep1.provider === "external" ? true : false
                            }
                          />
                          An external provider
                        </div>

                        <div
                          className={
                            extraStep1.provider === "external"
                              ? "extra_provider_show"
                              : "extra_provider_show d-none"
                          }
                        >
                          <span>
                            If you want to notify the external provider that a
                            reservation has just been made, please type its
                            email here
                          </span>
                          <input
                            className="form-control extra-input-full"
                            name="notify_email"
                            value={extraStep1.notify_email}
                            onChange={handlechangeExtra1}
                          />
                          <span className="extra_table_info_note">
                            (The e-mail will only include the reserved extra,
                            the amounts, the name of the client, the booking
                            dates and the reservation code)
                          </span>
                        </div>
                        <label style={{ color: "red", marginLeft: "22px" }}>
                          {extra1Error.notify_email}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab_nav_section">
                  <div className="tab_nav_section_btns">
                    <Link className="btn btn-be-form-cancel btn-be-back" to="/add-on">
                      Cancel
                    </Link>
                    <Link
                      className="btn btn-success btn-be-common"
                      to="#"
                      onClick={nextExtrastep1}
                    >
                      Next
                    </Link>
                  </div>
                </div>
              </>
            ) : tab2 === "subaddon" ? (
              <>
                <div className="offer-content-section">
                  <div className="offer-form-status-section">
                    <h6 className="offer-step-desc">
                      {" "}
                      If you wish to create a new sub add-on, please ensure that
                      the button is enabled.
                    </h6>

                    <div style={{ marginTop: "4%" }}>
                      <h6 className="offer-form-title subaddon-tittle">
                        Sub Add-ons
                      </h6>

                      <div
                        className="form-check form-switch form-switch-danger form-check-inline condition-active-status subaddon-adj"
                        dir="ltr"
                        style={{ marginLeft: "10%", bottom: "30px" }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input offer_checkbox1 checkbox-move"
                          id="inlineswitch5"
                          style={{ width: "50px", height: "25px" }}
                          value={sub_addon_status.sub_addon_status}
                          checked={
                            sub_addon_status.sub_addon_status === true
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleExtraSubaddonToggle(
                              e.target.checked,
                              "sub_addon_status"
                            )
                          }
                        />
                      </div>
                      {sub_addon_status.sub_addon_status === true
                        ? inputFields.map((data, index) => {
                            const isMatchingLanguagesub =
                              data.multi_lang.findIndex(
                                (language) =>
                                  language.lang_code === data.newlangsub
                              );

                            return (
                              <div key={index} className="subaddon_sep">
                                <h6
                                  className="subaddon_top-25"
                                  style={{ marginTop: "5%" }}
                                >
                                  Sub Add-on
                                </h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Sub-addon Here"
                                  value={data.subaddons}
                                  onChange={(e) =>
                                    handleChangeSubaddon(index, e, "subaddons")
                                  }
                                  name="subaddons"
                                  style={{ textTransform: "capitalize" }}
                                />
                                {formerror.map((err, i) => {
                                  return (
                                    <>
                                      {index === i && err && err.subaddons ? (
                                        <label style={{ color: "red" }}>
                                          {err.subaddons}
                                        </label>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                })}

                                <div
                                  className="col-md-12"
                                  style={{ marginTop: "15px" }}
                                >
                                  <div className="col-lg-6">
                                    <div className="d-flex">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                        style={{
                                          width: "130px",
                                          borderBottomRightRadius: "0px",
                                          borderTopRightRadius: "0px",
                                        }}
                                      >
                                        Add/edit in
                                      </label>
                                      <select
                                        className="form-control addeditin"
                                        data-choices
                                        data-choices-search-false
                                        name="choices-single-default"
                                        id="idPayment"
                                        style={{
                                          width: "130px",
                                          height: "38px",
                                          marginLeft: "-1px",
                                        }}
                                        value={data.newlangsub}
                                        onChange={(e) =>
                                          handleLanguageChangesub(
                                            index,
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="">
                                          Choose language
                                        </option>

                                        {language.map((lang, inx) => {
                                          return (
                                            <option
                                              key={inx}
                                              value={lang.lang_code}
                                            >
                                              {lang.label}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                  <div
                                    className="appendmore"
                                    style={{ marginTop: "7px" }}
                                  ></div>
                                  {data.newlangsub ? (
                                    data.multi_lang?.length > 0 ? (
                                      <>
                                        {data.multi_lang.some(
                                          (item) =>
                                            item.lang_code === data.newlangsub
                                        ) ? (
                                          <div
                                            className="col-md-12 translation-fill-section"
                                            data-value="1"
                                          >
                                            <div className="col-lg-6">
                                              <div className="input-group">
                                                <label
                                                  className="input-group-text input-joint-color"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  Sub Add-on:
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder=""
                                                  value={
                                                    data.multi_lang[
                                                      isMatchingLanguagesub
                                                    ]?.subaddon
                                                  }
                                                  onChange={(e) =>
                                                    handleAddonChangesub(
                                                      index,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            className="col-md-12 translation-fill-section"
                                            data-value="1"
                                          >
                                            <div className="col-lg-12">
                                              <div className="input-group">
                                                <label
                                                  className="input-group-text input-joint-color"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  Sub Add-on:
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder=""
                                                  value={
                                                    data.multi_lang[
                                                      data.mulit_lang?.length -
                                                        1
                                                    ]?.subaddon
                                                  }
                                                  onChange={(e) =>
                                                    handleAddonChangesub(
                                                      data.mulit_lang?.length -
                                                        1,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <h6 className="offer-form-title">Rates</h6>
                                <ul className="offer-create--rate-list cancellation-table-scroll">
                                  <li>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input per_room_once_check"
                                        type="radio"
                                        id={`freeRadio_${index}`}
                                        name={`price_type${index}`}
                                        value="free"
                                        checked={data.price_type === "free"}
                                        onChange={() =>
                                          handleOptionChangeExtrastep1(
                                            index,
                                            "price_type",
                                            "free"
                                          )
                                        }
                                      />
                                      <label>
                                        Free
                                        <span className="extra-label-sub">
                                          {" "}
                                        </span>
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input per_room_once_check"
                                        type="radio"
                                        id="WithoutinlineRadio1"
                                        name={`price_type${index}`}
                                        value="room_once"
                                        checked={
                                          data.price_type === "room_once"
                                        }
                                        onChange={() =>
                                          handleOptionChangeExtrastep1(
                                            index,
                                            "price_type",
                                            "room_once"
                                          )
                                        }
                                      />
                                      <label>
                                        Per room / once:
                                        <span className="extra-label-sub">
                                          ex. flowers, chocolate, late checkout,{" "}
                                        </span>
                                      </label>
                                    </div>

                                    <div
                                      className={
                                        data.price_type === "room_once"
                                          ? "per_room_once_show"
                                          : "per_room_once_show d-none"
                                      }
                                    >
                                      <span className="extra_freenight_discount_sec">
                                        <div className="extra_freenight_discount">
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={data.extra_price}
                                            name="extra_price"
                                            onChange={(e) =>
                                              handlechangeStep1(index, e)
                                            }
                                            onFocus={(e) =>
                                              e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                  e.preventDefault();
                                                },
                                                { passive: false }
                                              )
                                            }
                                          />
                                          <span className="offer_freenight_option">
                                            {currency.code}
                                          </span>
                                        </div>
                                      </span>
                                    </div>
                                  </li>

                                  <li>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input per_room_per_night_check"
                                        type="radio"
                                        id="WithoutinlineRadio1"
                                        name={`price_type${index}`}
                                        value="room_per_night"
                                        checked={
                                          data.price_type === "room_per_night"
                                        }
                                        onChange={() =>
                                          handleOptionChangeExtrastep1(
                                            index,
                                            "price_type",
                                            "room_per_night"
                                          )
                                        }
                                      />
                                      <label>
                                        Per room / per night:
                                        <span className="extra-label-sub">
                                          ex. cleaning, crib, etc.
                                        </span>
                                      </label>
                                    </div>

                                    <div
                                      className={
                                        data.price_type === "room_per_night"
                                          ? "per_room_per_night_show"
                                          : "per_room_per_night_show d-none"
                                      }
                                    >
                                      <span className="extra_freenight_discount_sec">
                                        <div className="extra_freenight_discount">
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={data.extra_price}
                                            name="extra_price"
                                            onChange={(e) =>
                                              handlechangeStep1(index, e)
                                            }
                                            onFocus={(e) =>
                                              e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                  e.preventDefault();
                                                },
                                                { passive: false }
                                              )
                                            }
                                          />
                                          <span className="offer_freenight_option">
                                            {currency.code}
                                          </span>
                                        </div>
                                      </span>
                                    </div>

                                    <div
                                      className={
                                        data.price_type === "room_per_night"
                                          ? "per_room_per_night_show extra-left-padding-5 form-check form-check-inline"
                                          : "per_room_per_night_show extra-left-padding-5 form-check form-check-inline d-none"
                                      }
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck1"
                                        value={data.extra3Check}
                                        checked={
                                          data.extra3Check === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleExtra1Check(
                                            index,
                                            "extra3Check",
                                            e.target.checked
                                          )
                                        }
                                      />
                                      <label className="offer-step-label">
                                        <span className="offer_freeningt_join">
                                          The client can choose the number of
                                          nights he wants to enjoy it
                                        </span>
                                      </label>
                                    </div>
                                  </li>

                                  <li>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input per_person_once_check"
                                        type="radio"
                                        id="WithoutinlineRadio1"
                                        name={`price_type${index}`}
                                        value={"person_once"}
                                        checked={
                                          data.price_type === "person_once"
                                        }
                                        onChange={() =>
                                          handleOptionChangeExtrastep1(
                                            index,
                                            "price_type",
                                            "person_once"
                                          )
                                        }
                                      />
                                      <label>
                                        Per person / once:
                                        <span className="extra-label-sub">
                                          ex. tickets, tours, etc.
                                        </span>
                                      </label>
                                    </div>

                                    <div
                                      className={
                                        data.price_type === "person_once"
                                          ? "per_person_once_show"
                                          : "per_person_once_show d-none"
                                      }
                                    >
                                      <span className="extra_freenight_discount_sec">
                                        <div className="extra_freenight_discount">
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={data.extra_price}
                                            name="extra_price"
                                            onChange={(e) =>
                                              handlechangeStep1(index, e)
                                            }
                                            onFocus={(e) =>
                                              e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                  e.preventDefault();
                                                },
                                                { passive: false }
                                              )
                                            }
                                          />
                                          <span className="offer_freenight_option">
                                            {currency.code}
                                          </span>
                                        </div>
                                      </span>
                                    </div>

                                    <ul
                                      className={
                                        data.price_type === "person_once"
                                          ? "offer-create-lists per_person_once_show"
                                          : "offer-create-lists per_person_once_show d-none"
                                      }
                                    >
                                      <li>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input "
                                            type="radio"
                                            id="WithoutinlineRadio2"
                                            name="person"
                                            value="person"
                                            checked={
                                              data.price_age_category ===
                                              "person"
                                            }
                                            onChange={() =>
                                              handleOptionChangeExtrastep1(
                                                index,
                                                "price_age_category",
                                                "person"
                                              )
                                            }
                                          />
                                          <label className="offer-step-label">
                                            <span className="offer_freeningt_join">
                                              Price per person{" "}
                                            </span>
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input "
                                            type="radio"
                                            id="WithoutinlineRadio2"
                                            name="adult"
                                            value="adult"
                                            checked={
                                              data.price_age_category ===
                                              "adult"
                                            }
                                            onChange={() =>
                                              handleOptionChangeExtrastep1(
                                                index,
                                                "price_age_category",
                                                "adult"
                                              )
                                            }
                                          />
                                          <label className="offer-step-label">
                                            <span className="offer_freeningt_join">
                                              Price per adult{" "}
                                            </span>
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input "
                                            type="radio"
                                            id="WithoutinlineRadio2"
                                            name="child"
                                            value="child"
                                            checked={
                                              data.price_age_category ===
                                              "child"
                                            }
                                            onChange={() =>
                                              handleOptionChangeExtrastep1(
                                                index,
                                                "price_age_category",
                                                "child"
                                              )
                                            }
                                          />
                                          <label className="offer-step-label">
                                            <span className="offer_freeningt_join">
                                              Price per child{" "}
                                            </span>
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  </li>

                                  <li>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input per_person_per_night_check"
                                        type="radio"
                                        id="WithoutinlineRadio1"
                                        name={`price_type${index}`}
                                        value="person_per_night"
                                        checked={
                                          data.price_type === "person_per_night"
                                        }
                                        onChange={() =>
                                          handleOptionChangeExtrastep1(
                                            index,
                                            "price_type",
                                            "person_per_night"
                                          )
                                        }
                                      />
                                      <label>
                                        Per person / per night:
                                        <span className="extra-label-sub">
                                          ex. VIP treatment, etc.
                                        </span>
                                      </label>
                                    </div>

                                    <div
                                      className={
                                        data.price_type === "person_per_night"
                                          ? "per_person_per_night_show"
                                          : "per_person_per_night_show d-none"
                                      }
                                    >
                                      <span className="extra_freenight_discount_sec">
                                        <div className="extra_freenight_discount">
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={data.extra_price}
                                            name="extra_price"
                                            onChange={(e) =>
                                              handlechangeStep1(index, e)
                                            }
                                            onFocus={(e) =>
                                              e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                  e.preventDefault();
                                                },
                                                { passive: false }
                                              )
                                            }
                                          />
                                          <span className="offer_freenight_option">
                                            {currency.code}
                                          </span>
                                        </div>
                                      </span>
                                    </div>
                                    <ul
                                      className={
                                        data.price_type === "person_per_night"
                                          ? "offer-create-lists per_person_per_night_show"
                                          : "offer-create-lists per_person_per_night_show d-none"
                                      }
                                    >
                                      <li>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input "
                                            type="radio"
                                            id="WithoutinlineRadio2"
                                            name="person"
                                            value="person"
                                            checked={
                                              data.price_age_category ===
                                              "person"
                                            }
                                            onChange={() =>
                                              handleOptionChangeExtrastep1(
                                                index,
                                                "price_age_category",
                                                "person"
                                              )
                                            }
                                          />
                                          <label className="offer-step-label">
                                            <span className="offer_freeningt_join">
                                              Price per person{" "}
                                            </span>
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input "
                                            type="radio"
                                            id="WithoutinlineRadio2"
                                            name="adult"
                                            value="adult"
                                            checked={
                                              data.price_age_category ===
                                              "adult"
                                            }
                                            onChange={() =>
                                              handleOptionChangeExtrastep1(
                                                index,
                                                "price_age_category",
                                                "adult"
                                              )
                                            }
                                          />
                                          <label className="offer-step-label">
                                            <span className="offer_freeningt_join">
                                              Price per adult{" "}
                                            </span>
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input "
                                            type="radio"
                                            id="WithoutinlineRadio2"
                                            name="child"
                                            value="child"
                                            checked={
                                              data.price_age_category ===
                                              "child"
                                            }
                                            onChange={() =>
                                              handleOptionChangeExtrastep1(
                                                index,
                                                "price_age_category",
                                                "child"
                                              )
                                            }
                                          />
                                          <label className="offer-step-label">
                                            <span className="offer_freeningt_join">
                                              Price per child{" "}
                                            </span>
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                    {/* <div className={extraStep1.price_type === 'person_per_night'?"per_person_per_night_show":"per_person_per_night_show d-none"}>
                            </div> */}

                                    <div
                                      className={
                                        data.price_type === "person_per_night"
                                          ? "per_person_per_night_show extra-left-padding-5 form-check form-check-inline"
                                          : "per_person_per_night_show extra-left-padding-5 form-check form-check-inline d-none"
                                      }
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck1"
                                        value={data.client_preference_status}
                                        checked={
                                          data.client_preference_status === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleExtra1Check(
                                            index,
                                            "client_preference_status",
                                            e.target.checked
                                          )
                                        }
                                      />
                                      <label className="offer-step-label">
                                        <span className="offer_freeningt_join">
                                          The client can choose the number of
                                          nights he wants to enjoy it
                                        </span>
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input no_person_once_check"
                                        type="radio"
                                        name={`price_type${index}`}
                                        id="WithoutinlineRadio1"
                                        value="person_range_once"
                                        checked={
                                          data.price_type ===
                                          "person_range_once"
                                        }
                                        onChange={() =>
                                          handleOptionChangeExtrastep1(
                                            index,
                                            "price_type",
                                            "person_range_once"
                                          )
                                        }
                                      />
                                      <label>
                                        Per number of persons / once:
                                        <span className="extra-label-sub">
                                          ex. airport shuttle, etc.
                                        </span>
                                      </label>
                                    </div>
                                    <div
                                      className={
                                        data.price_type === "person_range_once"
                                          ? "no_person_once_show no_person_once_tabel_sec"
                                          : "no_person_once_show no_person_once_tabel_sec d-none"
                                      }
                                    >
                                      <table className="extra-table-style">
                                        <tbody>
                                          <tr>
                                            <td>
                                              <span className="extra-table-label">
                                                Total Price:
                                              </span>
                                            </td>
                                            {data.person_range_price?.map(
                                              (pric, i) => {
                                                return (
                                                  <td key={i}>
                                                    <input
                                                      className="form-control extra-table-td"
                                                      style={{
                                                        width: "60px",
                                                        textAlign: "center",
                                                      }}
                                                      name="price"
                                                      value={pric.price}
                                                      onChange={(e) =>
                                                        handlePriceChangeStep1(
                                                          index,
                                                          i,
                                                          e
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                );
                                              }
                                            )}
                                            <td>{currency.code}</td>
                                          </tr>
                                          <tr>
                                            <td>guests</td>
                                            {data.person_range_price.map(
                                              (guest, index) => {
                                                return (
                                                  <td key={index}>
                                                    {index + 1}
                                                  </td>
                                                );
                                              }
                                            )}
                                          </tr>

                                          <tr>
                                            <td></td>
                                          </tr>
                                          <tr>
                                            <td
                                              colSpan="14"
                                              className="extra_table_info_note"
                                            >
                                              You may leave prices empty (not
                                              available for that number of
                                              people) or set the price to 0
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input once_per_reservation_check"
                                        type="radio"
                                        name={`price_type${index}`}
                                        id="WithoutinlineRadio1"
                                        value="reserve_once"
                                        checked={
                                          data.price_type === "reserve_once"
                                        }
                                        onChange={() =>
                                          handleOptionChangeExtrastep1(
                                            index,
                                            "price_type",
                                            "reserve_once"
                                          )
                                        }
                                      />
                                      <label>
                                        Once per reservation:
                                        <span className="extra-label-sub">
                                          ex.personal shopper{" "}
                                        </span>
                                      </label>
                                    </div>
                                    <div
                                      className={
                                        data.price_type === "reserve_once"
                                          ? "once_per_reservation_show"
                                          : "once_per_reservation_show d-none"
                                      }
                                    >
                                      <span className="extra_freenight_discount_sec">
                                        <div className="extra_freenight_discount">
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={data.extra_price}
                                            name="extra_price"
                                            onChange={(e) =>
                                              handlechangeStep1(index, e)
                                            }
                                            onFocus={(e) =>
                                              e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                  e.preventDefault();
                                                },
                                                { passive: false }
                                              )
                                            }
                                          />
                                          <span className="offer_freenight_option">
                                            {currency.code}
                                          </span>
                                        </div>
                                      </span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input reservation_per_night_check"
                                        type="radio"
                                        id="WithoutinlineRadio1"
                                        name={`price_type${index}`}
                                        value="reserve_per_night"
                                        checked={
                                          data.price_type ===
                                          "reserve_per_night"
                                        }
                                        onChange={() =>
                                          handleOptionChangeExtrastep1(
                                            index,
                                            "price_type",
                                            "reserve_per_night"
                                          )
                                        }
                                      />
                                      <label>
                                        One unit per reservation / per night:
                                        <span className="extra-label-sub">
                                          One unit per reservation / per night
                                        </span>
                                      </label>
                                    </div>

                                    <div
                                      className={
                                        data.price_type === "reserve_per_night"
                                          ? "reservation_per_night_show"
                                          : "reservation_per_night_show d-none"
                                      }
                                    >
                                      <span className="extra_freenight_discount_sec">
                                        <div className="extra_freenight_discount">
                                          <input
                                            type="number"
                                            className="form-control"
                                            value={data.extra_price}
                                            name="extra_price"
                                            onChange={(e) =>
                                              handlechangeStep1(index, e)
                                            }
                                            onFocus={(e) =>
                                              e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                  e.preventDefault();
                                                },
                                                { passive: false }
                                              )
                                            }
                                          />
                                          <span className="offer_freenight_option">
                                            {currency.code}
                                          </span>
                                        </div>
                                      </span>
                                    </div>
                                    <div
                                      className={
                                        data.price_type === "reserve_per_night"
                                          ? "reservation_per_night_show extra-left-padding-5 form-check form-check-inline"
                                          : "reservation_per_night_show extra-left-padding-5 form-check form-check-inline d-none"
                                      }
                                    >
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck1"
                                        value={data.client_preference_status}
                                        checked={
                                          data.client_preference_status === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleExtra1Check(
                                            index,
                                            "client_preference_status",
                                            e.target.checked
                                          )
                                        }
                                      />
                                      <label className="offer-step-label">
                                        <span className="offer_freeningt_join">
                                          The client can choose the number of
                                          nights he wants to enjoy it
                                        </span>
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                                {formerror.map((err, i) => {
                                  return (
                                    <>
                                      {index === i && err && err.extra_price ? (
                                        <label style={{ color: "red" }}>
                                          {err.extra_price}
                                        </label>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                })}
                                <span
                                  className={
                                    index === inputFields.length - 1
                                      ? ""
                                      : " d-none"
                                  }
                                  style={{ float: "right" }}
                                >
                                  <Link
                                    className="extra-rate-add"
                                    to="#"
                                    onClick={addInputField}
                                  >
                                    +
                                  </Link>
                                </span>
                                <Link
                                  className={
                                    index === 0
                                      ? "remove_more d-none"
                                      : "remove_more"
                                  }
                                  to="#"
                                  onClick={(e) => removeInputFields(index)}
                                  style={{
                                    float: "right",
                                    marginTop: "0px",
                                    marginRight: "10px",
                                  }}
                                >
                                  X
                                </Link>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="tab_nav_section">
                  <div className="tab_nav_section_btns">
                    <Link
                      className="btn btn-be-form-cancel btn-be-back"
                      to="#"
                      onClick={(e) => setTab2("addon")}
                    >
                      Prev
                    </Link>
                    <Link
                      className="btn btn-success btn-be-common"
                      to="#"
                      onClick={nextExtrastep1sub}
                    >
                      Next
                    </Link>
                  </div>
                </div>
              </>
            ) : tab2 === "inclusion" ? (
              <>
                <div className="offer-content-section">
                  <div className="offer-form-status-section">
                    <h6 className="offer-step-desc">
                      When including an existing extra in an offer, the final
                      price typically does not consider the price of the extra
                      separately. Instead, the extra is bundled into the overall
                      offer price.{" "}
                    </h6>
                    <div className="offer-content-section">
                      <div className="offer-form-status-section">
                        <ul className="offer-create--rate-list cancellation-table-scroll">
                          <li>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input offer_include_check"
                                type="checkbox"
                                id="formCheck1"
                                value={extra_include_status}
                                checked={
                                  extra_include_status === true ? true : false
                                }
                                onClick={(e) =>
                                  setExtra_include_status(e.target.checked)
                                }
                              />
                              <label>
                                Inclusions
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    When including an existing extra, the final
                                    price will not consider the price of the
                                    extra
                                  </span>
                                </span>
                              </label>
                            </div>
                            <div
                              className={
                                extra_include_status === true
                                  ? "offer_include_expand"
                                  : "offer_include_expand d-none"
                              }
                            >
                              <ul className="rest_list_block  label-media">
                                {loyalty.map((loyal, index) => {
                                  return (
                                    <li key={index}>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck1"
                                        value={loyal.selected}
                                        checked={
                                          loyal.selected &&
                                          loyal.selected === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleloyalchange(
                                            e.target.checked,
                                            index
                                          )
                                        }
                                      />
                                      <label className="offer_new_lbl  label-media">
                                        {loyal.label}
                                      </label>
                                    </li>
                                  );
                                })}
                                <li>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    value={otherloyal_status}
                                    checked={
                                      otherloyal_status === true ? true : false
                                    }
                                    onClick={(e) =>
                                      setOtherloyal_status(e.target.checked)
                                    }
                                  />
                                  <label className="offer_new_lbl">Other</label>
                                </li>
                                <label style={{ color: "red" }}>
                                  {extra1Error.loyalty}
                                </label>
                                <div
                                  className={
                                    otherloyal_status === true
                                      ? "data offer_step5_dis_in step5-others"
                                      : "d-none"
                                  }
                                >
                                  <div className="col-xxl-6 col-sm-4 col-md-4 col-lg-6 ">
                                    <input
                                      type="text"
                                      value={"English"}
                                      className="select_lang_dummy dummy_extra"
                                    />
                                    <br></br>
                                    <label
                                      className="mar-left-red"
                                      style={{
                                        color: "red",
                                        marginLeft: "30px",
                                      }}
                                    >
                                      {extra1Error.loyalty_name}
                                    </label>
                                    {otherloyal.map((data, indx) => {
                                      return (
                                        <div key={indx}>
                                          <div
                                            className={
                                              indx === 0
                                                ? ""
                                                : "extrarate_close_d"
                                            }
                                          >
                                            <input
                                              type="text"
                                              className="new_inp_type inp_type_extra top-mar-create"
                                              name="loyalty_name"
                                              value={data.loyalty_name}
                                              onChange={(e) =>
                                                hangleotherloyal(
                                                  indx,
                                                  e.target.value,
                                                  "loyalty_name"
                                                )
                                              }
                                              style={{ marginTop: "10px" }}
                                            />
                                            <Link
                                              className={
                                                indx === 0
                                                  ? " d-none"
                                                  : " extrarate_remove_more d-none"
                                              }
                                              to="#"
                                              onClick={(e) =>
                                                removeInputFieldinclusion(indx)
                                              }
                                            >
                                              X
                                            </Link>
                                          </div>
                                          <label
                                            className={
                                              indx === 0
                                                ? "new bottle-canva-col d-flex margin-none "
                                                : "d-none "
                                            }
                                            style={{ marginLeft: "30px" }}
                                          >
                                            (E.g. A Bottle of cava on arrival){" "}
                                          </label>

                                          <span
                                            className={
                                              indx === otherloyal.length - 1
                                                ? "extrarate-room-link"
                                                : "extrarate_close_d d-none"
                                            }
                                          >
                                            <Link
                                              className=" add_new_inclusion"
                                              to="#"
                                              onClick={addInputFieldinclusion}
                                              style={{
                                                color: "#5ea3cb",
                                                marginLeft: "25px",
                                              }}
                                            >
                                              Add a new inclusion
                                            </Link>
                                          </span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div className="col-xxl-6 col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                    <div
                                      className="input-group choose-lang-wid mar-lef"
                                      style={{
                                        marginLeft: "30px",
                                        width: "265px",
                                      }}
                                    >
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                      >
                                        Select Language:
                                      </label>
                                      <select
                                        className="form-control input_inclusion_select"
                                        data-choices
                                        data-choices-search-false
                                        name="choices-single-default"
                                        id="idPayment"
                                        value={loyallang}
                                        onChange={(e) =>
                                          handlelanglo(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          Choose Language..
                                        </option>

                                        {alllanguage.map((lang, inx) => {
                                          return (
                                            <option
                                              key={inx}
                                              value={lang.lang_code}
                                            >
                                              {lang.label}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <br></br>
                                    {/* <label style={{ color: "red" ,marginLeft:"30px"}} className={extra1Error.loyalty_name?"mt_off":""}>
                                                        {extra1Error.loyalty_lang_name}
                                                </label> */}
                                    {loyallang
                                      ? otherloyal.map((data, indx) => {
                                          const isMatchingLanguage =
                                            data.lang?.findIndex(
                                              (language) =>
                                                language.lang_code === loyallang
                                            );
                                          return (
                                            <div
                                              key={indx}
                                              style={{ marginTop: "7px" }}
                                            >
                                              {data.lang?.some(
                                                (item) =>
                                                  item.lang_code === loyallang
                                              ) ? (
                                                <>
                                                  {console.log(
                                                    isMatchingLanguage
                                                  )}
                                                  <div
                                                    className={
                                                      indx === 0
                                                        ? ""
                                                        : "extrarate_close_d"
                                                    }
                                                  >
                                                    <input
                                                      type="text"
                                                      className={
                                                        indx ===
                                                        otherloyal.length - 1
                                                          ? "new_inp_type bottle-canva-col"
                                                          : "new_inp_type "
                                                      }
                                                      name="loyalty_lang_name"
                                                      value={
                                                        data?.lang[
                                                          isMatchingLanguage
                                                        ]?.loyalty_lang_name
                                                      }
                                                      onChange={(e) =>
                                                        hangleotherloyallang(
                                                          indx,
                                                          e.target.value,
                                                          "loyalty_lang_name"
                                                        )
                                                      }
                                                    />
                                                    <Link
                                                      className={
                                                        indx === 0
                                                          ? " d-none"
                                                          : " extrarate_remove_more"
                                                      }
                                                      to="#"
                                                      onClick={(e) =>
                                                        removeInputFieldinclusion(
                                                          indx
                                                        )
                                                      }
                                                    >
                                                      X
                                                    </Link>
                                                  </div>
                                                  <label
                                                    className={
                                                      indx === 0
                                                        ? "new"
                                                        : "d-none"
                                                    }
                                                    style={{
                                                      marginLeft: "30px",
                                                    }}
                                                  >
                                                    (E.g. A Bottle of cava on
                                                    arrival){" "}
                                                  </label>

                                                  <span
                                                    className={
                                                      indx ===
                                                      otherloyal.length - 1
                                                        ? "extrarate-room-link_new"
                                                        : "extrarate_close_d d-none"
                                                    }
                                                  ></span>
                                                </>
                                              ) : (
                                                <>
                                                  <div
                                                    className={
                                                      indx === 0
                                                        ? ""
                                                        : "extrarate_close_d"
                                                    }
                                                  >
                                                    <input
                                                      type="text"
                                                      className={
                                                        indx ===
                                                        otherloyal?.length - 1
                                                          ? "new_inp_type"
                                                          : "new_inp_type"
                                                      }
                                                      name="loyalty_lang_name"
                                                      value={
                                                        data.lang[
                                                          data.lang?.length - 1
                                                        ]?.loyalty_lang_name
                                                      }
                                                      onChange={(e) =>
                                                        hangleotherloyallang(
                                                          indx,
                                                          e.target.value,
                                                          "loyalty_lang_name"
                                                        )
                                                      }
                                                    />
                                                    <Link
                                                      className={
                                                        indx === 0
                                                          ? " d-none"
                                                          : " extrarate_remove_more"
                                                      }
                                                      to="#"
                                                      onClick={(e) =>
                                                        removeInputFieldinclusion(
                                                          indx
                                                        )
                                                      }
                                                    >
                                                      X
                                                    </Link>
                                                  </div>
                                                  <label
                                                    className={
                                                      indx === 0
                                                        ? "new"
                                                        : "d-none"
                                                    }
                                                    style={{
                                                      marginLeft: "30px",
                                                    }}
                                                  >
                                                    (E.g. A Bottle of cava on
                                                    arrival){" "}
                                                  </label>

                                                  <span
                                                    className={
                                                      indx ===
                                                      otherloyal.length - 1
                                                        ? "extrarate-room-link_new"
                                                        : "extrarate_close_d d-none"
                                                    }
                                                  ></span>
                                                </>
                                              )}
                                            </div>
                                          );
                                        })
                                      : ""}
                                  </div>
                                  {/* <div className="col-xxl-6 col-sm-4 col-md-4 col-lg-6">
                                        <select className="form-control" data-choices data-choices-search-false
                                            name="choices-single-default" id="idPayment"  style={{width:"130px",height:"35px",marginLeft:"30px"}} value={loyallang} onChange={(e)=>setLoyallang(e.target.value)}>
                                            <option >Choose language</option>

                                        {alllanguage.map((lang,inx)=>{
                                            return(
                                                <option key={inx} value={lang.code}>{lang.label}</option>
                                            );
                                        })}
                                        
                                        </select><br></br>
                                       
                                        {otherloyal.map((data, indx) => {
                                          return (
                                            <div key={indx}>
                                                <div className={indx===0 ?"":'extrarate_close_d'}>
                                                <input type='text' className={indx===otherloyal.length-1?"inp_new_type_last":"new_inp_type"} name='loyalty_lang_name' value={data.loyalty_lang_name} onChange={(e)=>hangleotherloyal(indx,e.target.value,"loyalty_lang_name")}/>
                                                <Link className={indx===0 ?" d-none":" extrarate_remove_more"} to="#" onClick={(e)=>removeInputFieldinclusion(indx)}>X</Link>                                     
                                                </div>
                                                <label className={indx===0 ?"new":"d-none"}style={{marginLeft:"30px"}}>(E.g. A Bottle of cava on arrival) </label>
                                                <span className={indx===otherloyal.length-1?"extrarate-room-link":"extrarate_close_d d-none"}>
                                                </span>
                                            </div>
                                            
                                          );
                                        })}
                                    </div> */}
                                </div>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab_nav_section">
                  <div className="tab_nav_section_btns">
                    <Link
                      className="btn btn-be-form-cancel btn-be-back"
                      onClick={(e) => setTab2("subaddon")}
                    >
                      Prev
                    </Link>
                    <Link
                      className="btn btn-success btn-be-common"
                      to="#"
                      onClick={nextExtrastep1inc}
                    >
                      Next
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          {/* </>
          :<>
               <div className="offer-content-section">
                   <div class="col-lg-4" style={{marginTop:"20px"}}>
                      <div className="input-group">
                        <label className="input-group-text input-joint-color"
                          id="inputGroup-sizing-default" style={{width:"130px"}}> Choose Language:</label>
                           <div className="col-md-7">
                              <MultiSelect
                                options={language}
                                value={selectedMulti}
                                onChange={setselectedMulti}
                                labelledBy="Select"
                                selectionType="counter"
                                allowCustomValue={true}
                                aria-expanded="true"
                              />
                                  </div>
                                </div>
                                </div>
                                <div className="appendmore" style={{marginTop:"20px"}}></div>
                                {selectedMulti.length > 0
                                  ? selectedMulti.map((element, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="col-md-12 translation-fill-section"
                                          id="lang_ +val"
                                          data-value="1">
                                          <div className="translation-selected-lang">
                                            <p>
                                              <span className="translation-selected-lang-head">
                                                {element.code}
                                              </span>
                                            </p>
                                          </div>
                                     <div class="col-lg-4">
                                    <div className="input-group">
                                    <label className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default">Add-on:</label>
                                        <input type="text" className="form-control" placeholder="" value={selectedMulti[index].name} 
                                        onChange={(e) =>(selectedMulti[index].name =e.target.value)}/>
                                      </div>
                                    </div>
                                          </div>
                                        );
                                      })
                                    : ""}
                                    </div>
                                </>
                                } */}

          {/* <div className="tab_nav_section">
              <div className="tab_nav_section_btns">
           <Link className="btn btn-be-form-cancel btn-be-back" >Cancel</Link>
          <Link className="btn btn-success btn-be-common" to="#" onClick={nextExtrastep1}>Next</Link>
       </div>
      </div>  */}
        </div>
      </div>
      {/* </div>
  </div> */}
    </>
  );
}

export default Extrastep1;
