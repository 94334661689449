import React, { useEffect, useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledAlert,
} from "reactstrap";
function Alerts({ type, message, open }) {
  const [data, setData] = useState(open);

  // useEffect(() => {
  //   function close() {
  //     setTimeout(() => setData(false), 3000);
  //   }
  //   close();
  // }, []);

  return (
    <>
      {/* {data ? ( */}
      <div>
        {type === "success" ? (
          <UncontrolledAlert
            show={data}
            color="success"
            className="alert-border-left"
          >
            <i className="ri-user-smile-line me-3 align-middle fs-16"></i>
            {/* <strong>Success</strong>-  */}
            {message}
          </UncontrolledAlert>
        ) : (
          <UncontrolledAlert
            show={data}
            color="danger"
            className="alert-border-left mb-xl-0"
          >
            <i className="ri-error-warning-line me-3 align-middle fs-16"></i>
            {/* <strong>Error</strong>-  */}
            {message}
          </UncontrolledAlert>
        )}
      </div>
      {/* ) : (
        ""
      )} */}
    </>
  );
}

export default Alerts;
