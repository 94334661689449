import React, { useEffect, useState } from "react";
import Footer from "../../Common/Footer";
import { Link } from "react-router-dom";
import { useErrorBoundary } from "preact/hooks";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import Config from "../../Config";
import ReactPaginate from "react-paginate";
import DeleteModal from "../../DeleteModal";
import axios from "axios";
import Alerts from "../../Alerts";
import Commonheader from "../../Common/Commonheader";
import Nodatafound from "../../Common/Nodatafound";
import Loading from "../../Loading";

function Adminuserlisting() {
  const [user, setUser] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [pageid, setPageid] = useState("");
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [page,setPage]=useState(1);
  const [pageindex,setPageindex]=useState(1);
  const [tab, setTab] = useState("admin");
  const [page1,setPage1]=useState(1);
  const [pageindex1,setPageindex1]=useState(1);
  const [vendorUser, setVendorUser] = useState([]);
  const [paginate1, setPaginate1] = useState([]);
  const [loading, setLoading] = useState(true);


  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  function tog_animationFlip(id) {
    setmodal_animationFlip(!modal_animationFlip);
    setPageid(id);
  }

  const [modal_backdrop, setmodal_backdrop] = useState(false);
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
  }

  const getinfo = async (e) => {
    const res = await fetch(`${Config.apiurl}admin/admin-users?page=1`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",

      },
    });
    const data = await res.json();
    console.log(data);
    if (data.status_code !==200 || !data) {
      console.log("error");
    }  else {
      setUser(data.data.data);
      setPaginate(data.data.last_page);
      setLoading(false);
    }
  };

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}admin/admin-users?page=${currentPage}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const data = await res.json();
    return data.data.data;
  };

  const handlePageClick = async (data) => {
    if(data===1){
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
      setUser(commentsFormServer);
    }
    else{
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
      setUser(commentsFormServer);
    }
  };

  const getinfoVendor = async (e) => {
    const res = await fetch(`${Config.apiurl}admin/user/vendor/list?page=1`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",

      },
    });
    const data = await res.json();
    console.log(data);
    if (data.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setVendorUser(data.data.data);
      setPaginate1(data.data.last_page);
      setLoading(false);
    }
  };

  const fetchComments1 = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}admin/user/vendor/list?page=${currentPage}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const data = await res.json();
    return data.data.data;
  };

  const handlePageClick1 = async (data) => {
    if(data===1){
      setPage1(data);
      setPageindex1(1);
      const commentsFormServer = await fetchComments1(data);
      setVendorUser(commentsFormServer);
    }
    else{
      let currentPage = data.selected + 1;
      setPageindex1(currentPage);
      setPage1(data);
      const commentsFormServer = await fetchComments1(currentPage);
      setVendorUser(commentsFormServer);
    }
  };

  const DeleteData = async (id) => {
    axios
      .delete(`${Config.apiurl}admin/admin-user/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status !== "200" || !res) {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("error");
          setmodal_animationFlip(false);
        } else {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("success");
          setmodal_animationFlip(false);
          handlePageClick(page);
          handlePageClick1(page1);
        }
      });
  };

  useEffect(() => {
    getinfo();
    getinfoVendor();
  }, []);


  return (
    <div id="layout-wrapper">
    <Commonheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">USER</h4>
                </div>
              </div>
            </div>
            {alert ? (
              <Alerts type={alerttype} message={alertmessage} open={open} />
            ) : (
              ""
            )}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      {/* <h4 className="card-title mb-0 flex-grow-1">Users</h4> */}
                      <div className="col-md-10">
                        <ul className="offer-create-list_rate" style={{width:"auto"}}>
                          <li
                            className={
                              tab === "admin"
                                ? "extra-step-list  steplist-two active"
                                : "extra-step-list steplist-two"
                            }
                            onClick={(e) => setTab("admin")}
                          >
                            Admin Users
                          </li>
                          <li
                            className={
                              tab === "vendor"
                                ? "extra-step-list  steplist-two active"
                                : "extra-step-list steplist-two"
                            }
                            onClick={(e) => setTab("vendor")}
                            style={{
                              borderLeft: "1px solid #d2d2d2",
                              borderRight: "1px solid #d2d2d2",
                            }}
                          >
                            Vendor Users
                          </li>
                        </ul>
                      </div>
                      <div className="flex-shrink-0 d-flex justify-content-between">
                        <Link
                          type="button"
                          to="/admin_user"
                          className="btn db-save-button"
                        >
                          Create
                        </Link>
                      </div>
                    </div>
                    {tab==="admin"?
                    <div className="card-body pt-0">
                      <div>
                        <ul
                          className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                          role="tablist"
                        ></ul>
                        {loading===true?
                        <Loading/>
                        :
                        <>
                        {user?.length>0?
                        <div className="table-responsive table-card mb-1">
                          <table
                            className="table table-nowrap align-middle"
                            id="orderTable"
                          >
                            <thead className="text-muted table-light">
                              <tr className="text-uppercase">
                                <th className="" data-sort="id">
                                  Sl No.
                                </th>
                                <th className="" data-sort="customer_name">
                                  User
                                </th>
                                <th className="" data-sort="customer_name">
                                  Email
                                </th>
                                <th className="" data-sort="customer_name">
                                  Status
                                </th>
                                <th className="" data-sort="city">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {user?.map((user, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="id">{pageindex===1 ? index+1: (pageindex-1) * 10 + index +1}</td>
                                    <td className="customer_name">
                                      {user.name} {user.surname}
                                    </td>
                                    <td className="customer_name">
                                      {user.email}
                                    </td>
                                    <td className="status">
                                      {user.is_active === false ? (
                                        <span class="badge badge-soft-danger text-uppercase">
                                          {" "}
                                          INACTIVE
                                        </span>
                                      ) : (
                                        <span className="badge badge-soft-success text-uppercase">
                                          ACTIVE
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <ul className="list-inline hstack gap-2 mb-1">
                                        <li
                                          className="list-inline-item edit"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Edit"
                                        >
                                          <Link
                                            to={`/admin_user-edit`}
                                            state={{
                                             admin_id: user.user_id,
                                             
                                            }}
                                            data-bs-toggle="modal"
                                            className="text-primary d-inline-block edit-item-btn"
                                          >
                                            <i className="ri-pencil-fill fs-16"></i>
                                          </Link>
                                        </li>
                                        <li
                                          className="list-inline-item"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Remove"
                                        >
                                          <Link
                                            className="text-danger d-inline-block remove-item-btn"
                                            data-bs-toggle="modal"
                                            to="#"
                                            onClick={() => tog_animationFlip(user.user_id)}
                                          >
                                            <i className="ri-delete-bin-5-fill fs-16"></i>
                                          </Link>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>

                          {paginate > 1 ? (
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              pageCount={paginate}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination justify-content-end"
                              }
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        :
                        <Nodatafound/>
                        }
                        </>
                        }
                      </div>
                    </div>
                    :
                    <div className="card-body pt-0">
                    <div>
                      <ul
                        className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                        role="tablist"
                      ></ul>
                      {loading===true?
                      <Loading/>
                      :
                      <>
                     {vendorUser?.length>0?
                      <div className="table-responsive table-card mb-1">
                        <table
                          className="table table-nowrap align-middle"
                          id="orderTable"
                        >
                          <thead className="text-muted table-light">
                            <tr className="text-uppercase">
                              <th className="" data-sort="id">
                                Sl No.
                              </th>
                              <th className="" data-sort="customer_name">
                                User
                              </th>
                              <th className="" data-sort="customer_name">
                                Email
                              </th>
                              <th className="" data-sort="customer_name">
                                Status
                              </th>
                              <th className="" data-sort="city">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {vendorUser?.map((user, index) => {
                              return (
                                <tr key={index}>
                                  <td className="id">{pageindex1===1 ? index+1: (pageindex1-1) * 10 + index +1}</td>
                                  <td className="customer_name">
                                    {user.en_name} {user.en_surname}
                                  </td>
                                  <td className="customer_name">
                                    {user.email}
                                  </td>
                                  <td className="status">
                                    {user.is_active === false ? (
                                      <span class="badge badge-soft-danger text-uppercase">
                                        {" "}
                                        INACTIVE
                                      </span>
                                    ) : (
                                      <span className="badge badge-soft-success text-uppercase">
                                        ACTIVE
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <ul className="list-inline hstack gap-2 mb-1">
                                      <li
                                        className="list-inline-item edit"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <Link
                                          to={`/admin_user-edit`}
                                          state={{
                                           admin_id: user.user_id,
                                           
                                          }}
                                          data-bs-toggle="modal"
                                          className="text-primary d-inline-block edit-item-btn"
                                        >
                                          <i className="ri-pencil-fill fs-16"></i>
                                        </Link>
                                      </li>
                                      <li
                                        className="list-inline-item"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title="Remove"
                                      >
                                        <Link
                                          className="text-danger d-inline-block remove-item-btn"
                                          data-bs-toggle="modal"
                                          to="#"
                                          onClick={() => tog_animationFlip(user.user_id)}
                                        >
                                          <i className="ri-delete-bin-5-fill fs-16"></i>
                                        </Link>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>

                        {paginate1 > 1 ? (
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={paginate1}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={handlePageClick1}
                            containerClassName={
                              "pagination justify-content-end"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      :
                      <Nodatafound/>
                    }
                    </>
                   }
                    </div>
                  </div>
                  }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      {modal_animationFlip ? (
        <DeleteModal
          modal_animationFlip={modal_animationFlip}
          tog_animationFlip={tog_animationFlip}
          pageid={pageid}
          DeleteData={DeleteData}
          message={"User"}
        />
      ) : (
        ""
      )}

     
    </div>
  );
}

export default Adminuserlisting;
