import React from "react";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";

function Previousmodal({roomprev,message,setRoomprev,tag,Prvfunction,closetab}) {
  return (
    
    
        <Modal
          id="flipModal"
          isOpen={roomprev}
          toggle={() => {
            setRoomprev(!roomprev);
          }}
          modalClassName="flip"
          centered
          style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
        >
          <ModalBody className="modal-body p-5 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#405189,secondary:#f06548"
              style={{ width: "90px", height: "90px" }}
            ></lord-icon>
    
            <div className="mt-4 text-center">
              <h4>You have made changes to the {message} ?</h4>
              <p className="text-muted fs-15 mb-4">
               Please submit changes to save updated data
              </p>
            </div>


            <button
              className="btn btn-link link-danger fw-medium text-decoration-none"
             
            //   onClick={() => {
            //     setRoomprev(!roomprev);
            //   }}
            onClick={() => {
                closetab(tag);
              }}
            
            >
              {" "}
              <i className="ri-close-line me-1 align-middle"></i>
              Close{" "}
            </button>
            <Button color="success"
              onClick={(e)=>Prvfunction(tag)}
             >
              {" "}
              Submit{" "}
            </Button>
          </ModalBody>
        </Modal>
    
    
  )
}

export default Previousmodal
