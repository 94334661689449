import React from 'react'
import Footer from '../../Common/Footer';
import { MultiSelect } from 'react-multi-select-component';
import { useState } from 'react';
import { useEffect } from "react";
import Alerts from '../../Alerts';
import Config from "../../Config";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Basicsettingsheader from '../../Common/Basicsettingsheader';
import axios from 'axios';

function Paymentoptionedit() {

  let navigate = useNavigate();
  let params=useParams();

  const [ids, setId] = useState(params.type_id);

    const [selectedMulti, setselectedMulti] = useState([]);

    const [status, setStatus] = useState("");
    const [active, setActive] = useState("en");
    const [alert, setAlert] = useState(false);
    const [alertmessage, setAlertmessage] = useState("");
    const [alerttype, setAlerttype] = useState("");
    const [open, setOpen] = useState(false);
    const[be_language,setBe_language]=useState([])
    const [multi,setMulti]=useState([]);
    const [option,setOption]=useState("")
    const language = localStorage.getItem("be_language");
    const[method,setMethod]=useState([])

    const [formerror, setError] = useState({});
    const [formerror1, setError1] = useState({});
    const [formerror2, setError2] = useState({});
  
    const getDatamethod = async (e) => {
      const res = await fetch(`${Config.apiurl}payment-methods`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data1 = await res.json();
      const data = data1.data;
  
      if (data1.status_code !== "200" || !data) {
        console.log("error");
      } else {
        setMethod(data);
      }
    };
    const getlanguage = async (e) => {
  

      const res = await fetch(`${Config.apiurl}optional-languages`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,

        },
      });
      const data = await res.json();
      if (data.status_code !== "200" || !data.data) {
        console.log("error");
      } else {
        setBe_language(data.data);
      }
    };
   
    // const validate = (values) => {
    //   const noSpecialCharRegex = /^[A-Za-z0-9\s.,-,/]+$/;

    //   const errors = {};
    //   if (!values.payment_type) {
    //     errors.payment_type = "Payment type is required!";
    //   }
    //   else if (!noSpecialCharRegex.test(values.payment_type)) {
    //     errors.payment_type = "Payment type should not contain this special character";
    //   }
    //   else if (values.payment_type.length > 50) {
    //     errors.payment_type = `Payment type cannot be more than 50 characters!`;
    //   }
    //   return errors;
    // };

    // const validate1 = (values) => {
    //   const errors = {};
    //   if (!values.payment_method) {
    //     errors.payment_method = "Payment method is required!";
    //   }
             
    //   return errors;
    // };
    // const validate2 = () => {
    //   const errors = {};
    //   if (!status) {
    //     errors.status = "Status is required!";
    //   }
    //   return errors;
    // };

    const PostData = async (e) => {
      e.preventDefault();
      let isFormValid = true;
      const noSpecialCharRegex = /^[A-Za-z0-9\s.,-,/]+$/;

      const errors = {};
      if (!option) {
        isFormValid = false;
        errors.option = "Payment type is required!";
      }
      else if (!noSpecialCharRegex.test(option)) {
        isFormValid = false;
        errors.option = "Payment type should not contain this special character";
      }
      else if (option.length > 50) {
        isFormValid = false;
        errors.option = `Payment type cannot be more than 50 characters!`;
      }
      if (selectedMulti.length===0) {
        isFormValid = false;
        errors.selectedMulti = "Payment method is required!";
      }
      if (status.length===0) {
        isFormValid = false;
        errors.status = "Status is required!";
      }
      setError(errors);

     
      let lang = [];
      
      for (let i = 0; i < multi.length; i++) {
        if (multi[i].name) {
          if (multi[i].code !== "en") {
            let multiarray = {};
            multiarray.lang_code = multi[i].code;
            multiarray.payment_type = multi[i].name;
  
            lang.push(multiarray);
          }
        }
      }

      let paymethod = [];
      for (let i = 0; i < selectedMulti.length; i++) {
        paymethod.push(selectedMulti[i].value);
        }
      
  
      if (isFormValid===true) {
     
        const res = await fetch(`${Config.apiurl}payment-option/${ids}`, {
          method: "PATCH",
          body: JSON.stringify({
            en_payment_type: option,
            payment_methods:paymethod,
            is_active: status,
            multi_lang: lang,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });
        const data = await res.json();
        if (data.status_code !== "200" || !data) {
          setAlert(true);
          setAlertmessage(data.message);
          setOpen(true);
          setAlerttype("error");
        } else {
          setAlert(true);
          setAlertmessage(data.message);
          setOpen(true);
          setAlerttype("success");
          setTimeout(() => navigate("/basic_settings/payment-option-list"), 3000);
          Reset();

        }
      }
    };
  
    // const PostData = async (e) => {
    //   e.preventDefault();
     
    //   const ress=validate(option)
    //   const ress1=validate1(selectedMulti)
    //   const ress2=validate2(status)
  
    //   setError(ress);
    //   setError1(ress1)
    //   setError2(ress2);

  
    //   if (
        //  !ress.option && !ress1.selectedMulti && !ress2.status 
    //   ) {
  
    //     let paymethod = [];
    
    //     for (let i = 0; i < selectedMulti.length; i++) {
    
    //       paymethod.push(selectedMulti[i].value);
    //       }
          
    //     const formData = new FormData();
    //     formData.append("en_payment_type",option);
    //     formData.append("payment_methods",paymethod);
    //     formData.append("is_active", status);
    //     formData.append('_method', 'PATCH');
    //                 for (let i = 0; i < multi.length; i++) {
    //                   if (multi[i].name) {
    //                     if (multi[i].code !== "en") {
                        
    //                       formData.append(`multi_lang[${i}][lang_code]`, multi[i].code);
    //                       formData.append(`multi_lang[${i}][payment_type]`, multi[i].name);
                         
    //                     }
    //                   }
    //                 }    
                   
    //                 axios.post(`${Config.apiurl}payment-option/${ids}`, formData, {
    //             headers: {
    //               Authorization: `Bearer ${localStorage.getItem("token")}`,
    //               "Content-type": "application/json; charset=UTF-8",
    //             },
    //           })
    //           .then((res) => {
  
    //         if (res.status_code !== "200" || !res) {
    //           setAlert(true);
    //           setAlertmessage(res.message);
    //           setOpen(true);
    //           setAlerttype("error");
    //         } else {
    //           setAlert(true);
    //           setAlertmessage(res.message);
    //           setOpen(true);
    //           setAlerttype("success");
    //           Reset();
    //           setTimeout(() => navigate("/payment-option-list"), 3000);

    //         }
             
    //   }
    //           )}
    // };
  
    function Reset(){
      setOption("");
      setselectedMulti("");
      setMulti("");
      setStatus("");
    }
  
  useEffect(() => {
    const getinfo = async (e) => {
      const res = await fetch(`${Config.apiurl}payment-option/${ids}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await res.json();
      
        setOption(data.data.payment_option.payment_type);
        setselectedMulti(data.data.payment_method);
        setMulti(data.data.multi_lang);
        setStatus(data.data.payment_option.status);
      
    };
    
    getinfo();
    getlanguage();
    getDatamethod();
  }, [language,ids]);
 
  console.log(multi);
    return (
      <div id="layout-wrapper">
       <Basicsettingsheader/>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="db-page-title"> EDIT PAYMENT OPTION </h4>
                    {/* <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item">
                        <Link to="#">Settings</Link>
                      </li>
                        <li className="breadcrumb-item ">
                          <Link to="#">Basic Settings </Link>
                        </li>
                        <li className="breadcrumb-item">
                        <Link to="#">Payment Settings</Link>
                      </li>
                        <li className="breadcrumb-item ">
                          <Link to="/payment-option-list">  Payment Option</Link>
                        </li>
                      </ol>
                    </div> */}
                  </div>
                </div>
              </div>
              {alert ? (
                <Alerts type={alerttype} message={alertmessage} open={open} />
              ) : (
                ""
              )}
              <div className="row project-wrapper">
                <div className="row dashboard-top-tab-style">
                  <div className="col-xxl-12 dashboard-main-style">
                    <div className="card">
                      <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          Edit Payment Option
                        </h4>
                        <div className="flex-shrink-0">
                          {/* <Link
                            type="button"
                            className="btn db-other-button payment-btn"
                            to="/basic_settings/payment-option-list"
                            style={{ marginRight: "5px" }}
                          > */}
                           <Link
                          type="button"
                          className="btn db-other-button "
                          to="/basic_settings/payment-option-list"
                          style={{ marginRight: "5px" }}
                        >
                           Payment Option
                          </Link>
                          <button
                            type="button"
                            className="btn db-cancel-button"
                            data-bs-toggle="offcanvas"
                            to=""
                            onClick={Reset}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            className="btn db-save-button"
                            data-bs-toggle="modal"
                            id="create-btn"
                            data-bs-target=""onClick={PostData}
                         
                          >
                            Save
                          </button>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="live-preview">
                          <form action="#">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-translation-style">
                                  <ul>
                                    <li
                                      className={active === "en" ? "active" : ""}
                                    >
                                      <Link
                                        to="#"
                                        onClick={() => setActive("en")}
                                        className={
                                          active === "en" ? "active" : ""
                                        }
                                      >
                                        EN
                                      </Link>
                                    </li>
                                    <li
                                      className={
                                        active === "trans" ? "active" : ""
                                      }
                                    >
                                      <Link
                                        to="#"
                                        onClick={() => setActive("trans")}
                                        className={
                                          active === "trans" ? "active" : ""
                                        }
                                      >
                                        Translations
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              {active === "en" ? (
                                <>
                                   <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Payment Type
                            <span className="form-validation-required">*</span>
                              </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={option}
                                        onChange={(e) =>
                                          setOption(e.target.value)}
                                      />
                                       </div>
                                      <label style={{ color: "red" }}>
                                        {formerror.option}
                                      </label>
                                   
                                  </div>
                                  <div class="col-lg-4">
                                  <div className="input-group">
                                    <label className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default" style={{height:"40px"}}>Payment Method
                                      <span className="form-validation-required">*</span></label>
                                  <div className="col-md-7 pay-col-adj">
                                  <MultiSelect
                                      options={method}
                                      value={selectedMulti}
                                      onChange={setselectedMulti}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      closeOnChangedValue={true}
                                      aria-expanded="true"
                                    />
                                      </div>
                                     <label style={{ color: "red" }}>
                                      {formerror.selectedMulti}
                                    </label>
                                  
                                  </div>
                                    </div>
                                    <div class="col-lg-4">
                                <div className="input-group">
                              <label className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default">Status
                              <span className="form-validation-required">*</span>
                                </label>
                                <select id="ForminputState" className="form-select" value={status} onChange={(e)=>setStatus(e.target.value)}>
                                    <option value=''>Choose...</option>
                                    <option {...(status === true
                                  ? "selected"
                                  : "")}
                                value={true}  >Active</option>
                                    <option {...(status === false
                                  ? "selected"
                                  : "")}
                                value={false} >Inactive</option>
                                  </select>
                                                             
                                   </div>
                                   <label style={{ color: "red" }}>
                                    {formerror.status}
                                  </label>  
                              </div>
                                
                                  
                                </>
                                
                              ) : (
                                <>
                                <div className="col-lg-4" style={{ marginTop: "20px" }}>
                                  <div className="input-group">
                                    <label className="input-group-text input-joint-color" id="inputGroup-sizing-default" style={{ width: "130px" }}>
                                      Choose Language:
                                    </label>
                                    <div className="col-md-7">
                                      <MultiSelect
                                        options={be_language}
                                        value={multi}
                                        onChange={setMulti}
                                        labelledBy="Select"
                                        selectionType="counter"
                                        closeOnChangedValue={true}
                                        aria-expanded="true"
                                        hasSelectAll={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="appendmore"></div>
                                {multi.length > 0 &&
                                  multi.map((element, index) => (
                                    <div
                                      key={index}
                                      className="col-md-12 translation-fill-section"
                                      id={`lang_${element.value}`}
                                      data-value="1"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <div className="translation-selected-lang">
                                        <p>
                                          <span className="translation-selected-lang-head">
                                            {element.code}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="row">
                                        <div className="col-lg-4">
                                          <div className="input-group">
                                            <label className="input-group-text input-joint-color" id="inputGroup-sizing-default">
                                              Name
                                              <span className="form-validation-required">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder=""
                                              value={element.name}
                                              onChange={(e) =>
                                                setMulti((prevMulti) =>
                                                  prevMulti.map((item, i) =>
                                                    i === index ? { ...item, name: e.target.value } : item
                                                  )
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </>
                              )}
                            </div>
                            <div className="padding-20"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <Footer />
        </div>
      </div>
  )
}

export default Paymentoptionedit