import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';

function Offerstep4sugg({setTab,
  offer4fields,
  handleoffer4checkbox,
  Loyaltyclublist,
  handleloyalchangeoffer4,
  handlechangeoffer4,
  legend,
  handleOptionChangeoffer4Cancelpolicy,
  countrylist,
  handleloyalchangeoffer4country,
  showAll, setShowAll,
  visibleItems,
  nextoffterstep4,
  offer4error,
  multicountri4,
  setMulticountry4
}) {
  useEffect(() => {
    const numberInput = document.getElementById('myNumberField');

    if (numberInput) {
        const preventDefaultBehavior = (event) => {
            event.preventDefault();
        };

        numberInput.addEventListener('wheel', preventDefaultBehavior);
        numberInput.addEventListener('touchstart', (event) => {
            const initialTouchY = event.touches[0].clientY;
            
            numberInput.addEventListener('touchmove', (event) => {
                const currentY = event.touches[0].clientY;
                if (currentY !== initialTouchY) {
                    event.preventDefault();
                }
            });
        });

        return () => {
            numberInput.removeEventListener('wheel', preventDefaultBehavior);
            numberInput.removeEventListener('touchstart', preventDefaultBehavior);
            numberInput.removeEventListener('touchmove', preventDefaultBehavior);
        };
    }
}, []);


  return (
    <div className="row">
      <div className="col-md-12">
        <div className="offer-create-section offer-creation-media  offer-create-wid">
          <div className="offer-create-details">
          <div className="col-md-12">
            <h6 className="offer-step-desc">Offer restrictions refer to specific conditions or limitations that apply to an offer. These restrictions help define the terms and requirements for guests to be eligible for or utilize the offer.</h6>

            <div className="offer-content-section">
                <div className="offer-form-status-section">
                    <ul className="offer-create--rate-list">
                        <li>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input offer_lastminute_check" type="checkbox" id="formCheck1" 
                                
                                onChange={(e)=>handleoffer4checkbox(e.target.checked,"promotional_code")} value={offer4fields.promotional_code} checked={offer4fields.promotional_code===true?true:false}/>
                                <label>
                                    Only available for users with a corporate / promotional code
                                    <span className="confidential_icon">
                                        <img src="/images/lock (1).png"/>
                                        <span className="info-tag">i<span className="tooltiptext"> Important: For this to work, in "Promo Codes", you should have created the codes and they need to be associated to this offer/rate.</span></span>
                                    </span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input restr_loyalty_check" type="checkbox" id="formCheck1" 
                                onChange={(e)=>handleoffer4checkbox(e.target.checked,"loyalty_club")} value={offer4fields.loyalty_club} checked={offer4fields.loyalty_club===true?true:false}/>
                                <label>
                                    Only for the loyalty club
                                </label>
                            </div>
                            <div className={offer4fields.loyalty_club===true?"restr_loyalty_expand":"restr_loyalty_expand d-none"}>
                                <ul className="rest_list_inline">
                                  {Loyaltyclublist.map((loyal,index)=>{
                                    return(
                                      <li key={index}>
                                      <input className="form-check-input" type="checkbox" id="formCheck1" 
                                      value={loyal.selected} checked={loyal.selected===true?true:false} 
                                      onChange={(e)=>handleloyalchangeoffer4(e.target.checked,index)}/>
                                      <label style={{marginLeft:"8px"}}> {loyal.label}</label>
                                  </li>
                                    )
                                  })}
                                  
                                  
                                </ul>
                                <label style={{ color: "red" }}>
                                                              {offer4error.loyalty_club}
                                                            </label>
                            </div>
                        </li>
                        <li>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input restr_adv_booking_check" type="checkbox" id="formCheck1"                           
                                onChange={(e)=>handleoffer4checkbox(e.target.checked,"advanced_booking")} value={offer4fields.advanced_booking} checked={offer4fields.advanced_booking===true?true:false}/>

                                <label> 
                                    <span className="offer_freeningt_join">Advanced booking... </span>
                                    <span className={offer4fields.advanced_booking===true?"offer_freeningt_join restr_adv_booking_expand":"offer_freeningt_join restr_adv_booking_expand d-none"}>
                                        <input type="number" className="form-control offer-restr-textfield_1 box_style_1_is text_center_ctyle" 
                                        value={offer4fields.ad_booking_days} name="ad_booking_days" onChange={handlechangeoffer4}
                                        id="myNumberField"
                                        onFocus={(e) =>
                                          e.target.addEventListener(
                                            "wheel",
                                            function (e) {
                                              e.preventDefault();
                                            },
                                            { passive: false }
                                          )
                                        }
                                        />
                                </span>
                                    <span className={offer4fields.advanced_booking===true?"offer_freeningt_join restr_adv_booking_expand":"offer_freeningt_join restr_adv_booking_expand d-none"}>Day/s in advance</span>
                                    <label style={{ color: "red" }}>
                                                              {offer4error.ad_booking_days}
                                                            </label>
                                </label>
                              
                            </div>
                          
                            
                        </li>
                        <li>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input restr_policy_check" type="checkbox" id="formCheck1" 
                                onChange={(e)=>handleoffer4checkbox(e.target.checked,"different_payment")} value={offer4fields.different_payment} checked={offer4fields.different_payment===true?true:false}/>
                                <label>
                                    Different payment or cancellation policy...
                                </label>
                            </div>
                            <div className={offer4fields.different_payment===true?"restr_policy_expand  cancellation-table-scroll":"restr_policy_expand d-none"}>
                                <table className="table offer-policy-table">
                                    <tr>
                                        <th>
                                            Policy<br></br>Cancellation<br></br>Identificator
                                        </th>
                                        <th>
                                            Policy<br></br>Cancellation<br></br>Priority
                                        </th>
                                        <th>
                                            Minimum<br></br>hours before<br></br> arrival<br></br> to cancel
                                        </th>
                                        <th>
                                            Cancellation Policy Text<br></br>
                                            <p className="policy_th_span">
                                                Attention: the dates that appear have been updated according to the cancellation policy as if the checkin was now.
                                            </p>
                                        </th>
                                        <th>
                                            Method of Payment
                                        </th>
                                    </tr>
                                    {legend.map((lege,index)=>{
                                      return(
                                        
                                        <tr key={index}>
                                        <td>
                                            <input className="form-check-input form-check-input-is same_rate_show" type="radio" name="inlineRadioOptions" id={index} style={{marginLeft:"10px"}}
                                          value={lege.policy_id} 
                                          checked={offer4fields.cancel_policy === lege.policy_id}
                                          onChange={() => handleOptionChangeoffer4Cancelpolicy(lege.policy_id,'cancel_policy')}
                                          />
                                            <span className="td_policy_id offer4_pl" style={{background:`${lege.policy_description.color_code}`}}>{lege.policy_identifier}</span>
                                        </td>
                                        <td className="new_policy">
                                            {lege.priority}
                                        </td>
                                        <td className="new_policy">
                                          {lege.minimum_hours}
                                        </td>
                                        <td>
                                            <div className="policy_dec_td">
                                                {lege.policy_description.description}
                                            </div>
                                        </td>
                                        <td className="policy_dec_td offer4_policy_dec_td">
                                        {lege?.payment_type?.type}{" "}: {lege?.payment_type.payment_amount?.includes('.00')
                                                ? lege?.payment_type?.payment_amount?.replace('.00', '')
                                                : lege?.payment_type?.payment_amount}{" "}
                                                {lege?.payment_type?.payment_amounttype==="percentage"?"% :":lege?.payment_type?.payment_amounttype==="nights"?"Nights :":""}  {lege?.payment_type?.payment_option?.map((pay,pidx)=>{
                                                return(
                                                  <>
                                                {pay} {lege?.payment_type?.payment_option?.length-1===pidx?"":", "}
                                                  </>
                                                )
                                              })}
                                        </td>

                                        </tr>
                                    
                                    
                                      )
                                    })}
                                  
                                  
                                </table>
                                <label style={{ color: "red" }}>
                                                              {offer4error.cancel_policy}
                                                            </label>
                            </div>
                        </li>
                        <li>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input restr_minStay_check" type="checkbox" id="formCheck1"  
                                onChange={(e)=>handleoffer4checkbox(e.target.checked,"minimum_stay")} value={offer4fields.minimum_stay} checked={offer4fields.minimum_stay===true?true:false}/>
                                <label> 
                                    <span className="offer_freeningt_join">Minimum stay : </span>
                                    <span className={offer4fields.minimum_stay===true?"offer_freeningt_join restr_minStay_expand":"offer_freeningt_join restr_minStay_expand d-none"}>
                                        <select className="form-select offer-create-select box_style_1_1 text_center_ctyle" 
                                        value={offer4fields.minimum_stay_count}
                                        name="minimum_stay_count"
                                        onChange={handlechangeoffer4}
                                        >
                                        {Array.from({ length: 29 }, (_, index) => (
                                            <option key={index + 2} value={index + 2}>
                                              {index + 2}
                                            </option>
                                          ))}
                                          </select>
                                        </span>
                                    <span className={offer4fields.minimum_stay===true?"offer_freeningt_join restr_minStay_expand":"offer_freeningt_join restr_minStay_expand d-none"}>Nights </span>
                                    <span className={offer4fields.minimum_stay===true?"offer_freeningt_join restr_minStay_expand":"offer_freeningt_join restr_minStay_expand d-none"}>
                                        <span className="restr_input_join">
                                            <input className="form-check-input" type="checkbox" id="formCheck1"
                                            value={offer4fields.minimum_stay_night}
                                            checked={offer4fields.minimum_stay_night===true?true:false}
                                            onChange={(e)=>handleoffer4checkbox(e.target.checked,"minimum_stay_night")}
                                            />
                                        </span>
                                        Consider overall minimum requested nights (even if the offer is not open all of them)
                                    </span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input restr_maxStay_check" type="checkbox" id="formCheck1" 
                                onChange={(e)=>handleoffer4checkbox(e.target.checked,"maximum_stay")} value={offer4fields.maximum_stay} checked={offer4fields.maximum_stay===true?true:false}/>
                                <label> 
                                    <span className="offer_freeningt_join">Maximum stay : </span>
                                    <span className={offer4fields.maximum_stay===true?"restr_maxStay_expand":"restr_maxStay_expand d-none"}>
                                        <span className="offer_freeningt_join " >
                                        <select className="form-select offer-create-select box_style_1_1 text_center_ctyle" 
                                        value={offer4fields.maximum_stay_count}
                                        name="maximum_stay_count"
                                        onChange={handlechangeoffer4}
                                        >
                                        {Array.from({ length: 29 }, (_, index) => (
                                            <option key={index + 2} value={index + 2}>
                                              {index + 2}
                                            </option>
                                          ))}
                                          </select>
                                            </span>
                                        <span className="offer_freeningt_join ">Nights </span>
                                    </span>
                                </label>
                            </div>
                            <div className={offer4fields.maximum_stay===true?"restr_maxStay_expand":"restr_maxStay_expand d-none"}>
                                <ul className="rest_list_block">
                                    <li>
                                        <span className="offer_freeningt_join">
                                          
                                            <input className="form-check-input same_rate_show" type="radio" id="WithoutinlineRadio1"
                                            name='max_stay_all_night_offered'
                                            value="offer"
                                            checked={offer4fields.max_stay_all_night_offered === 'offer'}
                                              onChange={() => handleOptionChangeoffer4Cancelpolicy('offer',"max_stay_all_night_offered")}
                                            />
                                        </span>
                                        <span className="offer_freeningt_join">
                                            <label className="offer-step-label">For more nights, the offer will be offered the number of nights indicated here</label>
                                        </span>
                                    </li>
                                    <li>
                                        <span className="offer_freeningt_join">
                                            <input className="form-check-input same_rate_show" type="radio" id="WithoutinlineRadio1" 
                                              name="max_stay_all_night_offered"

                                            value='not_offered'
                                            checked={offer4fields.max_stay_all_night_offered === 'not_offered'}
                                              onChange={() => handleOptionChangeoffer4Cancelpolicy("not_offered","max_stay_all_night_offered")}/>
                                        </span>
                                        <span className="offer_freeningt_join">
                                            <label className="offer-step-label"> For more nights, the offer will not be offered</label>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input restr_minRoom_check" type="checkbox" id="formCheck1" 
                                onChange={(e)=>handleoffer4checkbox(e.target.checked,"minimum_room")} value={offer4fields.minimum_room} checked={offer4fields.minimum_room===true?true:false}/>
                                <label> 
                                    <span className="offer_freeningt_join">Minimum number of rooms of the offer per booking : </span>
                                    <span className={offer4fields.minimum_room===true?"restr_minRoom_expand":"restr_minRoom_expand d-none"}>
                                        <span className="offer_freeningt_join " >
                                            <select className="form-select offer-create-select box_style_1_1 text_center_ctyle" value={offer4fields.min_rooms_per_booking}
                                        name="min_rooms_per_booking"
                                        onChange={handlechangeoffer4}
                                        >
                                        {Array.from({ length: 19 }, (_, index) => (
                                            <option key={index + 2} value={index + 2}>
                                              {index + 2}
                                            </option>
                                          ))}
                                          </select>
                                            </span>
                                        <span className="offer_freeningt_join ">Rooms </span>
                                    </span>
                                </label>
                            </div>
                        </li>
                        <li>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input restr_availRoom_check" type="checkbox" id="formCheck1" 
                                onChange={(e)=>handleoffer4checkbox(e.target.checked,"close_offer")} value={offer4fields.close_offer} checked={offer4fields.close_offer===true?true:false}/>
                                <label> 
                                  <span className="offer_freeningt_join">Close the offer when there are few available rooms : </span>
                                  <span className={offer4fields.close_offer===true?"restr_availRoom_expand":"restr_availRoom_expand d-none"}>
                                      <span className="offer_freeningt_join ">Close the offer when there are less than </span>
                                      <span className="offer_freeningt_join " >
                                          <select className="form-select offer-create-select box_style_1 text_center_ctyle" value={offer4fields.offer_close_for_room}
                                        name="offer_close_for_room"
                                        onChange={handlechangeoffer4}
                                        >
                                        {Array.from({ length: 179 }, (_, index) => (
                                            <option key={index + 2} value={index + 2}>
                                              {index + 2}
                                            </option>
                                          ))}
                                          </select>
                                          </span>
                                      <span className="offer_freeningt_join ">rooms in inventory available </span>
                                  </span>
                              </label>
                            </div>
                        </li>
                        <li>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input restr_maxRoom_check" type="checkbox" id="formCheck1" 
                                onChange={(e)=>handleoffer4checkbox(e.target.checked,"maximum_rooms")} value={offer4fields.maximum_rooms} checked={offer4fields.maximum_rooms===true?true:false}/>
                                <label> 
                                <span className="offer_freeningt_join">Maximum quantity of rooms per night at this offer (all the reservations considered)... </span>
                                <span className={offer4fields.maximum_rooms===true?"restr_maxRoom_expand":"restr_maxRoom_expand d-none"}>
                                  <span className="offer_freeningt_join " >
                                    <select className="form-select offer-create-select box_style_1 text_center_ctyle" value={offer4fields.max_room_per_night}
                                        name="max_room_per_night"
                                        onChange={handlechangeoffer4}
                                        >
                                        {Array.from({ length: 180 }, (_, index) => (
                                            <option key={index + 1} value={index + 1}>
                                              {index + 1}
                                            </option>
                                          ))}
                                          </select>
                                  </span>
                                </span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input restr_last_minute_check" type="checkbox" id="formCheck1" 
                                onChange={(e)=>handleoffer4checkbox(e.target.checked,"last_minute")} value={offer4fields.last_minute} checked={offer4fields.last_minute===true?true:false}/>
                                <label> 
                                <span className="offer_freeningt_join">Last minute... :</span>
                                <span className={offer4fields.last_minute===true?"restr_last_minute_expand":"restr_last_minute_expand d-none"}>
                                  <span className="offer_freeningt_join ">Only activate the offer when less than</span>
                                  <span className="offer_freeningt_join " >
                                    <select className="form-select offer-create-select box_style_1 text_center_ctyle"
                                    value={offer4fields.offer_last_minute_hours}
                                    name="offer_last_minute_hours"
                                    onChange={handlechangeoffer4}>
                                      <option value=""></option>
                                      <option value="-8">-8</option>
                                      <option value="-6">-6</option>
                                      <option value="0">0</option>
                                      <option value="12">12</option>
                                      <option value="18">18</option>
                                      <option value="24">24</option>
                                      <option value="36">36</option>
                                      <option value="48">48</option>
                                      <option value="72">72</option>
                                      <option value="96">96</option>
                                      <option value="120">120</option>
                                      <option value="140">140</option>
                                      <option value="168">168</option>
                                      <option value="240">240</option>
                                      <option value="336">336</option>
                                      <option value="720">720</option>
                                    </select>
                                  </span>
                                  <span className="offer_freeningt_join ">hours until 12 noon of the arrival date</span>
                                </span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input restr_night_display_check" type="checkbox" id="formCheck1" 
                                onChange={(e)=>handleoffer4checkbox(e.target.checked,"display_night")} value={offer4fields.display_night} checked={offer4fields.display_night===true?true:false}/>
                                <label>
                              Display only at night / weekend.
                            </label>
                          </div>
                          <div className={offer4fields.display_night===true?"restr_night_display_expand":"restr_night_display_expand d-none"}>
                            <ul className="rest_list_block">
                              <li>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="checkbox" id="formCheck1"
                                  onChange={(e)=>handleoffer4checkbox(e.target.checked,"offer_display_hours_status")} value={offer4fields.offer_display_hours_status} checked={offer4fields.offer_display_hours_status===true?true:false}/>
                                  <label className="offer-step-label display-night"> 
                                    <span className="offer_freeningt_join freenight-media-from">From</span>
                                    <span className="offer_freeningt_join freenight-media-from">
                                      {/* <input type="text" className="form-control offer-step-textfield flatpickr-input active box_style_1" data-provider="timepickr" data-time-basic="true" id="timepicker-example" readonly="readonly" style={{width:"110px"}}/> */}
                                      {/* <input type="time" value={selectedTime} onChange={handleTimeChange}/> */}

                                      <select className="form-select offer-create-select box_style_1 text_center_ctyle" value={offer4fields.offer_display_from}
                                        name="offer_display_from"
                                        onChange={handlechangeoffer4}
                                        >
                                         <option value="0">12 am</option>
                                          <option value="1">1 am</option>
                                          <option value="2">2 am</option>
                                          <option value="3">3 am</option>
                                          <option value="4">4 am</option>
                                          <option value="5">5 am</option>
                                          <option value="6">6 am</option>
                                          <option value="7">7 am</option>
                                          <option value="8">8 am</option>
                                          <option value="9">9 am</option>
                                          <option value="10">10 am</option>
                                          <option value="11">11 am</option>
                                          <option value="12">12 pm</option>
                                          <option value="13">1 pm</option>
                                          <option value="14">2 pm</option>
                                          <option value="15">3 pm</option>
                                          <option value="16">4 pm</option>
                                          <option value="17">5 pm</option>
                                          <option value="18">6 pm</option>
                                          <option value="19">7 pm</option>
                                          <option value="20">8 pm</option>
                                          <option value="21">9 pm</option>
                                          <option value="22">10 pm</option>
                                          <option value="23">11 pm</option>
                                          </select>
                                    </span>
                                    {/* <span className="offer_freeningt_join_1">am </span>  */}

                                    <span className="offer_freeningt_join offer_freeningt_join"> To </span>
                                    <span className="offer_freeningt_join offer_freeningt_join select-2-medi">
                                    <select className="form-select offer-create-select box_style_1 text_center_ctyle" value={offer4fields.offer_display_to}
                                        name="offer_display_to"
                                        onChange={handlechangeoffer4}
                                        >
                                           <option value="12">12 pm</option>
                                          <option value="13">1 pm</option>
                                          <option value="14">2 pm</option>
                                          <option value="15">3 pm</option>
                                          <option value="16">4 pm</option>
                                          <option value="17">5 pm</option>
                                          <option value="18">6 pm</option>
                                          <option value="19">7 pm</option>
                                          <option value="20">8 pm</option>
                                          <option value="21">9 pm</option>
                                          <option value="22">10 pm</option>
                                          <option value="23">11 pm</option>
                                        <option value="00">12 am</option>
                                          <option value="1">1 am</option>
                                          <option value="2">2 am</option>
                                          <option value="3">3 am</option>
                                          <option value="4">4 am</option>
                                          <option value="5">5 am</option>
                                          <option value="6">6 am</option>
                                          <option value="7">7 am</option>
                                          <option value="8">8 am</option>
                                          <option value="9">9 am</option>
                                          <option value="10">10 am</option>
                                          <option value="11">11 am</option>
                                         
                                          </select>
                                    </span>
                                    {/* <span className="offer_freeningt_join_1">pm </span>  */}

                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="form-check form-check-inline">
                                  <input className="form-check-input" type="checkbox" id="formCheck1"
                                    onChange={(e)=>handleoffer4checkbox(e.target.checked,"offer_display_weekend_status")} value={offer4fields.offer_display_weekend_status} checked={offer4fields.offer_display_weekend_status===true?true:false}/>
                                                              
                                  <label className="offer-step-label weekend-box">
                                    <span className="offer_freeningt_join">
                                    <span className="offer_week_from">Weekends: from </span>
                                            <select id="ForminputState" className="form-select-2 box_style_is " 
                                                  value={offer4fields.offer_display_weekend_week_from}
                                                  name="offer_display_weekend_week_from"
                                                  onChange={handlechangeoffer4}
                                                  >
                                                      <option></option>
                                                      <option value="Sunday">Sunday</option>
                                                      <option value="Monday">Monday</option>
                                                      <option value="Tuesday">Tuesday</option>
                                                      <option value="Wednesday">Wednesday</option>
                                                      <option value="Thursday">Thursday</option>
                                                      <option value="Friday">Friday</option>
                                                      <option value="Saturday">Saturday</option>

                                              </select>
                                          
                                          <span className="offer_week_from">at </span>
                                          <select id="ForminputState" className="form-select-3 box_style_is form-select-media" 
                                                  value={offer4fields.offer_display_weekend_time_from}
                                                  name="offer_display_weekend_time_from"
                                                  onChange={handlechangeoffer4}
                                                  >
                                                      <option></option>
                                                      <option value="00">12 am</option>
                                          <option value="1">1 am</option>
                                          <option value="2">2 am</option>
                                          <option value="3">3 am</option>
                                          <option value="4">4 am</option>
                                          <option value="5">5 am</option>
                                          <option value="6">6 am</option>
                                          <option value="7">7 am</option>
                                          <option value="8">8 am</option>
                                          <option value="9">9 am</option>
                                          <option value="10">10 am</option>
                                          <option value="11">11 am</option>
                                          <option value="12">12 pm</option>
                                          <option value="13">1 pm</option>
                                          <option value="14">2 pm</option>
                                          <option value="15">3 pm</option>
                                          <option value="16">4 pm</option>
                                          <option value="17">5 pm</option>
                                          <option value="18">6 pm</option>
                                          <option value="19">7 pm</option>
                                          <option value="20">8 pm</option>
                                          <option value="21">9 pm</option>
                                          <option value="22">10 pm</option>
                                          <option value="23">11 pm</option>


                                              </select>
                                              <span className="offer_week_from "> to</span>
                                               <select id="ForminputState" className="form-select-2 box_style_is select-2-medi" 
                                                  value={offer4fields.offer_display_weekend_week_to}
                                                  name="offer_display_weekend_week_to"
                                                  onChange={handlechangeoffer4}
                                                  >
                                                      <option></option>
                                                      <option value="Sunday">Sunday</option>
                                                      <option value="Monday">Monday</option>
                                                      <option value="Tuesday">Tuesday</option>
                                                      <option value="Wednesday">Wednesday</option>
                                                      <option value="Thursday">Thursday</option>
                                                      <option value="Friday">Friday</option>
                                                      <option value="Saturday">Saturday</option>

                                              </select>
                                              <span className="offer_week_from"> at </span>
                                               <select id="ForminputState" className="form-select-3 box_style_is form-select-media" 
                                                  value={offer4fields.offer_display_weekend_time_to}
                                                  name="offer_display_weekend_time_to"
                                                  onChange={handlechangeoffer4}
                                                  >
                                                       <option></option>
                                                       <option value="12">12 pm</option>
                                          <option value="13">1 pm</option>
                                          <option value="14">2 pm</option>
                                          <option value="15">3 pm</option>
                                          <option value="16">4 pm</option>
                                          <option value="17">5 pm</option>
                                          <option value="18">6 pm</option>
                                          <option value="19">7 pm</option>
                                          <option value="20">8 pm</option>
                                          <option value="21">9 pm</option>
                                          <option value="22">10 pm</option>
                                          <option value="23">11 pm</option>
                                          <option value="00">12 am</option>
                                          <option value="1">1 am</option>
                                          <option value="2">2 am</option>
                                          <option value="3">3 am</option>
                                          <option value="4">4 am</option>
                                          <option value="5">5 am</option>
                                          <option value="6">6 am</option>
                                          <option value="7">7 am</option>
                                          <option value="8">8 am</option>
                                          <option value="9">9 am</option>
                                          <option value="10">10 am</option>
                                          <option value="11">11 am</option>

                                              </select> 
                                              {/* <span className="offer_week_from">am</span> */}
                                              
                                    </span>
                                    <label style={{ color: "red" }}>
                                                              {offer4error.offer_display_hours_status}
                                                            </label>

                                  </label>
                                </div>
                              </li>
                            </ul>
                          
                          </div>
                        </li>
                        <li>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input restr_country_check" type="checkbox" id="formCheck1" 
                                onChange={(e)=>handleoffer4checkbox(e.target.checked,"apply_country")} value={offer4fields.apply_country} checked={offer4fields.apply_country===true?true:false}/>
                                <label>
                              Apply only to these countries:
                            </label>
                          </div>
                          <div className={offer4fields.apply_country===true?"restr_country_expand":"restr_country_expand d-none"}>
                            <div className="row">
                            <div className="col-md-12 col-lg-12 col-xl-12" style={{paddingLeft:"30px"}}>
                                        <div className="input-group">
                                                <label className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default">Select Country</label>
                                                <MultiSelect
                                            options={countrylist}
                                            value={multicountri4}
                                            onChange={setMulticountry4}
                                            labelledBy="Select"
                                            selectionType="counter"
                                            closeOnChangedValue={false}
                                            aria-expanded="true"
                                            hasSelectAll={true}
                                            className="offer_country_multi_12 multi-width"
                                            />
                                        </div>
                                          
                                       
                                    </div>
                                    <div className='col-md-12' style={{padding:"10px 15px 0px 30px"}}>
                                      <div className=''>
                              {multicountri4.map((country,i)=>{
                                return(
                                  <div key={i}className='country_all_data' style={{float:"left"}}>
                                        
                                  <div className="input-group ">
                                         <label className="input-group-text input-joint-color country_dis"
                                     id="inputGroup-sizing-default" title={country.label}>{country.label}</label>
                                        
                               
                                  </div>
                              
                                 </div>
                                )
                              })}
                              </div>

                              </div>
                              
                              </div>
                          </div>
                          

                          <label style={{ color: "red" }}>
                            {offer4error.apply_country}
                          </label>

                        </li>
                        {multicountri4.length>0?
                        <div className='clearfix'> </div>
                        :""}

                        <li style={{marginTop:"10px"}}>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input restr_device_check" type="checkbox" id="formCheck1" 
                                onChange={(e)=>handleoffer4checkbox(e.target.checked,"only_device")} value={offer4fields.only_device} checked={offer4fields.only_device===true?true:false}/>
                                <label>
                              Only on this device type:
                            </label>
                          </div>
                          <div className={offer4fields.only_device===true?"restr_device_expand":"restr_device_expand d-none"}>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input " type="radio"  id="device_offer1"
                                name='offer_available_device_type'
                                value="desktop"
                                checked={offer4fields.offer_available_device_type === 'desktop'}
                                onChange={() => handleOptionChangeoffer4Cancelpolicy('desktop',"offer_available_device_type")}/>
                              <label>Desktop / Tablet </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio"  id="device_offer2" 
                                name='offer_available_device_type'
                                value="mobile"
                                checked={offer4fields.offer_available_device_type === 'mobile'}
                                onChange={() => handleOptionChangeoffer4Cancelpolicy('mobile',"offer_available_device_type")}/>
                              <label>Mobile</label>
                            </div>
                          </div>
                        
                        </li>
                        <label style={{ color: "red" }}>
                            {offer4error.only_device}
                          </label>
                          <label style={{ color: "red" }}>
                            {offer4error.offer4}
                          </label>
                    </ul>
                  
                    
                </div>
            </div>
            <div className="tab_nav_section">
                <div className="tab_nav_section_btns">
                    <Link className="btn btn-be-form-cancel btn-be-back" to="#" onClick={(e)=>nextoffterstep4('prev')}>Prev</Link>
                    {/* <Link className="btn btn-success btn-be-common" to="#" onClick={(e)=>setTab("step5")}>Next</Link> */}
                    <Link className="btn btn-success btn-be-common" to="#" onClick={(e)=>nextoffterstep4('next')} >Next</Link>

                </div>
            </div> 
        </div>
          </div>
        </div>
      </div>
    </div>

 )
}

export default Offerstep4sugg
