import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Loyaltyheader from "../../../Common/Loyaltyheader";
import Nodatafound from "../../../Common/Nodatafound";
import Config from "../../../Config";
import { useAuthHandling } from "../../../Common/useAuthHandling";
import Loading from "../../../Loading";
import { UserContext } from "../../../Contexts/UserContext";

function Loyaltymember() {
  const [member, setMember] = useState([]);
  const [page, setPage] = useState(1);
  const [pageindex, setPageindex] = useState(1);
  const [paginate, setPaginate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFullData, setShowFullData] = useState([]);
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [selehotel, setSelechotel] = useState(0);

  const handleDropdownClickhotel = (i) => {
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  const getlist = async (e) => {
    const res = await fetch(`${Config.apiurl}loyalty-members/list`, {
      method: "POST",
      body: JSON.stringify({
        hotels: id,
      }),

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setMember(data.data.data);
        setPaginate(data.data.last_page);
        setLoading(false);
      } else if (res.reauth === true) {
        handleUnauthorized();
        getlist();
      } else {
        setLoading(false);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getlist();
    } else {
      console.log("error");
    }
  };

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}loyalty-members/list=${currentPage}`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),

        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );

    const data = await res.json();
    if (data.status_code === "200") {
      return data.data;
    } else if (data.reauth === true) {
      handleUnauthorized();
      fetchComments(currentPage);
    }
  };

  const handlePageClick = async (data) => {
    if (data === 1) {
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);

      setMember(commentsFormServer);
    } else {
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);

      setMember(commentsFormServer);
    }
  };

  const Export = async (e) => {
    try {
      const res = await fetch(
        `${Config.apiurl}hotel/loyalty/members/list/download`,
        {
          method: "POST",
          body: JSON.stringify({
            hotel_id: member[selehotel]?.hotel_id,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );

      if (res.status !== 200) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const contentDisposition = res.headers.get("content-disposition");
      console.log(contentDisposition);
      const fileNameMatch =
        contentDisposition &&
        contentDisposition.match(/filename=(["'])(?:(?=(\\?))\2.)*?\1/);

      const suggestedFileName = fileNameMatch
        ? fileNameMatch[0].substring(10, fileNameMatch[0].length - 1)
        : "club_members.xlsx";

      const blob = await res.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([blob]));
      link.download = suggestedFileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  useEffect(() => {
    getlist();
  }, [id]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (member.length > 0) {
      const initialState = member.map((item, index) => ({
        hotel: index === 0,
      }));
      setShowFullData(initialState);
    }
  }, [member]);

  return (
    <div>
      <Loyaltyheader />
      <div className="main-content">
        <div className="page-content">
          <div className="col-lg-12">
            <div className="card" id="orderList">
              <div className="card-header border-0">
                <div className="row align-items-center gy-3">
                  <div className="col-sm d-flex justify-content-between">
                    <h5 className="card-title mb-0">List Of Loyalty Members</h5>
                    <div className="flex-shrink-0 d-flex justify-content-between">
                      <button
                        type="button"
                        onClick={Export}
                        className="btn loyalty_import_btn "
                      >
                        Export
                      </button>

                      {/* <button
                                type="button"
                               
                                className="btn loyalty_import_btn"
                                >
                                Import
                                </button> */}
                      <Link
                        type="button"
                        to={`/loyalty-member-create/${member[selehotel]?.hotel_id}`}
                        className="btn db-save-button_crete "
                      >
                        Create
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {loading === true ? (
                <Loading />
              ) : (
                <div className="card-body pt-0">
                  {member.map((data, i) => {
                    return (
                      <>
                        <div
                          className={
                            member.length > 1 ? "row hotel_single_list" : "row"
                          }
                          key={i}
                        >
                          {member.length > 1 ? (
                            <div className="hotel_service_display hotel_service_display_12">
                              <div className="hotel_service_display_14">
                                <input
                                  type="radio"
                                  id="radio-one"
                                  className="hotel_service_display_14_radio form-check-input per_room_once_check"
                                  value={true}
                                  checked={i === selehotel ? true : false}
                                  onChange={(e) => setSelechotel(i)}
                                  style={{
                                    position: "relative",
                                    top: "8px",
                                  }}
                                />

                                <div className="hk_service_all_hotel">
                                  <span className="">
                                    {" "}
                                    {pageindex === 1
                                      ? i + 1
                                      : (pageindex - 1) * 10 + i + 1}
                                    .
                                  </span>
                                  <span style={{ marginLeft: "10px" }}>
                                    {data.hotel_name}
                                  </span>
                                </div>
                              </div>
                              {showFullData[i]?.hotel === true ? (
                                <span
                                  className="db-modify_toggle_btn"
                                  onClick={() => handleDropdownClickhotel(i)}
                                >
                                  <span
                                    className="bx bxs-chevron-up"
                                    style={{ fontSize: "20px" }}
                                  ></span>
                                </span>
                              ) : (
                                <span
                                  className="db-modify_toggle_btn"
                                  onClick={() => handleDropdownClickhotel(i)}
                                >
                                  <span
                                    className="bx bxs-chevron-down"
                                    style={{ fontSize: "20px" }}
                                  ></span>
                                </span>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="">
                            <table
                              className={`table table-nowrap align-middle ${
                                showFullData[i]?.hotel === true ? "" : "d-none"
                              }`}
                              id="orderTable"
                            >
                              {data.loyalty_members.length > 0 ? (
                                <>
                                  <thead className="text-muted table-light">
                                    <tr className="text-uppercase">
                                      <th scope="col" style={{ width: "25px" }}>
                                        <div className="form-check">
                                          {/* <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    
                                    /> */}
                                        </div>
                                      </th>
                                      <th className="" data-sort="id">
                                        SL
                                      </th>
                                      <th
                                        className=""
                                        data-sort="customer_name"
                                      >
                                        Name
                                      </th>
                                      <th
                                        className=""
                                        data-sort="customer_name"
                                      >
                                        Country
                                      </th>
                                      <th
                                        className=""
                                        data-sort="customer_name"
                                      >
                                        Category
                                      </th>
                                      <th
                                        className=""
                                        data-sort="customer_name"
                                      >
                                        Joining Date
                                      </th>
                                      <th
                                        className=""
                                        data-sort="customer_name"
                                      >
                                        Source
                                      </th>
                                      <th
                                        className=""
                                        data-sort="customer_name"
                                      >
                                        Status
                                      </th>
                                      <th className="" data-sort="city">
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="list form-check-all">
                                    {data.loyalty_members?.map(
                                      (element, index) => {
                                        return (
                                          <tr key={index}>
                                            <th scope="row">
                                              <div className="form-check">
                                                {/* <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="checkAll"
                                          value="option1"
                                        /> */}
                                              </div>
                                            </th>
                                            <td className="id">
                                              {pageindex === 1
                                                ? index + 1
                                                : (pageindex - 1) * 10 +
                                                  index +
                                                  1}
                                            </td>
                                            <td
                                              className="customer_name"
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {element.name} {element.surname}
                                            </td>
                                            <td className="customer_name">
                                              {element.country_name}
                                            </td>
                                            <td className="customer_name">
                                              {element.category_name}
                                            </td>
                                            <td className="customer_name">
                                              {element.joining_date}
                                            </td>
                                            <td className="customer_name">
                                              {element.source}
                                            </td>
                                            <td className="status">
                                              {element.status === false ? (
                                                <span class="badge badge-soft-danger text-uppercase">
                                                  {" "}
                                                  INACTIVE
                                                </span>
                                              ) : (
                                                <span className="badge badge-soft-success text-uppercase">
                                                  ACTIVE
                                                </span>
                                              )}
                                            </td>
                                            <td>
                                              <ul className="list-inline hstack gap-2 mb-1">
                                                <li
                                                  className="list-inline-item edit"
                                                  data-bs-toggle="tooltip"
                                                  data-bs-trigger="hover"
                                                  data-bs-placement="top"
                                                  title="Edit"
                                                >
                                                  <Link
                                                    to={`/loyalty-member-update/${element.user_id}/${data.hotel_id}`}
                                                    data-bs-toggle="modal"
                                                    className="text-primary d-inline-block edit-item-btn"
                                                  >
                                                    <i className="ri-pencil-fill fs-16"></i>
                                                  </Link>
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </>
                              ) : (
                                <Nodatafound />
                              )}
                            </table>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  {paginate > 1 ? (
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={paginate}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loyaltymember;
