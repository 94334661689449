import React, { useContext, useEffect, useState } from "react";

import Select from "react-select";
import { Link } from "react-router-dom";
import Conditionsheader from "../../../Common/Conditionsheader";
import { useAuthHandling } from "../../../Common/useAuthHandling";
import Config from "../../../Config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Toastmodal from "../../../Common/Toastmodal";
import DeleteModal from "../../../DeleteModal";
import Loading from "../../../Loading";
import { UserContext } from "../../../Contexts/UserContext";

function Othercondition() {
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [lang, setLang] = useState([]);
  const [selectlang, setSelectlang] = useState([]);

  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [conditionid, setConditionid] = useState("");
  const [langcode, setLangcode] = useState("");
  const [hotelcondition, setHotelcondition] = useState("");
  const [newLang, setNewLang] = useState([]);
  const [condition_lang, setCondition_lang] = useState([]);
  const [loading,setLoading]=useState(true);
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const [id, setId] = useState(hotel_select ? hotel_select[0]?.value : "");
  const [hotelname, setHotelname] = useState(
    hotel_select ? hotel_select[0]?.label : ""
  );
    const [ckedi,setCkedi]=useState(false);
  const [inputFields, setInputFields] = useState([
    {
      multi_lang: [],
      // link_type: "website_link",
      // link: "",
      condition: "",
    },
  ]);

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        multi_lang: [],

        // link_type: "website_link",
        // link: "",

        condition: "",
      },
    ]);
  };

  const [delete_animationFlip, setDelete_animationFlip] = useState(false);
  function tog_animationFlipDel(code) {
    setLangcode(code);
    setDelete_animationFlip(!delete_animationFlip);
  }

  const getlanguage = async (e) => {
    const res = await fetch(`${Config.apiurl}booking-engine-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setLang(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getlanguage();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getlanguage();
    } else {
      console.log("error");
    }
  };

  const getOptionalLang = async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setSelectlang(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getOptionalLang();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getOptionalLang();
    } else {
      console.log("error");
    }
  };

  const handleEditorChange = (i, editor, event) => {
    const data = editor.getData();
    inputFields[i].condition = data;
  };

  let handleChangeLang = (i, data, name) => {
    let newval = [...inputFields];
    inputFields[i][name] = data;
    setInputFields(newval);
  };

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  let handleChange = (i, e) => {
    let newval = [...inputFields];
    inputFields[i][e.target.name] = e.target.value;
    setInputFields(newval);
  };

  const getData = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/other-condition`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setLoading(false);
        if(data.data.hotel_condition.condition && data.data.hotel_condition.condition!==null){
          setCkedi(true);
        }
        setHotelcondition(data.data.hotel_condition);
        setConditionid(data.data.hotel_condition?.hotel_condition_id);
        setCondition_lang(data.data["multi-lang"]);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getData();
      } else {
        console.log("error");
        setLoading(false);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getData();
    } else {
      console.log("error");
      setLoading(false);
    }
  };

  const Add = async () => {
    let newVal = [];
    for (let i = 1; i < inputFields.length; i++) {
      let multiarray = {
        lang_code: inputFields[i].multi_lang.code,
        condition: inputFields[i].condition,
        // link_type: inputFields[i].link_type,
        // url_link: inputFields[i].link,
      };
      newVal.push(multiarray);
    }
    const res = await fetch(`${Config.apiurl}hotel/other-condition/update`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        // link_type: inputFields[0].link_type,
        // url_link: inputFields[0].link,
        condition: inputFields[0].condition,
        multi_lang: newVal,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status !== 200 || !data.data) {
        console.log("error");
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(true);
        getData();
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      Add();
    } else {
      console.log("error");
    }
  };

  const Clear = async () => {
    setCkedi(false);
    setInputFields([]);
    const res = await fetch(`${Config.apiurl}hotel/other-condition/clear`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200" || !data.data) {
        console.log("error");
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
      } else {
        setLoading(true);
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(true);
        getData();
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      Clear();
    } else {
      console.log("error");
    }
  };

  const Delete = async () => {
    const res = await fetch(`${Config.apiurl}hotel/other-condition/delete`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        lang_code: langcode,
        condition_id: conditionid,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await res.json();
    if (res.status === 200) {
      setToastmodal(true);
      setToastmessage(data.message);
      setToasttype("success");
      setToastlarge(true);
      getData();
      tog_animationFlipDel(false);
    } else if (res.reauth === true) {
      handleUnauthorized();
      Delete();
    } else {
      console.log("error");
      setToastmodal(true);
      setToastmessage(data.message);
      setToasttype("error");
      setToastlarge(true);
    }
  };

  useEffect(() => {
    getlanguage();
    getOptionalLang();
    getData();
  }, [id]);

  useEffect(() => {
    for (let i = 0; i < lang?.length; i++) {
      if (lang[i]?.code === "en") {
        setNewLang({
          value: lang[i].value,
          label: lang[i].label,
        });
      }
    }
  }, [lang.length]);

  useEffect(() => {
    if (hotelcondition && inputFields?.length > 0) {
      setInputFields((prevInputFields) => {
        const updatedFields = [...prevInputFields];
        updatedFields[0] = {
          condition: hotelcondition.condition,
          // link: hotelcondition.link,
          // link_type: hotelcondition.link_type,
        };
        return updatedFields;
      });
    } else if (hotelcondition) {
      setInputFields([
        {
          condition: hotelcondition.condition,
          // link: hotelcondition.link,
          // link_type: hotelcondition.link_type,
        },
      ]);
    }
    if (condition_lang) {
      setInputFields((prevInputFields) => {
        const updatedFields = [...prevInputFields];
        const validIndexes = [0];
        for (let i = 0; i < condition_lang?.length; i++) {
          const multiLang = {};

          const matchedLang = selectlang.filter(
            (item) => item.code === condition_lang[i]?.lang_code
          );
          multiLang.value = matchedLang[0]?.value;
          multiLang.label = matchedLang[0]?.label;
          multiLang.code = matchedLang[0]?.code;

          updatedFields.push({
            condition: condition_lang[i]?.condition,
            // link: condition_lang[i]?.link,
            // link_type: condition_lang[i]?.link_type,
            multi_lang: multiLang,
          });

          const index = updatedFields.findIndex(
            (field) => field.multi_lang?.code === condition_lang[i]?.lang_code
          );
          if (index !== -1) {
            validIndexes.push(index);
          }
        }

        return updatedFields.filter((_, index) => validIndexes.includes(index));
        // return updatedFields;
      });
    }
  }, [hotelcondition, condition_lang.length, selectlang.length]);

  useEffect(() => {
    setId(hotel_select[0]?.value);
  }, [hotel_select]);

  useEffect(() => {
    setHotelname(hotel_select[0]?.label);
  }, [hotel_select]);

  console.log(inputFields);
  return (
    <div>
      <Conditionsheader />
      <div className="main-content">
        <div className="page-content">
          {loading ===true ?
          <Loading/>
          :
          <div className="container-fluid">
            <div className="row">
              <div className="col-12"></div>

              <h4 className="" style={{ marginLeft: "10px" }}>
                {hotelname}
              </h4>
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="db-page-title" style={{ color: "#008080" }}>
                  Other Conditions
                </h4>

                <div className="card-header align-items-center d-flex">
                  <div className="flex-shrink-0">
                    {/* { inputFields.length===1?
                  <Link
                      type="button"
                      to="#"
                      className="btn db-save-button "
                      onClick={Clear}
                    >
                      Clear Data
                    </Link>
                    :""} */}

                      {/* {inputFields.some(field => field.condition) ? ( */}
                      {inputFields.length>1 && inputFields[1].multi_lang.code ? (
                        <Link
                          type="button"
                          to="#"
                          className="btn db-save-button"
                          onClick={Clear}
                        >
                          Clear Data
                        </Link>
                      ) : (
                        ""
                      )}
                      
                    <Link
                      type="button"
                      to="#"
                      className="btn db-save-button "
                      onClick={Add}
                    >
                      Save
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card padding_card">
                <div className="addmore_tax_details new_tax_options">
                  {inputFields?.map((data, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          className={
                            index === inputFields.length - 1
                              ? "row"
                              : "row tax_sep"
                          }
                          style={{ marginTop: "10px" }}
                        >
                          {data?.multi_lang?.code ? (
                            <Link
                              className={
                                index === 0
                                  ? "other_condition_dlt d-none"
                                  : "other_condition_dlt"
                              }
                              to=""
                              title="Delete"
                            >
                              <img
                                src="images/trash.png"
                                onClick={(e) =>
                                  tog_animationFlipDel(data?.multi_lang?.code)
                                }
                                style={{ width: "16px" }}
                              />
                            </Link>
                          ) : (
                            <Link
                              className={
                                index === 0
                                  ? "other_condition_dlt d-none"
                                  : "other_condition_dlt"
                              }
                              to=""
                              title="Delete"
                              onClick={(e) => removeInputFields(index)}
                            >
                              <img
                                src="images/trash.png"
                                style={{ width: "16px" }}
                              />
                            </Link>
                          )}
                          {index === 0 ? (
                            <div className="col-lg-4">
                              <div className="mb-4">
                                <div className="input-group">
                                  <label
                                    className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"
                                  >
                                    Choose Language
                                  </label>
                                  <div className="col-md-7">
                                    <Select
                                      className="form-control other_condition_selectlang"
                                      data-choices
                                      name="choices-single-default"
                                      id="choices-single-default"
                                      options={lang}
                                      value={newLang}
                                     
                                    ></Select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-lg-4">
                              <div className="mb-4">
                                <div className="input-group">
                                  <label
                                    className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"
                                  >
                                    Choose Language
                                  </label>
                                  <div className="col-md-7">
                                    <Select
                                      className="form-control other_condition_selectlang"
                                      data-choices
                                      name="choices-single-default"
                                      id="choices-single-default"
                                      options={selectlang}
                                      value={data.multi_lang}
                                      onChange={(e) =>
                                        handleChangeLang(index, e, "multi_lang")
                                      }
                                    ></Select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {/* <div className="col-md-8">

                            <div
                              className="input-group"
                              style={{
                                width: "400px",
                              }}
                            >
                              <select
                                className="form-select"
                                onChange={(e) => handleChange(index, e)}
                                value={data.link_type}
                                name="link_type"
                                style={{
                                  backgroundColor: "#9edfed",
                                  maxWidth: "fit-content",
                                }}
                              >
                                <option value="website_link">
                                  Website URL
                                </option>
                                <option value="email">Email</option>
                              </select>

                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="link"
                                value={data.link}
                                onChange={(e) => handleChange(index, e)}
                                style={{ height: "40px" }}
                              />
                            </div>

                          </div> */}
                            {data.condition && data.condition !== null && data.condition !== undefined && ckedi===true?
                          <div className="col-md-12">
                            {console.log("1")}
                            <CKEditor
                              editor={ClassicEditor}
                              data={data.condition}
                              onChange={(editor, event) =>
                                handleEditorChange(index, event, editor)
                              }
                            />
                          </div>
                          :  <div className="col-md-12">
                              {console.log("2")}
                          <CKEditor
                            editor={ClassicEditor}
                            onChange={(editor, event) =>
                              handleEditorChange(index, event, editor)
                            }
                          />
                        </div>
                            }
                          <div
                            className="col-md-12"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <span
                              className={
                                index === inputFields.length - 1
                                  ? "extrarate-room-link extra_link_padding"
                                  : "extrarate-room-link d-none"
                              }
                            >
                              <Link
                                className="extra-rate-add"
                                to="#"
                                onClick={addInputField}
                              >
                                +
                              </Link>
                            </span>

                            {/* <Link
                            className={
                              index === 0
                                ? "remove_more d-none"
                                : "remove_more extra_link_padding"
                            }
                            to="#"
                            onClick={(e) => removeInputFields(index)}
                          >
                            X
                          </Link> */}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
      {delete_animationFlip ? (
        <DeleteModal
          modal_animationFlip={delete_animationFlip}
          tog_animationFlip={tog_animationFlipDel}
          DeleteData={Delete}
          pageid={conditionid}
          message={"Condition"}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Othercondition;
