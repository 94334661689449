import React, { useState } from "react";
import Footer from "../../Common/Footer";
import { Link } from "react-router-dom";
import { useErrorBoundary } from "preact/hooks";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import Commonheader from "../../Common/Commonheader";
import Basicsettingsheader from "../../Common/Basicsettingsheader";

const Users = [
  {
    id: 1,
    selected: false,
    board: "Room Only",
    adult: "AED 0",
    child: "AED 0",
    status: "ACTIVE",
  },
  {
    id: 2,
    selected: false,
    board: "Breakfast",
    adult: "AED 80",
    child: "AED 60",
    status: "ACTIVE",
  },
  {
    id: 3,
    selected: false,
    board: "Fullboard",
    adult: "AED 180",
    child: "AED 160",
    status: "ACTIVE",
  },
  {
    id: 4,
    selected: false,
    board: "Halfboard",
    adult: "AED 120",
    child: "AED 90",
    status: "ACTIVE",
  },
];
function Hotelboardlist() {
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  function tog_animationFlip() {
    setmodal_animationFlip(!modal_animationFlip);
  }
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
  }
  const [List, setList] = useState(Users);
  const [MasterChecked, setMasterChecked] = useState(false);
  const [SelectedList, setSelectedList] = useState([]);

  function onMasterCheck(e) {
    let tempList = List;
    // Check/ UnCheck All Items
    tempList.map((user) => (user.selected = e.target.checked));

    setMasterChecked(e.target.checked);
    setList(tempList);
    setSelectedList(List.filter((e) => e.selected));
  }

  function onItemCheck(e, item) {
    let tempList = List;
    tempList.map((user) => {
      if (user.id === item.id) {
        user.selected = e.target.checked;
      }
      return user;
    });

    const totalItems = List.length;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;

    // Update State
    setMasterChecked(totalItems === totalCheckedItems);
    setList(tempList);
    setSelectedList(List.filter((e) => e.selected));
  }

  // Event to get selected rows(Optional)
  function getSelectedRows() {
    setSelectedList(List.filter((e) => e.selected));
  }
  return (
    <div id="layout-wrapper">
     <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Hotel Boards</h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Basic Settings</Link>
                      </li>
                      <li className="breadcrumb-item active">Hotel Board</li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Hotel Board List
                      </h4>
                      <div className="flex-shrink-0 d-flex justify-content-between">
                        <Link
                          type="button"
                          to="/hotel-board-add"
                          className="btn db-save-button"
                        >
                          Create
                        </Link>
                        {SelectedList.length > 0 ? (
                          <Link
                            className="text-danger d-inline-block remove-item-btn"
                            data-bs-toggle="modal"
                            to="#"
                            onClick={() => tog_animationFlip()}
                            style={{ marginLeft: "10px" }}
                          >
                            <i className="ri-delete-bin-5-fill fs-20"></i>
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div>
                        <ul
                          className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                          role="tablist"
                        ></ul>

                        <div className="table-responsive table-card mb-1">
                          <table
                            className="table table-nowrap align-middle"
                            id="orderTable"
                          >
                            <thead className="text-muted table-light">
                              <tr className="text-uppercase">
                                <th scope="col" style={{ width: "25px" }}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                      checked={MasterChecked}
                                      onChange={(e) => onMasterCheck(e)}
                                    />
                                  </div>
                                </th>
                                <th className="" data-sort="id">
                                  Sl No.
                                </th>
                                <th className="" data-sort="customer_name">
                                  Board
                                </th>
                                <th className="" data-sort="customer_name">
                                  Adult Price
                                </th>
                                <th className="" data-sort="customer_name">
                                  Child Price
                                </th>
                                <th className="" data-sort="customer_name">
                                  Status
                                </th>
                                <th className="" data-sort="city">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {List.map((user, index) => {
                                return (
                                  <tr key={index}>
                                    <th scope="row">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="checkAll"
                                          value="option1"
                                          checked={user.selected}
                                          id="rowcheck{user.id}"
                                          s
                                          onChange={(e) => onItemCheck(e, user)}
                                        />
                                      </div>
                                    </th>
                                    <td className="id">{index + 1}</td>
                                    <td className="customer_name">
                                      {user.board}
                                    </td>
                                    <td className="customer_name">
                                      {user.adult}
                                    </td>
                                    <td className="customer_name">
                                      {user.child}
                                    </td>
                                    <td className="status">
                                      {user.status === "DISABLED" ? (
                                        <span class="badge badge-soft-danger text-uppercase">
                                          {" "}
                                          {user.status}
                                        </span>
                                      ) : (
                                        <span className="badge badge-soft-success text-uppercase">
                                          {user.status}
                                        </span>
                                      )}
                                    </td>

                                    <td>
                                      <ul className="list-inline hstack gap-2 mb-1">
                                        <li
                                          className="list-inline-item edit"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Edit"
                                        >
                                          <Link
                                            to="#showModal"
                                            data-bs-toggle="modal"
                                            className="text-primary d-inline-block edit-item-btn"
                                          >
                                            <i className="ri-pencil-fill fs-16"></i>
                                          </Link>
                                        </li>
                                        <li
                                          className="list-inline-item"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Remove"
                                        >
                                          <Link
                                            className="text-danger d-inline-block remove-item-btn"
                                            data-bs-toggle="modal"
                                            to="#"
                                            onClick={() => tog_animationFlip()}
                                          >
                                            <i className="ri-delete-bin-5-fill fs-16"></i>
                                          </Link>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="d-flex justify-content-end">
                          <div className="pagination-wrap hstack gap-2">
                            <Link
                              className="page-item pagination-prev disabled"
                              to="#"
                            >
                              Previous
                            </Link>
                            <ul className="pagination listjs-pagination mb-0"></ul>
                            <Link className="page-item pagination-next" to="#">
                              Next
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <Modal
        id="flipModal"
        isOpen={modal_animationFlip}
        toggle={() => {
          tog_animationFlip();
        }}
        modalClassName="flip"
        centered
        style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalBody className="modal-body p-5 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#405189,secondary:#f06548"
            style={{ width: "90px", height: "90px" }}
          ></lord-icon>

          <div className="mt-4 text-center">
            <h4>You are about to delete a page ?</h4>
            <p className="text-muted fs-15 mb-4">
              Deleting your page will remove all of your information from our
              database.
            </p>
          </div>

          <button
            className="btn btn-link link-success fw-medium text-decoration-none"
            onClick={() => {
              tog_animationFlip();
            }}
          >
            {" "}
            <i className="ri-close-line me-1 align-middle"></i>
            Close{" "}
          </button>
          <Button color="danger"> Yes, Delete It </Button>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Hotelboardlist;
