import { Line } from "react-chartjs-2";
import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const generateMonths = (startMonth) => {
  const months = [];
  const date = new Date();
  date.setMonth(startMonth);

  for (let i = 0; i < 12; i++) {
    months.push(
      date.toLocaleString("default", { month: "short", year: "numeric" })
    );
    date.setMonth(date.getMonth() + 1);
  }

  return months;
};

const LinebarChart = ({ chartTitle, data, options }) => {
  return <Line data={data} options={options} />;
};

const App = () => {
  // Separate startMonth states for each chart
  const [startMonthPace, setStartMonthPace] = useState(
    new Date().getMonth() - 5
  );
  const [startMonthSales, setStartMonthSales] = useState(
    new Date().getMonth() - 5
  );

  const labelsPace = generateMonths(startMonthPace);
  const labelsSales = generateMonths(startMonthSales);

  // Chart data generators
  const generateChartData = (labels) => ({
    labels,
    datasets: [
      {
        label: "Room Nights (Current Year)",
        data: Array(12)
          .fill()
          .map(() => Math.floor(Math.random() * 300 + 100)),
        backgroundColor: "#4cb6b6",
        type: "bar",
        borderRadius: 4,
        order: 1,
      },
      {
        label: "Room Nights (Last Year)",
        data: Array(12)
          .fill()
          .map(() => Math.floor(Math.random() * 200 + 100)),
        backgroundColor: "#99d5d5",
        type: "bar",
        borderRadius: 4,
        order: 1,
        borderWidth: 1,
      },
      {
        label: "Average Daily Rate (Current Year)",
        data: [260, 260, 240, 260, 280, 270, 250, 240, 200, 220, 140, 260],
        borderColor: "red", // Sky Blue
        backgroundColor: "red",
        fill: false,
        type: "line",
        tension: 0.4, // Curved line
        order: "0",
        borderWidth: 2,
      },

      {
        label: "Average Daily Rate (Last Year)",
        data: [265, 275, 255, 265, 225, 275, 255, 245, 205, 255, 155, 250],
        borderColor: "#367588",
        backgroundColor: "#367588",
        fill: false,
        type: "line",
        tension: 0.4, //to make crve
        order: "0", //decide who is first according to order
        borderWidth: 2,
      },
    ],
  });

  const bookerInsightsData = {
    labels: ["Russia", "United Arab Emirates", "Others"],
    datasets: [
      {
        label: "My Reservations - Current Year",
        data: [30, 20, 10],
        backgroundColor: "#4cb6b6",
        type: "bar",
        borderRadius: 4,
      },
      {
        label: "Reservations - Last Year",
        data: [35, 30, 10],
        backgroundColor: "#99d5d5",
        type: "bar",
        borderRadius: 4,
      },
    ],
  };

  // Chart options for Pace Report and Sale Statistics
  const baseChartOptions = (title, yAxisLabel) => ({
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          boxWidth: 12,
          font: { size: 12 },
        },
      },
      title: {
        display: true,
        text: title,
        font: { size: 16, weight: "bold" },
      },
    },
    scales: {
      x: {
        grid: { color: "rgba(0, 0, 0, 0.1)" },
        barPercentage: 0.5,
      },
      y: {
        title: {
          display: true,
          text: yAxisLabel,
          color: "#367588",
          weight: "800",
        },
        grid: { color: "rgba(0, 0, 0, 0.1)" },
        ticks: {
          beginAtZero: true,
          // Uncomment the line below to add "AED" suffix to each tick
          // callback: function (value) {
          //   return value + " AED"; // Adds "AED" suffix to each tick
          // },
        },
      },
    },
  });

  // Chart options for Booker Insights (percentage)
  const bookerInsightsOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          boxWidth: 12,
          font: { size: 12 },
        },
      },
      title: {
        display: true,
        text: "Booker Insights",
        font: { size: 16, weight: "bold" },
      },
    },
    scales: {
      x: {
        grid: { color: "rgba(0, 0, 0, 0.1)" },
        barPercentage: 0.5,
      },
      y: {
        title: {
          display: true,
          text: "Reservation Percentage",
          color: "#367588",
          weight: "800",
        },
        grid: { color: "rgba(0, 0, 0, 0.1)" },
        ticks: {
          beginAtZero: true,
          // Set the step size to 10 for percentage ticks
          stepSize: 10,
          callback: function (value) {
            return value + "%"; // Adds "%" suffix to each tick
          },
        },
      },
    },
  };

  // Navigation handlers
  const handlePreviousPace = () => setStartMonthPace((prev) => prev - 1);
  const handleNextPace = () => setStartMonthPace((prev) => prev + 1);

  const handlePreviousSales = () => setStartMonthSales((prev) => prev - 1);
  const handleNextSales = () => setStartMonthSales((prev) => prev + 1);

  return (
    <div>
      <div>
        <LinebarChart
          chartTitle="Pace Report"
          data={generateChartData(labelsPace)}
          options={baseChartOptions("Pace Report", "Room Nights")}
        />
        <button className="next-prev-btn" onClick={handlePreviousPace}>
          Previous
        </button>
        <button className="next-prev-btn m-1" onClick={handleNextPace}>
          Next
        </button>
      </div>

      <div>
        <LinebarChart
          chartTitle="Sale Statistics"
          data={generateChartData(labelsSales)}
          options={baseChartOptions("Sale Statistics", "Room Nights")}
        />
        <button className="next-prev-btn" onClick={handlePreviousSales}>
          Previous
        </button>
        <button className="next-prev-btn m-1" onClick={handleNextSales}>
          Next
        </button>
      </div>

      <div>
        <LinebarChart
          chartTitle="Booker Insights"
          data={bookerInsightsData}
          options={bookerInsightsOptions}
        />
      </div>
    </div>
  );
};

export default App;
