import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { MultiSelect } from "react-multi-select-component";

function Offerstep1sugg({
  setTab,
  activestaydate,
  setActivestaydate,
  activestaydateopen,
  setActivestaydateopen,
  activecheckindate,
  setActivecheckindate,
  activecheckindateopen,
  setActivecheckindateopen,
  activecheckoutdate,
  setActivecheckoutdate,
  activecheckoutdateopen,
  setActivecheckoutdateopen,
  activereservedate,
  setActivereservedate,
  activereservedateopen,
  setActivereservedateopen,
  datefromstay,
  datetostay,
  datefromcheckin,
  datetocheckin,
  datefromcheckout,
  datetocheckout,
  datefromreserve,
  datetoreserve,
  fpstay,
  fpcheckin,
  fpcheckout,
  fpreserve,
  selectedWeekdaysstay,
  selectedWeekdayscheckin,
  selectedWeekdayscheckout,
  selectedWeekdaysreserve,
  clickedDaysstay,
  clickedDayscheckin,
  clickedDayscheckout,
  clickedDaysreserve,
  openclosestay,
  setOpenclosestay,
  openclosecheckin,
  setOpenclosecheckin,
  openclosecheckout,
  setOpenclosecheckout,
  openclosereserve,
  setOpenclosereserve,
  language,
  selectedDatesstay,
  setSelectedDatesstay,
  selectedDatescheckin,
  setSelectedDatescheckin,
  selectedDatescheckout,
  setSelectedDatescheckout,
  selectedDatesreserve,
  setSelectedDatesreserve,
  handleApplyToCalendarstay,
  handleApplyToCalendarcheckin,
  handleApplyToCalendarcheckout,
  handleApplyToCalendareserve,
  toggleSelectedDaystay,
  toggleSelectedDaycheckin,
  toggleSelectedDaycheckout,
  toggleSelectedDayreserve,
  handleOpenclosestay,
  handleOpenclosecheckin,
  handleOpenclosecheckout,
  handleOpenclosereserve,
  handleCalendarChangeFrom,
  handleCalendarChangeTo,
  handleCalendarChangeFromcheckin,
  handleCalendarChangeTocheckin,
  handleCalendarChangeFromcheckout,
  handleCalendarChangeTocheckout,
  handleCalendarChangeFromreserve,
  handleCalendarChangeToreserve,
  offer1Check,
  setOffer1check,
  selectedMulti,
  setselectedMulti,
  offername,
  setOffername,
  nextoffterstep1,
  offer1error,
}) {
  const [tab1, setTab1] = useState("EN");

  const handleCalendarIconClick = (calendarId) => {
    const calendar = document.querySelector(`#flatpickr-${calendarId}`);
    if (calendar) {
      const isCalendarOpen = calendar._flatpickr.isOpen;
      const icon = document.querySelector(`#calendar-icon-${calendarId}`);
      if (icon) {
        icon.style.display = isCalendarOpen ? "none" : "inline-block";
      }
      calendar._flatpickr[isCalendarOpen ? "close" : "open"]();
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="offer-create-section offer-creation-media offer-create-wid">
          <div className="offer-create-details">
            <div className="col-md-12">
              <h6 className="offer-step-desc">
                Create Your Exclusive Website Offer - Craft irresistible offers
                tailored to your guests' preference and boost bookings like
                never before.
              </h6>
              <div className="form-translation-style">
                <ul>
                  <li
                    className={tab1 === "EN" ? "active" : ""}
                    onClick={(e) => setTab1("EN")}
                  >
                    EN
                  </li>
                  <li
                    className={tab1 === "TRANS" ? "active" : ""}
                    onClick={(e) => setTab1("TRANS")}
                  >
                    Translations
                  </li>
                </ul>
              </div>
            </div>
            {tab1 === "EN" ? (
              <>
                <div className="offer-content-section">
                  <span className="offer-status">
                    <div
                      className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                      dir="ltr"
                    >
                      <input
                        type="checkbox"
                        className="form-check-input offer_checkbox1"
                        id="inlineswitch5"
                        style={{ width: "50px", height: "25px" }}
                        value={offer1Check}
                        checked={offer1Check === true ? true : false}
                        onChange={(e) => setOffer1check(e.target.checked)}
                      />
                    </div>
                  </span>
                  <h6 className="offer-form-title">Offer Name</h6>
                  <p className="offer-form-label">
                    Enter the offer name you want to display in booking engine
                    search
                  </p>
                  <input
                    type="text"
                    className="form-control offer_name"
                    name="offer_name"
                    placeholder="Enter Offer Name Here"
                    value={offername}
                    onChange={(e) => setOffername(e.target.value)}
                  />
                  <label style={{ color: "red" }}>
                    {offer1error.offername}
                  </label>
                </div>

                <div className="offer-content-section">
                  <h6 className="offer-form-title">Offer Conditions</h6>

                  {/* Stay dates start */}
                  <div className="offer-form-status-section offer-grid">
                    <div className="offer-condition-check">
                      {offer1Check === true ? (
                        <input
                          className="form-check-input form-stay-check"
                          type="checkbox"
                          onChange={(e) => setActivestaydate(!activestaydate)}
                        />
                      ) : (
                        <input
                          className="form-check-input form-stay-check"
                          type="checkbox"
                          onChange={(e) => setActivestaydate(!activestaydate)}
                          disabled
                        />
                      )}
                      <label className="form-check-label">Stay Dates:</label>
                    </div>
                    <div
                      className={
                        activestaydate === true
                          ? "offer-condition-calendar form-calender-add form-stay-check-show  label-calender-media"
                          : "offer-condition-calendar form-calender-add form-stay-check-show d-none"
                      }
                      style={{ width: "310px", height: "315px" }}
                    >
                      <Flatpickr
                        ref={fpstay}
                        className="offer_flat"
                        value={selectedDatesstay}
                        onChange={(date) => setSelectedDatesstay(date)}
                        options={{
                          mode: "multiple",
                          inline: true,
                        }}
                      />
                    </div>

                    <div
                      className={
                        activestaydate === true
                          ? "offer-condition-calendar-pic  calender-pic-new form-stay-check-show"
                          : "offer-condition-calendar-pic form-stay-check-show d-none"
                      }
                    >
                      <div className="offer-condition-head-sec">
                        <span className="info-tag">
                          i
                          <span className="tooltiptext">
                            If you want to make the offer that available for
                            users only for particular dates of stay? Enable and
                            choose the stay dates
                          </span>
                        </span>
                        <p
                          className="offer-condition-calendar-choose stay_choose_status"
                          onClick={(e) =>
                            setActivestaydateopen(!activestaydateopen)
                          }
                        >
                          Open/Close period
                        </p>
                      </div>

                      <div className="clearfix"></div>
                      <div
                        className={
                          activestaydateopen === true
                            ? "offer-condition-apply stay_choose_open apply-margin"
                            : "offer-condition-apply stay_choose_open d-none"
                        }
                      >
                        <span className="offer-condition-close">
                          <Link to="" onClick={handleOpenclosestay}>
                            X
                          </Link>
                        </span>
                        <div className="offer-condition-apply-inner-section">
                          <select
                            className="form-select"
                            value={openclosestay}
                            onChange={(e) => setOpenclosestay(e.target.value)}
                          >
                            <option value="open">Open</option>
                            <option value="close">Close</option>
                          </select>
                          <div className="offer-condition-apply-calender">
                            <div className="offer-condition-check date_cal">
                              <form action="#">
                                <div className="input-group">
                                  <Flatpickr
                                    id="flatpickr-from"
                                    className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                    placeholder="FROM"
                                    options={{
                                      mode: "single",
                                      dateFormat: "d M, Y",
                                    }}
                                    value={datefromstay}
                                    onChange={handleCalendarChangeFrom}
                                  />

                                  <span
                                    className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                    onClick={() =>
                                      handleCalendarIconClick("from")
                                    }
                                  ></span>
                                </div>
                              </form>
                            </div>
                            {/* <span className="form_calendar-style fon mdi mdi-calendar-month-outline"></span> */}
                            <div className="offer-condition-check date_cal">
                              <form action="#">
                                <div className="input-group">
                                  <Flatpickr
                                    id="flatpickr-to"
                                    className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                    placeholder="UNTIL"
                                    options={{
                                      mode: "single",
                                      dateFormat: "d M, Y",
                                    }}
                                    value={datetostay}
                                    onChange={handleCalendarChangeTo}
                                  />
                                  <span
                                    className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                    onClick={() =>
                                      handleCalendarIconClick("to")
                                    }
                                  ></span>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="offer-condition-week">
                            <ul>
                              <li>
                                <p>Mon</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysstay.includes(1) &&
                                    !clickedDaysstay.includes(1)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaystay(1)}
                                />
                              </li>
                              <li>
                                <p>Tue</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysstay.includes(2) &&
                                    !clickedDaysstay.includes(2)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaystay(2)}
                                />
                              </li>
                              <li>
                                <p>Wed</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysstay.includes(3) &&
                                    !clickedDaysstay.includes(3)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaystay(3)}
                                />
                              </li>
                              <li>
                                <p>Thu</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysstay.includes(4) &&
                                    !clickedDaysstay.includes(4)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaystay(4)}
                                />
                              </li>
                              <li>
                                <p>Fri</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysstay.includes(5) &&
                                    !clickedDaysstay.includes(5)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaystay(5)}
                                />
                              </li>
                              <li>
                                <p>Sat</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysstay.includes(6) &&
                                    !clickedDaysstay.includes(6)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaystay(6)}
                                />
                              </li>
                              <li>
                                <p>Sun</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysstay.includes(0) &&
                                    !clickedDaysstay.includes(0)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaystay(0)}
                                />
                              </li>
                            </ul>
                          </div>
                          <div className="condition-apply-calendar-btn-sec">
                            <Link
                              className="condition-apply-calendar-btn"
                              onClick={handleApplyToCalendarstay}
                            >
                              Apply to calendar
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Stay dates end */}

                  <div className="offer-form-status-section offer-grid">
                    {/* <p className="offer-form-label">If you want to make the offer that available for users only for particular dates of stay? Enable and choose the stay dates</p>  */}
                    <div className="offer-condition-check">
                      {offer1Check === true ? (
                        <input
                          className="form-check-input form-checkin-check"
                          type="checkbox"
                          value={activecheckindate}
                          checked={activecheckindate === true ? true : false}
                          onChange={(e) =>
                            setActivecheckindate(!activecheckindate)
                          }
                        />
                      ) : (
                        <input
                          className="form-check-input form-checkin-check"
                          type="checkbox"
                          value={activecheckindate}
                          checked={activecheckindate === true ? true : false}
                          onChange={(e) =>
                            setActivecheckindate(!activecheckindate)
                          }
                          disabled
                        />
                      )}
                      <label className="form-check-label">
                        Check-In Dates:
                      </label>
                    </div>
                    <div
                      className={
                        activecheckindate === true
                          ? "offer-condition-calendar form-calender-add form-checkin-check-show  label-calender-media"
                          : "offer-condition-calendar form-calender-add form-checkin-check-show d-none"
                      }
                      style={{ width: "310px", height: "315px" }}
                    >
                      <Flatpickr
                        ref={fpcheckin}
                        className="offer_flat"
                        value={selectedDatescheckin}
                        onChange={(date) => setSelectedDatescheckin(date)}
                        options={{
                          mode: "multiple",
                          inline: true,
                        }}
                      />
                    </div>
                    <div
                      className={
                        activecheckindate === true
                          ? "offer-condition-calendar-pic form-checkin-check-show"
                          : "offer-condition-calendar-pic form-checkin-check-show d-none"
                      }
                    >
                      <div className="offer-condition-head-sec">
                        <span className="info-tag">
                          i
                          <span className="tooltiptext">
                            Would you like to make the offer available for users
                            only if it coincides with particular check-in dates?
                            Enable and choose the check-in dates.
                          </span>
                        </span>
                        <p
                          className="offer-condition-calendar-choose checkin_choose_status"
                          onClick={(e) =>
                            setActivecheckindateopen(!activecheckindateopen)
                          }
                        >
                          Open/Close period
                        </p>
                      </div>
                      <div className="clearfix"></div>
                      <div
                        className={
                          activecheckindateopen === true
                            ? "offer-condition-apply checkin_choose_open apply-margin"
                            : "offer-condition-apply checkin_choose_open d-none"
                        }
                      >
                        <span className="offer-condition-close">
                          <Link to="" onClick={handleOpenclosecheckin}>
                            X
                          </Link>
                        </span>
                        <div className="offer-condition-apply-inner-section">
                          <select
                            className="form-select"
                            value={openclosecheckin}
                            onChange={(e) =>
                              setOpenclosecheckin(e.target.value)
                            }
                          >
                            <option value="open">Open</option>
                            <option value="close">Close</option>
                          </select>
                          <div className="offer-condition-apply-calender">
                            <div className="offer-condition-check date_cal">
                              <form action="#">
                                <div className="input-group">
                                  <Flatpickr
                                    id="flatpickr-to1"
                                    className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                    placeholder="FROM"
                                    options={{
                                      mode: "single",
                                      dateFormat: "d M, Y",
                                    }}
                                    value={datefromcheckin}
                                    onChange={handleCalendarChangeFromcheckin}
                                  />
                                  <span
                                    className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                    onClick={() =>
                                      handleCalendarIconClick("to1")
                                    }
                                  ></span>
                                </div>
                              </form>
                            </div>
                            <div className="offer-condition-check date_cal">
                              <form action="#">
                                <div className="input-group">
                                  <Flatpickr
                                    id="flatpickr-to2"
                                    className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                    placeholder="UNTIL"
                                    options={{
                                      mode: "single",
                                      dateFormat: "d M, Y",
                                    }}
                                    value={datetocheckin}
                                    onChange={handleCalendarChangeTocheckin}
                                  />
                                  <span
                                    className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                    onClick={() =>
                                      handleCalendarIconClick("to2")
                                    }
                                  ></span>
                                </div>
                              </form>
                              {/* <span className="form_calendar-style fon mdi mdi-calendar-month-outline"></span> */}
                            </div>
                          </div>
                          <div className="offer-condition-week">
                            <ul>
                              <li>
                                <p>Mon</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdayscheckin.includes(1) &&
                                    !clickedDayscheckin.includes(1)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaycheckin(1)}
                                />
                              </li>
                              <li>
                                <p>Tue</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdayscheckin.includes(2) &&
                                    !clickedDayscheckin.includes(2)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaycheckin(2)}
                                />
                              </li>
                              <li>
                                <p>Wed</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdayscheckin.includes(3) &&
                                    !clickedDayscheckin.includes(3)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaycheckin(3)}
                                />
                              </li>
                              <li>
                                <p>Thu</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdayscheckin.includes(4) &&
                                    !clickedDayscheckin.includes(4)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaycheckin(4)}
                                />
                              </li>
                              <li>
                                <p>Fri</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdayscheckin.includes(5) &&
                                    !clickedDayscheckin.includes(5)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaycheckin(5)}
                                />
                              </li>
                              <li>
                                <p>Sat</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdayscheckin.includes(6) &&
                                    !clickedDayscheckin.includes(6)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaycheckin(6)}
                                />
                              </li>
                              <li>
                                <p>Sun</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdayscheckin.includes(0) &&
                                    !clickedDayscheckin.includes(0)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaycheckin(0)}
                                />
                              </li>
                            </ul>
                          </div>
                          <div className="condition-apply-calendar-btn-sec">
                            <Link
                              className="condition-apply-calendar-btn"
                              onClick={handleApplyToCalendarcheckin}
                            >
                              Apply to calendar
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="offer-form-status-section offer-grid">
                    {/* <p className="offer-form-label">If you want to make the offer that available for users only for particular dates of stay? Enable and choose the stay dates</p> */}
                    <div className="offer-condition-check">
                      {offer1Check === true ? (
                        <input
                          className="form-check-input form-checkout-check"
                          type="checkbox"
                          value={activecheckoutdate}
                          checked={activecheckoutdate === true ? true : false}
                          onChange={(e) =>
                            setActivecheckoutdate(!activecheckoutdate)
                          }
                        />
                      ) : (
                        <input
                          className="form-check-input form-checkout-check"
                          type="checkbox"
                          value={activecheckoutdate}
                          checked={activecheckoutdate === true ? true : false}
                          onChange={(e) =>
                            setActivecheckoutdate(!activecheckoutdate)
                          }
                          disabled
                        />
                      )}
                      <label className="form-check-label">
                        Check-Out Dates:
                      </label>
                    </div>
                    <div
                      className={
                        activecheckoutdate === true
                          ? "offer-condition-calendar form-calender-add form-checkout-check-show  label-calender-media"
                          : "offer-condition-calendar form-calender-add form-checkout-check-show d-none"
                      }
                      style={{ width: "310px", height: "315px" }}
                    >
                      <Flatpickr
                        ref={fpcheckout}
                        className="offer_flat"
                        value={selectedDatescheckout}
                        onChange={(date) => setSelectedDatescheckout(date)}
                        options={{
                          mode: "multiple",
                          inline: true,
                        }}
                      />
                    </div>
                    <div
                      className={
                        activecheckoutdate === true
                          ? "offer-condition-calendar-pic form-checkout-check-show "
                          : "offer-condition-calendar-pic form-checkout-check-show d-none"
                      }
                    >
                      <div className="offer-condition-head-sec">
                        <span className="info-tag">
                          i
                          <span className="tooltiptext">
                            Would you like to make the offer available for users
                            only if it coincides with particular check-out
                            dates? Enable and choose the check-out dates.
                          </span>
                        </span>
                        <p
                          className="offer-condition-calendar-choose checkout_choose_status"
                          onClick={(e) =>
                            setActivecheckoutdateopen(!activecheckoutdateopen)
                          }
                        >
                          Open/Close period
                        </p>
                      </div>
                      <div className="clearfix"></div>
                      <div
                        className={
                          activecheckoutdateopen === true
                            ? "offer-condition-apply checkout_choose_open apply-margin"
                            : "offer-condition-apply checkout_choose_open d-none"
                        }
                      >
                        <span className="offer-condition-close">
                          <Link to="" onClick={handleOpenclosecheckout}>
                            X
                          </Link>
                        </span>
                        <div className="offer-condition-apply-inner-section">
                          <select
                            className="form-select"
                            value={openclosecheckout}
                            onChange={(e) =>
                              setOpenclosecheckout(e.target.value)
                            }
                          >
                            <option value="open">Open</option>
                            <option value="close">Close</option>
                          </select>
                          <div className="offer-condition-apply-calender">
                            <div className="offer-condition-check date_cal">
                              <form action="#">
                                <div className="input-group">
                                  <Flatpickr
                                    id="flatpickr-to3"
                                    className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                    placeholder="FROM"
                                    options={{
                                      mode: "single",
                                      dateFormat: "d M, Y",
                                    }}
                                    value={datefromcheckout}
                                    onChange={handleCalendarChangeFromcheckout}
                                  />
                                  <span
                                    className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                    onClick={() =>
                                      handleCalendarIconClick("to3")
                                    }
                                  ></span>
                                </div>
                              </form>
                            </div>
                            <div className="offer-condition-check date_cal">
                              <form action="#">
                                <div className="input-group">
                                  <Flatpickr
                                    id="flatpickr-to4"
                                    className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                    placeholder="UNTIL"
                                    options={{
                                      mode: "single",
                                      dateFormat: "d M, Y",
                                    }}
                                    value={datetocheckout}
                                    onChange={handleCalendarChangeTocheckout}
                                  />
                                  <span
                                    className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                    onClick={() =>
                                      handleCalendarIconClick("to4")
                                    }
                                  ></span>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="offer-condition-week">
                            <ul>
                              <li>
                                <p>Mon</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdayscheckout.includes(1) &&
                                    !clickedDayscheckout.includes(1)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaycheckout(1)}
                                />
                              </li>
                              <li>
                                <p>Tue</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdayscheckout.includes(2) &&
                                    !clickedDayscheckout.includes(2)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaycheckout(2)}
                                />
                              </li>
                              <li>
                                <p>Wed</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdayscheckout.includes(3) &&
                                    !clickedDayscheckout.includes(3)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaycheckout(3)}
                                />
                              </li>
                              <li>
                                <p>Thu</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdayscheckout.includes(4) &&
                                    !clickedDayscheckout.includes(4)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaycheckout(4)}
                                />
                              </li>
                              <li>
                                <p>Fri</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdayscheckout.includes(5) &&
                                    !clickedDayscheckout.includes(5)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaycheckout(5)}
                                />
                              </li>
                              <li>
                                <p>Sat</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdayscheckout.includes(6) &&
                                    !clickedDayscheckout.includes(6)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaycheckout(6)}
                                />
                              </li>
                              <li>
                                <p>Sun</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdayscheckout.includes(0) &&
                                    !clickedDayscheckout.includes(0)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDaycheckout(0)}
                                />
                              </li>
                            </ul>
                          </div>
                          <div className="condition-apply-calendar-btn-sec">
                            <Link
                              className="condition-apply-calendar-btn"
                              to=""
                              onClick={handleApplyToCalendarcheckout}
                            >
                              Apply to calendar
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="offer-form-status-section offer-grid">
                    {/* <p className="offer-form-label">If you want to make the offer that available for users only for particular dates of stay? Enable and choose the stay dates</p> */}
                    <div className="offer-condition-check">
                      {offer1Check === true ? (
                        <input
                          className="form-check-input form-reservation-check"
                          type="checkbox"
                          value={activereservedate}
                          checked={activereservedate === true ? true : false}
                          onChange={(e) =>
                            setActivereservedate(!activereservedate)
                          }
                        />
                      ) : (
                        <input
                          className="form-check-input form-reservation-check"
                          type="checkbox"
                          value={activereservedate}
                          checked={activereservedate === true ? true : false}
                          onChange={(e) =>
                            setActivereservedate(!activereservedate)
                          }
                          disabled
                        />
                      )}
                      <label className="form-check-label">
                        Reservation Dates:
                      </label>
                    </div>
                    <div
                      className={
                        activereservedate === true
                          ? "offer-condition-calendar form-calender-add form-reservation-check-show  label-calender-media"
                          : "offer-condition-calendar form-calender-add form-reservation-check-show d-none"
                      }
                      style={{ width: "310px", height: "315px" }}
                    >
                      <Flatpickr
                        ref={fpreserve}
                        className="offer_flat"
                        value={selectedDatesreserve}
                        onChange={(date) => setSelectedDatesreserve(date)}
                        options={{
                          mode: "multiple",
                          inline: true,
                        }}
                      />
                    </div>
                    <div
                      className={
                        activereservedate === true
                          ? "offer-condition-calendar-pic form-reservation-check-show"
                          : "offer-condition-calendar-pic form-reservation-check-show d-none"
                      }
                    >
                      <div className="offer-condition-head-sec">
                        <span className="info-tag">
                          i
                          <span className="tooltiptext">
                            Would you like to make the offer available for users
                            only if it coincides with particular reservation
                            dates? Enable and choose the reservation dates.
                          </span>
                        </span>
                        <p
                          className="offer-condition-calendar-choose reservation_choose_status"
                          onClick={(e) =>
                            setActivereservedateopen(!activereservedateopen)
                          }
                        >
                          Open/Close period
                        </p>
                      </div>
                      <div className="clearfix"></div>
                      <div
                        className={
                          activereservedateopen === true
                            ? "offer-condition-apply reservation_choose_open apply-margin"
                            : "offer-condition-apply reservation_choose_open d-none"
                        }
                      >
                        <span className="offer-condition-close">
                          <Link to="" onClick={handleOpenclosereserve}>
                            X
                          </Link>
                        </span>
                        <div className="offer-condition-apply-inner-section">
                          <select
                            className="form-select"
                            value={openclosereserve}
                            onChange={(e) =>
                              setOpenclosereserve(e.target.value)
                            }
                          >
                            <option value="open">Open</option>
                            <option value="close">Close</option>
                          </select>
                          <div className="offer-condition-apply-calender">
                            <div className="offer-condition-check date_cal">
                              <form action="#">
                                <div className="input-group">
                                  <Flatpickr
                                    id="flatpickr-to5"
                                    className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                    placeholder="FROM"
                                    options={{
                                      mode: "single",
                                      dateFormat: "d M, Y",
                                    }}
                                    value={datefromreserve}
                                    onChange={handleCalendarChangeFromreserve}
                                  />
                                  <span
                                    className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                    onClick={() =>
                                      handleCalendarIconClick("to5")
                                    }
                                  ></span>
                                </div>
                              </form>
                            </div>
                            <div className="offer-condition-check date_cal">
                              <form action="#">
                                <div className="input-group">
                                  <Flatpickr
                                    id="flatpickr-to6"
                                    className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                    placeholder="UNTIL"
                                    options={{
                                      mode: "single",
                                      dateFormat: "d M, Y",
                                    }}
                                    value={datetoreserve}
                                    onChange={handleCalendarChangeToreserve}
                                  />
                                  <span
                                    className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                    onClick={() =>
                                      handleCalendarIconClick("to6")
                                    }
                                  ></span>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="offer-condition-week">
                            <ul>
                              <li>
                                <p>Mon</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysreserve.includes(1) &&
                                    !clickedDaysreserve.includes(1)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDayreserve(1)}
                                />
                              </li>
                              <li>
                                <p>Tue</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysreserve.includes(2) &&
                                    !clickedDaysreserve.includes(2)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDayreserve(2)}
                                />
                              </li>
                              <li>
                                <p>Wed</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysreserve.includes(3) &&
                                    !clickedDaysreserve.includes(3)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDayreserve(3)}
                                />
                              </li>
                              <li>
                                <p>Thu</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysreserve.includes(4) &&
                                    !clickedDaysreserve.includes(4)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDayreserve(4)}
                                />
                              </li>
                              <li>
                                <p>Fri</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysreserve.includes(5) &&
                                    !clickedDaysreserve.includes(5)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDayreserve(5)}
                                />
                              </li>
                              <li>
                                <p>Sat</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysreserve.includes(6) &&
                                    !clickedDaysreserve.includes(6)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDayreserve(6)}
                                />
                              </li>
                              <li>
                                <p>Sun</p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox6"
                                  value="option1"
                                  checked={
                                    selectedWeekdaysreserve.includes(0) &&
                                    !clickedDaysreserve.includes(0)
                                      ? true
                                      : false
                                  }
                                  onChange={() => toggleSelectedDayreserve(0)}
                                />
                              </li>
                            </ul>
                          </div>
                          <div className="condition-apply-calendar-btn-sec">
                            <Link
                              className="condition-apply-calendar-btn"
                              to=""
                              onClick={handleApplyToCalendareserve}
                            >
                              Apply to calendar
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="offer-content-section">
                  <div class="col-lg-4" style={{ marginTop: "20px" }}>
                    <div className="input-group">
                      <label
                        className="input-group-text input-joint-color box-wid"
                        id="inputGroup-sizing-default"
                        style={{ width: "130px" }}
                      >
                        {" "}
                        Choose Language:
                      </label>
                      <div className="col-md-7 choose-lang-step">
                        <MultiSelect
                          options={language}
                          value={selectedMulti}
                          onChange={setselectedMulti}
                          labelledBy="Select"
                          selectionType="counter"
                          allowCustomValue={true}
                          aria-expanded="true"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="appendmore"
                    style={{ marginTop: "20px" }}
                  ></div>
                  {selectedMulti.length > 0
                    ? selectedMulti.map((element, index) => {
                        return (
                          <div
                            key={index}
                            className="col-md-12 translation-fill-section"
                            id="lang_ +val"
                            data-value="1"
                          >
                            <div className="translation-selected-lang">
                              <p>
                                <span className="translation-selected-lang-head">
                                  {element.code}
                                </span>
                              </p>
                            </div>

                            <div class="col-lg-4">
                              <div className="input-group">
                                <label
                                  className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default"
                                >
                                  Offer Name:
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={selectedMulti[index].name}
                                  onChange={(e) =>
                                    (selectedMulti[index].name = e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </>
            )}

            <div className="tab_nav_section">
              <div className="tab_nav_section_btns">
                <Link className="btn btn-be-form-cancel btn-be-back">
                  Cancel
                </Link>
                <Link
                  className="btn btn-success btn-be-common"
                  to="#"
                  onClick={nextoffterstep1}
                >
                  Next
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Offerstep1sugg;
