import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Commonheader from '../../../Common/Commonheader'
import Footer from '../../../Common/Footer'
import Openclose from './Openclose';

function Condition() {
    return (
      <div>
          
          <Openclose/>
    
    {/* <Commonheader/>
    <div className="main-content">
    <div className="condition-top-menu">
        <div className="row">
            <div className="condition-tabs-section">
                <ul className="nav nav-tabs condtion-tabs-select" role="tablist">
                    <li className="nav-item">
                        <Link className={tab==="open"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setTab("open")}>
                            Open/Close
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={tab==="minimum"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setTab("minimum")} >
                            Minimum Stay
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={tab==="maximum"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setTab("maximum")} >
                            Maximum Stay
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={tab==="cancel"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setTab("cancel")} >
                            Cancellation & Payment
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={tab==="window"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setTab("window")}>
                            Booking Window
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    {tab==="open"?<Openclose/>:""}
    </div>
<Footer/> */}
    </div>
  )
}

export default Condition
