import React from 'react'
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import Select from "react-select";


function Timezoneedit({
    pagedata,
    modal_backdrop,
    tog_backdrop,
    pageid,
    UpdateData,
    handleInputedit,
    country,
    countrydata,
    setCountrydata
}) {
  return (
    <Modal
    isOpen={modal_backdrop}
    toggle={() => {
      tog_backdrop();
    }}
    backdrop={"static"}
    id="staticBackdrop"
    centered
  >
    <ModalHeader
      className="modal-title modal-header bg-light p-3"
      id="staticBackdropLabel"
      toggle={() => {
        tog_backdrop();
      }}
    ></ModalHeader>
    <ModalBody className="">
      <div className="mt-4">
        <label for="orderId" className="form-label">
          Time Zone
        </label>
        <input
          type="text"
          className="form-control"
          value={pagedata.time_zone}
          onChange={handleInputedit}
          name="time_zone"
        />
       <label for="orderId" className="form-label">
            Countries
            </label>
            <Select
                    className="form-control"
                    data-choices
                    name="choices-single-default"
                    id="choices-single-default"
                    options={country}    
                    value={countrydata}                       
                    onChange={(e)=>setCountrydata(e)}
                ></Select>
      
        <label for="orderId" className="form-label mt-2">
          Status
        </label>
        <select
          className="form-control "
          name="status"
          value={pagedata.status}
          onChange={handleInputedit}
        >
         
          <option name="status"
            {...(pagedata.status === true
            ? "selected"
            : "")}
            value={true}>
            Active
          </option>
        <option name="status"  
            {...(pagedata.status === false
            ? "selected"
            : "")}
            value={false}>
            InActive
          </option>
        </select>

        <div className="modal-footer p-3">
          <div className="hstack gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
              onClick={() => {
                tog_backdrop();
              }}
            >
              Close{" "}
            </button>
            {/* <!-- <button type="submit" className="btn btn-success" id="add-btn">Save</button> --> */}
            <button
              type="button"
              className="btn db-save-single-button"
              id="edit-btn"
              onClick={() => UpdateData(pageid)}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </ModalBody>
  </Modal>
  )
}

export default Timezoneedit