import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Alerts from '../Alerts';
import Config from '../Config';

function ResetPassword() {
    let navigate = useNavigate();

    const [alert, setAlert] = useState(false);
    const [formerror, setError] = useState({});
    const [alertmessage, setAlertmessage] = useState("");
    const [alerttype, setAlerttype] = useState("");
    const [password, setPassword] = useState("");
    const [cpassword, setCPassword] = useState("");


    const Login = async (e) => {
        e.preventDefault();
       let isFormValid=true;
       let error={};
       if(!password){
         isFormValid=false;
         error.pass="Password is required";
       }
       if(!cpassword){
        isFormValid=false;
        error.cpass="Confirm password is required";
      }
   
        setError(error);
    
    
        if (isFormValid===true) {
          const res = await fetch(`${Config.apiurl}password/reset`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              password: password,
              password_confirmation: cpassword,
            }),
          });
          const data1 = await res.json();
          if (res.status === 200) {
            if(data1.status=="200"){
    
            setAlert(true);
            
            setAlertmessage(data1.message);
            setAlerttype("success");
    
            setTimeout(() => navigate("/dashboard"), 3000);
            }
            else{
                setAlert(true);
            
                setAlertmessage(data1.message);
                setAlerttype("error");
            }
            
          } else {
            setAlert(true);
            
            setAlertmessage(data1.message);
    
          
            setAlerttype("error");
          }
        }
      };
  
  return (
    <div className="wrapper">
    <div className="row">
      <div className="col-md-12">
        <div className="login-main">
        

          <div className="login-box-style">
            <div className="login-box">
              <div className="login-inner-box">
                {alert ? (
                  <Alerts type={alerttype} message={alertmessage} />
                ) : (
                  ""
                )}
                
                <div className="login-content">
                  <h2>Reset Password</h2>
                </div>
                <div className="login-form-field">
                  <div className="login-form">
                  
                  <input
                      type="password"
                      className="login-text-field"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                
                  <label style={{ color: "red" }}>{formerror.pass}</label>
                 
                  <div className="login-form" >
                    
                    <input
                      type="password"
                      className="login-text-field"
                      placeholder="Confirm Password"
                      value={cpassword}
                      onChange={(e) => setCPassword(e.target.value)}
                    />
                 
                  </div>
                  <label style={{ color: "red" }}>{formerror.cpass}</label>

                
                  
                    <Link to="#" onClick={Login} style={{ color: "white" }}>
                    <div className="login-submit">
                        Reset
                    
                    </div></Link>
                  
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ResetPassword
