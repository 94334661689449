import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { MultiSelect } from "react-multi-select-component";

function Offerstep3({
  setTab,
  selectedroomocc,
  handleweekinput,
  currency,
  offer3fields,
  handleOptionChangeoffer3price,
  handleoffer3checkbox,
  handleoffer3change,
  exceptionstaydate,
  setExceptionstaydate,
  inputFieldsexception,
  addInputFieldoffer3,
  removeInputFieldsoffer3,
  handleChangedatefrom,
  handleChangetodate,
  handleChangediscount,
  exceptionnight,
  setExceptionnight,
  inputFieldsextra,
  addInputFieldextra,
  removeInputFieldsextra,
  handleChangedatefromextra,
  handleChangetodateextra,
  handleChangediscountsextra,
  countryOffer3,
  visibleItemsoffer3,
  handleloyalchangeoffer3country,
  showAlloffer3country,
  setShowAlloffer3country,
  handleInputChangeconutryvalue,
  // nextoffterstep3,
  nextoffterstep3rate,
  nextoffterstep3increment,
  nextoffterstep3freenight,
  nextoffterstep3extra,
  nextoffterstep3country,
  nextoffterstep3device,
  offer3error,
  handleoffer3weekcheck,
  tabroomweek,
  tabroom,
  handleTabweek,
  handleTab,
  handleClickroomweek,
  handleClickroom,
  handleweekinputextend,
  ratetab,
  setRatetab,
  offer3validrate,
  offer3validncrement,
  offer3validfreenight,
  offer3validextra,
  offer3validcountry,
  offer3validdivice,
  multicountri3,
  setMulticountry3,
  otherboard,
  selectedotherboard,
  setSelectedotherboard,
  handleotherboard,
  setBoard_apply_status,
  board_apply_status
}) {
  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [symbolsrate] = useState(["e", "E", "+", "-"]);

  const handleCalendarIconClick = (calendarId) => {
    const calendar = document.querySelector(`#flatpickr-${calendarId}`);
    if (calendar) {
      const isCalendarOpen = calendar._flatpickr.isOpen;
      const icon = document.querySelector(`#calendar-icon-${calendarId}`);
      if (icon) {
        icon.style.display = isCalendarOpen ? "none" : "inline-block";
      }
      calendar._flatpickr[isCalendarOpen ? "close" : "open"]();
    }
  };

  useEffect(() => {
    const numberInput = document.getElementById("myNumberField");

    if (numberInput) {
      const preventDefaultBehavior = (event) => {
        event.preventDefault();
      };

      numberInput.addEventListener("wheel", preventDefaultBehavior);
      numberInput.addEventListener("touchstart", (event) => {
        const initialTouchY = event.touches[0].clientY;

        numberInput.addEventListener("touchmove", (event) => {
          const currentY = event.touches[0].clientY;
          if (currentY !== initialTouchY) {
            event.preventDefault();
          }
        });
      });

      return () => {
        numberInput.removeEventListener("wheel", preventDefaultBehavior);
        numberInput.removeEventListener("touchstart", preventDefaultBehavior);
        numberInput.removeEventListener("touchmove", preventDefaultBehavior);
      };
    }
  }, []);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="offer-create-section offer-create-wid">
          <div className="offer-create-details">
            <div className="col-md-12">
              <h6 className="offer-step-desc">
                This enables you to provide targeted promotions, incentivize
                certain customer groups, and optimize revenue generation based
                on different pricing strategies tailored to each category.
              </h6>

              <div className="row">
                <div className="col-md-12">
                  <ul className="offer-create-list_rate">
                    <li
                      className={
                        ratetab === "rate"
                          ? "offer-step-list step-btn-offer active"
                          : "offer-step-list step-btn-offer"
                      }
                      onClick={(e) => setRatetab("rate")}
                    >
                      Rate
                    </li>

                    <li
                      className={
                        ratetab === "increment"
                          ? "offer-step-list step-btn-offer active"
                          : "offer-step-list step-btn-offer"
                      }
                      onClick={(e) => setRatetab("increment")}
                    >
                      Increment
                    </li>
                    <li
                      className={
                        ratetab === "free_nights"
                          ? "offer-step-list step-btn-offer active"
                          : "offer-step-list step-btn-offer"
                      }
                      onClick={(e) => setRatetab("free_nights")}
                    >
                      Free Nights
                    </li>
                    <li
                      className={
                        ratetab === "extra_discount"
                          ? "offer-step-list step-btn-offer  active"
                          : "offer-step-list step-btn-offer"
                      }
                      onClick={(e) => setRatetab("extra_discount")}
                    >
                      Extra Discount
                    </li>
                    <li
                      className={
                        ratetab === "country"
                          ? "offer-step-list step-btn-offer active"
                          : "offer-step-list step-btn-offer"
                      }
                      onClick={(e) => setRatetab("country")}
                    >
                      Country Discount
                    </li>
                    <li
                      className={
                        ratetab === "device_discount"
                          ? "offer-step-list step-btn-offer active"
                          : "offer-step-list step-btn-offer"
                      }
                      onClick={(e) => setRatetab("device_discount")}
                    >
                      Device Discount
                    </li>
                  </ul>
                </div>
              </div>
              {ratetab === "rate" ? (
                <>
                  <div className="offer-content-section">
                    <div className="offer-form-status-section">
                      <ul className="offer-create--rate-list">
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input same_rate_show"
                              type="radio"
                              id="WithoutinlineRadio1"
                              value="same"
                              name="price_type"
                              checked={offer3fields.price_type === "same"}
                              onChange={() =>
                                handleOptionChangeoffer3price(
                                  "same",
                                  "price_type"
                                )
                              }
                            />
                            <label>Same price as Regular Rate</label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input fixed_discount_show"
                              type="radio"
                              id="WithoutinlineRadio2"
                              value="fixed"
                              name="price_type"
                              checked={offer3fields.price_type === "fixed"}
                              onChange={() =>
                                handleOptionChangeoffer3price(
                                  "fixed",
                                  "price_type"
                                )
                              }
                            />
                            <label>
                              Fixed{" "}
                              <span className="offer-label-span">
                                ( It also applies to meal plan supplements, if
                                they exist )
                              </span>
                            </label>
                          </div>
                          <div
                            className={
                              offer3fields.price_type === "fixed"
                                ? "offer-create-fixed-section"
                                : "offer-create-fixed-section d-none"
                            }
                          >
                            <div className="offer-create-fixed-section_board_status">
                              <p>
                                Applicable to board rates?
                                <span className="info-tag info_tag_offer_1">
                                  i
                                  <span className="tooltiptext">
                                    {" "}
                                    If this offer is only applicable to room
                                    rate, set its status as NO otherwise choose
                                    YES
                                  </span>
                                </span>
                              </p>
                              <div className="fixed_discount_show_status">
                              <input
                              className="form-check-input fixed_discount_show"
                              type="radio"
                              id="WithoutinlineRadio2"
                              value="yes"
                              name="board_apply_status"
                              checked={board_apply_status === "yes"}
                              onChange={(e) =>
                                setBoard_apply_status(e.target.value)
                              }
                            />
                             <label>
                              Yes{" "}
                            
                            </label>
                            </div>
                            <div className="fixed_discount_show_status">
                            <input
                              className="form-check-input fixed_discount_show"
                              type="radio"
                              id="WithoutinlineRadio2"
                              value="no"
                              name="board_apply_status"
                              checked={board_apply_status === "no"}
                              onChange={(e) =>
                                setBoard_apply_status(e.target.value)
                              }
                            />
                             <label>
                              No{" "}
                            
                            </label>
                            </div>
                            </div>

                            <input
                              className="form-check-input isBasicSelected"
                              type="checkbox"
                              id="formCheck1"
                              onChange={(e) =>
                                handleoffer3weekcheck(
                                  e.target.checked,
                                  "fixed_week_price_status"
                                )
                              }
                              value={offer3fields.fixed_week_price_status}
                              checked={
                                offer3fields.fixed_week_price_status === true
                                  ? true
                                  : false
                              }
                            />

                            <label
                              className="form-check-label"
                              for="formCheck1"
                              style={{ marginLeft: "8px", marginBottom: "5px" }}
                            >
                              Different price on each day of the week…
                              <span className="offer-label-span">
                                (In {currency.code}, per room and night)
                              </span>
                            </label>
                            <div
                              className={
                                offer3fields.fixed_week_price_status === true
                                  ? "fixed-price-table fixed-price-table-weekly-basis"
                                  : "fixed-price-table fixed-price-table-weekly-basis d-none"
                              }
                            >
                              {selectedroomocc.map((rooms, i) => {
                                return (
                                  <div className="offer_room_names " key={i}>
                                    <span
                                      className="room-default-expand_offer"
                                      id="room1"
                                      onClick={(e) => handleClickroomweek(i)}
                                    >
                                      <span className="room-price-expanded_offer ri-arrow-down-s-line text-dark"></span>
                                      {rooms.label}
                                    </span>
                                    <span className="default_price_show_offer">
                                      <ul
                                        className="price_show_select_offer"
                                        id="price_show_type"
                                      >
                                        <li
                                          className={
                                            rooms.based_on === "per_room"
                                              ? "active"
                                              : "d-none"
                                          }
                                          data-value="Room Wise"
                                        >
                                          Room Wise
                                          <span className="info-tag info_tag_offer">
                                            i
                                            <span className="tooltiptext">
                                              {" "}
                                              Update rates below
                                            </span>
                                          </span>
                                        </li>
                                        <li
                                          className={
                                            rooms.based_on === "per_person"
                                              ? "active"
                                              : "d-none"
                                          }
                                          data-value="Person Wise"
                                        >
                                          Person Wise
                                        </li>
                                      </ul>
                                    </span>
                                    {otherboard[i]?.otherboard?.length > 0 ? (
                                      <div
                                        className="col-md-6 col-lg-4 col-xl-4"
                                        style={{ paddingLeft: "30px" }}
                                      >
                                        <div className="input-group">
                                          <label
                                            className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default"
                                          >
                                            Select Board
                                          </label>
                                          <MultiSelect
                                            options={otherboard[i]?.otherboard}
                                            value={
                                              selectedotherboard[i]
                                                ?.selectedboard
                                                ? selectedotherboard[i]
                                                    ?.selectedboard
                                                : ""
                                            }
                                            onChange={(selectedValues) => {
                                              handleotherboard(
                                                i,
                                                selectedValues
                                              );
                                            }}
                                            labelledBy="Select"
                                            selectionType="counter"
                                            closeOnChangedValue={false}
                                            aria-expanded="true"
                                            hasSelectAll={true}
                                            className="offer_country_multi"
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <table
                                      className={
                                        tabroomweek[i]?.click
                                          ? "table table-bordered t_styles cancellation-table-scroll"
                                          : "table table-bordered"
                                      }
                                    >
                                      {tabroomweek[i]?.click ? (
                                        <>
                                          <tr>
                                            <th
                                              style={{
                                                padding: "5px !important",
                                              }}
                                            >
                                              Rooms
                                            </th>
                                            <th className="offer-th-head">
                                              Mon
                                            </th>
                                            <th className="offer-th-head">
                                              Tue
                                            </th>
                                            <th className="offer-th-head ">
                                              Wed
                                            </th>
                                            <th className="offer-th-head">
                                              Thu
                                            </th>
                                            <th className="offer-th-head">
                                              Fri
                                            </th>
                                            <th className="offer-th-head">
                                              Sat
                                            </th>
                                            <th className="offer-th-head">
                                              Sun
                                            </th>
                                          </tr>
                                          {rooms.based_on === "per_person" ? (
                                            <>
                                              {rooms.occupancies.map(
                                                (occ, ind) => {
                                                  return (
                                                    <>
                                                      {occ.boards.map(
                                                        (board, index) => {
                                                          return (
                                                            <tr key={index}>
                                                              <td className="offer-rate-title">
                                                                {" "}
                                                                {board.label}
                                                                <span
                                                                  className=""
                                                                  style={{
                                                                    marginRight:
                                                                      "13px",
                                                                    float:
                                                                      "right",
                                                                    display:
                                                                      "inline-table",
                                                                  }}
                                                                >
                                                                  {occ.adult +
                                                                    occ.child >
                                                                    4 && (
                                                                    <>
                                                                      {occ.adult >
                                                                        0 && (
                                                                        <>
                                                                          <img
                                                                            src="/images/icon/user.png"
                                                                            alt="Adult"
                                                                            style={{
                                                                              paddingLeft:
                                                                                "1px",
                                                                            }}
                                                                          />
                                                                          (
                                                                          {
                                                                            occ.adult
                                                                          }
                                                                          )
                                                                        </>
                                                                      )}{" "}
                                                                      {"x"}
                                                                      {occ.child >
                                                                        0 && (
                                                                        <>
                                                                          <img
                                                                            src="/images/user-color.png"
                                                                            alt="Child"
                                                                            className="child-icon"
                                                                            style={{
                                                                              marginLeft:
                                                                                "2px",
                                                                            }}
                                                                          />
                                                                          (
                                                                          {
                                                                            occ.child
                                                                          }
                                                                          )
                                                                        </>
                                                                      )}
                                                                    </>
                                                                  )}

                                                                  {occ.adult +
                                                                    occ.child <=
                                                                    4 && (
                                                                    <>
                                                                      {Array.from(
                                                                        {
                                                                          length:
                                                                            occ.adult,
                                                                        },
                                                                        (
                                                                          _,
                                                                          index
                                                                        ) => (
                                                                          <img
                                                                            key={`adult-${index}`}
                                                                            src="/images/icon/user.png"
                                                                            alt="Adult"
                                                                            style={{
                                                                              paddingLeft:
                                                                                "1px",
                                                                            }}
                                                                          />
                                                                        )
                                                                      )}
                                                                      {Array.from(
                                                                        {
                                                                          length:
                                                                            occ.child,
                                                                        },
                                                                        (
                                                                          _,
                                                                          index
                                                                        ) => (
                                                                          <img
                                                                            key={`child-${index}`}
                                                                            src="/images/user-color.png"
                                                                            alt="Child"
                                                                            className="child-icon"
                                                                            style={{
                                                                              marginLeft:
                                                                                "2px",
                                                                            }}
                                                                          />
                                                                        )
                                                                      )}
                                                                    </>
                                                                  )}
                                                                </span>
                                                              </td>
                                                              <td className="offer-rate-field">
                                                                <input
                                                                  type="number"
                                                                  className="form-control new_offer_table"
                                                                  onKeyDown={(
                                                                    e
                                                                  ) =>
                                                                    symbolsrate.includes(
                                                                      e.key
                                                                    ) &&
                                                                    e.preventDefault()
                                                                  }
                                                                  min={0}
                                                                  id="myNumberField"
                                                                  value={
                                                                    board.monday
                                                                  }
                                                                  name="monday"
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleweekinput(
                                                                      i,
                                                                      ind,
                                                                      index,
                                                                      e
                                                                    )
                                                                  }
                                                                  onFocus={(e) =>
                                                                    e.target.addEventListener(
                                                                      "wheel",
                                                                      function (e) {
                                                                        e.preventDefault();
                                                                      },
                                                                      { passive: false }
                                                                    )
                                                                  }
                                                                />
                                                              </td>
                                                              <td className="offer-rate-field">
                                                                <input
                                                                  type="number"
                                                                  className="form-control new_offer_table"
                                                                  onKeyDown={(
                                                                    e
                                                                  ) =>
                                                                    symbolsrate.includes(
                                                                      e.key
                                                                    ) &&
                                                                    e.preventDefault()
                                                                  }
                                                                  min={0}
                                                                  id="myNumberField"
                                                                  value={
                                                                    board.tuesday
                                                                  }
                                                                  name="tuesday"
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleweekinput(
                                                                      i,
                                                                      ind,
                                                                      index,
                                                                      e
                                                                    )
                                                                  }
                                                                  onFocus={(e) =>
                                                                    e.target.addEventListener(
                                                                      "wheel",
                                                                      function (e) {
                                                                        e.preventDefault();
                                                                      },
                                                                      { passive: false }
                                                                    )
                                                                  }
                                                                />
                                                              </td>
                                                              <td className="offer-rate-field">
                                                                <input
                                                                  type="number"
                                                                  className="form-control new_offer_table"
                                                                  onKeyDown={(
                                                                    e
                                                                  ) =>
                                                                    symbolsrate.includes(
                                                                      e.key
                                                                    ) &&
                                                                    e.preventDefault()
                                                                  }
                                                                  min={0}
                                                                  id="myNumberField"
                                                                  value={
                                                                    board.wednesday
                                                                  }
                                                                  name="wednesday"
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleweekinput(
                                                                      i,
                                                                      ind,
                                                                      index,
                                                                      e
                                                                    )
                                                                  }
                                                                  onFocus={(e) =>
                                                                    e.target.addEventListener(
                                                                      "wheel",
                                                                      function (e) {
                                                                        e.preventDefault();
                                                                      },
                                                                      { passive: false }
                                                                    )
                                                                  }
                                                                />
                                                              </td>
                                                              <td className="offer-rate-field">
                                                                <input
                                                                  type="number"
                                                                  className="form-control new_offer_table"
                                                                  onKeyDown={(
                                                                    e
                                                                  ) =>
                                                                    symbolsrate.includes(
                                                                      e.key
                                                                    ) &&
                                                                    e.preventDefault()
                                                                  }
                                                                  min={0}
                                                                  id="myNumberField"
                                                                  value={
                                                                    board.thursday
                                                                  }
                                                                  name="thursday"
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleweekinput(
                                                                      i,
                                                                      ind,
                                                                      index,
                                                                      e
                                                                    )
                                                                  }
                                                                  onFocus={(e) =>
                                                                    e.target.addEventListener(
                                                                      "wheel",
                                                                      function (e) {
                                                                        e.preventDefault();
                                                                      },
                                                                      { passive: false }
                                                                    )
                                                                  }
                                                                />
                                                              </td>
                                                              <td className="offer-rate-field">
                                                                <input
                                                                  type="number"
                                                                  className="form-control new_offer_table"
                                                                  onKeyDown={(
                                                                    e
                                                                  ) =>
                                                                    symbolsrate.includes(
                                                                      e.key
                                                                    ) &&
                                                                    e.preventDefault()
                                                                  }
                                                                  min={0}
                                                                  id="myNumberField"
                                                                  value={
                                                                    board.friday
                                                                  }
                                                                  name="friday"
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleweekinput(
                                                                      i,
                                                                      ind,
                                                                      index,
                                                                      e
                                                                    )
                                                                  }
                                                                  onFocus={(e) =>
                                                                    e.target.addEventListener(
                                                                      "wheel",
                                                                      function (e) {
                                                                        e.preventDefault();
                                                                      },
                                                                      { passive: false }
                                                                    )
                                                                  }
                                                                />
                                                              </td>
                                                              <td className="offer-rate-field">
                                                                <input
                                                                  type="number"
                                                                  className="form-control new_offer_table"
                                                                  onKeyDown={(
                                                                    e
                                                                  ) =>
                                                                    symbolsrate.includes(
                                                                      e.key
                                                                    ) &&
                                                                    e.preventDefault()
                                                                  }
                                                                  min={0}
                                                                  id="myNumberField"
                                                                  value={
                                                                    board.saturday
                                                                  }
                                                                  name="saturday"
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleweekinput(
                                                                      i,
                                                                      ind,
                                                                      index,
                                                                      e
                                                                    )
                                                                  }
                                                                  onFocus={(e) =>
                                                                    e.target.addEventListener(
                                                                      "wheel",
                                                                      function (e) {
                                                                        e.preventDefault();
                                                                      },
                                                                      { passive: false }
                                                                    )
                                                                  }
                                                                />
                                                              </td>
                                                              <td className="offer-rate-field">
                                                                <input
                                                                  type="number"
                                                                  className="form-control new_offer_table"
                                                                  onKeyDown={(
                                                                    e
                                                                  ) =>
                                                                    symbolsrate.includes(
                                                                      e.key
                                                                    ) &&
                                                                    e.preventDefault()
                                                                  }
                                                                  min={0}
                                                                  id="myNumberField"
                                                                  value={
                                                                    board.sunday
                                                                  }
                                                                  name="sunday"
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleweekinput(
                                                                      i,
                                                                      ind,
                                                                      index,
                                                                      e
                                                                    )
                                                                  }
                                                                  onFocus={(e) =>
                                                                    e.target.addEventListener(
                                                                      "wheel",
                                                                      function (e) {
                                                                        e.preventDefault();
                                                                      },
                                                                      { passive: false }
                                                                    )
                                                                  }
                                                                />
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      )}
                                                    </>
                                                  );
                                                }
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {rooms.occupancies[0].boards.map(
                                                (board, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td className="offer-rate-title">
                                                        {" "}
                                                        {board.label}
                                                      </td>
                                                      <td className="offer-rate-field">
                                                        <input
                                                          type="number"
                                                          className="form-control new_offer_table"
                                                          onKeyDown={(e) =>
                                                            symbolsrate.includes(
                                                              e.key
                                                            ) &&
                                                            e.preventDefault()
                                                          }
                                                          min={0}
                                                          id="myNumberField"
                                                          value={board.monday}
                                                          name="monday"
                                                          onChange={(e) =>
                                                            handleweekinputextend(
                                                              i,
                                                              0,
                                                              index,
                                                              e
                                                            )
                                                          }
                                                          onFocus={(e) =>
                                                            e.target.addEventListener(
                                                              "wheel",
                                                              function (e) {
                                                                e.preventDefault();
                                                              },
                                                              { passive: false }
                                                            )
                                                          }
                                                        />
                                                      </td>
                                                      <td className="offer-rate-field">
                                                        <input
                                                          type="number"
                                                          className="form-control new_offer_table"
                                                          onKeyDown={(e) =>
                                                            symbolsrate.includes(
                                                              e.key
                                                            ) &&
                                                            e.preventDefault()
                                                          }
                                                          min={0}
                                                          id="myNumberField"
                                                          value={board.tuesday}
                                                          name="tuesday"
                                                          onChange={(e) =>
                                                            handleweekinputextend(
                                                              i,
                                                              0,
                                                              index,
                                                              e
                                                            )
                                                          }
                                                          onFocus={(e) =>
                                                            e.target.addEventListener(
                                                              "wheel",
                                                              function (e) {
                                                                e.preventDefault();
                                                              },
                                                              { passive: false }
                                                            )
                                                          }
                                                        />
                                                      </td>
                                                      <td className="offer-rate-field">
                                                        <input
                                                          type="number"
                                                          className="form-control new_offer_table"
                                                          onKeyDown={(e) =>
                                                            symbolsrate.includes(
                                                              e.key
                                                            ) &&
                                                            e.preventDefault()
                                                          }
                                                          min={0}
                                                          id="myNumberField"
                                                          value={
                                                            board.wednesday
                                                          }
                                                          name="wednesday"
                                                          onChange={(e) =>
                                                            handleweekinputextend(
                                                              i,
                                                              0,
                                                              index,
                                                              e
                                                            )
                                                          }
                                                          onFocus={(e) =>
                                                            e.target.addEventListener(
                                                              "wheel",
                                                              function (e) {
                                                                e.preventDefault();
                                                              },
                                                              { passive: false }
                                                            )
                                                          }
                                                        />
                                                      </td>
                                                      <td className="offer-rate-field">
                                                        <input
                                                          type="number"
                                                          className="form-control new_offer_table"
                                                          onKeyDown={(e) =>
                                                            symbolsrate.includes(
                                                              e.key
                                                            ) &&
                                                            e.preventDefault()
                                                          }
                                                          min={0}
                                                          id="myNumberField"
                                                          value={board.thursday}
                                                          name="thursday"
                                                          onChange={(e) =>
                                                            handleweekinputextend(
                                                              i,
                                                              0,
                                                              index,
                                                              e
                                                            )
                                                          }
                                                          onFocus={(e) =>
                                                            e.target.addEventListener(
                                                              "wheel",
                                                              function (e) {
                                                                e.preventDefault();
                                                              },
                                                              { passive: false }
                                                            )
                                                          }
                                                        />
                                                      </td>
                                                      <td className="offer-rate-field">
                                                        <input
                                                          type="number"
                                                          className="form-control new_offer_table"
                                                          onKeyDown={(e) =>
                                                            symbolsrate.includes(
                                                              e.key
                                                            ) &&
                                                            e.preventDefault()
                                                          }
                                                          min={0}
                                                          id="myNumberField"
                                                          value={board.friday}
                                                          name="friday"
                                                          onChange={(e) =>
                                                            handleweekinputextend(
                                                              i,
                                                              0,
                                                              index,
                                                              e
                                                            )
                                                          }
                                                          onFocus={(e) =>
                                                            e.target.addEventListener(
                                                              "wheel",
                                                              function (e) {
                                                                e.preventDefault();
                                                              },
                                                              { passive: false }
                                                            )
                                                          }
                                                        />
                                                      </td>
                                                      <td className="offer-rate-field">
                                                        <input
                                                          type="number"
                                                          className="form-control new_offer_table"
                                                          onKeyDown={(e) =>
                                                            symbolsrate.includes(
                                                              e.key
                                                            ) &&
                                                            e.preventDefault()
                                                          }
                                                          min={0}
                                                          id="myNumberField"
                                                          value={board.saturday}
                                                          name="saturday"
                                                          onChange={(e) =>
                                                            handleweekinputextend(
                                                              i,
                                                              0,
                                                              index,
                                                              e
                                                            )
                                                          }
                                                          onFocus={(e) =>
                                                            e.target.addEventListener(
                                                              "wheel",
                                                              function (e) {
                                                                e.preventDefault();
                                                              },
                                                              { passive: false }
                                                            )
                                                          }
                                                        />
                                                      </td>
                                                      <td className="offer-rate-field">
                                                        <input
                                                          type="number"
                                                          className="form-control new_offer_table"
                                                          onKeyDown={(e) =>
                                                            symbolsrate.includes(
                                                              e.key
                                                            ) &&
                                                            e.preventDefault()
                                                          }
                                                          min={0}
                                                          id="myNumberField"
                                                          value={board.sunday}
                                                          name="sunday"
                                                          onChange={(e) =>
                                                            handleweekinputextend(
                                                              i,
                                                              0,
                                                              index,
                                                              e
                                                            )
                                                          }
                                                          onFocus={(e) =>
                                                            e.target.addEventListener(
                                                              "wheel",
                                                              function (e) {
                                                                e.preventDefault();
                                                              },
                                                              { passive: false }
                                                            )
                                                          }
                                                        />
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </table>
                                  </div>
                                );
                              })}

                              <label style={{ color: "red" }}>
                                {offer3error.fixed_week_price}
                              </label>
                            </div>
                            <div
                              className={
                                offer3fields.price_type === "fixed" &&
                                offer3fields.fixed_week_price_status === false
                                  ? "fixed-price-table fixed-price-table-general-basis"
                                  : "fixed-price-table fixed-price-table-general-basis d-none"
                              }
                            >
                              {/* <table className="table offer-same-price-table"> */}

                              {selectedroomocc.map((rooms, i) => {
                                return (
                                  <div className="offer_room_names" key={i}>
                                    <span
                                      className="room-default-expand_offer"
                                      id="room1"
                                      onClick={(e) => handleClickroom(i)}
                                    >
                                      <span className="room-price-expanded_offer ri-arrow-down-s-line text-dark"></span>
                                      {rooms.label}
                                    </span>
                                    <span
                                      className="default_price_show_offer"
                                      style={{ float: "left" }}
                                    >
                                      <ul
                                        className="price_show_select_offer"
                                        id="price_show_type"
                                      >
                                        <li
                                          className={
                                            rooms.based_on === "per_room"
                                              ? "active"
                                              : "d-none"
                                          }
                                          data-value="Room Wise"
                                        >
                                          Room Wise
                                          <span className="info-tag info_tag_offer">
                                            i
                                            <span className="tooltiptext">
                                              {" "}
                                              Update rates below
                                            </span>
                                          </span>
                                        </li>
                                        <li
                                          className={
                                            rooms.based_on === "per_person"
                                              ? "active"
                                              : "d-none"
                                          }
                                          data-value="Person Wise"
                                        >
                                          Person Wise
                                        </li>
                                      </ul>
                                    </span>
                                    {otherboard[i]?.otherboard?.length > 0 ? (
                                      <span className="input-group input_offer_meal">
                                        <label
                                          className="input-group-text input-joint-color offer_extra_meal_label"
                                          id="inputGroup-sizing-default"
                                        >
                                          Select Board
                                        </label>
                                        <MultiSelect
                                          options={otherboard[i]?.otherboard}
                                          value={
                                            selectedotherboard[i]?.selectedboard
                                              ? selectedotherboard[i]
                                                  .selectedboard
                                              : ""
                                          }
                                          onChange={(selectedValues) => {
                                            handleotherboard(i, selectedValues);
                                          }}
                                          labelledBy="Select"
                                          selectionType="counter"
                                          closeOnChangedValue={false}
                                          aria-expanded="true"
                                          hasSelectAll={true}
                                          className="offer_country_multi_1"
                                        />
                                      </span>
                                    ) : (
                                      ""
                                    )}

                                    {tabroom[i]?.click ? (
                                      <>
                                        {rooms.based_on === "per_person" ? (
                                          <>
                                            {rooms.occupancies.map(
                                              (occ, ind) => {
                                                return (
                                                  <>
                                                    {occ.boards.map(
                                                      (board, index) => {
                                                        return (
                                                          <tr
                                                            key={index}
                                                            style={{
                                                              borderWidth:
                                                                "0px 0px",
                                                              display:
                                                                "inline-flex",
                                                              marginTop: "8px",
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <td className="offer-same-price-td_34">
                                                              {" "}
                                                              {board.label}
                                                              <span
                                                                className=""
                                                                style={{
                                                                  marginRight:
                                                                    "13px",
                                                                  float:
                                                                    "right",
                                                                }}
                                                              >
                                                                {occ.adult +
                                                                  occ.child >
                                                                  4 && (
                                                                  <>
                                                                    {occ.adult >
                                                                      0 && (
                                                                      <>
                                                                        <img
                                                                          src="/images/icon/user.png"
                                                                          alt="Adult"
                                                                          style={{
                                                                            paddingLeft:
                                                                              "1px",
                                                                          }}
                                                                        />
                                                                        (
                                                                        {
                                                                          occ.adult
                                                                        }
                                                                        )
                                                                      </>
                                                                    )}{" "}
                                                                    {"x"}
                                                                    {occ.child >
                                                                      0 && (
                                                                      <>
                                                                        <img
                                                                          src="/images/user-color.png"
                                                                          alt="Child"
                                                                          className="child-icon"
                                                                          style={{
                                                                            marginLeft:
                                                                              "2px",
                                                                          }}
                                                                        />
                                                                        (
                                                                        {
                                                                          occ.child
                                                                        }
                                                                        )
                                                                      </>
                                                                    )}
                                                                  </>
                                                                )}

                                                                {occ.adult +
                                                                  occ.child <=
                                                                  4 && (
                                                                  <>
                                                                    {Array.from(
                                                                      {
                                                                        length:
                                                                          occ.adult,
                                                                      },
                                                                      (
                                                                        _,
                                                                        index
                                                                      ) => (
                                                                        <img
                                                                          key={`adult-${index}`}
                                                                          src="/images/icon/user.png"
                                                                          alt="Adult"
                                                                          style={{
                                                                            paddingLeft:
                                                                              "1px",
                                                                          }}
                                                                        />
                                                                      )
                                                                    )}
                                                                    {Array.from(
                                                                      {
                                                                        length:
                                                                          occ.child,
                                                                      },
                                                                      (
                                                                        _,
                                                                        index
                                                                      ) => (
                                                                        <img
                                                                          key={`child-${index}`}
                                                                          src="/images/user-color.png"
                                                                          alt="Child"
                                                                          className="child-icon"
                                                                          style={{
                                                                            marginLeft:
                                                                              "2px",
                                                                          }}
                                                                        />
                                                                      )
                                                                    )}
                                                                  </>
                                                                )}
                                                              </span>
                                                            </td>
                                                            <td className="offer-same-price-td">
                                                              <div className="offer-general-rate-currency">
                                                                <input
                                                                  type="number"
                                                                  className="form-control"
                                                                  name="rate"
                                                                  value={
                                                                    board.rate
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleweekinput(
                                                                      i,
                                                                      ind,
                                                                      index,
                                                                      e
                                                                    )
                                                                  }
                                                                  onKeyDown={(
                                                                    e
                                                                  ) =>
                                                                    symbolsrate.includes(
                                                                      e.key
                                                                    ) &&
                                                                    e.preventDefault()
                                                                  }
                                                                  min={0}
                                                                  id="myNumberField"
                                                                  onFocus={(e) =>
                                                                    e.target.addEventListener(
                                                                      "wheel",
                                                                      function (e) {
                                                                        e.preventDefault();
                                                                      },
                                                                      { passive: false }
                                                                    )
                                                                  }
                                                                />
                                                                <div className="offer-general-rate-currency-type">
                                                                  {
                                                                    currency.code
                                                                  }{" "}
                                                                  Per Room Per
                                                                  Night
                                                                </div>
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </>
                                                );
                                              }
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {rooms.occupancies[0].boards.map(
                                              (board, index) => {
                                                return (
                                                  <tr
                                                    key={index}
                                                    style={{
                                                      borderWidth: "0px 0px",
                                                      display: "inline-flex",
                                                      marginTop: "8px",
                                                      width: "100%",
                                                    }}
                                                  >
                                                    <td className="offer-same-price-td_12">
                                                      {" "}
                                                      {board.label}
                                                    </td>
                                                    <td className="offer-same-price-td_1">
                                                      <div className="offer-general-rate-currency">
                                                        <input
                                                          type="number"
                                                          className="form-control"
                                                          name="rate"
                                                          value={board.rate}
                                                          onChange={(e) =>
                                                            handleweekinputextend(
                                                              i,
                                                              0,
                                                              index,
                                                              e
                                                            )
                                                          }
                                                          onKeyDown={(e) =>
                                                            symbolsrate.includes(
                                                              e.key
                                                            ) &&
                                                            e.preventDefault()
                                                          }
                                                          min={0}
                                                          id="myNumberField"
                                                          onFocus={(e) =>
                                                            e.target.addEventListener(
                                                              "wheel",
                                                              function (e) {
                                                                e.preventDefault();
                                                              },
                                                              { passive: false }
                                                            )
                                                          }
                                                        />
                                                        <div className="offer-general-rate-currency-type">
                                                          {currency.code} Per
                                                          Room Per Night
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                );
                              })}

                              {/* </table> */}
                              <label style={{ color: "red" }}>
                                {offer3error.fixed_price}
                              </label>
                            </div>
                          </div>
                        </li>
                        <div className="clearfix"> </div>

                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input common_discount_show"
                              type="radio"
                              id="WithoutinlineRadio3"
                              value="discount"
                              name="price_type"
                              checked={offer3fields.price_type === "discount"}
                              onChange={() =>
                                handleOptionChangeoffer3price(
                                  "discount",
                                  "price_type"
                                )
                              }
                            />
                            <label>Discount</label>
                          </div>
                          <div
                            className={
                              offer3fields.price_type === "discount"
                                ? "offer-create-common-discount-section"
                                : "offer-create-common-discount-section d-none"
                            }
                          >
                            <div className="common-discount-offer">
                              <label> Discount</label>
                              <span className="offer-common-discount-selection_1">
                                <div className="common_discount_parts">
                                  <input
                                    type="number"
                                    onKeyDown={(e) =>
                                      symbolsrate.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                    id="myNumberField"
                                    className="form-control box_style_is"
                                    placeholder="Insert Value"
                                    value={offer3fields.discount_value}
                                    name="discount_value"
                                    onChange={handleoffer3change}
                                    onFocus={(e) =>
                                      e.target.addEventListener(
                                        "wheel",
                                        function (e) {
                                          e.preventDefault();
                                        },
                                        { passive: false }
                                      )
                                    }
                                  />
                                </div>

                                <div className="common_discount_parts">
                                  <div className="offer_common_discount_select">
                                    <select
                                      id="ForminputState"
                                      className="form-select box_style_is"
                                      value={offer3fields.discount_unit_price}
                                      name="discount_unit_price"
                                      onChange={handleoffer3change}
                                    >
                                      <option value="percentage" selected="">
                                        %
                                      </option>
                                      <option value="currency">
                                        {currency.code}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </span>
                              <div className="common-discount-label-sec">
                                <p>Per Room Per Night</p>
                              </div>
                            </div>
                            <div className="clearfix"> </div>
                            <label style={{ color: "red" }}>
                              {offer3error.discount_value}
                            </label>

                            <div className="offer-common-discount-exception-area">
                              <Link
                                className="common-discount-exception-link"
                                onClick={(e) =>
                                  setExceptionstaydate(!exceptionstaydate)
                                }
                                style={{ color: "#5ea3cb" }}
                              >
                                Exceptions (Stay Dates){" "}
                              </Link>
                            </div>

                            <div
                              className={
                                exceptionstaydate === true
                                  ? "offer-common-discount-exception-expand"
                                  : "offer-common-discount-exception-expand d-none"
                              }
                            >
                              {inputFieldsexception.map((data, index) => {
                                return (
                                  <div
                                    className="row"
                                    key={index}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <ul>
                                      <li>
                                        <label>From : </label>

                                        <div
                                          className="offer-condition-check"
                                          style={{ marginLeft: "5px" }}
                                        >
                                          <form action="#">
                                            <div className="input-group">
                                              <Flatpickr
                                                className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active box_style_is"
                                                id={`flatpickr-from${index}`}
                                                options={{
                                                  mode: "single",
                                                  dateFormat: "d M, Y",
                                                }}
                                                value={data.fromdate}
                                                onChange={(e) =>
                                                  handleChangedatefrom(index, e)
                                                }
                                              />
                                              <span
                                                className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                                onClick={() =>
                                                  handleCalendarIconClick(
                                                    `from${index}`
                                                  )
                                                }
                                                style={{ marginTop: "-3px" }}
                                              ></span>
                                            </div>
                                          </form>
                                        </div>
                                      </li>
                                      <li>
                                        <label>Until : </label>
                                        <div
                                          className="offer-condition-check"
                                          style={{ marginLeft: "5px" }}
                                        >
                                          <form action="#">
                                            <div className="input-group">
                                              <Flatpickr
                                                className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active box_style_is"
                                                id={`flatpickr-to${index}`}
                                                options={{
                                                  mode: "single",
                                                  dateFormat: "d M, Y",
                                                }}
                                                value={data.todate}
                                                onChange={(e) =>
                                                  handleChangetodate(index, e)
                                                }
                                              />
                                              <span
                                                className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                                onClick={() =>
                                                  handleCalendarIconClick(
                                                    `to${index}`
                                                  )
                                                }
                                                style={{ marginTop: "-3px" }}
                                              ></span>
                                            </div>
                                          </form>
                                        </div>
                                      </li>
                                      <li>
                                        <label>Discount : </label>
                                        <span
                                          className="offer-common-discount-selection"
                                          style={{ marginLeft: "5px" }}
                                        >
                                          <div
                                            className="common_discount_parts"
                                            style={{ width: "160px" }}
                                          >
                                            <input
                                              type="text"
                                              className="form-control box_style_is"
                                              placeholder="Insert Value"
                                              value={data.discount}
                                              onChange={(e) =>
                                                handleChangediscount(
                                                  index,
                                                  e.target.value,
                                                  "discount"
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="common_discount_parts">
                                            <div className="offer_common_discount_select">
                                              <select
                                                className="form-select box_style"
                                                name="unit_value"
                                                value={data.unit_value}
                                                onChange={(e) =>
                                                  handleChangediscount(
                                                    index,
                                                    e.target.value,
                                                    "unit_value"
                                                  )
                                                }
                                              >
                                                <option value="percentage">
                                                  %
                                                </option>
                                                <option value="currency">
                                                  {currency.code}
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        </span>
                                      </li>
                                      <span
                                        className={
                                          index ===
                                          inputFieldsexception.length - 1
                                            ? "extrarate-room-link"
                                            : "extrarate-room-link d-none"
                                        }
                                      >
                                        <Link
                                          className=""
                                          to="#"
                                          onClick={addInputFieldoffer3}
                                          style={{ color: "#5ea3cb" }}
                                        >
                                          Add
                                        </Link>
                                      </span>
                                      <Link
                                        className={
                                          index === 0
                                            ? "remove_more d-none"
                                            : "remove_more"
                                        }
                                        to="#"
                                        onClick={(e) =>
                                          removeInputFieldsoffer3(index)
                                        }
                                      >
                                        X
                                      </Link>
                                      {/* <li> <Link className="common-discount-exception-link" style={{color:"#5ea3cb"}}>Add</Link></li> */}
                                    </ul>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab_nav_section">
                    <div className="tab_nav_section_btns">
                      <Link
                        className="btn btn-be-form-cancel btn-be-back"
                        to="#"
                        onClick={(e) => nextoffterstep3rate("prev")}
                      >
                        Prev
                      </Link>

                      <Link
                        className="btn btn-success btn-be-common"
                        to="#"
                        onClick={(e) => nextoffterstep3rate("next")}
                      >
                        Next
                      </Link>
                    </div>
                  </div>
                </>
              ) : ratetab === "increment" ? (
                <>
                  <div className="offer-content-section">
                    <div className="offer-form-status-section">
                      <ul className="offer-create--rate-list">
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input offer_increment_check"
                              type="checkbox"
                              id="formCheck1"
                              onChange={(e) =>
                                handleoffer3checkbox(
                                  e.target.checked,
                                  "increment_status"
                                )
                              }
                              value={offer3fields.increment_status}
                              checked={
                                offer3fields.increment_status === true
                                  ? true
                                  : false
                              }
                            />

                            <label>
                              Increment (Increment for a candlelight dinner or
                              for any add-ons with the room)
                            </label>
                          </div>
                          <div
                            className={
                              offer3fields.increment_status === true
                                ? "offer-increment-expand"
                                : "offer-increment-expand d-none"
                            }
                          >
                            <ul>
                              <li>
                                <select
                                  className="form-select box_style"
                                  style={{ marginTop: "17px" }}
                                  name="increment_type"
                                  value={offer3fields.increment_type}
                                  onChange={handleoffer3change}
                                >
                                  <option value="per_person" selected>
                                    Per Person
                                  </option>
                                  <option value="per_room">Per Room</option>
                                </select>
                              </li>
                              <li>
                                <select
                                  className="form-select box_style once-per"
                                  style={{ marginTop: "17px" }}
                                  name="increment_frequency"
                                  value={offer3fields.increment_frequency}
                                  onChange={handleoffer3change}
                                >
                                  <option value="once">Once</option>
                                  <option value="per_night">Per Night</option>
                                </select>
                              </li>
                              {offer3fields.increment_type === "per_room" && (
                                <li className="offer_3_per">
                                  <label className="offer-step-label_1 input-joint-color  per-color-dummy input-group-text">
                                    Rate
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control box_style offer-step-input_1"
                                    name="increment_room_value"
                                    value={offer3fields.increment_room_value}
                                    onChange={handleoffer3change}
                                  />
                                </li>
                              )}
                              {offer3fields.increment_type === "per_person" && (
                                <>
                                  <li className="offer_3_per">
                                    <label className="offer-step-label_1 input-joint-color per-color-dummy input-group-text">
                                      Per Adult
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control box_style offer-step-input_1"
                                      name="increment_per_adult"
                                      value={offer3fields.increment_per_adult}
                                      onChange={handleoffer3change}
                                      onKeyDown={(e) =>
                                        symbolsrate.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      min={0}
                                      onFocus={(e) =>
                                        e.target.addEventListener(
                                          "wheel",
                                          function (e) {
                                            e.preventDefault();
                                          },
                                          { passive: false }
                                        )
                                      }
                                    />
                                  </li>
                                  <li className="offer_3_per">
                                    <label className="offer-step-label_1 input-joint-color  per-color-dummy input-group-text">
                                      Per Child
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control box_style input_offer_length offer-step-input_1"
                                      name="increment_per_child"
                                      value={offer3fields.increment_per_child}
                                      onChange={handleoffer3change}
                                      onKeyDown={(e) =>
                                        symbolsrate.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      min={0}
                                      onFocus={(e) =>
                                        e.target.addEventListener(
                                          "wheel",
                                          function (e) {
                                            e.preventDefault();
                                          },
                                          { passive: false }
                                        )
                                      }
                                    />
                                  </li>
                                </>
                              )}
                              <li>
                                <input
                                  className="form-control box_style once-per"
                                  style={{ marginTop: "17px" }}
                                  name="increment_unit"
                                  value={currency.code}
                                  disabled
                                  // onChange={handleoffer3change}
                                />
                                {/* <select
                                  className="form-select box_style once-per"
                                  style={{ marginTop: "17px" }}
                                  name="increment_unit"
                                  value={offer3fields.increment_unit}
                                  onChange={handleoffer3change}
                                >
                                  <option value="currency">
                                    {currency.code}
                                  </option>
                                </select> */}
                              </li>
                            </ul>
                            <div className="clearfix"> </div>
                            <label style={{ color: "red" }}>
                              {offer3error.increment_type}
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab_nav_section">
                    <div className="tab_nav_section_btns">
                      <Link
                        className="btn btn-be-form-cancel btn-be-back"
                        to="#"
                        onClick={(e) => nextoffterstep3increment("prev")}
                      >
                        Prev
                      </Link>
                      <Link
                        className="btn btn-success btn-be-common"
                        to="#"
                        onClick={(e) => nextoffterstep3increment("next")}
                      >
                        Next
                      </Link>
                    </div>
                  </div>
                </>
              ) : ratetab === "free_nights" ? (
                <>
                  <div className="offer-content-section">
                    <div className="offer-form-status-section">
                      <ul className="offer-create--rate-list">
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input offer_freenight_check"
                              type="checkbox"
                              id="formCheck1"
                              onChange={(e) =>
                                handleoffer3checkbox(
                                  e.target.checked,
                                  "free_night_status"
                                )
                              }
                              value={offer3fields.free_night_status}
                              checked={
                                offer3fields.free_night_status === true
                                  ? true
                                  : false
                              }
                            />

                            <label>
                              One free night for every{" "}
                              <span
                                style={{ fontWeight: "bold", color: "#008080" }}
                              >
                                {" "}
                                X{" "}
                              </span>{" "}
                              nights booked...{" "}
                              <span className="offer-label-span">
                                (It also applies to meal plan supplements, if
                                they exist)
                              </span>
                            </label>
                          </div>
                          <div
                            className={
                              offer3fields.free_night_status === true
                                ? "offer_freenight_expand"
                                : "offer_freenight_expand d-none"
                            }
                          >
                            <ul className="offer-create-lists">
                              <li>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input "
                                    type="radio"
                                    id="WithoutinlineRadio1"
                                    style={{ marginTop: "12px" }}
                                    value="last_night"
                                    name="free_night_type"
                                    checked={
                                      offer3fields.free_night_type ===
                                      "last_night"
                                    }
                                    onChange={() =>
                                      handleOptionChangeoffer3price(
                                        "last_night",
                                        "free_night_type"
                                      )
                                    }
                                  />
                                  <label className="offer-step-label">
                                    <span className="offer_freeningt_join-is">
                                      Last night free for every{" "}
                                    </span>
                                    <span className="offer_freeningt_join">
                                      <select
                                        className="form-select box_style select_box offer_one_night"
                                        value={offer3fields.free_booking_night1}
                                        name="free_booking_night1"
                                        onChange={handleoffer3change}
                                      >
                                        <option disabled value="">
                                          Select
                                        </option>
                                        {offer3fields.free_night_type ===
                                        "last_night" ? (
                                          <>
                                            {[...Array(29).keys()].map(
                                              (value) => (
                                                <option
                                                  key={value + 2}
                                                  value={value + 2}
                                                >
                                                  {value + 2}
                                                </option>
                                              )
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </select>
                                    </span>
                                    <span className="offer_freeningt_join-is">
                                      booked nights
                                    </span>
                                  </label>
                                  <div className="clearfix"> </div>
                                  <label style={{ color: "red" }}>
                                    {offer3error.free_booking_night1}
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input "
                                    type="radio"
                                    id="WithoutinlineRadio1"
                                    style={{ marginTop: "12px" }}
                                    value="cheapest_night"
                                    name="free_night_type"
                                    checked={
                                      offer3fields.free_night_type ===
                                      "cheapest_night"
                                    }
                                    onChange={() =>
                                      handleOptionChangeoffer3price(
                                        "cheapest_night",
                                        "free_night_type"
                                      )
                                    }
                                  />
                                  <label className="offer-step-label">
                                    <span className="offer_freeningt_join-is">
                                      Cheapest night free out of{" "}
                                    </span>
                                    <span className="offer_freeningt_join">
                                      <select
                                        className="form-select box_style select_box offer_one_night"
                                        value={offer3fields.free_booking_night2}
                                        name="free_booking_night2"
                                        onChange={handleoffer3change}
                                      >
                                        <option disabled value="">
                                          Select
                                        </option>
                                        {offer3fields.free_night_type ===
                                        "cheapest_night" ? (
                                          <>
                                            {[...Array(29).keys()].map(
                                              (value) => (
                                                <option
                                                  key={value + 2}
                                                  value={value + 2}
                                                >
                                                  {value + 2}
                                                </option>
                                              )
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </select>
                                    </span>
                                    <span className="offer_freeningt_join-is">
                                      booked nights
                                    </span>
                                  </label>
                                  <div className="clearfix"> </div>
                                  <label style={{ color: "red" }}>
                                    {offer3error.free_booking_night2}
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab_nav_section">
                    <div className="tab_nav_section_btns">
                      <Link
                        className="btn btn-be-form-cancel btn-be-back"
                        to="#"
                        onClick={(e) => nextoffterstep3freenight("prev")}
                      >
                        Prev
                      </Link>
                      <Link
                        className="btn btn-success btn-be-common"
                        to="#"
                        onClick={(e) => nextoffterstep3freenight("next")}
                      >
                        Next
                      </Link>
                    </div>
                  </div>
                </>
              ) : ratetab === "extra_discount" ? (
                <>
                  <div className="offer-content-section">
                    <div className="offer-form-status-section">
                      <ul className="offer-create--rate-list">
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input offer_booknight_check"
                              type="checkbox"
                              id="formCheck1"
                              onChange={(e) =>
                                handleoffer3checkbox(
                                  e.target.checked,
                                  "booking_at_night_status"
                                )
                              }
                              value={offer3fields.booking_at_night_status}
                              checked={
                                offer3fields.booking_at_night_status === true
                                  ? true
                                  : false
                              }
                            />
                            <label>Booking at night </label>
                          </div>
                          <div
                            className={
                              offer3fields.booking_at_night_status === true
                                ? "offer_booknight_expand"
                                : "offer_booknight_expand d-none"
                            }
                          >
                            <div className="offer_booknight_top_section discount-box">
                              <label className="offer-step-label">
                                Discount{" "}
                              </label>
                              <span className="offer-common-discount-selection">
                                <div
                                  className="common_discount_parts"
                                  style={{ width: "160px" }}
                                >
                                  <input
                                    type="number"
                                    className="form-control box_style_is"
                                    placeholder="Insert Value"
                                    name="booking_night_discount"
                                    value={offer3fields.booking_night_discount}
                                    onChange={handleoffer3change}
                                    onKeyDown={(e) =>
                                      symbolsrate.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                    id="myNumberField"
                                    onFocus={(e) =>
                                      e.target.addEventListener(
                                        "wheel",
                                        function (e) {
                                          e.preventDefault();
                                        },
                                        { passive: false }
                                      )
                                    }
                                  />
                                </div>
                                <div className="common_discount_parts">
                                  <div className="offer_common_discount_select">
                                    <select
                                      id="ForminputState"
                                      className="form-select box_style_is"
                                      value={
                                        offer3fields.booking_night_unit_value
                                      }
                                      name="booking_night_unit_value"
                                      onChange={handleoffer3change}
                                    >
                                      <option value="percentage" selected="">
                                        %
                                      </option>
                                      <option value="currency">
                                        {currency.code}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </span>
                              <div className="clearfix"> </div>
                              <label style={{ color: "red" }}>
                                {offer3error.booking_time_discount}
                              </label>
                            </div>
                            <ul className="offer-create-lists">
                              <li>
                                <div className="form-check form-check-inline from-display">
                                  {/* <input className="form-check-input" type="checkbox" id="formCheck1"
                                    onChange={(e)=>handleoffer3checkbox(e.target.checked,"booking_hours_status")} value={offer3fields.booking_hours_status} checked={offer3fields.booking_hours_status===true?true:false}/> */}

                                  <span className="offer_freeningt_join_off">
                                    From
                                  </span>
                                  <span className="offer_freeningt_join_off">
                                    <div className="offer-condition-check">
                                      <span className="offer_freeningt_join_off">
                                        <select
                                          className="form-select offer-create-select box_style_1 text_center_ctyle"
                                          value={
                                            offer3fields.hours_discount_from
                                          }
                                          name="hours_discount_from"
                                          onChange={handleoffer3change}
                                        >
                                          <option>Select</option>
                                          <option value="0">12 am</option>
                                          <option value="1">1 am</option>
                                          <option value="2">2 am</option>
                                          <option value="3">3 am</option>
                                          <option value="4">4 am</option>
                                          <option value="5">5 am</option>
                                          <option value="6">6 am</option>
                                          <option value="7">7 am</option>
                                          <option value="8">8 am</option>
                                          <option value="9">9 am</option>
                                          <option value="10">10 am</option>
                                          <option value="11">11 am</option>
                                          <option value="12">12 pm</option>
                                          <option value="13">1 pm</option>
                                          <option value="14">2 pm</option>
                                          <option value="15">3 pm</option>
                                          <option value="16">4 pm</option>
                                          <option value="17">5 pm</option>
                                          <option value="18">6 pm</option>
                                          <option value="19">7 pm</option>
                                          <option value="20">8 pm</option>
                                          <option value="21">9 pm</option>
                                          <option value="22">10 pm</option>
                                          <option value="23">11 pm</option>
                                        </select>
                                      </span>
                                      {/* <span className="offer_freeningt_join_12" style={{marginLeft:"2px"}}>am </span>  */}
                                    </div>{" "}
                                  </span>
                                  <span className="offer_freeningt_join_off">
                                    to{" "}
                                  </span>
                                  <span className="offer_freeningt_join_off">
                                    <div className="offer-condition-check">
                                      <span className="offer_freeningt_join_off">
                                        <select
                                          className="form-select offer-create-select box_style_1 text_center_ctyle"
                                          value={offer3fields.hours_discount_to}
                                          name="hours_discount_to"
                                          onChange={handleoffer3change}
                                        >
                                          <option>Select</option>
                                          <option value="12">12 pm</option>
                                          <option value="13">1 pm</option>
                                          <option value="14">2 pm</option>
                                          <option value="15">3 pm</option>
                                          <option value="16">4 pm</option>
                                          <option value="17">5 pm</option>
                                          <option value="18">6 pm</option>
                                          <option value="19">7 pm</option>
                                          <option value="20">8 pm</option>
                                          <option value="21">9 pm</option>
                                          <option value="22">10 pm</option>
                                          <option value="23">11 pm</option>
                                          <option value="0">12 am</option>
                                          <option value="1">1 am</option>
                                          <option value="2">2 am</option>
                                          <option value="3">3 am</option>
                                          <option value="4">4 am</option>
                                          <option value="5">5 am</option>
                                          <option value="6">6 am</option>
                                          <option value="7">7 am</option>
                                          <option value="8">8 am</option>
                                          <option value="9">9 am</option>
                                          <option value="10">10 am</option>
                                          <option value="11">11 am</option>
                                        </select>
                                      </span>
                                      {/* <span className="offer_freeningt_join_1" style={{marginLeft:"2px"}}>pm </span>                             */}
                                    </div>
                                  </span>
                                </div>
                              </li>
                              <div className="clearfix"> </div>
                              <label style={{ color: "red" }}>
                                {offer3error.hours_discount_to}
                              </label>
                            </ul>
                          </div>
                          <div className="clearfix"></div>
                        </li>
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input offer_booknight_check"
                              type="checkbox"
                              id="formCheck1"
                              onChange={(e) =>
                                handleoffer3checkbox(
                                  e.target.checked,
                                  "booking_weekend_discount_status"
                                )
                              }
                              value={
                                offer3fields.booking_weekend_discount_status
                              }
                              checked={
                                offer3fields.booking_weekend_discount_status ===
                                true
                                  ? true
                                  : false
                              }
                            />
                            <label>Booking at weekends</label>
                          </div>
                          <div
                            className={
                              offer3fields.booking_weekend_discount_status ===
                              true
                                ? "offer_booknight_expand"
                                : "offer_booknight_expand d-none"
                            }
                          >
                            <div className="offer_booknight_top_section discount-box">
                              <label className="offer-step-label">
                                Discount{" "}
                              </label>
                              <span className="offer-common-discount-selection">
                                <div
                                  className="common_discount_parts"
                                  style={{ width: "160px" }}
                                >
                                  <input
                                    type="number"
                                    className="form-control box_style_is"
                                    placeholder="Insert Value"
                                    name="booking_weekend_discount"
                                    value={
                                      offer3fields.booking_weekend_discount
                                    }
                                    onChange={handleoffer3change}
                                    onKeyDown={(e) =>
                                      symbolsrate.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                    id="myNumberField"
                                    onFocus={(e) =>
                                      e.target.addEventListener(
                                        "wheel",
                                        function (e) {
                                          e.preventDefault();
                                        },
                                        { passive: false }
                                      )
                                    }
                                  />
                                </div>
                                <div className="common_discount_parts">
                                  <div className="offer_common_discount_select">
                                    <select
                                      id="ForminputState"
                                      className="form-select box_style_is"
                                      value={
                                        offer3fields.booking_weekend_unit_value
                                      }
                                      name="booking_weekend_unit_value"
                                      onChange={handleoffer3change}
                                    >
                                      <option value="percentage" selected="">
                                        %
                                      </option>
                                      <option value="currency">
                                        {currency.code}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </span>
                              <div className="clearfix"> </div>
                              <label style={{ color: "red" }}>
                                {offer3error.booking_weekend_discount}
                              </label>
                            </div>
                            <ul className="offer-create-lists">
                              <li>
                                <div className="form-check form-check-inline">
                                  {/* <input className="form-check-input" type="checkbox" id="formCheck1" style={{marginTop:"3px"}}
                                    onChange={(e)=>handleoffer3checkbox(e.target.checked,"booking_weekend_discount_status")} value={offer3fields.booking_weekend_discount_status} checked={offer3fields.booking_weekend_discount_status===true?true:false}/> */}
                                  <label className="offer-step-label weekend-label">
                                    <span className="offer_freeningt_join">
                                      <span className="offer_week_from">
                                        Weekends: from{" "}
                                      </span>
                                      <select
                                        id="ForminputState"
                                        className="form-select-2 box_style_is select-2-media form-select-media select-2-medi"
                                        value={
                                          offer3fields.booking_weekend_week_from
                                        }
                                        name="booking_weekend_week_from"
                                        onChange={handleoffer3change}
                                      >
                                        <option>Select</option>
                                        <option value="Sunday">Sunday</option>
                                        <option value="Monday">Monday</option>
                                        <option value="Tuesday">Tuesday</option>
                                        <option value="Wednesday">
                                          Wednesday
                                        </option>
                                        <option value="Thursday">
                                          Thursday
                                        </option>
                                        <option value="Friday">Friday</option>
                                        <option value="Saturday">
                                          Saturday
                                        </option>
                                      </select>

                                      <span className="offer_week_from">
                                        at{" "}
                                      </span>
                                      <select
                                        id="ForminputState"
                                        className="form-select-3 box_style_is  form-select-media"
                                        value={
                                          offer3fields.booking_weekend_time_from
                                        }
                                        name="booking_weekend_time_from"
                                        onChange={handleoffer3change}
                                      >
                                        <option>Select</option>
                                        <option value="0">12 am</option>
                                        <option value="1">1 am</option>
                                        <option value="2">2 am</option>
                                        <option value="3">3 am</option>
                                        <option value="4">4 am</option>
                                        <option value="5">5 am</option>
                                        <option value="6">6 am</option>
                                        <option value="7">7 am</option>
                                        <option value="8">8 am</option>
                                        <option value="9">9 am</option>
                                        <option value="10">10 am</option>
                                        <option value="11">11 am</option>
                                        <option value="12">12 pm</option>
                                        <option value="13">1 pm</option>
                                        <option value="14">2 pm</option>
                                        <option value="15">3 pm</option>
                                        <option value="16">4 pm</option>
                                        <option value="17">5 pm</option>
                                        <option value="18">6 pm</option>
                                        <option value="19">7 pm</option>
                                        <option value="20">8 pm</option>
                                        <option value="21">9 pm</option>
                                        <option value="22">10 pm</option>
                                        <option value="23">11 pm</option>
                                      </select>
                                      <span className="offer_week_from">
                                        to
                                      </span>
                                      <select
                                        id="ForminputState"
                                        className="form-select-2 box_style_is select-2-medi"
                                        value={
                                          offer3fields.booking_weekend_week_to
                                        }
                                        name="booking_weekend_week_to"
                                        onChange={handleoffer3change}
                                      >
                                        <option></option>
                                        <option value="Sunday">Sunday</option>
                                        <option value="Monday">Monday</option>
                                        <option value="Tuesday">Tuesday</option>
                                        <option value="Wednesday">
                                          Wednesday
                                        </option>
                                        <option value="Thursday">
                                          Thursday
                                        </option>
                                        <option value="Friday">Friday</option>
                                        <option value="Saturday">
                                          Saturday
                                        </option>
                                      </select>
                                      <span className="offer_week_from">
                                        {" "}
                                        at{" "}
                                      </span>
                                      <select
                                        id="ForminputState"
                                        className="form-select-3 box_style_is form-select-media"
                                        value={
                                          offer3fields.booking_weekend_time_to
                                        }
                                        name="booking_weekend_time_to"
                                        onChange={handleoffer3change}
                                      >
                                        <option>Select</option>
                                        <option value="12">12 pm</option>
                                        <option value="13">1 pm</option>
                                        <option value="14">2 pm</option>
                                        <option value="15">3 pm</option>
                                        <option value="16">4 pm</option>
                                        <option value="17">5 pm</option>
                                        <option value="18">6 pm</option>
                                        <option value="19">7 pm</option>
                                        <option value="20">8 pm</option>
                                        <option value="21">9 pm</option>
                                        <option value="22">10 pm</option>
                                        <option value="23">11 pm</option>
                                        <option value="0">12 am</option>
                                        <option value="1">1 am</option>
                                        <option value="2">2 am</option>
                                        <option value="3">3 am</option>
                                        <option value="4">4 am</option>
                                        <option value="5">5 am</option>
                                        <option value="6">6 am</option>
                                        <option value="7">7 am</option>
                                        <option value="8">8 am</option>
                                        <option value="9">9 am</option>
                                        <option value="10">10 am</option>
                                        <option value="11">11 am</option>
                                      </select>
                                      {/* <span className="offer_week_from">am</span> */}
                                    </span>
                                  </label>
                                </div>
                              </li>
                              <div className="clearfix"> </div>
                              <label style={{ color: "red" }}>
                                {offer3error.booking_weekend_week_from}
                              </label>
                              <li>
                                <div className="offer-common-discount-exception-area">
                                  <Link
                                    className="common-discount-exception-link"
                                    onClick={(e) =>
                                      setExceptionnight(!exceptionnight)
                                    }
                                    style={{ color: "#5ea3cb" }}
                                  >
                                    Exceptions (Stay Dates)
                                  </Link>
                                </div>
                                <div
                                  className={
                                    exceptionnight === true
                                      ? "offer-common-discount-exception-expand"
                                      : "offer-common-discount-exception-expand d-none"
                                  }
                                >
                                  {inputFieldsextra.map((data, index) => {
                                    return (
                                      <div
                                        className="row"
                                        key={index}
                                        style={{ marginTop: "10px" }}
                                      >
                                        <ul>
                                          <li>
                                            <label>From : </label>
                                            <div
                                              className="offer-condition-check"
                                              style={{ marginLeft: "5px" }}
                                            >
                                              <form action="#">
                                                <div className="input-group">
                                                  <Flatpickr
                                                    className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active box_style_is"
                                                    id="flatpickr-from2"
                                                    options={{
                                                      mode: "single",
                                                      dateFormat: "d M, Y",
                                                    }}
                                                    value={data.datefrom}
                                                    onChange={(e) =>
                                                      handleChangedatefromextra(
                                                        index,
                                                        e
                                                      )
                                                    }
                                                  />
                                                  <span
                                                    className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                                    onClick={() =>
                                                      handleCalendarIconClick(
                                                        "from2"
                                                      )
                                                    }
                                                    style={{
                                                      marginTop: "-3px",
                                                    }}
                                                  ></span>
                                                </div>
                                              </form>
                                            </div>
                                          </li>
                                          <li>
                                            <label>Until : </label>
                                            <div
                                              className="offer-condition-check"
                                              style={{ marginLeft: "5px" }}
                                            >
                                              <form action="#">
                                                <div className="input-group">
                                                  <Flatpickr
                                                    className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active box_style_is"
                                                    id="flatpickr-to2"
                                                    options={{
                                                      mode: "single",
                                                      dateFormat: "d M, Y",
                                                    }}
                                                    value={data.dateto}
                                                    onChange={(e) =>
                                                      handleChangetodateextra(
                                                        index,
                                                        e
                                                      )
                                                    }
                                                  />
                                                  <span
                                                    className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                                    onClick={() =>
                                                      handleCalendarIconClick(
                                                        "to2"
                                                      )
                                                    }
                                                    style={{
                                                      marginTop: "-3px",
                                                    }}
                                                  ></span>
                                                </div>
                                              </form>
                                            </div>
                                          </li>
                                          <li>
                                            <label>Discount : </label>
                                            <span
                                              className="offer-common-discount-selection"
                                              style={{ marginLeft: "5px" }}
                                            >
                                              <div
                                                className="common_discount_parts"
                                                style={{ width: "160px" }}
                                              >
                                                <input
                                                  type="text"
                                                  className="form-control box_style_is"
                                                  placeholder="Insert Value"
                                                  value={data.discounts}
                                                  name="discounts"
                                                  onChange={(e) =>
                                                    handleChangediscountsextra(
                                                      index,
                                                      e
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="common_discount_parts">
                                                <div className="offer_common_discount_select">
                                                  <select
                                                    id="ForminputState"
                                                    className="form-select box_style_is"
                                                    value={
                                                      data.discount_unit_price
                                                    }
                                                    name="discount_unit_price"
                                                    onChange={(e) =>
                                                      handleChangediscountsextra(
                                                        index,
                                                        e
                                                      )
                                                    }
                                                  >
                                                    <option
                                                      value="percentage"
                                                      selected=""
                                                    >
                                                      %
                                                    </option>
                                                    <option value="currency">
                                                      {currency.code}
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            </span>
                                          </li>
                                          <span
                                            className={
                                              index ===
                                              inputFieldsextra.length - 1
                                                ? "extrarate-room-link"
                                                : "extrarate-room-link d-none"
                                            }
                                          >
                                            <Link
                                              className=""
                                              to="#"
                                              onClick={addInputFieldextra}
                                              style={{ color: "#5ea3cb" }}
                                            >
                                              Add
                                            </Link>
                                          </span>
                                          <Link
                                            className={
                                              index === 0
                                                ? "remove_more d-none"
                                                : "remove_more"
                                            }
                                            to="#"
                                            onClick={(e) =>
                                              removeInputFieldsextra(index)
                                            }
                                          >
                                            X
                                          </Link>{" "}
                                        </ul>
                                      </div>
                                    );
                                  })}
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="clearfix"></div>
                        </li>
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input offer_longStay_check"
                              type="checkbox"
                              id="formCheck1"
                              onChange={(e) =>
                                handleoffer3checkbox(
                                  e.target.checked,
                                  "long_stay_discount_status"
                                )
                              }
                              value={offer3fields.long_stay_discount_status}
                              checked={
                                offer3fields.long_stay_discount_status === true
                                  ? true
                                  : false
                              }
                            />

                            <label>
                              Additional discount for long stays ...
                            </label>
                          </div>
                          <div
                            className={
                              offer3fields.long_stay_discount_status === true
                                ? "offer_longStay_expand"
                                : "offer_longStay_expand d-none"
                            }
                          >
                            <ul className="offer-create-inline-lists">
                              <li>
                                <div className="offer_next_add">
                                  <label className="offer-step-label">
                                    Minimum nights :{" "}
                                  </label>
                                </div>
                                <div className="offer_next_add">
                                  <label className="offer-step-label">
                                    Discount :{" "}
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="offer_next_add">
                                  <select
                                    className="form-select offer-create-select box_style_1 text_center_ctyle"
                                    value={
                                      offer3fields.long_stay_discount0_night
                                    }
                                    name="long_stay_discount0_night"
                                    onChange={handleoffer3change}
                                  >
                                    {Array.from({ length: 100 }, (_, index) => (
                                      <option key={index + 1} value={index + 1}>
                                        {index + 1}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div
                                  className="offer_next_add"
                                  style={{ width: "115px" }}
                                >
                                  <input
                                    type="number"
                                    className="form-control offer-step-textfield percent_attach_text box_style_is input_offer_length"
                                    onKeyDown={(e) =>
                                      symbolsrate.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                    value={
                                      offer3fields.long_stay_discount0_percentage
                                    }
                                    onChange={handleoffer3change}
                                    name="long_stay_discount0_percentage"
                                    id="myNumberField"
                                    onFocus={(e) =>
                                      e.target.addEventListener(
                                        "wheel",
                                        function (e) {
                                          e.preventDefault();
                                        },
                                        { passive: false }
                                      )
                                    }
                                  />
                                  <div className="common_discount_parts">
                                    <div className="offer_common_discount_select">
                                      <select
                                        id="ForminputState"
                                        className="form-select box_style_is"
                                        value={
                                          offer3fields.long_stay_discount0_unit
                                        }
                                        name="long_stay_discount0_unit"
                                        onChange={handleoffer3change}
                                      >
                                        <option value="percentage" selected="">
                                          %
                                        </option>
                                        <option value="currency">
                                          {currency.code}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </li>

                              <li>
                                {offer3fields.long_stay_discount0_percentage ? (
                                  <div className="offer_next_add">
                                    <select
                                      className="form-select offer-create-select box_style_1 text_center_ctyle"
                                      value={
                                        offer3fields.long_stay_discount1_night
                                      }
                                      name="long_stay_discount1_night"
                                      onChange={handleoffer3change}
                                    >
                                      <option></option>
                                      {Array.from(
                                        { length: 99 },
                                        (_, index) => (
                                          <option
                                            key={index + 2}
                                            value={index + 2}
                                          >
                                            {index + 2}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {offer3fields.long_stay_discount1_night && (
                                  <div
                                    className="offer_next_add"
                                    style={{ width: "115px" }}
                                  >
                                    <input
                                      type="text"
                                      className="form-control offer-step-textfield percent_attach_text box_style_is input_offer_length"
                                      value={
                                        offer3fields.long_stay_discount1_percentage
                                      }
                                      onChange={handleoffer3change}
                                      name="long_stay_discount1_percentage"
                                    />
                                    <div className="common_discount_parts">
                                      <div className="offer_common_discount_select">
                                        <select
                                          id="ForminputState"
                                          className="form-select box_style_is"
                                          value={
                                            offer3fields.long_stay_discount1_unit
                                          }
                                          name="long_stay_discount1_unit"
                                          onChange={handleoffer3change}
                                        >
                                          <option
                                            value="percentage"
                                            selected=""
                                          >
                                            %
                                          </option>
                                          <option value="currency">
                                            {currency.code}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </li>
                              <li>
                                {offer3fields.long_stay_discount1_percentage ? (
                                  <div className="offer_next_add">
                                    <select
                                      className="form-select offer-create-select box_style_1 text_center_ctyle"
                                      value={
                                        offer3fields.long_stay_discount2_night
                                      }
                                      name="long_stay_discount2_night"
                                      onChange={handleoffer3change}
                                    >
                                      <option></option>
                                      {Array.from(
                                        { length: 98 },
                                        (_, index) => (
                                          <option
                                            key={index + 3}
                                            value={index + 3}
                                          >
                                            {index + 3}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {offer3fields.long_stay_discount2_night && (
                                  <div
                                    className="offer_next_add"
                                    style={{ width: "115px" }}
                                  >
                                    <input
                                      type="text"
                                      className="form-control offer-step-textfield percent_attach_text box_style_is input_offer_length"
                                      value={
                                        offer3fields.long_stay_discount2_percentage
                                      }
                                      onChange={handleoffer3change}
                                      name="long_stay_discount2_percentage"
                                    />
                                    <div className="common_discount_parts">
                                      <div className="offer_common_discount_select">
                                        <select
                                          id="ForminputState"
                                          className="form-select box_style_is"
                                          value={
                                            offer3fields.long_stay_discount2_unit
                                          }
                                          name="long_stay_discount2_unit"
                                          onChange={handleoffer3change}
                                        >
                                          <option
                                            value="percentage"
                                            selected=""
                                          >
                                            %
                                          </option>
                                          <option value="currency">
                                            {currency.code}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </li>
                              <li>
                                {offer3fields.long_stay_discount2_percentage ? (
                                  <div className="offer_next_add">
                                    <select
                                      className="form-select offer-create-select box_style_1 text_center_ctyle"
                                      value={
                                        offer3fields.long_stay_discount3_night
                                      }
                                      name="long_stay_discount3_night"
                                      onChange={handleoffer3change}
                                    >
                                      <option></option>
                                      {Array.from(
                                        { length: 97 },
                                        (_, index) => (
                                          <option
                                            key={index + 4}
                                            value={index + 4}
                                          >
                                            {index + 4}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {offer3fields.long_stay_discount3_night && (
                                  <div
                                    className="offer_next_add"
                                    style={{ width: "115px" }}
                                  >
                                    <input
                                      type="text"
                                      className="form-control offer-step-textfield percent_attach_text box_style_is input_offer_length"
                                      value={
                                        offer3fields.long_stay_discount3_percentage
                                      }
                                      onChange={handleoffer3change}
                                      name="long_stay_discount3_percentage"
                                    />
                                    <div className="common_discount_parts">
                                      <div className="offer_common_discount_select">
                                        <select
                                          id="ForminputState"
                                          className="form-select box_style_is"
                                          value={
                                            offer3fields.long_stay_discount3_unit
                                          }
                                          name="long_stay_discount3_unit"
                                          onChange={handleoffer3change}
                                        >
                                          <option
                                            value="percentage"
                                            selected=""
                                          >
                                            %
                                          </option>
                                          <option value="currency">
                                            {currency.code}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </li>
                              <div className="clearfix"> </div>
                              {offer3error.long_stay_discount0_percentage &&
                              offer3error.long_stay_discount1_night ? (
                                <label style={{ color: "red" }}>
                                  {offer3error.long_stay_discount0_percentage}
                                </label>
                              ) : offer3error.long_stay_discount0_percentage ? (
                                <label style={{ color: "red" }}>
                                  {offer3error.long_stay_discount0_percentage}
                                </label>
                              ) : (
                                <label style={{ color: "red" }}>
                                  {offer3error.long_stay_discount1_night}
                                </label>
                              )}
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input offer_earlyBooking_check"
                              type="checkbox"
                              id="formCheck1"
                              onChange={(e) =>
                                handleoffer3checkbox(
                                  e.target.checked,
                                  "early_booking_discount_status"
                                )
                              }
                              value={offer3fields.early_booking_discount_status}
                              checked={
                                offer3fields.early_booking_discount_status ===
                                true
                                  ? true
                                  : false
                              }
                            />

                            <label>For early booking...</label>
                          </div>
                          <div
                            className={
                              offer3fields.early_booking_discount_status ===
                              true
                                ? "offer_earlyBooking_expand"
                                : "offer_earlyBooking_expand d-none"
                            }
                          >
                            <ul className="offer-create-inline-lists">
                              <li>
                                <div className="offer_next_add">
                                  <label className="offer-step-label">
                                    <span className="offer_freeningt_join">
                                      Upto :
                                    </span>
                                    <span className="offer_freeningt_join">
                                      <input
                                        type="text"
                                        className="form-control offer-step-textfield box_style_is input_offer_length"
                                        value={
                                          offer3fields.early_booking_discount0_days
                                        }
                                        name="early_booking_discount0_days"
                                        onChange={handleoffer3change}
                                        style={{
                                          textAlign: "center",
                                          width: "100px",
                                        }}
                                      />
                                    </span>
                                    <span className="offer_freeningt_join">
                                      Days
                                    </span>
                                  </label>
                                </div>
                                <div
                                  className="offer_next_add "
                                  style={{ width: "115px", marginLeft: "47px" }}
                                >
                                  <input
                                    type="number"
                                    className="form-control offer-step-textfield percent_attach_text box_style_is input_offer_length"
                                    onKeyDown={(e) =>
                                      symbolsrate.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                    value={
                                      offer3fields.early_booking_discount0_percentage
                                    }
                                    name="early_booking_discount0_percentage"
                                    id="myNumberField"
                                    onChange={handleoffer3change}
                                    style={{ textAlign: "center" }}
                                    onFocus={(e) =>
                                      e.target.addEventListener(
                                        "wheel",
                                        function (e) {
                                          e.preventDefault();
                                        },
                                        { passive: false }
                                      )
                                    }
                                  />
                                  <div className="common_discount_parts">
                                    <div className="offer_common_discount_select">
                                      <select
                                        id="ForminputState"
                                        className="form-select box_style_is"
                                        value={
                                          offer3fields.early_booking_discount0_unit
                                        }
                                        name="early_booking_discount0_unit"
                                        onChange={handleoffer3change}
                                      >
                                        <option value="percentage" selected="">
                                          %
                                        </option>
                                        <option value="currency">
                                          {currency.code}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                {offer3fields.early_booking_discount0_percentage ? (
                                  <div className="offer_next_add">
                                    <label className="offer-step-label">
                                      <span className="offer_freeningt_join">
                                        Upto :
                                      </span>
                                      <span className="offer_freeningt_join">
                                        <input
                                          type="text"
                                          className="form-control offer-step-textfield box_style_is input_offer_length"
                                          value={
                                            offer3fields.early_booking_discount1_days
                                          }
                                          name="early_booking_discount1_days"
                                          onChange={handleoffer3change}
                                          style={{
                                            textAlign: "center",
                                            width: "100px",
                                          }}
                                        />
                                      </span>
                                      <span className="offer_freeningt_join">
                                        Days
                                      </span>
                                    </label>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {offer3fields.early_booking_discount1_days ? (
                                  <div
                                    className="offer_next_add"
                                    style={{
                                      width: "115px",
                                      marginLeft: "47px",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      className="form-control offer-step-textfield percent_attach_text box_style_is input_offer_length"
                                      onKeyDown={(e) =>
                                        symbolsrate.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      min={0}
                                      value={
                                        offer3fields.early_booking_discount1_percentage
                                      }
                                      name="early_booking_discount1_percentage"
                                      id="myNumberField"
                                      onChange={handleoffer3change}
                                      style={{ textAlign: "center" }}
                                      onFocus={(e) =>
                                        e.target.addEventListener(
                                          "wheel",
                                          function (e) {
                                            e.preventDefault();
                                          },
                                          { passive: false }
                                        )
                                      }
                                    />
                                    <div className="common_discount_parts">
                                      <div className="offer_common_discount_select">
                                        <select
                                          id="ForminputState"
                                          className="form-select box_style_is"
                                          value={
                                            offer3fields.early_booking_discount1_unit
                                          }
                                          name="early_booking_discount1_unit"
                                          onChange={handleoffer3change}
                                        >
                                          <option
                                            value="percentage"
                                            selected=""
                                          >
                                            %
                                          </option>
                                          <option value="currency">
                                            {currency.code}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </li>
                              <li>
                                {offer3fields.early_booking_discount1_percentage ? (
                                  <div className="offer_next_add">
                                    <label className="offer-step-label">
                                      <span className="offer_freeningt_join">
                                        Upto :
                                      </span>
                                      <span className="offer_freeningt_join">
                                        <input
                                          type="text"
                                          className="form-control offer-step-textfield box_style_is input_offer_length"
                                          value={
                                            offer3fields.early_booking_discount2_days
                                          }
                                          name="early_booking_discount2_days"
                                          onChange={handleoffer3change}
                                          style={{
                                            textAlign: "center",
                                            width: "100px",
                                          }}
                                        />
                                      </span>
                                      <span className="offer_freeningt_join">
                                        Days
                                      </span>
                                    </label>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {offer3fields.early_booking_discount2_days ? (
                                  <div
                                    className="offer_next_add"
                                    style={{
                                      width: "115px",
                                      marginLeft: "47px",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      className="form-control offer-step-textfield percent_attach_text box_style_is input_offer_length"
                                      onKeyDown={(e) =>
                                        symbolsrate.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      min={0}
                                      id="myNumberField"
                                      value={
                                        offer3fields.early_booking_discount2_percentage
                                      }
                                      name="early_booking_discount2_percentage"
                                      onChange={handleoffer3change}
                                      style={{ textAlign: "center" }}
                                      onFocus={(e) =>
                                        e.target.addEventListener(
                                          "wheel",
                                          function (e) {
                                            e.preventDefault();
                                          },
                                          { passive: false }
                                        )
                                      }
                                    />
                                    <div className="common_discount_parts">
                                      <div className="offer_common_discount_select">
                                        <select
                                          id="ForminputState"
                                          className="form-select box_style_is"
                                          value={
                                            offer3fields.early_booking_discount2_unit
                                          }
                                          name="early_booking_discount2_unit"
                                          onChange={handleoffer3change}
                                        >
                                          <option
                                            value="percentage"
                                            selected=""
                                          >
                                            %
                                          </option>
                                          <option value="currency">
                                            {currency.code}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </li>
                              <li>
                                {offer3fields.early_booking_discount2_percentage ? (
                                  <div className="offer_next_add">
                                    <label className="offer-step-label">
                                      <span className="offer_freeningt_join">
                                        Upto :
                                      </span>
                                      <span className="offer_freeningt_join">
                                        <input
                                          type="text"
                                          className="form-control offer-step-textfield box_style_is input_offer_length"
                                          value={
                                            offer3fields.early_booking_discount3_days
                                          }
                                          name="early_booking_discount3_days"
                                          onChange={handleoffer3change}
                                          style={{
                                            textAlign: "center",
                                            width: "100px",
                                          }}
                                        />
                                      </span>
                                      <span className="offer_freeningt_join">
                                        Days
                                      </span>
                                    </label>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {offer3fields.early_booking_discount3_days ? (
                                  <div
                                    className="offer_next_add"
                                    style={{
                                      width: "115px",
                                      marginLeft: "47px",
                                    }}
                                  >
                                    <input
                                      type="number"
                                      className="form-control offer-step-textfield percent_attach_text box_style_is input_offer_length"
                                      onKeyDown={(e) =>
                                        symbolsrate.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      min={0}
                                      id="myNumberField"
                                      value={
                                        offer3fields.early_booking_discount3_percentage
                                      }
                                      name="early_booking_discount3_percentage"
                                      onChange={handleoffer3change}
                                      style={{ textAlign: "center" }}
                                      onFocus={(e) =>
                                        e.target.addEventListener(
                                          "wheel",
                                          function (e) {
                                            e.preventDefault();
                                          },
                                          { passive: false }
                                        )
                                      }
                                    />
                                    <div className="common_discount_parts">
                                      <div className="offer_common_discount_select">
                                        <select
                                          id="ForminputState"
                                          className="form-select box_style_is"
                                          value={
                                            offer3fields.early_booking_discount3_unit
                                          }
                                          name="early_booking_discount3_unit"
                                          onChange={handleoffer3change}
                                        >
                                          <option
                                            value="percentage"
                                            selected=""
                                          >
                                            %
                                          </option>
                                          <option value="currency">
                                            {currency.code}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </li>
                            </ul>

                            <div className="clearfix"> </div>
                            <label style={{ color: "red" }}>
                              {offer3error.early_booking_discount_status}
                            </label>
                          </div>
                        </li>

                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input offer_lastminute_check"
                              type="checkbox"
                              id="formCheck1"
                              onChange={(e) =>
                                handleoffer3checkbox(
                                  e.target.checked,
                                  "last_minute_discount_status"
                                )
                              }
                              value={offer3fields.last_minute_discount_status}
                              checked={
                                offer3fields.last_minute_discount_status ===
                                true
                                  ? true
                                  : false
                              }
                            />
                            <label>For last minute...</label>
                          </div>
                          <div
                            className={
                              offer3fields.last_minute_discount_status === true
                                ? "offer_lastminute_expand"
                                : "offer_lastminute_expand d-none"
                            }
                          >
                            <ul className="offer-create-inline-lists">
                              <li>
                                <div className="offer_next_add">
                                  <label className="offer-step-label">
                                    <span className="offer_freeningt_join">
                                      From :
                                    </span>
                                    <span className="offer_freeningt_join">
                                      <select
                                        className="form-select offer-create-select box_style"
                                        value={
                                          offer3fields.last_minute_discount0_from
                                        }
                                        name="last_minute_discount0_from"
                                        onChange={handleoffer3change}
                                      >
                                        <option value=""></option>
                                        <option value="-8">-8</option>
                                        <option value="-6">-6</option>
                                        <option value="0">0</option>
                                        <option value="12">12</option>
                                        <option value="18">18</option>
                                        <option value="24">24</option>
                                        <option value="36">36</option>
                                        <option value="48">48</option>
                                        <option value="72">72</option>
                                        <option value="96">96</option>
                                        <option value="120">120</option>
                                        <option value="140">140</option>
                                        <option value="168">168</option>
                                        <option value="240">240</option>
                                        <option value="336">336</option>
                                        <option value="720">720</option>
                                      </select>
                                    </span>
                                    <span className="offer_freeningt_join">
                                      hours before
                                    </span>
                                  </label>
                                </div>
                                <div
                                  className="offer_next_add hours-top"
                                  style={{ width: "115px", marginLeft: "49px" }}
                                >
                                  <input
                                    type="number"
                                    className="form-control box_style_is input_offer_length"
                                    onKeyDown={(e) =>
                                      symbolsrate.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                    min={0}
                                    id="myNumberField"
                                    value={
                                      offer3fields.last_minute_discount0_discount
                                    }
                                    name="last_minute_discount0_discount"
                                    onChange={handleoffer3change}
                                    onFocus={(e) =>
                                      e.target.addEventListener(
                                        "wheel",
                                        function (e) {
                                          e.preventDefault();
                                        },
                                        { passive: false }
                                      )
                                    }
                                  />
                                  <div className="common_discount_parts">
                                    <div className="offer_common_discount_select">
                                      <select
                                        id="ForminputState"
                                        className="form-select box_style_is"
                                        value={
                                          offer3fields.last_minute_discount0_unit
                                        }
                                        name="last_minute_discount0_unit"
                                        onChange={handleoffer3change}
                                      >
                                        <option value="percentage" selected="">
                                          %
                                        </option>
                                        <option value="currency">
                                          {currency.code}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li>
                                {offer3fields.last_minute_discount0_discount ? (
                                  <div className="offer_next_add">
                                    <label className="offer-step-label">
                                      <span className="offer_freeningt_join">
                                        From :
                                      </span>
                                      <span className="offer_freeningt_join">
                                        <select
                                          className="form-select offer-create-select box_style"
                                          value={
                                            offer3fields.last_minute_discount1_from
                                          }
                                          name="last_minute_discount1_from"
                                          onChange={handleoffer3change}
                                        >
                                          <option value=""></option>
                                          <option value="-8">-8</option>
                                          <option value="-6">-6</option>
                                          <option value="0">0</option>
                                          <option value="12">12</option>
                                          <option value="18">18</option>
                                          <option value="24">24</option>
                                          <option value="36">36</option>
                                          <option value="48">48</option>
                                          <option value="72">72</option>
                                          <option value="96">96</option>
                                          <option value="120">120</option>
                                          <option value="140">140</option>
                                          <option value="168">168</option>
                                          <option value="240">240</option>
                                          <option value="336">336</option>
                                          <option value="720">720</option>
                                        </select>
                                      </span>
                                      <span className="offer_freeningt_join">
                                        hours before
                                      </span>
                                    </label>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {offer3fields.last_minute_discount1_from ? (
                                  <div
                                    className="offer_next_add"
                                    style={{
                                      width: "115px",
                                      marginLeft: "49px",
                                    }}
                                  >
                                    <span className="offer_freenight_discount_sec">
                                      <div className="offer_freenight_discount">
                                        <input
                                          type="number"
                                          className="form-control box_style_is input_offer_length"
                                          onKeyDown={(e) =>
                                            symbolsrate.includes(e.key) &&
                                            e.preventDefault()
                                          }
                                          min={0}
                                          id="myNumberField"
                                          value={
                                            offer3fields.last_minute_discount1_discount
                                          }
                                          name="last_minute_discount1_discount"
                                          onChange={handleoffer3change}
                                          onFocus={(e) =>
                                            e.target.addEventListener(
                                              "wheel",
                                              function (e) {
                                                e.preventDefault();
                                              },
                                              { passive: false }
                                            )
                                          }
                                        />
                                        <div className="common_discount_parts">
                                          <div className="offer_common_discount_select">
                                            <select
                                              id="ForminputState"
                                              className="form-select box_style_is"
                                              value={
                                                offer3fields.last_minute_discount1_unit
                                              }
                                              name="last_minute_discount1_unit"
                                              onChange={handleoffer3change}
                                            >
                                              <option
                                                value="percentage"
                                                selected=""
                                              >
                                                %
                                              </option>
                                              <option value="currency">
                                                {currency.code}
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </li>
                              <li>
                                {offer3fields.last_minute_discount1_discount ? (
                                  <div className="offer_next_add">
                                    <label className="offer-step-label">
                                      <span className="offer_freeningt_join">
                                        From :
                                      </span>
                                      <span className="offer_freeningt_join">
                                        <select
                                          className="form-select offer-create-select box_style"
                                          value={
                                            offer3fields.last_minute_discount2_from
                                          }
                                          name="last_minute_discount2_from"
                                          onChange={handleoffer3change}
                                        >
                                          <option value=""></option>
                                          <option value="-8">-8</option>
                                          <option value="-6">-6</option>
                                          <option value="0">0</option>
                                          <option value="12">12</option>
                                          <option value="18">18</option>
                                          <option value="24">24</option>
                                          <option value="36">36</option>
                                          <option value="48">48</option>
                                          <option value="72">72</option>
                                          <option value="96">96</option>
                                          <option value="120">120</option>
                                          <option value="140">140</option>
                                          <option value="168">168</option>
                                          <option value="240">240</option>
                                          <option value="336">336</option>
                                          <option value="720">720</option>
                                        </select>
                                      </span>
                                      <span className="offer_freeningt_join">
                                        hours before
                                      </span>
                                    </label>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {offer3fields.last_minute_discount2_from ? (
                                  <div
                                    className="offer_next_add"
                                    style={{
                                      width: "115px",
                                      marginLeft: "49px",
                                    }}
                                  >
                                    <span className="offer_freenight_discount_sec">
                                      <div className="offer_freenight_discount">
                                        <input
                                          type="number"
                                          className="form-control box_style_is input_offer_length"
                                          onKeyDown={(e) =>
                                            symbolsrate.includes(e.key) &&
                                            e.preventDefault()
                                          }
                                          min={0}
                                          id="myNumberField"
                                          value={
                                            offer3fields.last_minute_discount2_discount
                                          }
                                          name="last_minute_discount2_discount"
                                          onChange={handleoffer3change}
                                          onFocus={(e) =>
                                            e.target.addEventListener(
                                              "wheel",
                                              function (e) {
                                                e.preventDefault();
                                              },
                                              { passive: false }
                                            )
                                          }
                                        />
                                        <div className="common_discount_parts">
                                          <div className="offer_common_discount_select">
                                            <select
                                              id="ForminputState"
                                              className="form-select box_style_is"
                                              value={
                                                offer3fields.last_minute_discount2_unit
                                              }
                                              name="last_minute_discount2_unit"
                                              onChange={handleoffer3change}
                                            >
                                              <option
                                                value="percentage"
                                                selected=""
                                              >
                                                %
                                              </option>
                                              <option value="currency">
                                                {currency.code}
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </li>
                              <li>
                                {offer3fields.last_minute_discount2_discount ? (
                                  <div className="offer_next_add">
                                    <label className="offer-step-label">
                                      <span className="offer_freeningt_join">
                                        From :
                                      </span>
                                      <span className="offer_freeningt_join">
                                        <select
                                          className="form-select offer-create-select box_style"
                                          value={
                                            offer3fields.last_minute_discount3_from
                                          }
                                          name="last_minute_discount3_from"
                                          onChange={handleoffer3change}
                                        >
                                          <option value=""></option>
                                          <option value="-8">-8</option>
                                          <option value="-6">-6</option>
                                          <option value="0">0</option>
                                          <option value="12">12</option>
                                          <option value="18">18</option>
                                          <option value="24">24</option>
                                          <option value="36">36</option>
                                          <option value="48">48</option>
                                          <option value="72">72</option>
                                          <option value="96">96</option>
                                          <option value="120">120</option>
                                          <option value="140">140</option>
                                          <option value="168">168</option>
                                          <option value="240">240</option>
                                          <option value="336">336</option>
                                          <option value="720">720</option>
                                        </select>
                                      </span>
                                      <span className="offer_freeningt_join">
                                        hours before
                                      </span>
                                    </label>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {offer3fields.last_minute_discount3_from ? (
                                  <div
                                    className="offer_next_add"
                                    style={{
                                      width: "115px",
                                      marginLeft: "49px",
                                    }}
                                  >
                                    <span className="offer_freenight_discount_sec">
                                      <div className="offer_freenight_discount">
                                        <input
                                          type="number"
                                          className="form-control box_style_is input_offer_length"
                                          onKeyDown={(e) =>
                                            symbolsrate.includes(e.key) &&
                                            e.preventDefault()
                                          }
                                          min={0}
                                          id="myNumberField"
                                          value={
                                            offer3fields.last_minute_discount3_discount
                                          }
                                          name="last_minute_discount3_discount"
                                          onChange={handleoffer3change}
                                          onFocus={(e) =>
                                            e.target.addEventListener(
                                              "wheel",
                                              function (e) {
                                                e.preventDefault();
                                              },
                                              { passive: false }
                                            )
                                          }
                                        />
                                        <div className="common_discount_parts">
                                          <div className="offer_common_discount_select">
                                            <select
                                              id="ForminputState"
                                              className="form-select box_style_is"
                                              value={
                                                offer3fields.last_minute_discount3_unit
                                              }
                                              name="last_minute_discount3_unit"
                                              onChange={handleoffer3change}
                                            >
                                              <option
                                                value="percentage"
                                                selected=""
                                              >
                                                %
                                              </option>
                                              <option value="currency">
                                                {currency.code}
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </li>
                            </ul>
                            <div className="clearfix"> </div>
                            <label style={{ color: "red" }}>
                              {offer3error.last_minute_discount0_discount}
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab_nav_section">
                    <div className="tab_nav_section_btns">
                      <Link
                        className="btn btn-be-form-cancel btn-be-back"
                        to="#"
                        onClick={(e) => nextoffterstep3extra("prev")}
                      >
                        Prev
                      </Link>
                      <Link
                        className="btn btn-success btn-be-common"
                        to="#"
                        onClick={(e) => nextoffterstep3extra("next")}
                      >
                        Next
                      </Link>
                    </div>
                  </div>
                </>
              ) : ratetab === "country" ? (
                <>
                  <div className="offer-content-section">
                    <div className="offer-form-status-section">
                      <ul className="offer-create--rate-list">
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input offer_country_check"
                              type="checkbox"
                              id="formCheck1"
                              onChange={(e) =>
                                handleoffer3checkbox(
                                  e.target.checked,
                                  "country_discount_status"
                                )
                              }
                              value={offer3fields.country_discount_status}
                              checked={
                                offer3fields.country_discount_status === true
                                  ? true
                                  : false
                              }
                            />

                            <label>By country...</label>
                          </div>
                          <div
                            className={
                              offer3fields.country_discount_status === true
                                ? "offer_country_expand "
                                : "offer_country_expand d-none"
                            }
                          >
                            <div className="row">
                              <div
                                className="col-md-12 col-lg-12 col-xl-12"
                                style={{ paddingLeft: "30px" }}
                              >
                                <div className="input-group">
                                  <label
                                    className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"
                                  >
                                    Select Country
                                  </label>
                                  <MultiSelect
                                    options={countryOffer3}
                                    value={multicountri3}
                                    onChange={setMulticountry3}
                                    labelledBy="Select"
                                    selectionType="counter"
                                    closeOnChangedValue={false}
                                    aria-expanded="true"
                                    hasSelectAll={true}
                                    className="offer_country_multi_12 multi-width"
                                  />
                                </div>
                              </div>
                              <div
                                className="col-md-12 pad-col-country"
                                style={{ padding: "10px 15px 0px 30px" }}
                              >
                                <div className="">
                                  {multicountri3.map((data, i) => {
                                    return (
                                      <div
                                        key={i}
                                        className="country_all_data "
                                      >
                                        <div className="input-group ">
                                          <label
                                            className="input-group-text input-joint-color country_dis"
                                            id="inputGroup-sizing-default"
                                            title={data.label}
                                          >
                                            {data.label}
                                          </label>
                                          <span className="offer_freenight_discount_sec offer_country_span_sec">
                                            <div className="offer_freenight_discount">
                                              <input
                                                type="number"
                                                className="form-control box_style_is input_offer_length"
                                                id="myNumberField"
                                                value={data.discount}
                                                onKeyDown={(e) =>
                                                  symbolsrate.includes(e.key) &&
                                                  e.preventDefault()
                                                }
                                                min={0}
                                                onChange={(e) =>
                                                  handleInputChangeconutryvalue(
                                                    i,
                                                    e.target.value,
                                                    "discount"
                                                  )
                                                }
                                                onFocus={(e) =>
                                                  e.target.addEventListener(
                                                    "wheel",
                                                    function (e) {
                                                      e.preventDefault();
                                                    },
                                                    { passive: false }
                                                  )
                                                }
                                              />
                                              <div className="common_discount_parts">
                                                <div className="offer_common_discount_select">
                                                  <select
                                                    id="ForminputState"
                                                    className="form-select box_style_is"
                                                    value={
                                                      data.country_unit_value
                                                    }
                                                    name="country_unit_value"
                                                    onChange={(e) =>
                                                      handleInputChangeconutryvalue(
                                                        i,
                                                        e.target.value,
                                                        "country_unit_value"
                                                      )
                                                    }
                                                  >
                                                    <option
                                                      value="currency"
                                                      selected
                                                    >
                                                      {currency.code}
                                                    </option>
                                                    <option value="percentage">
                                                      %
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>

                          {offer3error.country_discount ? (
                            <>
                              <div className="clearfix"> </div>
                              <label style={{ color: "red" }}>
                                {offer3error.country_discount}
                              </label>
                            </>
                          ) : (
                            ""
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="tab_nav_section">
                    <div className="tab_nav_section_btns">
                      <Link
                        className="btn btn-be-form-cancel btn-be-back"
                        to="#"
                        onClick={(e) => nextoffterstep3country("prev")}
                      >
                        Prev
                      </Link>
                      <Link
                        className="btn btn-success btn-be-common"
                        to="#"
                        onClick={(e) => nextoffterstep3country("next")}
                      >
                        Next
                      </Link>
                    </div>
                  </div>
                </>
              ) : ratetab === "device_discount" ? (
                <>
                  <div className="offer-content-section">
                    <div className="offer-form-status-section">
                      <ul className="offer-create--rate-list">
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input offer_device_check"
                              type="checkbox"
                              id="formCheck1"
                              onChange={(e) =>
                                handleoffer3checkbox(
                                  e.target.checked,
                                  "device_discount_status"
                                )
                              }
                              value={offer3fields.device_discount_status}
                              checked={
                                offer3fields.device_discount_status === true
                                  ? true
                                  : false
                              }
                            />
                            <label>By device...</label>
                          </div>
                          <div
                            className={
                              offer3fields.device_discount_status === true
                                ? "offer_device_expand"
                                : "offer_device_expand  d-none"
                            }
                          >
                            <div className="offer_device_sec">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input "
                                  type="radio"
                                  name="inlineRadioOptions"
                                  id="device_offer1"
                                  value="desktop"
                                  checked={
                                    offer3fields.device_discount_type ===
                                    "desktop"
                                  }
                                  onChange={() =>
                                    handleOptionChangeoffer3price(
                                      "desktop",
                                      "device_discount_type"
                                    )
                                  }
                                />
                                <label>Desktop / Tablet </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="device_offer2"
                                  value="mobile"
                                  checked={
                                    offer3fields.device_discount_type ===
                                    "mobile"
                                  }
                                  onChange={() =>
                                    handleOptionChangeoffer3price(
                                      "mobile",
                                      "device_discount_type"
                                    )
                                  }
                                />
                                <label>Mobile</label>
                              </div>
                              <div
                                className={
                                  offer3fields.device_discount_type ===
                                    "desktop" ||
                                  offer3fields.device_discount_type === "mobile"
                                    ? "offer_device_discount_sec"
                                    : "offer_device_discount_sec d-none"
                                }
                              >
                                <div className="offer_device_discount_label">
                                  Discount :
                                </div>
                                <div className="offer_country_value country-inline-sec">
                                  <span className="offer_freenight_discount_sec">
                                    <div
                                      className="offer_freenight_discount"
                                      style={{ width: "115px" }}
                                    >
                                      <input
                                        type="number"
                                        className="form-control box_style_is input_offer_length"
                                        onKeyDown={(e) =>
                                          symbolsrate.includes(e.key) &&
                                          e.preventDefault()
                                        }
                                        min={0}
                                        id="myNumberField"
                                        value={
                                          offer3fields.device_discount_percentage
                                        }
                                        name="device_discount_percentage"
                                        onChange={handleoffer3change}
                                        onFocus={(e) =>
                                          e.target.addEventListener(
                                            "wheel",
                                            function (e) {
                                              e.preventDefault();
                                            },
                                            { passive: false }
                                          )
                                        }
                                      />
                                      <div className="common_discount_parts">
                                        <div className="offer_common_discount_select">
                                          <select
                                            id="ForminputState"
                                            className="form-select box_style_is"
                                            value={
                                              offer3fields.device_discount_unit_value
                                            }
                                            name="device_discount_unit_value"
                                            onChange={handleoffer3change}
                                          >
                                            <option value="currency" selected>
                                              {currency.code}
                                            </option>

                                            <option value="percentage">
                                              %
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="clearfix"> </div>
                            <label style={{ color: "red" }}>
                              {offer3error.device_discount_percentage}
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab_nav_section">
                    <div className="tab_nav_section_btns">
                      <Link
                        className="btn btn-be-form-cancel btn-be-back"
                        to="#"
                        onClick={(e) => nextoffterstep3device("prev")}
                      >
                        Prev
                      </Link>
                      <Link
                        className="btn btn-success btn-be-common"
                        to="#"
                        onClick={(e) => nextoffterstep3device("next")}
                      >
                        Next
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Offerstep3;
