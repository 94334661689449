import React, { useEffect, useState } from "react";
import Footer from "../../Common/Footer";
import { Link } from "react-router-dom";
import Alerts from "../../Alerts";
import Config from "../../Config";
import axios from "axios";
import Hotelstarcategoryedit from "./Hotelstarcategoryedit";
import ReactPaginate from "react-paginate";
import Commonheader from "../../Common/Commonheader";
import Basicsettingsheader from "../../Common/Basicsettingsheader";
import Loading from "../../Loading";

function Hotelstarcategory() {
  const [hotel,setHotel]=useState("")
  const [formerror, setError] = useState({});
  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [open, setOpen] = useState(false);
  const [pageid, setPageid] = useState("");
  const [pagedata, setPagedata] = useState("");
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [paginate, setPaginate] = useState([]);
  const [page,setPage]=useState(1);
  const [pageindex,setPageindex]=useState(1);
  const [loader,setLoader] = useState(false);
   
  function tog_animationFlip(id) {
    setmodal_animationFlip(!modal_animationFlip);
    setPageid(id);
  } 

  
  function tog_backdrop(ids) {
    setmodal_backdrop(!modal_backdrop);
    setPageid(ids);

    const getinfo = async (e) => {
      const res = await fetch(`${Config.apiurl}admin/category/${ids}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const data1 = await res.json();
      const data = data1.data;
      if (data1.status_code !== "200" || !data) {
        console.log("error");
      } else {
        setPagedata(data);
      
      }
    };
    getinfo();
  }

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}admin/category/list?page=${currentPage}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const data = await res.json();
    return data.data.data;
  };


  const [List, setList] = useState([]);

  const handlePageClick = async (data) => {
  
    if(data===1){
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
    
      setList(commentsFormServer);
    
    }
    else{
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
    
      setList(commentsFormServer);
    }
  };
  
  const getData = async (e) => {
    const res = await fetch(`${Config.apiurl}admin/category/list?page=1`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setList(data.data.data);
      setPaginate(data.data.last_page);
      setLoader(true);

    }
  };


  const validate = (values) => {
    const errors = {};
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-]+$/;

    if (!values) {
      errors.hotel = "Category is required!";
    }
    else if (!noSpecialCharRegex.test(values)) {
      errors.hotel = "Category should not contain this special character";
    }
    else if (values.length > 50) {
      errors.hotel = `Category cannot be more than 50 characters!`;
    }
    return errors;
  };

  const PostData = async (e) => {
    e.preventDefault();
    const ress = validate(hotel);
    
    setError(ress);
   
    if (!ress.hotel ) {
      const formData = new FormData();
      formData.append("star", hotel);
      
      axios
        .post(`${Config.apiurl}admin/category/add`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.status_code !== "200" || !res) {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("error");
          } else {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("success");
            handlePageClick(page);

          }
        });
    }
  };

  const UpdateData = async (id) => {
    const formData = new FormData();
    formData.append("name", pagedata.name);
    formData.append("is_active", pagedata.status);
 
    axios
      .post(`${Config.apiurl}admin/category/update/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,

        },
      })
      .then((res) => {
        if (res.status_code !== "200" || !res) {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("error");
          setmodal_animationFlip(false);
        } else {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("success");
          setmodal_animationFlip(false);
          setmodal_backdrop(false);
          handlePageClick(page);

        }
      });
  };
  let name, value;
  const handleInputedit = (e) => {
    e.preventDefault();
    console.log(e);
    name = e.target.name;
    value = e.target.value;
    setPagedata({ ...pagedata, [name]: value });
  };


  useEffect(() => {
    getData();
  }, []);
console.log(alert);
  
  return (
    <div id="layout-wrapper">
      <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Hotel Star Category</h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Settings</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Basic Settings</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Hotel Star Category
                      </li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>
            {alert ? 
                <Alerts type={alerttype} message={alertmessage} open={open} />
              : 
                ""}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create New Hotel Star Category
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                          <div class="col-lg-4">
                                        <div className="input-group star_space">
                                      <label className="input-group-text input-joint-color"
                                          id="inputGroup-sizing-default">Category
                                      <span className="form-validation-required">*</span>
                                        </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={hotel}
                                onChange={(e) => setHotel(e.target.value)}
                              />
                              
                            </div>
                            <label style={{ color: "red" }}>
                                  {formerror.hotel}
                                </label>
                            </div>
                            <div className="col-md-4">
                              <button
                                type="button"
                                className="btn db-save-single-button"
                                data-bs-toggle="modal"
                                id="create-btn"
                                data-bs-target=""  onClick={PostData}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="card" id="orderList">
                    <div className="card-header border-0">
                      <div className="row align-items-center gy-3">
                        <div className="col-sm d-flex justify-content-between">
                          <h5 className="card-title mb-0">
                            List Of Hotel Star Category
                          </h5>
                          
                        </div>
                      </div>
                    </div>

                    <div className="card-body pt-0">
                      <div>
                        <ul
                          className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                          role="tablist"
                        ></ul>

                        <div className="table-responsive table-card mb-1">
                          <table
                            className="table table-nowrap align-middle"
                            id="orderTable"
                          >
                            <thead className="text-muted table-light">
                              <tr className="text-uppercase">
                           
                                <th className="" data-sort="id">
                                  SL
                                </th>
                                <th className="" data-sort="customer_name">
                                  Category
                                </th>
                                <th className="" data-sort="customer_name">
                                  Status
                                </th>
                                <th className="" data-sort="city">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {loader===false?
                             <tbody>
                             <tr>
                            <td
                              style={{ textAlign: "center" }}
                              colSpan={8}
                            >
                              <Loading />
                            </td>
                          </tr>
                          </tbody>
                          :
                            <tbody className="list form-check-all">
                              {List.map((user, index) => {
                                return (
                                  <tr key={index}>
                                   
                                    <td className="id">{pageindex===1 ? index+1: (pageindex-1) * 10 + index +1}</td>
                                    <td className="customer_name">
                                      {user.name}
                                    </td>
                                    <td>
                                      {user.status === false ? (
                                        <span class="badge badge-soft-danger text-uppercase">
                                          {" "}
                                          INACTIVE
                                        </span>
                                      ) : (
                                        <span className="badge badge-soft-success text-uppercase">
                                         ACTIVE
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <ul className="list-inline hstack gap-2 mb-1">
                                        <li
                                          className="list-inline-item edit"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Edit"
                                        >
                                          <Link
                                            to="#"
                                            data-bs-toggle="modal"
                                            className="text-primary d-inline-block edit-item-btn"
                                            onClick={() =>
                                              tog_backdrop(user.cat_id)}
                                          >
                                            <i className="ri-pencil-fill fs-16"></i>
                                          </Link>
                                        </li>
                                        <li
                                          className="list-inline-item"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Remove"
                                        >
                                          {/* <Link
                                            className="text-danger d-inline-block remove-item-btn"
                                            data-bs-toggle="modal"
                                            to="#"
                                            onClick={() => tog_animationFlip()}
                                          >
                                            <i className="ri-delete-bin-5-fill fs-16"></i>
                                          </Link> */}
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                             }
                          </table>
                          {paginate > 1 ? (
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={paginate}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                          ) : (
                            ""
                          )} 
                        </div>
                       
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      {modal_backdrop ? (
          <Hotelstarcategoryedit
            pagedata={pagedata}
           modal_backdrop={modal_backdrop}
            tog_backdrop={tog_backdrop}
            pageid={pageid}
            UpdateData={UpdateData}
            handleInputedit={handleInputedit}
           
          />
        ) : (
          ""
        )}
     
    </div>
  );
}

export default Hotelstarcategory;
