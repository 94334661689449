import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Common/Footer";
import Privatesaleheader from "../../Common/Privatesaleheader";
import Allavailablecode from "./Allavailablecode";
import Blackoutdays from "./Blackoutdays";
import Commontab from "./Commontab";
import Createcode from "./Createcode";
import Poststayemail from "./Poststayemail";

function Codes() {
  const [tab, setTab] = useState("available");

  return (
    <div>
      <Privatesaleheader />
      <div style={{ marginTop: "11%" }}>
        <Commontab tab={tab} setTab={setTab} />
        {tab === "available" ? (
          <Allavailablecode tab={tab} setTab={setTab} />
        ) : tab === "create" ? (
          <Createcode setTab={setTab} tab={tab} />
        ) : tab === "email" ? (
          <Poststayemail />
        ) : tab === "blackout" ? (
          <Blackoutdays />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Codes;
