import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { MultiSelect } from 'react-multi-select-component'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Alerts from '../../Alerts'
import Basicsettingsheader from '../../Common/Basicsettingsheader'
import Footer from '../../Common/Footer'
import Config from '../../Config'
import Loading from '../../Loading'

function Policycontractedit() {
  let navigate = useNavigate();

    let params=useParams();
    const ids=params.id;

        const [active, setActive] = useState("en");
        const[be_language,setBe_language]=useState([])
        const language = localStorage.getItem("be_language");
        const [alert, setAlert] = useState(false);
        const [alertmessage, setAlertmessage] = useState("");
        const [alerttype, setAlerttype] = useState("");
      
        const [formerror4, setError4] = useState({});
        const [formerror5, setError5] = useState({});
        const [open, setOpen] = useState(false);
        const [status,setStatus]=useState("")
        const [policy,setPolicy]=useState([]);
     
        const[langdata,setLangdata]=useState('')
        const[be_languagefull,setBe_languagefull]=useState([])
        const [multi,setMulti]=useState([]);

     
        const getlanguage = async (e) => {
    
          const res = await fetch(`${Config.apiurl}optional-languages`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
    
            },
          });
          const data = await res.json();
          if (data.status_code !== "200" || !data.data) {
            console.log("error");
          } else {
            setBe_language(data.data);
          }
        
        
        };
    
        const getfulllanguage = async (e) => {

          const res = await fetch(`${Config.apiurl}booking-engine-languages`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
    
            },
          });
          const data = await res.json();
          if (data.status_code !== "200" || !data.data) {
            console.log("error");
          } else {
            setBe_languagefull(data.data);
          }
        
        
        };
        
        const handleInput = (e) => {
          let name = e.target.name;
          let value = e.target.value;
          setPolicy({ ...policy, [name]: value });
        };
       
     
        
      const [selectedMulti, setselectedMulti] = useState([]);
      function handleMulti(selectedMulti) {
        setselectedMulti(selectedMulti);
      }
     
      const [selectedMulti1, setselectedMulti1] = useState([]);
      function handleMulti1(selectedMulti1) {
        setselectedMulti1(selectedMulti1);
      }
      const validate = (values) => {
        const errors = {};
        if (!values.description) {
          errors.description = "Description is required!";
        }

        if (!values.contract_type) {
          errors.contract_type = "Type is required!";
        }
        if (!values.policy_title) {
          errors.policy_title = "Title is required!";
        }    
        return errors;
      };
    
      const validatestatus = () => {
        const errors = {};
        // console.log(values);
        // if (values===true || values===false) {
        //  console.log("");
        // }
        // else{
        //     errors.status = "Status is required!";
        // }
        if (!status) {
          errors.status = "Status is required!";
        } 
        return errors;
      };

      const PostData = async (e) => {
        e.preventDefault();
        const ress = validate(policy);
        const ress1= validatestatus(status);
       
        let lang = [];
        
        for (let i = 0; i < multi.length; i++) {
          if (multi[i].name) {
            if (multi[i].code !== "en") {
              let multiarray = {};
              multiarray.lang_code = multi[i].code;  
              multiarray.content = multi[i].name;  
              multiarray.type = multi[i].type;
              multiarray.title = multi[i].title;  
              lang.push(multiarray);
            }
          }
        }
        
        setError4(ress);
        setError5(ress1);
    
        if (!ress.policy && !ress.contract_type && !ress.policy_title && !ress1.status) {
       
          const res = await fetch(`${Config.apiurl}admin/policy-contract/update/${ids}`, {
            method: "POST",
            body: JSON.stringify({
              type:policy.contract_type,
              title:policy.policy_title,
              content: policy.description,
              status: status,
              multi_lang: lang,
            }),
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-type": "application/json; charset=UTF-8",
            },
          });
          const data = await res.json();
          if (data.status_code !== "200" || !data) {
            setAlert(true);
            setAlertmessage(data.message);
            setOpen(true);
            setAlerttype("error");
          } else {
            setAlert(true);
            setAlertmessage(data.message);
            setOpen(true);
            setAlerttype("success");
            setTimeout(() => navigate("/basic_settings/policy-contract-list"), 3000);

            Reset();
          }
        }
      };
    
      function Reset(){
        setPolicy("");
        setLangdata("");
        setMulti([]);
        setStatus("");
      }
    
      useEffect(() => {
        const getinfo = async (e) => {
            const res = await fetch(`${Config.apiurl}admin/policy-contract/view/${ids}`, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-type": "application/json; charset=UTF-8",
              },
            });
            const data = await res.json();
              setPolicy(data.data.policy_contract);
             setMulti(data.data.multi_lang);
              setStatus(data.data.policy_contract.status);
          };
          getinfo();
          getlanguage();
          getfulllanguage();

    },[language,ids]);


  return (
    <div id="layout-wrapper">
    <Basicsettingsheader/>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
              
              </div>
              {alert ? (
                <Alerts type={alerttype} message={alertmessage} open={open} />
              ) : (
                ""
              )}
              <div className="row project-wrapper">
                <div className="row dashboard-top-tab-style">
                  <div className="col-xxl-12 dashboard-main-style">
                    <div className="card">
                      <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                       Update Policy Contract
                        </h4>
                        <div className="flex-shrink-0">
                          <Link
                            type="button"
                            className="btn db-other-button"
                            to="/basic_settings/policy-contract-list"
                            style={{ marginRight: "5px" }}
                          >
                            Policy Contract
                          </Link>
                          {/* <button
                            type="button"
                            className="btn db-cancel-button"
                            data-bs-toggle="offcanvas"
                            to=""
                            onClick={Reset}
                          >
                            Cancel
                          </button> */}
                          <button
                            type="button"
                            className="btn db-save-button"
                            data-bs-toggle="modal"                       
                            onClick={PostData}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                     
                      <div className="card-body">
                        <div className="live-preview">
                          <form action="#">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-translation-style">
                                  <ul>
                                  <li
                                      className={active === "en" ? "active" : ""}
                                    >
                                      <button
                                           type="button"
                                        onClick={() => setActive("en")}
                                        className={
                                          active === "en" ? "active" : ""
                                        }
                                        style={{border:"none",background:"transparent"}}
                                      >
                                        EN
                                      </button>
                                    </li>
                                    <li
                                      className={
                                        active === "trans" ? "active" : ""
                                      }
                                    >
                                      <button   
                                                   type="button"
                                        onClick={() => setActive("trans")}
                                        className={
                                          active === "trans" ? "active" : ""
                                        }
                                        style={{border:"none",background:"transparent"}}
                                      >
                                        Translations
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>                 
  
                                {policy!==undefined ?
  
  
                              <>
                         {active==="en"?
                              <>

                          <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Type
                            <span className="form-validation-required">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                name="contract_type"
                              value={policy.contract_type}
                              onChange={handleInput}
                                          />
                              </div>
                              <label style={{ color: "red" }}>
                                    {formerror4.contract_type}
                                  </label>
                            </div>

                            <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Title
                            <span className="form-validation-required">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                name="policy_title"
                              value={policy.policy_title}
                              onChange={handleInput}
                                          />
                              </div>
                              <label style={{ color: "red" }}>
                                    {formerror4.policy_title}
                                  </label>
                            </div>
                             
                              <div class="col-lg-12">
                                  <div className="input-group">
                                <label className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default">Description
                                <span className="form-validation-required">*</span>
                                  </label>
                                  <textarea className="form-control" 
                                 rows="3" cols="50"
                                   value={policy.description}
                                          name="description"
                                          onChange={handleInput}>
                                          </textarea>
                                  
                                </div>
                                <label
                                    style={{ color: "red", marginTop: "3px" }}
                                  >
                                    {formerror4.title}
                                  </label>
                              </div>
                              <div class="col-lg-3">
                                        <div className="input-group">
                                      <label className="input-group-text input-joint-color"
                                          id="inputGroup-sizing-default">Status
                                      <span className="form-validation-required">*</span>
                                        </label>
                                      <select
                                        id="ForminputState"
                                        className="form-select"
                                        value={status}
                                        onChange={(e)=>setStatus(e.target.value)}>
                                        <option selected>Choose...</option>
                                        <option {...(status === true
                                      ? "selected"
                                      : "")}
                                    value={true}  >Active</option>
                                        <option {...(status === false
                                      ? "selected"
                                      : "")}
                                    value={false} >Inactive</option>
                                      </select>
                                     
                                    </div>
                                    <label style={{ color: "red" }}>
                                        {formerror5.status}
                                      </label>
                                  </div>
                              </>:
                             <>
                             <div className="col-lg-4" style={{ marginTop: "20px" }}>
                               <div className="input-group">
                                 <label className="input-group-text input-joint-color" id="inputGroup-sizing-default" style={{ width: "130px" }}>
                                   Choose Language:
                                 </label>
                                 <div className="col-md-7">
                                   <MultiSelect
                                     options={be_language}
                                     value={multi}
                                     onChange={setMulti}
                                     labelledBy="Select"
                                     selectionType="counter"
                                     closeOnChangedValue={false}
                                     aria-expanded="true"
                                     hasSelectAll={false}
                                   />
                                 </div>
                               </div>
                             </div>
                             <div className="appendmore"></div>
                             {multi.length > 0 &&
                               multi.map((element, index) => (
                                 <div
                                   key={index}
                                   className="col-md-12 translation-fill-section"
                                   id={`lang_${element.value}`}
                                   data-value="1"
                                   style={{ marginTop: "20px" }}
                                 >
                                   <div className="translation-selected-lang">
                                     <p>
                                       <span className="translation-selected-lang-head">
                                         {element.code}
                                       </span>
                                     </p>
                                   </div>
                                   <div className="row">

                                   <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Type
                            <span className="form-validation-required">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                name="contract_type"
                                value={element.type}
                                onChange={(e) =>
                                  setMulti((prevMulti) =>
                                    prevMulti.map((item, i) =>
                                      i === index ? { ...item, type: e.target.value } : item
                                    )
                                  )
                            }
                                          />
                              </div>
                            
                            </div>
                            <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Title
                            <span className="form-validation-required">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                name="policy_title"
                                value={element.title}
                                onChange={(e) =>
                                  setMulti((prevMulti) =>
                                    prevMulti.map((item, i) =>
                                      i === index ? { ...item, title: e.target.value } : item
                                    )
                                  )
                            }
                                          />
                              </div>
                            
                            </div>
                                     <div className="col-lg-10 mt-3">
                                       <div className="input-group">
                                         <label className="input-group-text input-joint-color" id="inputGroup-sizing-default">
                                          Description
                                           <span className="form-validation-required">
                                             *
                                           </span>
                                         </label>
                                         <textarea className="form-control" rows="3" cols="50"
                                           value={element.name}
                                           onChange={(e) =>
                                             setMulti((prevMulti) =>
                                               prevMulti.map((item, i) =>
                                                 i === index ? { ...item, name: e.target.value } : item
                                               )
                                             )
                                           }
                                         />
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                                
                               ))}
                           </>
                                }
                                  </>:<Loading/>}
                            </div>
  
                            <div className="padding-20"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
  )
}

export default Policycontractedit
