import React, { useEffect, useState } from "react";
import Footer from "../../Common/Footer";
import { Link, useNavigate } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import Config from "../../Config";
import Alerts from "../../Alerts";
import axios from "axios";
import Commonheader from "../../Common/Commonheader";
import Basicsettingsheader from "../../Common/Basicsettingsheader";

function Boardadd() {

  let navigate = useNavigate();
  const [selectedMulti, setselectedMulti] = useState([]);
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }
  const[board,setBoard]=useState("")
  const[tab,setTab]=useState("EN")
  const [List, setList] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [open, setOpen] = useState(false);
  const [formerror, setError] = useState({});
  const [formerror1, setError1] = useState({});
  const [formerror2, setError2] = useState({});
  const [status, setStatus] = useState(1);
  const [defaultstatus, setDefaultstatus] = useState(0);
  const [fullform, setFullform] = useState('');
  const [boardCode, setBoardCode] = useState('');

  const [language, setLanguage] = useState("");


  const getData = async (e) => {
    const res = await fetch(`${Config.apiurl}admin/board/list?page=1`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data) {
      console.log("error");
    }
     else 
    {
      setList(data.data.data);
    }
  };


  const getValue = async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers:
      {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setLanguage(data.data);
      
    }
  };
  console.log(language);

  const validate = (values) => {
    const errors = {};
    const noSpecialCharRegex = /^[A-Za-z0-9\s]+$/;

    if (!values) {
      errors.board = "Name is required!";
    }
    else if (!noSpecialCharRegex.test(values)) {
      errors.board = "Name should not contain this special character";
    }
    else if (values.length > 50) {
      errors.board = `Name cannot be more than 50 characters!`;
    }
    // if (!fullform) {
    //   errors.fullform = "Full form is required!";
    // }
    return errors;
  };

    const validate1 = (values) => {
      const errors = {};
      if (!values) {
        errors.status = "Status is required!";
      }
      return errors;
    };

    const validate2 = (values) => {
      const errors = {};
      if (!values) {
        errors.boardCode = "Board code is required!";
      }
      return errors;
    };


    
  const PostData = async (e) => {
    e.preventDefault();
    const ress = validate(board);
    const ress1=validate1(status);
    const ress2=validate2(boardCode);
    setError(ress);
    setError1(ress1);
    setError2(ress2);
    let lang = [];

  
    for (let i = 0; i < selectedMulti.length; i++) {
      if (selectedMulti[i].name) {
        if (selectedMulti[i].code !== "en") {
          let multiarray = {};
          multiarray.lang_code = selectedMulti[i].code;
          multiarray.name = selectedMulti[i].name;
          multiarray.full_form = selectedMulti[i].fullform;

          lang.push(multiarray);
        }
      }
    }
  if (!ress.board && !ress1.status && !ress2.boardCode) {
    
      const res = await fetch(`${Config.apiurl}admin/board/add`, {
        method: "POST",
        body: JSON.stringify({
          en_name: board,
          default_status:defaultstatus,
          is_active: status,
          multi_lang: lang,
          full_form:fullform,
          indicator:boardCode
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
          if (data.status_code !== "200" || !data) {
            setAlert(true);
            setAlertmessage(data.message);
            setOpen(true);
            setAlerttype("error");
          } else {
            setAlert(true);
            setAlertmessage(data.message);
            setOpen(true);
            setAlerttype("success");
            getData();
            setTimeout(() => navigate("/basic_settings/board-list"), 3000);
          }
        
    }
  };
  
  useEffect(() => {
    getData();
    getValue();
  }, []);
console.log(selectedMulti);
  return (
    <div id="layout-wrapper">
      <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Meal Boards</h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Settings</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Basic Settings </Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="/board-lis">Meal Boards</Link>
                      </li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>
            {alert ? 
                <Alerts type={alerttype} message={alertmessage} open={open} />
              : 
                ""}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create New Meal Board
                      </h4>
                      <div className="flex-shrink-0">
                        <Link
                          type="button"
                          className="btn db-other-button"
                          to="/basic_settings/board-list"
                          style={{ marginRight: "5px" }}
                        >
                          Boards
                        </Link>
                        {/* <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                        >
                          Cancel
                        </button> */}
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target=""onClick={PostData}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-translation-style">
                                <ul>
                                  <li className={tab==="EN" ?"active" :""}onClick={(e)=>setTab("EN")}>EN</li>
                                  <li  className={tab==="TRANS" ?"active" :""} onClick={(e)=>setTab("TRANS")}>
                                  
                                      Translations
                                  
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {tab==="EN"?
                            <>
                           <div class="col-lg-6">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                  Name
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                 value={board}
                                  onChange={(e) => setBoard(e.target.value)}
                                />
                                
                            
                              </div>
                              <label style={{ color: "red" }}>
                                    {formerror.board}
                                  </label>
                            </div>
                            <div class="col-lg-6">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                  Full form
                                  {/* <span className="form-validation-required">
                                    *
                                  </span> */}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                 value={fullform}
                                  onChange={(e) => setFullform(e.target.value)}
                                />
                                
                            
                              </div>
                              <label style={{ color: "red" }}>
                                    {formerror.fullform}
                                  </label>
                            </div>
                            <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                  Board Code
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                 value={boardCode}
                                  onChange={(e) => setBoardCode(e.target.value)}
                                />
                                
                            
                              </div>
                              <label style={{ color: "red" }}>
                                    {formerror2.boardCode}
                                  </label>
                            </div>
                            <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                  Status{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <select
                                  id="ForminputState"
                                  className="form-select"
                                  value={status}
                                  onChange={(e) => setStatus(e.target.value)}
                                >
                                  <option selected>Choose...</option>
                                  <option value={1}>Active</option>
                                  <option value={0}>Inactive</option>
                                </select>
                               
                              </div>
                              <label style={{ color: "red" }}>
                                    {formerror1.status}
                                  </label>
                            </div>
                            <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                  Default Board{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <select
                                  id="ForminputState"
                                  className="form-select"
                                  value={defaultstatus}
                                  onChange={(e) => setDefaultstatus(e.target.value)}
                                >
                                  <option selected>Choose...</option>
                                  <option value={1}>Active</option>
                                  <option value={0}>Inactive</option>
                                </select>
                              
                              </div>
                              <label style={{ color: "red" }}>
                                    {formerror1.defaultstatus}
                                  </label>
                            </div>
                            </>:
                            <>
                            <div class="col-lg-4" style={{marginTop:"20px"}}>
                                  <div className="input-group">
                                    <label className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default" style={{width:"130px"}}> Choose Language:</label>
                                  <div className="col-md-7">
                                <MultiSelect
                                  options={language}
                                  value={selectedMulti}
                                  onChange={setselectedMulti}
                                  labelledBy="Select"
                                  selectionType="counter"
                                  closeOnChangedValue={true}
                                  aria-expanded="true"
                                />
                              </div>
                            </div>
                            </div>
                            <div className="appendmore"></div>
                            {selectedMulti.length > 0
                              ? selectedMulti.map((element, index) => {
                                  return (
                                    <div
                                      key={index}
                                      class="col-md-12 translation-fill-section"
                                      id="lang_ +val"
                                      data-value="1" style={{marginTop:"20px"}}
                                    >
                                      <div class="translation-selected-lang">
                                        <p>
                                          <span class="translation-selected-lang-head">
                                            {element.code}
                                          </span>
                                        </p>
                                      </div>
                                    <div className="row">
                                      <div class="col-lg-6">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                            Name
                                            <span class="form-validation-required">
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder=""
                                            value={
                                              selectedMulti[index].name
                                            }
                                            onChange={
                                              (e) =>
                                                (selectedMulti[index].name =
                                                  e.target.value)}/>
                                          <label style={{ color: "red" }}>
                                                        {formerror.board}
                                          </label>
                                        
                                        </div>
                                      </div>
                                      <div class="col-lg-6">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                           Full form
                                            {/* <span class="form-validation-required">
                                              *
                                            </span> */}
                                          </label>
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder=""
                                            value={
                                              selectedMulti[index].fullform
                                            }
                                            onChange={
                                              (e) =>
                                                (selectedMulti[index].fullform =
                                                  e.target.value)}/>
                                          <label style={{ color: "red" }}>
                                                        {formerror.fullform}
                                          </label>
                                        
                                        </div>
                                      </div>
                                    </div>
                                    </div>
                                  );
                                })
                              : ""}
                            </>}
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default Boardadd;
