import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Commonheader from "../../../Common/Commonheader";
import Footer from "../../../Common/Footer";
import Toastmodal from "../../../Common/Toastmodal";
import { useAuthHandling } from "../../../Common/useAuthHandling";
import Config from "../../../Config";
import Extraeditstep1 from "./Extraeditstep1";
import Extraeditstep2 from "./Extraeditstep2";
import Extraeditstep3 from "./Extraeditstep3";
import Extraeditstep4 from "./Extraeditstep4";

function Extraedit() {
  let navigate = useNavigate();
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const [tab, setTab] = useState("step1");
  const [extra1Valid, setExtra1Valid] = useState(false);
  const [extra2valid, setExtra2valid] = useState(false);

  let params = useParams();
  const [id, setId] = useState(params.hotel_id);
  const [extraid, setExtraid] = useState(params.extraid);

  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [sub_addon_status, setSubaddon_status] = useState("");
  const [loyalty_club, setLoyalty_club] = useState([]);
  const [newboard, setNewboard] = useState([]);

  const [extraStep1, setExtraStep1] = useState({
    extraCheck: true,
    provider: "hotel",
    notify_email: "",
    availabilty_status: false,
    availabilty_type: "all_between",
  });
  const [extraName, setExtraName] = useState("");
  const [extra1Error, setExtra1Error] = useState({});
  const [selectedDatesstay, setSelectedDatesstay] = useState([]);
  const fpstay = useRef(null);
  const [activestaydateopen, setActivestaydateopen] = useState(false);
  const [openclosestay, setOpenclosestay] = useState("open");
  const [datefromstay, setDatefromstay] = useState("");
  const [datetostay, setDatetostay] = useState("");
  const [selectedWeekdaysstay, setSelectedWeekdaysstay] = useState([]);
  const [clickedDaysstay, setClickedDaysstay] = useState([]);
  const [language, setLanguage] = useState([]);
  const [selectedMulti, setselectedMulti] = useState([]);
  const [multi, setMulti] = useState([]);
  const [multinew, setMultinew] = useState([]);
  const [extra_include_status, setExtra_include_status] = useState(false);
  const [loyalty, setLoyalty] = useState([]);
  const [otherloyal_status, setOtherloyal_status] = useState(false);
  const [otherloyal, setOtherloyal] = useState([
    {
      loyalty_name: "",
      lang: [],
    },
  ]);
  const [alllanguage, setAlllanguage] = useState([]);
  const [multilang, setMultilang] = useState([]);
  const [loyallang, setLoyallang] = useState("");
  const [extra2Room, setExtra2Room] = useState([]);
  const [extra2Board, setExtra2Board] = useState([]);
  const [extra2Ratelist, setExtra2Ratelist] = useState([]);
  const [extra2error, setExtra2error] = useState({});
  const [activeroom, setActiveroom] = useState(false);
  const [activeboard, setActiveboard] = useState(false);
  const [activerate, setActiverate] = useState(false);
  const [selectedroomocc, setSelectedroomocc] = useState([]);
  const [subAddonName, setSubAddonName] = useState("");
  const [optionimage, setOptionimage] = useState([]);
  const [offers, setOffers] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState([]);
  const [selectedRate, setSelectedRate] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [newLang, setNewLang] = useState("");
  const [imagechoose, setImagechoose] = useState("");
  const [removeid, setRemoveid] = useState([]);
  const [offerstatus, setOfferstatus] = useState({ only_offer: false });

  const [extraStep3, setExtraStep3] = useState({
    price_type: "room_once",
    extra_price: "",
    extra3Check: false,
    price_age_category: "person",
    client_preference_status: false,
    price: "",
  });
  const [numberOfGuests, setNumberOfGuests] = useState([
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
  ]);
  const [numberOfguestsub, setNumberOfGuestsub] = useState([
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
    { price: "" },
  ]);
  const [currency, setCurrency] = useState([]);
  const [availableboard, setAvailbleboard] = useState([]);

  const [Loyaltyclublist, setLoyaltyclublist] = useState([]);
  const [extraStep5, setExtraStep5] = useState({
    subtitle: "",
    description: "",
    extra_condition: "",
  });
  const [image, setImage] = useState([]);

  const [extraname, setExtraname] = useState([]);
  const [extraRoom, setExtraRoom] = useState([]);
  const [extraOffer, setExtraOffer] = useState([]);
  const [extraBoard, setExtraBoard] = useState([]);
  const [extraBoardnew, setExtraBoardnew] = useState([]);
  const [extraRatelist, setExtraRatelist] = useState([]);
  const [extraRatelistnew, setExtraRatelistnew] = useState([]);
  const [extraOffernew, setExtraOffernew] = useState([]);
  const [extra, setExtra] = useState([]);
  const [subaddonlist, setSubaddonlist] = useState([
    {
      sub_addon: {
        title: "",
        sub_addon_price_type: "free",
      },
      multi_lang: [],
    },
  ]);
  const [inclusionlist, setInclusionlist] = useState([]);
  const [langall, setLangall] = useState([]);

  const getextra = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/extra/view`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        extra_id: extraid,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        if (data.data.rooms) {
          setExtraRoom(data.data.rooms);
        }
        setExtraname(data.data.extra.title);
        if (data.data.boards) {
          setExtraBoard(data.data.boards);
        }
        if (data.data.rates) {
          setExtraRatelist(data.data.rates);
        }
        setExtra(data.data.extra);
        setSelectedDatesstay(data.data.availability);
        setLangall(data.data.multi_lang);
        if (data.data.sub_addon_list) {
          setSubaddonlist(data.data.sub_addon_list);
        }
        setInclusionlist(data.data.inclusions);
        setExtraOffer(data.data.offers);
        setMultilang(data.data.multi_lang);
        if (data.data.loyality_clubs) {
          setLoyalty_club(data?.data?.loyality_clubs);
        }

        setImage(data.data.extra.image);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getextra();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getextra();
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    const updatedExtraRoom = extraRoom.map((room) => {
      const matchingRoom = extra2Room.find((r) => r.room_id === room.room_id);
      if (matchingRoom) {
        return {
          ...room,
          selected: matchingRoom.selected,
          room_type: matchingRoom.room_type,
        };
      }

      return room;
    });
    setExtraRoom(updatedExtraRoom);
  }, [extraRoom.length, extra2Room.length]);

  //step1

  const handleExtraToggle = (isChecked, name) => {
    setExtra({ ...extra, [name]: isChecked });
  };
  const handleExtraRadio = (isChecked, name) => {
    setExtra({ ...extra, [name]: isChecked });
  };
  const handleOpenclosestay = (e) => {
    setActivestaydateopen(false);
  };
  const handlechangeExtra1 = (e) => {
    setExtra({ ...extra, [e.target.name]: e.target.value });
  };
  const handleApplyToCalendarstay = () => {
    if (openclosestay === "open") {
      if (fpstay.current) {
        fpstay.current.flatpickr.clear();
      }
      if (datefromstay && datetostay && selectedWeekdaysstay.length > 0) {
        const dates = [];
        let currentDate = new Date(datefromstay);

        while (currentDate <= datetostay) {
          const dayOfWeek = currentDate.getDay();
          if (!clickedDaysstay.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatesstay(dates);
      }
    } else {
      if (fpstay.current) {
        fpstay.current.flatpickr.clear();
      }

      if (
        datefromstay &&
        datetostay &&
        selectedWeekdaysstay.length > 0 &&
        clickedDaysstay.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromstay);

        while (currentDate <= datetostay) {
          const dayOfWeek = currentDate.getDay();
          if (clickedDaysstay.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
        setSelectedDatesstay(dates);
      }
    }
  };

  const toggleSelectedDaystay = (dayIndex) => {
    if (selectedWeekdaysstay.includes(dayIndex)) {
      if (clickedDaysstay.includes(dayIndex)) {
        setClickedDaysstay((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayIndex)
        );
      } else {
        setClickedDaysstay((prevClickedDays) => [...prevClickedDays, dayIndex]);
      }
    }
  };

  const handleCalendarChangeFrom = (selectedDates) => {
    setDatefromstay(selectedDates[0]);
  };

  const handleCalendarChangeTo = (selectedDates) => {
    setDatetostay(selectedDates[0]);
  };
  const [inputFields, setInputFields] = useState([
    {
      subaddons: "",
      multi_lang: [],
      price_type: "free",
      extra_price: "",
      extra3Check: false,
      price_age_category: "person",
      client_preference_status: false,
    },
  ]);
  const addInputField = () => {
    setSubaddonlist((prevSubaddonlist) => [
      ...prevSubaddonlist,
      {
        multi_lang: [],
        newlangsub: "",
        sub_addon: {
          title: "",
          sub_addon_price_type: "free",
          sub_addon_price: "",
          extra3Check: false,
          price_age_category: "person",
          client_preference_status: false,
        },
      },
    ]);
  };

  const removeInputFields = (index) => {
    const rows = [...subaddonlist];
    const deletedRow = subaddonlist[index];
    removeid.push(deletedRow.sub_addon.sub_addon_id);
    rows.splice(index, 1);
    setSubaddonlist(rows);
  };

  const handleChangeSubaddon = (i, e) => {
    const newval = [...subaddonlist];
    newval[i].sub_addon[e.target.name] = e.target.value;
    setSubaddonlist(newval);
  };

  let handleChangeslang = (i, name, data) => {
    let newval = [...subaddonlist];
    newval[i][name] = data;
    setSubaddonlist(newval);
  };

  let handleLang = (index, ind, e) => {
    let newval = [...subaddonlist];
    newval[index]["multi_lang"][ind][e.target.name] = e.target.value;
    setSubaddonlist(newval);
  };

  const handleOptionChangeExtrastep1 = (index, name, value) => {
    let newInputFields = [...subaddonlist];
    newInputFields[index].sub_addon[name] = value;
    // newInputFields[index].sub_addon.sub_addon_price = "";
    setSubaddonlist(newInputFields);
  };

  const handlechangeStep1 = (index, e) => {
    let newInputFields = [...subaddonlist];
    newInputFields[index].sub_addon[e.target.name] = e.target.value;
    setSubaddonlist(newInputFields);
  };

  const handleExtra1Check = (index, name, isChecked) => {
    let newInputFields = [...subaddonlist];
    newInputFields[index].sub_addon[name] = isChecked;
    setSubaddonlist(newInputFields);
  };

  // const handlePriceChangeStep1 = (i, index, event) => {
  //   const updatedSubAddonList = [...subaddonlist];
  //   const price = event.target.value;
  //   updatedSubAddonList[i].sub_addon.person_range_price[index] = {
  //     ...updatedSubAddonList[i].sub_addon.person_range_price[index],
  //     price: price
  //   };
  //   setSubaddonlist(updatedSubAddonList);
  // };

  const handlePriceChangeStep1 = (outerIndex, innerIndex, event) => {
    const updatedSubAddonList = [...subaddonlist];
    const price = event.target.value;
    updatedSubAddonList[outerIndex].sub_addon =
      updatedSubAddonList[outerIndex].sub_addon || {};
    updatedSubAddonList[outerIndex].sub_addon.person_range_price =
      updatedSubAddonList[outerIndex].sub_addon.person_range_price ||
      Array(12).fill({ price: "" });
    updatedSubAddonList[outerIndex].sub_addon.person_range_price[innerIndex] = {
      ...updatedSubAddonList[outerIndex].sub_addon.person_range_price[
        innerIndex
      ],
      price: price,
    };
    setSubaddonlist(updatedSubAddonList);
  };

  const addInputFieldinclusion = () => {
    setOtherloyal([
      ...otherloyal,
      {
        loyalty_name: "",
        lang: [],
      },
    ]);
  };
  const removeInputFieldinclusion = (index) => {
    const rows = [...otherloyal];
    rows.splice(index, 1);
    setOtherloyal(rows);
  };

  const handleloyalchange = (isChecked, index) => {
    const updatedList = [...loyalty];
    updatedList[index]["selected"] = isChecked;
    setLoyalty(updatedList);
  };

  const hangleotherloyal = (index, val, name) => {
    const updatedList = [...otherloyal];
    updatedList[index][name] = val;
    setOtherloyal(updatedList);
  };

  const handleChangeextraname = (e) => {
    const newValue = e.target.value;
    setExtraname(newValue);
  };

  const [tab2, setTab2] = useState("addon");
  const [formerror, setError] = useState([{}]);

  const nextExtrastep1 = () => {
    let isFormValid = true;
    let isFormValid1 = true;
    let isFormValid2 = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-,/,_,%,@]+$/;

    const errors = {};
    if (!extraname) {
      isFormValid = false;
      errors.extraname = "Add-on name is required!";
    }
    else if (!noSpecialCharRegex.test(extraname)) {
      isFormValid = false;
      errors.extraname = "Add-on name should not contain this special character";
    }
    else if (extraname.length > 100) {
      isFormValid = false;
      errors.extraname = `Add-on name cannot be more than 100 characters!`;
    }
    if (extra.days_in_advance_status === true) {
      if (!extra.days_in_advance) {
        isFormValid1 = false;

        errors.days_in_advance = "This field is required!";
      }
    }
    //   if(extra.provider==='external'){
    //     if (!extra.notifyEmail) {
    //        isFormValid1 = false;
    //        errors.notifyEmail = "Email is required!";
    //     }
    //  }
    if (extra.provider === "external") {
      if (!extra.notifyEmail) {
        isFormValid1 = false;

        errors.notifyEmail = "Email is required!";
      } else if (!regex.test(extra.notifyEmail)) {
        isFormValid1 = false;

        errors.notifyEmail = "This is not a valid email format!";
      }
    }
    if (
      extra.extra_price_type === "free" ||
      extra.extra_price_type === "person_range_once"
    ) {
      isFormValid2 = true;
    } else {
      if (!extra.extra_price) {
        isFormValid2 = false;
        errors.extra_price = "Price field is required!";
      }
    }
    setExtra1Error(errors);
    if (
      isFormValid === true &&
      isFormValid1 === true &&
      isFormValid2 === true
    ) {
      setTab2("subaddon");
      setExtra1Valid(true);
    }
  };

  const nextExtrastep1sub = () => {
    let isFormValid = true;
    let isFormValid1 = true;
    let isFormValid2 = true;
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-,/,_,%,@]+$/;

    let arr = [];
    if (extra.sub_addon_status === true) {
      for (let i = 0; i < subaddonlist.length; i++) {
        const error = {};
        if (!subaddonlist[i].sub_addon.title) {
          isFormValid = false;
          error.title = "Sub-addon is required!";
        }
        else if (!noSpecialCharRegex.test(subaddonlist[i].sub_addon.title)) {
          isFormValid = false;
          error.title = "Sub-addon should not contain this special character";
        }
        else if (subaddonlist[i].sub_addon.title.length > 100) {
          isFormValid = false;
          error.title = `Sub-addon cannot be more than 100 characters!`;
        }
        if (
          subaddonlist[i].sub_addon.sub_addon_price_type === "free" ||
          subaddonlist[i].sub_addon.sub_addon_price_type === "person_range_once"
        ) {
          isFormValid1 = true;
        } else {
          if (!subaddonlist[i].sub_addon.sub_addon_price) {
            isFormValid2 = false;
            error.sub_addon_price = "Price field is required!";
          }
        }

        arr.push(error);
      }
    }
    setError(arr);
    if (
      isFormValid === true &&
      isFormValid1 === true &&
      isFormValid2 === true
    ) {
      setTab2("inclusion");
      setExtra1Valid(true);
    }
  };

  const nextExtrastep1inc = () => {
    if (extra_include_status === false) {
      setTab("step2");
      setExtra1Valid(true);
    } else {
      let isFormValid = true;
      const errors = {};

      const hasSelectedFalse = loyalty.some((item) => item.selected === true);
      if (!hasSelectedFalse && otherloyal_status === false) {
        isFormValid = false;
        errors.loyalty = "Please choose one field";
      }
      if (otherloyal_status === true) {
        const hasSelectedFalse = otherloyal.some(
          (item) => item.loyalty_name !== ""
        );
        if (!hasSelectedFalse) {
          isFormValid = false;
          errors.loyalty_name = "Please enter at least one name.";
        }
      }
      setExtra1Error(errors);
      if (isFormValid === true) {
        setTab("step2");
        setExtra2valid(true);
      }
    }
  };
  const handleExtraSubaddonToggle = (isChecked, name) => {
    setExtra({ ...extra, [name]: isChecked });
  };

  const handleOptionpriceExtrastep3 = (index, event) => {
    const updatedGuests = [...numberOfGuests];
    updatedGuests[index] = { price: event.target.value };
    setNumberOfGuests(updatedGuests);
  };

  const handleLanguageChange = (selectedOption) => {

    setNewLang(selectedOption);
    const updatedLanguages = [...multi];
    if (!updatedLanguages.some((item) => item.lang_code === selectedOption)) {
      updatedLanguages.push({ lang_code: selectedOption, title: "" });
    }
    setMulti(updatedLanguages);
  };

  const handleAddonChange = (addonValue) => {
    const updatedList = [...multi];
    const matchingIndex = updatedList.findIndex(
      (language) => language.lang_code === newLang
    );
    if (matchingIndex !== -1) {
      updatedList[matchingIndex]["title"] = addonValue;
    } else {
      const newLanguage = { lang_code: newLang };
      newLanguage["title"] = addonValue;
      updatedList.push(newLanguage);
    }
    setMulti(updatedList);
  };

  const handleLanguageChangesub = (index, selectedOption) => {
    const updatedLanguages = [...subaddonlist];
    updatedLanguages[index]["newlangsub"] = selectedOption;
    if (!updatedLanguages.some((item) => item.lang_code === selectedOption)) {
      updatedLanguages[index].multi_lang.push({
        lang_code: selectedOption,
        title: "",
      });
    }
    setSubaddonlist(updatedLanguages);
  };

  const handleAddonChangesub = (index, addonValue) => {
    const updatedList = [...subaddonlist];
    const matchingIndex = updatedList[index].multi_lang.findIndex(
      (language) => language.lang_code === subaddonlist[index].newlangsub
    );
    if (matchingIndex !== -1) {
      updatedList[index]["multi_lang"][matchingIndex]["title"] = addonValue;
    } else {
      const newLanguage = { lang_code: subaddonlist[index].newlangsub };
      newLanguage["title"] = addonValue;
      updatedList[index]["multi_lang"].push(newLanguage);
    }
    setSubaddonlist(updatedList);
  };

  const handlelanglo = (val) => {
    setLoyallang(val);
    const updatedList = [...otherloyal];
    for (let i = 0; i < updatedList.length; i++) {
      if (!updatedList[i].lang.some((item) => item.lang_code === val)) {
        updatedList[i].lang.push({ lang_code: val, loyalty_lang_name: "" });
      }
    }
    setOtherloyal(updatedList);
  };

  const hangleotherloyallang = (index, val, name) => {
    const updatedList = [...otherloyal];
    const matchingIndex = updatedList[index].lang.findIndex(
      (language) => language.lang_code === loyallang
    );
    if (matchingIndex !== -1) {
      updatedList[index]["lang"][matchingIndex][name] = val;
    } else {
      const newLanguage = { lang_code: loyallang };
      newLanguage[name] = val;
      updatedList[index].lang.push(newLanguage);
    }
    setOtherloyal(updatedList);
  };
  //step2

  const getListall = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/extra/all`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        // setExtra2Board(data.data[0].hotel_board);
        setNewboard(data.data[0].hotel_board);
        setExtra2Room(data.data[0].rooms_occupancies);
        setExtra2Ratelist(data.data[0].rate_lists);
        setCurrency(data.data[0].currency);
        setLanguage(data.data[0].optional_language);
        setOptionimage(data.data[0].option_images);
        setOffers(data.data[0].offers);
        setLoyalty(data.data[0].inclusions);
        setLoyaltyclublist(data.data[0].loyalty_club);
        setAlllanguage(data.data[0].optional_language);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getListall();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getListall();
    } else {
      console.log("error");
    }
  };
  const handlesingleroomselect = (i, isChecked) => {
    const updatedList = [...extra2Room];
    const updatedroomocc = [...selectedroomocc];
    updatedList[i]["selected"] = isChecked;
    const updatedboard = [...extra2Board];
    if (isChecked === true) {
      if (availableboard.length > 0) {
        const updatedAvailableBoard = [...availableboard];

        updatedList[i].display_boards.forEach((newItem) => {
          const newItemId = newItem;
          const existingItem = updatedAvailableBoard.find(
            (item) => item.id === newItemId
          );
          if (existingItem) {
            existingItem.count += existingItem.count;
          } else {
            updatedAvailableBoard.push({ id: newItemId, count: 1 });
          }

          const existingboard = updatedboard.find(
            (item) => item.value === newItemId
          );
          if (!existingboard) {
            for (let i = 0; i < newboard.length; i++) {
              if (newboard[i].value === newItemId) {
                updatedboard.push({
                  value: newItemId,
                  label: newboard[i].label,
                });
              }
            }
          }
        });

        setAvailbleboard(updatedAvailableBoard);
        setExtra2Board(updatedboard);
      } else {
        const newAvailableBoard = updatedList[i].display_boards.map(
          (newItem) => ({
            id: newItem,
            count: 1,
          })
        );
        setAvailbleboard(newAvailableBoard);
      }
      updatedroomocc.push({
        key: updatedList[i].key,
        label: updatedList[i].label,
        value: updatedList[i].value,
      });
      setSelectedroomocc(updatedroomocc);

      updatedList[i].display_boards.forEach((newItem) => {
        const newItemId = newItem;
        const existingboard = updatedboard.find(
          (item) => item.value === newItemId
        );
        if (!existingboard) {
          for (let i = 0; i < newboard.length; i++) {
            if (newboard[i].value === newItemId) {
              updatedboard.push({ value: newItemId, label: newboard[i].label });
            }
          }
        }
        setExtra2Board(updatedboard);
      });
    } else {
      updatedList[i]["based_on"] = "";
      updatedList[i]["all_label"] = false;
      if (availableboard.length > 0) {
        const updatedAvailableBoard = [...availableboard];
        updatedList[i]?.display_boards?.forEach((newItem) => {
          const newItemId = newItem;
          const existingItem = updatedAvailableBoard.find(
            (item) => item.id === newItemId
          );
          if (existingItem) {
            // Decrease the count when isChecked is false
            existingItem.count -= 1;

            // If count is zero or less, remove the item
            if (existingItem.count <= 0) {
              updatedAvailableBoard.splice(
                updatedAvailableBoard.indexOf(existingItem),
                1
              );
              const existingboard = updatedboard.find(
                (item) => item.value === newItemId
              );
              updatedboard.splice(updatedboard.indexOf(existingboard), 1);
            }
            setExtra2Board(updatedboard);
          } else {
            console.error("Item not found with id:", newItemId);
          }
        });

        setAvailbleboard(updatedAvailableBoard);
      } else {
        const updatedAvailableBoard = [...availableboard];

        updatedList[i]?.display_board?.forEach((newItem) => {
          const newItemId = newItem;
          const existingIteml = updatedAvailableBoard.find(
            (item) => item.id === newItemId
          );
          if (existingIteml) {
            // Decrease the count when isChecked is false
            existingIteml.count -= 1;

            // If count is zero or less, remove the item
            if (existingIteml.count <= 0) {
              const existingboard = updatedboard.find(
                (item) => item.value === newItemId
              );
              updatedboard.splice(updatedboard.indexOf(existingboard), 1);
            }
            setExtra2Board(updatedboard);
          } else {
            console.error("Item not found with id:", newItemId);
          }
        });
      }
      const updatedroomoccFiltered = updatedroomocc.filter((item) => {
        return item.key !== updatedList[i].key;
      });

      setSelectedroomocc(updatedroomoccFiltered);
    }
    updatedList[i].occupancies.forEach((occ) => {
      occ.selected = false;
    });

    setExtra2Room(updatedList);

    if (isChecked === false) {
      setActiveroom(isChecked);
    } else {
      const hasSelectedFalse = updatedList.some(
        (item) => item.selected === false || !("selected" in item)
      );
      if (!hasSelectedFalse) {
        setActiveroom(true);
      }
    }
  };
  // const handlesingleroomselect = (i, isChecked) => {
  //   const updatedList = [...extra2Room];
  //   const updatedroomocc = [...selectedroomocc];
  //   updatedList[i]["selected"] = isChecked;
  //   if (isChecked === true) {
  //     if (availableboard.length > 0) {
  //       const updatedAvailableBoard = [...availableboard];

  //       updatedList[i].display_boards.forEach((newItem) => {
  //         const newItemId = newItem;
  //         const existingItem = updatedAvailableBoard.find(
  //           (item) => item.id === newItemId
  //         );
  //         if (existingItem) {
  //           existingItem.count += existingItem.count;
  //         } else {
  //           updatedAvailableBoard.push({ id: newItemId, count: 1 });
  //         }
  //       });

  //       setAvailbleboard(updatedAvailableBoard);
  //     } else {
  //       const newAvailableBoard = updatedList[i].display_boards.map(
  //         (newItem) => ({
  //           id: newItem,
  //           count: 1,
  //         })
  //       );
  //       setAvailbleboard(newAvailableBoard);
  //     }
  //     updatedroomocc.push({
  //       key: updatedList[i].key,
  //       label: updatedList[i].label,
  //       value: updatedList[i].value,
  //     });
  //     setSelectedroomocc(updatedroomocc);
  //   } else {
  //     updatedList[i]["room_type"] = "";
  //     updatedList[i]["all_label"] = false;
  //     if (availableboard.length > 0) {
  //       const updatedAvailableBoard = [...availableboard];

  //       updatedList[i].display_boards.forEach((newItem) => {
  //         const newItemId = newItem;
  //         const existingItem = updatedAvailableBoard.find(
  //           (item) => item.id === newItemId
  //         );

  //         if (existingItem) {
  //           existingItem.count -= 1;
  //           if (existingItem.count <= 0) {
  //             updatedAvailableBoard.splice(
  //               updatedAvailableBoard.indexOf(existingItem),
  //               1
  //             );
  //           }
  //         } else {
  //           console.error("Item not found with id:", newItemId);
  //         }
  //       });
  //       setAvailbleboard(updatedAvailableBoard);
  //     } else {
  //       console.error("Availableboard is empty");
  //     }

  //     const updatedroomoccFiltered = updatedroomocc.filter((item) => {
  //       return item.key !== updatedList[i].key;
  //     });

  //     setSelectedroomocc(updatedroomoccFiltered);
  //   }
  //   updatedList[i].occupancies.forEach((occ) => {
  //     occ.selected = false;
  //   });

  //   setExtra2Room(updatedList);

  //   if (isChecked === false) {
  //     setActiveroom(isChecked);
  //   } else {
  //     const hasSelectedFalse = updatedList.some(
  //       (item) => item.selected === false || !("selected" in item)
  //     );
  //     if (!hasSelectedFalse) {
  //       setActiveroom(true);
  //     }
  //   }
  // };

  const handlesingleoccupancyselect = (index, indx, isChecked) => {
    const updatedList = [...extra2Room];
    const updatedroomocc = [...selectedroomocc];
    if (isChecked === true) {
      //occupancy id remove to new list
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          if (!Array.isArray(updatedroomocc[i].occupancies)) {
            updatedroomocc[i].occupancies = [];
          }
          updatedroomocc[i].occupancies.push(
            updatedList[index].occupancies[indx]
          );
          break; // Exit the loop after finding and updating the matching room
        }
      }
      //room select after board select
      if (extra2Board.find((item) => item.selected === true)) {
        updatedList.forEach((newone) => {
          extra2Board.forEach((newItem) => {
            for (let j = 0; j < updatedroomocc.length; j++) {
              if (newone.value === updatedroomocc[j].value) {
                if (newone.display_boards.includes(newItem.value)) {
                  for (
                    let k = 0;
                    k < updatedroomocc[j].occupancies.length;
                    k++
                  ) {
                    if (!updatedroomocc[j].occupancies[k].boards) {
                      updatedroomocc[j].occupancies[k].boards = [];
                    }
                    updatedroomocc[j].occupancies[k].boards.push({
                      hotel_board: newItem.hotel_borad,
                      label: newItem.label,
                      value: newItem.value,
                    });
                  }
                }
              }
            }
          });
        });
      }
      setSelectedroomocc(updatedroomocc);
    } else {
      //occupancy id remove to new list
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          updatedroomocc[i].occupancies = updatedroomocc[i].occupancies.filter(
            (item) =>
              item.occupancy_id !==
              updatedList[index].occupancies[indx].occupancy_id
          );
          break;
        }
      }
      setSelectedroomocc(updatedroomocc);
    }
    updatedList[index]["occupancies"][indx]["selected"] = isChecked;

    if (isChecked === false) {
      updatedList[index]["all_label"] = isChecked;
    } else {
      const hasSelectedFalse = updatedList[index].occupancies.some(
        (item) => item.selected === false || !("selected" in item)
      );
      if (!hasSelectedFalse) {
        updatedList[index]["all_label"] = isChecked;
      }
    }
    setExtra2Room(updatedList);
  };

  const handleChangeroomExtra2 = (isChecked) => {
    setActiveroom(isChecked);
    const updatedList = [...extra2Room];
    const updatedAvailableBoard = [...availableboard];
    const updatedroomocc = [...selectedroomocc];
    const updatedboard = [...extra2Board];

    if (isChecked === true) {
      setActiveboard([]);

      updatedList.forEach((newone) => {
        newone.display_boards.forEach((newItem) => {
          const newItemId = newItem;
          const existingItem = updatedAvailableBoard.find(
            (item) => item.id === newItemId
          );
          if (existingItem) {
            existingItem.count += existingItem.count;
          } else {
            updatedAvailableBoard.push({ id: newItemId, count: 1 });
          }

          const existingboard = updatedboard.find(
            (item) => item.value === newItemId
          );
          if (!existingItem) {
            for (let i = 0; i < newboard.length; i++) {
              if (newboard[i].value === newItemId) {
                updatedboard.push({
                  value: newItemId,
                  label: newboard[i].label,
                });
              }
            }
          }
          setExtra2Board(updatedboard);
        });

        updatedroomocc.push({
          key: newone.key,
          label: newone.label,
          value: newone.value,
        });
      });

      setAvailbleboard(updatedAvailableBoard);
      setSelectedroomocc(updatedroomocc);
    } else {
      setAvailbleboard([]);
      setSelectedroomocc([]);
      setExtra2Board([]);
    }
    updatedList.forEach((room) => {
      room.selected = isChecked;
    });
    setExtra2Room(updatedList);
  };

  // const handleChangeroomExtra2 = (isChecked) => {
  //   setActiveroom(isChecked);
  //   const updatedList = [...extra2Room];
  //   const updatedAvailableBoard = [...availableboard];
  //   const updatedroomocc = [...selectedroomocc];

  //   if (isChecked === true) {
  //     setActiveboard([]);

  //     updatedList.forEach((newone) => {
  //       newone["room_type"] = "";
  //       newone.display_boards.forEach((newItem) => {
  //         const newItemId = newItem;
  //         const existingItem = updatedAvailableBoard.find(
  //           (item) => item.id === newItemId
  //         );
  //         if (existingItem) {
  //           existingItem.count += existingItem.count;
  //         } else {
  //           updatedAvailableBoard.push({ id: newItemId, count: 1 });
  //         }
  //       });
  //       updatedroomocc.push({
  //         key: newone.key,
  //         label: newone.label,
  //         value: newone.value,
  //       });
  //     });
  //     setAvailbleboard(updatedAvailableBoard);
  //     setSelectedroomocc(updatedroomocc);
  //   } else {
  //     updatedList.forEach((room) => {
  //       room.room_type = "";
  //     });
  //     setAvailbleboard([]);
  //     setSelectedroomocc([]);
  //   }
  //   updatedList.forEach((room) => {
  //     room.selected = isChecked;
  //   });
  //   setExtra2Room(updatedList);
  // };

  const handlebasedon = (index, val) => {
    const updatedroomocc = [...selectedroomocc];
    const updatedList = [...extra2Room];

    updatedList[index]["room_type"] = val;

    if (val === "per_room") {
      updatedList[index]["all_label"] = false;
      updatedList[index].occupancies.forEach((occ) => {
        occ.selected = false;
      });
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          updatedroomocc[i]["room_type"] = val;

          if (!Array.isArray(updatedroomocc[i].occupancies)) {
            updatedroomocc[i].occupancies = [];
          }

          for (let j = 0; j < updatedList[index].occupancies.length; j++) {
            if (
              !updatedroomocc[i].occupancies.some(
                (item) =>
                  item.occupancy_id ===
                  updatedList[index].occupancies[j].occupancy_id
              )
            ) {
              updatedroomocc[i].occupancies.push(
                updatedList[index].occupancies[j]
              );
            }
          }
        }
      }
      setSelectedroomocc(updatedroomocc);
    } else {
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          updatedroomocc[i]["room_type"] = val;

          updatedroomocc[i].occupancies = [];
        }
      }
      updatedList[index].all_label = false;
      updatedList[index].occupancies.forEach((occ) => {
        occ.selected = false;
      });
    }
    setExtra2Room(updatedList);
  };

  const handleChangeroomextra2all_label = (index, isChecked) => {
    const updatedList = [...extra2Room];
    const updatedroomocc = [...selectedroomocc];

    updatedList[index]["all_label"] = isChecked;
    if (isChecked === true) {
      updatedList[index].occupancies.forEach((occ) => {
        occ.selected = true;
      });

      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          if (!Array.isArray(updatedroomocc[i].occupancies)) {
            updatedroomocc[i].occupancies = [];
          }
          for (let j = 0; j < updatedList[index].occupancies.length; j++) {
            updatedroomocc[i].occupancies.push(
              updatedList[index].occupancies[j]
            );
          }
        }
      }
    } else {
      updatedList[index].occupancies.forEach((occ) => {
        occ.selected = false;
      });

      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          updatedroomocc[i].occupancies = [];
        }
      }
    }
    setExtra2Room(updatedList);
    setSelectedroomocc(updatedroomocc);
  };

  let handleOfferstatusToggle = (e) => {
    let value1 = e.target.value;
    let name = e.target.name;

    if (value1 === "false") {
      const value = false;
      setExtra({ ...extra, [name]: value });
    } else if (value1 === "true") {
      const value = true;
      setExtra({ ...extra, [name]: value });
    }
  };

  const nextextrastep2 = () => {
    let isFormValid = false;
    let isFormValid1 = true;
    let isFormValid2 = true;
    let isFormValid3 = true;

    const errors = {};

    const hasSelectedRoom = extra2Room.some((item) => item.selected === true);

    if (hasSelectedRoom) {
      isFormValid = true;

      for (let i = 0; i < extra2Room.length; i++) {
        if (extra2Room[i].selected === true) {
          for (let j = 0; j < extra2Room[i].occupancies.length; j++) {
            if (extra2Room[i].room_type === "per_person") {
              if (extra2Room[i].occupancies[j].selected === true) {
                isFormValid = true;
              }
            } else if (extra2Room[i].room_type === "per_room") {
              isFormValid = true;
            } else {
              isFormValid = false;
            }
          }
        }
      }
    }

    if (!isFormValid) {
      errors.room = "Please select one room and occupancy!";
    }

    if (extra.only_offer === false) {
      if (extraBoardnew.length === 0) {
        isFormValid1 = false;
        errors.extraBoardnew = "Please select any board!";
      }
    }
    if (extra.only_offer === false) {
      if (extraRatelistnew.length === 0) {
        isFormValid2 = false;
        errors.extraRatelistnew = "Please select any rate!";
      }
    }
    if (extra.only_offer === true) {
      if (extraOffernew.length === 0) {
        isFormValid3 = false;
        errors.extraOffernew = "Please select any offer!";
      }
    }
    setExtra2error(errors);

    if (
      isFormValid === true &&
      isFormValid1 === true &&
      isFormValid2 === true &&
      isFormValid3 === true
    ) {
      setTab("step3");
      setExtra2valid(true);
    }
  };

  //step3

  const handleOptionChangeExtrastep3 = (val, name) => {
    setExtra({ ...extra, [name]: val });
  };

  const handlechangeStep3 = (e) => {
    setExtra({ ...extra, [e.target.name]: e.target.value });
  };
  const handleExtra3Check = (isChecked, name) => {
    setExtra({ ...extra, [name]: isChecked });
  };

  //step4

  const handleExtra4Check = (isChecked, name) => {
    setExtra({ ...extra, [name]: isChecked });
  };

  // const handleloyalchangeExtra4 = (index, isChecked, value) => {
  //   const updatedBoardTog = [...loyalty_club];
  //   const updateloyal = [...Loyaltyclublist];
  //   if (isChecked) {
  //     if (!updatedBoardTog?.some((item) => item === value)) {
  //       updatedBoardTog.push(value);
  //     }
  //   } else {
  //     if (updatedBoardTog?.some((item) => item === value)) {
  //       updatedBoardTog = updatedBoardTog.filter((item) => item !== value);
  //     }
  //   }
  //   setLoyalty_club(updatedBoardTog);
  // };

  const handleloyalchangeExtra4 = (index, isChecked, value) => {
    const updatedLoyaltyClub = [...loyalty_club];
    
    if (isChecked && !updatedLoyaltyClub.includes(value)) {
      updatedLoyaltyClub.push(value);
    } else {
      const indexToRemove = updatedLoyaltyClub.indexOf(value);
      if (indexToRemove !== -1) {
        updatedLoyaltyClub.splice(indexToRemove, 1);
      }
    } 
    setLoyalty_club(updatedLoyaltyClub);
  };
  
  console.log(loyalty_club);

  const handlePriceChangeStep3 = (index, event) => {
    const updatedGuests = [...numberOfGuests];
    updatedGuests[index] = { price: event.target.value };
    setNumberOfGuests(updatedGuests);
  };

  const handlechangeSelectStep4 = (e) => {
    setExtra({ ...extra, [e.target.name]: e.target.value });
  };

  const handlechangeStep4 = (e) => {
    setExtra({ ...extra, [e.target.name]: e.target.value });
  };

  //step5

  const handlechangeExtra4 = (e) => {
    setExtra({ ...extra, [e.target.name]: e.target.value });
  };
  const onFileChange = (e) => {
    setImage(e.target.file[0]);
  };

  const Update = async () => {
    const formData = new FormData();
    formData.append("hotel_id", id);
    formData.append("title", extraname);
    formData.append("extra_id", extra.extra_id);
    if (extra.subtitle) {
      formData.append("subtitle", extra.subtitle);
    } else {
      formData.append("subtitle", "");
    }
    if (extra.description) {
      formData.append("description", extra.description);
    } else {
      formData.append("description", "");
    }
    if (extra.condition) {
      formData.append("extra_condition", extra.condition);
    } else {
      formData.append("extra_condition", "");
    }
    if (extra.status === true) {
      formData.append("status", 1);
    } else {
      formData.append("status", 0);
    }

    if (extra.sub_addon_status === true) {
      for (let i = 0; i < subaddonlist.length; i++) {
        formData.append("sub_addon_status", 1);
        formData.append(
          `sub_addons[${i}][title]`,
          subaddonlist[i].sub_addon.title
        );
        if (subaddonlist[i].sub_addon.sub_addon_id) {
          formData.append(
            `sub_addons[${i}][id]`,
            subaddonlist[i].sub_addon.sub_addon_id
          );
        } else {
          formData.append(`sub_addons[${i}][id]`, "");
        }
        formData.append(
          `sub_addons[${i}][price_type]`,
          subaddonlist[i].sub_addon.sub_addon_price_type
        );

        if (
          subaddonlist[i].sub_addon.sub_addon_price_type === "room_once" ||
          subaddonlist[i].sub_addon.sub_addon_price_type === "reserve_once"
        ) {
          formData.append(
            `sub_addons[${i}][sub_price]]`,
            subaddonlist[i].sub_addon.sub_addon_price
          );
        }
        if (
          subaddonlist[i].sub_addon.sub_addon_price_type === "room_per_night"
        ) {
          formData.append(
            `sub_addons[${i}][sub_price]`,
            subaddonlist[i].sub_addon.sub_addon_price
          );
          if (subaddonlist[i].sub_addon.client_preference_status === true) {
            formData.append(`sub_addons[${i}][client_preference_status]`, 1);
          } else {
            formData.append(`sub_addons[${i}][client_preference_status]`, 0);
          }
        }
        if (subaddonlist[i].sub_addon.sub_addon_price_type === "person_once") {
          formData.append(
            `sub_addons[${i}][sub_price]`,
            subaddonlist[i].sub_addon.sub_addon_price
          );
          formData.append(
            `sub_addons[${i}][price_age_category]`,
            subaddonlist[i].sub_addon.price_age_category
          );
        }
        if (
          subaddonlist[i].sub_addon.sub_addon_price_type === "person_per_night"
        ) {
          formData.append(
            `sub_addons[${i}][sub_price]`,
            subaddonlist[i].sub_addon.sub_addon_price
          );
          formData.append(
            `sub_addons[${i}][price_age_category]`,
            subaddonlist[i].sub_addon.price_age_category
          );
          if (subaddonlist[i].sub_addon.client_preference_status === true) {
            formData.append(`sub_addons[${i}][client_preference_status]`, 1);
          } else {
            formData.append(`sub_addons[${i}][client_preference_status]`, 0);
          }
        }
        if (
          subaddonlist[i].sub_addon.sub_addon_price_type === "person_range_once"
        ) {
          let count = 0;
          for (
            let k = 0;
            k < subaddonlist[i].sub_addon.person_range_price.length;
            k++
          ) {
            if (subaddonlist[i].sub_addon.person_range_price[k].price) {
              formData.append(
                `sub_addons[${i}][person_range_price][${count}][price]`,
                subaddonlist[i].sub_addon.person_range_price[k].price
              );
              formData.append(
                `sub_addons[${i}][person_range_price][${count}][person]`,
                k + 1
              );
              count += 1;
            }
          }
        }
        if (
          subaddonlist[i].sub_addon.sub_addon_price_type === "reserve_per_night"
        ) {
          formData.append(
            `sub_addons[${i}][sub_price]`,
            subaddonlist[i].sub_addon.sub_addon_price
          );
          if (subaddonlist[i].sub_addon.client_preference_status === true) {
            formData.append(`sub_addons[${i}][client_preference_status]`, 1);
          } else {
            formData.append(`sub_addons[${i}][client_preference_status]`, 0);
          }
        }
        for (let j = 0; j < subaddonlist[i]?.multi_lang?.length; j++) {
          if (subaddonlist[i]?.multi_lang[j]?.title) {
            formData.append(
              `sub_addons[${i}][multi_lang][${j}][lang_code]`,
              subaddonlist[i].multi_lang[j].lang_code
            );
            formData.append(
              `sub_addons[${i}][multi_lang][${j}][sub_addon_title]`,
              subaddonlist[i].multi_lang[j].title
            );
          }
        }
      }
    } else {
      formData.append("sub_addon_status", 0);
    }

    if (extra.extra_include_status === true) {
      formData.append("inclusion_add_status", 1);
      let count = 0;
      for (let i = 0; i < loyalty.length; i++) {
        if (loyalty[i].selected && loyalty[i].selected === true) {
          formData.append(`inclusions[${count}]`, loyalty[i].value);
          count += 1;
        }
      }

      if (otherloyal_status === true) {
        formData.append("extra_include_list_other_status", 1);
        formData.append("extra_include_list_other_language_code", loyallang);
        for (let i = 0; i < otherloyal.length; i++) {
          for (let j = 0; j < otherloyal[i].lang.length; j++) {
            if (otherloyal[i].loyalty_name) {
              formData.append(
                `extra_include_other[${i}][title]`,
                otherloyal[i].loyalty_name
              );
            }
            if (otherloyal[i].lang[j].loyalty_lang_name) {
              formData.append(
                `extra_include_other[${i}][multi_lang][${j}][lang_code]`,
                otherloyal[i].lang[j].lang_code
              );
              formData.append(
                `extra_include_other[${i}][multi_lang][${j}][title]`,
                otherloyal[i].lang[j].loyalty_lang_name
              );
            }
          }
        }
      }
    } else {
      formData.append("inclusion_add_status", 0);
    }
    for (let i = 0; i < multi.length; i++) {
      if (multi[i].title) {
        formData.append(`multi_lang[${i}][lang_code]`, multi[i].lang_code);
        formData.append(`multi_lang[${i}][title]`, multi[i].title);
      }
    }

    for (let i = 0; i < multilang?.length; i++) {
      if (
        multilang[i].subtitle ||
        multilang[i].description ||
        multilang[i].extra_condition
      ) {
        formData.append(`multi_lang[${i}][lang_code]`, multilang[i].lang_code);
        if (multilang[i].subtitle) {
          formData.append(`multi_lang[${i}][subtitle]`, multilang[i].subtitle);
        } else {
          formData.append(`multi_lang[${i}][subtitle]`, "");
        }
        if (multilang[i].description) {
          formData.append(
            `multi_lang[${i}][description]`,
            multilang[i].description
          );
        } else {
          formData.append(`multi_lang[${i}][description]`, "");
        }
        if (multilang[i].condition) {
          formData.append(
            `multi_lang[${i}][extra_condition]`,
            multilang[i].condition
          );
        } else {
          formData.append(`multi_lang[${i}][extra_condition]`, "");
        }
      }
    }

    formData.append("provider", extra.provider);
    if (extra.provider === "external") {
      formData.append("notify_email", extra.notifyEmail);
    }
    if (extra.availabilty_status === true) {
      formData.append("availabilty_status", 1);
      formData.append("availabilty_type", extra.availabilty_type);
    } else {
      formData.append("availabilty_status", 0);
    }
    if (image) {
      if (image instanceof File) {
        formData.append("image", image);
      } else {
        formData.append("choosen_image", image);
      }
    } else {
      formData.append("choosen_image", "");
    }
    if (extra.availabilty_status === true) {
      for (let i = 0; i < selectedDatesstay.length; i++) {
        const formattedDate = moment(selectedDatesstay[i]).format("DD-MM-YYYY");
        formData.append(`reserve_dates[${i}]`, formattedDate);
      }
    }

    //   for(let i=0;i<selectedroomocc.length;i++){
    //     formData.append(`rooms[${i}][room]`,selectedroomocc[i].value );
    //     for(let j=0;j<selectedroomocc[i].occupancies.length;j++){
    //        formData.append(`rooms[${i}][occupancy][${j}][occupancy_id]`,selectedroomocc[i].occupancies[j].occupancy_id );
    //   }
    //   formData.append(`rooms[${i}][type]`,selectedroomocc[i].based_on );

    // }
    for (let i = 0; i < selectedroomocc?.length; i++) {
      formData.append(`rooms[${i}][room]`, selectedroomocc[i].value);
      for (let j = 0; j < selectedroomocc[i]?.occupancies?.length; j++) {
        formData.append(
          `rooms[${i}][occupancy][${j}][occupancy_id]`,
          selectedroomocc[i].occupancies[j].occupancy_id
        );
      }
      formData.append(`rooms[${i}][type]`, selectedroomocc[i].room_type);
    }

    let count = 0;
    for (let i = 0; i < extraBoardnew.length; i++) {
      formData.append(`boards[${count}]`, extraBoardnew[i].value);
      count += 1;
    }

    let count1 = 0;
    for (let i = 0; i < extraRatelistnew.length; i++) {
      formData.append(`rates[${count1}]`, extraRatelistnew[i].value);
      count1 += 1;
    }
    if (extraOffernew.length > 0) {
      let count2 = 0;
      for (let i = 0; i < extraOffernew.length; i++) {
        formData.append(`offers[${count2}]`, extraOffernew[i].value);
        count2 += 1;
      }
    }
    if (extra.only_offer === true) {
      formData.append(`only_offer`, 1);
    } else {
      formData.append(`only_offer`, 0);
    }

    formData.append("price_type", extra.extra_price_type);
    if (
      extra.extra_price_type === "room_once" ||
      extra.extra_price_type === "reserve_once"
    ) {
      formData.append("extra_price", extra.extra_price);
    }
    if (extra.extra_price_type === "room_per_night") {
      formData.append("extra_price", extra.extra_price);
      if (extra.client_preference_status === true) {
        formData.append("client_preference_status", 1);
      } else {
        formData.append("client_preference_status", 0);
      }
    }
    if (extra.extra_price_type === "person_once") {
      formData.append("extra_price", extra.extra_price);
      formData.append("price_age_category", extra.price_age_category);
    }
    if (extra.extra_price_type === "person_per_night") {
      formData.append("extra_price", extra.extra_price);
      formData.append("price_age_category", extra.price_age_category);
      if (extra.client_preference_status === true) {
        formData.append("client_preference_status", 1);
      } else {
        formData.append("client_preference_status", 0);
      }
    }
    if (extra.extra_price_type === "person_range_once") {
      let count = 0;
      for (let i = 0; i < numberOfGuests.length; i++) {
        if (numberOfGuests[i].price) {
          formData.append(
            `person_range_price[${count}][price]`,
            numberOfGuests[i].price
          );
          formData.append(`person_range_price[${count}][person]`, i + 1);
          count += 1;
        }
      }
    }
    if (extra.extra_price_type === "reserve_per_night") {
      formData.append("extra_price", extra.extra_price);
      if (extra.client_preference_status === true) {
        formData.append("client_preference_status", 1);
      } else {
        formData.append("client_preference_status", 0);
      }
    }
    if (extra.min_stay_status === true) {
      formData.append("min_stay_status", 1);

      if (extra.min_stay_days) {
        formData.append("min_stay_days", extra.min_stay_days);
      } else {
        formData.append("min_stay_days", 1);
      }
    } else {
      formData.append("min_stay_status", 0);
    }
    if (extra.max_stay_status === true) {
      formData.append("max_stay_status", 1);

      if (extra.max_stay_days) {
        formData.append("max_stay_days", extra.max_stay_days);
      } else {
        formData.append("max_stay_days", 1);
      }
    } else {
      formData.append("max_stay_status", 0);
    }
    if (extra.days_in_advance_status === true) {
      formData.append("days_in_advance_status", 1);
      formData.append("days_in_advance", extra.days_in_advance);
    } else {
      formData.append("days_in_advance_status", 0);
    }
   
    if (extra.loyality_club_status === true) {
      formData.append("loyalty_active_status", 1);
      let count = 0;
      for (let i = 0; i < loyalty_club.length; i++) {
        if (loyalty_club[i] !== "undefined") {
          formData.append(`loyality_clubs[${count}]`, loyalty_club[i]);
          count += 1;
        }
      }
    } else {
      formData.append("loyalty_active_status", 0);
    }

    if (removeid.length > 0) {
      for (let l = 0; l < removeid.length; l++) {
        if (removeid[l] !== undefined) {
          formData.append(`remove_sub_addon[${l}]`, removeid[l]);
        }
      }
    }

    axios
      .post(`${Config.apiurl}hotel/extra/update`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((res) => {
        if (res.status_code !== "200") {
          setToastmodal(true);
          setToastmessage(res.message);
          setToasttype("error");
          setToastlarge(true);
        } else if (res.reauth === true) {
          handleUnauthorized();
          Update();
        } else {
          setToastmodal(true);
          setToastmessage(res.message);
          setToasttype("success");
          setToastlarge(true);
          setTimeout(() => navigate("/add-on"), 3000);
        }
        // if (res.status_code !== "200" || !res) {
        //   setToastmodal(true);
        //   setToastmessage(res.message);
        //   setToasttype("error");
        //   setToastlarge(true);
        // } else {
        //   setToastmodal(true);
        //   setToastmessage(res.message);
        //   setToasttype("success");
        //   setToastlarge(true);
        //   setTimeout(() => navigate("/add-on"), 3000);
        // }
      });
  };

  useEffect(() => {
    const newExtraRatelist = extra2Ratelist
      ?.filter((rate) => extraRatelist?.includes(rate.value))
      .map((rate) => ({
        value: rate.value,
        label: rate.label,
      }));

    setExtraRatelistnew(newExtraRatelist);
  }, [extraRatelist, extra2Ratelist]);

  useEffect(() => {
    const newExtraOffer = offers
      ?.filter((offer) => extraOffer?.includes(offer.value))
      .map((offer) => ({
        value: offer.value,
        offer_key: offer.offer_key,
        label: offer.label,
      }));

    setExtraOffernew(newExtraOffer);
  }, [extraOffer, offers]);

  useEffect(() => {
    getextra();
    getListall();
  }, []);

  useEffect(() => {
    const getWeekdays = (startDate, endDate) => {
      const weekdays = [];
      const currentDay = new Date(startDate);
      const lastDay = new Date(endDate);

      while (currentDay <= lastDay) {
        const dayOfWeek = currentDay.getDay();
        if (dayOfWeek >= 0 && dayOfWeek <= 6) {
          weekdays.push(dayOfWeek);
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }

      return weekdays;
    };

    const getWeekdaysBetweenSelectedDates = (datefromstay, datetostay) => {
      const weekdays = getWeekdays(datefromstay, datetostay);
      setSelectedWeekdaysstay(weekdays);
      setClickedDaysstay([]);
    };

    if (datefromstay && datetostay) {
      getWeekdaysBetweenSelectedDates(datefromstay, datetostay);
    } else {
      setSelectedWeekdaysstay([]);
    }
  }, [datefromstay, datetostay]);

  useEffect(() => {
    if (inclusionlist?.length > 0) {
      const updatedList = [...loyalty];
      const updateddis = [...inclusionlist];
      updatedList?.forEach((newone) => {
        const dis = updateddis?.find((item) => item === newone.value);
        if (dis) {
          newone.selected = true;
        }
      });
      setLoyalty(updatedList);
    }
  }, [loyalty?.length, inclusionlist?.length]);

  useEffect(() => {
    let newval;

    langall.forEach((room) => {
      if (room.title) {
        let ar = {};
        ar.label = room.label;
        ar.lang_code = room.lang_code;
        ar.value = room.value;
        ar.title = room.title;
        multi.push(ar);
        newval = room.lang_code;
      }
    });
    setNewLang(newval);
  }, [langall.length]);


  useEffect(() => {
    let newarray = [];
    let lang;
    if (subaddonlist) {
      const updatedList = [...subaddonlist];

      for (let i = 0; i < updatedList?.length; i++) {
        for (let j = 0; j < updatedList[i]?.multi_lang?.length; j++) {
          let ar = {};
          ar.label = updatedList[i]?.multi_lang[j].label;
          ar.lang_code = updatedList[i]?.multi_lang[j]?.lang_code;
          ar.title = updatedList[i]?.multi_lang[j]?.title;
          newarray.push(ar);
          lang = updatedList[i]?.multi_lang[j]?.lang_code;
        }
        updatedList[i].newlangsub = lang;
      }
    }
  }, [subaddonlist?.length]);

  useEffect(() => {
    const updatedList = [...extra2Room];
    const updatedroomocc = [...selectedroomocc];
    const roomlist = [...extraRoom];
    const Boards = [...extraBoard];
    const allboard = [...newboard];
    const updatedAvailableBoard = [...availableboard];
    roomlist.forEach((newone) => {
      updatedList.forEach((newoneroom) => {
        if (newone.room_id === newoneroom.value) {
          newoneroom.display_boards.forEach((newItem) => {
            const newItemId = newItem;
            const newid = Boards?.find((item) => item === newItemId);
            if (newid) {
              const existingItem = updatedAvailableBoard.find(
                (item) => item.id === newItemId
              );
              if (existingItem) {
                existingItem.count += existingItem.count;
              } else {
                updatedAvailableBoard.push({ id: newItemId, count: 1 });
              }
            } else {
              const existingItem = updatedAvailableBoard.find(
                (item) => item.id === newItemId
              );
              if (!existingItem) {
                updatedAvailableBoard.push({ id: newItemId, count: 1 });
              }
            }
          });
        }
      });
    });
    updatedList.forEach((newone) => {
      //room wise board

      // new selected room
      if (roomlist.find((item) => item.room_id === newone.value)) {
        newone.selected = true;
        roomlist.forEach((newroo) => {
          if (newroo.room_id === newone.value) {
            newone.room_type = newroo.room_type;
          }
        });
        updatedroomocc.push({
          key: newone.key,
          label: newone.label,
          value: newone.value,
          room_type: newone.room_type,
        });
        for (let i = 0; i < updatedroomocc?.length; i++) {
          for (let k = 0; k < roomlist?.length; k++) {
            for (let l = 0; l < roomlist[i]?.ocuupancies?.length; l++) {
              if (
                updatedroomocc[i]?.value === roomlist[k]?.room_id &&
                roomlist[k].room_id === newone.value
              ) {
                for (let n = 0; n < newone.occupancies.length; n++) {
                  if (
                    newone.occupancies[n].occupancy_id ===
                    roomlist[k].ocuupancies[l]
                  ) {
                    newone.occupancies[n].selected = true;
                    if (!Array.isArray(updatedroomocc[i].occupancies)) {
                      updatedroomocc[i].occupancies = [];
                    }
                    updatedroomocc[i].occupancies.push(newone.occupancies[n]);
                  }
                  if (
                    updatedroomocc[i]?.occupancies?.length ===
                    newone?.occupancies?.length
                  ) {
                    newone.all_label = true;
                  }
                }
              }
            }
          }
        }
      }

      setAvailbleboard(updatedAvailableBoard);
      setSelectedroomocc(updatedroomocc);
      // new board
      allboard.forEach((newItem) => {
        for (let j = 0; j < updatedroomocc.length; j++) {
          if (newone.value === updatedroomocc[j].value) {
            if (newone.display_boards.includes(newItem.value)) {
              for (let k = 0; k < updatedroomocc[j]?.occupancies?.length; k++) {
                for (let l = 0; l < Boards.length; l++) {
                  if (newItem.value === Boards[l]) {
                    if (!updatedroomocc[j].occupancies[k].boards) {
                      updatedroomocc[j].occupancies[k].boards = [];
                    }
                    updatedroomocc[j].occupancies[k].boards.push({
                      hotel_board: newItem.hotel_borad,
                      label: newItem.label,
                      value: newItem.value,
                    });
                    newItem.selected = true;
                  }
                }
              }
            }
          }
        }
      });

      setSelectedroomocc(updatedroomocc);
      setNewboard(allboard);
      setExtra2Board(allboard);
    });
    setExtra2Room(updatedList);
  }, [extraRoom?.length, extra2Room?.length]);

  useEffect(() => {
    const newExtraBoard = extra2Board
      ?.filter((board) => extraBoard?.includes(board.value))
      .map((board) => ({
        value: board.value,
        lang_code: board.lang_code,
        label: board.label,
        hotel_borad: board.hotel_borad,
      }));

    setExtraBoardnew(newExtraBoard);
  }, [extraBoard, extra2Board]);
  // useEffect(() => {
  //   const updatedList = [...extra2Room];
  //   const roomlist = [...extraRoom];
  //   const updatedroomocc = [...selectedroomocc];

  //   updatedList.forEach((newone) => {
  //     if (roomlist.find((item) => item.room_id === newone.value)) {
  //       newone.selected = true;
  //       roomlist.forEach((newroo) => {
  //         if (newroo.room_id === newone.value) {
  //           newone.room_type = newroo.room_type;
  //         }
  //       });
  //       updatedroomocc.push({
  //         key: newone.key,
  //         label: newone.label,
  //         value: newone.value,
  //         room_type: newone.room_type,
  //       });
  //       console.log(roomlist);
  //       console.log(updatedroomocc);
  //       for (let i = 0; i < updatedroomocc.length; i++) {
  //         for (let k = 0; k < roomlist.length; k++) {
  //           for (let l = 0; l < roomlist[k].ocuupancies.length; l++) {
  //             if (
  //               updatedroomocc[i].value === roomlist[k].room_id &&
  //               roomlist[k].room_id === newone.value
  //             ) {
  //               for (let n = 0; n < newone.occupancies.length; n++) {
  //                 if (
  //                   newone.occupancies[n].occupancy_id ===
  //                   roomlist[k].ocuupancies[l]
  //                 ) {
  //                   newone.occupancies[n].selected = true;
  //                   if (!Array.isArray(updatedroomocc[i].occupancies)) {
  //                     updatedroomocc[i].occupancies = [];
  //                   }
  //                   updatedroomocc[i].occupancies.push(newone.occupancies[n]);
  //                 }
  //                 if (
  //                   updatedroomocc[i]?.occupancies?.length ===
  //                   newone?.occupancies?.length
  //                 ) {
  //                   newone.all_label = true;
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //       console.log(newone);
  //     }
  //     //     for(let k=0;k<roomlist.length;k++){
  //     //       for(let l=0;l<roomlist[k].occupancy_id.length;l++){

  //     //         if(roomlist[k].room_id === newone.value) {

  //     //           for(let n=0;n<newone.occupancies.length;n++){
  //     //               if (newone.occupancies[n].occupancy_id === roomlist[k].occupancy_id[l]) {

  //     //                 newone.occupancies[n].selected=true;

  //     //               // if(roomlist[k]?.occupancies?.length===newone?.occupancies?.length){
  //     //               //   newone.all_label=true;
  //     //               // }
  //     //           }
  //     //         }
  //     //       }
  //     //     }

  //     // }
  //   });
  //   setExtra2Room(updatedList);
  //   setSelectedroomocc(updatedroomocc);
  // }, [extraRoom?.length, extra2Room?.length]);

  useEffect(() => {
    if (extraRoom?.length === extra2Room?.length) {
      setActiveroom(true);
    } else {
      setActiveroom(false);
    }
  }, [extraRoom?.length, extra2Room?.length]);

  useEffect(() => {
    if (extra.person_range_price && extra.person_range_price.length > 0) {
      const updatedGuests = numberOfGuests.map((guest, index) => ({
        price: extra.person_range_price[index]?.price || "",
      }));

      setNumberOfGuests(updatedGuests);
    }
  }, [extra.person_range_price]);

  return (
    <div className="main-content">
      <Commonheader />
      <div className="page-content">
        <div className="container-fluid">
          <div className="row project-wrapper">
            <div className="offer-list-main">
              <div className="offer-list-top">
                <div
                  className="offer-list-title"
                  style={{ marginLeft: "23px" }}>
                  <h6 className="offer-list-head">Update Add-on</h6>
                </div>
                <Link
                    type="button"
                    className="btn db-other-button"
                    to="/add-on"
                    style={{ marginRight: "1%" }}>
                    Add-on list
                  </Link>
              </div>
              <div className="col-md-1">
                <ul
                  className="offer-create-list block_by_block"
                  style={{ display: "contents" }}
                >
                  {extra1Valid === true ? (
                    <li
                      className={
                        tab === "step1"
                          ? "offer-list_new offer-list-dummy active"
                          : "offer-list_new offer-list-dummy"
                      }
                      onClick={(e) => setTab("step1")}
                    >
                      Step 1<div style={{ fontSize: "12px" }}>Availability</div>
                    </li>
                  ) : (
                    <li
                      className={
                        tab === "step1"
                          ? "offer-list_new offer-list-dummy active"
                          : "offer-list_new offer-list-dummy"
                      }
                    >
                      Step 1<div style={{ fontSize: "12px" }}>Availability</div>
                    </li>
                  )}

                  {extra1Valid === true ? (
                    <li
                      className={
                        tab === "step2"
                          ? "offer-list_new offer-list-dummy active"
                          : "offer-list_new offer-list-dummy"
                      }
                      onClick={(e) => setTab("step2")}
                    >
                      Step 2<div style={{ fontSize: "12px" }}>Room Types</div>
                    </li>
                  ) : (
                    <li
                      className={
                        tab === "step2"
                          ? "offer-list_new active offer-list-dummy"
                          : "offer-list_new offer-list-dummy"
                      }
                    >
                      Step 2<div style={{ fontSize: "12px" }}>Room Types</div>
                    </li>
                  )}

                  {extra1Valid === true && extra2valid === true ? (
                    <li
                      className={
                        tab === "step3"
                          ? "offer-list_new active offer-list-dummy dummy-delet"
                          : "offer-list_new offer-list-dummy dummy-delet"
                      }
                      onClick={(e) => setTab("step3")}
                    >
                      Step 3<div style={{ fontSize: "12px" }}>Marketing</div>
                    </li>
                  ) : (
                    <li
                      className={
                        tab === "step3"
                          ? "offer-list_new active offer-list-dummy dummy-delet"
                          : "offer-list_new offer-list-dummy dummy-delet"
                      }
                    >
                      {" "}
                      Step 3<div style={{ fontSize: "12px" }}>Marketing</div>
                    </li>
                  )}
                </ul>
              </div>

              {tab === "step1" ? (
                <Extraeditstep1
                  setTab={setTab}
                  extra={extra}
                  extraname={extraname}
                  setNumberOfGuests={setNumberOfGuests}
                  extraStep1={extraStep1}
                  handleExtraToggle={handleExtraToggle}
                  extraName={extraName}
                  setExtraName={setExtraName}
                  extra1Error={extra1Error}
                  handleChangeextraname={handleChangeextraname}
                  nextExtrastep1={nextExtrastep1}
                  handleExtraRadio={handleExtraRadio}
                  selectedDatesstay={selectedDatesstay}
                  setSelectedDatesstay={setSelectedDatesstay}
                  fpstay={fpstay}
                  handleOpenclosestay={handleOpenclosestay}
                  activestaydateopen={activestaydateopen}
                  setActivestaydateopen={setActivestaydateopen}
                  datefromstay={datefromstay}
                  datetostay={datetostay}
                  selectedWeekdaysstay={selectedWeekdaysstay}
                  clickedDaysstay={clickedDaysstay}
                  openclosestay={openclosestay}
                  setOpenclosestay={setOpenclosestay}
                  handleApplyToCalendarstay={handleApplyToCalendarstay}
                  handleCalendarChangeFrom={handleCalendarChangeFrom}
                  handleCalendarChangeTo={handleCalendarChangeTo}
                  setSelectedWeekdaysstay={setSelectedWeekdaysstay}
                  toggleSelectedDaystay={toggleSelectedDaystay}
                  language={language}
                  formerror={formerror}
                  selectedMulti={selectedMulti}
                  setselectedMulti={setselectedMulti}
                  handlechangeExtra1={handlechangeExtra1}
                  handleExtra4Check={handleExtra4Check}
                  Loyaltyclublist={Loyaltyclublist}
                  handleloyalchangeExtra4={handleloyalchangeExtra4}
                  handlechangeSelectStep4={handlechangeSelectStep4}
                  handlechangeStep4={handlechangeStep4}
                  multi={multi}
                  setMulti={setMulti}
                  nextExtrastep1sub={nextExtrastep1sub}
                  nextExtrastep1inc={nextExtrastep1inc}
                  addInputField={addInputField}
                  removeInputFields={removeInputFields}
                  inputFields={inputFields}
                  handleChangeslang={handleChangeslang}
                  handleLang={handleLang}
                  subAddonName={subAddonName}
                  setSubAddonName={setSubAddonName}
                  currency={currency}
                  numberOfGuests={numberOfGuests}
                  handleOptionChangeExtrastep1={handleOptionChangeExtrastep1}
                  handlechangeStep1={handlechangeStep1}
                  handleExtra1Check={handleExtra1Check}
                  handlePriceChangeStep1={handlePriceChangeStep1}
                  sub_addon_status={sub_addon_status}
                  setSubaddon_status={setSubaddon_status}
                  handleChangeSubaddon={handleChangeSubaddon}
                  extra_include_status={extra_include_status}
                  setExtra_include_status={setExtra_include_status}
                  loyalty={loyalty}
                  otherloyal_status={otherloyal_status}
                  setOtherloyal_status={setOtherloyal_status}
                  otherloyal={otherloyal}
                  addInputFieldinclusion={addInputFieldinclusion}
                  removeInputFieldinclusion={removeInputFieldinclusion}
                  alllanguage={alllanguage}
                  loyallang={loyallang}
                  setLoyallang={setLoyallang}
                  hangleotherloyal={hangleotherloyal}
                  handleloyalchange={handleloyalchange}
                  subaddonlist={subaddonlist}
                  inclusionlist={inclusionlist}
                  extraStep3={extraStep3}
                  setExtraStep3={setExtraStep3}
                  handleOptionChangeExtrastep3={handleOptionChangeExtrastep3}
                  handlechangeStep3={handlechangeStep3}
                  handleExtra3Check={handleExtra3Check}
                  handlePriceChangeStep3={handlePriceChangeStep3}
                  setCurrency={setCurrency}
                  handleExtraSubaddonToggle={handleExtraSubaddonToggle}
                  handleLanguageChange={handleLanguageChange}
                  handleAddonChange={handleAddonChange}
                  newLang={newLang}
                  handleLanguageChangesub={handleLanguageChangesub}
                  handleAddonChangesub={handleAddonChangesub}
                  hangleotherloyallang={hangleotherloyallang}
                  handlelanglo={handlelanglo}
                  numberOfguestsub={numberOfguestsub}
                  setMultilang={setMultilang}
                  tab2={tab2}
                  setTab2={setTab2}
                  handleOptionpriceExtrastep3={handleOptionpriceExtrastep3}
                  removeid={removeid}
                  loyalty_club={loyalty_club}
                />
              ) : tab === "step2" ? (
                <Extraeditstep2
                  setTab={setTab}
                  extraRoom={extraRoom}
                  extraBoard={extraBoard}
                  extraRatelist={extraRatelist}
                  extra2Room={extra2Room}
                  extra2Board={extra2Board}
                  extra2Ratelist={extra2Ratelist}
                  extra2error={extra2error}
                  handlesingleroomselect={handlesingleroomselect}
                  handlesingleoccupancyselect={handlesingleoccupancyselect}
                  handleChangeroomExtra2={handleChangeroomExtra2}
                  activeroom={activeroom}
                  activeboard={activeboard}
                  activerate={activerate}
                  nextextrastep2={nextextrastep2}
                  handlebasedon={handlebasedon}
                  handleChangeroomextra2all_label={
                    handleChangeroomextra2all_label
                  }
                  offers={offers}
                  extraOffer={extraOffer}
                  setExtraBoard={setExtraBoard}
                  setExtraRatelist={setExtraRatelist}
                  setExtraOffer={setExtraOffer}
                  extraBoardnew={extraBoardnew}
                  setExtraBoardnew={setExtraBoardnew}
                  extraRatelistnew={extraRatelistnew}
                  setExtraRatelistnew={setExtraRatelistnew}
                  extraOffernew={extraOffernew}
                  setExtraOffernew={setExtraOffernew}
                  handleOfferstatusToggle={handleOfferstatusToggle}
                  offerstatus={offerstatus}
                  extra={extra}
                />
              ) : // :tab==="step3"?
              // <Extraeditstep3 setTab={setTab}
              // extra={extra}
              // extraStep3={extraStep3}
              // setExtraStep3={setExtraStep3}
              // handleOptionChangeExtrastep3={handleOptionChangeExtrastep3}
              // handlechangeStep3={handlechangeStep3}
              // handleExtra3Check={handleExtra3Check}
              // numberOfGuests={numberOfGuests}
              // handlePriceChangeStep3={handlePriceChangeStep3}
              // currency={currency}
              // setCurrency={setCurrency}
              // />

              tab === "step3" ? (
                <Extraeditstep4
                  setTab={setTab}
                  extra={extra}
                  extraStep5={extraStep5}
                  language={language}
                  handlechangeExtra4={handlechangeExtra4}
                  onFileChange={onFileChange}
                  Update={Update}
                  image={image}
                  setImage={setImage}
                  imagechoose={imagechoose}
                  setImagechoose={setImagechoose}
                  optionimage={optionimage}
                  multilang={multilang}
                  setMultilang={setMultilang}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {toastmodal === true ? (
          <Toastmodal
            setToast={setToastmodal}
            message={toastmessage}
            type={toasttype}
            large={toastlarge}
          />
        ) : (
          ""
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Extraedit;
