import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../../Common/Footer";
import Privatesaleheader from "../../../Common/Privatesaleheader";
import Toastmodal from "../../../Common/Toastmodal";
import Select from "react-select";
import { useAuthHandling } from "../../../Common/useAuthHandling";
import Config from "../../../Config";

function Agenciescreate() {
  let navigate = useNavigate();
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  let params = useParams();
  const [id, setId] = useState(params.hotel_id ? params.hotel_id.split(',') : []);
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [list, setList] = useState({
    status: true,
    name: "",
    type: "",
    cigma_code: "",
    payment_type: "",
    branch: "",
    email: "",
    phone: "",
    manager: "",
    contact_person: "",
    address1: "",
    address2: "",
    address3: "",
    province: "",
    zip_code: "",
    vat_number: "",
    commision_type: "",
    commision: "",
  });
  const [formerror, setError] = useState({});
  const [country, setCountry] = useState([]);
  const [countrydata, setCountrydata] = useState([]);
  const [city, setCity] = useState([]);
  const [selectedcity, setSelectedcity] = useState([]);
  const [cityval, setCityval] = useState([]);

  const handleConditionChange = (name, value) => {
    const updatedValues = { ...list, [name]: value };
    setList(updatedValues);
  };

  const handleloyaltyToggle = (isChecked, name) => {
    setList({ ...list, [name]: isChecked });
  };

  const getcountry = async (e) => {
    const res = await fetch(`${Config.apiurl}country-list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setCountry(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getcountry();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getcountry();
    } else {
      console.log("error");
    }
  };

  function handleChangeselectcountry(selectedOption) {
    getcountrybasetime(selectedOption);
  }

  const getcountrybasetime = async (val) => {
    const res = await fetch(`${Config.apiurl}country-city/${val.value}`, {
      method: "GET",
    });
    const data1 = await res.json();
    const data = data1.data;
    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setCityval(data);
      setSelectedcity([]);
    }
    setCountrydata(val);
  };

  const Add = async () => {
    let isFormValid = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-]+$/;

    const errors = {};

    if (!list.name) {
      isFormValid = false;
      errors.name = "Name is required!";
    }
    else if (!noSpecialCharRegex.test(list.name)) {
      isFormValid = false;
      errors.name = "Name should not contain this special character";
    }
    else if (list.name.length > 50) {
      isFormValid = false;
      errors.name = `Name cannot be more than 50 characters!`;
    }

    if (!list.type) {
      isFormValid = false;
      errors.type = "Type is required!";
    }
    if (list.type === "travel_agency") {
      if (!list.cigma_code) {
        isFormValid = false;
        errors.cigma_code = "Code is required!";
      }
    }
    if (!list.payment_type) {
      isFormValid = false;
      errors.payment_type = "Payment type is required!";
    }
    if (!list.commission_type) {
      isFormValid = false;
      errors.commission_type = "Commission type is required!";
    }
    if (list.type === "travel_agency") {
      if (!list.commission) {
        isFormValid = false;
        errors.commission = "This field is required!";
      }
    }

    if (!list.email) {
      isFormValid = false;
      errors.email = "Email is required!";
    } else if (!regex.test(list.email)) {
      isFormValid = false;
      errors.email = "This is not a valid email format!";
    }
    if (!list.address_line1) {
      isFormValid = false;
      errors.address_line1 = "Address is required!";
    }
    if (!list.state) {
      isFormValid = false;
      errors.state = "State is required!";
    }

    if (countrydata.length === 0) {
      isFormValid = false;
      errors.countrydata = "Country is required!";
    }
    if (selectedcity.length === 0) {
      isFormValid = false;
      errors.selectedcity = "City is required!";
    }
    setError(errors);

    if (isFormValid === true) {
      const res = await fetch(
        `${Config.apiurl}private-sale/agencies/bulk-create`,
        {
          method: "POST",
          body: JSON.stringify({
            hotels: id,
            name: list.name,
            type: list.type,
            cigma_code: list.cigma_code,
            payment_type: list.payment_type,
            branch: list.branch,
            email: list.email,
            phone: list.phone,
            manager: list.manager,
            contact_person: list.contact_person,
            country: countrydata.value,
            address1: list.address_line1,
            address2: list.address_line2,
            address3: list.address_line3,
            city: selectedcity.value,
            province: list.state,
            zip_code: list.zip_code,
            vat_number: list.vat_number,
            commision_type: list.commission_type,
            commision: list.commission,
            status: list.status,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200" || !data.data) {
          console.log("error");
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          setTimeout(() => navigate("/agencies-and-companies-list"), 3000);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Add();
      } else {
        console.log("error");
      }
    }
  };
  console.log(list);
  useEffect(() => {
    getcountry();

    const transformedCities = cityval.map((city) => ({
      label: city.name,
      value: city.city_id,
    }));
    setCity(transformedCities);
  }, [cityval]);

console.log(id);

  return (
    <div>
      <Privatesaleheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create Agency/Company
                      </h4>
                      <div className="flex-shrink-0">
                        <div
                          className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                          dir="ltr"
                        >
                          <label
                            className="form-check-label"
                            htmlFor="inlineswitch5"
                            style={{
                              marginRight: "40px",
                              marginTop: "5px",
                              marginLeft: "5px",
                              color: "black",
                            }}
                          >
                            Active
                          </label>
                          <input
                            type="checkbox"
                            className="form-check-input offer_checkbox1"
                            id="inlineswitch5"
                            value={list?.status}
                            checked={list?.status === true ? true : false}
                            onChange={(e) =>
                              handleloyaltyToggle(e.target.checked, "status")
                            }
                            style={{ width: "50px", height: "25px" }}
                          />
                        </div>
                        <Link
                          type="button"
                          className="btn db-other-button"
                          to="/agencies-and-companies-list"
                          style={{ marginRight: "5px" }}
                        >
                          Agencies/Companies
                        </Link>
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          onClick={Add}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="" style={{ marginTop: "15px" }}>
                            <div className="row">
                              <div class="col-lg-6">
                                <div className="mb-2">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-psale-agency"
                                      id="inputGroup-sizing-default"
                                    >
                                      Name
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      name="name"
                                      value={list.name}
                                      onChange={(e) =>
                                        handleConditionChange(
                                          "name",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <label style={{ color: "red" }}>
                                  {formerror.name}
                                </label>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-2">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-psale-agency"
                                      id="inputGroup-sizing-default"
                                    >
                                      Type{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <select
                                      className="form-select"
                                      id="idPayment"
                                      value={list.type}
                                      onChange={(e) =>
                                        handleConditionChange(
                                          "type",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Select...</option>
                                      <option value="company">Company</option>
                                      <option value="travel_agency">
                                        Travel Agency
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <label style={{ color: "red" }}>
                                  {formerror.type}
                                </label>
                              </div>
                              {list.type === "travel_agency" ? (
                                <div class="col-lg-6">
                                  <div className="mb-2">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-psale-agency"
                                        id="inputGroup-sizing-default"
                                      >
                                        IATA CIGMA Code
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="cigma_code"
                                        value={list.cigma_code}
                                        onChange={(e) =>
                                          handleConditionChange(
                                            "cigma_code",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {formerror.cigma_code}
                                  </label>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="col-lg-6">
                                <div className="mb-2">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-psale-agency"
                                      id="inputGroup-sizing-default"
                                    >
                                      Payment Type
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <select
                                      className="form-select"
                                      id="idPayment"
                                      value={list.payment_type}
                                      onChange={(e) =>
                                        handleConditionChange(
                                          "payment_type",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Select...</option>
                                      <option value="normal">
                                        Guarantee and normal payment of the
                                        rate-system
                                      </option>
                                      <option value="special">
                                        Card not required. Payment according to
                                        special agreement
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <label style={{ color: "red" }}>
                                  {formerror.payment_type}
                                </label>
                              </div>

                              <div className="col-lg-6">
                                <div className="mb-2">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-psale-agency"
                                      id="inputGroup-sizing-default"
                                    >
                                      Commission On
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <select
                                      className="form-select"
                                      id="idPayment"
                                      value={list.commission_type}
                                      onChange={(e) =>
                                        handleConditionChange(
                                          "commission_type",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Select...</option>
                                      <option value="with_tax">
                                        Price With Taxes
                                      </option>
                                      <option value="without_tax">
                                        Price Without Taxes
                                      </option>
                                    </select>
                                    {list.type === "travel_agency" ? (
                                      <>
                                        <input
                                          type="number"
                                          className="form-control"
                                          aria-label="Sizing example input"
                                          placeholder=""
                                          name="commision"
                                          value={list.commission}
                                          onChange={(e) =>
                                            handleConditionChange(
                                              "commission",
                                              e.target.value
                                            )
                                          }
                                          style={{ textAlign: "center" }}
                                          onFocus={(e) =>
                                            e.target.addEventListener(
                                              "wheel",
                                              function (e) {
                                                e.preventDefault();
                                              },
                                              { passive: false }
                                            )
                                          }
                                        />
                                        <span
                                          className="input-group-text  input-joint-color"
                                          id="inputGroup-sizing-default"
                                          style={{ width: "60px" }}
                                        >
                                          %
                                        </span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <label style={{ color: "red" }}>
                                  {formerror.commission_type}
                                </label>

                                <label
                                  style={{
                                    color: "red",
                                    float: "right",
                                    paddingRight: "12%",
                                  }}
                                >
                                  {formerror.commission}
                                </label>
                              </div>

                              <div class="col-lg-6">
                                <div className="mb-2">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-psale-agency"
                                      id="inputGroup-sizing-default"
                                    >
                                      VAT Number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      name="vat_number"
                                      value={list.vat_number}
                                      onChange={(e) =>
                                        handleConditionChange(
                                          "vat_number",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-6">
                                <div className="mb-4">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-psale-agency"
                                      id="inputGroup-sizing-default"
                                    >
                                      Location
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      name="branch"
                                      value={list.branch}
                                      onChange={(e) =>
                                        handleConditionChange(
                                          "branch",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-6">
                                <div className="mb-4">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-psale-agency"
                                      id="inputGroup-sizing-default"
                                    >
                                      Manager
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      name="manager"
                                      value={list.manager}
                                      onChange={(e) =>
                                        handleConditionChange(
                                          "manager",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-6">
                                <div className="mb-2">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-psale-agency"
                                      id="inputGroup-sizing-default"
                                    >
                                      Contact Person
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      name="contact_person"
                                      value={list.contact_person}
                                      onChange={(e) =>
                                        handleConditionChange(
                                          "contact_person",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-6">
                                <div className="mb-2">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-psale-agency"
                                      id="inputGroup-sizing-default"
                                    >
                                      Email
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      name="email"
                                      value={list.email}
                                      onChange={(e) =>
                                        handleConditionChange(
                                          "email",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <label style={{ color: "red" }}>
                                  {formerror.email}
                                </label>
                              </div>

                              <div class="col-lg-6">
                                <div className="mb-2">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-psale-agency"
                                      id="inputGroup-sizing-default"
                                    >
                                      Phone
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder=""
                                      name="phone"
                                      value={list.phone}
                                      onChange={(e) =>
                                        handleConditionChange(
                                          "phone",
                                          e.target.value
                                        )
                                      }
                                      onFocus={(e) =>
                                        e.target.addEventListener(
                                          "wheel",
                                          function (e) {
                                            e.preventDefault();
                                          },
                                          { passive: false }
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="be_loyalty_level"
                              style={{ marginTop: "15px" }}
                            >
                              <h5>Address Information</h5>
                              <div className="row">
                                <div class="col-lg-6">
                                  <div className="mb-2">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-psale-agency"
                                        id="inputGroup-sizing-default"
                                      >
                                        Address Line1{" "}
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="address_line1"
                                        value={list.address_line1}
                                        onChange={(e) =>
                                          handleConditionChange(
                                            "address_line1",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {formerror.address_line1}
                                  </label>
                                </div>

                                <div class="col-lg-6">
                                  <div className="mb-4">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-psale-agency"
                                        id="inputGroup-sizing-default"
                                      >
                                        Address Line2
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="address_line2"
                                        value={list.address_line2}
                                        onChange={(e) =>
                                          handleConditionChange(
                                            "address_line2",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-6">
                                  <div className="mb-2">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-psale-agency"
                                        id="inputGroup-sizing-default"
                                      >
                                        Address Line3
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="address_line3"
                                        value={list.address_line3}
                                        onChange={(e) =>
                                          handleConditionChange(
                                            "address_line3",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="mb-2">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-psale-agency"
                                        id="inputGroup-sizing-default"
                                      >
                                        Country
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <Select
                                        className="form-control"
                                        data-choices
                                        name="choices-single-default"
                                        id="choices-single-default"
                                        options={country}
                                        value={countrydata}
                                        onChange={(val) => {
                                          handleChangeselectcountry(val);
                                        }}
                                      ></Select>
                                    </div>
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {formerror.countrydata}
                                  </label>
                                </div>

                                <div class="col-lg-6">
                                  <div className="mb-2">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-psale-agency"
                                        id="inputGroup-sizing-default"
                                      >
                                        State/Province
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="state"
                                        value={list.state}
                                        onChange={(e) =>
                                          handleConditionChange(
                                            "state",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {formerror.state}
                                  </label>
                                </div>

                                <div className="col-md-6">
                                  <div className="mb-2">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-psale-agency"
                                        id="inputGroup-sizing-default"
                                      >
                                        City/Town
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <Select
                                        className="form-control"
                                        data-choices
                                        name="choices-single-default"
                                        id="choices-single-default"
                                        options={city}
                                        value={selectedcity}
                                        onChange={(val) => {
                                          setSelectedcity(val);
                                        }}
                                      ></Select>
                                    </div>
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {formerror.selectedcity}
                                  </label>
                                </div>

                                <div class="col-lg-6">
                                  <div className="mb-2">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-psale-agency"
                                        id="inputGroup-sizing-default"
                                      >
                                        Zip Code
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="zip_code"
                                        value={list.zip_code}
                                        onChange={(e) =>
                                          handleConditionChange(
                                            "zip_code",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {toastmodal === true ? (
          <Toastmodal
            setToast={setToastmodal}
            message={toastmessage}
            type={toasttype}
            large={toastlarge}
          />
        ) : (
          ""
        )}
        <Footer />
      </div>
    </div>
  );
}

export default Agenciescreate;
