import React, { useState } from "react";
import Commonheader from "../../Common/Commonheader";
import { Link } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";

function Extrastep2({
  setTab,
  extra2Room,
  extra2Board,
  extra2Ratelist,
  extra2error,
  handlesingleroomselect,
  handlesingleoccupancyselect,
  activeroom,
  handleChangeroomExtra2,
  activeboard,
  handlesingleboardselect,
  handleChangeboardextra2,
  activerate,
  handlesinglerateselect,
  handleChangerateextra2,
  nextofferstep2,
  handlebasedon,
  handleChangeroomextra2all_label,
  availableboard,
  selectedBoard,
  setSelectedBoard,
  selectedRate,
  setSelectedRate,
  offers,
  selectedOffers,
  setSelectedOffers,
  handleOfferstatusToggle,
  offerstatus,
}) {
  console.log(offerstatus);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="offer-create-section">
          <div className="offer-create-details">
            <div className="col-md-12">
              <div className="col-md-12 offer-apply-section">
                <div className="col-md-6 offer-apply-sec ">
                  <div className="offer-apply-sub">
                    <h6 className="offer-form-title">Rooms & Occupancies</h6>
                    <div className="offer-form-status-section">
                      <p className="offer-form-label">
                        Choose the rooms and occupancies the add-on wants to
                        apply.
                      </p>
                      <div className="offer-condition-check">
                        <ul className="offer-roomtypes">
                          <li>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={activeroom}
                              checked={activeroom === true ? true : false}
                              onChange={(e) =>
                                handleChangeroomExtra2(e.target.checked)
                              }
                            />
                            <label className="form-check-label"> All</label>
                          </li>
                          {extra2Room.map((rooms, index) => {
                            return (
                              <li key={index}>
                                <input
                                  className="form-check-input room1_check"
                                  type="checkbox"
                                  value={rooms.selected}
                                  checked={
                                    rooms.selected && rooms.selected === true
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    handlesingleroomselect(
                                      index,
                                      e.target.checked
                                    )
                                  }
                                />
                                <label className="form-check-label label-media">
                                  {rooms.label}{" "}
                                  <span className="room_id_colors">
                                    (ROOM ID: {rooms.key})
                                  </span>
                                </label>
                                {rooms.selected === true ? (
                                  <>
                                    <div className="offer_room_wise">
                                      <div className="offer_room_wise_base">
                                        Based on:
                                      </div>
                                      <select
                                        className=""
                                        value={rooms.based_on}
                                        onChange={(e) =>
                                          handlebasedon(index, e.target.value)
                                        }
                                      >
                                        <option>Select..</option>
                                        <option value="per_room">
                                          Per Room
                                        </option>
                                        <option value="per_person">
                                          Per Person
                                        </option>
                                      </select>
                                    </div>
                                    {rooms.based_on === "per_person" ? (
                                      <div className="offer_room_wise_per_person">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={rooms.all_label}
                                          checked={
                                            rooms.all_label === true
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleChangeroomextra2all_label(
                                              index,
                                              e.target.checked
                                            )
                                          }
                                        />
                                        <label className="form-check-label">
                                          All
                                        </label>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                                {rooms.based_on === "per_person" ? (
                                  <ul
                                    className={
                                      rooms.selected === true
                                        ? "offer-roomtypes-list room1_check_show"
                                        : "offer-roomtypes-list room1_check_show d-none"
                                    }
                                  >
                                    {rooms.occupancies.map((occu, indx) => {
                                      return (
                                        <li key={indx}>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="formCheck1"
                                            value={occu.selected}
                                            checked={
                                              occu.selected &&
                                              occu.selected === true
                                                ? true
                                                : false
                                            }
                                            onChange={(e) =>
                                              handlesingleoccupancyselect(
                                                index,
                                                indx,
                                                e.target.checked
                                              )
                                            }
                                          />

                                          <span className="offer-roomtypes-list-occ">
                                            {occu.adult + occu.child > 4 && (
                                              <>
                                                {occu.adult > 0 && (
                                                  <>
                                                    <img
                                                      src="/images/icon/user.png"
                                                      alt="Adult"
                                                      style={{
                                                        paddingLeft: "1px",
                                                      }}
                                                    />
                                                    ({occu.adult})
                                                  </>
                                                )}
                                                {occu.child > 0 && (
                                                  <>
                                                    {" "}
                                                    X{" "}
                                                    <img
                                                      src="/images/user-color.png"
                                                      alt="Child"
                                                      className="child-icon"
                                                      style={{
                                                        marginLeft: "2px",
                                                      }}
                                                    />
                                                    ({occu.child})
                                                  </>
                                                )}
                                              </>
                                            )}

                                            {occu.adult + occu.child <= 4 && (
                                              <>
                                                {Array.from(
                                                  { length: occu.adult },
                                                  (_, indx) => (
                                                    <img
                                                      key={`adult-${indx}`}
                                                      src="/images/icon/user.png"
                                                      alt="Adult"
                                                      style={{
                                                        paddingLeft: "1px",
                                                      }}
                                                    />
                                                  )
                                                )}
                                                {Array.from(
                                                  { length: occu.child },
                                                  (_, indx) => (
                                                    <img
                                                      key={`child-${indx}`}
                                                      src="/images/user-color.png"
                                                      alt="Child"
                                                      className="child-icon"
                                                      style={{
                                                        marginLeft: "2px",
                                                      }}
                                                    />
                                                  )
                                                )}
                                              </>
                                            )}
                                          </span>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </li>
                            );
                          })}

                          <label style={{ color: "red" }}>
                            {extra2error.room}
                          </label>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 offer-apply-sec extra-offers">
                  <span
                    className={
                      offers.length > 0
                        ? "offer_only_extra_head"
                        : "offer_only_extra_disable"
                    }
                  >
                    Offers Only
                  </span>
                  {offers.length === 0 ? (
                    <span className="info-tag">
                      i
                      <span className="tooltiptext">
                        Currently, there are no special offers available for
                        this hotel.
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                  {/* <div className="form-check form-switch form-switch-danger form-check-inline condition-active-status" dir="ltr" style={{marginLeft:"16%",bottom:"30px"}}>
                                            <input type="checkbox" className="form-check-input offer_checkbox1" id="inlineswitch5"  style={{width:"50px",height:"25px"}}
                                                value={offerstatus.only_offer} checked={offerstatus.only_offer=== true ? true : false} 
                                                onChange={(e)=>handleOfferstatusToggle(e.target.checked,"only_offer")}/>
                                            </div> */}
                  <div className="only_offer_status_extra">
                    <input
                      type="radio"
                      id="radio-one"
                      name="only_offer"
                      title="Currently, there are no special offers available for this hotel."
                      value={true}
                      checked={offerstatus.only_offer === true}
                      onChange={(e) => handleOfferstatusToggle(e)}
                      disabled={offers.length === 0}
                    />
                    <label for="radio-one">Yes</label>

                    <input
                      name="only_offer"
                      type="radio"
                      id="radio-two"
                      value={false}
                      checked={offerstatus.only_offer === false}
                      onChange={(e) => handleOfferstatusToggle(e)}
                      disabled={offers.length === 0}
                    />
                    <label for="radio-two">No</label>
                  </div>
                  {offers.length > 0 ? (
                    <div class="col-lg-8" style={{ marginTop: "15px" }}>
                      <div className="input-group">
                        <label
                          className="input-group-text"
                          id="inputGroup-sizing-default"
                          style={{
                            width: "107px",
                            backgroundColor: "#008080",
                            color: "white",
                          }}
                        >
                          Offers:
                        </label>
                        <div className="col-md-8 col-multiselect">
                          <MultiSelect
                            options={offers}
                            value={selectedOffers}
                            onChange={(e) => setSelectedOffers(e)}
                            labelledBy="Select"
                            selectionType="counter"
                            allowCustomValue={true}
                            aria-expanded="true"
                          />
                        </div>
                        <label style={{ color: "red", fontWeight: "400" }}>
                          {extra2error.selectedOffers}
                        </label>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {offerstatus.only_offer === false ? (
                    <>
                      <div class="col-lg-8" style={{ marginTop: "15px" }}>
                        <div className="input-group">
                          <label
                            className="input-group-text"
                            id="inputGroup-sizing-default"
                            style={{
                              backgroundColor: "#008080",
                              color: "white",
                            }}
                          >
                            Meal Boards:
                          </label>
                          <div className="col-md-8 col-multiselect">
                            <MultiSelect
                              options={extra2Board}
                              value={selectedBoard}
                              onChange={(e) => setSelectedBoard(e)}
                              labelledBy="Select"
                              selectionType="counter"
                              allowCustomValue={true}
                              aria-expanded="true"
                            />
                          </div>
                          <label style={{ color: "red", fontWeight: "400" }}>
                            {extra2error.selectedBoard}
                          </label>
                        </div>
                      </div>
                      <div class="col-lg-8" style={{ marginTop: "15px" }}>
                        <div className="input-group">
                          <label
                            className="input-group-text"
                            id="inputGroup-sizing-default"
                            style={{
                              width: "107px",
                              backgroundColor: "#008080",
                              color: "white",
                            }}
                          >
                            Rate Plans:
                          </label>
                          <div className="col-md-8 col-multiselect">
                            <MultiSelect
                              options={extra2Ratelist}
                              value={selectedRate}
                              onChange={(e) => setSelectedRate(e)}
                              labelledBy="Select"
                              selectionType="counter"
                              allowCustomValue={true}
                              aria-expanded="true"
                            />
                          </div>
                          <label style={{ color: "red", fontWeight: "400" }}>
                            {extra2error.selectedRate}
                          </label>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {/* <div className="col-md-3 offer-apply-sec">
                                            <div className="offer-apply-sub">
                                                <h6 className="offer-form-title">Meal Boards</h6>
                                                <div className="offer-form-status-section">
                                                    <p className="offer-form-label">Choose the meal boards the offer wants to apply.</p>
                                                    <div className="offer-condition-check">
                                                        <ul className="offer-roomtypes">
                                                            <li>
                                                                <input className="form-check-input" type="checkbox" value={activeboard} checked={activeboard===true?true:false} onChange={(e)=>handleChangeboardextra2(e.target.checked)}/>
                                                                <label className="form-check-label" >
                                                                All
                                                                </label>

                                                            </li>
                                                            {extra2Board.map((board,index)=>{
                                                                return(
                                                                    <li key={index}>
                                                                    { availableboard.some((item) => item.id === board.value) ? 
                                                                        <input className="form-check-input" type="checkbox" value={board.selected} checked={board.selected && board.selected===true?true:false} onChange={(e)=>handlesingleboardselect(index,e.target.checked)}/>
                                                                        :
                                                                        <input className="form-check-input" type="checkbox" value={board.selected} checked={board.selected && board.selected===true?true:false} onChange={(e)=>handlesingleboardselect(index,e.target.checked)} disabled/>
                                                                      }
                                                                    <label className="form-check-label">
                                                                      {board.label}
                                                                    </label>
                                                                    
                                                                </li>
                                                                //     <li key={index}>
                                                                //     <input className="form-check-input" type="checkbox" value={board.selected} checked={board.selected && board.selected===true?true:false} onChange={(e)=>handlesingleboardselect(index,e.target.checked)}/>
                                                                //     <label className="form-check-label">
                                                                //     {board.label}
                                                                //     </label>
                                                                    
                                                                // </li>
                                                                )
                                                            })}
                                                        
                                                    <label style={{ color: "red" }}>
                                                               {extra2error.board}
                                                                  </label>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                         </div> */}
                {/* <div className="col-md-3 offer-apply-sec">
                                    <div className="offer-apply-sub">
                                        <h6 className="offer-form-title">Rate Plans</h6>
                                        <div className="offer-form-status-section">
                                            <p className="offer-form-label">Choose the rate plans the offer wants to apply.</p>
                                            <div className="offer-condition-check">
                                                <ul className="offer-roomtypes">
                                                    <li>
                                                        <input className="form-check-input" type="checkbox" value={activerate} checked={activerate===true?true:false} onChange={(e)=>handleChangerateextra2(e.target.checked)}/>
                                                        <label className="form-check-label" >
                                                        All
                                                        </label>

                                                    </li>
                                                    {extra2Ratelist.map((rate,index)=>{
                                                        return(
                                                    <li key={index}>
                                                        <input className="form-check-input" type="checkbox" value={rate.selected} checked={rate.selected && rate.selected===true?true:false} onChange={(e)=>handlesinglerateselect(index,e.target.checked)}/>
                                                        <label className="form-check-label" >
                                                            {rate.label}
                                                        </label>
                                                        
                                                    </li>
                                                        )
                                                    })}
                                                    
                                                    <label style={{ color: "red" }}>
                                                          {extra2error.rate}
                                                         </label>
                                                   </ul>
                                                </div>
                                             </div>
                                           </div>
                                         </div> */}
              </div>
            </div>
            <div className="tab_nav_section">
              <div className="tab_nav_section_btns">
                <Link
                  className="btn btn-be-form-cancel btn-be-back"
                  to="#"
                  onClick={(e) => setTab("step1")}
                >
                  Prev
                </Link>
                <Link
                  className="btn btn-success btn-be-common"
                  to="#"
                  onClick={nextofferstep2}
                >
                  Next
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Extrastep2;
