import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";

import Footer from "../Common/Footer";
import axios from "axios";
import Config from "../Config";
import Alerts from "../Alerts";
import DeleteModal from "../DeleteModal";
import Editmodal from "../Editmodal";
import ReactPaginate from "react-paginate";
import Commonheader from "../Common/Commonheader";
import Nodatafound from "../Common/Nodatafound";
import Loading from "../Loading";

function Pages() {
  const [pagename, setPagename] = useState("");
  const [pageslug, setPageslug] = useState("");
  const [pageslu, setPageslu] = useState("");
  const [pages, setPages] = useState([]);
  const [paginate, setPaginate] = useState([]);

  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [formerror, setError] = useState({});
  const [formerror1, setError1] = useState({});
  const [page, setPage] = useState(1);
  const [pageindex, setPageindex] = useState(1);
  const [open, setOpen] = useState(false);
  const [pageid, setPageid] = useState("");
  const [status, setStatus] = useState("");
  const [pagedata, setPagedata] = useState("");
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [loader, setLoader] = useState(false);

  function tog_animationFlip(id) {
    setmodal_animationFlip(!modal_animationFlip);
    setPageid(id);
  }
  const [modal_backdrop, setmodal_backdrop] = useState(false);

  function tog_backdrop(ids) {
    setmodal_backdrop(!modal_backdrop);
    setPageid(ids);
    const getinfo = async (e) => {
      const res = await fetch(`${Config.apiurl}admin/page/view/${ids}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data1 = await res.json();
      const data = data1.data;
      if (data1.status_code !== "200" || !data) {
        console.log("error");
      } else {
        setPagedata(data.name);
        setPageslu(data.slug);
      }
    };
    getinfo();
  }

  const getData = async (e) => {
    const res = await fetch(`${Config.apiurl}admin/page/list?page=1`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setPages(data.data);
      setPaginate(data.data.last_page);
      setLoader(true);
    }
  };

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}admin/page/list?page=${currentPage}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const data = await res.json();
    return data.data.data;
  };

  const handlePageClick = async (data) => {
    if (data === 1) {
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);

      setPages(commentsFormServer);
    } else {
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);

      setPages(commentsFormServer);
    }
  };
  function Reset() {
    setPagename("");
    setPageslug("");
  }

  const validate = (values) => {
    const errors = {};
    if (!values) {
      errors.pagename = "Name is required!";
    }
    return errors;
  };
  const validate1 = (values) => {
    const errors = {};
    if (!values) {
      errors.pageslug = "Slug is required!";
    }
    return errors;
  };
  const PostData = async (e) => {
    e.preventDefault();
    const ress = validate(pagename);
    const ress1 = validate1(pageslug);
    setError(ress);
    setError1(ress1);
    if (!ress.pagename && !ress1.pageslug) {
      const formData = new FormData();
      formData.append("name", pagename);
      formData.append("slug", pageslug);
      axios
        .post(`${Config.apiurl}admin/page/add`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.status_code !== "200" || !res) {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("error");
          } else {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("success");
            Reset();
            handlePageClick(page);
          }
        });
    }
  };
  const DeleteData = async (id) => {
    axios
      .delete(`${Config.apiurl}admin/page/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status !== "200" || !res) {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("error");
          setmodal_animationFlip(false);
        } else {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("success");
          setmodal_animationFlip(false);
          handlePageClick(page);
        }
      });
  };

  const UpdateData = async (id) => {
    const formData = new FormData();
    formData.append("name", pagedata);
    formData.append("slug", pageslu);
    formData.append("status", status);

    axios
      .post(`${Config.apiurl}admin/page/update/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status_code !== "200" || !res) {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("error");
          setmodal_animationFlip(false);
        } else {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("success");
          setmodal_animationFlip(false);
          setmodal_backdrop(false);
          handlePageClick(page);
        }
      });
  };
  const handleInput = (e) => {
    let value = e.target.value;
    setPagedata(value);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div id="layout-wrapper">
        <Commonheader />

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="db-page-title">PAGES</h4>
                    {/* <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item active">Pages</li>
                      </ol>
                    </div> */}
                  </div>
                </div>
              </div>
              {alert ? (
                <Alerts type={alerttype} message={alertmessage} open={open} />
              ) : (
                ""
              )}
              <div className="row project-wrapper">
                <div className="row dashboard-top-tab-style">
                  <div className="col-xxl-12 dashboard-main-style">
                    <div className="card">
                      <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          Create Page
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className="live-preview">
                          <form action="#">
                            <div className="row align-items-center">
                              <div className="col-md-4">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Page Name{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Page Name"
                                  value={pagename}
                                  onChange={(e) => setPagename(e.target.value)}
                                />
                                <label style={{ color: "red" }}>
                                  {formerror.pagename}
                                </label>
                              </div>

                              <div className="col-md-4">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Page Slug{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Page slug"
                                  value={pageslug}
                                  onChange={(e) => setPageslug(e.target.value)}
                                />
                                <label style={{ color: "red" }}>
                                  {formerror1.pageslug}
                                </label>
                              </div>
                              <div className="col-md-4">
                                <button
                                  type="button"
                                  className="btn db-save-single-button"
                                  data-bs-toggle="modal"
                                  id="create-btn"
                                  data-bs-target=""
                                  onClick={PostData}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                            <div className="padding-20"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="card" id="orderList">
                      <div className="card-header border-0">
                        <div className="row align-items-center gy-3">
                          <div className="col-sm">
                            <h5 className="card-title mb-0">List Of Pages</h5>
                          </div>
                        </div>
                      </div>
                      {loader===false?
                      <Loading/>
                      :
                      <>
                      {pages.length > 0 ? (
                        <div className="card-body pt-0">
                          <div>
                            <ul
                              className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                              role="tablist"
                            ></ul>

                            <div className="table-responsive table-card mb-1">
                              <table
                                className="table table-nowrap align-middle"
                                id="orderTable"
                              >
                                <thead className="text-muted table-light">
                                  <tr className="text-uppercase">
                                    <th scope="col" style={{ width: "25px" }}>
                                      <div className="form-check">
                                        {/* <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="checkAll"
                                        value="option"
                                      /> */}
                                      </div>
                                    </th>
                                    <th className="" data-sort="id">
                                      SL
                                    </th>
                                    <th className="" data-sort="customer_name">
                                      Page
                                    </th>
                                    <th className="" data-sort="city">
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="list form-check-all">
                                  {pages.map((element, index) => {
                                    return (
                                      <tr key={index}>
                                        <th scope="row">
                                          <div className="form-check">
                                            {/* <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="checkAll"
                                        value="option1"
                                      /> */}
                                          </div>
                                        </th>
                                        <td className="id">
                                          {pageindex === 1
                                            ? index + 1
                                            : (pageindex - 1) * 10 + index + 1}
                                        </td>
                                        <td className="customer_name">
                                          {element.name}
                                        </td>

                                        <td>
                                          <ul className="list-inline hstack gap-2 mb-1">
                                            <li className="list-inline-item">
                                              <Link
                                                // to={`/page-content/${element.slug}/${element.page_id}/${element.name}`}
                                                to={`/page-content-listing/${element.slug}/${element.page_id}/${element.name}`}
                                                className="create-common-link"
                                              >
                                                Manage Page Fields
                                              </Link>
                                            </li>
                                            {/* <!-- <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"
                                                            data-bs-placement="top" title="View">
                                                            <a href="apps-ecommerce-order-details.html" className="text-primary d-inline-block">
                                                                <i className="ri-eye-fill fs-16"></i>
                                                            </a>
                                                        </li> --> */}
                                            <li
                                              className="list-inline-item edit"
                                              data-bs-toggle="tooltip"
                                              data-bs-trigger="hover"
                                              data-bs-placement="top"
                                              title="Edit"
                                            >
                                              <Link
                                                to="#"
                                                data-bs-toggle="modal"
                                                className="text-primary d-inline-block edit-item-btn"
                                                onClick={() =>
                                                  tog_backdrop(element.page_id)
                                                }
                                              >
                                                <i className="ri-pencil-fill fs-16"></i>
                                              </Link>
                                            </li>
                                            <li
                                              className="list-inline-item"
                                              data-bs-toggle="tooltip"
                                              data-bs-trigger="hover"
                                              data-bs-placement="top"
                                              title="Remove"
                                            >
                                              <Link
                                                className="text-danger d-inline-block remove-item-btn"
                                                to="#"
                                                onClick={(e) =>
                                                  tog_animationFlip(
                                                    element.page_id
                                                  )
                                                }
                                              >
                                                <i className="ri-delete-bin-5-fill fs-16"></i>
                                              </Link>
                                            </li>
                                          </ul>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                            {paginate > 1 ? (
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={paginate}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={handlePageClick}
                                containerClassName={
                                  "pagination justify-content-end"
                                }
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                       <Nodatafound/>
                      )}
                      </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
        {modal_animationFlip ? (
          <DeleteModal
            modal_animationFlip={modal_animationFlip}
            tog_animationFlip={tog_animationFlip}
            pageid={pageid}
            DeleteData={DeleteData}
            message={"page"}
          />
        ) : (
          ""
        )}

        {modal_backdrop ? (
          <Editmodal
            pagedata={pagedata}
            pageslu={pageslu}
            modal_backdrop={modal_backdrop}
            tog_backdrop={tog_backdrop}
            pageid={pageid}
            UpdateData={UpdateData}
            status={status}
            setStatus={setStatus}
            setPagedata={setPagedata}
            setPageslu={setPageslu}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Pages;
