import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Alerts from "../../../Alerts";
import Commonheader from "../../../Common/Commonheader";
import Footer from "../../../Common/Footer";
import Config from "../../../Config";
import { useAuthHandling } from "../../../Common/useAuthHandling";
import { UserContext } from "../../../Contexts/UserContext";
import Toastmodal from "../../../Common/Toastmodal";
import Nodatafound from "../../../Common/Nodatafound";
import Loading from "../../../Loading";

function Rateplanlist() {
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [roomtype, setRoomtype] = useState([]);
  const [List, setList] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [page, setPage] = useState(1);
  const [pageindex, setPageindex] = useState(1);
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [selehotel, setSelechotel] = useState(0);
  const [showFullData, setShowFullData] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleDropdownClickhotel = (i) => {
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  const fetchComments = async (currentPage) => {
    const res = await fetch(`${Config.apiurl}rate-lists?page=${currentPage}`, {
      method: "POST",
      body: JSON.stringify({
        hotels: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await res.json();
    if (data.status_code === "200") {
      return data.data.data;
    } else if (data.reauth === true) {
      handleUnauthorized();
      fetchComments(currentPage);
    }
  };

  const handlePageClick = async (data) => {
    if (data === 1) {
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
      setList(commentsFormServer);
    } else {
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
      setList(commentsFormServer);
    }
  };

  const fetchPost = async () => {
    const res = await fetch(`${Config.apiurl}rate-lists?page=1`, {
      method: "POST",
      body: JSON.stringify({
        hotels: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setList(data.data.data);
        setPaginate(data.data.last_page);
        setLoader(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        fetchPost();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      fetchPost();
    } else {
      console.log("error");
    }
    // const data = await response.json();
    // if (data.status_code === "200") {
    //   setList(data.data.data);
    //   setPaginate(data.data.last_page);
    // } else if (response.reauth === true) {
    //   handleUnauthorized();
    //   fetchPost();
    // } else {
    //   console.log("error");
    // }
  };

  const Export = async (e) => {
    try {
      const res = await fetch(`${Config.apiurl}hotel/rate-list/download`, {
        method: "POST",
        body: JSON.stringify({
          hotel_id: List[selehotel]?.hotel_id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      if (res.status !== 200) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const contentDisposition = res.headers.get("content-disposition");
      console.log(contentDisposition);
      const fileNameMatch =
        contentDisposition &&
        contentDisposition.match(/filename=(["'])(?:(?=(\\?))\2.)*?\1/);

      const suggestedFileName = fileNameMatch
        ? fileNameMatch[0].substring(10, fileNameMatch[0].length - 1)
        : "ratelist.xlsx";

      const blob = await res.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([blob]));
      link.download = suggestedFileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // const getavailability = async (e) => {

  //     const res = await fetch(`${Config.apiurl}hotel/rate-lists`, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     });
  //     const data1 = await res.json();
  //     const data = data1.data;

  //     if (data1.status_code !== "200" || !data) {
  //       console.log("error");
  //     } else {
  //       setRatelist(data.data);
  //     }
  //   }
  const getRoomtype = async () => {
    const res = await fetch(`${Config.apiurl}active-rooms/${id[0]}`, {
      method: "POST",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setRoomtype(data);
    }
  };
  console.log(List);
  useEffect(() => {
    fetchPost();
    getRoomtype();
  }, [id]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (List.length > 0) {
      const initialState = List.map((item, index) => ({
        hotel: index === 0,
        rateplans: Array(item.rateplans.length).fill(false),
      }));
      setShowFullData(initialState);
    }
  }, [List]);

  return (
    <div id="layout-wrapper">
      <Commonheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12"></div>
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="db-page-title">Rate List</h4>
                <div className="flex-shrink-0 d-flex justify-content-between">
                  <button
                    type="button"
                    onClick={Export}
                    className="btn loyalty_import_btn "
                  >
                    Export
                  </button>
                  <Link
                    type="button"
                    to={`/rate-plan/${List[selehotel]?.hotel_id}`}
                    className="btn db-save-button "
                  >
                    Create
                  </Link>
                </div>
              </div>
            </div>

            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card" style={{ marginTop: "5px" }}>
                    <div className="card-body">
                      <div>
                        {/* <ul
                          className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                          role="tablist"
                        ></ul> */}
                        {loader===false?
                        <Loading/>
                        :
                        <div className="">
                          {List.map((li, i) => {
                            return (
                              <>
                                <div
                                  className={
                                    List.length > 1
                                      ? "row hotel_single_list"
                                      : "row"
                                  }
                                  key={i}
                                >
                                  {List.length > 1 ? (
                                    <div className="hotel_service_display hotel_service_display_12">
                                      <div className="hotel_service_display_14">
                                        <input
                                          type="radio"
                                          id="radio-one"
                                          className="hotel_service_display_14_radio form-check-input per_room_once_check"
                                          value={true}
                                          checked={
                                            i === selehotel ? true : false
                                          }
                                          onChange={(e) => setSelechotel(i)}
                                          style={{
                                            position: "relative",
                                            top: "8px",
                                          }}
                                        />

                                        <div className="hk_service_all_hotel">
                                          <span className="">
                                            {" "}
                                            {pageindex === 1
                                              ? i + 1
                                              : (pageindex - 1) * 10 + i + 1}
                                            .
                                          </span>
                                          <span style={{ marginLeft: "10px" }}>
                                            {li.hotel_name}
                                          </span>
                                        </div>
                                      </div>
                                      {showFullData[i]?.hotel === true ? (
                                        <span
                                          className="db-modify_toggle_btn"
                                          onClick={() =>
                                            handleDropdownClickhotel(i)
                                          }
                                        >
                                          <span
                                            className="bx bxs-chevron-up"
                                            style={{ fontSize: "20px" }}
                                          ></span>
                                        </span>
                                      ) : (
                                        <span
                                          className="db-modify_toggle_btn"
                                          onClick={() =>
                                            handleDropdownClickhotel(i)
                                          }
                                        >
                                          <span
                                            className="bx bxs-chevron-down"
                                            style={{ fontSize: "20px" }}
                                          ></span>
                                        </span>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <table
                                    id="fixed-header orderTable"
                                    className={`table table-nowrap align-middle ${
                                      showFullData[i]?.hotel === true
                                        ? ""
                                        : "d-none"
                                    }`}
                                  >
                                    {li.rateplans.length > 0 ? (
                                      <>
                                        <thead className="text-muted table-light">
                                          <tr className="text-uppercase">
                                            <th></th>
                                            <th className="" data-sort="id">
                                              Sl No.
                                            </th>
                                            <th
                                              className=""
                                              data-sort="customer_name"
                                            >
                                              Internal Name
                                            </th>
                                            <th
                                              className=""
                                              data-sort="customer_name"
                                            >
                                              Status
                                            </th>
                                            <th className="" data-sort="city">
                                              Action
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                          {li.rateplans.map((user, index) => {
                                            return (
                                              <tr key={index}>
                                                <th></th>
                                                <td className="id">
                                                  {index + 1}
                                                </td>
                                                <td className="customer_name">
                                                  {user.internal_name}
                                                </td>
                                                <td className="status">
                                                  {user.status === false ? (
                                                    <span class="badge badge-soft-danger text-uppercase">
                                                      {" "}
                                                      INACTIVE
                                                    </span>
                                                  ) : (
                                                    <span className="badge badge-soft-success text-uppercase">
                                                      ACTIVE
                                                    </span>
                                                  )}
                                                </td>
                                                <td>
                                                  <ul className="list-inline hstack gap-2 mb-1">
                                                    <li
                                                      className="list-inline-item edit"
                                                      data-bs-toggle="tooltip"
                                                      data-bs-trigger="hover"
                                                      data-bs-placement="top"
                                                      title="Edit"
                                                    >
                                                      <Link
                                                        // to="/rate-plan-edit"
                                                        // state={{
                                                        //   rate_id: user.rate_id,
                                                        // }}
                                                        to={`/rate-plan-edit/${user.rate_id}/${li.hotel_id}`}
                                                        data-bs-toggle="modal"
                                                        className="text-primary d-inline-block edit-item-btn"
                                                      >
                                                        <i className="ri-pencil-fill fs-16"></i>
                                                      </Link>
                                                    </li>
                                                  </ul>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </>
                                    ) : (
                                      <Nodatafound />
                                    )}
                                  </table>
                                </div>
                              </>
                            );
                          })}
                          {paginate > 1 ? (
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              pageCount={paginate}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination justify-content-end"
                              }
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Rateplanlist;
