import React, {  useEffect, useLayoutEffect } from "react";

//import Scss
import "./assets/scss/themes.scss";
import { UserProvider } from "./Components/Commoncomponents/Contexts/UserContext";
import Route from "./Routes";
import { useNavigate } from "react-router-dom";
import jwt from "jwt-decode";
import Config from "./Components/Commoncomponents/Config";

function App() {

  let navigate = useNavigate();
  const refreshtoken=localStorage.getItem("refreshtoken");

  const handleUnauthorized = async() => {
    
    const toekns= jwt(refreshtoken);
    const expirationTime = (toekns.exp * 1000) - 60000
    const date=Date.now();
    if (date >= expirationTime){
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("user_type");
      localStorage.removeItem("refreshtoken");
      localStorage.removeItem("allhotel");
      localStorage.removeItem("user_name");
      localStorage.removeItem("myData");
      navigate("/")
    }
    else{
      const res = await fetch(`${Config.apiurl}refresh-token`, {
        method: "POST",        
        body: JSON.stringify({
          old_token: `Bearer ${localStorage.getItem("refreshtoken")}`,
        }),
        headers: {       
          "Content-type": "application/json; charset=UTF-8",
        },
      });
    
      if (res.status === 200) {
        const data1 = await res.json();
          if(data1.status===false){
            localStorage.removeItem("token");
            localStorage.removeItem("email");
            localStorage.removeItem("user_type");
            localStorage.removeItem("refreshtoken");
            localStorage.removeItem("allhotel");
            localStorage.removeItem("user_name");
            localStorage.removeItem("myData");
            window.location.href=("/")
          }else{
           localStorage.setItem("token",data1.token);
          }
        }
    }
    };

  useEffect(() => {
  handleUnauthorized();

  }, []);

  // useEffect(() => {

  //   const token = localStorage.getItem('token');
    
  //   if (!token || token === undefined) {

  //     localStorage.removeItem("token");
  //     localStorage.removeItem("email");
  //     localStorage.removeItem("user_type");
  //     localStorage.removeItem("selected-hotel-id");
  //     localStorage.removeItem("selected-hotel");
  //     localStorage.removeItem("refreshtoken");
  //     localStorage.removeItem("myData");

  //     setTimeout(() => navigate("/"), 1000);
  //   } else {
  //     console.log("Token is valid");
  //   }
  // }, [navigate]);


  return (
    <React.Fragment>
      <UserProvider>
      <Route />
      </UserProvider>
    </React.Fragment>
  );
}

export default App;
