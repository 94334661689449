import React from 'react'

function Pagenotfound() {
  return (
    <div class="section-notfound">
    <h1 class="error-notfound">404</h1>
    <div class="page-notfound">Ooops!!! The page you are looking for is not found</div>
    
  </div>
  )
}

export default Pagenotfound