import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Link, useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import Alerts from "../../Alerts";
import Commonheader from "../../Common/Commonheader";
import Footer from "../../Common/Footer";
import Toastmodal from "../../Common/Toastmodal";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Config from "../../Config";
import DeleteModal from "../../DeleteModal";
import { UserContext } from "../../Contexts/UserContext";
import Loading from "../../Loading";

function Boardplan() {
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);

  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");

  const [board, setBoard] = useState([]);
  const [roomdata, setRoomdata] = useState([]);
  const [hotelboard, setHotelboard] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [page, setPage] = useState(1);
  const [pageindex, setPageindex] = useState(1);
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [mealboard_popup, setMealboard_popup] = useState([]);
  const [hotelboard_popup, setHotelboard_popup] = useState([]);
  const [boardid, setBoardid] = useState("");
  const [hotelId, setHotelId] = useState("");
  const [mealboard_popupnew, setMealboard_popupnew] = useState([]);

  const [hotelboard_popup_new, setHotelboard_popup_new] = useState([
    {
      en_name: "",
      sort_oder: "",
    },
  ]);
  const [selectedMulti, setselectedMulti] = useState([]);
  const [language, setLanguage] = useState([]);
  const [status, setStatus] = useState([]);
  const [default_status, setDefault_status] = useState([]);
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [board_tog, setBoard_tog] = useState([]);
  let navigate = useNavigate();
  const [tab, setTab] = useState("EN");
  const [showFullData, setShowFullData] = useState([]);
  const [hotelIdPopup, setHotelIdPopup] = useState("");
  const [loader, setLoader] = useState(false);

  const handleDropdownClickhotel = (i) => {
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  function tog_animationFlip(hotel_id) {
    setmodal_animationFlip(!modal_animationFlip);
    setHotelIdPopup(hotel_id);
    setHotelboard_popup([]);
  }

  const [delete_animationFlip, setDelete_animationFlip] = useState(false);
  function tog_animationFlipDel(hotel_id, ids) {
    setHotelId(hotel_id);
    setBoardid(ids);
    setDelete_animationFlip(!delete_animationFlip);
  }

  const getBoard = async (e) => {
    const res = await fetch(`${Config.apiurl}board-plan/list-board`, {
      method: "POST",
      body: JSON.stringify({
        hotels: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setBoard(data.data);
        setLoader(true);
        // setRoomdata(data.data.roomData);
        // setHotelboard(data.data.hotel_boards);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getBoard();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getBoard();
    } else {
      console.log("error");
    }
  };

  const getMealBoard_pop = async (e) => {
    const res = await fetch(`${Config.apiurl}boards`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        const additionalOption = { label: "Other", value: "other" };
        setMealboard_popupnew([...data.data, additionalOption]);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getMealBoard_pop();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getMealBoard_pop();
    } else {
      console.log("error");
    }
  };

  const getLang = async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setLanguage(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getLang();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getLang();
    } else {
      console.log("error");
    }
  };

  const handleBoardChangePop = (index, name, value) => {
    const updatedMealBoard = [...hotelboard_popup];
    updatedMealBoard[index][name] = value;
    setHotelboard_popup(updatedMealBoard);
  };

  const handleBoardChangePop_new = (name, val) => {
    setHotelboard_popup_new({ ...hotelboard_popup_new, [name]: val });
  };

  const handleSortOrderChange = (e, i, index) => {
    const updatedBoard = [...board];
    updatedBoard[i].hotel_boards[index].sort_order = parseInt(
      e.target.value,
      10
    );
    setBoard(updatedBoard);
  };

  const handleChangeToggle = (i, ind, isChecked, value) => {
    const updatedBoardTog = [...board];
    const room = updatedBoardTog[i].rooms[ind];

    if (room && Array.isArray(room.room_display_boards)) {
      const displayBoards = [...room.room_display_boards];

      if (isChecked) {
        if (!displayBoards.includes(value)) {
          displayBoards.push(value);
        }
      } else {
        const index = displayBoards.indexOf(value);
        if (index !== -1) {
          displayBoards.splice(index, 1);
        }
      }

      updatedBoardTog[i].rooms[ind].room_display_boards = displayBoards;
      setBoard(updatedBoardTog);
    }
  };

  const MealBoardAdd = async (e) => {
    e.preventDefault();

    let newBoard = [];
    for (let i = 0; i < hotelboard_popup.length; i++) {
      if (hotelboard_popup[i].value !== "other") {
        let multiarray = {};
        multiarray.board = hotelboard_popup[i].value;
        multiarray.order = hotelboard_popup[i].sort_order;
        newBoard.push(multiarray);
      }
    }

    let newCreateBoard = [];
    for (let i = 0; i < hotelboard_popup.length; i++) {
      if (hotelboard_popup[i].value === "other") {
        let multiarray = {};
        multiarray.en_name = hotelboard_popup_new.en_name;
        multiarray.full_form = hotelboard_popup_new.full_form;
        multiarray.order = hotelboard_popup_new.sort_order;
        multiarray.indicator = hotelboard_popup_new.indicator;
        multiarray.is_active = status;
        multiarray.default_status = default_status;
        let lang = [];
        for (let i = 0; i < selectedMulti.length; i++) {
          if (selectedMulti[i].name) {
            if (selectedMulti[i].code !== "en") {
              let multiarraylang = {};
              multiarraylang.lang_code = selectedMulti[i].code;
              multiarraylang.name = selectedMulti[i].name;
              multiarraylang.full_board = selectedMulti[i].full_board;
              lang.push(multiarraylang);
            }
          }
          multiarray.multi_lang = lang;
        }
        newCreateBoard.push(multiarray);
      }
    }

    const res = await fetch(`${Config.apiurl}hotel/board-plan/add-board`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: hotelIdPopup,
        hotel_boards: newBoard,
        new_boards: newCreateBoard,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        console.log("success");
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(true);
        getBoard();
        tog_animationFlip();
        hotelboard_popup(null);
      } else if (data.reauth === true) {
        handleUnauthorized();
        MealBoardAdd();
      } else {
        console.log("error");
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      MealBoardAdd();
    } else {
      console.log("error");
    }
  };

  const UpdateBordSort = async (ids) => {
    let newBoard = [];
    for (let i = 0; i < board.length; i++) {
      for (let j = 0; j < board[i].hotel_boards?.length; j++) {
        let multiarray = {};
        if (board[i].hotel_id === ids) {
          multiarray.board = board[i].hotel_boards[j]?.value;
          multiarray.order = board[i].hotel_boards[j]?.sort_order;
          newBoard.push(multiarray);
        }
      }
    }
    const res = await fetch(`${Config.apiurl}hotel/board-plan/update-board`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: ids,
        hotel_boards: newBoard,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        console.log("sucess");
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(true);
        getBoard();
      } else if (data.reauth === true) {
        handleUnauthorized();
        UpdateBordSort();
      } else {
        console.log("error");
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      UpdateBordSort();
    } else {
      console.log("error");
    }
  };

  const UpdateRoomBoard = async (ids) => {
    let newRoom = [];
    for (let i = 0; i < board.length; i++) {
      for (let j = 0; j < board[i].rooms.length; j++) {
        if (board[i].hotel_id === ids) {
          let multiarray = {};
          multiarray.room = board[i].rooms[j].room_id;

          let newBoard = [];
          for (
            let k = 0;
            k < board[i].rooms[j].room_display_boards.length;
            k++
          ) {
            newBoard.push(board[i].rooms[j].room_display_boards[k]);
          }
          multiarray.boards = newBoard;
          newRoom.push(multiarray);
        }
      }
    }

    const res = await fetch(
      `${Config.apiurl}hotel/board-plan/room-boards/update`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: ids,
          room_boards: newRoom,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        console.log("sucess");
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(true);
        getBoard();
      } else if (data.reauth === true) {
        handleUnauthorized();
        UpdateRoomBoard();
      } else {
        console.log("error");
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      UpdateRoomBoard();
    } else {
      console.log("error");
    }
  };

  const DeleteData = async (ids) => {
    const res = await fetch(`${Config.apiurl}hotel/board-plan/delete-board`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: hotelId,
        board_id: ids,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status === "200") {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(true);
        setDelete_animationFlip(false);
        getBoard();
      } else if (data.reauth === true) {
        handleUnauthorized();
        DeleteData();
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
        setDelete_animationFlip(false);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      DeleteData();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // if (data1.status !== "200" || !data1) {
    //   setToastmodal(true);
    //   setToastmessage(data1.message);
    //   setToasttype("error");
    //   setToastlarge(true);
    //   setDelete_animationFlip(false);
    // } else {
    //   setToastmodal(true);
    //   setToastmessage(data1.message);
    //   setToasttype("success");
    //   setToastlarge(true);
    //   setDelete_animationFlip(false);
    //   getBoard();
    // }
  };

  useEffect(() => {
    getMealBoard_pop();
    getLang();
  }, []);

  useEffect(() => {
    getBoard();
  }, [id]);

  // useEffect(() => {
  //   if (mealboard_popupnew && board) {
  //     setMealboard_popup([]);
  //     mealboard_popupnew.forEach((newItem) => {
  //       if (!board.some((boardItem) => boardItem.value === newItem.value)) {
  //         setMealboard_popup((prevMealboardPopup) => [
  //           ...prevMealboardPopup,
  //           {
  //             value: newItem.value,
  //             label: newItem.label,
  //           },
  //         ]);
  //       }
  //     });
  //   }
  // }, [mealboard_popupnew.length, board.length]);
  useEffect(() => {
    if (mealboard_popupnew && board) {
      setMealboard_popup([]);
      mealboard_popupnew.forEach((newItem) => {
        if (
          !board.some((boardItem) =>
            boardItem.hotel_boards.some((hb) => hb.value === newItem.value)
          )
        ) {
          setMealboard_popup((prevMealboardPopup) => [
            ...prevMealboardPopup,
            {
              value: newItem.value,
              label: newItem.label,
            },
          ]);
        }
      });
    }
  }, [mealboard_popupnew.length, board.length]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (board.length > 0) {
      const initialState = board.map((item, index) => ({
        hotel: index === 0,
      }));
      setShowFullData(initialState);
    }
  }, [board?.length]);


  return (
    <div id="layout-wrapper">
      <Commonheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12"></div>
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="db-page-title" style={{ marginLeft: "4%" }}>
                  Board Plans
                </h4>
              </div>
            </div>
            {loader===false?
            <Loading/>
            :
            <>
            {board.map((data, i) => {
              return (
                <>
                  <div
                    className={
                      board.length > 1 ? "row hotel_single_list" : "row"
                    }
                    key={i}
                  >
                    {board.length > 1 ? (
                      <div className="hotel_service_display hotel_service_display_12">
                        <div className="hotel_service_display_14">
                          <div className="hk_service_all_hotel">
                            <span className="">
                              {" "}
                              {pageindex === 1
                                ? i + 1
                                : (pageindex - 1) * 10 + i + 1}
                              .
                            </span>
                            <span style={{ marginLeft: "10px" }}>
                              {data.hotel_name}
                            </span>
                          </div>
                        </div>
                        {showFullData[i]?.hotel === true ? (
                          <span
                            className="db-modify_toggle_btn"
                            onClick={() => handleDropdownClickhotel(i)}
                          >
                            <span
                              className="bx bxs-chevron-up"
                              style={{ fontSize: "20px" }}
                            ></span>
                          </span>
                        ) : (
                          <span
                            className="db-modify_toggle_btn"
                            onClick={() => handleDropdownClickhotel(i)}
                          >
                            <span
                              className="bx bxs-chevron-down"
                              style={{ fontSize: "20px" }}
                            ></span>
                          </span>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      className={`row ${
                        showFullData[i]?.hotel === true ? "" : "d-none"
                      }`}
                    >
                      <div key={i} style={{ paddingLeft: "3%" }}>
                        <button
                          className="board_plan_button"
                          onClick={() => tog_animationFlip(data.hotel_id)}
                        >
                          Add
                        </button>
                      </div>

                      <div
                        className="row project-wrapper"
                        style={{ marginLeft: "1%" }}
                      >
                        <div className="row dashboard-top-tab-style">
                          <div
                            className="col-xxl-12 dashboard-main-style"
                            style={{ marginTop: "5px" }}
                          >
                            <div className="card">
                              <div
                                className="card-body pt-0"
                                style={{ border: "1px solid #f1f1f1" }}
                              >
                                <div>
                                  <ul
                                    className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                                    role="tablist"
                                  ></ul>
                                  <div className="table-responsive table-card mb-1">
                                    <table
                                      className="table table-nowrap align-middle"
                                      id="orderTable"
                                    >
                                      <thead className="text-muted table-light">
                                        <tr className="text-uppercase">
                                          <th>SL NO.</th>
                                          <th>MEAL BOARDS</th>
                                          <th>BOARD CODE</th>
                                          <th>Sort Order</th>
                                          <th>ACTION</th>
                                        </tr>
                                      </thead>

                                      <tbody className="list form-check-all">
                                        {data.hotel_boards.map(
                                          (user, index) => {
                                            return (
                                              <tr key={index}>
                                                <td className="id">
                                                  {pageindex === 1
                                                    ? index + 1
                                                    : (pageindex - 1) * 10 +
                                                      index +
                                                      1}
                                                </td>
                                                <td>{user.label}</td>
                                                <td>{user.indiator}</td>
                                                <td>
                                                  <input
                                                    type="number"
                                                    className="sort-order-field"
                                                    min={0}
                                                    value={user.sort_order}
                                                    onChange={(e) =>
                                                      handleSortOrderChange(
                                                        e,
                                                        i,
                                                        index
                                                      )
                                                    }
                                                    onFocus={(e) =>
                                                      e.target.addEventListener(
                                                        "wheel",
                                                        function (e) {
                                                          e.preventDefault();
                                                        },
                                                        { passive: false }
                                                      )
                                                    }
                                                  />
                                                </td>
                                                <td>
                                                  <li
                                                    className="list-inline-item"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-trigger="hover"
                                                    data-bs-placement="top"
                                                    title="Remove"
                                                  >
                                                    <Link
                                                      className="text-danger d-inline-block remove-item-btn"
                                                      data-bs-toggle="modal"
                                                      to="#"
                                                      onClick={(e) =>
                                                        tog_animationFlipDel(
                                                          data.hotel_id,
                                                          user.value
                                                        )
                                                      }
                                                    >
                                                      <i className="ri-delete-bin-5-fill fs-16"></i>
                                                    </Link>
                                                  </li>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                    <div
                                      style={{
                                        float: "right",
                                        paddingRight: "3%",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        className="board_plan_button_up"
                                        onClick={() =>
                                          UpdateBordSort(data.hotel_id)
                                        }
                                      >
                                        Update
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row project-wrapper"
                        style={{ marginLeft: "1%" }}
                      >
                        <div className="row dashboard-top-tab-style">
                          <div
                            className="col-xxl-12 dashboard-main-style"
                            style={{ marginTop: "5px" }}
                          >
                            <div className="card">
                              <div
                                className="card-body pt-0"
                                style={{ border: "1px solid #f1f1f1" }}
                              >
                                <div>
                                  {/* <ul
                                    className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                                    role="tablist"
                                  ></ul> */}
                                  <div className="table-responsive table-card mb-1">
                                    <table
                                      className="table table-nowrap align-middle"
                                      id="orderTable"
                                    >
                                      <thead
                                        className=""
                                        style={{
                                          backgroundColor: "#008080",
                                          color: "white",
                                        }}
                                      >
                                        <tr className="text-uppercase">
                                          <th>ROOMS</th>
                                          <th style={{ paddingLeft: "25px" }}>
                                            ASSIGNED BOARDS
                                          </th>
                                        </tr>
                                      </thead>

                                      <tbody className="list form-check-all">
                                        {data.rooms?.map((room, ind) => {
                                          return (
                                            <tr key={ind}>
                                              <td>{room.room_name}</td>
                                              <td>
                                                {data.hotel_boards?.map(
                                                  (board, indd) => {
                                                    const room_display_boards =
                                                      room.room_display_boards ||
                                                      [];
                                                    const isChecked =
                                                      room_display_boards.includes(
                                                        board?.value
                                                      ) || false;

                                                    return (
                                                      <span
                                                        key={indd}
                                                        className="form-check form-switch form-switch-danger condition-active-status"
                                                        dir="ltr"
                                                        style={{
                                                          marginLeft: "15px",
                                                          backgroundColor:
                                                            "#f1f1f1",
                                                        }}
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input open-close-check"
                                                          id={`inlineswitch${i}${ind}`}
                                                          checked={isChecked}
                                                          onChange={(e) =>
                                                            handleChangeToggle(
                                                              i,
                                                              ind,
                                                              e.target.checked,
                                                              board.value
                                                            )
                                                          }
                                                        />
                                                        <span
                                                          style={{
                                                            marginLeft: "7px",
                                                          }}
                                                        >
                                                          {board.label}
                                                        </span>
                                                      </span>
                                                    );
                                                  }
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                    <div
                                      style={{
                                        float: "right",
                                        paddingRight: "3%",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        className="board_plan_button_up"
                                        onClick={() =>
                                          UpdateRoomBoard(data.hotel_id)
                                        }
                                      >
                                        Update
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            </>
            }
          </div>
        </div>
      </div>
      <Modal
        id="flipModal"
        className="new_modal"
        isOpen={modal_animationFlip}
        toggle={() => {
          tog_animationFlip();
        }}
        modalClassName="flip"
        centered
        style={{
          fontFamily: "IBM Plex Sans,sans-serif",
          justifyContent: "center",
        }}
      >
        <ModalBody
          className="modal-body text-center"
          style={{ width: "650px" }}
        >
          <div className="tab-pane active" id="boardRate-tab" role="tabpanel">
            <div
              className="row price-modify-filter"
              style={{ marginTop: "20px" }}
            >
              <form>
                <button
                  type="button"
                  className="btn btn-link link-success fw-medium text-decoration-none"
                  onClick={() => {
                    tog_animationFlip();
                  }}
                  style={{ float: "right", bottom: "30px" }}
                >
                  {" "}
                  <i
                    className="ri-close-line me-1 align-middle"
                    style={{ fontSize: "20px" }}
                  ></i>
                </button>
                <div className="col-md-6" style={{ marginLeft: "15%" }}>
                  <div className="input-group">
                    <label
                      className="input-group-text input-joint-color"
                      id="inputGroup-sizing-default"
                    >
                      Meal Boards:
                    </label>
                    <div className="col-md-6">
                      <MultiSelect
                        options={mealboard_popup}
                        value={hotelboard_popup}
                        onChange={(e) => setHotelboard_popup(e)}
                        labelledBy="Select"
                        className="multiselect_board"
                        selectionType="counter"
                        allowCustomValue={true}
                      />
                    </div>
                  </div>
                </div>
                {hotelboard_popup.map((board, i) => {
                  return (
                    <>
                      {board.value !== "other" ? (
                        <div
                          key={i}
                          style={{
                            paddingLeft: "25%",
                            marginTop: "15px",
                            width: "70%",
                          }}
                        >
                          <div className="input-group">
                            <label
                              className="input-group-text input-joint-color"
                              id="inputGroup-sizing-default"
                            >
                              {board.label}-Sort order
                            </label>
                            <input
                              type="number"
                              className="form-control board-price-text"
                              aria-label="Sizing example input"
                              aria-describedby="inputGroup-sizing-default"
                              name="sort_order"
                              min={0}
                              value={hotelboard_popup.sort_order}
                              onChange={(e) =>
                                handleBoardChangePop(
                                  i,
                                  "sort_order",
                                  e.target.value
                                )
                              }
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  { passive: false }
                                )
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="card-body">
                            <div className="live-preview">
                              <form action="#">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div
                                      className="form-translation-style"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <ul>
                                        <li
                                          className={
                                            tab === "EN" ? "active" : ""
                                          }
                                          onClick={(e) => setTab("EN")}
                                        >
                                          EN
                                        </li>
                                        <li
                                          className={
                                            tab === "TRANS" ? "active" : ""
                                          }
                                          onClick={(e) => setTab("TRANS")}
                                        >
                                          Translations
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  {tab === "EN" ? (
                                    <>
                                      <div className="row">
                                        <div
                                          class="col-md-6"
                                          style={{ marginTop: "15px" }}
                                        >
                                          <div className="input-group">
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                            >
                                              Board Name{" "}
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="en_name"
                                              value={
                                                hotelboard_popup_new.en_name
                                              }
                                              onChange={(e) =>
                                                handleBoardChangePop_new(
                                                  "en_name",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div
                                          class="col-md-6"
                                          style={{ marginTop: "15px" }}
                                        >
                                          <div className="input-group">
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                            >
                                              Full form{" "}
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="full_form"
                                              value={
                                                hotelboard_popup_new.full_form
                                              }
                                              onChange={(e) =>
                                                handleBoardChangePop_new(
                                                  "full_form",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                      <div
                                          className="col-md-6"
                                          style={{ marginTop: "15px" }}
                                        >
                                          <div className="input-group">
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                            >
                                              Board Code
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              aria-label="Sizing example input"
                                              aria-describedby="inputGroup-sizing-default"
                                              name="indicator"
                                              value={
                                                hotelboard_popup_new.indicator
                                              }
                                              onChange={(e) =>
                                                handleBoardChangePop_new(
                                                  "indicator",
                                                  e.target.value
                                                )
                                              }
                                            
                                            />
                                          </div>
                                        </div>
                                       <div
                                          className="col-md-6"
                                          style={{ marginTop: "15px" }}
                                        >
                                          <div className="input-group">
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                            >
                                              Sort Order
                                            </label>
                                            <input
                                              type="number"
                                              className="form-control"
                                              aria-label="Sizing example input"
                                              aria-describedby="inputGroup-sizing-default"
                                              name="sort_order"
                                              min={0}
                                              value={
                                                hotelboard_popup_new.sort_order
                                              }
                                              onChange={(e) =>
                                                handleBoardChangePop_new(
                                                  "sort_order",
                                                  e.target.value
                                                )
                                              }
                                              onFocus={(e) =>
                                                e.target.addEventListener(
                                                  "wheel",
                                                  function (e) {
                                                    e.preventDefault();
                                                  },
                                                  { passive: false }
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div
                                          class="col-md-6"
                                          style={{ marginTop: "15px" }}
                                        >
                                          <div className="input-group">
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                            >
                                              Status{" "}
                                            </label>
                                            <select
                                              id="ForminputState"
                                              className="form-select"
                                              value={status}
                                              onChange={(e) =>
                                                setStatus(e.target.value)
                                              }
                                            >
                                              <option selected>
                                                Choose...
                                              </option>
                                              <option value={1}>Active</option>
                                              <option value={0}>
                                                Inactive
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                        <div
                                          class="col-md-6"
                                          style={{ marginTop: "15px" }}
                                        >
                                          <div className="input-group">
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                            >
                                              Default Status
                                            </label>
                                            <select
                                              id="ForminputState"
                                              className="form-select"
                                              value={default_status}
                                              onChange={(e) =>
                                                setDefault_status(
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option selected>
                                                Choose...
                                              </option>
                                              <option value={1}>Active</option>
                                              <option value={0}>
                                                Inactive
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                       
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        class="col-md-8"
                                        style={{ marginTop: "20px" }}
                                      >
                                        <div className="input-group">
                                          <label
                                            className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default"
                                            style={{ width: "130px" }}
                                          >
                                            {" "}
                                            Choose Language:
                                          </label>
                                          <div className="col-md-7">
                                            <MultiSelect
                                              options={language}
                                              value={selectedMulti}
                                              onChange={setselectedMulti}
                                              labelledBy="Select"
                                              selectionType="counter"
                                              closeOnChangedValue={true}
                                              aria-expanded="true"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="appendmore"></div>
                                      {selectedMulti.length > 0
                                        ? selectedMulti.map(
                                            (element, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  class="col-md-12 translation-fill-section"
                                                  id="lang_ +val"
                                                  data-value="1"
                                                  style={{ marginTop: "20px" }}
                                                >
                                                  <div
                                                    class="translation-selected-lang"
                                                    style={{
                                                      paddingRight: "91%",
                                                    }}
                                                  >
                                                    <p>
                                                      <span class="translation-selected-lang-head">
                                                        {element.code}
                                                      </span>
                                                    </p>
                                                  </div>
                                                  <div className="row">
                                                    <div class="col-lg-6">
                                                      <div className="input-group">
                                                        <label
                                                          className="input-group-text input-joint-color"
                                                          id="inputGroup-sizing-default"
                                                        >
                                                          Board Name
                                                        </label>
                                                        <input
                                                          type="text"
                                                          class="form-control"
                                                          placeholder=""
                                                          value={
                                                            selectedMulti[index]
                                                              .name
                                                          }
                                                          onChange={(e) =>
                                                            (selectedMulti[
                                                              index
                                                            ].name =
                                                              e.target.value)
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                      <div className="input-group">
                                                        <label
                                                          className="input-group-text input-joint-color"
                                                          id="inputGroup-sizing-default"
                                                        >
                                                          Full form
                                                        </label>
                                                        <input
                                                          type="text"
                                                          class="form-control"
                                                          placeholder=""
                                                          value={
                                                            selectedMulti[index]
                                                              .full_form
                                                          }
                                                          onChange={(e) =>
                                                            (selectedMulti[
                                                              index
                                                            ].full_form =
                                                              e.target.value)
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )
                                        : ""}
                                    </>
                                  )}
                                </div>
                              </form>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  );
                })}
              </form>
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <button
              type="button"
              className="btn db-save-button"
              onClick={MealBoardAdd}
            >
              Save
            </button>
          </div>
        </ModalBody>
      </Modal>
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
      <Footer />
      {delete_animationFlip ? (
        <DeleteModal
          modal_animationFlip={delete_animationFlip}
          tog_animationFlip={tog_animationFlipDel}
          DeleteData={DeleteData}
          pageid={boardid}
          message={"Meal Board"}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Boardplan;
