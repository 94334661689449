import React from "react";
import { ThreeDots } from "react-loader-spinner";

function Loading() {
  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div
            className="col-lg-2"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ThreeDots
              color="#008080"
              height={100}
              width={100}
              display="grid"
              text-align="center"
              position="fixed"
              timeout={8000}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loading;
