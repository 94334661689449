import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import Commonimagepath from "../../../Commonimagepath";

function TempHotelinformation({
  setTab,
  hoteldetails,
  country,
  timezone,
  extra,
  be_lang,
  propertytypehotel,
  category,
  currency,
  channel,
  pms,
  credit,
  hotelimg,
  roomimg,
}) {
  console.log(hoteldetails);
  return (
    <div id="BasicInfo" className="tabcontent">
      <div className="card-body">
        <div className="live-preview">
          <form action="#">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="firstNameinput"
                    className="form-label be_form_label"
                  >
                    Name of the Hotel
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={hoteldetails.hotel_name}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="lastNameinput"
                    className="form-label be_form_label"
                  >
                    Corporate Name
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    value={hoteldetails.corporate_name}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label be_form_label">Country </label>

                  <input
                    type="text"
                    className="form-control"
                    value={country.label}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label be_form_label">Timezone </label>
                  <input
                    type="text"
                    className="form-control"
                    value={timezone.label}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="phonenumberInput"
                    className="form-label be_form_label"
                  >
                    Phone Number{" "}
                  </label>

                  <div className="input-group" data-input-flag>
                    <button
                      class="btn btn-light border calling-btn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {hoteldetails.calling_code}
                    </button>
                    <input
                      type="number"
                      class="form-control rounded-end flag-input"
                      value={hoteldetails.phone_no}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Reservation Email{" "}
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    value={hoteldetails.reservation_email}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Address of Hotel{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={hoteldetails.address}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label be_form_label">
                    Extranet Languages{" "}
                  </label>
                  <MultiSelect
                    options={extra}
                    value={extra}
                    labelledBy="Select"
                    selectionType="counter"
                    allowCustomValue={true}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label be_form_label">
                    Booking engine Languages{" "}
                  </label>
                  <MultiSelect
                    options={be_lang}
                    value={be_lang}
                    labelledBy="Select"
                    selectionType="counter"
                    allowCustomValue={true}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    License Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={hoteldetails.licence_number}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Vat Registration Number{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={hoteldetails.vat_registration_number}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label be_form_label">
                    Property Type{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={propertytypehotel.label}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label be_form_label">Category</label>
                  <input
                    type="text"
                    className="form-control"
                    value={category.label}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label be_form_label">
                    Default Currency{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={currency.label}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Minimum Room rate{" "}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    value={hoteldetails.min_room_rate}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Maximum Room rate{" "}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    value={hoteldetails.max_room_rate}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Hotel Image
                  </label>
                  <div className="row">
                    {hotelimg.map((image, i) => {
                      return (
                        <div className="col-md-4 mt-2" key={i}>
                          <img
                            src={`${Commonimagepath.apiurl}${image}`}
                            alt=""
                            style={{ width: "100px", display: "block" }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Room Image
                  </label>
                  <div className="row">
                    {roomimg.map((image, i) => {
                      return (
                        <div className="col-md-4 mt-2" key={i}>
                          <img
                            src={`${Commonimagepath.apiurl}${image}`}
                            alt=""
                            style={{ width: "100px", display: "block" }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Logo{" "}
                  </label>
                  <img
                    src={`${Commonimagepath.apiurl}${hoteldetails.hotel_logo}`}
                    alt=""
                    style={{ width: "100px", display: "block" }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    No Of Rooms
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={hoteldetails.no_of_rooms}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Website URL
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={hoteldetails.website_url}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="emailidInput"
                    className="form-label be_form_label"
                  >
                    Extranet Admin Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    id="emailidInput"
                    value={hoteldetails.extranet_admin_email}
                    name="extranet_admin_email"
                  />
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="mb-3">
                  <label for="emailidInput" className="form-label be_form_label">
                  How many extranet login{" "}
                   </label>                  
                  <input
                    type="number"
                    className="form-control"
                    placeholder=""
                    id="emailidInput"
                    value={hoteldetails.extranet_login_nos}
                  
                    name="extranet_login_nos"
                  />
              
                </div>
              </div> */}

              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="firstNameinput"
                    className="form-label be_form_label"
                  >
                    Do you use a channel manager?{" "}
                  </label>
                  <span
                    className="form-check form-switch form-switch-lg mb-3"
                    dir="ltr"
                  >
                    <input
                      type="checkbox"
                      className="form-check-input loyalty_expiration"
                      value={hoteldetails.channel_manager_status}
                      checked={
                        hoteldetails.channel_manager_status === true
                          ? true
                          : false
                      }
                    />
                  </span>
                </div>
              </div>
              {hoteldetails.channel_manager_status === true ? (
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      for="emailidInput"
                      className="form-label be_form_label"
                    >
                      Name of Channel Manager
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={channel.label}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="firstNameinput"
                    className="form-label be_form_label"
                  >
                    Do you use a PMS{" "}
                    <span className="form-validation-required">*</span>
                  </label>
                  <span
                    className="form-check form-switch form-switch-lg mb-3"
                    dir="ltr"
                  >
                    <input
                      type="checkbox"
                      className="form-check-input loyalty_expiration"
                      value={hoteldetails.pms_status}
                      checked={hoteldetails.pms_status === true ? true : false}
                    />
                  </span>
                </div>
              </div>
              {hoteldetails.pms_status === true ? (
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      for="emailidInput"
                      className="form-label be_form_label"
                    >
                      Name of the PMS
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      value={pms.label}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    for="firstNameinput"
                    className="form-label be_form_label"
                  >
                    Do you accepts credit card ?{" "}
                    <span className="form-validation-required">*</span>
                  </label>

                  <span
                    className="form-check form-switch form-switch-lg mb-3"
                    dir="ltr"
                  >
                    <input
                      type="checkbox"
                      className="form-check-input loyalty_expiration"
                      value={hoteldetails.credit_card_status}
                      checked={
                        hoteldetails.credit_card_status === true ? true : false
                      }
                    />
                  </span>
                </div>
              </div>
              {credit.length > 0 ? (
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      for="emailidInput"
                      className="form-label be_form_label"
                    >
                      Accepted credit cards ?{" "}
                    </label>
                    <MultiSelect
                      options={credit}
                      value={credit}
                      labelledBy="Select"
                      selectionType="counter"
                      allowCustomValue={true}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="padding-10"></div>
          </form>
          <div className="tab_nav_section">
            <div className="tab_nav_section_btns">
              <button
                type="button"
                className="btn btn-success btn-be-common"
                onClick={() => setTab("Room-info")}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TempHotelinformation;
