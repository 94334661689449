import React, { useState } from 'react'
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
  } from "reactstrap";


function Settings() {
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
      setIsProfileDropdown(!isProfileDropdown);
    };
  return (
<Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className=" header-item topbar-user"
      >                {/* <button
                   
                    type="button"
                    className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                >
                     <i className="ri-settings-5-fill fs-22"></i>
                    
                </button> */}



                <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
          <i className="ri-settings-5-fill fs-22"></i>
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
               
              </span>
              <span className="d-none d-xl-block ms-1 fs-12  user-name-sub-text" >
                             </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome </h6>
          <DropdownItem >
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Profile</span>
          </DropdownItem>
          {/* <DropdownItem href={process.env.PUBLIC_URL + "/apps-chat"}>
            <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Messages</span>
          </DropdownItem>
          <DropdownItem href="#">
            <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Taskboard</span>
          </DropdownItem>
          <DropdownItem href={process.env.PUBLIC_URL + "/pages-faqs"}>
            <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Help</span>
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem href={process.env.PUBLIC_URL + "/pages-profile"}>
            <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">
              Balance : <b>$5971.67</b>
            </span>
          </DropdownItem> */}
          
          {/* <DropdownItem
           
          >
           
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Settings</span>
          </DropdownItem> */}
          {/* <DropdownItem
            href={process.env.PUBLIC_URL + "/auth-lockscreen-basic"}
          >
            <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Lock screen</span>
          </DropdownItem> */}
          <DropdownItem href="#">
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
           </Dropdown>
  )
}

export default Settings
