import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Basicsettingsheader from '../Common/Basicsettingsheader';
import Commonheader from '../Common/Commonheader';
import Footer from '../Common/Footer';
import Currencylist from './Currency/Currencylist';

function Basicsettings() {
    const [active,setActive]=useState("country");
  return (
    <div>
        
        <Currencylist/>
      {/* <div className="condition-top-menu">
                <div className="row">
                    <div className="condition-tabs-section">
                        <ul className="nav nav-tabs condtion-tabs" role="tablist">
                            <li className="nav-item tabs-menu">
                                <Link className={active==="country"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setActive("country")}>
                                   Country
                                </Link>
                            </li>
                            <li className="nav-item tabs-menu">
                                <Link className={active==="time"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setActive("time")} >
                                    Timezone
                                </Link>
                            </li>
                            <li className="nav-item tabs-menu">
                                <Link className={active==="currency"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setActive("currency")} >
                                  Currency
                                </Link>
                            </li>
                            <li className="nav-item tabs-menu">
                                <Link className={active==="card"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setActive("card")} >
                                   Cards
                                </Link>
                            </li>
                            <li className="nav-item tabs-menu">
                                <Link className={active==="city"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setActive("city")}>
                                    City
                                </Link>
                            </li>
                            <li className="nav-item tabs-menu">
                                <Link className={active==="star"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setActive("star")}>
                                Hotel Star Category
                                </Link>
                            </li>
                            <li className="nav-item tabs-menu">
                                <Link className={active==="board"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setActive("board")} >
                                Meal Board
                                </Link>
                            </li>
                            <li className="nav-item tabs-menu">
                                <Link className={active==="hk"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setActive("hk")}>
                                HK freequency
                                </Link>
                            </li><li className="nav-item tabs-menu">
                                <Link className={active==="service"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setActive("service")} >
                                   Services
                                </Link>
                            </li>
                            <li className="nav-item tabs-menu">
                                <Link className={active==="facility"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setActive("facility")} >
                                Facility
                                </Link>
                            </li>

                            <li className="nav-item tabs-menu">
                                <Link className={active==="bed"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setActive("bed")} >
                                Bed Type
                                </Link>
                            </li><li className="nav-item tabs-menu">
                                <Link className={active==="aminity"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setActive("aminity")} >
                                  Aminities
                                </Link>
                            </li>
                            <li className="nav-item tabs-menu">
                                <Link className={active==="ptype"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setActive("ptype")} >
                                Property Type
                                </Link>
                            </li>
                            <li className="nav-item tabs-menu">
                                <Link className={active==="pmethod"?"nav-link active":"nav-link"}  to="#" onClick={(e)=>setActive("pmethod")} >
                                Payment Method
                                </Link>
                            </li>

                        </ul>

                    </div>
                </div>

                {active==="country"?<Countries/>:""}
      </div> */}


     
    </div>
  )
}

export default Basicsettings
