import React from "react";
import { Link } from "react-router-dom";
import Commonheader from "../Commonheader";
import Date from "../Date";
import Footer from "../Footer";
import HotelOverviews from "./HotelOverviews";

function HotelStatistics() {
  return (
    <div>
      <div id="layout-wrapper">
       <Commonheader/>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
            
              <div className="row project-wrapper">
                <div className="row dashboard-top-tab-style">
                  <div className="col-xxl-9 dashboard-main-style">
                    <div className="col-xxl-8">
                      <HotelOverviews />
                    </div>
                  </div>
                  <div className="col-xxl-3 dashboard-main-style">
                    <div className="col-sm-auto">
                      {/* <Date /> */}
                    </div>
                  </div>
                  <div className="col-xxl-12 dashboard-main-style">
                    <div className="row dashboard-statistics-style">
                      <div className="col-lg-3 padding-0">
                        <div
                          className="nav nav-statistics flex-column nav-pills-tab text-center"
                          role="tablist"
                          aria-orientation="vertical"
                        >
                          <a
                            className="nav-link active show"
                            id="custom-v-pills-home-tab"
                            data-bs-toggle="pill"
                            href="#custom-v-pills-home"
                            role="tab"
                            aria-controls="custom-v-pills-home"
                            aria-selected="true"
                          >
                            INITIAL RESERVATION / REVENUE
                          </a>
                          <a
                            className="nav-link"
                            id="custom-v-pills-home-tab"
                            data-bs-toggle="pill"
                            href="#custom-v-pills-home"
                            role="tab"
                            aria-controls="custom-v-pills-home"
                            aria-selected="false"
                          >
                            CANCELLATIONS / REVENUE
                          </a>
                          <a
                            className="nav-link"
                            id="custom-v-pills-home-tab"
                            data-bs-toggle="pill"
                            href="#custom-v-pills-home"
                            role="tab"
                            aria-controls="custom-v-pills-home"
                            aria-selected="false"
                          >
                            ROOM NIGHTS /ARR
                          </a>
                          <a
                            className="nav-link"
                            id="custom-v-pills-home-tab"
                            data-bs-toggle="pill"
                            href="#custom-v-pills-home"
                            role="tab"
                            aria-controls="custom-v-pills-home"
                            aria-selected="true"
                          >
                            UPSELL /REVENUE
                          </a>
                          <a
                            className="nav-link"
                            id="custom-v-pills-home-tab"
                            data-bs-toggle="pill"
                            href="#custom-v-pills-home"
                            role="tab"
                            aria-controls="custom-v-pills-home"
                            aria-selected="false"
                          >
                            ORIGINAL RESERVATIONS /REVENUE
                          </a>
                          <a
                            className="nav-link"
                            id="custom-v-pills-home-tab"
                            data-bs-toggle="pill"
                            href="#custom-v-pills-home"
                            role="tab"
                            aria-controls="custom-v-pills-home"
                            aria-selected="false"
                          >
                            PARITY / REVENUE
                          </a>
                          <a
                            className="nav-link"
                            id="custom-v-pills-home-tab"
                            data-bs-toggle="pill"
                            href="#custom-v-pills-home"
                            role="tab"
                            aria-controls="custom-v-pills-home"
                            aria-selected="true"
                          >
                            COMMISSION / HOTEL
                          </a>
                          <a
                            className="nav-link"
                            id="custom-v-pills-home-tab"
                            data-bs-toggle="pill"
                            href="#custom-v-pills-home"
                            role="tab"
                            aria-controls="custom-v-pills-home"
                            aria-selected="false"
                          >
                            RETRIEVED RESERVATION / REVENUE
                          </a>
                          <a
                            className="nav-link"
                            id="custom-v-pills-home-tab"
                            data-bs-toggle="pill"
                            href="#custom-v-pills-home"
                            role="tab"
                            aria-controls="custom-v-pills-home"
                            aria-selected="false"
                          >
                            LOYALTY
                          </a>
                        </div>
                      </div>
                      <div className="col-lg-9 padding-0">
                        <div className="tab-content bg-white statistics-tab-content text-muted mt-3 mt-lg-0">
                          <div
                            className="tab-pane fade active show"
                            id="custom-v-pills-home"
                            role="tabpanel"
                            aria-labelledby="custom-v-pills-home-tab"
                          >
                            <h2 className="st_comparision_title">
                              Cancelations / Revenue
                            </h2>
                            <div className="statistics-comparision">
                              <div
                                id="multi_chart"
                                data-colors='["--vz-primary", "--vz-info", "--vz-success"]'
                                className="apex-charts"
                                dir="ltr"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
}

export default HotelStatistics;
