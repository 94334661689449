import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import Config from "../../../Config";

function Extrabedlist({
  extrabedshow,
  setExtrabedshow,
  boardallrate,
  extrabedratecurrency,
  tabextra,
  setTabextra,
}) {
  console.log(boardallrate);

  //  const [tabextra, setTabextra] = useState([]);

  let handleClickroomextra = (i) => {
    let newval = [...tabextra];
    tabextra[i]["click"] = !tabextra[i]["click"];
    setTabextra(newval);
  };
  console.log(tabextra);

  return (
    <Modal
      id="flipModal"
      className="new_modal_12"
      isOpen={extrabedshow}
      toggle={() => {
        setExtrabedshow(false);
      }}
      modalClassName="flip"
      centered
      style={{
        fontFamily: "IBM Plex Sans,sans-serif",
        justifyContent: "center",
      }}
    >
      <ModalBody className="modal-body text-center">
        <>
          <div className="table-responsive">
            <table className="table room-price-table table-bordered table-nowrap align-middle mb-0">
              <thead>
                <tr>
                  <th scope="col" className="stickyclass">
                    <p
                      className="room-available-filtersec-head"
                      style={{ width: "200px", marginBottom: "5px" }}
                    >
                      Rooms
                    </p>
                  </th>

                  <th scope="col" className="th-element">
                    First Bed
                  </th>

                  <th scope="col" className="th-element">
                    Second Bed
                  </th>

                  <th scope="col" className="th-element">
                    Third Bed
                  </th>
                  <th scope="col" className="th-element">
                    Fourth Bed
                  </th>
                  <th scope="col" className="th-element">
                    Fifth Bed
                  </th>
                </tr>
              </thead>

              {boardallrate?.length > 0 ? (
                boardallrate?.map((data, i) => {
                  return (
                    <tbody key={i}>
                      <tr className="extrabed_list_cls">
                        {/* <th
                                          
                                          className="price-inner-table stickyclass"
                                        > */}
                        <table className="table room-price-inner-table table-bordered table-nowrap align-middle mb-0">
                          <tbody>
                            <tr>
                              <td>
                                <h6 className="price-sec-room-title ">
                                  <span
                                    className="room-default-expand"
                                    id="room1"
                                    onClick={(e) => handleClickroomextra(i)}
                                  >
                                    <span className="room-price-expanded ri-arrow-down-s-line text-dark"></span>
                                    {data.room_name}
                                  </span>
                                </h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        {/* <table className="table room-price-inner-table table-bordered table-nowrap align-middle mb-0">
                                            <tbody> */}
                        {/* <tr> */}
                        {/* <td >
                                                <h6 className="price-sec-room-title"> */}
                        {/* <span
                                                      className="room-default-expand"
                                                      id="room1"
                                                      onClick={(e) =>
                                                        handleClickroomextra(i)
                                                      }
                                                    >
                                                      <span className="room-price-expanded ri-arrow-down-s-line text-dark"></span> */}

                        {/* {type.type==="per_person"?"Per Person":"Per Room"} */}

                        {/* </span> */}
                        {/* </h6>
                                                 </td> */}

                        {/* </tr> */}
                        {/* </tbody>
                                          </table> */}
                        {/* </th> */}
                      </tr>
                      {data.types.map((type, intype) => {
                        return (
                          <>
                            {tabextra[i]?.click ? (
                              <tr className="extrabed_list_cls_12">
                                <td>
                                  {type.type === "per_person"
                                    ? "Per Person"
                                    :type.type === "per_room"
                                    ? "Per Room"
                                    :type.type === "per_adult"
                                    ? "Per Adult"
                                    :type.type === "per_child"
                                    ? "Per Child":""}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {type?.ratelists?.length > 0 ? (
                              <>
                                {tabextra[i]?.click ? (
                                  <>
                                    {type?.ratelists.map((rate, ind) => {
                                      return (
                                        <>
                                          {rate.hotel_board.map(
                                            (board, indx) => {
                                              return (
                                                <>
                                                  {board.occupancies.map(
                                                    (occupancies, index) => {
                                                      return (
                                                        <tr
                                                          key={indx}
                                                          // className="room-price-expanded-sec"
                                                          id="room1_default"
                                                        >
                                                          <th
                                                            className="stickyclass"
                                                            style={{
                                                              textAlign: "left",
                                                            }}
                                                          >
                                                            <span
                                                              className="price-td-rates"
                                                              style={{
                                                                paddingLeft:
                                                                  "20px",
                                                              }}
                                                            >
                                                              {
                                                                rate.internal_name
                                                              }{" "}
                                                              (
                                                              {
                                                                extrabedratecurrency.code
                                                              }
                                                              )
                                                            </span>
                                                            <span className="price-td-boards">
                                                              {" "}
                                                              {board.name}
                                                            </span>

                                                            <span
                                                              className="price-td-occupancy-in"
                                                              style={{
                                                                float: "right",
                                                                paddingRight:
                                                                  "15px",
                                                              }}
                                                            >
                                                              {occupancies.adult +
                                                                occupancies.child >
                                                                4 && (
                                                                <>
                                                                  {occupancies.adult >
                                                                    0 && (
                                                                    <>
                                                                      <img
                                                                        src="images/icon/user.png"
                                                                        alt="Adult"
                                                                        style={{
                                                                          paddingLeft:
                                                                            "1px",
                                                                        }}
                                                                      />
                                                                      (
                                                                      {
                                                                        occupancies.adult
                                                                      }
                                                                      )
                                                                    </>
                                                                  )}{" "}
                                                                  {"x"}
                                                                  {occupancies.child >
                                                                    0 && (
                                                                    <>
                                                                      <img
                                                                        src="images/icon/user.png"
                                                                        alt="Child"
                                                                        className="child-icon"
                                                                        style={{
                                                                          marginLeft:
                                                                            "2px",
                                                                        }}
                                                                      />
                                                                      (
                                                                      {
                                                                        occupancies.child
                                                                      }
                                                                      )
                                                                    </>
                                                                  )}
                                                                </>
                                                              )}

                                                              {occupancies.adult +
                                                                occupancies.child <=
                                                                4 && (
                                                                <>
                                                                  {Array.from(
                                                                    {
                                                                      length:
                                                                        occupancies.adult,
                                                                    },
                                                                    (
                                                                      _,
                                                                      index
                                                                    ) => (
                                                                      <img
                                                                        key={`adult-${index}`}
                                                                        src="images/icon/user.png"
                                                                        alt="Adult"
                                                                        style={{
                                                                          paddingLeft:
                                                                            "1px",
                                                                        }}
                                                                      />
                                                                    )
                                                                  )}
                                                                  {Array.from(
                                                                    {
                                                                      length:
                                                                        occupancies.child,
                                                                    },
                                                                    (
                                                                      _,
                                                                      index
                                                                    ) => (
                                                                      <img
                                                                        key={`child-${index}`}
                                                                        src="images/icon/user.png"
                                                                        alt="Child"
                                                                        className="child-icon"
                                                                        style={{
                                                                          marginLeft:
                                                                            "2px",
                                                                        }}
                                                                      />
                                                                    )
                                                                  )}
                                                                </>
                                                              )}
                                                            </span>
                                                            {/* <span style={{display:"block",textAlign:"center"}}>{" " }({type.type==="per_person"?"Per Person":"Per Room"})</span> */}
                                                          </th>
                                                          <td>
                                                            {
                                                              occupancies.first_bed
                                                            }
                                                          </td>
                                                          <td>
                                                            {
                                                              occupancies.second_bed
                                                            }
                                                          </td>
                                                          <td>
                                                            {
                                                              occupancies.third_bed
                                                            }
                                                          </td>
                                                          <td>
                                                            {
                                                              occupancies.fourth_bed
                                                            }
                                                          </td>
                                                          <td>
                                                            {
                                                              occupancies.fifth_bed
                                                            }
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </>
                                              );
                                            }
                                          )}
                                          <tr className="span_separate"></tr>
                                        </>
                                      );
                                    })}
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <tr>
                                <td colSpan={100} className="rate_col_clr">
                                  No data
                                </td>
                              </tr>
                            )}
                            {/* </>
                                        )
                                      })} */}
                          </>
                        );
                      })}
                    </tbody>
                  );
                })
              ) : (
                <tr>
                  <td style={{ textAlign: "center",padding:"20px" }} colSpan="6">No data found</td>
                </tr>
              )}
            </table>
          </div>
        </>
      </ModalBody>
    </Modal>
  );
}

export default Extrabedlist;
