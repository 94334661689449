import React, { useEffect, useState } from 'react'
import Nodatafound from '../Common/Nodatafound';
import ReactPaginate from 'react-paginate';
import Footer from '../Common/Footer';
import { useParams } from 'react-router-dom';
import Config from '../Config';
import Commonheader from '../Common/Commonheader';
import Loading from '../Loading';

function PropertyTypes() {

  let {property_type_id,type} = useParams();
  const [paginate, setPaginate] = useState([]);
  const [page,setPage]=useState(1);
  const [pageindex,setPageindex]=useState(1);
  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(false);

  const getData = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/type/${property_type_id}?page=1`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data1 = await res.json();
    const data = data1.data;
    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setList(data.data);
      setPaginate(data.last_page);
      setLoader(true);
    }
  };

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}hotel/type/${property_type_id}?page=${currentPage}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const data = await res.json();
    return data.data.data;
  };

  const handlePageClick = async (data) => {
  
    if(data===1){
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
      setList(commentsFormServer);
    }
    else{
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
      setList(commentsFormServer);
    }
  };

  useEffect(() => {
    getData();
  }, [property_type_id]);

  return (
    <div>
    <div id="layout-wrapper">
    <Commonheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12" >
                </div>
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Property Type - <span className='property_type_label'>{type}</span></h4>
                 </div>    
                  </div>
                <div className="row project-wrapper">
                    <div className="row dashboard-top-tab-style">
                    <div className="col-xxl-12 dashboard-main-style">
                        <div className="card">

                    {loader===false?
                      <Loading/>
                    :
                    <>
                    {list.length > 0 ? (
                        <div className="card-body pt-0">
                        <div>
                            <ul
                            className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                            role="tablist"
                            ></ul>

                            <div className="table-responsive table-card mb-1">
                            <table
                                className="table table-nowrap align-middle"
                                id="orderTable"
                            >
                                <thead className="text-muted table-light">
                                <tr className="text-uppercase">
                                    <th className="" data-sort="id">
                                    Sl No.
                                    </th>
                                    <th className="" data-sort="customer_name">
                                    Hotel Name
                                    </th>
                                    
                                </tr>
                                </thead>
                                <tbody className="list form-check-all">
                                {list.map((element, i) => {
                                    return (
                                    <tr key={i}>
                                        <td className="id">{pageindex===1 ? i+1: (pageindex-1) * 10 + i +1}</td>
                                        <td className="customer_name">
                                        {element.hotel_name}{" "}
                                        </td>
                                    </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                            </div>
                            {paginate > 1 ? (
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={paginate}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={handlePageClick}
                                containerClassName={
                                "pagination justify-content-end"
                                }
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                            ) : (
                            ""
                            )}
                        </div>
                        </div>
                        ) : (
                        <div className="card-body pt-0">
                            <Nodatafound/>
                        </div>
                    )} 
                    </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
       </div>
        <Footer />
      </div>
    </div>
    </div>
  )
}

export default PropertyTypes