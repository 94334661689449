import React, { useEffect, useState } from 'react'
import { MultiSelect } from 'react-multi-select-component';
import { Link, useNavigate } from 'react-router-dom';
import Alerts from '../../Alerts';
import Basicsettingsheader from '../../Common/Basicsettingsheader';
import Footer from '../../Common/Footer';
import Config from '../../Config';

function Policycontractadd() {
  let navigate = useNavigate();

    const[policy,setPolicy]=useState("")
const[tab,setTab]=useState("EN")
  const [status, setStatus] = useState("");
  const [formerror, setError] = useState({});
  const [formerror1, setError1] = useState({});
  const [language, setLanguage] = useState("");
  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");

  const [selectedMulti, setselectedMulti] = useState([]);
  
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }

  
  const getValue = async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers:
      {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setLanguage(data.data);
      
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values) {
      errors.policy = "Description is required!";
    }
    return errors;
  };
 const validate1 = (values,type,title) => {
    const errors = {};
    if (!values) {
      errors.status = "Status is required!";
    }
    if (!type) {
      errors.type = "Type is required!";
    }
    if (!title) {
      errors.title = "Title is required!";
    }
    return errors;
    
  };
  
const PostData = async (e) => {
  e.preventDefault();
  const ress = validate(policy);
  const ress1=validate1(status,type,title)
  setError(ress);
  setError1(ress1);
  let lang = [];


  for (let i = 0; i < selectedMulti.length; i++) {
    if (selectedMulti[i].name) {
      if (selectedMulti[i].code !== "en") {
        let multiarray = {};
        multiarray.lang_code = selectedMulti[i].code;
        multiarray.content = selectedMulti[i].name;
        multiarray.type = selectedMulti[i].type;
        multiarray.title = selectedMulti[i].title;

        
        lang.push(multiarray);
      }
    }
  }
if (!ress.board && !ress1.status && !ress1.type) {
  
    const res = await fetch(`${Config.apiurl}admin/policy-contract/add`, {
      method: "POST",
      body: JSON.stringify({
        content: policy,
        type:type,
        title:title,
        multi_lang: lang,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await res.json();
        if (data.status_code !== "200" || !data) {
          setAlert(true);
          setAlertmessage(data.message);
          setOpen(true);
          setAlerttype("error");
        } else {
          setAlert(true);
          setAlertmessage(data.message);
          setOpen(true);
          setAlerttype("success");
          setTimeout(() => navigate("/basic_settings/policy-contract-list"), 3000);

         
          Reset();
         
        }
      
  }
};

function Reset() {
  setLanguage("");
  setPolicy("");
  setStatus("");
  setselectedMulti("");
}


useEffect(() => {
  getValue();
}, []);
  return (
    <div id="layout-wrapper">
     <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Policy Contract</h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Settings</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Basic Settings </Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="/service-list">Service</Link>
                      </li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>
            {alert ? 
                <Alerts type={alerttype} message={alertmessage} open={open} />
              : 
                ""}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create New Service
                      </h4>
                      <div className="flex-shrink-0">
                        <Link
                          type="button"
                          className="btn db-other-button"
                          to="/basic_settings/policy-contract-list"
                          style={{ marginRight: "5px" }}
                        >
                          Policy Contract
                        </Link>
                        {/* <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to="" 
                        >
                          Cancel
                        </button> */}
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target=""onClick={PostData}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-translation-style">
                                <ul>
                                  <li className={tab==="EN" ?"active" :""}onClick={(e)=>setTab("EN")}>EN</li>
                                  <li  className={tab==="TRANS" ?"active" :""} onClick={(e)=>setTab("TRANS")}>
                                  
                                      Translations
                                  
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {tab==="EN"?
                            <>
                              <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Type
                            <span className="form-validation-required">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                              value={type}
                              onChange={(e)=>setType(e.target.value)}
                                          />
                              </div>
                              <label style={{ color: "red" }}>
                                    {formerror1.type}
                                  </label>
                            </div>

                            <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Title
                            <span className="form-validation-required">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                              value={title}
                              onChange={(e)=>setTitle(e.target.value)}
                                          />
                              </div>
                              <label style={{ color: "red" }}>
                                    {formerror1.title}
                                  </label>
                            </div>
                             <div class="col-lg-12">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Description
                            <span className="form-validation-required">*</span>
                              </label>
                            
                              <textarea className="form-control " 
                              rows="3" cols="50"
                              value={policy}
                                          name="description"
                                          onChange={(e) => setPolicy(e.target.value)}>
                                          </textarea>
                              </div>
                              <label style={{ color: "red" }}>
                                    {formerror.policy}
                                  </label>
                            </div>
                            <div class="col-lg-3">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Status
                            <span className="form-validation-required">*</span>
                              </label>
                                <select
                                  id="ForminputState"
                                  className="form-select"
                                  value={status}
                                  onChange={(e) => setStatus(e.target.value)}
                                >
                                  <option selected>Choose...</option>
                                  <option value={1}>Active</option>
                                  <option value={0}>Inactive</option>
                                </select>
                              </div>
                              <label style={{ color: "red" }}>
                                    {formerror1.status}
                                  </label>
                            </div>
                            </>:<>
                            <div class="col-lg-4" style={{marginTop:"20px"}}>
                                  <div className="input-group">
                                    <label className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default" style={{width:"130px"}}> Choose Language:</label>
                                  <div className="col-md-7">
                                  <MultiSelect
                                  options={language}
                                  value={selectedMulti}
                                  onChange={setselectedMulti}
                                  labelledBy="Select"
                                  selectionType="counter"
                                  closeOnChangedValue={false}
                                  aria-expanded="true"/>
                                </div>
                                </div>
                                </div>
                           
                            <div className="appendmore"></div>
                            {selectedMulti.length > 0
                              ? selectedMulti.map((element, index) => {
                                  return (
                                    <div
                                      key={index}
                                      class="col-md-12 translation-fill-section"
                                      id="lang_ +val"
                                      data-value="1" style={{marginTop:"20px"}}
                                    >
                                      <div class="translation-selected-lang">
                                        <p>
                                          <span class="translation-selected-lang-head">
                                            {element.code}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="row">
                                      <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Type
                            <span className="form-validation-required">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                value={
                                  selectedMulti[index].type
                                }
                                onChange={
                                  (e) =>
                                    (selectedMulti[index].type =
                                      e.target.value)}
                                          />
                              </div>
                             
                            </div>
                            <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Title
                            <span className="form-validation-required">*</span>
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder=""
                                value={
                                  selectedMulti[index].title
                                }
                                onChange={
                                  (e) =>
                                    (selectedMulti[index].title =
                                      e.target.value)}
                                          />
                              </div>
                             
                            </div>
                                      <div class="col-lg-10 mt-3">
                                      <div className="input-group">
                                    <label className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default">Description
                                    <span className="form-validation-required">*</span>
                                      </label>
                                      <textarea className="form-control "  rows="3" cols="50"
                                            value={
                                              selectedMulti[index].name
                                            }
                                            onChange={
                                              (e) =>
                                                (selectedMulti[index].name =
                                                  e.target.value)}/>
                                         
                                        
                                        </div>
                                      </div>
                                      </div>
                                    </div>
                                  );
                                })
                              : ""}
                            </>}
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  )
}

export default Policycontractadd
