import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { MultiSelect } from "react-multi-select-component";

function Offerstep3country({
  setTab,
  selectedroomocc,
  handleweekinput,
  currency,
  offer3fields,
  handleOptionChangeoffer3price,
  handleoffer3checkbox,
  handleoffer3change,
  exceptionstaydate,
  setExceptionstaydate,
  inputFieldsexception,
  addInputFieldoffer3,
  removeInputFieldsoffer3,
  handleChangedatefrom,
  handleChangetodate,
  handleChangediscount,
  exceptionnight,
  setExceptionnight,
  inputFieldsextra,
  addInputFieldextra,
  removeInputFieldsextra,
  handleChangedatefromextra,
  handleChangetodateextra,
  handleChangediscountsextra,
  countryOffer3,
  visibleItemsoffer3,
  handleloyalchangeoffer3country,
  showAlloffer3country,
  setShowAlloffer3country,
  handleInputChangeconutryvalue,
  // nextoffterstep3,
  nextoffterstep3rate,
  nextoffterstep3increment,
  nextoffterstep3freenight,
  nextoffterstep3extra,
  nextoffterstep3country,
  nextoffterstep3device,
  offer3error,
  handleoffer3weekcheck,
  tabroomweek,
  tabroom,
  handleTabweek,
  handleTab,
  handleClickroomweek,
  handleClickroom,
  handleweekinputextend,
  ratetab,
  setRatetab,
  offer3validrate,
  offer3validncrement,
  offer3validfreenight,
  offer3validextra,
  offer3validcountry,
  offer3validdivice,
}) {
  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [symbolsrate] = useState(["e", "E", "+", "-"]);

  const [multi, setMulti] = useState([]);

  const handleCalendarIconClick = (calendarId) => {
    const calendar = document.querySelector(`#flatpickr-${calendarId}`);
    if (calendar) {
      const isCalendarOpen = calendar._flatpickr.isOpen;
      const icon = document.querySelector(`#calendar-icon-${calendarId}`);
      if (icon) {
        icon.style.display = isCalendarOpen ? "none" : "inline-block";
      }
      calendar._flatpickr[isCalendarOpen ? "close" : "open"]();
    }
  };

  useEffect(() => {
    const numberInput = document.getElementById("myNumberField");

    if (numberInput) {
      const preventDefaultBehavior = (event) => {
        event.preventDefault();
      };

      numberInput.addEventListener("wheel", preventDefaultBehavior);
      numberInput.addEventListener("touchstart", (event) => {
        const initialTouchY = event.touches[0].clientY;

        numberInput.addEventListener("touchmove", (event) => {
          const currentY = event.touches[0].clientY;
          if (currentY !== initialTouchY) {
            event.preventDefault();
          }
        });
      });

      return () => {
        numberInput.removeEventListener("wheel", preventDefaultBehavior);
        numberInput.removeEventListener("touchstart", preventDefaultBehavior);
        numberInput.removeEventListener("touchmove", preventDefaultBehavior);
      };
    }
  }, []);

  console.log(multi);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="offer-create-section">
          <div className="offer-create-details">
            <div className="col-md-12">
              <h6 className="offer-step-desc">
                This enables you to provide targeted promotions, incentivize
                certain customer groups, and optimize revenue generation based
                on different pricing strategies tailored to each category.
              </h6>

              <div className="row">
                <div className="col-md-12">
                  <ul className="offer-create-list_rate">
                    <li
                      className={
                        ratetab === "rate"
                          ? "offer-step-list active"
                          : "offer-step-list"
                      }
                      onClick={(e) => setRatetab("rate")}
                    >
                      Rate
                    </li>

                    <li
                      className={
                        ratetab === "increment"
                          ? "offer-step-list active"
                          : "offer-step-list"
                      }
                      onClick={(e) => setRatetab("increment")}
                    >
                      Increment
                    </li>
                    <li
                      className={
                        ratetab === "free_nights"
                          ? "offer-step-list active"
                          : "offer-step-list"
                      }
                      onClick={(e) => setRatetab("free_nights")}
                    >
                      Free Nights
                    </li>
                    <li
                      className={
                        ratetab === "extra_discount"
                          ? "offer-step-list active"
                          : "offer-step-list"
                      }
                      onClick={(e) => setRatetab("extra_discount")}
                    >
                      Extra Discount
                    </li>
                    <li
                      className={
                        ratetab === "country"
                          ? "offer-step-list active"
                          : "offer-step-list"
                      }
                      onClick={(e) => setRatetab("country")}
                    >
                      Country Discount
                    </li>
                    <li
                      className={
                        ratetab === "device_discount"
                          ? "offer-step-list active"
                          : "offer-step-list"
                      }
                      onClick={(e) => setRatetab("device_discount")}
                    >
                      Device Discount
                    </li>
                  </ul>
                </div>
              </div>
              {ratetab === "country" ? (
                <>
                  <div className="offer-content-section">
                    <div className="offer-form-status-section">
                      <ul className="offer-create--rate-list">
                        <li>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input offer_country_check"
                              type="checkbox"
                              id="formCheck1"
                              onChange={(e) =>
                                handleoffer3checkbox(
                                  e.target.checked,
                                  "country_discount_status"
                                )
                              }
                              value={offer3fields.country_discount_status}
                              checked={
                                offer3fields.country_discount_status === true
                                  ? true
                                  : false
                              }
                            />

                            <label>By country...</label>
                          </div>
                          <div
                            className={
                              offer3fields.country_discount_status === true
                                ? "offer_country_expand row"
                                : "offer_country_expand d-none"
                            }
                          >
                            <div className="col-md-6 col-lg-4 col-xl-4">
                              <div className="input-group">
                                <label
                                  className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default"
                                >
                                  Select Country
                                </label>
                                <MultiSelect
                                  options={countryOffer3}
                                  value={multi}
                                  onChange={setMulti}
                                  labelledBy="Select"
                                  selectionType="counter"
                                  closeOnChangedValue={false}
                                  aria-expanded="true"
                                  hasSelectAll={false}
                                  className="offer_country_multi"
                                />
                              </div>
                            </div>
                            <div
                              className="col-md-12"
                              style={{ marginTop: "10px" }}
                            >
                              {multi.map((data, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="country_all_data col-md-3"
                                  >
                                    <div className="input-group ">
                                      <label
                                        className="input-group-text input-joint-color country_dis"
                                        id="inputGroup-sizing-default"
                                      >
                                        {data.label}
                                      </label>
                                      <span
                                        className="offer_freenight_discount_sec"
                                        style={{ width: "134px" }}
                                      >
                                        <div className="offer_freenight_discount">
                                          <input
                                            type="number"
                                            className="form-control box_style_is input_offer_length"
                                            id="myNumberField"
                                            value={data.discount}
                                            onKeyDown={(e) =>
                                              symbolsrate.includes(e.key) &&
                                              e.preventDefault()
                                            }
                                            min={0}
                                            onChange={(e) =>
                                              handleInputChangeconutryvalue(
                                                i,
                                                e.target.value,
                                                "discount"
                                              )
                                            }
                                            onFocus={(e) =>
                                              e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                  e.preventDefault();
                                                },
                                                { passive: false }
                                              )
                                            }
                                          />
                                          <div className="common_discount_parts">
                                            <div className="offer_common_discount_select">
                                              <select
                                                id="ForminputState"
                                                className="form-select box_style_is"
                                                value={data.country_unit_value}
                                                name="country_unit_value"
                                                onChange={(e) =>
                                                  handleInputChangeconutryvalue(
                                                    i,
                                                    e.target.value,
                                                    "country_unit_value"
                                                  )
                                                }
                                              >
                                                <option
                                                  value="currency"
                                                  selected
                                                >
                                                  {currency.code}
                                                </option>
                                                <option value="percentage">
                                                  %
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          {offer3error.country_discount ? (
                            <>
                              <div className="clearfix"> </div>
                              <label style={{ color: "red" }}>
                                {offer3error.country_discount}
                              </label>
                            </>
                          ) : (
                            ""
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="tab_nav_section">
                    <div className="tab_nav_section_btns">
                      <Link
                        className="btn btn-be-form-cancel btn-be-back"
                        to="#"
                        onClick={(e) => nextoffterstep3country("prev")}
                      >
                        Prev
                      </Link>
                      <Link
                        className="btn btn-success btn-be-common"
                        to="#"
                        onClick={(e) => nextoffterstep3country("next")}
                      >
                        Next
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Offerstep3country;
