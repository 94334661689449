import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Common/Footer";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import Commonheader from "../../Common/Commonheader";
import Basicsettingsheader from "../../Common/Basicsettingsheader";
function Hotelinclusionaddtrans() {
  const SingleOptions = [
    { value: "AR", label: "Arabic" },
    { value: "ES", label: "Spanish" },
    { value: "FR", label: "French" },
    { value: "AeR", label: "Arabic" },
    { value: "EeS", label: "Spanish" },
    { value: "FeR", label: "French" },
  ];
  const [selectedMulti, setselectedMulti] = useState([]);
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }
  return (
    <div id="layout-wrapper">
    <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">AMENITIES</h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Settings</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Basic Settings</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="/hotel-inclusion">AMENITIES</Link>
                      </li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create New Meal Board
                      </h4>
                      <div className="flex-shrink-0">
                        <Link
                          type="button"
                          className="btn db-other-button "
                          to="/basic_settings/hotel-inclusion"
                          style={{ marginRight: "5px" }}
                        >
                        AMENITIES
                        </Link>
                        <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target=""
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-translation-style">
                                <ul>
                                  <li>
                                    <Link to="/basic_settings/hotel-inclusion-add">EN</Link>
                                  </li>
                                  <li className="active">Translations</li>
                                </ul>
                              </div>
                            </div>
                            <div className="form-translation-choose-lang">
                              <span
                                for="firstNameinput"
                                className="form-label db-lang-choose"
                              >
                                Choose Language:{" "}
                              </span>
                              <div className="lang-choose-select-option">
                                <MultiSelect
                                  options={SingleOptions}
                                  value={selectedMulti}
                                  onChange={setselectedMulti}
                                  labelledBy="Select"
                                  selectionType="counter"
                                  closeOnChangedValue={true}
                                  aria-expanded="true"
                                />
                              </div>
                            </div>
                            <div className="appendmore"></div>
                            {selectedMulti.length > 0
                              ? selectedMulti.map((element, index) => {
                                  return (
                                    <div
                                      key={index}
                                      class="col-md-12 translation-fill-section"
                                      id="lang_ +val"
                                      data-value="1"
                                    >
                                      <div class="translation-selected-lang">
                                        <p>
                                          <span class="translation-selected-lang-head">
                                            {element.value}
                                          </span>
                                        </p>
                                      </div>

                                      <div class="col-6">
                                        <div class="mb-3">
                                          <label
                                            for="firstNameinput"
                                            class="form-label"
                                          >
                                            Name
                                            <span class="form-validation-required">
                                              *
                                            </span>
                                          </label>
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder=""
                                            min="1"
                                            max="5"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              : ""}
                            {/* <div
                              className="col-md-12 translation-fill-section"
                              id="lang_AR"
                              data-value="1"
                            >
                              <div className="translation-selected-lang">
                                <p>
                                  <span className="translation-selected-lang-head">
                                    AR
                                  </span>
                                </p>
                              </div>

                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label
                                    for="firstNameinput"
                                    className="form-label"
                                  >
                                    Room Title
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder=""
                                    min="1"
                                    max="5"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label
                                    for="lastNameinput"
                                    className="form-label"
                                  >
                                    Room Short Description
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <textarea
                                    className="db-desc-box"
                                    rows="3"
                                  ></textarea>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label
                                    for="firstNameinput"
                                    className="form-label"
                                  >
                                    Room Description{" "}
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <textarea
                                    className="db-desc-box"
                                    rows="3"
                                  ></textarea>
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-md-12 translation-fill-section"
                              id="lang_ES"
                              data-value="1"
                            >
                              <div className="translation-selected-lang">
                                <p>
                                  <span className="translation-selected-lang-head">
                                    ES
                                  </span>
                                </p>
                              </div>

                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label
                                    for="firstNameinput"
                                    className="form-label"
                                  >
                                    Room Title
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder=""
                                    min="1"
                                    max="5"
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label
                                    for="lastNameinput"
                                    className="form-label"
                                  >
                                    Room Short Description
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <textarea
                                    className="db-desc-box"
                                    rows="3"
                                  ></textarea>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label
                                    for="firstNameinput"
                                    className="form-label"
                                  >
                                    Room Description{" "}
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <textarea
                                    className="db-desc-box"
                                    rows="3"
                                  ></textarea>
                                </div>
                              </div>
                            </div> */}
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default Hotelinclusionaddtrans;
