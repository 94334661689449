import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../../Common/Footer";
import Config from "../../Config";
import Alerts from "../../Alerts";
import Commonheader from "../../Common/Commonheader";
import axios from "axios";
import Toastmodal from "../../Common/Toastmodal";
import { useAuthHandling } from "../../Common/useAuthHandling";

function Pagecontent() {
  let navigate = useNavigate();
  const location = useLocation();
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  let { page_id, slug, page_name } = useParams();
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [formerror, setError] = useState({});
  const [pagecontent, setPagecontent] = useState([]);
  const [be_lang, setBe_lang] = useState([]);

  const [inputFields, setInputFields] = useState([
    {
      language: "",
      sub: [
        {
          slug: "",
          name: "",
        },
      ],
    },
  ]);

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        language: "",
        sub: [
          {
            slug: "",
            name: "",
          },
        ],
      },
    ]);
  };

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const [inputFieldssub, setInputFieldssub] = useState([
    {
      slug: "",
      name: "",
    },
  ]);

  let array = [];
  const addInputFieldsub = (index) => {
    setInputFields((prevInputFields) => {
      const newInputFields = [...prevInputFields];
      const subArray = [...newInputFields[index].sub];

      subArray.push({
        slug: "",
        name: "",
      });

      newInputFields[index].sub = subArray;

      return newInputFields;
    });
  };

  const removeInputFieldssub = (index, i) => {
    setInputFields((prevInputFields) => {
      const newInputFields = [...prevInputFields];
      const subArray = [...newInputFields[index].sub];
      subArray.splice(i, 1);
      newInputFields[index].sub = subArray;
      return newInputFields;
    });
  };

  let name, value;
  const handleInput = (e) => {
    e.preventDefault();
    console.log(e);
    name = e.target.name;
    value = e.target.value;
    setPagecontent({ ...pagecontent, [name]: value });
  };

  const getinfo = async (e) => {
    const res = await fetch(`${Config.apiurl}booking-engine-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setBe_lang(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getinfo();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getinfo();
    } else {
      console.log("error");
    }
    // if (data.status === 404 || !data) {
    //   console.log("error");
    // } else {
    //   setBe_lang(data.data);
    // }
  };

  let handleChange = (i, e) => {
    let newval = [...inputFields];
    inputFields[i][e.target.name] = e.target.value;
    setInputFields(newval);
  };

  let handleChangesub = (index, i, e) => {
    let newval = [...inputFields];
    newval[index].sub[i][e.target.name] = e.target.value;
    setInputFields(newval);
  };

  const Add = async () => {
    let isFormValid = true;

    let array1=[];

    for (let i = 0; i < inputFields.length; i++) {
      let array = [];
      let err1={};
      for (let j = 0; j < inputFields[i].sub.length; j++) {
        let err = {};
       
        if (!inputFields[i].sub[j].slug) {
          isFormValid = false;
          err.slug = "Slug is required!";
        }
        if (!inputFields[i].sub[j].name) {
          isFormValid = false;
          err.name = "Text is required!";
        }
        array.push(err);
      }
      if (!inputFields[i].language) {
        isFormValid = false;
        err1.language = "Language is required!";
      }
      err1.file=array;
      array1.push(err1);
    }
    setError(array1);

    if (isFormValid === true) {
      const formData = new FormData();

      formData.append("page_id", page_id);
      formData.append("page_slug", slug);
      for (let i = 0; i < inputFields.length; i++) {
        for (let j = 0; j < inputFields[i].sub.length; j++) {
          formData.append(
            `translation[${i}][language]`,
            inputFields[i].language
          );
          formData.append(
            `translation[${i}][content][${j}][slug]`,
            inputFields[i].sub[j].slug
          );
          formData.append(
            `translation[${i}][content][${j}][title]`,
            inputFields[i].sub[j].name
          );
        }
      }
      axios
        .post(`${Config.apiurl}admin/page/content/add`, formData, {})
        .then((res) => {
          if (res.status_code === "200" ) {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(true);
            setTimeout(
              () =>
                navigate(
                  `/page-content-listing/${slug}/${page_id}/${page_name}`
                ),
              3000
            );
          }
          else if(res.reauth===true){
            handleUnauthorized();
            Add();
          }
          else {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(true);

          }
          
          // if (res.status_code !== "200" || !res) {
          //   setToastmodal(true);
          //   setToastmessage(res.message);
          //   setToasttype("error");
          //   setToastlarge(true);
          // } else {
          //   setToastmodal(true);
          //   setToastmessage(res.message);
          //   setToasttype("success");
          //   setToastlarge(true);
          //   setTimeout(
          //     () =>
          //       navigate(
          //         `/page-content-listing/${slug}/${page_id}/${page_name}`
          //       ),
          //     3000
          //   );
          // }
        });
    }
  };

  // const validate = (values) => {
  //   const errors = {};
  //   if (!values.title) {
  //     errors.title = "Title is required!";
  //   }
  //   if (!values.slug) {
  //     errors.slug = "Slug is required!";
  //   }
  //   if (!values.type) {
  //     errors.type = "Type is required!";
  //   }
  //   if (!values.status) {
  //     errors.status = "Status is required!";
  //   }
  //   if (!values.order) {
  //     errors.order = "Order is required!";
  //   }
  //   return errors;
  // };

  // const validate1 = (values) => {
  //   const errors = [{}];
  //   for (let i = 0; i < values.length; i++) {
  //     if (!values[i].language) {
  //       errors[i].language = "Language is required!";
  //     }
  //     if (!values[i].name) {
  //       errors[i].name = "Text is required!";
  //     }
  //     console.log(errors[i]);
  //     return errors[i];
  //   }
  // };

  // const PostData = async (e) => {
  //   e.preventDefault();
  //   const ress = validate(pagecontent);

  //   let lang = [];
  //   for (let i = 0; i < inputFields.length; i++) {
  //     if (inputFields[i].language) {
  //       if (inputFields[i].language !== "en") {
  //         let multiarray = {};
  //         multiarray.lang_code = inputFields[i].language;
  //         multiarray.title = inputFields[i].name;

  //         lang.push(multiarray);
  //       }
  //     }
  //   }
  //   setError(ress);
  //   if (
  //     !ress.title &&
  //     !ress.slug &&
  //     !ress.type &&
  //     !ress.status &&
  //     !ress.order
  //   ) {
  //     const res = await fetch(`${Config.apiurl}admin/page/content/add`, {
  //       method: "POST",
  //       body: JSON.stringify({
  //         en_title: pagecontent.title,
  //         slug: pagecontent.slug,
  //         type: pagecontent.type,
  //         status: pagecontent.status,
  //         sort_order: pagecontent.order,
  //         multi_lang: lang,
  //       }),
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         "Content-type": "application/json; charset=UTF-8",
  //       },
  //     });
  //     const data = await res.json();
  //     if (data.status_code !== "200" || !data) {
  //       setAlert(true);
  //       setAlertmessage(data.message);
  //       setOpen(true);
  //       setAlerttype("error");
  //     } else {
  //       setAlert(true);
  //       setAlertmessage(data.message);
  //       setOpen(true);
  //       setAlerttype("success");
  //       Reset();
  //       setTimeout(() => navigate("/page-content-listing"), 3000);
  //     }
  //   }
  // };

  useEffect(() => {
    getinfo();
  }, []);

  console.log(inputFields);
  console.log(formerror);
  return (
    <div id="layout-wrapper">
      <Commonheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Page Contents</h4>
                </div>
              </div>
            </div>

            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create Page Contents for {page_name} Page
                      </h4>
                      <div className="flex-shrink-0">
                      <Link
                          type="button"
                          className="btn db-other-button"
                          to={`/page-content-listing/${slug}/${page_id}/${page_name}`}
                          style={{ marginRight: "5px" }}
                        >
                          Page Content List
                        </Link>
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target=""
                          onClick={Add}
                        >
                          Save
                        </button>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12 d-flex justify-content-between">
                              <div
                                for="firstNameinput"
                                className="addmore_select-label "
                              >
                                Language{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                              </div>
                              <div
                                for="firstNameinput"
                                className="addmore_select-label"
                              >
                                Slug{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                              </div>
                              <div className="addmore_select-label ">
                                Text{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="clearfix"></div>

                            <div className="new_options  " id="new_option">
                              {inputFields.map((data, index) => {
                                return (
                                  <div key={index} className="">
                                    <div className="row ">
                                      <div className="col-md-3">
                                        <select
                                          id="ForminputState"
                                          className="form-select addmore_select"
                                          onChange={(e) =>
                                            handleChange(index, e)
                                          }
                                          value={data.language}
                                          name="language"
                                        >
                                          <option value="">
                                            Choose language
                                          </option>
                                          {be_lang.map((lang, indi) => {
                                            return (
                                              <option
                                                key={indi}
                                                value={lang.code}
                                              >
                                                {lang.label}
                                              </option>
                                            );
                                          })}
                                        </select>
                                        
                                                <label style={{ color: "red" }}>
                                                  {formerror[index]?.language}
                                                </label>
                                             
                                      </div>
                                      {data.sub.map((datas, i) => {
                                        return (
                                          <div
                                            key={i}
                                            className={
                                              i === 0
                                                ? "contentclass"
                                                : " displayclass-in"
                                            }
                                          >
                                            <div
                                              className={
                                                i === 0
                                                  ? "nothingclass"
                                                  : "col-md-3 nothingclass"
                                              }
                                            ></div>
                                            <div
                                              className={
                                                i === 0
                                                  ? "col-md-4 p_1"
                                                  : "col-md-4"
                                              }
                                            >
                                              <input
                                                type="text"
                                                onChange={(e) =>
                                                  handleChangesub(index, i, e)
                                                }
                                                value={datas.slug}
                                                name="slug"
                                                className="form-control addmore_select "
                                                placeholder="Enter ..."
                                                id=""
                                              />
                                             
                                                      <label
                                                        style={{ color: "red" }}
                                                      >
                                                        {formerror[index]?.file[i]?.slug}
                                                      </label>
                                                  
                                            </div>
                                            <div
                                              className={
                                                i === 0
                                                  ? "col-md-3 ml-2 p_2"
                                                  : "col-md-3 ml-2"
                                              }
                                            >
                                              <input
                                                type="text"
                                                onChange={(e) =>
                                                  handleChangesub(index, i, e)
                                                }
                                                value={datas.name}
                                                name="name"
                                                className="form-control addmore_select "
                                                placeholder="Enter ..."
                                                id=""
                                              />
                                            
                                                      <label
                                                        style={{ color: "red" }}
                                                      >
                                                        {formerror[index]?.file[i]?.name}
                                                      </label>
                                                  
                                            </div>
                                            <span
                                              className={
                                                i === data.sub.length - 1
                                                  ? "extrarate-room-link"
                                                  : "extrarate-room-link d-none"
                                              }
                                            >
                                              <button
                                                className="extra-rate-add-in"
                                                type="button"
                                                onClick={(e) =>
                                                  addInputFieldsub(index)
                                                }
                                                style={{ border: "none" }}
                                              >
                                                +
                                              </button>
                                            </span>

                                            <button
                                              className={
                                                i === 0
                                                  ? "remove_more-in d-none"
                                                  : "remove_more-in"
                                              }
                                              type="button"
                                              onClick={(e) =>
                                                removeInputFieldssub(index, i)
                                              }
                                              style={{ border: "none" }}
                                            >
                                              X
                                            </button>
                                          </div>
                                        );
                                      })}
                                    </div>
                                    <div className="d-flex">
                                      <button
                                        className={
                                          index === 0
                                            ? "remove_more-in d-none"
                                            : "remove_more-in"
                                        }
                                        type="button"
                                        style={{ border: "none" }}
                                        onClick={(e) =>
                                          removeInputFields(index)
                                        }
                                      >
                                        X
                                      </button>
                                    </div>
                                    <div
                                      className={
                                        index === inputFields.length - 1
                                          ? "boder1 d-none"
                                          : "boder1"
                                      }
                                    ></div>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="be-form-add-anthor-section">
                              <button
                                type="button"
                                className="extra-rate-add-in"
                                style={{ border: "none", marginLeft: "5px" }}
                                onClick={addInputField}
                              >
                                +
                              </button>
                            </div>

                            <div className="clearfix"></div>
                            <div className="appendmore"></div>
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Pagecontent;
