import React from 'react'
import Footer from '../../Common/Footer'
import Reservationheader from '../../Common/Reservationheader'
import { FaRegCreditCard } from "react-icons/fa";
function Blackcards() {
  return (
    <div className="main-content">
     <Reservationheader/>

     <div className="page-content reservation-page-content" style={{backgroundColor:"#f1f1f1"}}>
         <div className="container-fluid" style={{backgroundColor:"white",height:"300px"}}>
         <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title mt-2">Black Cards</h4>
                </div><br></br><br></br>
             
              <div className="black-card-new">Problems with a credit card?<br></br>
              Insert the number here and it will not be possible to use it again in our system.</div><br></br>
              <div className='d-flex'>
                <div className="input-group blackcard_input_grp" >
                  <div className="input-group-text input-joint-color">Credit Card Number</div>
                    <div className='blackcrad_portion'>
                      <input type='text' className="black-card-new-style" placeholder='0000 0000 0000 0000'/>
                      <div className='blackcard_card'>
                        <FaRegCreditCard />
                      </div>
                    </div>
                  </div>
                  <button className="modify-button">Check and Save</button>

                </div>
              </div>
          </div>
       {/* <div className="page-content reservation-page-content" style={{backgroundColor:"#f1f1f1"}}>
         <div className="container-fluid" style={{backgroundColor:"white",height:"300px"}}>
            <div className="project-wrapper">
              <div className="black-card-new">Problems with a credit card?<br></br>
              Insert the number here and it will not be possible to use it again in our system.</div><br></br>
             <div className="blackcard-new">Credit Card Number</div><br></br>
             <input type='text' className="black-card-new-inp"/><br></br><br></br>
             <button className="modify-button black-card-inp">Check and Save</button>
            </div>
        </div>
       </div> */}
<Footer />
</div>
  )
}

export default Blackcards
