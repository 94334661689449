import React, { useState } from 'react'
import { Link } from "react-router-dom";
import {NavLink} from 'react-router-dom'
function Sidebarroom({showhotel}) {

    return (
        <div>
        <div
          id="removeNotificationModal"
          className="modal fade zoomIn"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="NotificationModalbtn-close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="mt-2 text-center">
                  <lord-icon
                    src="https://cdn.lordicon.com/gsqxdxog.json"
                    trigger="loop"
                    colors="primary:#f7b84b,secondary:#f06548"
                    style={{ width: "100px", height: "100px" }}
                  ></lord-icon>
                  <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Are you sure ?</h4>
                    <p className="text-muted mx-4 mb-0">
                      Are you sure you want to remove this Notification ?
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <button
                    type="button"
                    className="btn w-sm btn-light"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn w-sm btn-danger"
                    id="delete-notification"
                  >
                    Yes, Delete It!
                  </button>
                </div>
              </div>
            </div>
            {/* /.modal-content  */}
          </div>
          {/* /.modal-dialog  */}
        </div>
        <div className="app-menu navbar-menu">
          {/* LOGO  */}
          <div className="navbar-brand-box">
            {/* Dark Logo */}
            <Link to="/dashboard" className="logo logo-dark">
              <span className="logo-sm">
                <img src="/images/sm-white.png" alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src="/images/logo-white.png" alt="" height="40" />
              </span>
            </Link>
            {/* Light Logo */}
            <Link to="/dashboard" className="logo logo-light">
              <span className="logo-sm">
                <img src="/images/sm-white.png" alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src="/images/logo-white.png" alt="" height="40" />
              </span>
            </Link>
            <button
              type="button"
              className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
              id="vertical-hover"
            >
              <i className="ri-record-circle-line"></i>
            </button>
          </div>
    
          <div id="scrollbar">
            <div className="container-fluid">
              <div id="two-column-menu"></div>
              <ul className="navbar-nav" id="navbar-nav">
                <li className="menu-title">
                  <span data-key="t-menu">Menu</span>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link menu-link common"
                    to="#"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
     
                  <span>Rooms</span>
                    <  i className="ri-arrow-right-line"></i>{" "}
                   
                   </Link>
                 </li>
                 <li className="nav-item">
                   <NavLink exact 
                    
                     className="nav-link menu-link common"
                     to="/rooms"
                     role="button"
                     aria-expanded="false"
                     aria-controls="sidebarMaps"
                   >
                  
                {/* < i className="ri-currency-line"></i>{" "} */}
                    <span data-key="t-maps">Room Name</span>
                  </NavLink>
                
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link common"
                    to="/room-photos"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarMaps" 
                  >
                    {/* <i className="ri-rocket-line"></i>{" "} */}
                    <span data-key="t-maps">Room Photos</span>
                  </NavLink>
                  
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link common"
                    to="/bed-configuration"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarMaps"
                  > 
                    {/* <i className="las la-pager"></i>{" "} */}
                    <span data-key="t-maps">Bed Configuration</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link common"
                    to="/all-facilities"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarMaps" 
                  >
                    {/* <i className="las la-pager"></i>{" "} */}
                    <span data-key="t-maps">All Facilities</span>
                  </NavLink>
                </li>
    
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link common"
                    to="/description"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarMaps"
                  >
                    {/* <i className="ri-briefcase-2-line"></i>{" "} */}
                    <span data-key="t-maps">Room Description</span>
                  </NavLink>
                </li>
    
    
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link common"
                    to="/facilities"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarMaps" 
                  >
                    {/* <i className="ri-briefcase-2-line"></i>{" "} */}
                    <span data-key="t-maps">Main Facilities</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
    
          <div className="sidebar-background"></div>
        </div>
        <div className="vertical-overlay"></div>
      </div> 
       )
}

export default Sidebarroom