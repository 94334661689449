import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Conditionsheader from "../../../Common/Conditionsheader";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import Flatpickr from "react-flatpickr";
import moment, { months } from "moment";
import dayjs from "dayjs";
import Alerts from "../../../Alerts";
import Config from "../../../Config";
import Loading from "../../../Loading";
import { UserContext } from "../../../Contexts/UserContext";
import Toastmodal from "../../../Common/Toastmodal";
import Footer from "../../../Common/Footer";
import Nodatafound from "../../../Common/Nodatafound";
import { useAuthHandling } from "../../../Common/useAuthHandling";

function Maximumstay() {
  const [roomtype, setRoomtype] = useState([]);
  const [board, setBoard] = useState([]);
  const [ratelist, setRatelist] = useState([]);
  const [occupancy, setOccupancy] = useState([]);
  const [maxstay, setMaxstay] = useState([]);
  const [maxstaylen, setMaxstaylen] = useState("");
  const [formerror, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const [selectedRooms, setselectedRooms] = useState([]);
  const [selectedRate, setselectedRate] = useState([]);
  const [selectedBoard, setselectedBoard] = useState([]);
  const [selectedOccupancy, setselectedOccupancy] = useState([]);
  const [selectedroom1, setSelectedroom1] = useState([]);
  const [selectedrate1, setSelectedrate1] = useState([]);
  const [selectedboard1, setSelectedboard1] = useState([]);
  const [selectedoccupancy1, setSelectedoccupancy1] = useState([]);
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const [id, setId] = useState(hotel_select ? hotel_select[0]?.value : "");
  const [hotelname, setHotelname] = useState(
    hotel_select ? hotel_select[0]?.label : ""
  );
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [toastmodal, setToastmodal] = useState(false);
  const [dateString1, setDateString1] = useState(
    moment().format("MMM DD, YYYY")
  );
  const firstDayOfNextMonth = moment().add(1, "month").startOf("month");
  const lastDayOfNextMonth = firstDayOfNextMonth.clone().endOf("month");
  const [dateString2, setDateString2] = useState(
    lastDayOfNextMonth.format("MMM DD, YYYY")
  );
  const [todaydate, setTodaydate] = useState(moment().format("MMM DD, YYYY"));
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [symbolsrate] = useState(["e", "E", "+", "-", "."]);

  const [saveddata, setSaveddata] = useState([]);

  const [selectedWeekdays, setSelectedWeekdays] = useState([]);
  const [clickedDays, setClickedDays] = useState([]);
  const [datefrom, setDateFrom] = useState(null);
  const [dateto, setDateTo] = useState(null);

  const handleCalendarChangeFrom = (selectedDates) => {
    setDateFrom(selectedDates[0]);
  };

  const handleCalendarChangeTo = (selectedDates) => {
    setDateTo(selectedDates[0]);
  };

  const [open1, setOpen1] = useState(false);
  function clickEventHandler() {
    setOpen1(!open1);
  }

  const [tab, setTab] = useState([]);

  let handleTab = (val, i) => {
    let newval = [...tab];
    tab[i]["tab"] = val;
    setTab(newval);
  };

  let handleClickroom = (i) => {
    let newval = [...tab];
    tab[i]["click"] = !tab[i]["click"];
    setTab(newval);
  };

  const handleInputChange = (i, ind, indx, index, indexx, value) => {
    const updatedValues = [...maxstay];
    updatedValues[i]["rates"][ind]["hotel_board"][indx]["occupancies"][index][
      "maximum_stays"
    ][indexx]["maximum_stay_days"] = value;

    updatedValues[i]["flag"] = true;
    updatedValues[i]["rates"][ind]["flag"] = true;
    updatedValues[i]["rates"][ind]["hotel_board"][indx]["flag"] = true;
    updatedValues[i]["rates"][ind]["hotel_board"][indx]["occupancies"][index][
      "flag"
    ] = true;

    updatedValues[i]["rates"][ind]["hotel_board"][indx]["occupancies"][index][
      "maximum_stays"
    ][indexx]["flag"] = true;

    setMaxstay(updatedValues);
  };

  const handleCalendarIconClick = (calendarId) => {
    const calendar = document.querySelector(`#flatpickr-${calendarId}`);
    if (calendar) {
      const isCalendarOpen = calendar._flatpickr.isOpen;
      const icon = document.querySelector(`#calendar-icon-${calendarId}`);
      if (icon) {
        icon.style.display = isCalendarOpen ? "none" : "inline-block";
      }
      calendar._flatpickr[isCalendarOpen ? "close" : "open"]();
    }
  };

  const getRoomtype = async () => {
    const res = await fetch(`${Config.apiurl}active-rooms/${id}`, {
      method: "POST",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setRoomtype(data);
    }
  };

  const getBoard = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/hotel-boards`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        getBoard();
      } else {
        setBoard(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getBoard();
    } else {
      console.log("error");
    }
  };

  const getRatelist = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/rate-list`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        getRatelist();
      } else {
        setRatelist(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getRatelist();
    } else {
      console.log("error");
    }
  };

  const getOccupancies = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/occupancies`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        getOccupancies();
      } else {
        setOccupancy(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getOccupancies();
    } else {
      console.log("error");
    }
  };

  const Bulkupdate = async (e) => {
    e.preventDefault();

    let isFormValid = true;
    const errors = {};

    if (selectedRooms.length === 0) {
      isFormValid = false;

      errors.selectedRooms = "Room is required!";
    }
    if (selectedRate.length === 0) {
      isFormValid = false;

      errors.selectedRate = "Rate list is required!";
    }
    if (selectedBoard.length === 0) {
      isFormValid = false;

      errors.selectedBoard = "Board is required!";
    }
    if (selectedOccupancy.length === 0) {
      isFormValid = false;

      errors.selectedOccupancy = "Occupancy is required!";
    }
    if (!datefrom) {
      isFormValid = false;
      errors.datefrom = "From date is required!";
    }
    if (!dateto) {
      isFormValid = false;
      errors.dateto = "To date is required!";
    }
    if (!maxstaylen) {
      isFormValid = false;
      errors.maxstaylen = "This field is required!";
    }

    setError(errors);
    console.log(errors);

    let roomVal = [];
    for (let i = 0; i < selectedRooms.length; i++) {
      roomVal.push(selectedRooms[i].value);
    }

    let rateVal = [];
    for (let i = 0; i < selectedRate.length; i++) {
      rateVal.push(selectedRate[i].value);
    }
    let boardVal = [];
    for (let i = 0; i < selectedBoard.length; i++) {
      boardVal.push(selectedBoard[i].value);
    }
    let occupancyVal = [];
    for (let i = 0; i < selectedOccupancy.length; i++) {
      occupancyVal.push(selectedOccupancy[i].value);
    }

    let exp = [];
    for (let i = 0; i < clickedDays.length; i++) {
      const numericValue = clickedDays[i];
      if (numericValue >= 0 && numericValue < weekdays.length) {
        const weekdayName = weekdays[numericValue];
        exp.push(weekdayName);
      }
    }

    if (isFormValid === true) {
      const res = await fetch(
        `${Config.apiurl}hotel/condition/maximum-stay/update`,
        {
          method: "POST",
          body: JSON.stringify({
            hotel_id: id,
            start_date: moment(datefrom).format("DD-MM-YYYY"),
            end_date: moment(dateto).format("DD-MM-YYYY"),
            rooms: roomVal,
            boards: boardVal,
            rates: rateVal,
            occupancies: occupancyVal,
            exception: exp,
            length_of_maximum_stay: maxstaylen,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200") {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else if (data.reauth === true) {
          handleUnauthorized();
          Bulkupdate();
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          setClickedDays([]);
          getMaxstay();
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Bulkupdate();
      } else {
        console.log("error");
      }
      // const data = await res.json();
      // if (res.status === 200) {
      //   setToastmodal(true);
      //   setToastmessage(data.message);
      //   setToasttype("success");
      //   setToastlarge(true);
      //   getMaxstay();
      //   setClickedDays([]);
      // } else {
      //   setToastmodal(true);
      //   setToastmessage(data.message);
      //   setToasttype("error");
      //   setToastlarge(true);
      // }
    }
  };

  let Startdatechange = moment(dateString1).format("DD-MM-YYYY");
  let Enddatechange = moment(dateString2).format("DD-MM-YYYY");

  const Filter = async () => {
    let roomval1 = [];
    for (let i = 0; i < selectedroom1.length; i++) {
      roomval1.push(selectedroom1[i].value);
    }

    let rateval1 = [];
    for (let i = 0; i < selectedrate1.length; i++) {
      rateval1.push(selectedrate1[i].value);
    }
    let boardval1 = [];
    for (let i = 0; i < selectedboard1.length; i++) {
      boardval1.push(selectedboard1[i].value);
    }
    let occupancyVal1 = [];
    for (let i = 0; i < selectedoccupancy1.length; i++) {
      occupancyVal1.push(selectedoccupancy1[i].value);
    }

    const res = await fetch(
      `${Config.apiurl}hotel/condition/maximum-stay/search`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
          rooms: roomval1,
          rates: rateval1,
          boards: boardval1,
          occupancy: occupancyVal1,
          from: Startdatechange,
          to: Enddatechange,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        Filter();
      } else {
        setMaxstay(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      Filter();
    } else {
      console.log("error");
    }
    // const data = await res.json();
    // if (res.status === 200) {
    //   console.log("error");
    // } else {
    //   setMaxstay(data.data);
    // }
  };

  const getMaxstay = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/condition/maximum-stay`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        start_date: Startdatechange,
        end_date: Enddatechange,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data1 = await res.json();
      const data = data1.data;
      if (data1.status_code !== "200" || !data) {
        console.log("error");
      } else if (data.length === 0) {
        setMaxstay([]);
        setLoading(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getMaxstay();
      } else {
        setMaxstay(data.data);
        setLoading(true);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getMaxstay();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;

    // if (data1.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   if (data.length === 0) {
    //     setMaxstay([]);
    //   } else {
    //     setMaxstay(data.data);
    //     setLoading(true);
    //   }
    // }
  };

  const Singlesave = async () => {
    setSaveddata(maxstay);

    console.log(maxstay);

    let newmin = [];
    for (let i = 0; i < maxstay.length; i++) {
      if (maxstay[i].flag && maxstay[i].flag === true) {
        for (let j = 0; j < maxstay[i].rates.length; j++) {
          if (maxstay[i].rates[j].flag && maxstay[i].rates[j].flag === true) {
            for (let k = 0; k < maxstay[i].rates[j].hotel_board.length; k++) {
              if (
                maxstay[i].rates[j].hotel_board[k].flag &&
                maxstay[i].rates[j].hotel_board[k].flag === true
              ) {
                for (
                  let l = 0;
                  l < maxstay[i].rates[j].hotel_board[k].occupancies.length;
                  l++
                ) {
                  if (
                    maxstay[i].rates[j].hotel_board[k].occupancies[l].flag &&
                    maxstay[i].rates[j].hotel_board[k].occupancies[l].flag ===
                      true
                  ) {
                    let multiarray = {};
                    multiarray.room = maxstay[i].room_id;
                    multiarray.rate = maxstay[i].rates[j].rate_id;
                    multiarray.board = maxstay[i].rates[j].hotel_board[k].board;
                    multiarray.occupancy =
                      maxstay[i].rates[j].hotel_board[k].occupancies[
                        l
                      ].occupancy_id;

                    let newminstay = [];
                    for (
                      let m = 0;
                      m <
                      maxstay[i].rates[j].hotel_board[k].occupancies[l]
                        .maximum_stays.length;
                      m++
                    ) {
                      let minstayarray = {};
                      if (
                        maxstay[i].rates[j].hotel_board[k].occupancies[l]
                          .maximum_stays[m].flag &&
                        maxstay[i].rates[j].hotel_board[k].occupancies[l]
                          .maximum_stays[m].flag === true
                      ) {
                        minstayarray.max_stay =
                          maxstay[i].rates[j].hotel_board[k].occupancies[
                            l
                          ].maximum_stays[m].maximum_stay_days;
                        let formattedDate = moment(
                          `${maxstay[i].rates[j].hotel_board[k].occupancies[l].maximum_stays[m].year}-${maxstay[i].rates[j].hotel_board[k].occupancies[l].maximum_stays[m].month}-${maxstay[i].rates[j].hotel_board[k].occupancies[l].maximum_stays[m].date}`
                        ).format("DD-MM-YYYY");
                        minstayarray.date = formattedDate;
                        newminstay.push(minstayarray);
                      }
                    }
                    multiarray.max_stay_condition = newminstay;
                    newmin.push(multiarray);
                  }
                }
              }
            }
          }
        }
      }
    }
    console.log(newmin);
    const res = await fetch(
      `${Config.apiurl}hotel/condition/maximum-stay/update/${id}`,
      {
        method: "POST",
        body: JSON.stringify({
          start_date: Startdatechange,
          end_date: Enddatechange,
          conditions: newmin,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        Singlesave();
      } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          getMaxstay();
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      Singlesave();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;
    // if (data1.status_code !== "200" || !data1) {
    //   setToastmodal(true);
    //   setToastmessage(data1.message);
    //   setToasttype("error");
    //   setToastlarge(true);
    // } else {
    //   setToastmodal(true);
    //   setToastmessage(data1.message);
    //   setToasttype("success");
    //   setToastlarge(true);
    //   getMaxstay();
    // }
  };

  const handleClickPrev = async () => {
    let first;
    const currentDate = moment().format("DD-MM-YYYY");

    first = moment(dateString1)
      .subtract(1, "M")
      .startOf("month")
      .format("MMM DD, YYYY");
    let firstfull = moment(dateString1)
      .subtract(1, "M")
      .startOf("month")
      .format("DD-MM-YYYY");
    let end = moment(first).endOf("month").format("MMM DD, YYYY");
    let endfull = moment(first).endOf("month").format("DD-MM-YYYY");

    if (
      moment(firstfull, "DD-MM-YYYY").isBefore(
        moment(currentDate, "DD-MM-YYYY")
      )
    ) {
      firstfull = currentDate;
      first = moment(currentDate, "DD-MM-YYYY").format("MMM DD, YYYY");
    }
    setDateString1(first);
    setDateString2(end);

    let roomval1 = [];
    if (selectedroom1.length > 0) {
      for (let i = 0; i < selectedroom1.length; i++) {
        let newval = {};
        newval = selectedroom1[i].value;
        roomval1.push(newval);
      }
    } else {
      for (let i = 0; i < roomtype.length; i++) {
        let newval = {};
        newval = roomtype[i].value;
        roomval1.push(newval);
      }
    }

    let rateval1 = [];
    if (selectedrate1.length > 0) {
      for (let i = 0; i < selectedrate1.length; i++) {
        let newval = {};
        newval = selectedrate1[i].value;
        rateval1.push(newval);
      }
    } else {
      for (let i = 0; i < ratelist.length; i++) {
        let newval = {};
        newval = ratelist[i].value;
        rateval1.push(newval);
      }
    }

    let boardval1 = [];
    if (selectedboard1.length > 0) {
      for (let i = 0; i < selectedboard1.length; i++) {
        let newval = {};
        newval = selectedboard1[i].value;
        boardval1.push(newval);
      }
    } else {
      for (let i = 0; i < board.length; i++) {
        let newval = {};
        newval = board[i].value;
        boardval1.push(newval);
      }
    }

    let occupancyVal1 = [];
    if (selectedoccupancy1.length > 0) {
      for (let i = 0; i < selectedoccupancy1.length; i++) {
        let newval = {};
        newval = selectedoccupancy1[i].value;
        occupancyVal1.push(newval);
      }
    } else {
      for (let i = 0; i < occupancy.length; i++) {
        let newval = {};
        newval = occupancy[i].value;
        occupancyVal1.push(newval);
      }
    }

    const res = await fetch(
      `${Config.apiurl}hotel/condition/maximum-stay/search`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
          rooms: roomval1,
          rates: rateval1,
          boards: boardval1,
          occupancy: occupancyVal1,
          from: firstfull,
          to: endfull,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        handleClickPrev();
      } else {
        setMaxstay(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      handleClickPrev();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;
    // if (data1.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   setMaxstay(data);
    //   console.log(maxstay);
    // }
  };

  const handleClickNext = async () => {
    let firstnext = moment(dateString1)
      .add(1, "M")
      .startOf("month")
      .format("MMM DD, YYYY");
    let firstnextfull = moment(dateString1)
      .add(1, "M")
      .startOf("month")
      .format("DD-MM-YYYY");
    let endnext = moment(firstnext).endOf("month").format("MMM DD, YYYY");
    let endnextfull = moment(firstnext).endOf("month").format("DD-MM-YYYY");
    setDateString1(firstnext);
    setDateString2(endnext);

    let roomval1 = [];
    if (selectedroom1.length > 0) {
      for (let i = 0; i < selectedroom1.length; i++) {
        let newval = {};
        newval = selectedroom1[i].value;
        roomval1.push(newval);
      }
    } else {
      for (let i = 0; i < roomtype.length; i++) {
        let newval = {};
        newval = roomtype[i].value;
        roomval1.push(newval);
      }
    }

    let rateval1 = [];
    if (selectedrate1.length > 0) {
      for (let i = 0; i < selectedrate1.length; i++) {
        let newval = {};
        newval = selectedrate1[i].value;
        rateval1.push(newval);
      }
    } else {
      for (let i = 0; i < ratelist.length; i++) {
        let newval = {};
        newval = ratelist[i].value;
        rateval1.push(newval);
      }
    }

    let boardval1 = [];
    if (selectedboard1.length > 0) {
      for (let i = 0; i < selectedboard1.length; i++) {
        let newval = {};
        newval = selectedboard1[i].value;
        boardval1.push(newval);
      }
    } else {
      for (let i = 0; i < board.length; i++) {
        let newval = {};
        newval = board[i].value;
        boardval1.push(newval);
      }
    }

    let occupancyVal1 = [];
    if (selectedoccupancy1.length > 0) {
      for (let i = 0; i < selectedoccupancy1.length; i++) {
        let newval = {};
        newval = selectedoccupancy1[i].value;
        occupancyVal1.push(newval);
      }
    } else {
      for (let i = 0; i < occupancy.length; i++) {
        let newval = {};
        newval = occupancy[i].value;
        occupancyVal1.push(newval);
      }
    }

    const res = await fetch(
      `${Config.apiurl}hotel/condition/maximum-stay/search`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
          rooms: roomval1,
          rates: rateval1,
          boards: boardval1,
          occupancy: occupancyVal1,
          from: firstnextfull,
          to: endnextfull,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        handleClickNext();
      } else {
        setMaxstay(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      handleClickNext();
    } else {
      console.log("error");
    }
  };

  var loop = new Date(dateString1);
  var end = new Date(dateString2);
  console.log(loop);
  var newDate1 = loop.setDate(loop.getDate() - 1);
  var newDate2 = end.setDate(end.getDate() - 1);
  let arr = [];

  while (loop <= end) {
    var newDate = loop.setDate(loop.getDate() + 1);
    loop = new Date(loop);
    let date = moment(loop).format("MMM DD,YYYY");
    arr.push(date);
  }
  let val = arr.length;
  const handleDayClick = (dayOfWeek) => {
    if (selectedWeekdays.includes(dayOfWeek)) {
      if (clickedDays.includes(dayOfWeek)) {
        setClickedDays((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayOfWeek)
        );
      } else {
        setClickedDays((prevClickedDays) => [...prevClickedDays, dayOfWeek]);
      }
    }
  };

  const [columnsDisplayed, setColumnsDisplayed] = useState(0);

  useEffect(() => {
    getRoomtype();
    getBoard();
    getRatelist();
    getOccupancies();
    getMaxstay();
  }, [id]);

  useEffect(
    () => {
      const getWeekdays = (startDate, endDate) => {
        const weekdays = [];
        const currentDay = new Date(startDate);
        const lastDay = new Date(endDate);

        while (currentDay <= lastDay) {
          const dayOfWeek = currentDay.getDay();
          if (dayOfWeek >= 0 && dayOfWeek <= 6) {
            weekdays.push(dayOfWeek);
          }
          currentDay.setDate(currentDay.getDate() + 1);
        }

        return weekdays;
      };

      const getWeekdaysBetweenSelectedDates = (datefrom, dateto) => {
        const weekdays = getWeekdays(datefrom, dateto);
        setSelectedWeekdays(weekdays);
        setClickedDays([]);
      };

      if (datefrom && dateto) {
        getWeekdaysBetweenSelectedDates(datefrom, dateto);
      } else {
        setSelectedWeekdays([]);
      }

      const columnNumber =
        window.innerWidth / document.querySelector(".th-element").clientWidth;
      const datas = Math.ceil(columnNumber);
      setColumnsDisplayed(datas);

      for (let i = 0; i < maxstay.length; i++) {
        let ar = {};
        ar.tab = "default";
        ar.click = i === 0;
        tab.push(ar);
      }
    },
    [dateto, maxstay.length],
    [datefrom]
  );

  useEffect(() => {
    setselectedRooms(roomtype.map((option) => option));
    setselectedRate(ratelist.map((option) => option));
    setselectedBoard(board.map((option) => option));
    setselectedOccupancy(occupancy.map((option) => option));
    setSelectedroom1(roomtype.map((option) => option));
    setSelectedrate1(ratelist.map((option) => option));
    setSelectedboard1(board.map((option) => option));
    setSelectedoccupancy1(occupancy.map((option) => option));
  }, [roomtype.length, ratelist.length, board.length, occupancy.length]);

  useEffect(() => {
    setId(hotel_select[0]?.value);
  }, [hotel_select]);

  useEffect(() => {
    setHotelname(hotel_select[0]?.label);
  }, [hotel_select]);

  console.log(maxstay);
  return (
    <>
      <div className="page-content">
        <Conditionsheader />
        <h4 className="" style={{ marginLeft: "10px" }}>
          {hotelname}
        </h4>
        <div className="row">
          <div className="col-12"></div>
        </div>

        <div className="row">
          <div className="col-xxl-12">
            <div className="card">
              <div className="condition-top-section">
                <div className="hotel-condition-modify-sec">
                  <div className="db-condition-modify-collapse">
                    <span
                      className="db-modify_toggle_btn"
                      onClick={clickEventHandler}
                    >
                      <span className="bx bxs-chevron-down"></span>
                    </span>

                    <button
                      className="modify-button"
                      onClick={clickEventHandler}
                    >
                      Modify Conditions
                    </button>
                  </div>
                  <div
                    className={
                      open1 === true
                        ? "condition-modify-sec-main b_b_1"
                        : "condition-modify-sec-main d-none"
                    }
                  >
                    <div className="row g-3 modify-condition-inner">
                      <div class="col-lg-3">
                        <div className="input-group">
                          <label
                            className="input-group-text input-joint-color"
                            id="inputGroup-sizing-default"
                          >
                            Rooms:
                          </label>
                          <div className="col-md-8 item-room">
                            <MultiSelect
                              options={roomtype}
                              value={selectedRooms}
                              onChange={setselectedRooms}
                              labelledBy="Select"
                              selectionType="counter"
                              allowCustomValue={true}
                              aria-expanded="true"
                            />
                          </div>
                          <label style={{ color: "red" }}>
                            {formerror.selectedRooms}
                          </label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div className="input-group">
                          <label
                            className="input-group-text input-joint-color"
                            id="inputGroup-sizing-default"
                          >
                            Rate List :
                          </label>
                          <div className="col-md-8 item_rate">
                            <MultiSelect
                              options={ratelist}
                              value={selectedRate}
                              onChange={setselectedRate}
                              labelledBy="Select"
                              selectionType="counter"
                              allowCustomValue={true}
                              aria-expanded="true"
                            />
                          </div>
                          <label style={{ color: "red" }}>
                            {formerror.selectedRate}
                          </label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div className="input-group">
                          <label
                            className="input-group-text input-joint-color"
                            id="inputGroup-sizing-default"
                          >
                            Boards :
                          </label>
                          <div className="col-md-8 item_board">
                            <MultiSelect
                              options={board}
                              value={selectedBoard}
                              onChange={setselectedBoard}
                              labelledBy="Select"
                              selectionType="counter"
                              allowCustomValue={true}
                              aria-expanded="true"
                            />
                          </div>
                          <label style={{ color: "red" }}>
                            {formerror.selectedBoard}
                          </label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div className="input-group">
                          <label
                            className="input-group-text input-joint-color"
                            id="inputGroup-sizing-default"
                          >
                            Occupancies :
                          </label>
                          <div className="col-md-6 item-occupy">
                            <MultiSelect
                              options={occupancy}
                              value={selectedOccupancy}
                              onChange={setselectedOccupancy}
                              labelledBy="Select"
                              selectionType="counter"
                              allowCustomValue={true}
                              aria-expanded="true"
                            />
                          </div>
                          <label style={{ color: "red" }}>
                            {formerror.selectedOccupancy}
                          </label>
                        </div>
                      </div>
                      <div className="col-xxl-2 col-sm-4 form-calender-add cal-width">
                        <form action="#">
                          <div className="input-group">
                            <Flatpickr
                              className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                              placeholder="FROM"
                              id="flatpickr-from"
                              options={{
                                mode: "single",
                                dateFormat: "d M, Y",
                                minDate: "today",
                              }}
                              value={datefrom}
                              onChange={handleCalendarChangeFrom}
                            />
                            <label style={{ color: "red" }}>
                              {formerror.datefrom}
                            </label>
                            <span
                              className="form_calendar-style fon mdi mdi-calendar-month-outline"
                              onClick={() => handleCalendarIconClick("from")}
                            ></span>
                          </div>
                        </form>
                      </div>
                      <div className="col-xxl-2 col-sm-4 form-calender-add cal-width">
                        <form action="#">
                          <div className="input-group">
                            <Flatpickr
                              className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                              placeholder="TO"
                              id="flatpickr-to"
                              options={{
                                mode: "single",
                                dateFormat: "d M, Y",
                                minDate: "today",
                              }}
                              value={dateto}
                              onChange={handleCalendarChangeTo}
                            />
                            <label style={{ color: "red" }}>
                              {formerror.dateto}
                            </label>
                            <span
                              className="form_calendar-style fon mdi mdi-calendar-month-outline"
                              onClick={() => handleCalendarIconClick("to")}
                            ></span>
                          </div>
                        </form>
                      </div>
                      <div className="col-xxl-8 col-sm-5">
                        <ul className="modify-week-list top_col">
                          <li
                            className={`modify-week-list-item card-title ${
                              selectedWeekdays.includes(1) ? "green-bg" : ""
                            } ${clickedDays.includes(1) ? "red-bg" : ""}`}
                            onClick={() => handleDayClick(1)}
                          >
                            Mon
                          </li>
                          <li
                            className={`modify-week-list-item card-title ${
                              selectedWeekdays.includes(2) ? "green-bg" : ""
                            } ${clickedDays.includes(2) ? "red-bg" : ""}`}
                            onClick={() => handleDayClick(2)}
                          >
                            Tue
                          </li>
                          <li
                            className={`modify-week-list-item ${
                              selectedWeekdays.includes(3) ? "green-bg" : ""
                            } ${clickedDays.includes(3) ? "red-bg" : ""}`}
                            onClick={() => handleDayClick(3)}
                          >
                            Wed
                          </li>
                          <li
                            className={`modify-week-list-item ${
                              selectedWeekdays.includes(4) ? "green-bg" : ""
                            } ${clickedDays.includes(4) ? "red-bg" : ""}`}
                            onClick={() => handleDayClick(4)}
                          >
                            Thu
                          </li>
                          <li
                            className={`modify-week-list-item ${
                              selectedWeekdays.includes(5) ? "green-bg" : ""
                            } ${clickedDays.includes(5) ? "red-bg" : ""}`}
                            onClick={() => handleDayClick(5)}
                          >
                            Fri
                          </li>
                          <li
                            className={`modify-week-list-item ${
                              selectedWeekdays.includes(6) ? "green-bg" : ""
                            } ${clickedDays.includes(6) ? "red-bg" : ""}`}
                            onClick={() => handleDayClick(6)}
                          >
                            Sat
                          </li>
                          <li
                            className={`modify-week-list-item ${
                              selectedWeekdays.includes(0) ? "green-bg" : ""
                            } ${clickedDays.includes(0) ? "red-bg" : ""}`}
                            onClick={() => handleDayClick(0)}
                          >
                            Sun
                          </li>
                        </ul>
                      </div>
                      <div className="col-xxl-3 col-lg-2 col-sm-12 col-expand2">
                        <ul className="rate-extra-info">
                          <li className="label-li">
                            <div className="">
                              <label className="price-modify-label">
                                Length of Maximum Stay:
                              </label>
                            </div>
                          </li>
                          <li className="text-li col-lg-1">
                            <span className="">
                              <input
                                onKeyDown={(e) =>
                                  symbolsrate.includes(e.key) &&
                                  e.preventDefault()
                                }
                                className="form-control extra-child-age-range"
                                type="number"
                                value={maxstaylen}
                                onChange={(e) => setMaxstaylen(e.target.value)}
                                style={{ width: "100px", marginTop: "0px" }}
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                              />
                            </span>
                          </li>
                          <label style={{ color: "red" }}>
                            {formerror.maxstaylen}
                          </label>
                        </ul>
                      </div>
                      <div className="col-xxl-12 col-sm-12">
                        <div className="modify-period-submit">
                          <button
                            type="button"
                            className="btn db-save-button condition-save-btn"
                            onClick={Bulkupdate}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row project-wrapper price-wrapper">
          <div className="row dashboard-top-tab-style">
            <div className="col-xxl-12 dashboard-availability-type">
              <div className="card availabilty-filter-card">
                <div className="card-header">
                  <div className="row align-items-center gy-3">
                    <div className="price-filter-sec">
                      <form>
                        <div className="row g-3">
                          <div className="col-md-1">
                            <h5 className="card-title db-title-header filter-head">
                              {" "}
                              Filter
                            </h5>
                          </div>
                          <div className="col-md-3 extrabed-rates-sec">
                            <MultiSelect
                              options={roomtype}
                              value={selectedroom1}
                              onChange={setSelectedroom1}
                              labelledBy="Select"
                              className="multi_select_op"
                              selectionType="counter"
                              allowCustomValue={true}
                              aria-expanded="true"
                            />
                          </div>
                          <div className="col-md-2 extrabed-rates-sec">
                            <MultiSelect
                              options={board}
                              value={selectedboard1}
                              onChange={setSelectedboard1}
                              labelledBy="Select"
                              className="multi_select_op"
                              selectionType="counter"
                              allowCustomValue={true}
                              aria-expanded="true"
                            />
                          </div>
                          <div className="col-md-2 extrabed-rates-sec">
                            <MultiSelect
                              options={ratelist}
                              value={selectedrate1}
                              onChange={setSelectedrate1}
                              labelledBy="Select"
                              className="multi_select_op"
                              selectionType="counter"
                              allowCustomValue={true}
                              aria-expanded="true"
                            />
                          </div>
                          <div className="col-md-2 extrabed-rates-sec">
                            <MultiSelect
                              options={occupancy}
                              value={selectedoccupancy1}
                              onChange={setSelectedoccupancy1}
                              labelledBy="Select"
                              className="multi_select_op"
                              selectionType="counter"
                              allowCustomValue={true}
                              aria-expanded="true"
                            />
                          </div>
                          <div className="col-xxl-1 col-md-1 col-sm-4">
                            <button
                              type="button"
                              className="btn db-common-filter"
                              style={{
                                color: "white",
                                backgroundColor: "black",
                              }}
                              onClick={Filter}
                            >
                              <span className="bx bx-filter-alt"></span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="card-header price-bottom-header">
                  <div className="row available_filter_calender">
                    <span className="col-xxl-6 available_filter_calender_sec">
                      {dateString1 === todaydate ? (
                        ""
                      ) : (
                        <button
                          type="button"
                          title="Previous month"
                          aria-pressed="false"
                          className="fc-prev-button btn btn-availability-arrow"
                          onClick={handleClickPrev}
                        >
                          <span className="fa fa-chevron-left"></span>
                        </button>
                      )}
                      <input
                        type="text"
                        value={`${dateString1} - ${dateString2}`}
                        style={{
                          marginTop: "10px",
                          border: "0px",
                          width: "200px",
                        }}
                      />
                      <button
                        type="button"
                        title="Next month"
                        aria-pressed="false"
                        className="fc-next-button btn btn-availability-arrow"
                        onClick={handleClickNext}
                      >
                        <span className="fa fa-chevron-right"></span>
                      </button>
                      <span className="calendar-show-count">
                        {" "}
                        A total of
                        <span className="calendar-date-count">
                          {" "}
                          {arr.length < columnsDisplayed - 4
                            ? arr.length
                            : columnsDisplayed - 4}{" "}
                          days
                        </span>{" "}
                        are displayed on this screen
                      </span>
                    </span>
                    <span className="col-xxl-6 available_update_btn">
                      <button
                        type="button"
                        className="btn db-save-button"
                        onClick={Singlesave}
                      >
                        Save
                      </button>
                    </span>
                  </div>
                </div>
                <div className="">
                  <div className="table-responsive">
                    <table className="table room-price-table table-bordered table-nowrap align-middle mb-0">
                      <thead>
                        <tr>
                          <th scope="col" className="stickyclass">
                            <p
                              className="room-available-filtersec-head"
                              style={{ width: "200px" }}
                            >
                              Rooms
                            </p>
                          </th>
                          {arr.map((user, index) => {
                            return (
                              <th
                                scope="col"
                                key={index}
                                className="th-element"
                              >
                                <div
                                  className="room-avail-date"
                                  style={{ width: "70px" }}
                                >
                                  <span className="avail-month-name">
                                    {dayjs(user).format("MMM")}
                                  </span>
                                  <br></br>
                                  <span className="avail-date-bold">
                                    {dayjs(user).format("DD")}
                                  </span>
                                  <br></br>
                                  <span className="avail-week-name">
                                    {dayjs(user).format("ddd")}
                                  </span>
                                  <br></br>
                                </div>
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      {loading === true ? (
                        <>
                          {maxstay.length > 0 ? (
                            maxstay.map((open, i) => {
                              return (
                                <tbody key={i}>
                                  <tr>
                                    <th
                                      colSpan={val + 1}
                                      className="price-inner-table stickyclass stickey_blocks"
                                    >
                                      <table className="table room-price-inner-table table-bordered table-nowrap align-middle mb-0 col-table-siz">
                                        <tbody>
                                          <tr>
                                            <td colSpan={val + 1}>
                                              <h6 className="price-sec-room-title stickyclass_11">
                                                <span
                                                  className="room-default-expand expand_fond"
                                                  id="room1"
                                                  onClick={(e) =>
                                                    handleClickroom(i)
                                                  }
                                                >
                                                  <span className="room-price-expanded ri-arrow-down-s-line text-dark"></span>
                                                  {open.room_name}
                                                </span>
                                                <span className="default_price_show price_top_show">
                                                  <ul
                                                    className="price_show_select"
                                                    id="price_show_type"
                                                  >
                                                    {tab.map((ta, idx) => {
                                                      return (
                                                        <div key={idx}>
                                                          {i === idx ? (
                                                            <>
                                                              <li
                                                                className={
                                                                  ta.tab ===
                                                                  "default"
                                                                    ? "active"
                                                                    : ""
                                                                }
                                                                data-value="default"
                                                                onClick={(e) =>
                                                                  handleTab(
                                                                    "default",
                                                                    idx
                                                                  )
                                                                }
                                                              >
                                                                Default View
                                                              </li>
                                                              <li
                                                                className={
                                                                  ta.tab ===
                                                                  "extend"
                                                                    ? "active"
                                                                    : ""
                                                                }
                                                                data-value="extend"
                                                                onClick={(e) =>
                                                                  handleTab(
                                                                    "extend",
                                                                    idx
                                                                  )
                                                                }
                                                              >
                                                                Extended View
                                                              </li>
                                                            </>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </div>
                                                      );
                                                    })}
                                                  </ul>
                                                </span>
                                                <input
                                                  type="hidden"
                                                  name="list_value"
                                                  id="li_change_value"
                                                  value="default"
                                                />
                                                <input
                                                  type="hidden"
                                                  name="list_room_id"
                                                  id="list_room_id"
                                                  value="room1"
                                                />
                                              </h6>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </th>
                                  </tr>
                                  {open.rates.length > 0?(
                                    <>
                                  {tab[i]?.click === true ? (
                                    <>
                                      {tab[i]?.tab === "extend" ? (
                                        <>
                                          {open.rates.map((rate, ind) => {
                                            return (
                                              <>
                                                {rate.hotel_board.map(
                                                  (board, indx) => {
                                                    return (
                                                      <>
                                                        {board.occupancies.map(
                                                          (
                                                            occupancies,
                                                            index
                                                          ) => {
                                                            return (
                                                              <tr
                                                                key={indx}
                                                                className="room-price-expanded-sec"
                                                                id="room1_default"
                                                              >
                                                                <th
                                                                  colSpan="col"
                                                                  className="stickyclass"
                                                                  style={{
                                                                    textAlign:
                                                                      "left",
                                                                  }}
                                                                >
                                                                  <span
                                                                    className="price-td-rates"
                                                                    style={{
                                                                      paddingLeft:
                                                                        "20px",
                                                                    }}
                                                                  >
                                                                    {
                                                                      rate.internal_name
                                                                    }{" "}
                                                                  </span>
                                                                  <span className="price-td-boards">
                                                                    {board.name}
                                                                  </span>
                                                                  <span
                                                                    className="price-td-occupancy-in"
                                                                    style={{
                                                                      float:
                                                                        "right",
                                                                      paddingRight:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    {occupancies.adult +
                                                                      occupancies.child >
                                                                      4 && (
                                                                      <>
                                                                        {occupancies.adult >
                                                                          0 && (
                                                                          <>
                                                                            <img
                                                                              src="images/icon/user.png"
                                                                              alt="Adult"
                                                                              style={{
                                                                                paddingLeft:
                                                                                  "1px",
                                                                              }}
                                                                            />
                                                                            (
                                                                            {
                                                                              occupancies.adult
                                                                            }
                                                                            )
                                                                          </>
                                                                        )}
                                                                        {occupancies.child >
                                                                          0 && (
                                                                          <>
                                                                            {" "}
                                                                            X{" "}
                                                                            <img
                                                                              src="images/icon/user.png"
                                                                              alt="Child"
                                                                              className="child-icon"
                                                                              style={{
                                                                                marginLeft:
                                                                                  "2px",
                                                                              }}
                                                                            />
                                                                            (
                                                                            {
                                                                              occupancies.child
                                                                            }
                                                                            )
                                                                          </>
                                                                        )}
                                                                      </>
                                                                    )}

                                                                    {occupancies.adult +
                                                                      occupancies.child <=
                                                                      4 && (
                                                                      <>
                                                                        {Array.from(
                                                                          {
                                                                            length:
                                                                              occupancies.adult,
                                                                          },
                                                                          (
                                                                            _,
                                                                            index
                                                                          ) => (
                                                                            <img
                                                                              key={`adult-${index}`}
                                                                              src="images/icon/user.png"
                                                                              alt="Adult"
                                                                              style={{
                                                                                paddingLeft:
                                                                                  "1px",
                                                                              }}
                                                                            />
                                                                          )
                                                                        )}
                                                                        {Array.from(
                                                                          {
                                                                            length:
                                                                              occupancies.child,
                                                                          },
                                                                          (
                                                                            _,
                                                                            index
                                                                          ) => (
                                                                            <img
                                                                              key={`child-${index}`}
                                                                              src="images/icon/user.png"
                                                                              alt="Child"
                                                                              className="child-icon"
                                                                              style={{
                                                                                marginLeft:
                                                                                  "2px",
                                                                              }}
                                                                            />
                                                                          )
                                                                        )}
                                                                      </>
                                                                    )}
                                                                  </span>
                                                                </th>
                                                                {occupancies.maximum_stays.map(
                                                                  (
                                                                    occ,
                                                                    indexx
                                                                  ) => {
                                                                    let saveColorClass;
                                                                    if (
                                                                      saveddata.length >
                                                                      0
                                                                    ) {
                                                                      const isDesiredAvailability =
                                                                        occ.maximum_stay_days ===
                                                                        saveddata[
                                                                          i
                                                                        ]
                                                                          ?.rates[
                                                                          ind
                                                                        ]
                                                                          ?.hotel_board[
                                                                          indx
                                                                        ]
                                                                          ?.occupancies[
                                                                          index
                                                                        ]
                                                                          ?.maximum_stays[
                                                                          indexx
                                                                        ]
                                                                          ?.maximum_stay_days;
                                                                      saveColorClass =
                                                                        isDesiredAvailability
                                                                          ? ""
                                                                          : "ch_";
                                                                    }
                                                                    return (
                                                                      <td
                                                                        key={
                                                                          indexx
                                                                        }
                                                                        className="room-price-td"
                                                                      >
                                                                        <input
                                                                          onKeyDown={(
                                                                            e
                                                                          ) =>
                                                                            symbolsrate.includes(
                                                                              e.key
                                                                            ) &&
                                                                            e.preventDefault()
                                                                          }
                                                                          type="number"
                                                                          className={`form-control  ${saveColorClass}`}
                                                                          value={
                                                                            occ.maximum_stay_days
                                                                              ? occ.maximum_stay_days
                                                                              : ""
                                                                          }
                                                                          name="maximum_stay_days"
                                                                          onChange={(
                                                                            e
                                                                          ) =>
                                                                            handleInputChange(
                                                                              i,
                                                                              ind,
                                                                              indx,
                                                                              index,
                                                                              indexx,
                                                                              e
                                                                                .target
                                                                                .value
                                                                            )
                                                                          }
                                                                          onFocus={(
                                                                            e
                                                                          ) =>
                                                                            e.target.addEventListener(
                                                                              "wheel",
                                                                              function (
                                                                                e
                                                                              ) {
                                                                                e.preventDefault();
                                                                              },
                                                                              {
                                                                                passive: false,
                                                                              }
                                                                            )
                                                                          }
                                                                        />
                                                                      </td>
                                                                    );
                                                                  }
                                                                )}
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                                                      </>
                                                    );
                                                  }
                                                )}
                                                <tr className="span_separate"></tr>
                                              </>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <>
                                          {open.rates.map((rate, ind) => {
                                            return (
                                              <>
                                                {rate.hotel_board.map(
                                                  (board, indx) => {
                                                    return (
                                                      <tr
                                                        key={indx}
                                                        className="room-price-expanded-sec"
                                                        id="room1_default"
                                                      >
                                                        {board.occupancies.map(
                                                          (
                                                            occupancy,
                                                            index
                                                          ) => {
                                                            return (
                                                              <>
                                                                {index === 0 ? (
                                                                  <>
                                                                    <th
                                                                      colSpan="col"
                                                                      className="stickyclass"
                                                                      style={{
                                                                        textAlign:
                                                                          "left",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        className="price-td-rates"
                                                                        style={{
                                                                          paddingLeft:
                                                                            "20px",
                                                                        }}
                                                                      >
                                                                        {
                                                                          rate.internal_name
                                                                        }{" "}
                                                                      </span>
                                                                      <span className="price-td-boards">
                                                                        {
                                                                          board.name
                                                                        }
                                                                      </span>
                                                                      <span
                                                                        className="price-td-occupancy-in"
                                                                        style={{
                                                                          float:
                                                                            "right",
                                                                          paddingRight:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        {occupancy.adult +
                                                                          occupancy.child >
                                                                          4 && (
                                                                          <>
                                                                            {occupancy.adult >
                                                                              0 && (
                                                                              <>
                                                                                <img
                                                                                  src="images/icon/user.png"
                                                                                  alt="Adult"
                                                                                  style={{
                                                                                    paddingLeft:
                                                                                      "1px",
                                                                                  }}
                                                                                />

                                                                                (
                                                                                {
                                                                                  occupancy.adult
                                                                                }

                                                                                )
                                                                              </>
                                                                            )}
                                                                            {occupancy.child >
                                                                              0 && (
                                                                              <>
                                                                                {" "}
                                                                                X{" "}
                                                                                <img
                                                                                  src="images/icon/user.png"
                                                                                  alt="Child"
                                                                                  className="child-icon"
                                                                                  style={{
                                                                                    marginLeft:
                                                                                      "2px",
                                                                                  }}
                                                                                />

                                                                                (
                                                                                {
                                                                                  occupancy.child
                                                                                }

                                                                                )
                                                                              </>
                                                                            )}
                                                                          </>
                                                                        )}

                                                                        {occupancy.adult +
                                                                          occupancy.child <=
                                                                          4 && (
                                                                          <>
                                                                            {Array.from(
                                                                              {
                                                                                length:
                                                                                  occupancy.adult,
                                                                              },
                                                                              (
                                                                                _,
                                                                                index
                                                                              ) => (
                                                                                <img
                                                                                  key={`adult-${index}`}
                                                                                  src="images/icon/user.png"
                                                                                  alt="Adult"
                                                                                  style={{
                                                                                    paddingLeft:
                                                                                      "1px",
                                                                                  }}
                                                                                />
                                                                              )
                                                                            )}
                                                                            {Array.from(
                                                                              {
                                                                                length:
                                                                                  occupancy.child,
                                                                              },
                                                                              (
                                                                                _,
                                                                                index
                                                                              ) => (
                                                                                <img
                                                                                  key={`child-${index}`}
                                                                                  src="images/icon/user.png"
                                                                                  alt="Child"
                                                                                  className="child-icon"
                                                                                  style={{
                                                                                    marginLeft:
                                                                                      "2px",
                                                                                  }}
                                                                                />
                                                                              )
                                                                            )}
                                                                          </>
                                                                        )}
                                                                      </span>
                                                                    </th>
                                                                    {occupancy.maximum_stays.map(
                                                                      (
                                                                        occ,
                                                                        indexx
                                                                      ) => {
                                                                        let saveColorClass;
                                                                        if (
                                                                          saveddata.length >
                                                                          0
                                                                        ) {
                                                                          const isDesiredAvailability =
                                                                            occ.maximum_stay_days ===
                                                                            saveddata[
                                                                              i
                                                                            ]
                                                                              ?.rates[
                                                                              ind
                                                                            ]
                                                                              ?.hotel_board[
                                                                              indx
                                                                            ]
                                                                              ?.occupancies[
                                                                              index
                                                                            ]
                                                                              ?.maximum_stays[
                                                                              indexx
                                                                            ]
                                                                              ?.maximum_stay_days;
                                                                          saveColorClass =
                                                                            isDesiredAvailability
                                                                              ? ""
                                                                              : "ch_";
                                                                        }
                                                                        return (
                                                                          <td
                                                                            key={
                                                                              indexx
                                                                            }
                                                                            className="room-price-td"
                                                                          >
                                                                            <input
                                                                              onKeyDown={(
                                                                                e
                                                                              ) =>
                                                                                symbolsrate.includes(
                                                                                  e.key
                                                                                ) &&
                                                                                e.preventDefault()
                                                                              }
                                                                              type="number"
                                                                              className={`form-control  ${saveColorClass}`}
                                                                              value={
                                                                                occ.maximum_stay_days
                                                                                  ? occ.maximum_stay_days
                                                                                  : ""
                                                                              }
                                                                              name="maximum_stay_days"
                                                                              onChange={(
                                                                                e
                                                                              ) =>
                                                                                handleInputChange(
                                                                                  i,
                                                                                  ind,
                                                                                  indx,
                                                                                  index,
                                                                                  indexx,
                                                                                  e
                                                                                    .target
                                                                                    .value
                                                                                )
                                                                              }
                                                                              onFocus={(
                                                                                e
                                                                              ) =>
                                                                                e.target.addEventListener(
                                                                                  "wheel",
                                                                                  function (
                                                                                    e
                                                                                  ) {
                                                                                    e.preventDefault();
                                                                                  },
                                                                                  {
                                                                                    passive: false,
                                                                                  }
                                                                                )
                                                                              }
                                                                            />
                                                                          </td>
                                                                        );
                                                                      }
                                                                    )}
                                                                  </>
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                                <tr className="span_separate"></tr>
                                              </>
                                            );
                                          })}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  </>
                                ) : (
                                  <tr>
                                    <td
                                      colSpan={100}
                                      className="rate_col_clr"
                                    >
                                      Please add the rate plan to the{" "}
                                      <b>{open.room_name}</b> at the{" "}
                                      <b>{hotelname}</b> Hotel.
                                    </td>
                                  </tr>
                                )}
                                </tbody>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                style={{ textAlign: "center" }}
                                colSpan={columnsDisplayed - 3}
                              >
                                No data found
                              </td>
                            </tr>
                          )}
                        </>
                      ) : (
                        <tr>
                          <td
                            style={{ textAlign: "center" }}
                            colSpan={columnsDisplayed - 3}
                          >
                            <Loading />
                          </td>
                        </tr>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
      <Footer />
    </>
  );
}

export default Maximumstay;
