import React, { useEffect, useState } from "react";
import Footer from "../../Common/Footer";
import { Link } from "react-router-dom";
import { useErrorBoundary } from "preact/hooks";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import Select from "react-select";
import Config from "../../Config";
import Alerts from "../../Alerts";
import ReactPaginate from "react-paginate";
import Commonheader from "../../Common/Commonheader";
import Basicsettingsheader from "../../Common/Basicsettingsheader";
import axios from "axios";
import DeleteModal from "../../DeleteModal";
import Citiesedit from "./Citiesedit";
import Loading from "../../Loading";



function Cities() {
  
  const [List, setList] = useState([]);
  const [country,setCountry]=useState([]);
const [countryname,setCountryname]=useState("");
const [formerror, setError] = useState({});
const [formerror1, setError1] = useState([{}]);
const [alertmessage, setAlertmessage] = useState("");
const [alerttype, setAlerttype] = useState("");
const [open, setOpen] = useState(false);
const [alert, setAlert] = useState(false);
const [pageid, setPageid] = useState("");
const [pagedata, setPagedata] = useState([]);
const [countrydata,setCountrydata]=useState([])
const [paginate, setPaginate] = useState([]);
const[page,setPage]=useState(1);
const[pageindex,setPageindex]=useState(1);
const [loader,setLoader] = useState(false);

const [cities,setCities]=useState({
  name:"",
  country:"",
  status:""
})

// const [modal_animationFlip, setmodal_animationFlip] = useState(false);

// function tog_animationFlip(id) {
//   setmodal_animationFlip(!modal_animationFlip);
//   setPageid(id);
// }
const [modal_backdrop, setmodal_backdrop] = useState(false);

function tog_backdrop(ids) {
  setmodal_backdrop(!modal_backdrop);
  setPageid(ids);

  const getinfo = async (e) => {
    const res = await fetch(`${Config.apiurl}admin/city/${ids}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data1 = await res.json();
    const data = data1.data;
    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setPagedata(data.city);
      setCountrydata(data.country)
    }
  };
  getinfo();
}

const getinfo = async (e) => {
  const res = await fetch(`${Config.apiurl}admin/city/list?page=1`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const data = await res.json();
  if (data.status_code !=="200" || !data) {
    console.log("error");
  } else {
    setList(data.data.data);
    setPaginate(data.data.last_page);
    setLoader(true);
  }
};

const fetchComments = async (currentPage) => {
  const res = await fetch(`${Config.apiurl}admin/city/list?page=${currentPage}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    }
  );

  const data = await res.json();
  return data.data.data;
};

const handlePageClick = async (data) => {
if(data===1){
  setPage(data);
  setPageindex(1);
  const commentsFormServer = await fetchComments(data);

  setList(commentsFormServer);

}
else{
  let currentPage = data.selected + 1;
  setPageindex(currentPage);
  setPage(data);
  const commentsFormServer = await fetchComments(currentPage);

  setList(commentsFormServer);
}
 
};

const getCountry = async (e) => {
  const res = await fetch(`${Config.apiurl}country-list`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const data = await res.json();
  if (res.status === 404 || !data) {
    console.log("error");
  } else {
    setCountry(data.data);
  }
};

function Reset(){
  setCities({
    name:"",
    country:"",
  status:""
  })
}

let name, value;
  const handleInput = (e) => {
    e.preventDefault();
    console.log(e);
    name = e.target.name;
    value = e.target.value;
    console.log(value);
    setCities({ ...cities, [name]: value });
  };

  const validate = (values) => {
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-]+$/;

    const errors = {};
    if (!values.name) {
      errors.name = "Name is required!";
    }
    else if (!noSpecialCharRegex.test(values.name)) {
      errors.name = "Name should not contain this special character";
    }
    else if (values.name.length > 50) {
      errors.name = `Name cannot be more than 50 characters!`;
    }
    if (!values.status) {
      errors.status = "Status is required!";
    }
   
    return errors;
  };

  const validate1 = (values) => {
    const errors = {};
    if (!values) {
      errors.countryname = "Country is required!";
    }
    return errors;
  };
  const PostData = async (e) => {
    e.preventDefault();
    const ress = validate(cities);
    const ress1 = validate1(countryname);

    console.log(countryname);
    setError(ress);
    setError1(ress1);

    if (
      !ress.name &&
      !ress.status &&
      !ress1.countryname 
      
    ) {
      const res = await fetch(`${Config.apiurl}admin/city/add`, {
        method: "POST",
        body: JSON.stringify({
          name: cities.name,
          country: countryname.value,
          is_active: cities.status,
          
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
      if (data.status_code !== "200" || !data) {
        setAlert(true);
        setAlertmessage(data.message);
        setOpen(true);
        setAlerttype("error");
      } else {
        setAlert(true);
        setAlertmessage(data.message);
        setOpen(true);
        setAlerttype("success");
        Reset();
        handlePageClick(page);
      }
    }
  };
 

  const UpdateData = async (id) => {
    console.log(pagedata);
    const formData = new FormData();
    formData.append("name", pagedata.name);
    formData.append("country", countrydata.value);
    formData.append("is_active", pagedata.status);
    formData.append('_method', 'PATCH');
    axios
      .post(`${Config.apiurl}admin/city/update/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
                                                                                                
        },
      })
      .then((res) => {
        if (res.status_code !== "200" || !res) {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("error");
          // setmodal_animationFlip(false);
        } else {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("success");
          // setmodal_animationFlip(false);
          setmodal_backdrop(false);
          handlePageClick(page);
        }
      });
  };
 
  const handleInputedit = (e) => {
    e.preventDefault();
    console.log(e);
    name = e.target.name;
    value = e.target.value;
    setPagedata({ ...pagedata, [name]: value });
  };

  useEffect(() => {
    getCountry();
    getinfo();
  }, []);

  function handleChangeselect(selectedOption) {
  
let value = selectedOption;
setPagedata({ ...pagedata, ['country']: value });
}
  
  return (
    <div id="layout-wrapper">
     <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Cities</h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Settings</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Basic Settings</Link>
                      </li>

                      <li className="breadcrumb-item">
                        <Link to="#">Location Settings</Link>
                      </li>
                      <li className="breadcrumb-item active">City</li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>

            {alert ? (
              <Alerts type={alerttype} message={alertmessage} open={open} />
            ) : (
              ""
            )}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create New City
                      </h4>
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                          onClick={Reset}
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target=""
                          onClick={PostData}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Name{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                name="name"
                                value={cities.name}
                                onChange={handleInput}
                                />
                                 <label
                                  style={{ color: "red"}}
                                >
                                  {formerror.name}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Country{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <Select
                                  className="form-control"
                                  data-choices
                                   name="choices-single-default"
                                  id="choices-single-default"
                                  options={country}        
                                  value={countryname}                   
                                  onChange={(e)=>setCountryname(e)}
                                ></Select>
                                <label
                                  style={{ color: "red"}}
                                >
                                  {formerror1.countryname}
                                </label>
                              </div>
                              
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Status{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <select
                                  id="ForminputState"
                                  className="form-select"
                                  onChange={handleInput}
                                  value={cities.status}
                                  name="status"
                                >
                                  <option selected>Choose...</option>
                                  <option name="status" value="1">Active</option>
                                  <option name="status" value="0">Inactive</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="card" id="orderList">
                    <div className="card-header border-0">
                      <div className="row align-items-center gy-3">
                        <div className="col-sm d-flex justify-content-between">
                          <h5 className="card-title mb-0">List Of Cities</h5>
                          
                        </div>
                      </div>
                    </div>

                    <div className="card-body pt-0">
                      <div>
                        <ul
                          className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                          role="tablist"
                        ></ul>

                        <div className="table-responsive table-card mb-1">
                          <table
                            className="table table-nowrap align-middle"
                            id="orderTable"
                          >
                            <thead className="text-muted table-light">
                              <tr className="text-uppercase">
                               
                                <th className="" data-sort="id">
                                  SL
                                </th>
                                <th className="" data-sort="customer_name">
                                  Cities
                                </th>
                                <th className="" data-sort="customer_name">
                                  Country
                                </th>
                                <th className="" data-sort="city">
                                  Action
                                </th>
                                <th className="" data-sort="city">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {loader===false?
                             <tbody>
                             <tr>
                            <td
                              style={{ textAlign: "center" }}
                              colSpan={8}
                            >
                              <Loading />
                            </td>
                          </tr>
                          </tbody>
                          :
                            <tbody className="list form-check-all">
                              {List.map((user, index) => {
                                return (
                                  <tr key={index}>
                                   
                                    <td className="id">{pageindex===1 ? index+1: (pageindex-1) * 10 + index +1}</td>
                                    <td className="customer_name">
                                      {user.name}
                                    </td>
                                    <td className="customer_name">
                                      {user.country_name}
                                    </td>
                                    <td className="status">
                                        {user.status === false ? (
                                          <span class="badge badge-soft-danger text-uppercase">
                                            {" "}
                                            DISABLED
                                          </span>
                                        ) : (
                                          <span className="badge badge-soft-success text-uppercase">
                                            ACTIVE
                                          </span>
                                        )}
                                      </td>
                                    <td>
                                      <ul className="list-inline hstack gap-2 mb-1">
                                        <li
                                          className="list-inline-item edit"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Edit"
                                        >
                                          <Link
                                            to="#showModal"
                                            data-bs-toggle="modal"
                                            className="text-primary d-inline-block edit-item-btn"  onClick={() =>tog_backdrop(user.city_id)}
                                          >
                                            <i className="ri-pencil-fill fs-16"></i>
                                          </Link>
                                        </li>
                                        {/* <li
                                          className="list-inline-item"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Remove"
                                        >
                                          <Link
                                            className="text-danger d-inline-block remove-item-btn"
                                            data-bs-toggle="modal"
                                            to="#"
                                            // onClick={() => tog_animationFlip()}
                                          >
                                            <i className="ri-delete-bin-5-fill fs-16"></i>
                                          </Link>
                                        </li> */}
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          }
                          </table>
                        </div>
                        {paginate > 1 ? (
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={paginate}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        </div>
      
          {/* <DeleteModal
            modal_animationFlip={modal_animationFlip}
            tog_animationFlip={tog_animationFlip}
            pageid={pageid}
            DeleteData={DeleteData}
            message={"cities"}
          /> */}
            {modal_backdrop ? (
          <Citiesedit
            pagedata={pagedata}
            modal_backdrop={modal_backdrop}
            tog_backdrop={tog_backdrop}
            pageid={pageid}
            UpdateData={UpdateData}
            handleInputedit={handleInputedit}
            country={country}
            handleChangeselect={handleChangeselect}
            countrydata={countrydata}
            setCountrydata={setCountrydata}
          />)
          :""}
       
    </div>
  );
}

export default Cities;
