import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../Common/Footer";
import { MultiSelect } from "react-multi-select-component";
import Commonheader from "../../Common/Commonheader";
import Alerts from "../../Alerts";
import Config from "../../Config";
import { useEffect } from "react";
import axios from "axios";
import Loading from "../../Loading";
import Basicsettingsheader from "../../Common/Basicsettingsheader";

function Loyaltyedit() {

    const location = useLocation();
    let navigate = useNavigate();
    const [ids, setId] = useState(location.state.benefit_id);

        const [active, setActive] = useState("en");
        const[be_language,setBe_language]=useState([]);
        const language = localStorage.getItem("be_language");
        const [alert, setAlert] = useState(false);
        const [alertmessage, setAlertmessage] = useState("");
        const [alerttype, setAlerttype] = useState("");
        const [formerror4, setError4] = useState({});
        const [formerror5, setError5] = useState({});
        const [open, setOpen] = useState(false);
        const [status,setStatus]=useState("");
        const [loyalty,setLoyalty]=useState([]);
        const[langdata,setLangdata]=useState('');
        const[be_languagefull,setBe_languagefull]=useState([]);
        const [multi,setMulti]=useState([]);

     
        const getlanguage = async (e) => {
    
          const res = await fetch(`${Config.apiurl}optional-languages`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
    
            },
          });
          const data = await res.json();
          if (data.status_code !== "200" || !data.data) {
            console.log("error");
          } else {
            setBe_language(data.data);
          }
        
        
        };
    
        const getfulllanguage = async (e) => {

          const res = await fetch(`${Config.apiurl}booking-engine-languages`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
    
            },
          });
          const data = await res.json();
          if (data.status_code !== "200" || !data.data) {
            console.log("error");
          } else {
            setBe_languagefull(data.data);
          }
        
        
        };
        
        const handleInput = (e) => {
          let name = e.target.name;
          let value = e.target.value;
          setLoyalty({ ...loyalty, [name]: value });
        };
       
     
      const validate = (values) => {
        const noSpecialCharRegex = /^[A-Za-z0-9\s.,-,/]+$/;

        const errors = {};
        if (!values.title) {
          errors.title = "Name is required!";
        }
        else if (!noSpecialCharRegex.test(values.title)) {
          errors.title = "Name should not contain this special character";
        }
        else if (values.title.length > 50) {
          errors.title = `Name cannot be more than 50 characters!`;
        }
               
        return errors;
      };
    
    
      const validatestatus = () => {
        const errors = {};
        // console.log(values);
        // if (values===true || values===false) {
        //  console.log("");
        // }
        // else{
        //     errors.status = "Status is required!";
        // }
        if (!status) {
          errors.status = "Status is required!";
        }
        return errors;
      };

      const PostData = async (e) => {
        e.preventDefault();
       
        const ress=validate(loyalty)
        const ressstatus=validatestatus(status)
    
        setError4(ress);
        setError5(ressstatus);
    
        let lang = [];

  
    for (let i = 0; i < multi.length; i++) {
      if (multi[i].name) {
        if (multi[i].code !== "en") {
          let multiarray = {};
          multiarray.lang_code = multi[i].code;
          multiarray.title = multi[i].name;

          lang.push(multiarray);
        }
      }
    }

    console.log(lang);
        if ( !ressstatus.status &&!ress.title)
         {
          const res = await fetch(`${Config.apiurl}loyalty/benefits/${ids}`, {
            method: "POST",
            body: JSON.stringify({
              en_title: loyalty.title,       
              status: status,
              multi_lang: lang,
            }),
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-type": "application/json; charset=UTF-8",
            },
          });
          const data = await res.json();
              if (data.status_code !== "200" || !data) {
                setAlert(true);
                setAlertmessage(data.message);
                setOpen(true);
                setAlerttype("error");
              } else {
                setAlert(true);
                setAlertmessage(data.message);
                setOpen(true);
                setAlerttype("success");
                Reset();
                setTimeout(() => navigate("/basic_settings/loyalty-benefit-list"), 3000);
              }
      
      }
      };
    
      function Reset(){
        setLoyalty("");
        setMulti("");
        setStatus("");
      }
    
    useEffect(() => {
        const getinfo = async (e) => {
            console.log(ids);
            const res = await fetch(`${Config.apiurl}loyalty/benefits/${ids}`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            });
            const data = await res.json();
            console.log(data);
              setLoyalty(data.data.benefit);
              setMulti(data.data.multi_lang);
              setStatus(data.data.benefit.status);
            
          };
          getinfo();
          getlanguage();
          getfulllanguage();
    },[language,ids]);
console.log(loyalty);
console.log(multi);
console.log(status);
    return (
        <div id="layout-wrapper">
      <Basicsettingsheader/>
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row">
                
                </div>
                {alert ? (
                  <Alerts type={alerttype} message={alertmessage} open={open} />
                ) : (
                  ""
                )}
                <div className="row project-wrapper">
                  <div className="row dashboard-top-tab-style">
                    <div className="col-xxl-12 dashboard-main-style">
                      <div className="card">
                        <div className="card-header align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                           Loyalty Benefit
                          </h4>
                          <div className="flex-shrink-0">
                            <Link
                              type="button"
                              className="btn db-other-button"
                              to="/basic_settings/loyalty-benefit-list"
                              style={{ marginRight: "5px" }}
                            >
                              Loyalty Benefit
                            </Link>
                            {/* <button
                              type="button"
                              className="btn db-cancel-button"
                              data-bs-toggle="offcanvas"
                              to=""
                              onClick={Reset}
                            >
                              Cancel
                            </button> */}
                            <button
                              type="button"
                              className="btn db-save-button"
                              data-bs-toggle="modal"                       
                              onClick={PostData}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                       
                        <div className="card-body">
                          <div className="live-preview">
                            <form action="#">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-translation-style">
                                    <ul>
                                    <li
                                        className={active === "en" ? "active" : ""}
                                      >
                                        <button
                                             type="button"
                                          onClick={() => setActive("en")}
                                          className={
                                            active === "en" ? "active" : ""
                                          }
                                          style={{border:"none",background:"transparent"}}
                                        >
                                          EN
                                        </button>
                                      </li>
                                      <li
                                        className={
                                          active === "trans" ? "active" : ""
                                        }
                                      >
                                        <button   
                                                     type="button"
                                          onClick={() => setActive("trans")}
                                          className={
                                            active === "trans" ? "active" : ""
                                          }
                                          style={{border:"none",background:"transparent"}}
                                        >
                                          Translations
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>                 

                                  {loyalty!==undefined ?


                                <>
                             {active==="en"?
                                <>
                                <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Name
                            <span className="form-validation-required">*</span>
                              </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={loyalty.title}
                                      name="title"
                                      onChange={handleInput}
                                    />
                                    
                                  </div>
                                  <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror4.title}
                                    </label>
                                </div>
                             
    
                                <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Status
                            <span className="form-validation-required">*</span>
                              </label>
                                        <select
                                          id="ForminputState"
                                          className="form-select"
                                          value={status}
                                          onChange={(e)=>setStatus(e.target.value)}
                                         
                                        >
                                          <option selected>Choose...</option>
                                          <option {...(status === true
                                        ? "selected"
                                        : "")}
                                      value={true}  >Active</option>
                                          <option {...(status === false
                                        ? "selected"
                                        : "")}
                                      value={false} >Inactive</option>
                                        </select>
                                       
                                      </div>
                                      <label style={{ color: "red" }}>
                                          {formerror5.status}
                                        </label>
                                    </div>
                                </>:
                                  <>
                                  <div className="col-lg-4" style={{ marginTop: "20px" }}>
                                    <div className="input-group">
                                      <label className="input-group-text input-joint-color" id="inputGroup-sizing-default" style={{ width: "130px" }}>
                                        Choose Language:
                                      </label>
                                      <div className="col-md-7">
                                        <MultiSelect
                                          options={be_language}
                                          value={multi}
                                          onChange={setMulti}
                                          labelledBy="Select"
                                          selectionType="counter"
                                          closeOnChangedValue={true}
                                          aria-expanded="true"
                                          hasSelectAll={false}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="appendmore"></div>
                                  {multi.length > 0 &&
                                    multi.map((element, index) => (
                                      <div
                                        key={index}
                                        className="col-md-12 translation-fill-section"
                                        id={`lang_${element.value}`}
                                        data-value="1"
                                        style={{ marginTop: "20px" }}
                                      >
                                        <div className="translation-selected-lang">
                                          <p>
                                            <span className="translation-selected-lang-head">
                                              {element.code}
                                            </span>
                                          </p>
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-4">
                                            <div className="input-group">
                                              <label className="input-group-text input-joint-color" id="inputGroup-sizing-default">
                                                Name
                                                <span className="form-validation-required">
                                                  *
                                                </span>
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                value={element.name}
                                                onChange={(e) =>
                                                  setMulti((prevMulti) =>
                                                    prevMulti.map((item, i) =>
                                                      i === index ? { ...item, name: e.target.value } : item
                                                    )
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </>
                                  }
                                    </>:<Loading/>}
                              </div>
  
                              <div className="padding-20"></div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer/>
          </div>
        </div>
      );
}

export default Loyaltyedit