import React, { useState } from "react";

import { useEffect } from "react";
import { IoClose, IoWarningOutline } from "react-icons/io5";
import { TiTick } from "react-icons/ti";

function Toastmodal({ setToast, message, type, large }) {
  const [data, setData] = useState({
    responseStatus: true,
    responseText: "",
  });
  useEffect(() => {
    function close() {
      setTimeout(() => setToast(false), 2000);
    }
    close();
  }, []);
  return (
    <div className="modalBackground-new">
      <div className={large ? "modalContainer4-new" : "modalContainer4-new1"}>
        <div className="title">
          {type === "success" ? (
            <TiTick style={{ color: "#07a207" }} className={large ? "tick_sv":""}/>
          ) : (
            <IoWarningOutline style={{ color: "#dfdf09" }} />
          )}
          <p className="cancel-model-content1-new">{message}</p>
          <div className="titleCloseBtn">
            {/* <button
              onClick={() => {
                setToast(false);
              }}
            > */}
            <IoClose style={{ color: "white" }} onClick={(e)=>setToast(false)}/>
            {/* </button> */}
          </div>
        </div>
        <div className="toastprogress"></div>
      </div>
    </div>
  );
}

export default Toastmodal;
