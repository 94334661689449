import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../Common/Footer'
import Reservationheader from '../../Common/Reservationheader'
import Select from "react-select";
import Flatpickr from "react-flatpickr";

function Download() {

    const SingleOptions= [
        { value: "checkin", label: "With checkin date " },
        { value: "checkout", label: "With checkout date " },
        { value: "reserved", label: "Reserved on" },
        { value: "cancelled", label: "Cancelled on" },
      ];
    const SingleOptions1=[
        {value:"onereservation", label:"Download extended report (one reservation per line)"},
        {value:"oneroom", label:"Download extended report (one room per line)"},
        {value:"basic", label:" Download basic reservation report"},
        {value:"postponed", label:"Download postponed reservation report"}
    ]

  const [datefrom, setDateFrom] = useState(null);
  const [dateto, setDateTo] = useState(null);

  const handleCalendarChangeFrom = (selectedDates) => {
      setDateFrom(selectedDates);
    };
  
    const handleCalendarChangeTo = (selectedDates) => {
      setDateTo(selectedDates);
    };
  return (
<div id="layout-wrapper">
       <Reservationheader/>
        <div className="main-content">
          <div className="page-content">
              <div className="row">
                <div className="col-12">
                </div>
              </div>
              <div className="row project-wrapper">
                <div className="row dashboard-top-tab-style">
                  <div className="col-xxl-12 dashboard-main-style">
                    <div className="card">
                      <div className="card-body">
                        <div className="live-preview">
                          <form action="#">
                            <div className="row">
                              <div className="col-md-12">
                              </div>
                              <div className="col-xxl-2 col-xl-2 col-md-2 col-sm-4 col-xs-12">
                                            <div className="input-group">
                                                <label className="input-group-text input-joint-color" for="inputGroupSelect01">Criteria</label>
                                                <Select
                                                placeholder="Select..."
                                                className="form-control"
                                                data-choices
                                                name="choices-single-default"
                                                id="choices-single-default"
                                                options={SingleOptions}>
                                             </Select>
                                          </div>
                                        </div>
                              <div className="col-xxl-2 col-xl-2 col-sm-4 col-xs-12 col-md-1">
                                    <div className="input-group">
                                        <span className="input-group-text input-joint-color" id="inputGroup-sizing-sm">From</span>
                                        <Flatpickr className="form-control dash-filter-picker-new shadow fs-13 form-control flatpickr-input active"
                                        options={{ mode: "single", dateFormat: "d M, Y" }} value={datefrom} onChange={handleCalendarChangeFrom}/>                                                
                                        <span className="reservation_calendar-style fon mdi mdi-calendar-month-outline" style={{marginTop:"5px"}}></span>
                                    </div>
                                        </div>
                                        <div className="col-xxl-2 col-xl-2 col-sm-4 col-xs-12 col-md-1">
                                            <div className="input-group">
                                                <span className="input-group-text input-joint-color" id="inputGroup-sizing-sm">Until</span>
                                                <Flatpickr className="form-control dash-filter-picker-new shadow fs-13 form-control flatpickr-input active"
                                                options={{ mode: "single", dateFormat: "d M, Y" }} value={dateto} onChange={handleCalendarChangeTo}/>                                                
                                                <span className="reservation_calendar-style fon mdi mdi-calendar-month-outline" style={{marginTop:"5px"}}></span>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-xl-3 col-sm-4 col-xs-12 col-md-2">
                                            <div className="input-group">
                                                <label className="input-group-text input-joint-color" for="inputGroupSelect01">Report Type</label>
                                                <Select
                                                placeholder="Select..."
                                                className="form-control"
                                                data-choices
                                                name="choices-single-default"
                                                id="choices-single-default"
                                                options={SingleOptions1}>
                                             </Select>
                                            </div>
                                        </div>
                                        <div className="col-md-12" style={{marginTop:"10px"}}>
                                     <input className="form-check-input" type="checkbox" id="formCheck1" />
                                       <span className="room-check" style={{fontWeight:"400"}}>Only reservations in which the client accepted to receive offers from the hotel</span>
                                     </div>
                                          <div className="reservation-search-btn-sec-new" style={{marginTop:"10px",width:"105px"}}>
                                            <Link className="reservation-search-btn">
                                        <span className=" las la-download"></span>
                                    <span className="reserv_search_label">Download</span></Link>
                                </div>
                             </div>
                            <div className="padding-20"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          
          </div>
          <Footer />
        </div>
      </div>
  )
}

export default Download
