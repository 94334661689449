import React, { useState } from "react";
import Commonheader from "../../Common/Commonheader";
import { Link } from "react-router-dom";

function Extrastep4({
  setTab,
  extraStep4,
  handleExtra4Check,
  Loyaltyclublist,
  handleloyalchangeExtra4,
  handlechangeSelectStep4,
  handlechangeStep4,
}) {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="offer-create-section" style={{ height: "355px" }}>
          <div className="offer-create-details">
            <div className="col-md-12">
              <h6 className="offer-form-title">
                Only offer in reservations with
              </h6>
              <div className="padding-5"></div>
              <ul className="offer-create--rate-list">
                <li style={{ marginTop: "10px" }}>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input extra_min_stay_check"
                      type="checkbox"
                      id="formCheck1"
                      value={extraStep4.min_stay_status}
                      checked={
                        extraStep4.min_stay_status === true ? true : false
                      }
                      onChange={(e) =>
                        handleExtra4Check(e.target.checked, "min_stay_status")
                      }
                    />
                    <label className="offer-step-label">
                      <span className="offer_freeningt_join">Minimum stay</span>
                      <span
                        className={
                          extraStep4.min_stay_status === true
                            ? "offer_freeningt_join extra_min_stay_show"
                            : "offer_freeningt_join extra_min_stay_show d-none"
                        }
                      >
                        <select
                          className="form-select box_style"
                          value={extraStep4.min_stay_days}
                          name="min_stay_days"
                          onChange={handlechangeSelectStep4}
                        >
                          {[...Array(30).keys()].map((value) => (
                            <option key={value + 1} value={value + 1}>
                              {value + 1}
                            </option>
                          ))}
                        </select>
                      </span>
                    </label>
                  </div>
                </li>
                <li style={{ marginTop: "10px" }}>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input extra_max_stay_check"
                      type="checkbox"
                      id="formCheck1"
                      value={extraStep4.max_stay_status}
                      checked={
                        extraStep4.max_stay_status === true ? true : false
                      }
                      onChange={(e) =>
                        handleExtra4Check(e.target.checked, "max_stay_status")
                      }
                    />
                    <label className="offer-step-label">
                      <span className="offer_freeningt_join">Maximum stay</span>
                      <span
                        className={
                          extraStep4.max_stay_status === true
                            ? "offer_freeningt_join extra_max_stay_show"
                            : "offer_freeningt_join extra_max_stay_show d-none"
                        }
                      >
                        <select
                          className="form-select box_style"
                          value={extraStep4.max_stay_days}
                          name="max_stay_days"
                          onChange={handlechangeSelectStep4}
                        >
                          {[...Array(30).keys()].map((value) => (
                            <option key={value + 1} value={value + 1}>
                              {value + 1}
                            </option>
                          ))}
                        </select>
                      </span>
                    </label>
                  </div>
                </li>
                <li style={{ marginTop: "10px" }}>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input extra_advance_days_check"
                      type="checkbox"
                      id="formCheck1"
                      value={extraStep4.days_in_advance_status}
                      checked={
                        extraStep4.days_in_advance_status === true
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        handleExtra4Check(
                          e.target.checked,
                          "days_in_advance_status"
                        )
                      }
                    />
                    <label className="offer-step-label">
                      <span className="offer_freeningt_join">
                        Days in advance
                      </span>
                      <span
                        className={
                          extraStep4.days_in_advance_status === true
                            ? "offer_freeningt_join extra_advance_days_show"
                            : "offer_freeningt_join extra_advance_days_show d-none"
                        }
                      >
                        <input
                          className="form-control extra-input-field box_style_is"
                          value={extraStep4.days_in_advance}
                          name="days_in_advance"
                          onChange={handlechangeStep4}
                        />
                      </span>
                    </label>
                  </div>
                </li>
                <li style={{ marginTop: "10px" }}>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input restr_loyalty_check"
                      type="checkbox"
                      id="formCheck1"
                      onChange={(e) =>
                        handleExtra4Check(
                          e.target.checked,
                          "loyalty_active_status"
                        )
                      }
                      value={extraStep4.loyalty_active_status}
                      checked={
                        extraStep4.loyalty_active_status === true ? true : false
                      }
                    />
                    <label> Only for the loyalty club </label>
                  </div>
                  <div
                    className={
                      extraStep4.loyalty_active_status === true
                        ? "restr_loyalty_expand"
                        : "restr_loyalty_expand d-none"
                    }
                  >
                    <ul className="rest_list_inline">
                      {Loyaltyclublist.map((loyal, index) => {
                        return (
                          <li key={index}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="formCheck1"
                              value={loyal.selected}
                              checked={loyal.selected === true ? true : false}
                              onChange={(e) =>
                                handleloyalchangeExtra4(e.target.checked, index)
                              }
                            />
                            <label style={{ marginLeft: "8px" }}>
                              {" "}
                              {loyal.label}
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </li>
              </ul>
              <div className="tab_nav_section">
                <div
                  className="tab_nav_section_btns"
                  style={{ marginTop: "5%" }}
                >
                  <Link
                    className="btn btn-be-form-cancel btn-be-back"
                    to="#"
                    onClick={(e) => setTab("step3")}
                  >
                    Prev
                  </Link>
                  <Link
                    className="btn btn-success btn-be-common"
                    to="#"
                    onClick={(e) => setTab("step5")}
                  >
                    Next
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Extrastep4;
