import React from 'react'
import Reservations from './Reservations'

function Menureservation() {
  return (
    <div>
      <Reservations/>
    </div>
  )
}

export default Menureservation
