import React from "react";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";

function DeleteModal({
  modal_animationFlip,
  tog_animationFlip,
  pageid,
  DeleteData,
  message,
}) {
  return (
    <Modal
      id="flipModal"
      isOpen={modal_animationFlip}
      toggle={() => {
        tog_animationFlip();
      }}
      modalClassName="flip"
      centered
      style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
    >
      <ModalBody className="modal-body p-5 text-center">
        <lord-icon
          src="https://cdn.lordicon.com/gsqxdxog.json"
          trigger="loop"
          colors="primary:#405189,secondary:#f06548"
          style={{ width: "90px", height: "90px" }}
        ></lord-icon>

        <div className="mt-4 text-center">
          <h4>You are about to delete a {message} ?</h4>
          <p className="text-muted fs-15 mb-4">
            Deleting your {message} will remove all of your information from our
            database.
          </p>
        </div>

        <button
          className="btn btn-link link-success fw-medium text-decoration-none"
          onClick={() => {
            tog_animationFlip();
          }}
        >
          {" "}
          <i className="ri-close-line me-1 align-middle"></i>
          Close{" "}
        </button>
        <Button color="danger" onClick={(e) => DeleteData(pageid)}>
          {" "}
          Yes, Delete It{" "}
        </Button>
      </ModalBody>
    </Modal>
  );
}

export default DeleteModal;
