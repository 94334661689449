import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Footer from '../../Common/Footer';
import Alerts from '../../Alerts';
import Config from '../../Config';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import DeleteModal from '../../DeleteModal';
import Commonheader from '../../Common/Commonheader';
import Basicsettingsheader from '../../Common/Basicsettingsheader';
import Loading from '../../Loading';

function Paymentmethodlist() {
    const [alertmessage, setAlertmessage] = useState("");
    const [alerttype, setAlerttype] = useState("");
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(false);
    const [modal_animationFlip, setmodal_animationFlip] = useState(false);
    const [List, setList] = useState([]);
    const [paginate, setPaginate] = useState([]);
    const [pageid, setPageid] = useState("");
    const [page,setPage]=useState(1);
    const [pageindex,setPageindex]=useState(1);
    const [loader,setLoader] = useState(false);

    function tog_animationFlip(id) {
      setmodal_animationFlip(!modal_animationFlip);
      setPageid(id);
    }

    const fetchComments = async (currentPage) => {
      const res = await fetch(
        `${Config.apiurl}payment-method/list?page=${currentPage}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await res.json();
      return data.data.data;
    };
  
  
    const handlePageClick = async (data) => {
      if(data===1){
        setPage(data);
        setPageindex(1);
        const commentsFormServer = await fetchComments(data);
        setList(commentsFormServer);
      }

      else{
        let currentPage = data.selected + 1;
        setPageindex(currentPage);
        setPage(data);
        const commentsFormServer = await fetchComments(currentPage);
        setList(commentsFormServer);
      }
    };


    const fetchPost = async () => {
      const response = await fetch(
         `${Config.apiurl}payment-method/list?page=1`,{
          method:'POST',
          headers:{
            Authorization:`Bearer ${localStorage.getItem('token')}`
          }
         }
      );
      const data = await response.json();
      console.log(data);
         if(data.status_code==="200")
         { 
          setList(data.data.data);
          setPaginate(data.data.last_page);
          setLoader(true);
        }
         else{console.log("error");}
   };

   const DeleteData = async (id) => {
    axios
      .delete(`${Config.apiurl}payment-method/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status !== "200" || !res){
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("error");
          setmodal_animationFlip(false);
        }else {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("success");
          setmodal_animationFlip(false);
          fetchPost();
          handlePageClick(page);
        }
      });
  };

   useEffect(() => {
    fetchPost();
    }, []);

  return (
    <div id="layout-wrapper">
     <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12" >
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Settings</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Basic Settings</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Payment Settings</Link>
                      </li>
                      <li className="breadcrumb-item active">Payment Method</li>
                    </ol>
                  </div> */}
                </div>
             
           
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Payment Method</h4>
                  <div className="flex-shrink-0 d-flex justify-content-between">
                            <Link
                              type="button"
                              to="/basic_settings/payment-method-create"
                              className="btn db-save-button "
                            >
                              Create
                            </Link>
                          </div>
                      </div>
                      </div>
            {alert ? (
              <Alerts type={alerttype} message={alertmessage} open={open} />
            ) : (
              ""
            )}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-body pt-0">
                      <div>
                        <ul className="nav nav-tabs nav-tabs-custom nav-success mb-3" role="tablist"></ul>
                        <div className="table-responsive table-card mb-1">
                          <table className="table table-nowrap align-middle" id="orderTable">
                            <thead className="text-muted table-light">
                              <tr className="text-uppercase">
                                {/* <th scope="col" style={{ width: "25px" }}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                  
                                    />
                                  </div>
                                </th> */}
                                <th className="" data-sort="id">
                                  Sl No.
                                </th>
                                <th className="" data-sort="customer_name">
                                Payment Method
                                </th>
                                <th className="" data-sort="customer_name">
                                Cancellation Flag
                                </th>
                                <th className="" data-sort="customer_name">
                                  Status
                                </th>
                                <th className="" data-sort="city">
                                  Action
                                </th>
                              </tr>
                            </thead>
                          {loader===false?
                           <tbody>
                           <tr>
                          <td
                            style={{ textAlign: "center" }}
                            colSpan={8}
                          >
                            <Loading />
                          </td>
                        </tr>
                        </tbody>
                        :
                            <tbody className="list form-check-all">
                              {List.map((user, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="id">{pageindex===1 ? index+1: (pageindex-1) * 10 + index +1}</td>
                                    <td className="customer_name">
                                      {user.en_payment_method}
                                    </td>
                                    <td className="status">
                                      {user.cancelation_flag === false ? (
                                        <span class="badge badge-soft-danger text-uppercase">
                                          {" "}
                                         INACTIVE
                                        </span>
                                      ) : (
                                        <span className="badge badge-soft-success text-uppercase">
                                         ACTIVE
                                        </span>
                                      )}
                                    </td>
                                    <td className="status">
                                      {user.status === false ? (
                                        <span class="badge badge-soft-danger text-uppercase">
                                          {" "}
                                         INACTIVE
                                        </span>
                                      ) : (
                                        <span className="badge badge-soft-success text-uppercase">
                                         ACTIVE
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <ul className="list-inline hstack gap-2 mb-1">
                                        <li
                                          className="list-inline-item edit"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Edit">
                                          <Link
                                            to="/basic_settings/payment-method-edit"
                                            state={{
                                              payment_method_id: user.payment_method_id,
                                               
                                              }}
                                            data-bs-toggle="modal"
                                            className="text-primary d-inline-block edit-item-btn">
                                            <i className="ri-pencil-fill fs-16"></i>
                                          </Link>
                                        </li>
                                        <li
                                          className="list-inline-item"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Remove">
                                          <Link
                                            className="text-danger d-inline-block remove-item-btn"
                                            data-bs-toggle="modal"
                                            to="#"
                                            onClick={() => tog_animationFlip(user.payment_method_id)}>
                                            <i className="ri-delete-bin-5-fill fs-16"></i>
                                          </Link>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          }
                          </table>
                          {paginate > 1 ?
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={paginate}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />        
                          :""}
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer/>
      </div>
      {modal_animationFlip ? (
          <DeleteModal
            modal_animationFlip={modal_animationFlip}
            tog_animationFlip={tog_animationFlip}
            pageid={pageid}
            DeleteData={DeleteData}
            message={"Payment-method"}
          />
        ) : (
          ""
        )}
    </div>
  )
}

export default Paymentmethodlist