import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Toastmodal from "../../Common/Toastmodal";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Flatpickr from "react-flatpickr";
import { MultiSelect } from "react-multi-select-component";
import Config from "../../Config";
import moment from "moment";
import Loading from "../../Loading";
import DeleteModal from "../../DeleteModal";
import { UserContext } from "../../Contexts/UserContext";

function Blackoutdays() {
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const [id, setId] = useState(hotel_select ? hotel_select[0]?.value : "");
  const [hotelname, setHotelname] = useState(
    hotel_select ? hotel_select[0]?.label : ""
  );
  let navigate = useNavigate();

  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [activestaydateopen, setActivestaydateopen] = useState(false);
  const [openclosestay, setOpenclosestay] = useState("open");
  const [datefromstay, setDatefromstay] = useState("");
  const [datetostay, setDatetostay] = useState("");
  const [selectedWeekdaysstay, setSelectedWeekdaysstay] = useState([]);
  const [clickedDaysstay, setClickedDaysstay] = useState([]);
  const [selectedDatesstay, setSelectedDatesstay] = useState([]);
  const fpstay = useRef(null);
  const [code, setCode] = useState([]);
  const [promocode, setPromocode] = useState([]);
  const [promocodenew, setPromocodenew] = useState([]);
  const [loading, setLoading] = useState(false);
  const [delete_animationFlip, setDelete_animationFlip] = useState(false);
  const [promocodid, setPromocodeid] = useState("");
  const [Error, setError] = useState({});

  function tog_animationFlipDel(ids) {
    setPromocodeid(ids);
    setDelete_animationFlip(!delete_animationFlip);
  }

  const toggleSelectedDaystay = (dayIndex) => {
    if (selectedWeekdaysstay.includes(dayIndex)) {
      if (clickedDaysstay.includes(dayIndex)) {
        setClickedDaysstay((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayIndex)
        );
      } else {
        setClickedDaysstay((prevClickedDays) => [...prevClickedDays, dayIndex]);
      }
    }
  };

  const handleCalendarChangeFrom = (selectedDates) => {
    setDatefromstay(selectedDates[0]);
  };

  const handleCalendarChangeTo = (selectedDates) => {
    setDatetostay(selectedDates[0]);
  };

  const handleOpenclosestay = (e) => {
    setActivestaydateopen(false);
  };

  const getActiveCode = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/private-sale/promo-codes`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setCode(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getActiveCode();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getActiveCode();
    } else {
      console.log("error");
    }
  };

  const handleApplyToCalendarstay = () => {
    if (openclosestay === "open") {
      if (fpstay.current) {
        fpstay.current.flatpickr.clear();
      }
      if (datefromstay && datetostay && selectedWeekdaysstay.length > 0) {
        const dates = [];
        let currentDate = new Date(datefromstay);

        while (currentDate <= datetostay) {
          const dayOfWeek = currentDate.getDay();
          if (!clickedDaysstay.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
        setSelectedDatesstay(dates);
      }
    } else {
      if (fpstay.current) {
        fpstay.current.flatpickr.clear();
      }
      if (
        datefromstay &&
        datetostay &&
        selectedWeekdaysstay.length > 0 &&
        clickedDaysstay.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromstay);

        while (currentDate <= datetostay) {
          const dayOfWeek = currentDate.getDay();
          if (clickedDaysstay.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
        setSelectedDatesstay(dates);
      }
    }
  };

  const handleCalendarIconClick = (calendarId) => {
    const calendar = document.querySelector(`#flatpickr-${calendarId}`);
    if (calendar) {
      const isCalendarOpen = calendar._flatpickr.isOpen;
      const icon = document.querySelector(`#calendar-icon-${calendarId}`);
      if (icon) {
        icon.style.display = isCalendarOpen ? "none" : "inline-block";
      }
      calendar._flatpickr[isCalendarOpen ? "close" : "open"]();
    }
  };

  const Add = async () => {
    let isFormValid = true;

    const errors = {};

    if (code.length > 0) {
      if (promocode.length === 0) {
        isFormValid = false;
        errors.promocode = "Promocode is required!";
      }
    }
    if (selectedDatesstay.length === 0) {
      isFormValid = false;
      errors.selectedDatesstay = "Date field is required!";
    }
    setError(errors);

    if (isFormValid === true) {
      let newdate = [];
      for (let i = 0; i < selectedDatesstay.length; i++) {
        const formattedDate = moment(selectedDatesstay[i]).format("DD-MM-YYYY");
        newdate.push(formattedDate);
      }

      let newCode = [];
      if (promocode.length > 0) {
        for (let i = 0; i < promocode.length; i++) {
          newCode.push(promocode[i].value);
        }
      }

      const res = await fetch(
        `${Config.apiurl}hotel/private-sale/promo-code/black-out/date/add`,
        {
          method: "POST",
          body: JSON.stringify({
            hotel_id: id,
            promocodes: newCode,
            dates: newdate,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      const data = await res.json();
      if (res.status === 200) {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(true);
        getData();
        setActivestaydateopen(false);
      } else if (res.reauth === true) {
        handleUnauthorized();
        Add();
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
      }
    }
  };

  const Update = async () => {
    let isFormValid = true;

    const errors = {};

    if (code.length > 0) {
      if (promocode.length === 0) {
        isFormValid = false;
        errors.promocode = "Promocode is required!";
      }
    }
    if (selectedDatesstay.length === 0) {
      isFormValid = false;
      errors.selectedDatesstay = "Date field is required!";
    }
    setError(errors);

    if (isFormValid === true) {
      let newdate = [];
      for (let i = 0; i < selectedDatesstay.length; i++) {
        const formattedDate = moment(selectedDatesstay[i]).format("DD-MM-YYYY");
        newdate.push(formattedDate);
      }

      let newCode = [];
      if (promocode.length > 0) {
        for (let i = 0; i < promocode.length; i++) {
          newCode.push(promocode[i].value);
        }
      }

      const res = await fetch(
        `${Config.apiurl}hotel/private-sale/promo-code/black-out/date/bulk-update`,
        {
          method: "POST",
          body: JSON.stringify({
            hotel_id: id,
            promocodes: newCode,
            dates: newdate,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      const data = await res.json();
      if (res.status === 200) {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(true);
        getData();
        setActivestaydateopen(false);
      } else if (res.reauth === true) {
        handleUnauthorized();
        Update();
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
      }
    }
  };

  const getData = async (e) => {
    const res = await fetch(
      `${Config.apiurl}hotel/private-sale/promo-code/black-out/date/list`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setLoading(true);
        setPromocodenew(data.data);
        // setSelectedDatesstay(data.data[0].dates);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getData();
      } else {
        setLoading(true);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getData();
    } else {
      console.log("error");
    }
  };

  const Delete = async () => {
    let isFormValid = true;

    const errors = {};

    if (code.length > 0) {
      if (promocode.length === 0) {
        isFormValid = false;
        errors.promocode = "Promocode is required!";
      }
    }
    
    setError(errors);

    if (isFormValid === true) {

      let arr=[];
      for(let i=0; i<promocode.length; i++){
        arr.push(promocode[i].value);
      }

    const res = await fetch(
      `${Config.apiurl}hotel/private-sale/promo-code/black-out/date/delete`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
          promocode: arr,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const data = await res.json();
    if (res.status === 200) {
      setToastmodal(true);
      setToastmessage(data.message);
      setToasttype("success");
      setToastlarge(true);
      tog_animationFlipDel(false);
      setPromocode([]);
      setSelectedDatesstay(null);
      getData();
    } else if (res.reauth === true) {
      handleUnauthorized();
      Delete();
    } else {
      setToastmodal(true);
      setToastmessage(data.message);
      setToasttype("error");
      setToastlarge(true);
    }
  }
  };

  useEffect(
    () => {
      if (promocodenew) {
        for (let i = 0; i < code?.length; i++) {
          for (let j = 0; j < code?.length; j++) {
            if (promocodenew[j]?.promocode_id === code[i]?.value) {
              promocode.push({ value: code[i].value, label: code[i].label });
            }
          }
        }
      }
      const newselectedDate = [
        ...new Set(promocodenew?.flatMap((item) => item.dates)),
      ];
      if (newselectedDate) {
        setSelectedDatesstay(newselectedDate);
      }
    },
    [promocodenew?.length, code?.length],
    [promocode?.length]
  );

  useEffect(() => {
    getActiveCode();
    getData();
  }, [id]);

  useEffect(() => {
    const getWeekdays = (startDate, endDate) => {
      const weekdays = [];
      const currentDay = new Date(startDate);
      const lastDay = new Date(endDate);

      while (currentDay <= lastDay) {
        const dayOfWeek = currentDay.getDay();
        if (dayOfWeek >= 0 && dayOfWeek <= 6) {
          weekdays.push(dayOfWeek);
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }
      return weekdays;
    };

    const getWeekdaysBetweenSelectedDates = (datefromstay, datetostay) => {
      const weekdays = getWeekdays(datefromstay, datetostay);
      setSelectedWeekdaysstay(weekdays);
      setClickedDaysstay([]);
    };

    if (datefromstay && datetostay) {
      getWeekdaysBetweenSelectedDates(datefromstay, datetostay);
    } else {
      setSelectedWeekdaysstay([]);
    }
  }, [datefromstay, datetostay]);

  useEffect(() => {
    setId(hotel_select[0]?.value);
  }, [hotel_select]);

  useEffect(() => {
    setHotelname(hotel_select[0]?.label);
  }, [hotel_select]);

  return (
    <div className="main-content" style={{ marginTop: "30px" }}>
      <div className="container-fluid">
        <div className="row project-wrapper">
          <div className="row dashboard-top-tab-style">
            {loading === false ? (
              <Loading />
            ) : (
              <div className="col-xxl-12 dashboard-main-style">
                <h4 className="" style={{ marginLeft: "10px" }}>
                  {hotelname}
                </h4>
                <div className="card header">
                  <div className="card-body">
                    <div
                      className="flex-shrink-0 d-flex justify-content-between"
                      style={{ float: "right" }}
                    >
                      <Link
                        type="button"
                        to="#"
                        className="btn db-save-button-loyalty"
                        style={{ height: "30px" }}
                        onClick={(e) =>
                          tog_animationFlipDel(promocodenew[0].promocode_id)
                        }
                      >
                        Delete
                        <span
                          className="info-tag-blackout"
                          style={{ marginLeft: "5px" }}
                        >
                          i
                          <span
                            className="tooltiptext"
                            style={{ marginTop: "12px" }}
                          >
                            To remove blackout days for a specific promo code,
                            select the desired code and click the 'Delete'
                            button.
                          </span>
                        </span>
                      </Link>
                      {promocodenew[0]?.promocode_id ? (
                        <Link
                          type="button"
                          to="#"
                          className="btn db-save-button"
                          style={{ height: "30px" }}
                          onClick={Update}
                        >
                          {" "}
                          Save
                        </Link>
                      ) : (
                        <Link
                          type="button"
                          to="#"
                          className="btn db-save-button"
                          style={{ height: "30px" }}
                          onClick={Add}
                        >
                          {" "}
                          Save
                        </Link>
                      )}{" "}
                    </div>
                    <div
                      className="col-lg-4"
                      style={{ marginTop: "3%", marginLeft: "30px" }}
                    >
                      <div className="input-group">
                        <label
                          className="input-group-text input-joint-color"
                          id="inputGroup-sizing-default"
                        >
                          Promocode
                        </label>
                        <div className="col-md-6">
                          <MultiSelect
                            options={code}
                            value={promocode}
                            onChange={setPromocode}
                            labelledBy="Select"
                            selectionType="counter"
                            allowCustomValue={true}
                          />
                        </div>
                      </div>
                      <label style={{ color: "red" }}>{Error.promocode}</label>
                    </div>
                    <div
                      className="align-items-center d-flex"
                      style={{ marginTop: "3%" }}
                    >
                      <div style={{ marginLeft: "28px" }}>
                        <h4 style={{ color: "#008080" }}>
                          The selected date will be marked as blackout day.
                        </h4>
                      </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: "10px" }}>
                      <div className="offer-condition-calendar-pic form-extra-check-show">
                        <div
                          className="offer-condition-calendar form-calender-add form-stay-check-show"
                          style={{ width: "310px", height: "315px" }}
                        >
                          <Flatpickr
                            ref={fpstay}
                            className="offer_flat"
                            value={selectedDatesstay}
                            onChange={(date) => setSelectedDatesstay(date)}
                            options={{
                              mode: "multiple",
                              inline: true,
                              minDate: "today",
                            }}
                          />
                          <label style={{ color: "red", marginTop: "5px" }}>
                            {Error.selectedDatesstay}
                          </label>
                        </div>
                        <div className="offer-condition-head-sec">
                          <span className="info-tag">
                            i
                            <span className="tooltiptext">
                              Enhance your control over scheduling by
                              configuring blackout days, enabling you to select
                              and customize specific dates.
                            </span>
                          </span>
                          <p
                            className="offer-condition-calendar-choose checkin_choose_status"
                            onClick={(e) =>
                              setActivestaydateopen(!activestaydateopen)
                            }
                          >
                            Choose a period/date
                          </p>
                        </div>
                        <div
                          className={
                            activestaydateopen === true
                              ? "offer-condition-apply stay_choose_open"
                              : "offer-condition-apply stay_choose_open d-none"
                          }
                          style={{ marginTop: "3%", right: "17%" }}
                        >
                          <span className="offer-condition-close">
                            <Link to="" onClick={handleOpenclosestay}>
                              X
                            </Link>
                          </span>
                          <div
                            className="offer-condition-apply-inner-section"
                            style={{ marginTop: "10px" }}
                          >
                            <div className="offer-condition-apply-calender">
                              <div className="offer-condition-check">
                                <form action="#">
                                  <div className="input-group">
                                    <Flatpickr
                                      id="flatpickr-from"
                                      className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                      placeholder="FROM"
                                      options={{
                                        mode: "single",
                                        dateFormat: "d M, Y",
                                        minDate: "today",
                                      }}
                                      value={datefromstay}
                                      onChange={handleCalendarChangeFrom}
                                    />
                                    <span
                                      className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                      onClick={() =>
                                        handleCalendarIconClick("from")
                                      }
                                    ></span>
                                  </div>
                                </form>
                              </div>
                              <div className="offer-condition-check">
                                <form action="#">
                                  <div className="input-group">
                                    <Flatpickr
                                      id="flatpickr-to"
                                      className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                      placeholder="UNTIL"
                                      options={{
                                        mode: "single",
                                        dateFormat: "d M, Y",
                                        minDate: "today",
                                      }}
                                      value={datetostay}
                                      onChange={handleCalendarChangeTo}
                                    />
                                    <span
                                      className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                      onClick={() =>
                                        handleCalendarIconClick("to")
                                      }
                                    ></span>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="offer-condition-week">
                              <ul>
                                <li>
                                  <p>Mon</p>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox6"
                                    value="option1"
                                    checked={
                                      selectedWeekdaysstay.includes(1) &&
                                      !clickedDaysstay.includes(1)
                                        ? true
                                        : false
                                    }
                                    onChange={() => toggleSelectedDaystay(1)}
                                  />
                                </li>
                                <li>
                                  <p>Tue</p>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox6"
                                    value="option1"
                                    checked={
                                      selectedWeekdaysstay.includes(2) &&
                                      !clickedDaysstay.includes(2)
                                        ? true
                                        : false
                                    }
                                    onChange={() => toggleSelectedDaystay(2)}
                                  />
                                </li>
                                <li>
                                  <p>Wed</p>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox6"
                                    value="option1"
                                    checked={
                                      selectedWeekdaysstay.includes(3) &&
                                      !clickedDaysstay.includes(3)
                                        ? true
                                        : false
                                    }
                                    onChange={() => toggleSelectedDaystay(3)}
                                  />
                                </li>
                                <li>
                                  <p>Thu</p>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox6"
                                    value="option1"
                                    checked={
                                      selectedWeekdaysstay.includes(4) &&
                                      !clickedDaysstay.includes(4)
                                        ? true
                                        : false
                                    }
                                    onChange={() => toggleSelectedDaystay(4)}
                                  />
                                </li>
                                <li>
                                  <p>Fri</p>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox6"
                                    value="option1"
                                    checked={
                                      selectedWeekdaysstay.includes(5) &&
                                      !clickedDaysstay.includes(5)
                                        ? true
                                        : false
                                    }
                                    onChange={() => toggleSelectedDaystay(5)}
                                  />
                                </li>
                                <li>
                                  <p>Sat</p>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox6"
                                    value="option1"
                                    checked={
                                      selectedWeekdaysstay.includes(6) &&
                                      !clickedDaysstay.includes(6)
                                        ? true
                                        : false
                                    }
                                    onChange={() => toggleSelectedDaystay(6)}
                                  />
                                </li>
                                <li>
                                  <p>Sun</p>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox6"
                                    value="option1"
                                    checked={
                                      selectedWeekdaysstay.includes(0) &&
                                      !clickedDaysstay.includes(0)
                                        ? true
                                        : false
                                    }
                                    onChange={() => toggleSelectedDaystay(0)}
                                  />
                                </li>
                              </ul>
                            </div>
                            <div className="condition-apply-calendar-btn-sec">
                              <Link
                                className="condition-apply-calendar-btn"
                                onClick={handleApplyToCalendarstay}
                              >
                                Apply to calendar
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
      {delete_animationFlip ? (
        <DeleteModal
          modal_animationFlip={delete_animationFlip}
          tog_animationFlip={tog_animationFlipDel}
          DeleteData={Delete}
          pageid={promocodid}
          message={"Promocode Blackout day"}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Blackoutdays;
