import React, { useState ,useEffect} from "react";
import Footer from "../../Common/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import Alerts from "../../Alerts";
import Config from "../../Config";
import DeleteModal from "../../DeleteModal";
import Timezoneedit from "./Timezoneedit";
import ReactPaginate from "react-paginate";
import Commonheader from "../../Common/Commonheader";
import Basicsettingsheader from "../../Common/Basicsettingsheader";
import Select from "react-select";
import Loading from "../../Loading";


function Timezone() {
  const[timezone,setTimezone]=useState("")
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [formerror, setError] = useState({});
  const [formerror1, setError1] = useState({});
  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [pageid, setPageid] = useState("");
  const [pagedata, setPagedata] = useState("");
  const [paginate, setPaginate] = useState([]);
  const [page,setPage]=useState(1);
  const [pageindex,setPageindex]=useState(1);
  const [countryname,setCountryname]=useState("");
  const [country,setCountry]=useState([]);
  const [countrydata,setCountrydata]=useState([])
  const [loader,setLoader] = useState(false);
  
  function tog_animationFlip(id) {
    setmodal_animationFlip(!modal_animationFlip);
    setPageid(id);
  }
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  
  function tog_backdrop(ids) {
    setmodal_backdrop(!modal_backdrop);
    setPageid(ids);
    console.log(ids);
    const getinfo = async (e) => {
      const res = await fetch(`${Config.apiurl}admin/time-zone/${ids}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data1 = await res.json();
      const data = data1.data;
      if (data1.status_code !== "200" || !data) {
        console.log("error");
      } else {
        setPagedata(data.timezone);
        setCountrydata(data.country)

      }
    };
    getinfo();
  }

  
  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}admin/time-zone/list?page=${currentPage}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const data = await res.json();
    return data.data.data;
  };

  const handlePageClick = async (data) => {
    if(data===1){
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
    
      setList(commentsFormServer);
    
    }
    else{
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
    
      setList(commentsFormServer);
    }
     
    };


  const [List, setList] = useState([]);

  const getData = async (e) => {
    const res = await fetch(`${Config.apiurl}admin/time-zone/list?page=1`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setList(data.data.data);
      setPaginate(data.data.last_page);
      setLoader(true);
    }
  };

  const validate = (values) => {
    const errors = {};
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-,/,_]+$/;
    if (!values) {
      errors.timezone = "Timezone is required!";
    }
    else if (!noSpecialCharRegex.test(values)) {
      errors.timezone = "Timezone should not contain this special character";
    }
    else if (values.length > 50) {
      errors.timezone = `Timezone cannot be more than 50 characters!`;
    }
    return errors;
  };

  const validate1 = (values) => {
    const errors = {};
    if (!values) {
      errors.countryname = "Country is required!";
    }
    return errors;
  };

  const PostData = async (e) => {
    e.preventDefault();
    const res = validate(timezone);
    const res1 = validate1(countryname);

    setError(res);
    setError1(res1);

    if (!res.timezone && !res1.countryname) {
      const formData = new FormData();
      formData.append("timezone", timezone);
      formData.append("country", countryname.value);

      axios
        .post(`${Config.apiurl}admin/time-zone/add`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.status_code !== "200" || !res) {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("error");
          } else {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("success");
            handlePageClick(page);
          }
        });
    }
  };

  const DeleteData = async (id) => {
    axios
      .delete(`${Config.apiurl}admin/time-zone/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status !== "200" || !res) {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("error");
          setmodal_animationFlip(false);
        } else {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("success");
          setmodal_animationFlip(false);
          handlePageClick(page);
        }
      });
  };

  const UpdateData = async (id) => {
    const formData = new FormData();
    formData.append("timezone", pagedata.time_zone);
    formData.append("country", countrydata.value);
    formData.append("status", pagedata.status);
    formData.append('_method', 'PATCH');
    axios
      .post(`${Config.apiurl}admin/time-zone/update/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,

        },
      })
      .then((res) => {
        if (res.status_code !== "200" || !res) {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("error");
          setmodal_animationFlip(false);
        } else {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("success");
          setmodal_animationFlip(false);
          setmodal_backdrop(false);
          handlePageClick(page);
        }
      });
  };

  const getCountry = async (e) => {
    const res = await fetch(`${Config.apiurl}country-list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (res.status === 404 || !data) {
      console.log("error");
    } else {
      setCountry(data.data);
    }
  };

  let name, value;
  const handleInputedit = (e) => {
    e.preventDefault();
    console.log(e);
    name = e.target.name;
    value = e.target.value;
    setPagedata({ ...pagedata, [name]: value });
  };


  useEffect(() => {
    getData();
    getCountry();
  }, []);

  console.log(countryname);
  return (
    <div id="layout-wrapper">
    <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Timezone</h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Settings</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Basic Settings</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Location Settings</Link>
                      </li>
                      <li className="breadcrumb-item active">Timezone</li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>
            {alert ? 
                <Alerts type={alerttype} message={alertmessage} open={open} />
              : 
                ""}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create New Timezone
                      </h4>
                      {/* <!-- <div className="flex-shrink-0">
                                <button type="button" className="btn db-cancel-button"
                                    data-bs-toggle="offcanvas" to="">Reset</button>
                                <button type="button" className="btn db-save-button" data-bs-toggle="modal"
                                    id="create-btn" data-bs-target="">Save</button>
                            </div> --> */}
                    </div>

                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                          <div class="col-lg-4 col-md-4 col-sm-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Timezone
                            <span className="form-validation-required">*</span>
                              </label>
                              <input type="text" className="form-control" placeholder="" value={timezone}
                               onChange={(e) => setTimezone(e.target.value)}/>
                               </div>
                               <label style={{ color: "red" }}>{formerror.timezone} </label>                              

                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4">
                              <div className="input-group timzone-space">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Country
                            <span className="form-validation-required">*</span>
                              </label>
                              <Select
                                  className="form-control"
                                  data-choices
                                   name="choices-single-default"
                                  id="choices-single-default"
                                  options={country}                           
                                  onChange={(e)=>setCountryname(e)}></Select>
                                                             
                               </div>
                               <label style={{ color: "red" }}>
                                  {formerror1.countryname}
                                </label> 
                            </div>
                          {/* <div className="col-md-4">
                              <div className="mb-3">
                                <label className="form-label">
                                 Timezone
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                              <input type="text" className="form-control" placeholder="" value={timezone} onChange={(e) => setTimezone(e.target.value)}/>
                               <label style={{ color: "red" }}>{formerror.timezone} </label>
                            </div>
                            </div> */}
                            {/* <div className="col-md-4">
                              <div className="mb-3">
                                <label className="form-label">
                                  Country{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <Select
                                  className="form-control"
                                  data-choices
                                   name="choices-single-default"
                                  id="choices-single-default"
                                  options={country}                           
                                  onChange={(e)=>setCountryname(e)}
                                ></Select>
                                   <label style={{ color: "red" }}>
                                  {formerror1.countryname}
                                </label>
                              </div>
                            </div> */}
                            <div className="col-md-4 col-lg-4 col-sm-4"  style={{marginTop:"0px"}}>
                              <div className="mb-3 timzone-space">
                              <button
                                type="button"
                                className="btn db-save-single-button"
                                data-bs-toggle="modal"
                                id="create-btn"
                                data-bs-target=""  onClick={PostData}
                              >
                                Submit
                              </button>
                            </div>
                            </div>
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="card" id="orderList">
                    <div className="card-header border-0">
                      <div className="row align-items-center gy-3">
                        <div className="col-sm d-flex justify-content-between">
                          <h5 className="card-title mb-0">List Of Timezone</h5>
                          
                        </div>
                      </div>
                    </div>

                    <div className="card-body pt-0">
                      <div>
                        <ul
                          className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                          role="tablist"
                        ></ul>

                        <div className="table-responsive table-card mb-1">
                          <table
                            className="table table-nowrap align-middle"
                            id="orderTable"
                          >
                            <thead className="text-muted table-light">
                              <tr className="text-uppercase">
                                
                                <th className="" data-sort="id">
                                  SL
                                </th>
                                <th className="" data-sort="customer_name">
                                  Timezone
                                </th>
                                <th className="" data-sort="customer_name">
                                  Country
                                </th>
                                <th className="" data-sort="customer_name">
                                  Status
                                </th>
                                <th className="" data-sort="city">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {loader===false?
                             <tbody>
                             <tr>
                            <td
                              style={{ textAlign: "center" }}
                              colSpan={8}
                            >
                              <Loading />
                            </td>
                          </tr>
                          </tbody>
                          :
                            <tbody className="list form-check-all">
                              {List.map((user, index) => {
                                return (
                                  <tr key={index}>
                                   
                                    <td className="id">{pageindex===1 ? index+1: (pageindex-1) * 10 + index +1}</td>
                                    <td className="customer_name">
                                      {user.time_zone}
                                    </td>
                                    <td className="customer_name">
                                      {user.country_name}
                                    </td>
                                    <td className="status">
                                      {user.status === false ? (
                                        <span class="badge badge-soft-danger text-uppercase">
                                          {" "}
                                          INACTIVE
                                        </span>
                                      ) : (
                                        <span className="badge badge-soft-success text-uppercase">
                                          ACTIVE
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <ul className="list-inline hstack gap-2 mb-1">
                                        <li
                                          className="list-inline-item edit"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Edit"
                                        >
                                          <Link
                                            to="#"
                                            data-bs-toggle="modal"
                                            className="text-primary d-inline-block edit-item-btn"
                                            onClick={() =>
                                              tog_backdrop(user.zone_id)}
                                          >
                                            <i className="ri-pencil-fill fs-16"></i>
                                          </Link>
                                        </li>
                                        <li
                                          className="list-inline-item"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Remove"
                                        >
                                          <Link
                                            className="text-danger d-inline-block remove-item-btn"
                                            data-bs-toggle="modal"
                                            to="#"
                                            onClick={(e) =>
                                              tog_animationFlip(
                                                user.zone_id
                                                )
                                              }
                                          >
                                            <i className="ri-delete-bin-5-fill fs-16"></i>
                                          </Link>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                            }
                          </table>
                        </div>
                       {paginate > 1 ? (
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={paginate}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                       ) : (
                          ""
                        )} 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      {modal_animationFlip ? (
          <DeleteModal
            modal_animationFlip={modal_animationFlip}
            tog_animationFlip={tog_animationFlip}
            pageid={pageid}
            DeleteData={DeleteData}
            message={"time-zone"}
          />
        ) : (
          "")}
          {modal_backdrop ? (
          <Timezoneedit
            pagedata={pagedata}
            modal_backdrop={modal_backdrop}
            tog_backdrop={tog_backdrop}
            pageid={pageid}
            UpdateData={UpdateData}
            handleInputedit={handleInputedit}
            country={country}
            setCountrydata={setCountrydata}
            countrydata={countrydata}
          />
        ) : (
          ""
        )}
    </div>
  );
}

export default Timezone;
