import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import { Link } from "react-router-dom";
import "./alert.css";
import Commonimagepath from "../../../Commonimagepath";

function TempLoyalty({
  setTab,
  loyal,
  hoteldetails,
  sendApprove,
  review,
  setReview,
  reviewstatus,
  setReviewstatus,
  Reviewfn,
  reviewtab,
  setReviewtab,
  reviewbutton,
  approvebutton,
  fullapprove,
  setFullapprove,
  fullapprovebutton,
  approvehotelid,
  hoteluserassign,
}) {
  const SingleOptions = [
    { value: "hotel_info", label: "Hotel Information" },
    { value: "room_info", label: "Room Information" },
    { value: "hk_frequency", label: "HK Frequency" },
    { value: "tax", label: "Tax" },
    { value: "loyalty", label: "Loyalty" },
  ];
  console.log(reviewstatus);
  return (
    <div id="Loyalty" className="tabcontent">
      <div className="card-body">
        <div className="live-preview">
          <div className="row">
            <div className="col-md-12 be-form-tax-title">
              <label
                for="firstNameinput"
                className="form-label be-form-tax-title-status be_form_label"
              >
                Do you want a free loyalty program for your hotel{" "}
              </label>
              <div className="switch-field">
                <input
                  type="radio"
                  id="radio-one"
                  name="switch-one"
                  value={true}
                  checked={hoteldetails.loyalty_program_status === true}
                />
                <label for="radio-one">Yes</label>
                <input
                  type="radio"
                  id="radio-two"
                  name="switch-one"
                  value={false}
                  checked={hoteldetails.loyalty_program_status === false}
                />
                <label for="radio-two">No</label>
              </div>
            </div>
            {hoteldetails.loyalty_program_status === true ? (
              <div className="col-md-12 loyalty_include_section">
                <div className="row">
                  <div className="col-md-12 ">
                    <label
                      for="firstNameinput"
                      className="form-label be-form-tax-title-status be_form_label"
                    >
                      What you want to call your loyalty program?{" "}
                    </label>
                    <div className="tax_include_status_btn">
                      <input
                        type="text"
                        className="form-control loyalty_form_field"
                        value={loyal.loyalty_program.program_name}
                      />
                    </div>
                  </div>
                  <div className="padding-5"></div>
                  <div className="col-md-12">
                    <label
                      for="firstNameinput"
                      className="form-label be-form-tax-title-status be_form_label"
                    >
                      What is the basic discount percentage you wanted to give
                      your loyalty program?
                    </label>
                    <div className="tax_include_status_btn tax_include_status_display">
                      <input
                        type="text"
                        className="form-control"
                        value={loyal.loyalty_program.discount_percentage}
                      />
                    </div>
                    <div className="discount_percentage_symbol">%</div>
                  </div>
                  <div className="padding-5"></div>
                  <div className="col-md-12 ">
                    <label
                      for="firstNameinput"
                      className="form-label be-form-tax-title-status be_form_label"
                    >
                      Logo URL
                    </label>
                    <div className="tax_include_status_btn loyalty_field-width">
                      <input
                        type="text"
                        className="form-control loyalty_form_field"
                        value={loyal.loyalty_program.club_logo_url}
                      />
                    </div>
                  </div>
                  <div className="padding-5"></div>
                  <div className="col-md-12 ">
                    <label
                      for="firstNameinput"
                      className="form-label be-form-tax-title-status be_form_label"
                    >
                      Club Condition URL
                    </label>
                    <div className="tax_include_status_btn loyalty_field-width">
                      <input
                        type="text"
                        className="form-control loyalty_form_field"
                        value={loyal.loyalty_program.club_condition_url}
                      />
                    </div>
                  </div>
                  <div className="padding-5"></div>
                  <div className="col-md-12 ">
                    <label
                      for="firstNameinput"
                      className="form-label be-form-tax-title-status be_form_label"
                    >
                      Privacy policy URL :
                    </label>
                    <div className="tax_include_status_btn loyalty_field-width">
                      <input
                        type="text"
                        className="form-control loyalty_form_field"
                        value={loyal.loyalty_program.club_policy_url}
                        name="loyalty_privacy_url"
                      />
                    </div>
                  </div>
                  <div className="padding-20"></div>
                  <div className="col-md-12">
                    <p className="beform-loyalty-level-title">
                      Fill in the Loyalty Details Below
                    </p>
                    <div className="be_loyalty_level">
                      <h5 className="be_loyalty_level_title">
                        Loyalty Club - Basic Level
                      </h5>
                      <span className="padding-5"></span>
                      <span className="be_form_loyalty_label_50">
                        <label
                          for="firstNameinput"
                          className="form-label be_form_label"
                        >
                          Level Name{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={loyal.loyalty_program.basic_level_name}
                          name="loyalty_name_ex"
                        />
                      </span>
                      <span className="be_form_loyalty_label_50">
                        <label
                          for="firstNameinput"
                          className="form-label be_form_label"
                        >
                          Logo{" "}
                        </label>
                        <img
                          src={`${Commonimagepath.apiurl}${loyal.loyalty_program.basic_level_logo}`}
                          alt=""
                          style={{ width: "30px", display: "block" }}
                        />
                      </span>
                    </div>

                    {loyal.loyalty_levels.map((data, index) => {
                      return (
                        <div key={index}>
                          <div className="be_loyalty_level">
                            <span className="be_form_loyalty_label_35">
                              <label
                                for="firstNameinput"
                                className="form-label be_form_label"
                              >
                                Name of Loyalty Level-{index + 1}{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={data.name}
                              />
                            </span>
                            {/* {data.loyalty==="other"?
                    <span
                      className="be_form_loyalty_label_30 other_loyalty_level"
                     
                    >
                      <label
                        for="firstNameinput"
                        className="form-label form_spec_color be_form_label"
                      >
                        Name of Loyalty Level-1{" "}
                        <span className="form-validation-required">*</span>
                        <span className="info-tag">
                          i
                          <span className="tooltiptext">
                            Name of the loyalty level you want to call
                          </span>
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={data.other}
                        name="other"   onChange={(e) => handleChangeloyalty(index, e)}
                      />
                    </span>
                    :""} */}
                            <span className="be_form_loyalty_label_35">
                              <label
                                for="firstNameinput"
                                className="form-label be_form_label"
                              >
                                Logo{" "}
                              </label>
                              <img
                                src={`${Commonimagepath.apiurl}${data.logo}`}
                                alt=""
                                style={{ width: "30px", display: "block" }}
                              />
                            </span>
                            <div className="padding-5"></div>
                            <span className="be_form_loyalty_label_full">
                              <p className="loyalty_form_subhead">
                                Classification Rules
                              </p>
                            </span>
                            <span className="be_form_loyalty_label_40 loyalty_joint_style">
                              <span className="input-group">
                                <span
                                  className="input-group-text input-joint-color1"
                                  id="inputGroup-sizing-default"
                                >
                                  Minimum Number of Reservations
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={data.minimum_reservation}
                                />
                              </span>
                            </span>
                            <span className="be_form_loyalty_label_20">
                              <input
                                type="text"
                                className="form-control"
                                value={data.condition}
                                disabled
                              />
                            </span>
                            <span className="be_form_loyalty_label_40 loyalty_joint_style">
                              <span className="input-group">
                                <span
                                  className="input-group-text input-joint-color1"
                                  id="inputGroup-sizing-default"
                                >
                                  Minimum Number of Room Nights
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={data.minimum_room_nights}
                                  name="minimum_night"
                                />
                              </span>
                            </span>
                            <span className="padding-5"></span>
                            <span className="be_form_loyalty_label_25 be_loyalty_level_sec1">
                            <label
                              for="firstNameinput"
                              className="form-label be_loyalty_level_sec1_label be_form_label"
                            >
                              Expiry Status
                            </label>
                            <span
                              className="form-check form-switch form-switch-lg mb-3"
                              dir="ltr"
                            >
                              <input
                                type="checkbox"
                                name="expiry_status"
                                className="form-check-input loyalty_expiration"
                                value={data.expiry_status}
                                checked={
                                  data.expiry_status === true ? true : false
                                }
                              />
                            </span>
                          </span>
                          {data.expiry_status===true?
                            <span className="be_form_loyalty_label_25 loyalty_joint_style">
                              <span className="input-group">
                                <span
                                  className="input-group-text input-joint-color1"
                                  id="inputGroup-sizing-default"
                                >
                                  {" "}
                                  Period
                                </span>

                                <input
                                  type="number"
                                  min={0}
                                  className="form-control"
                                  aria-label="Sizing example input"
                                  aria-describedby="inputGroup-sizing-default"
                                  value={data.period}
                                  name="period"
                                  onFocus={(e) =>
                                    e.target.addEventListener(
                                      "wheel",
                                      function (e) {
                                        e.preventDefault();
                                      },
                                      { passive: false }
                                    )
                                  }
                                />
                                <span
                                  className="input-group-text input-joint-color1"
                                  id="inputGroup-sizing-default"
                                >
                                  Year
                                </span>
                              </span>
                            </span>
                            :""}
                            <span className="input-group be_form_loyalty_label_35">
                              <span className="input-group">
                                <span
                                  className="input-group-text input-joint-color1"
                                  id="inputGroup-sizing-default"
                                >
                                  Percentage of discount
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={data.discount_percentage}
                                  name="dis_per"
                                />
                              </span>
                            </span>
                            <span className="padding-5"></span>
                            <span className="be_form_loyalty_label_full">
                              <span className="input-group be_loyalty_level_sec_select">
                                <span
                                  className="input-group-text input-joint-color1 be_loyalty_level_sec3_label"
                                  id="inputGroup-sizing-default"
                                >
                                  Benefits
                                </span>
                                <span className="be_loyalty_level_sec_select_field">
                                  <MultiSelect
                                    options={data.benefits}
                                    value={data.benefits}
                                    labelledBy="Select"
                                    selectionType="counter"
                                    closeOnChangedValue={true}
                                    aria-expanded="true"
                                  />
                                </span>
                              </span>
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="loyaltyappendmore"></div>
                  <div className="col-md-12 ">
                    <label
                      for="firstNameinput"
                      className="form-label be-form-tax-title-status be_form_label"
                    >
                      Do you want to display your loyalty rates to meta search
                      platforms?
                    </label>
                    <div className="switch-field">
                      <input
                        type="radio"
                        id="radio-three"
                        name="switch"
                        value={true}
                        checked={
                          loyal.loyalty_program.rate_display_status === true
                        }
                      />
                      <label for="radio-three">Yes</label>
                      <input
                        type="radio"
                        id="radio-four"
                        name="switch"
                        value={false}
                        checked={
                          loyal.loyalty_program.rate_display_status === false
                        }
                      />
                      <label for="radio-four">No</label>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="padding-20"></div>
            <div className="tab_nav_section">
              <div className="tab_nav_section_btns_form">
                <button
                  type="button"
                  className="btn btn-be-form-cancel btn-be-back"
                  onClick={(e) => setTab("Tax")}
                >
                  Prev
                </button>
                {hoteldetails.hotel_form_status !== 3 &&
                hoteldetails.hotel_form_status !== 1 &&
                hoteldetails.hotel_form_status !== 0 &&
                hoteldetails.is_approved === false &&
                hoteldetails.hotel_info_status === true &&
                hoteldetails.room_info_status === true &&
                hoteldetails.hk_info_status === true &&
                hoteldetails.tax_info_status === true &&
                hoteldetails.loyalty_info_status === true ? (
                  <div className="temp_approve_page_button">
                    <button
                      type="button"
                      className="btn btn-success btn-be-common temp_approve_page_button_style1  mr-2 "
                      onClick={(e) => setReviewstatus(true)}
                    >
                      Review & Resubmit
                    </button>
                    {
                      approvebutton === true ? (
                        <button
                          type="button"
                          disabled
                          className="btn btn-success btn-be-common temp_approve_page_button_style"
                          onClick={sendApprove}
                        >
                          Approve
                        </button>
                      ) : (
                        //  <button type="button" disabled className="btn btn-success btn-be-common temp_approve_page_button_style" onClick={(e)=>setFullapprove(true)} >Approve</button>

                        <button
                          type="button"
                          className="btn btn-success btn-be-common temp_approve_page_button_style"
                          onClick={sendApprove}
                        >
                          Approve
                        </button>
                      )
                      // <button type="button"  className="btn btn-success btn-be-common temp_approve_page_button_style" onClick={(e)=>setFullapprove(true)} >Approve</button>
                    }
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {reviewstatus === true ? (
        <Modal
          isOpen={reviewstatus}
          toggle={() => {
            setReviewstatus(false);
          }}
          backdrop={"static"}
          id="staticBackdrop"
          centered
        >
          <ModalHeader
            className="modal-title modal-header bg-light p-3"
            id="staticBackdropLabel"
            toggle={() => {
              setReviewstatus(false);
            }}
          >
            Enter Your review notes here
          </ModalHeader>
          <ModalBody className="">
            <label>Select the tabs which have corrections</label>
            <MultiSelect
              options={SingleOptions}
              value={reviewtab}
              onChange={setReviewtab}
              labelledBy="Select"
              selectionType="counter"
              closeOnChangedValue={false}
              aria-expanded="true"
            />
            <div className="mt-4">
              <label>Enter review comments</label>
              <textarea
                className="form-control"
                rows="5"
                value={review}
                onChange={(e) => setReview(e.target.value)}
              ></textarea>

              <div className="modal-footer p-3">
                <div className="hstack gap-2 justify-content-end">
                  <button
                    type="button"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setReviewstatus(false);
                    }}
                  >
                    Close{" "}
                  </button>
                  {reviewbutton === true ? (
                    <button
                      type="button"
                      className="btn db-save-single-button"
                      id="edit-btn"
                      onClick={Reviewfn}
                      disabled
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn db-save-single-button"
                      id="edit-btn"
                      onClick={Reviewfn}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      ) : (
        ""
      )}
      {/* {fullapprove===true? */}
      {/* 
    <Modal
       isOpen={fullapprove}
       toggle={() => {
        setFullapprove(false);
       }}
       backdrop={"static"}
       id="staticBackdrop"
       centered
     >
       
       <ModalBody className="approve_model">
        <h3>The Hotel {hoteldetails.hotel_name} is now onboard. Let's set up Extranet user accounts.</h3>
                 
      
         
           <div className="modal-footer p-3 approve_modal_footer">
             <div className="hstack gap-2">
              
               {fullapprovebutton===true?
               <button
                 type="button"
                 className="btn db-save-single-button"
                 id="edit-btn"
                 disabled
               >
                Create account
               </button>
               :
               <button
               type="button"
               className="btn db-save-single-button"
               id="edit-btn"
             >
               Create account
             </button>
              }
             </div>
           </div>
       </ModalBody>
     </Modal> */}

      <Modal
        isOpen={fullapprove}
        toggle={() => {
          setFullapprove(false);
        }}
        backdrop={"static"}
        id="staticBackdrop"
        centered
      >
        <ModalBody className="text-center  approve_model">
          <div className="approve_success_model">
            <div class="success_approve alert_approve ">
              {/* <div class="alert-body">
             Success !
          </div> */}
            </div>
          </div>
          {approvehotelid.user_count === 1 ? (
            <div className="mt-4">
              <h3 className="mb-3 approve_success_model_h3">
                The Hotel '{hoteldetails.hotel_name}' is now onboard, The email
                address '{approvehotelid.extranet_login}' is already in use. You
                can either create a user account with a new email address or add
                the user to this hotel. If you want to create an account with a
                different email, please proceed with the 'Create Account'
                option.{" "}
              </h3>
              <div className="hstack gap-2 justify-content-center">
                <Link
                  to="#"
                  className="btn btn-success"
                  onClick={(e) =>
                    hoteluserassign(
                      approvehotelid.user_id.user_id,
                      approvehotelid.hotel
                    )
                  }
                >
                  OK
                </Link>

                <Link
                  to={`/hotel_user_create/${hoteldetails.hotel_name}/${approvehotelid.hotel}`}
                  className="btn btn-success"
                  onClick={() => setFullapprove(false)}
                >
                  Create account
                </Link>
              </div>
            </div>
          ) : (
            <div className="mt-4">
              <h3 className="mb-3 approve_success_model_h3">
                The Hotel {hoteldetails.hotel_name} is now onboard. Let's set up
                Extranet user accounts.!
              </h3>

              <div className="hstack gap-2 justify-content-center">
                <Link
                  to={`/hotel_user_create/${hoteldetails.hotel_name}/${approvehotelid.hotel}`}
                  className="btn btn-success"
                  onClick={() => setFullapprove(false)}
                >
                  Create account
                </Link>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
      {/* :""} */}
    </div>
  );
}

export default TempLoyalty;
