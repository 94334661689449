import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Alerts from '../../../Alerts';
import Commonheader from '../../../Common/Commonheader'
import Config from '../../../Config';
import Loading from '../../../Loading';
import { UserContext } from '../../../Contexts/UserContext';
import { useAuthHandling } from '../../../Common/useAuthHandling';
import Toastmodal from '../../../Common/Toastmodal';
import { FaArrowTurnDown, FaArrowTurnUp } from 'react-icons/fa6';

function Cancellationpolicydefault() {
    let navigate = useNavigate();

    const { updateHotel_select, hotel_select, hotel_selectid } = useContext(UserContext);
    const { isAuthorized, handleUnauthorized } = useAuthHandling();
    const [id, setId] = useState(hotel_selectid ? hotel_selectid : '');

      const[policy,setPolicy]=useState([]);
      const[changedpolicy,setChangedpolicy]=useState([]);

    const[be_languagefull,setBe_languagefull]=useState([])
    const[loading,setLoading]=useState(false);
    const [toastmodal, setToastmodal] = useState(false);
    const [toastmessage, setToastmessage] = useState("");
    const [toasttype, setToasttype] = useState("");
    const [toastlarge, setToastlarge] = useState(false);
    const [showFullData, setShowFullData] = useState([]);

  

    const getinfo = async (e) => {
        const res = await fetch(`${Config.apiurl}policy-contracts/list`, {
          method: "POST",
          body: JSON.stringify({
            hotels: id
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });
        if(res.status===200){
        const data = await res.json();
        if (data.status_code === "200" ) {
        
            setPolicy(data.data);
          setLoading(true);
          }
       
        
        else if (data.reauth === true) {
          handleUnauthorized();
          getinfo();
  
        }
      }
      else if (res.status === 401) {
        handleUnauthorized();
        getinfo();
      }
    }

    const getfulllanguage = async (e) => {

        const res = await fetch(`${Config.apiurl}booking-engine-languages`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
  
          },
        });
        if(res.status===200){
          const data = await res.json();
          if (data.status_code === "200") {
            setBe_languagefull(data.data);
          }
          else if (data.reauth === true) {
            handleUnauthorized();
            getfulllanguage();
    
          }
        }
        else if (res.status === 401) {
          handleUnauthorized();
          getfulllanguage();
        }
       
      };
      const handleRoomCheckboxChange = (i, isChecked,contractid) => {
        // const updatedList = [...policy];
        // const hotel=policy.hotel_list[i].hotel_id;
        // const contarct=[...changedpolicy];
        // updatedList[i].selected = isChecked;
       
        // setChangedpolicy(contarct)

        const hotel=policy.hotel_list[i].hotel_id;
        const contarct=[...changedpolicy];
     
        const existingHotel = contarct.find(item => item.hotel === hotel);
        if(existingHotel){
          for(let i=0;i<contarct.length;i++){
            if(contarct[i].hotel===hotel){
              const existingHotelContracts = contarct[i].hotel_contracts;
              const index = existingHotelContracts.indexOf(contractid);
              if (index !== -1) {
                existingHotelContracts.splice(index, 1);
              } else {
                existingHotelContracts.push(contractid);
              }
              contarct[i].hotel_contracts=existingHotelContracts;
              setChangedpolicy(contarct)

            }
          }
       
        }
        else{
          contarct.push({ hotel: hotel, hotel_contracts: [contractid] });
          setChangedpolicy(contarct)

        }
        };

        console.log(changedpolicy);
      const Postdata = async (e) => {
        e.preventDefault();

        let arr=[];
        for (let i = 0; i < changedpolicy.length; i++) {
            let mularr={};
            let conarr=[];
            mularr.hotel_id=changedpolicy[i].hotel;
            for (let j = 0; j < changedpolicy[i].hotel_contracts.length; j++) {
              conarr.push( changedpolicy[i].hotel_contracts[j])
            }
            mularr.contract_id=conarr;

                arr.push(mularr);
        }
       const res = await fetch(
                  `${Config.apiurl}policy-contract/assign`,
                  {
                    method: "POST",
                    body: JSON.stringify({
                      hotels: arr,
                    }),
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                      "Content-type": "application/json; charset=UTF-8",
                    },
                  }
                );
                  if(res.status===200){
                const data = await res.json();
                if (data.status_code === "200" ) {
                  setToastmodal(true);
                  setToastmessage(data.message);
                  setToasttype("success");
                  setToastlarge(false);
                  setTimeout(() => navigate("/cancellation-policies-list"), 3000);

                } 
                else if (data.reauth === true) {
                  handleUnauthorized();
                  Postdata();
          
                }
                else {
                 
                  setToastmodal(true);
                  setToastmessage(data.message);
                  setToasttype("error");
                  setToastlarge(false);
                }
               
              }
              else if (res.status === 401) {
                handleUnauthorized();
                Postdata();
              }
             
       
      };

      const handleDropdownClickhotel = (i) => {

        const updatedShowFullData = [...showFullData];
        showFullData[i].hotel = !showFullData[i].hotel;
        setShowFullData(updatedShowFullData);
      };

    useEffect(() => {
       
          getfulllanguage();
    },[]);

    useEffect(() => {
      getinfo();
    }, [id]);

    useEffect(() => {
      setId(hotel_selectid);
    }, [hotel_selectid]);

    useEffect(() => {
      if (policy?.hotel_list?.length > 0) {
        const initialState = policy.hotel_list.map((item, index) => ({
          hotel: index === 0,
        }));
        setShowFullData(initialState);
      }
    }, [policy.length]);
    
    console.log(policy);
    console.log(showFullData);
  return (
    <div className="page-content">
        <Commonheader/>
        <div className="container-fluid">
            <div className="row project-wrapper">
                <div className="offer-list-main">
                    <div className="offer-list-top">
                        <div className="offer-list-title">
                            <h6 className="offer-list-head">General Cancellation Policies</h6>
                        </div>
                        <div className="offer-list-btn">
                            <Link type="button" className="btn db-save-button" to="#" onClick={Postdata}>
                                <span className="" style={{fontSize: "21px",lineHeight: "0px !important",paddingBottom: "0px !important",marginBottom: "0px !important"}}>+</span> Add To Hotel Policy
                            </Link>
                        </div>
                    </div>
                    {loading===false?
                    <Loading/>
                    :
                    <>
                    {policy.hotel_list.length>0?
                    policy.hotel_list.map((poli,i)=>{
                        return(
                  
                    <div className="row hotel_single_list" key={i}>
                   
                   <div className='hotel_service_display hotel_service_display_12'>
                            <div className='hk_service_all_hotel'>{poli.hotel_name}</div>
                            {showFullData[i]?.hotel === true ?

                            <span className="db-modify_toggle_btn"  onClick={() => handleDropdownClickhotel(i)}><span className="bx bxs-chevron-up" style={{fontSize:"20px"}}></span></span>

                              // <button className="cp-delete-icon hotel_list_arrowupdn" type="button" onClick={() => handleDropdownClickhotel(i)}
                              // ><FaArrowTurnUp /></button>
                              :
                              <span className="db-modify_toggle_btn"  onClick={() => handleDropdownClickhotel(i)}><span className="bx bxs-chevron-down" style={{fontSize:"20px"}}></span></span>

                              // <button className="cp-delete-icon hotel_list_arrowupdn" type="button" onClick={() => handleDropdownClickhotel(i)}
                              // ><FaArrowTurnDown /></button>
                            }
                          </div>
                          {policy.contract_list.map((contract,index)=>{
                            return(
                    <div className={`col-md-12 ${showFullData[i]?.hotel === true ? '' : 'd-none'}`} key={index}>

                        <div className="card offer-lists">
                        
                              <div className={poli.contractIds.includes(contract.contract_id) ?"policy-list-default policy-list-disable":"policy-list-default"}>
                              <div className="policy-list-item col-md-1 col-lg-1">
                                  <div className="policy-id-list d-flex">
                                     
                                  {poli.contractIds.includes(contract.contract_id) ? 
                                          <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id={`check-${i}`}
                                          value="option"                  
                                          disabled={true}                 
                                          />  
                                          :
                                          <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id={`check-${i}`}
                                          value="option"
                                          checked={poli.selected}                    
                                          onChange={(e) => handleRoomCheckboxChange(i,  e.target.checked,contract.contract_id)}
                                          />  
                                      }     
                                      <div className='policy-list-default_code'> {contract.contract_code}</div>
                                      </div>
                                </div>
                                
                                <div className="policy-list-content policy_content_type col-md-2 col-lg-2 pl-20" >
                                  <p style={{background:`${contract.color_code}`}}>{contract.contract_type}</p> </div>
                              <div className="policy-list-content col-md-9 col-lg-9"> {contract.description}</div>
                          </div>
                          
                       
                        </div>
                        
                    </div>
                         )
                        })}
                
                    </div>
                     )})
                     :
                     <center className='mt-4'>No data found</center>
                 }
                 </>
                 }
                </div>
            </div>
        </div>
        {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}

    </div>
  )
}

export default Cancellationpolicydefault
