import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../Common/Footer";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import Commonheader from "../../Common/Commonheader";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Basicsettingsheader from "../../Common/Basicsettingsheader";
import Alerts from "../../Alerts";
import Config from "../../Config";
import { useEffect } from "react";
import axios from "axios";
import Loading from "../../Loading";

function Boardedit() {

  const location = useLocation();
    let navigate = useNavigate();

    const [ids, setId] = useState(location.state.board_id);

        const [active, setActive] = useState("en");
        const[be_language,setBe_language]=useState([])
        const language = localStorage.getItem("be_language");
        const [alert, setAlert] = useState(false);
        const [alertmessage, setAlertmessage] = useState("");
        const [alerttype, setAlerttype] = useState("");
        const [formerror3, setError3] = useState({});

        const [formerror4, setError4] = useState({});
        const [formerror5, setError5] = useState({});
        const [open, setOpen] = useState(false);
        const [image,setImage]=useState([]);
        const [status,setStatus]=useState("")
        const [defaultstatus, setDefaultstatus] = useState("");
        const [sortorder, setSortorder] = useState('');
        const [boardCode, setBoardCode] = useState('');

        const [board,setBoard]=useState([]);
        const[defaultlang,setDefaultlang]=useState([]);
        const [optionallang,setOptionallang]=useState([]);
        const[langdata,setLangdata]=useState('')
        const[countrydata,setCountrydata]=useState('')
        const[be_languagefull,setBe_languagefull]=useState([])
        const [multi,setMulti]=useState([]);
        const [fullform, setFullform] = useState('');

     
        const getlanguage = async (e) => {
    
          const res = await fetch(`${Config.apiurl}optional-languages`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
    
            },
          });
          const data = await res.json();
          if (data.status_code !== "200" || !data.data) {
            console.log("error");
          } else {
            setBe_language(data.data);
          }
        
        
        };
    
        const getfulllanguage = async (e) => {

          const res = await fetch(`${Config.apiurl}booking-engine-languages`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
    
            },
          });
          const data = await res.json();
          if (data.status_code !== "200" || !data.data) {
            console.log("error");
          } else {
            setBe_languagefull(data.data);
          }
        
        
        };
        
        const handleInput = (e) => {
          let name = e.target.name;
          let value = e.target.value;
          setBoard({ ...board, [name]: value });
        };
       
     
        
      const [selectedMulti, setselectedMulti] = useState([]);
      function handleMulti(selectedMulti) {
        setselectedMulti(selectedMulti);
      }
     
      const [selectedMulti1, setselectedMulti1] = useState([]);
      function handleMulti1(selectedMulti1) {
        setselectedMulti1(selectedMulti1);
      }

      const validate = (values) => {
        const errors = {};
        const noSpecialCharRegex = /^[A-Za-z0-9\s]+$/;

        if (!values.name) {
          errors.name = "Name is required!";
        }
        else if (!noSpecialCharRegex.test(values.name)) {
          errors.name = "Name should not contain this special character";
        }
        else if (values.name.length > 50) {
          errors.name = `Name cannot be more than 50 characters!`;
        }
        if (!sortorder) {
          errors.sortorder = "Sort order is required!";
        }
        // if (!boardCode) {
        //   errors.boardCode = "Board code is required!";
        // }
        // if (!fullform) {
        //   errors.fullform = "Full form is required!";
        // }        
        return errors;
      };
    
      const validate1 = (values) => {
        const errors = {};
       
        if (!boardCode) {
          errors.boardCode = "Board code is required!";
        }
               
        return errors;
      };
      
      const validatestatus = () => {
        const errors = {};
        if (!status) {
          errors.status = "Status is required!";
        }
        return errors;
      };
      
      const PostData = async (e) => {
        e.preventDefault();
       
        const ress=validate(board)
        const ress1=validate1(boardCode)
        const ressstatus=validatestatus(status)

        setError3(ress1);
        setError4(ress);
        setError5(ressstatus);
    
        if (
          !ressstatus.status &&!ress.name && !ress.sortorder && !ress1.boardCode
        ) {
    
    
          const formData = new FormData();
          formData.append("en_name", board.name);
          formData.append("full_form", fullform);
          if(status===true || status==="true"){
            formData.append("is_active", 1);
          }
          else{
          formData.append("is_active", 0);
          }
          if(defaultstatus===true || defaultstatus==="true"){
            formData.append("default_status", 1);
          }
          else{
          formData.append("default_status", 0);
          }
          formData.append("sort_order", sortorder);
          formData.append("indicator", boardCode);
          formData.append('_method', 'PATCH');
                      for (let i = 0; i < multi.length; i++) {
                        if (multi[i].name) {
                          if (multi[i].code !== "en") {
                            
                          
                            formData.append(`multi_lang[${i}][lang_code]`, multi[i].code);
                            formData.append(`multi_lang[${i}][name]`, multi[i].name);
                            formData.append(`multi_lang[${i}][full_form]`, multi[i].full_form);
                         

                          }
                        }
                      }               
                      axios.post(`${Config.apiurl}admin/board/update/${ids}`, formData, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                       "Content-type": "application/json; charset=UTF-8",
                  },
                })
                .then((res) => {
    
              if (res.status_code !== "200" || !res) {
                setAlert(true);
                setAlertmessage(res.message);
                setOpen(true);
                setAlerttype("error");
              } else {
                setAlert(true);
                setAlertmessage(res.message);
                setOpen(true);
                setAlerttype("success");
                Reset();
                setTimeout(() => navigate("/basic_settings/board-list"), 3000);

              }
               
        }
                )}
      };
    
      function Reset(){
        setBoard("");
        setLangdata("");
        setCountrydata("");
        setselectedMulti([]);
        setselectedMulti1([]);
        setStatus("");
      }
    
      useEffect(() => {
        const getinfo = async (e) => {
            const res = await fetch(`${Config.apiurl}admin/board/${ids}`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            });
            const data = await res.json();          
              setBoard(data.data.board);
              setFullform(data.data.board.full_form);
             setMulti(data.data.multi_lang);
             setDefaultstatus(data.data.board.default_status);
              setStatus(data.data.board.is_active);
              setSortorder(data.data.board.sort_order);
              setBoardCode(data.data.board.indicator);
            
          };
          getinfo();
          getlanguage();
          getfulllanguage();
    },[language,ids]);

      const onFileChange = (e) => {
        console.log(e);
        setImage(  e.target.files[0] );
      };
    
      const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
    
      return (
        <div id="layout-wrapper">
      <Basicsettingsheader/>
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row">
                
                </div>
                {alert ? (
                  <Alerts type={alerttype} message={alertmessage} open={open} />
                ) : (
                  ""
                )}
                <div className="row project-wrapper">
                  <div className="row dashboard-top-tab-style">
                    <div className="col-xxl-12 dashboard-main-style">
                      <div className="card">
                        <div className="card-header align-items-center d-flex">
                          <h4 className="card-title mb-0 flex-grow-1">
                            Meal Board
                          </h4>
                          <div className="flex-shrink-0">
                            <Link
                              type="button"
                              className="btn db-other-button btn_size"
                              to="/basic_settings/board-list"
                              style={{ marginRight: "5px" }}
                            >
                              Meal Board
                            </Link>
                            {/* <button
                              type="button"
                              className="btn db-cancel-button"
                              data-bs-toggle="offcanvas"
                              to=""
                              onClick={Reset}
                            >
                              Reset
                            </button> */}
                            <button
                              type="button"
                              className="btn db-save-button"
                              data-bs-toggle="modal"                       
                              onClick={PostData}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                       
                        <div className="card-body">
                          <div className="live-preview">
                            <form action="#">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-translation-style">
                                    <ul>
                                    <li
                                        className={active === "en" ? "active" : ""}
                                      >
                                        <button
                                             type="button"
                                          onClick={() => setActive("en")}
                                          className={
                                            active === "en" ? "active" : ""
                                          }
                                          style={{border:"none",background:"transparent"}}
                                        >
                                          EN
                                        </button>
                                      </li>
                                      <li
                                        className={
                                          active === "trans" ? "active" : ""
                                        }
                                      >
                                        <button type="button"
                                          onClick={() => setActive("trans")}
                                          className={
                                            active === "trans" ? "active" : ""
                                          }
                                          style={{border:"none",background:"transparent"}}
                                        >
                                          Translations
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>                 

                                  {board!==undefined ?


                                <>
                             {active==="en"?
                                <>
                                <div class="col-lg-6 board-edit">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                      Name
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={board.name}
                                      name="name"
                                      onChange={handleInput}
                                    />
                                  
                                  </div>
                                  <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror4.name}
                                    </label>
                                </div>
                                <div class="col-lg-6">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                  Full form
                                  {/* <span className="form-validation-required">
                                    *
                                  </span> */}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                 value={fullform}
                                  onChange={(e) => setFullform(e.target.value)}
                                />
                                
                            
                              </div>
                              <label style={{ color: "red" }}>
                                    {formerror4.fullform}
                                  </label>
                            </div>
                            <div class="col-lg-3 mt-2">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                  Board Code{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={boardCode}
                                      onChange={(e)=>setBoardCode(e.target.value)}
                                    
                                    />
                                    
                              </div>
                              <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror3.boardCode}
                                    </label>
                            </div>
                                <div class="col-lg-3 mt-2">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                          Status{" "}
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                        </label>
                                        <select
                                          id="ForminputState"
                                          className="form-select"
                                          value={status}
                                          onChange={(e)=>setStatus(e.target.value)}
                                         
                                        >
                                          <option value="">Choose...</option>
                                          <option {...(status === true
                                        ? "selected"
                                        : "")}
                                      value={true} 
                                       >Active</option>
                                          <option {...(status === false
                                        ? "selected"
                                        : "")}
                                      value={false} >Inactive</option>
                                        </select>
                                       
                                      </div>
                                      <label style={{ color: "red" }}>
                                          {formerror5.status}
                                        </label>
                                    </div>

                                    <div class="col-lg-3 mt-2">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                  Default Board{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <select
                                  id="ForminputState"
                                  className="form-select"
                                  value={defaultstatus}
                                  onChange={(e) => setDefaultstatus(e.target.value)}
                                >
                                  <option selected>Choose...</option>
                                  <option {...(defaultstatus === true
                                        ? "selected"
                                        : "")}
                                      value={true} 
                                       >Active</option>
                                          <option {...(defaultstatus === false
                                        ? "selected"
                                        : "")}
                                      value={false} >Inactive</option>
                                  {/* <option value={1}>Active</option>
                                  <option value={0}>Inactive</option> */}
                                </select>
                              
                              </div>
                              <label style={{ color: "red" }}>
                                    {formerror4.defaultstatus}
                                  </label>
                            </div>

                            <div class="col-lg-3 mt-2">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                  Sort Order{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                      type="number"
                                      className="form-control"
                                      placeholder=""
                                      value={sortorder}
                                      name="sortorder"
                                      onChange={(e)=>setSortorder(e.target.value)}
                                      onKeyDown={(e) =>
                                        symbolsArr.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      min={0}
                                      onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}

                                    />
                                    
                              </div>
                              <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror4.sortorder}
                                    </label>
                            </div>
                                </>:    <>
                            <div className="col-lg-4" style={{ marginTop: "20px" }}>
                              <div className="input-group">
                                <label className="input-group-text input-joint-color" id="inputGroup-sizing-default" style={{ width: "130px" }}>
                                  Choose Language:
                                </label>
                                <div className="col-md-7 board-translations">
                                  <MultiSelect
                                    options={be_language}
                                    value={multi}
                                    onChange={setMulti}
                                    labelledBy="Select"
                                    selectionType="counter"
                                    closeOnChangedValue={true}
                                    aria-expanded="true"
                                    hasSelectAll={false}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="appendmore"></div>
                            {multi.length > 0 &&
                              multi.map((element, index) => (
                                <div
                                  key={index}
                                  className="col-md-12 translation-fill-section"
                                  id={`lang_${element.value}`}
                                  data-value="1"
                                  style={{ marginTop: "20px" }}
                                >
                                  <div className="translation-selected-lang">
                                    <p>
                                      <span className="translation-selected-lang-head">
                                        {element.code}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-4">
                                      <div className="input-group">
                                        <label className="input-group-text input-joint-color" id="inputGroup-sizing-default">
                                          Name
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder=""
                                          value={element.name}
                                          onChange={(e) =>
                                            setMulti((prevMulti) =>
                                              prevMulti.map((item, i) =>
                                                i === index ? { ...item, name: e.target.value } : item
                                              )
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="input-group">
                                        <label className="input-group-text input-joint-color" id="inputGroup-sizing-default">
                                          Full form
                                          {/* <span className="form-validation-required">
                                            *
                                          </span> */}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder=""
                                          value={element.full_form}
                                          onChange={(e) =>
                                            setMulti((prevMulti) =>
                                              prevMulti.map((item, i) =>
                                                i === index ? { ...item, full_form: e.target.value } : item
                                              )
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </>
                            //     <>
                            //     <div class="col-lg-4" style={{marginTop:"20px"}}>
                            //       <div className="input-group">
                            //         <label className="input-group-text input-joint-color"
                            //           id="inputGroup-sizing-default" style={{width:"130px"}}> Choose Language:</label>
                            //       <div className="col-md-7">
                            //       <MultiSelect
                            //           options={be_language}
                            //           value={multi}
                            //           onChange={setMulti}
                            //           labelledBy="Select"
                            //           selectionType="counter"
                            //           closeOnChangedValue={true}
                            //           aria-expanded="true"
                            //           hasSelectAll={false}
                            //         />
                            //   </div>
                            //     </div>
                            //     </div>
                            //     <div className="appendmore"></div>
                            //     {multi.length > 0
                            //       ? multi.map((element, index) => {
                            //           return (
                            //             <div
                            //               key={index}
                            //               class="col-md-12 translation-fill-section"
                            //               id="lang_ +val"
                            //               data-value="1" style={{marginTop:"20px"}}
                            //             >
                            //               <div class="translation-selected-lang">
                            //                 <p>
                            //                   <span class="translation-selected-lang-head">
                            //                     {element.code}
                            //                   </span>
                            //                 </p>
                            //               </div>
                            //               <div className="row">
                            //               <div class="col-lg-4">
                            //   <div className="input-group">
                            // <label className="input-group-text input-joint-color"
                            //     id="inputGroup-sizing-default">
                            //                       Name
                            //                       <span class="form-validation-required">
                            //                         *
                            //                       </span>
                            //                     </label>
                            //                     <input
                            //                       type="text"
                            //                       class="form-control"
                            //                       placeholder=""
                            //                       value={
                            //                         multi[index].name
                            //                       }
                            //                       onChange={
                            //                         (e) =>
                            //                           (multi[index].name =
                            //                             e.target.value)}
                            //                     />
                            //                   </div>
                            //                 </div>
                                           
                            //               </div>
                            //             </div>
                            //           );
                            //         })
                            //       : ""}</>
                                  }
                                    </>:<Loading/>}
                              </div>
  
                              <div className="padding-20"></div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      );
}

export default Boardedit