import React, { useContext,useEffect,useState } from 'react'
import Header from '../../../Layouts/Header';
import HotelSidebar from './Sidebars/HoletSidebar';
import Sidebar from './Sidebars/Sidebar';
import Sidebarbasicsettings from './Sidebars/Sidebarbasicsettings';
import { UserContext } from '../Contexts/UserContext';
import LZString from 'lz-string';
import CryptoJS from "crypto-js";

function Basicsettingsheader() {
  const {updateHotel_select, hotel_select} = useContext(UserContext);

  const [showhotel,setShowhotel]=useState(false);

  const [dashboard,setDashboard]=useState(true);
  const[pagename,setPagename]=useState("basic");
  const [user_type, setUser_type] = useState("");
  const secretKey ="fTh76vpd&nxs#94PzAr49Hy6q*!MbLR7";

useEffect(() => {
  const encryptedString = localStorage.getItem("user_type");
  if (encryptedString) {
    const bytes = CryptoJS.AES.decrypt(encryptedString, secretKey);
    const decryptedUserType = bytes.toString(CryptoJS.enc.Utf8); 
    setUser_type(decryptedUserType); 
  }
}, []);

  useEffect(()=>{
    setShowhotel(hotel_select.length>0?true:false);
  },[hotel_select])

  return (
    <div>
      <Header setShowhotel={setShowhotel} dashboard={dashboard} pagename={pagename} user_type={user_type}/>
     
        <Sidebarbasicsettings showhotel={showhotel} />
       
    </div>
  )
}

export default Basicsettingsheader
