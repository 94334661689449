import React, { useContext, useEffect, useState } from "react";
import Footer from "../../Common/Footer";
import { Link } from "react-router-dom";
import { useErrorBoundary } from "preact/hooks";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import Config from "../../Config";
import ReactPaginate from "react-paginate";
import DeleteModal from "../../DeleteModal";
import axios from "axios";
import Alerts from "../../Alerts";
import Commonheader from "../../Common/Commonheader";
import { UserContext } from "../../Contexts/UserContext";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Toastmodal from "../../Common/Toastmodal";
import Nodatafound from "../../Common/Nodatafound";
import Loading from "../../Loading";

function Hoteluserlist() {
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");

  const [user, setUser] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [pageid, setPageid] = useState("");
  const [page, setPage] = useState(1);
  const [pageindex, setPageindex] = useState(1);
  const [loader, setLoader] = useState(false);

  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [showFullData, setShowFullData] = useState([]);
  const [selehotel, setSelechotel] = useState(0);
  const [popup,setPopup]=useState(false);
  const [email,setEmail]=useState("");
  const [sendid,setSendid]=useState("");
  const [selectHotelId,setSelectHotelId]=useState("");
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);

    function tog_animationFlip(id,hId) {
      setmodal_animationFlip(!modal_animationFlip);
      setPageid(id);
      setSelectHotelId(hId);
    }

  const getinfo = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/extranet-login/list`, {
      method: "POST",
      body: JSON.stringify({
        hotels: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });

    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setUser(data.data.data);
        setPaginate(data.data.last_page);
        setLoader(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getinfo();
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getinfo();
    } else {
      console.log("error");
    }
  };

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}hotel/extranet-login/list?page=${currentPage}`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        return data.data.data;
      } else if (data.reauth === true) {
        handleUnauthorized();
        fetchComments(currentPage);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      fetchComments(currentPage);
    }
  };

  const handlePageClick = async (data) => {
    if (data === 1) {
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
      setUser(commentsFormServer);
    } else {
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
      setUser(commentsFormServer);
    }
  };

  const handleDropdownClickhotel = (i) => {
    // Toggle the showFullData state for the corresponding item
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  const ResendEmail = async () => {
      const res = await fetch(
        `${Config.apiurl}hotel/extranet-user/resend-mail`,
        {
          method: "POST",
          body: JSON.stringify({
            user_id:sendid
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200" || !data.data) {
          console.log("error");
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          setPopup(false);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        ResendEmail();
      } else {
        console.log("error");
    }
  };

  const sendemail= async (id,email) => {
    setPopup(true);
    setSendid(id);
    setEmail(email);
  }

  const DeleteData = async () => {
    const res = await fetch(
      `${Config.apiurl}hotel/extranet-user/delete`,
      {
        method: "POST",
        body: JSON.stringify({
          user_id:pageid,
          hotel_id:selectHotelId
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200" || !data) {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
        setmodal_animationFlip(false);
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(true);
        setmodal_animationFlip(false);
        getinfo();
        handlePageClick(page);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      DeleteData();
    } else {
      console.log("error");
  }
};

  useEffect(() => {
    getinfo();
  }, [id]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (user.length > 0) {
      const initialState = user.map((item, index) => ({
        hotel: index === 0,
      }));
      setShowFullData(initialState);
    }
  }, [user.length]);

  return (
    <div id="layout-wrapper">
      <Commonheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">USER</h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">DR Super Admin</Link>
                      </li>
                      <li className="breadcrumb-item active">Admin Users</li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="row project-wrapper">
              {/* <div className="row dashboard-top-tab-style"> */}
              <div className="col-xxl-12 dashboard-main-style">
                <div className="card">
                  <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Users</h4>
                    <div className="flex-shrink-0 d-flex justify-content-between">
                      <Link
                        type="button"
                        // to={`/hotel_user_create/${hotelname}/${hotelid}`}
                        to={`/hotel_user_create/${user[selehotel]?.hotel_name}/${user[selehotel]?.hotel_id}`}
                        className="btn db-save-button"
                      >
                        Create
                      </Link>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <div>
                      <ul
                        className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                        role="tablist"
                      ></ul>
                      {loader===false?
                      <Loading/>
                      :
                      <div className="table-responsive table-card mb-1">
                        {user.map((hotel, i) => {
                          return (
                            <div
                              className={
                                user.length > 1
                                  ? "row hotel_single_list"
                                  : "row"
                              }
                              key={i}
                              style={{ padding: "0px 10px" }}
                            >
                              {user.length > 1 ? (
                                <div className="hotel_service_display hotel_service_display_12">
                                  <div className="hotel_service_display_14">
                                    <input
                                      type="radio"
                                      id="radio-one"
                                      className="hotel_service_display_14_radio form-check-input per_room_once_check"
                                      value={true}
                                      checked={i === selehotel ? true : false}
                                      onChange={(e) => setSelechotel(i)}
                                      style={{
                                        position: "relative",
                                        top: "8px",
                                      }}
                                    />

                                    <div className="hk_service_all_hotel">
                                      {hotel.hotel_name}
                                    </div>
                                  </div>
                                  {showFullData[i]?.hotel === true ? (
                                    <span
                                      className="db-modify_toggle_btn"
                                      onClick={() =>
                                        handleDropdownClickhotel(i)
                                      }
                                    >
                                      <span
                                        className="bx bxs-chevron-up"
                                        style={{ fontSize: "20px" }}
                                      ></span>
                                    </span>
                                  ) : (
                                    <span
                                      className="db-modify_toggle_btn"
                                      onClick={() =>
                                        handleDropdownClickhotel(i)
                                      }
                                    >
                                      <span
                                        className="bx bxs-chevron-down"
                                        style={{ fontSize: "20px" }}
                                      ></span>
                                    </span>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              <table
                                className={`table table-nowrap align-middle ${
                                  showFullData[i]?.hotel === true
                                    ? ""
                                    : "d-none"
                                }`}
                                id="orderTable"
                              >
                                {hotel.users.length > 0 ? (
                                  <>
                                    <thead className="text-muted table-light">
                                      <tr className="text-uppercase">
                                        <th className="" data-sort="id">
                                          Sl No.
                                        </th>
                                        <th
                                          className=""
                                          data-sort="customer_name"
                                        >
                                          Name
                                        </th>
                                        {/* <th
                                          className=""
                                          data-sort="customer_name"
                                        >
                                          Last Name
                                        </th> */}
                                        <th
                                          className=""
                                          data-sort="customer_name"
                                        >
                                          Email
                                        </th>
                                        <th
                                          className=""
                                          data-sort="customer_name"
                                        >
                                          Status
                                        </th>
                                        <th
                                          className=""
                                          data-sort="customer_name"
                                        >
                                          Resend Email
                                        </th>
                                        <th className="" data-sort="city">
                                        Action
                                      </th>
                                      </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                      {hotel.users.map((usr, index) => {
                                        return (
                                          <tr key={index}>
                                            <td
                                              className={`id ${
                                                hotel.users.length === index + 1
                                                  ? "td_btm_line"
                                                  : ""
                                              }`}
                                            >
                                              {index + 1}
                                            </td>
                                            <td
                                              className={`customer_name ${
                                                hotel.users.length === index + 1
                                                  ? "td_btm_line"
                                                  : ""
                                              }`}
                                            >
                                              {usr.name} {usr.surname}
                                            </td>
                                            {/* <td
                                              className={`customer_name ${
                                                hotel.users.length === index + 1
                                                  ? "td_btm_line"
                                                  : ""
                                              }`}
                                            >
                                              {usr.surname}
                                            </td> */}
                                            <td
                                              className={`customer_name ${
                                                hotel.users.length === index + 1
                                                  ? "td_btm_line"
                                                  : ""
                                              }`}
                                            >
                                              {usr.email}
                                            </td>
                                            <td className={`customer_name ${
                                                hotel.users.length === index + 1
                                                  ? "td_btm_line"
                                                  : ""
                                              }`}>
                                              {usr.status === false ? (
                                                <span class="badge badge-soft-danger text-uppercase">
                                                  {" "}
                                                  INACTIVE
                                                </span>
                                              ) : (
                                                <span className="badge badge-soft-success text-uppercase">
                                                  ACTIVE
                                                </span>
                                              )}
                                            </td>
                                            <td className={`customer_name ${
                                                hotel.users.length === index + 1
                                                  ? "td_btm_line"
                                                  : ""
                                              }`}>
                                              <button type="button" className='send_email_btn' 
                                              onClick={(e)=>sendemail(usr.user_id,usr.email)}>
                                                Resend Email</button>
                                            </td>

                                            <td className={`customer_name ${
                                                hotel.users.length === index + 1
                                                  ? "td_btm_line"
                                                  : ""
                                              }`}>
                                            <ul className="list-inline hstack gap-2 mb-1">
                                              <li
                                                className="list-inline-item edit"
                                                data-bs-toggle="tooltip"
                                                data-bs-trigger="hover"
                                                data-bs-placement="top"
                                                title="Edit"
                                              >
                                                <Link
                                                  to={`/hotel-user-edit/${user[selehotel]?.hotel_name}/${user[selehotel]?.hotel_id}/${usr.user_id}`}
                                                  // state={{
                                                  // user_id: usr.user_id,
                                                  
                                                  // }}
                                                  data-bs-toggle="modal"
                                                  className="text-primary d-inline-block edit-item-btn"
                                                >
                                                  <i className="ri-pencil-fill fs-16"></i>
                                                </Link>
                                              </li>
                                              <li
                                                className="list-inline-item"
                                                data-bs-toggle="tooltip"
                                                data-bs-trigger="hover"
                                                data-bs-placement="top"
                                                title="Remove"
                                              >
                                                <Link
                                                  className="text-danger d-inline-block remove-item-btn"
                                                  data-bs-toggle="modal"
                                                  to="#"
                                                  onClick={() => tog_animationFlip(usr.user_id,hotel.hotel_id)}
                                                >
                                                  <i className="ri-delete-bin-5-fill fs-16"></i>
                                                </Link>
                                              </li>
                                            </ul>
                                          </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </>
                                ) : (
                                  <Nodatafound />
                                  // <div className="card-body pt-0">
                                  //   <div className="no-content text-center">
                                  //     No data Found
                                  //   </div>
                                  // </div>
                                )}
                              </table>
                            </div>
                          );
                        })}

                        {paginate > 1 ? (
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={paginate}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end marg_ins"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>

        <Footer />
      </div>
      {modal_animationFlip ? (
        <DeleteModal
          modal_animationFlip={modal_animationFlip}
          tog_animationFlip={tog_animationFlip}
          pageid={pageid}
          DeleteData={DeleteData}
          message={"Hotel user"}
        />
      ) : (
        ""
      )}

  <Modal
      id="flipModal"
      isOpen={popup}
      toggle={() => {
        setPopup(false);
      }}
      modalClassName="flip"
      centered
      style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
    >
      <ModalBody className="modal-body p-5 text-center">
        <lord-icon
          src="https://cdn.lordicon.com/gsqxdxog.json"
          trigger="loop"
          colors="primary:#405189,secondary:#f06548"
          style={{ width: "90px", height: "90px" }}
        ></lord-icon>

        <div className="mt-4 text-center">
          <h4>Email address is {email}</h4>
          <p className="text-muted fs-15 mb-4">
           Do you want to resend email to {email}?
          </p>
        </div>

        <button
        type="button"
          className="btn btn-link link-danger fw-medium text-decoration-none"
          onClick={() => {
            setPopup(false);
          }}
        >
          {" "}
          <i className="ri-close-line me-1 align-middle"></i>
          Close{" "}
        </button>
        <Button color="success" onClick={ResendEmail}>
          {" "}
          Yes, Resend email{" "}
        </Button>
      </ModalBody>
    </Modal>

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Hoteluserlist;
