import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Alerts from "../../Alerts";
import Commonheader from "../../Common/Commonheader";
import Footer from "../../Common/Footer";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Config from "../../Config";
import axios from "axios";
import Toastmodal from "../../Common/Toastmodal";
import Loading from "../../Loading";

function Pagecontentedit() {
  let { page_id, slug, page_name } = useParams();
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  let navigate = useNavigate();
  const [formerror, setError] = useState({});
  const [be_lang, setBe_lang] = useState([]);
  const [loader, setLoader] = useState(false);


  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [pagecontent, setPagecontent] = useState([]);

  const [inputFields, setInputFields] = useState([
    // {
    //   language: "",
    //   file_content: [
    //     {
    //       slug: "",
    //       name: "",
    //     },
    //   ],
    // },
  ]);

  const [inputFieldsold, setInputFieldsold] = useState([])


  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        language: "",
        page_slug:'',
        file_content: [
          {
            slug: "",
            name: "",
          },
        ],
      },
    ]);
  };

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const addInputFieldsub = (index) => {
    setInputFields((prevInputFields) => {
      const newInputFields = [...prevInputFields];
      const subArray = [...newInputFields[index].file_content];

      subArray.push({
        slug: "",
        value: "",
      });

      newInputFields[index].file_content = subArray;

      return newInputFields;
    });
  };

  const removeInputFieldssub = (index, i) => {
    setInputFields((prevInputFields) => {
      const newInputFields = [...prevInputFields];
      const subArray = [...newInputFields[index].file_content];
      subArray.splice(i, 1);
      newInputFields[index].file_content = subArray;
      return newInputFields;
    });
  };

  // const removeInputFieldssub = (index, key) => {
  //   setInputFields((prevInputFields) => {
  //     const newInputFields = [...prevInputFields];
  //     const newFileContent = { ...newInputFields[index].file_content };
  //     delete newFileContent[key];
  //     newInputFields[index].file_content = newFileContent;
  //     return newInputFields;
  //   });
  // };

  const getinfo = async (e) => {
    const res = await fetch(`${Config.apiurl}booking-engine-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status === 404 || !data) {
      console.log("error");
    } else {
      setBe_lang(data.data);
    }
  };

  const handleChange = (i, e) => {
    const newInputFields = [...inputFields];
    newInputFields[i] = {
      ...newInputFields[i],
      lang_code: e.target.value,
    };
    setInputFields(newInputFields);
  };

  let handleChangesub = (index, i, e) => {
    let newval = [...inputFields];
    newval[index].file_content[i][e.target.name] = e.target.value;
    setInputFields(newval);
  };

  

  const getList = async (e) => {
    const res = await fetch(`${Config.apiurl}admin/page/content/view`, {
      method: "POST",
      body: JSON.stringify({
        page_id: page_id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setInputFieldsold(data.data);
        setLoader(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getList();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getList();
    } else {
      console.log("error");
    }
  };


  useEffect(() => {
    getList();
    getinfo();
  }, []);


  useEffect(()=>{
    const transformedData = inputFieldsold.map(({ lang_code, file_content }) => ({
      lang_code,
      page_slug: file_content.page_slug,
      file_content: Object.entries(file_content)
        .filter(([key]) => key !== 'page_slug' && key !== 'lang_code')
        .map(([slug, value]) => ({ slug, value }))
    }));
    
  setInputFields(transformedData);
  },[inputFieldsold])



  const Add = async () => {
    let isFormValid = true;

    let array1=[];
    for (let i = 0; i < inputFields.length; i++) {
      let err1={};
      let array = [];

      for (let j = 0; j < inputFields[i].file_content.length; j++) {
       
        let err = {};
        if (!inputFields[i].file_content[j].slug) {
          isFormValid = false;
          err.slug = "Slug is required!";
        }
        if (!inputFields[i].file_content[j].value) {
          isFormValid = false;
          err.value = "Text is required!";
        }
     
        array.push(err);
      
      }
      if (!inputFields[i].lang_code) {
        isFormValid = false;
        err1.lang_code = "Language is required!";
      }
      err1.file=array;
       array1.push(err1);

    }
    setError(array1);

    if (isFormValid === true) {
      const formData = new FormData();

      formData.append("page_id", page_id);
      formData.append("page_slug", slug);
      for (let i = 0; i < inputFields.length; i++) {
        formData.append(
          `translation[${i}][language]`,
          inputFields[i].lang_code
        );
        for (let j = 0; j < inputFields[i].file_content.length; j++) {
          
          formData.append(
            `translation[${i}][content][${j}][slug]`,
            inputFields[i].file_content[j].slug
          );
          formData.append(
            `translation[${i}][content][${j}][title]`,
            inputFields[i].file_content[j].value
          );
        }
      }
      axios
        .post(`${Config.apiurl}admin/page/content/update`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((res) => {
          if (res.status_code === "200" ) {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(true);
            setTimeout(
              () =>
                navigate(
                  `/page-content-listing/${slug}/${page_id}/${page_name}`
                ),
              3000
            );
          }
          else if(res.reauth===true){
            handleUnauthorized();
            Add();
          }
          else {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(true);

          }


          // if (res.status_code !== "200" || !res) {
          //   setToastmodal(true);
          //   setToastmessage(res.message);
          //   setToasttype("error");
          //   setToastlarge(true);
          // } else {
          //   setToastmodal(true);
          //   setToastmessage(res.message);
          //   setToasttype("success");
          //   setToastlarge(true);
          //   setTimeout(
          //     () =>
          //       navigate(
          //         `/page-content-listing/${slug}/${page_id}/${page_name}`
          //       ),
          //     3000
          //   );
          // }
        });
    }
  };


  console.log(inputFieldsold);
  console.log(inputFields);
  console.log(formerror);

  return (
    <div id="layout-wrapper">
      <Commonheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex res-header">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Edit Page Content for {page_name} Page
                      </h4>
                     
                      <div className="flex-shrink-0">
                        <Link
                          type="button"
                          className="btn db-other-button"
                          to={`/page-content-listing/${slug}/${page_id}/${page_name}`}
                          style={{ marginRight: "5px" }}
                        >
                          Page Content List
                        </Link>
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target=""
                          onClick={Add}
                        >
                          Save
                        </button>
                      </div>
                    
                    </div>
                    {loader===false?
                      <Loading/>
                      :
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12 justify-content-between page_content_responsive ">
                              <div
                                for="firstNameinput"
                                className="addmore_select-label "
                              >
                                Language{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                              </div>
                              <div
                                for="firstNameinput"
                                className="addmore_select-label"
                              >
                                Slug{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                              </div>
                              <div className="addmore_select-label ">
                                Text{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                              </div>
                            </div>
                            <div className="clearfix"></div>

                            <div className="new_options  " id="new_option">
                              {inputFields.map((data, index) => {
                                return (
                                  <div key={index} className="">
                                    <div className="row">
                                      <div className="col-md-3">
                                        <select
                                          id="ForminputState"
                                          className="form-select addmore_select"
                                          onChange={(e) =>
                                            handleChange(index, e)
                                          }
                                          value={data.lang_code}
                                          name="language"
                                        >
                                          <option value="">
                                            Choose language
                                          </option>
                                          {be_lang.map((lang, indi) => {
                                            return (
                                              <option
                                                key={indi}
                                                value={lang.code}
                                              >
                                                {lang.label}
                                              </option>
                                            );
                                          })}
                                        </select>
                                        
                                                      <label
                                                        style={{ color: "red" }}
                                                      >
                                                        {formerror[index]?.lang_code}
                                                      </label>
                                                   
                                      </div>
                                     
                                          {data.file_content
                                      
                                        .map((file,i) => (
                                          <div
                                            key={i}
                                            className={
                                              i === 0
                                                ? "contentclass"
                                                : "displayclass-in"
                                            }
                                          >
                                            <div
                                              className={
                                                i === 0
                                                  ? "nothingclass"
                                                  : "col-md-3 nothingclass"
                                              }
                                            ></div>
                                            <div
                                              className={
                                                i === 0
                                                  ? "col-md-4 p_1"
                                                  : "col-md-4"
                                              }
                                            >
                                              <input
                                                type="text"
                                                value={file.slug}
                                                onChange={(e) => handleChangesub(index, i, e)}
                                                name="slug"
                                                className="form-control addmore_select "
                                                placeholder="Enter ..."
                                                id=""
    
                                              />
                                              <label
                                                        style={{ color: "red" }}
                                                      >
                                                        {formerror[index]?.file[i]?.slug}
                                                      </label>
                                            </div>
                                            <div
                                              className={
                                                i === 0
                                                  ? "col-md-3 ml-2 p_2"
                                                  : "col-md-3 ml-2"
                                              }
                                            >
                                              <input
                                                type="text"
                                                onChange={(e) =>
                                                  handleChangesub(
                                                    index,
                                                    i,
                                                    e
                                                  )
                                                }
                                                value={file.value}
                                                name="value"
                                                className="form-control addmore_select "
                                                placeholder="Enter ..."
                                                id=""
                                              />

<label
                                                        style={{ color: "red" }}
                                                      >
                                                        {formerror[index]?.file[i]?.value}
                                                      </label>
                                            </div>
                                           
                                            <button
                                              className={
                                                i === 0
                                                  ? "remove_more-in d-none"
                                                  : "remove_more-in"
                                              }
                                              type="button"
                                              onClick={(e) =>
                                                removeInputFieldssub(index,i)
                                              }
                                              style={{ border: "none" }}
                                            >
                                              X
                                            </button>
                                            <span
                                        className={ i=== data.file_content.length - 1 ?"  span_add_page_sub":"d-none"}
                                        
                                      >
                                        <button
                                          className="extra-rate-add-in"
                                          type="button"
                                          onClick={(e) =>
                                            addInputFieldsub(index)
                                          }
                                          style={{ border: "none" }}
                                        >
                                          +
                                        </button>
                                      </span>
                                          </div>
                                        ))}
                                   
                                    </div>
                                    <div className="d-flex">
                                      <button
                                        className={
                                          index === 0
                                            ? "remove_more-new d-none"
                                            : "remove_more-new"
                                        }
                                        type="button"
                                        style={{ border: "none" }}
                                        onClick={(e) =>
                                          removeInputFields(index)
                                        }
                                      >
                                        Remove
                                      </button>
                                    </div>
                                    <div
                                      className={
                                        index === inputFields.length - 1
                                          ? "boder1 d-none"
                                          : "boder1"
                                      }
                                    ></div>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="be-form-add-anthor-section">
                              <button
                                type="button"
                                className="extra-rate-add-new"
                                style={{ border: "none", marginLeft: "5px" }}
                                onClick={addInputField}
                              >
                                Add
                              </button>
                            </div>

                            <div className="clearfix"></div>
                            <div className="appendmore"></div>
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Pagecontentedit;
