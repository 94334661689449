import React, { useState } from "react";
import { Link } from "react-router-dom";

function Offerstep5({
  setTab,
  offer_include_status,
  setOffer_include_status,
  loyalty,
  otherloyal_status,
  setOtherloyal_status,
  otherloyal,
  addInputField,
  removeInputFields,
  alllanguage,
  loyallang,
  setLoyallang,
  hangleotherloyal,
  nextoffterstep5,
  handleloyalchange,
  offer5error,
  hangleotherloyallang,
  handlelanglo,
}) {
  console.log(otherloyal);
  console.log(loyallang);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="offer-create-section offer-create-wid">
          <div className="offer-create-details">
            <div className="col-md-12">
              <h6 className="offer-step-desc">
                When including an existing extra in an offer, the final price
                typically does not consider the price of the extra separately.
                Instead, the extra is bundled into the overall offer price.{" "}
              </h6>

              <div className="offer-content-section">
                <div className="offer-form-status-section">
                  <ul className="offer-create--rate-list">
                    <li>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input offer_include_check"
                          type="checkbox"
                          id="formCheck1"
                          value={offer_include_status}
                          checked={offer_include_status === true ? true : false}
                          onClick={(e) =>
                            setOffer_include_status(e.target.checked)
                          }
                        />
                        <label>
                          Inclusions
                          <span className="info-tag">
                            i
                            <span className="tooltiptext">
                              When including an existing extra, the final price
                              will not consider the price of the extra
                            </span>
                          </span>
                        </label>
                      </div>
                      <div
                        className={
                          offer_include_status === true
                            ? "offer_include_expand"
                            : "offer_include_expand d-none"
                        }
                      >
                        <ul className="rest_list_block rest_list_media">
                          {loyalty.map((loyal, index) => {
                            return (
                              <li key={index}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck1"
                                  value={loyal.selected}
                                  checked={
                                    loyal.selected && loyal.selected === true
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    handleloyalchange(e.target.checked, index)
                                  }
                                />
                                <label className="offer_new_lbl label-media">
                                  {loyal.label}
                                </label>
                              </li>
                            );
                          })}

                          <li>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="formCheck1"
                              value={otherloyal_status}
                              checked={
                                otherloyal_status === true ? true : false
                              }
                              onClick={(e) =>
                                setOtherloyal_status(e.target.checked)
                              }
                            />
                            <label className="offer_new_lbl label-media">
                              Other
                            </label>
                          </li>
                          <label style={{ color: "red" }}>
                            {offer5error.loyalty}
                          </label>
                          <div
                            className={
                              otherloyal_status === true
                                ? "data offer_step5_dis_in step5-others"
                                : "d-none"
                            }
                          >
                            <div className="col-xxl-6 col-xl-6 col-sm-6 col-md-6 col-lg-6 eng-block">
                              {/* <select className="form-control" data-choices data-choices-search-false
                                                name="choices-single-default" id="idPayment"  style={{width:"130px",height:"35px",marginLeft:"30px"}} value={loyallang} onChange={(e)=>setLoyallang(e.target.value)}>
                                                <option>English</option>

                                            </select> */}

                              <button className="select_lang_dummy">
                                English
                              </button>
                              <div className="offer_other_lang_unl lang-block-local"></div>

                              <label
                                style={{ color: "red", marginLeft: "30px" }}
                              >
                                {offer5error.loyalty_name}
                              </label>
                              {otherloyal.map((data, indx) => {
                                return (
                                  <div key={indx}>
                                    <div
                                      className={
                                        indx === 0 ? "" : "extrarate_close_d"
                                      }
                                    >
                                      <input
                                        type="text"
                                        className="new_inp_type new_inp_type_padding lang-width-offer"
                                        name="loyalty_name"
                                        value={data.loyalty_name}
                                        onChange={(e) =>
                                          hangleotherloyal(
                                            indx,
                                            e.target.value,
                                            "loyalty_name"
                                          )
                                        }
                                      />
                                      <Link
                                        className={
                                          indx === 0
                                            ? " d-none"
                                            : " extrarate_remove_more"
                                        }
                                        to="#"
                                        onClick={(e) => removeInputFields(indx)}
                                      >
                                        X
                                      </Link>
                                    </div>
                                    <label
                                      className={
                                        indx === 0 ? "new d-flex" : "d-none"
                                      }
                                      style={{ marginLeft: "30px" }}
                                    >
                                      (E.g. A Bottle of cava on arrival){" "}
                                    </label>

                                    <p
                                      className={
                                        indx === otherloyal.length - 1
                                          ? "extrarate-room-link_new"
                                          : "extrarate_close_d d-none"
                                      }
                                    >
                                      <Link
                                        className=""
                                        to="#"
                                        onClick={addInputField}
                                        style={{
                                          color: "#5ea3cb",
                                          marginLeft: "25px",
                                        }}
                                      >
                                        Add a new inclusion
                                      </Link>
                                    </p>
                                  </div>
                                );
                              })}
                            </div>

                            <div className="col-xxl-6 col-xl-6 col-sm-6 col-md-6 col-lg-6">
                              <div className="input-group input_inclusion  choose-lang-wid">
                                <label
                                  className="input-group-text input-joint-color box-wid"
                                  id="inputGroup-sizing-default"
                                >
                                  Select Language:
                                </label>
                                <select
                                  className="form-control input_inclusion_select"
                                  data-choices
                                  data-choices-search-false
                                  name="choices-single-default"
                                  id="idPayment"
                                  value={loyallang}
                                  onChange={(e) => handlelanglo(e.target.value)}
                                >
                                  <option value="">Choose Language..</option>

                                  {alllanguage.map((lang, inx) => {
                                    return (
                                      <option key={inx} value={lang.code}>
                                        {lang.label}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>

                              <label
                                style={{ color: "red", marginLeft: "30px" }}
                              >
                                {offer5error.null}
                              </label>

                              {/* <label style={{ color: "red" ,marginLeft:"30px"}} className={offer5error.loyalty_name?"mt_off":""}>
                                                        {offer5error.loyalty_lang_name}
                                                </label> */}
                              {loyallang
                                ? otherloyal.map((data, indx) => {
                                    const isMatchingLanguage =
                                      data.lang.findIndex(
                                        (language) =>
                                          language.lang_code === loyallang
                                      );
                                    return (
                                      <>
                                        {data.lang.some(
                                          (item) => item.lang_code === loyallang
                                        ) ? (
                                          <>
                                            {console.log(isMatchingLanguage)}
                                            <div
                                              className={
                                                indx === 0
                                                  ? ""
                                                  : "extrarate_close_d"
                                              }
                                              style={{ marginTop: "-3px" }}
                                            >
                                              <input
                                                type="text"
                                                className={
                                                  indx === otherloyal.length - 1
                                                    ? "new_inp_type  new_inp_type_padding"
                                                    : "new_inp_type  new_inp_type_padding"
                                                }
                                                name="loyalty_lang_name"
                                                value={
                                                  data?.lang[isMatchingLanguage]
                                                    ?.loyalty_lang_name
                                                }
                                                onChange={(e) =>
                                                  hangleotherloyallang(
                                                    indx,
                                                    e.target.value,
                                                    "loyalty_lang_name"
                                                  )
                                                }
                                              />
                                              <Link
                                                className={
                                                  indx === 0
                                                    ? " d-none"
                                                    : " extrarate_remove_more"
                                                }
                                                to="#"
                                                onClick={(e) =>
                                                  removeInputFields(indx)
                                                }
                                              >
                                                X
                                              </Link>
                                            </div>
                                            <label
                                              className={
                                                indx === 0 ? "new" : "d-none"
                                              }
                                              style={{ marginLeft: "30px" }}
                                            >
                                              (E.g. A Bottle of cava on arrival){" "}
                                            </label>

                                            <span
                                              className={
                                                indx === otherloyal.length - 1
                                                  ? "extrarate-room-link_new"
                                                  : "extrarate_close_d d-none"
                                              }
                                            ></span>
                                          </>
                                        ) : (
                                          <>
                                            <div
                                              className={
                                                indx === 0
                                                  ? ""
                                                  : "extrarate_close_d"
                                              }
                                            >
                                              <input
                                                type="text"
                                                className={
                                                  indx === otherloyal.length - 1
                                                    ? "new_inp_type  new_inp_type_padding"
                                                    : "new_inp_type  new_inp_type_padding"
                                                }
                                                name="loyalty_lang_name"
                                                value={
                                                  data?.lang[
                                                    data.lang.length - 1
                                                  ]?.loyalty_lang_name
                                                }
                                                onChange={(e) =>
                                                  hangleotherloyallang(
                                                    indx,
                                                    e.target.value,
                                                    "loyalty_lang_name"
                                                  )
                                                }
                                              />
                                              {/* <Link className={indx===0 ?" d-none":" extrarate_remove_more"} to="#" onClick={(e)=>removeInputFields(indx)}>X</Link>                                      */}
                                            </div>
                                            <label
                                              className={
                                                indx === 0 ? "new" : "d-none"
                                              }
                                              style={{ marginLeft: "30px" }}
                                            >
                                              (E.g. A Bottle of cava on arrival){" "}
                                            </label>

                                            <span
                                              className={
                                                indx === otherloyal.length - 1
                                                  ? "extrarate-room-link_new"
                                                  : "extrarate_close_d d-none"
                                              }
                                            ></span>
                                          </>
                                        )}
                                      </>
                                    );
                                  })
                                : ""}
                            </div>
                          </div>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tab_nav_section">
                <div className="tab_nav_section_btns">
                  <Link
                    className="btn btn-be-form-cancel btn-be-back"
                    to="#"
                    onClick={(e) => nextoffterstep5("prev")}
                  >
                    Prev
                  </Link>
                  {/* <Link className="btn btn-success btn-be-common" to="#" onClick={(e)=>setTab("step6")}>Next</Link> */}
                  <Link
                    className="btn btn-success btn-be-common"
                    to="#"
                    onClick={(e) => nextoffterstep5("next")}
                  >
                    Next
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Offerstep5;
