import React from 'react'
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import Commonimagepath from '../../Commonimagepath';

function Countryedit({pagedata,
    modal_backdrop,
    tog_backdrop,
    pageid,
    UpdateData,
    handleInputedit,
    onFileChangeedit,
    currency
  }) {
  return (
    <Modal
    isOpen={modal_backdrop}
    toggle={() => {
      tog_backdrop();
    }}
    backdrop={"static"}
    id="staticBackdrop"
    centered
  >
    <ModalHeader
      className="modal-title modal-header bg-light p-3"
      id="staticBackdropLabel"
      toggle={() => {
        tog_backdrop();
      }}
    ></ModalHeader>
    <ModalBody className="">
      <div className="mt-4">
        <label for="orderId" className="form-label">
         Country Name
        </label>
        <input
          type="text"
          className="form-control"
          name="name"
           value={pagedata.name}
           onChange={handleInputedit}
        />

<label for="orderId" className="form-label mt-2">
         Country Code
        </label>
        <input
          type="text"
          className="form-control"
          name="code"
           value={pagedata.code}
           onChange={handleInputedit}
        />
         <label for="orderId" className="form-label mt-2">
         Currency
        </label>
        <select
          className="form-control "
          name="currency"
           value={pagedata.currency}
           onChange={handleInputedit}
        >
          <option>select</option>
            {currency.map((curr,i)=>{
            return(
                <option key={i}   {...(pagedata.currency === curr.currency_id
                    ? "selected"
                    : "")} value={curr.currency_id}>{curr.name}</option>
            )})}
         
          </select>

        <label for="orderId" className="form-label mt-2">
         Flag
        </label>
        <input
                                  type="file"
                                  className="form-control"
                                  placeholder=""
                                  id="lastNameinput"
                                  name="image"
                                   onChange={onFileChangeedit}
                                   accept=".jpg, .jpeg, .webp, .png"
                                />
        <img src={`${Commonimagepath.apiurl}${pagedata.country_flag}`} style={{margin:"5px",display:"block"}}/>
        
        <label for="orderId" className="form-label mt-2">
         Calling Code
        </label>
        <input
          type="text"
          className="form-control"
          name="calling_code"
           value={pagedata.calling_code}
           onChange={handleInputedit}
        />
        <label for="orderId" className="form-label mt-2">
          Status
        </label>
        <select
          className="form-control "
          name="status"
           value={pagedata.status}
           onChange={handleInputedit}
        >
          <option>Choose...</option>
          <option name="status"
            {...(pagedata.status === true
                                        ? "selected"
                                        : "")}
                                      value={true}
                                      >
            Active
          </option>
          <option name="status" 
          {...(pagedata.status === false
                                        ? "selected"
                                        : "")}

                                      value={false}>
            InActive
          </option>
        </select>

        <div className="modal-footer p-3">
          <div className="hstack gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
              onClick={() => {
                tog_backdrop();
              }}
            >
              Close{" "}
            </button>
            {/* <!-- <button type="submit" className="btn btn-success" id="add-btn">Save</button> --> */}
            <button
              type="button"
              className="btn db-save-single-button"
              id="edit-btn"
              onClick={() => UpdateData(pageid)}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </ModalBody>
  </Modal>
  )
}

export default Countryedit
