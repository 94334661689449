import React from "react";

export default function Nodatafound() {
  return (
    <div className="nodatafoundclass">
      <img src="/images/nodata.png" alt="" />
      <h3>No data found</h3>
    </div>
  );
}
