import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Common/Footer";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import Config from "../../Config";
import ReactPaginate from "react-paginate";
import Commonheader from "../../Common/Commonheader";
import Basicsettingsheader from "../../Common/Basicsettingsheader";
import Loading from "../../Loading";

function Currencylist() {
  const [currency, setCurrency] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const[page,setPage]=useState(1);
  const[pageindex,setPageindex]=useState(1);
  const [loader,setLoader] = useState(false);
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  function tog_animationFlip() {
    setmodal_animationFlip(!modal_animationFlip);
  }
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
  }

  const getData = async (e) => {
    const res = await fetch(`${Config.apiurl}admin/currency/list?page=1`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data1 = await res.json();
    const data = data1.data;
    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setCurrency(data.data);
      setPaginate(data.last_page);
      setLoader(true);
    }
  };

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}admin/currency/list?page=${currentPage}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const data = await res.json();
    return data.data.data;
  };

  const handlePageClick = async (data) => {
  
    if(data===1){
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
    
      setCurrency(commentsFormServer);
    
    }
    else{
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
    
      setCurrency(commentsFormServer);
    }
     
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div id="layout-wrapper">
     <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12" >
                
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Settings</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Basic Settings</Link>
                      </li>
                      <li className="breadcrumb-item active">Currencies</li>
                    </ol>
                    
                  </div> */}
                 
                </div>
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Currencies</h4>
                   <div className="button-style">
                
                      <Link
                      type="button"
                      to="/basic_settings/currency-add"
                      className="btn db-save-button"
                    >
                      Create
                    </Link>
                    </div>
                 </div>    
                  </div>
             
             {loader===false?
             <Loading/>
             :
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    {/* <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Currencies
                      </h4>
                    
                    </div> */}
                    {currency.length > 0 ? (
                      <div className="card-body pt-0">
                        <div>
                          <ul
                            className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                            role="tablist"
                          ></ul>

                          <div className="table-responsive table-card mb-1">
                            <table
                              className="table table-nowrap align-middle"
                              id="orderTable"
                            >
                              <thead className="text-muted table-light">
                                <tr className="text-uppercase">
                                  <th className="" data-sort="id">
                                    Sl No.
                                  </th>
                                  <th className="" data-sort="customer_name">
                                    Currencies
                                  </th>
                                  <th className="" data-sort="customer_name">
                                    Currency Code
                                  </th>
                                  <th className="" data-sort="customer_name">
                                    Status
                                  </th>
                                  <th className="" data-sort="city">
                                    Action
                                  </th>
                               
                                </tr>
                              </thead>
                              <tbody className="list form-check-all">
                                {currency.map((element, i) => {
                                  return (
                                    <tr key={i}>
                                      <td className="id">{pageindex===1 ? i+1: (pageindex-1) * 10 + i +1}</td>
                                      <td className="customer_name">
                                        {element.name}{" "}
                                      </td>
                                      <td className="customer_name">
                                        {element.code}{" "}
                                      </td>
                                      <td className="status">
                                        {element.status === false ? (
                                          <span class="badge badge-soft-danger text-uppercase">
                                            {" "}
                                            DISABLED
                                          </span>
                                        ) : (
                                          <span className="badge badge-soft-success text-uppercase">
                                            ACTIVE
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <ul className="list-inline hstack gap-2 mb-1">
                                          <li
                                            className="list-inline-item edit"
                                            data-bs-toggle="tooltip"
                                            data-bs-trigger="hover"
                                            data-bs-placement="top"
                                            title="Edit"
                                          >
                                            <Link
                                              to={`/basic_settings/currency-edit/${element.currency_id}`}
                                              data-bs-toggle="modal"
                                              className="text-primary d-inline-block edit-item-btn"
                                              // onClick={() => tog_backdrop()}
                                            >
                                              <i className="ri-pencil-fill fs-16"></i>
                                            </Link>
                                          </li>
                                          <li
                                            className="list-inline-item"
                                            data-bs-toggle="tooltip"
                                            data-bs-trigger="hover"
                                            data-bs-placement="top"
                                            title="Remove"
                                          >
                                            {/* <Link
                                              className="text-danger d-inline-block remove-item-btn"
                                              data-bs-toggle="modal"
                                              to="#"
                                              onClick={() =>
                                                tog_animationFlip()
                                              }
                                            >
                                              <i className="ri-delete-bin-5-fill fs-16"></i>
                                            </Link> */}
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          {paginate > 1 ? (
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              pageCount={paginate}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination justify-content-end"
                              }
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="card-body pt-0">
                        <div className="no-content text-center">
                          No data Found
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
        </div>

        <Footer />
      </div>
      <Modal
        id="flipModal"
        isOpen={modal_animationFlip}
        toggle={() => {
          tog_animationFlip();
        }}
        modalClassName="flip"
        centered
        style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalBody className="modal-body p-5 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#405189,secondary:#f06548"
            style={{ width: "90px", height: "90px" }}
          ></lord-icon>

          <div className="mt-4 text-center">
            <h4>You are about to delete a page ?</h4>
            <p className="text-muted fs-15 mb-4">
              Deleting your page will remove all of your information from our
              database.
            </p>
          </div>

          <button
            className="btn btn-link link-success fw-medium text-decoration-none"
            onClick={() => {
              tog_animationFlip();
            }}
          >
            {" "}
            <i className="ri-close-line me-1 align-middle"></i>
            Close{" "}
          </button>
          <Button color="danger"> Yes, Delete It </Button>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modal_backdrop}
        toggle={() => {
          tog_backdrop();
        }}
        backdrop={"static"}
        id="staticBackdrop"
        centered
      >
        <ModalHeader
          className="modal-title modal-header bg-light p-3"
          id="staticBackdropLabel"
          toggle={() => {
            tog_backdrop();
          }}
        ></ModalHeader>
        <ModalBody className="">
          <div className="mt-4">
            <label for="orderId" className="form-label">
              Page Name
            </label>
            <input
              type="text"
              id="orderId"
              className="form-control"
              placeholder="ID"
              readonly
            />

            <div className="modal-footer p-3">
              <div className="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                  onClick={() => setmodal_backdrop(false)}
                >
                  Close
                </button>
                {/* <!-- <button type="submit" className="btn btn-success" id="add-btn">Save</button> --> */}
                <button
                  type="button"
                  className="btn db-save-single-button"
                  id="edit-btn"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Currencylist;
