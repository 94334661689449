import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Commonheader from "../../../Common/Commonheader";
import { MultiSelect } from "react-multi-select-component";
import moment, { months } from "moment";
import dayjs from "dayjs";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import Config from "../../../Config";
import Alerts from "../../../Alerts";
import Loading from "../../../Loading";
import { Button, Modal, ModalBody, Spinner } from "reactstrap";
import Footer from "../../../Common/Footer";
import DeleteModal from "../../../DeleteModal";
import axios from "axios";
import { UserContext } from "../../../Contexts/UserContext";
import Toastmodal from "../../../Common/Toastmodal";
import Extrabedlist from "./Extrabedlist";
import { useAuthHandling } from "../../../Common/useAuthHandling";

function Rates() {
  const SingleOptions = [
    { value: "per_room", label: "Per Room" },
    { value: "per_person", label: "Per Person" },
    { value: "per_adult", label: "Per Adult" },
    { value: "per_child", label: "Per Child" },
  ];
  const SingleOptions1 = [
    // { value: "per_room", label: "Per Room" },
    { value: "per_person", label: "Per Person" },
    { value: "per_adult", label: "Per Adult" },
    { value: "per_child", label: "Per Child" },
  ];
  const normalSingle = [
    { value: "per_room", label: "Per Room" },
    { value: "per_person", label: "Per Person" },
    { value: "per_adult", label: "Per Adult" },
    { value: "per_child", label: "Per Child" },
  ];

  const [pageid, setPageid] = useState("");
  const [mealboard_delete, setMealboard_delete] = useState(false);
  function tog_mealboard_delete(ids) {
    setMealboard_delete(!mealboard_delete);
    setPageid(ids);
  }

  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  function tog_animationFlip() {
    setmodal_animationFlip(!modal_animationFlip);
  }

  const [modal_board_add, setmodal_board_add] = useState(false);

  function tog_animationFlip1() {
    setmodal_board_add(!modal_board_add);
  }

  const [modal_board_modify, setmodal_board_modify] = useState(false);

  function tog_animationFlip2() {
    setmodal_board_modify(!modal_board_modify);
    setSelectBoardChange([]);
  }

  const [modal_board_change, setmodal_board_change] = useState(false);

  function tog_animationFlip3() {
    setmodal_board_change(!modal_board_change);
    setBoardChange([]);
  }

  const [import_pop, setImport_pop] = useState(false);

  function ImportPopup() {
    setImport_pop(!import_pop);
  }
  
  const [importstatus, setImportstatus] = useState(true);
  const [daynumber, setDaynumber] = useState("");
  const [tab1, setTab1] = useState("default");
  const [tab5, setTab5] = useState("create");
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const [selectedRoomnormal, setSelectedRoomnormal] = useState([]);
  const [selectedRatenormal, setSelectedRatenormal] = useState([]);
  const [selectedBoardnormal, setSelectedBoardnormal] = useState([]);
  const [selectedOccupancynormal, setSelectedOccupancynormal] = useState([]);
  const [singleSelectnormal, setSingleSelectnormal] = useState([]);
  const [selectedWeekdays, setSelectedWeekdays] = useState([]);
  const [clickedDays, setClickedDays] = useState([]);
  const [selectedWeekdays1, setSelectedWeekdays1] = useState([]);
  const [clickedDays1, setClickedDays1] = useState([]);
  const [selectedWeekdaysnormal, setSelectedWeekdaysnormal] = useState([]);
  const [clickedDaysnormal, setClickedDaysnormal] = useState([]);
  const [datefrom, setDateFrom] = useState(null);
  const [extrabedshow, setExtrabedshow] = useState(false);
  const [boardallrate, setBoardAllrate] = useState([]);
  const [dateto, setDateTo] = useState(null);
  const [datefromnormal, setDateFromnormal] = useState(null);
  const [datetonormal, setDateTonormal] = useState(null);
  const [formerror, setError] = useState({});
  const [formerror1, setError1] = useState([{}]);
  const [formerror2, setError2] = useState({});
  const [formerror3, setError3] = useState([{}]);
  const [formerror4, setError4] = useState({});
  const [formerror5, setError5] = useState({});
  const [formerror6, setError6] = useState([{}]);
  const [formerror7, setError7] = useState([{}]);
  const [formerrorPop, setErrorPop] = useState([{}]);
  const [formerrorPop1, setErrorPop1] = useState([{}]);
  const [fromdatepop, setFromdatepop] = useState(null);
  const [todatepop, setTodatepop] = useState(null);
  const [extrabedratecurrency, setExtrabedratecurrency] = useState([]);
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);

  const [saveddata, setSaveddata] = useState([]);
  const [currencyname, setCurrencyname] = useState([]);

  const [normalval, setNormalval] = useState("+");
  const [normalamount, setNormalamount] = useState("currency");
  const [normalrate, setNormalrate] = useState("");
  const [ratecreate, setRatecreate] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionNew, setSelectedOptionNew] = useState(null);

  let counter = 0;

  const [dateString1, setDateString1] = useState(
    moment().format("MMM DD, YYYY")
  );
  const firstDayOfNextMonth = moment().add(1, "month").startOf("month");
  const lastDayOfNextMonth = firstDayOfNextMonth.clone().endOf("month");
  const [dateString2, setDateString2] = useState(
    lastDayOfNextMonth.format("MMM DD, YYYY")
  );
  const [id, setId] = useState(hotel_select ? hotel_select[0]?.value : "");
  const [hotelname, setHotelname] = useState(
    hotel_select ? hotel_select[0]?.label : ""
  );
  
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [toastmodal, setToastmodal] = useState(false);
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [todaydate, setTodaydate] = useState(moment().format("MMM DD, YYYY"));
  const [newRoom, setNewRoom] = useState([]);

  const [roomtype, setRoomtype] = useState([]);
  const [board, setBoard] = useState([]);
  const [ratelist, setRatelist] = useState([]);
  const [ratelistnew, setRatelistnew] = useState([]);
  const [ratebar,setRatebar] = useState([]);
  const [occupancy, setOccupancy] = useState([]);
  const [selectedRoomsFilter, setselectedRoomsFilter] = useState([]);
  const [selectedRateFilter, setselectedRateFilter] = useState([]);
  const [selectedBoardFilter, setselectedBoardFilter] = useState([]);
  const [selectedOccupancyFilter, setselectedOccupancyFilter] = useState([]);
  const [rate, setRate] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingimport, setLoadingimport] = useState(false);
  const [symbolsArr] = useState(["e", "E", "+", "-"]);
  const [symbolsrate] = useState(["e", "E", "+", "-", "."]);
  const [extrabed_room, setExtrabed_room] = useState([]);
  const [importFile, setImportFile] = useState("");
  const [formerrorimport, setFormerrorimport] = useState({});

  const handleCalendarChangeFrom = (selectedDates) => {
    setDateFrom(selectedDates[0]);
  };

  const handleCalendarChangeTo = (selectedDates) => {
    setDateTo(selectedDates[0]);
  };
  const handleCalendarChangeFrom1 = (selectedDates) => {
    setDynamicfromdate(selectedDates[0]);
  };

  const handleCalendarChangeTo1 = (selectedDates) => {
    setDynamictodate(selectedDates[0]);
  };
  const handleCalendarChangeFromnormal = (selectedDates) => {
    setDateFromnormal(selectedDates[0]);
  };

  const handleCalendarChangeTonormal = (selectedDates) => {
    setDateTonormal(selectedDates[0]);
  };

  const CalendarChangeFromPop = (selectedDates) => {
    setFromdatepop(selectedDates[0]);
  };

  const CalendarChangeToPop = (selectedDates) => {
    setTodatepop(selectedDates[0]);
  };

  const [active, setActive] = useState(false);
  function clickEventHandler() {
    if (mealboardnull === true && active === false) {
      tog_animationFlip();
    }
    setActive(!active);
    setTab1("boardrate");
  }

  const [extrabedinput, setExtrabedinput] = useState([
    {
      visible: true,
      visible1: true,
      visible2: true,
      visible3: true,
      extrabedrate1: "",
      extrabedrate2: "",
      extrabedrate3: "",
      extrabedrate4: "",
      extrabedrate5: "",
    },
  ]);

  const handleExtraInput = (index, name) => {
    const updatedValues = [...extrabedinput];
    updatedValues[index][name] = false;
    setExtrabedinput(updatedValues);
  };

  const handleExtraVisible = (index, name) => {
    const updatedValues = [...extrabedinput];
    updatedValues[index][name] = true;
    setExtrabedinput(updatedValues);
  };

  const handleChangeInv = (i, name, value) => {
    // .filter(data => data.value !== basicroom.value && !dynamicroomrate.some(dynamicData => dynamicData.room.value === data.value))
    // .map((room, index) => {
    //     if (index === i) {
    //         return { ...room, [name]: value };
    //     }
    //     return room;
    // });
    const updatedInv = [...roomtype];
    updatedInv[i][name] = value;
    setRoomtype(updatedInv);
  };

  var loop = new Date(dateString1);
  var end = new Date(dateString2);
  var newDate1 = loop.setDate(loop.getDate() - 1);
  var newDate2 = end.setDate(end.getDate() - 1);
  let arr = [];

  while (loop <= end) {
    var newDate = loop.setDate(loop.getDate() + 1);
    loop = new Date(loop);
    let date = moment(loop).format("MMM DD,YYYY");
    arr.push(date);
  }

  const handleInputChange = (i, ind, indx, index, indexx, value) => {
    const updatedValues = [...rate];
    updatedValues[i]["ratelists"][ind]["hotel_board"][indx]["occupancies"][
      index
    ]["rates"][indexx]["rate"] = value;
    updatedValues[i]["flag"] = true;
    updatedValues[i]["ratelists"][ind]["flag"] = true;
    updatedValues[i]["ratelists"][ind]["hotel_board"][indx]["flag"] = true;
    updatedValues[i]["ratelists"][ind]["hotel_board"][indx]["occupancies"][
      index
    ]["flag"] = true;
    updatedValues[i]["ratelists"][ind]["hotel_board"][indx]["occupancies"][
      index
    ]["rates"][indexx]["flag"] = true;

    setRate(updatedValues);
  };

  const [selectedrooms, setSelectedrooms] = useState([]);
  const [selectedrate, setSelectedrate] = useState([]);
  const [selectedboard, setSelectedboard] = useState([]);
  const [selectBoardadd, setSelectBoardadd] = useState([]);
  const [selectedoccupancy, setSelectedoccupancy] = useState([]);
  const [mealboard, setMealboard] = useState([]);
  const [selectage, setSelectage] = useState([]);
  const [extrabed, setExtrabed] = useState([]);
  const [dynamicrate, setDynamicrate] = useState([]);
  const [dynamicfromdate, setDynamicfromdate] = useState([]);
  const [dynamictodate, setDynamictodate] = useState([]);
  const [basicroom, setBasicroom] = useState([]);
  const [mealboardnull, setMealboardnull] = useState(false);
  const [dynamicrateselect, setDynamicrateselect] = useState([]);
  const [dynamicrateselectnew, setDynamicrateselectnew] = useState([]);
  const [dynamicroomrate, setDynamicroomrate] = useState([]);
  const [filteredOccupancy, setFilteredOccupancy] = useState([]);
  const [tab, setTab] = useState([]);
  const [mealboardpop, setMealboardpop] = useState([]);
  const [selectagepop, setSelectagepop] = useState([]);
  const [mealboard_new, setMealboard_new] = useState([]);
  const [selectBoardChange, setSelectBoardChange] = useState([]);
  const [modifyboardrate, setModifyboardrate] = useState([]);
  const [boardChange, setBoardChange] = useState([]);

  let handleTab = (val, i) => {
    let newval = [...tab];
    tab[i]["tab"] = val;
    setTab(newval);
  };

  let handleClickroom = (i) => {
    let newval = [...tab];
    tab[i]["click"] = !tab[i]["click"];
    setTab(newval);
  };

  const [inputFields, setInputFields] = useState([
    { rooms: [], rate: [], board: [], occupancy: [], condition: [] },
  ]);
  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        rooms: newextrabed_room.map((option) => option),
        rate: ratelist.map((option) => option),
        board: board.map((option) => option),
        occupancy: occupancy.map((option) => option),
        condition: [],
      },
    ]);
    setExtrabedinput([
      ...extrabedinput,
      {
        visible: true,
        visible1: true,
        visible2: true,
        visible3: true,
        extrabedrate1: "",
        extrabedrate2: "",
        extrabedrate3: "",
        extrabedrate4: "",
        extrabedrate5: "",
      },
    ]);
  };

  // let handleChanges = (i, data) => {
  //   setSelectedrooms(data);
  //   let newval = [...inputFields];
  //   inputFields[i]["rooms"] = data;
  //   setInputFields(newval);

  //   // Filter occupancy options based on selected rooms
  //   const selectedRoomType = data[0]; // Assuming only one room can be selected at a time

  //   // Find the selected room in roomtype array
  //   const selectedRoom = roomtype.find(room => room.label === selectedRoomType);

  //   if (selectedRoom) {
  //     // Extract occupancy options for the selected room
  //     const occupancyOptions = selectedRoom.occupancy_list.map(option => ({
  //       label: option.label,
  //       value: option.value
  //     }));
  //     setFilteredOccupancy(occupancyOptions);
  //   } else {
  //     // Reset occupancy options if no room is selected
  //     setFilteredOccupancy([]);
  //   }
  // };

  const roomOccupancy = inputFields
    .map((field) => field.rooms.map((room) => room.occupancy_list))
    .flat();

  const uniqueOccupancies = new Set();
  const uniqueRoomOccupancy = roomOccupancy.filter((option) => {
    const isUnique = !uniqueOccupancies.has(option.label);
    if (isUnique) {
      uniqueOccupancies.add(option.label);
    }
    return isUnique;
  });

  let handleChanges = (i, data) => {
    setSelectedrooms(data);
    let newval = [...inputFields];
    inputFields[i]["rooms"] = data;
    setInputFields(newval);
  };

  let handleChanges1 = (i, data) => {
    setSelectedrate(data);
    let newval = [...inputFields];
    inputFields[i]["rate"] = data;
    setInputFields(newval);
  };

  let handleChanges2 = (i, data) => {
    setSelectedboard(data);
    let newval = [...inputFields];
    inputFields[i]["board"] = data;
    setInputFields(newval);
  };

  let handleChanges3 = (i, data) => {
    setSelectedoccupancy(data);
    let newval = [...inputFields];
    inputFields[i]["occupancy"] = data;
    setInputFields(newval);
  };
  let handleChanges4 = (i, data) => {
    setSelectedoccupancy(data);
    let newval = [...inputFields];
    inputFields[i]["condition"] = data;
    setInputFields(newval);
  };
  const handleChangeExtrabedInput = (index, value, name) => {
    let newval = [...extrabedinput];
    extrabedinput[index][name] = value;
    setExtrabedinput(newval);
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  let val = arr.length;

  const handleChangeDynamic = (index, key, value) => {
    const updatedData = [...dynamicroomrate];
    updatedData[index][key] = value;
    setDynamicroomrate(updatedData);
  };

  const BulkupdatemealBoard = async (e) => {
    e.preventDefault();
    let mealarray = [];
    let isFormValid = true;
    for (let i = 0; i < selectBoardChange.length; i++) {
      const errors = {};

      if (!selectBoardChange[i].adult_price) {
        isFormValid = false;

        errors.adult_price = "Adult price is required!";
      }
      if (!selectBoardChange[i].child_price) {
        isFormValid = false;

        errors.child_price = "Child price is required!";
      }
      mealarray.push(errors);
    }
    let err = {};

    if (!fromdatepop) {
      isFormValid = false;
      err.fromdatepop = "From date is required!";
    }
    if (!todatepop) {
      isFormValid = false;
      err.todatepop = "To date is required!";
    }
    setErrorPop(mealarray);
    setErrorPop1(err);

    let newVal = [];
    for (let i = 0; i < selectBoardChange.length; i++) {
      let multiarray = {};
      multiarray.board = selectBoardChange[i].value;
      multiarray.adult_price = parseInt(selectBoardChange[i].adult_price, 10);
      multiarray.child_price = parseInt(selectBoardChange[i].child_price, 10);
      newVal.push(multiarray);
    }

    if (isFormValid === true) {
      const res = await fetch(`${Config.apiurl}hotel/board-rate/modify`, {
        method: "POST",
        body: JSON.stringify({
          hotel: id,
          boards: newVal,
          date_from: moment(fromdatepop).format("DD-MM-YYYY"),
          date_to: moment(todatepop).format("DD-MM-YYYY"),
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200") {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else if (data.reauth === true) {
          handleUnauthorized();
          BulkupdatemealBoard();
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          getAll();
          tog_animationFlip2();
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        BulkupdatemealBoard();
      } else {
        console.log("error");
      }
      // const data1 = await res.json();
      // const data = data1.data;
      // if (data1.status_code !== "200" || !data1) {
      //   setToastmodal(true);
      //   setToastmessage(data1.message);
      //   setToasttype("error");
      //   setToastlarge(true);
      // } else {
      //   setToastmodal(true);
      //   setToastmessage(data1.message);
      //   setToasttype("success");
      //   setToastlarge(true);
      //   getAll();
      //   tog_animationFlip2();
      // }
    }
  };

  const MealBoardAdd = async (e) => {
    e.preventDefault();

    let newVal = [];
    for (let i = 0; i < selectBoardadd.length; i++) {
      let multiarray = {};
      multiarray.board = selectBoardadd[i].value;
      multiarray.adult_price = parseInt(selectBoardadd[i].adult_price, 10);
      multiarray.child_price = parseInt(selectBoardadd[i].child_price, 10);
      newVal.push(multiarray);
    }

    const res = await fetch(`${Config.apiurl}hotel/hotel-board/add`, {
      method: "POST",
      body: JSON.stringify({
        hotel: id,
        boards: newVal,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        MealBoardAdd();
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(true);
        tog_animationFlip1();
        getAll();
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      MealBoardAdd();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;
    // if (data1.status_code !== "200" || !data1) {
    //   setToastmodal(true);
    //   setToastmessage(data1.message);
    //   setToasttype("error");
    //   setToastlarge(true);
    // } else {
    //   setToastmodal(true);
    //   setToastmessage(data1.message);
    //   setToasttype("success");
    //   setToastlarge(true);
    //   tog_animationFlip1();
    //   getAll();
    // }
  };

  const MealBoardChange = async (e) => {
    e.preventDefault();

    let newVal = [];
    for (let i = 0; i < boardChange.length; i++) {
      let multiarray = {};
      multiarray.board = boardChange[i].value;
      multiarray.adult_price = parseInt(boardChange[i].adult_price, 10);
      multiarray.child_price = parseInt(boardChange[i].child_price, 10);
      newVal.push(multiarray);
    }

    const res = await fetch(`${Config.apiurl}hotel/board-rate/add`, {
      method: "POST",
      body: JSON.stringify({
        hotel: id,
        boards: newVal,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
        tog_animationFlip3();
      } else if (data.reauth === true) {
        handleUnauthorized();
        MealBoardChange();
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(true);
        tog_animationFlip3();
        getAll();
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      MealBoardChange();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;
    // if (data1.status_code !== "200" || !data1) {
    //   setToastmodal(true);
    //   setToastmessage(data1.message);
    //   setToasttype("error");
    //   setToastlarge(true);
    //   tog_animationFlip3();
    // } else {
    //   setToastmodal(true);
    //   setToastmessage(data1.message);
    //   setToasttype("success");
    //   setToastlarge(true);
    //   tog_animationFlip3();
    //   getAll();
    // }
  };

  const DeleteMealboard = async (ids) => {
    const res = await fetch(`${Config.apiurl}hotel/board-rate/delete`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        modify_rate_id: ids,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
        setMealboard_delete(false);
      } else if (data.reauth === true) {
        handleUnauthorized();
        DeleteMealboard();
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(true);
        getAll();
        setMealboard_delete(false);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      DeleteMealboard();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;
    // if (data1.status_code !== "200" || !data1) {
    //   setToastmodal(true);
    //   setToastmessage(data1.message);
    //   setToasttype("error");
    //   setToastlarge(true);
    //   setMealboard_delete(false);
    // } else {
    //   setToastmodal(true);
    //   setToastmessage(data1.message);
    //   setToasttype("success");
    //   setToastlarge(true);
    //   getAll();
    //   setMealboard_delete(false);
    // }
  };

  const AgeupdatemealBoard = async (e) => {
    e.preventDefault();
    let isFormValid = true;

    let err = {};
    if (!selectage.age_from) {
      isFormValid = false;

      err.age_from = "Age is required!";
    }
    if (!selectage.age_to) {
      isFormValid = false;

      err.age_to = "Age is required!";
    }

    setError(err);

    if (isFormValid === true) {
      const res = await fetch(`${Config.apiurl}hotel/hotel-board/age-update`, {
        method: "POST",
        body: JSON.stringify({
          hotel: id,
          age_from: selectage.age_from,
          age_to: selectage.age_to,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200") {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else if (data.reauth === true) {
          handleUnauthorized();
          AgeupdatemealBoard();
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          getRate();
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        AgeupdatemealBoard();
      } else {
        console.log("error");
      }
      // const data1 = await res.json();
      // const data = data1.data;
      // if (data1.status_code !== "200" || !data1) {
      //   setToastmodal(true);
      //   setToastmessage(data1.message);
      //   setToasttype("error");
      //   setToastlarge(true);
      // } else {
      //   setToastmodal(true);
      //   setToastmessage(data1.message);
      //   setToasttype("success");
      //   setToastlarge(true);
      //   getRate();
      // }
    }
  };

  const getAll = async () => {
    const res = await fetch(`${Config.apiurl}hotel/rate-list/all`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data1 = await res.json();
      const data = data1.data;
      if (data1.status_code !== "200" || !data) {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        getAll();
      } else {
        setBoard(data.hotel_boards);
        setRoomtype(data.rooms);
        setExtrabed_room(data.rooms);
        setRatelist(data.rate_lists);
        setOccupancy(data.occupancies);
        setExtrabed(data.extrabed_age_range);
        setCurrencyname(data.currency);
        setNewRoom(data.rooms);

        setDynamicrate(data.dynamic_rate_list);
        setDynamicfromdate(data.dynamic_rate_list.from_date);
        setDynamictodate(data.dynamic_rate_list.to_date);
        // setBasicroom(data.dynamic_rate_list.basic_room);
        setModifyboardrate(data.modify_board_rates);
        if (data.dynamic_rate_list.length !== 0) {
          setDynamicrateselectnew(data.dynamic_rate_list.rates);
          setDynamicroomrate(data.dynamic_rate_list.room_rate_data);
        }

        if (data.hotel_boards.length === 0) {
          setMealboardnull(true);
        }
        setMealboard_new(data.meal_boards.new_boards);
        setMealboard(data.meal_boards.hotel_board);
        setSelectage(data.meal_boards);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getAll();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;

    // if (data1.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   setBoard(data.hotel_boards);
    //   setRoomtype(data.rooms);
    //   setExtrabed_room(data.rooms);
    //   setRatelist(data.rate_lists);
    //   setOccupancy(data.occupancies);
    //   setExtrabed(data.extrabed_age_range);
    //   setCurrencyname(data.currency);
    //   setNewRoom(data.rooms);

    //   setDynamicrate(data.dynamic_rate_list);
    //   setDynamicfromdate(data.dynamic_rate_list.from_date);
    //   setDynamictodate(data.dynamic_rate_list.to_date);
    //   setBasicroom(data.dynamic_rate_list.basic_room);
    //   setModifyboardrate(data.modify_board_rates);
    //   if (data.dynamic_rate_list.length !== 0) {
    //     setDynamicrateselect(data.dynamic_rate_list.rates);
    //     setDynamicroomrate(data.dynamic_rate_list.room_rate_data);
    //   }

    //   if (data.hotel_boards.length === 0) {
    //     setMealboardnull(true);
    //   }
    //   setMealboard_new(data.meal_boards.new_boards);
    //   setMealboard(data.meal_boards.hotel_board);
    //   setSelectage(data.meal_boards);
    // }
  };

  const getAllBedtype = async () => {
    const res = await fetch(`${Config.apiurl}hotel/extra-bed/list`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data1 = await res.json();
      const data = data1.data;
      if (data1.status_code !== "200" || !data) {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        getAllBedtype();
      } else {
        setBoardAllrate(data.extra_bed_rates);
        setExtrabedratecurrency(data.currency);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getAllBedtype();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;

    // if (data1.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   setBoardAllrate(data.extra_bed_rates);
    //   setExtrabedratecurrency(data.currency);
    // }
  };
  // const getHotelBoard = async (e) => {
  //   const res = await fetch(`${Config.apiurl}hotel/board-plan/list`, {
  //     method: "POST",
  //     body: JSON.stringify({
  //       hotel_id: id,
  //     }),
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       "Content-type": "application/json; charset=UTF-8",
  //     },
  //   });
  //   const data1 = await res.json();
  //   const data = data1.data;
  //   if (data1.status_code !== "200" || !data) {
  //     console.log("error");
  //   } else {
  //     setMealboard_new(data);
  //   }
  // };

  const ChildAgeUpdateExtrabed = async (e) => {
    e.preventDefault();
    let isFormValid = true;

    const errors = {};
    if (!extrabed.max_age_free_extra_bed) {
      isFormValid = false;
      errors.max_age_free_extra_bed = "This field is required!";
    }
    if (!extrabed.extra_bed_child_from) {
      isFormValid = false;
      errors.extra_bed_child_from = "This field is required!";
    }
    if (!extrabed.extra_bed_child_to) {
      isFormValid = false;
      errors.extra_bed_child_to = "This field is required!";
    }

    setError2(errors);

    if (isFormValid === true) {
      const res = await fetch(`${Config.apiurl}hotel/extra-bed/update`, {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
          infant_age: extrabed.max_age_free_extra_bed,
          child_extra_bed_from: extrabed.extra_bed_child_from,
          child_extra_bed_to: extrabed.extra_bed_child_to,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200") {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else if (data.reauth === true) {
          handleUnauthorized();
          ChildAgeUpdateExtrabed();
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          getRate();
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        ChildAgeUpdateExtrabed();
      } else {
        console.log("error");
      }
      // const data1 = await res.json();
      // const data = data1.data;
      // if (data1.status_code !== "200" || !data1) {
      //   setToastmodal(true);
      //   setToastmessage(data1.message);
      //   setToasttype("error");
      //   setToastlarge(true);
      // } else {
      //   setToastmodal(true);
      //   setToastmessage(data1.message);
      //   setToasttype("success");
      //   setToastlarge(true);
      //   getRate();
      // }
    }
  };

  const BulkupdateExtrabed = async (e) => {
    e.preventDefault();
    let isFormValid = true;

    const errors = {};
    let extrarray = [];
    if (!extrabed.max_age_free_extra_bed) {
      isFormValid = false;
      errors.max_age_free_extra_bed = "This field is required!";
    }
    if (!extrabed.extra_bed_child_from) {
      isFormValid = false;
      errors.extra_bed_child_from = "This field is required!";
    }
    if (!extrabed.extra_bed_child_to) {
      isFormValid = false;
      errors.extra_bed_child_to = "This field is required!";
    }

    for (let i = 0; i < inputFields.length; i++) {
      let err = {};
      if (inputFields[i].rooms.length === 0) {
        isFormValid = false;

        err.rooms = "Room is required!";
      }
      if (inputFields[i].rate.length === 0) {
        isFormValid = false;

        err.rate = "Rate is required!";
      }

      if (inputFields[i].board.length === 0) {
        isFormValid = false;

        err.board = "Board is required!";
      }
      if (inputFields[i].occupancy.length === 0) {
        isFormValid = false;

        err.occupancy = "Occupancy is required!";
      }
      if (inputFields[i].condition.length === 0) {
        isFormValid = false;

        err.condition = "This field is required!";
      }

      if (!extrabedinput[i].extrabedrate1) {
        isFormValid = false;

        err.extrabedrate1 = "Extra bed rate is required!";
      }
      extrarray.push(err);
    }
    setError2(errors);
    setError3(extrarray);

    let newVal = [];

    let roomval1 = [];
    let rateval1 = [];
    let boardval1 = [];
    let occupancyVal1 = [];

    for (let i = 0; i < inputFields.length; i++) {
      let multiarray = {};
      for (let j = 0; j < inputFields[i].rooms.length; j++) {
        roomval1.push(inputFields[i].rooms[j].value);
      }
      for (let j = 0; j < inputFields[i].rate.length; j++) {
        rateval1.push(inputFields[i].rate[j].value);
      }
      for (let j = 0; j < inputFields[i].board.length; j++) {
        boardval1.push(inputFields[i].board[j].value);
      }
      for (let j = 0; j < inputFields[i].occupancy.length; j++) {
        occupancyVal1.push(inputFields[i].occupancy[j].value);
      }

      multiarray.condition = inputFields[i].condition.value;

      if (extrabedinput[i].extrabedrate1) {
        multiarray.first_bed_rate = parseInt(extrabedinput[i].extrabedrate1);
      }
      if (extrabedinput[i].extrabedrate2) {
        multiarray.second_bed_rate = parseInt(extrabedinput[i].extrabedrate2);
      }
      if (extrabedinput[i].extrabedrate3) {
        multiarray.third_bed_rate = parseInt(extrabedinput[i].extrabedrate3);
      }
      if (extrabedinput[i].extrabedrate4) {
        multiarray.fourth_bed_rate = parseInt(extrabedinput[i].extrabedrate4);
      }
      if (extrabedinput[i].extrabedrate5) {
        multiarray.fifth_bed_rate = parseInt(extrabedinput[i].extrabedrate5);
      }
      multiarray.rooms = roomval1;
      multiarray.rates = rateval1;
      multiarray.boards = boardval1;
      multiarray.occupancies = occupancyVal1;

      newVal.push(multiarray);
    }

    if (isFormValid === true) {
      const res = await fetch(`${Config.apiurl}hotel/extra-bed/update`, {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
          infant_age: extrabed.max_age_free_extra_bed,
          child_extra_bed_from: extrabed.extra_bed_child_from,
          child_extra_bed_to: extrabed.extra_bed_child_to,
          conditions: newVal,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200") {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else if (data.reauth === true) {
          handleUnauthorized();
          BulkupdateExtrabed();
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          getRate();
          getAllBedtype();
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        BulkupdateExtrabed();
      } else {
        console.log("error");
      }
      // const data1 = await res.json();
      // const data = data1.data;
      // if (data1.status_code !== "200" || !data1) {
      //   setToastmodal(true);
      //   setToastmessage(data1.message);
      //   setToasttype("error");
      //   setToastlarge(true);
      // } else {
      //   setToastmodal(true);
      //   setToastmessage(data1.message);
      //   setToasttype("success");
      //   setToastlarge(true);
      //   getRate();
      //   getAllBedtype();
      // }
    }
  };

  const handlechange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setExtrabed({ ...extrabed, [name]: value });
  };

  const handleDynamicSelect = (i, name, value) => {
    const updatedValues = [...dynamicroomrate];
    updatedValues[i][name] = value;
    setDynamicroomrate(updatedValues);
  };

  const handleChangeDynamicselect = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setDynamicrate({ ...dynamicrate, [name]: value });
  };

  // const handleChangeDynamicselect = (e) => {
  //   const intValue = Math.floor(parseFloat(e.target.value));
  //   if (!isNaN(intValue)) {
  //     setDynamicrate({ ...dynamicrate, least_inventory_value: intValue });
  //   }
  // };

  const handleDynamicCurrency = (e) => {
    const { name, value } = e.target;
    setDynamicrate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const BulkupdateNormal = async (e) => {
    e.preventDefault();

    let isFormValid = true;
    const errors = {};

    if (selectedRoomnormal.length === 0) {
      isFormValid = false;

      errors.selectedRoomnormal = "Room is required!";
    }
    if (selectedRatenormal.length === 0) {
      isFormValid = false;

      errors.selectedRatenormal = "Rate list is required!";
    }
    if (selectedBoardnormal.length === 0) {
      isFormValid = false;

      errors.selectedBoardnormal = "Board is required!";
    }
    if (selectedOccupancynormal.length === 0) {
      isFormValid = false;

      errors.selectedOccupancynormal = "Occupancy is required!";
    }
    if (singleSelectnormal.length === 0) {
      isFormValid = false;

      errors.singleSelectnormal = "This field is required!";
    }
    if (!datefromnormal) {
      isFormValid = false;

      errors.datefromnormal = "From date is required!";
    }
    if (!datetonormal) {
      isFormValid = false;

      errors.datetonormal = "To date is required!";
    }
    setError4(errors);

    let roomVal = [];
    for (let i = 0; i < selectedRoomnormal.length; i++) {
      roomVal.push(selectedRoomnormal[i].value);
    }

    let rateVal = [];
    for (let i = 0; i < selectedRatenormal.length; i++) {
      rateVal.push(selectedRatenormal[i].value);
    }
    let boardVal = [];
    for (let i = 0; i < selectedBoardnormal.length; i++) {
      boardVal.push(selectedBoardnormal[i].value);
    }
    let occupancyVal = [];
    for (let i = 0; i < selectedOccupancynormal.length; i++) {
      occupancyVal.push(selectedOccupancynormal[i].value);
    }

    let exp = [];
    for (let i = 0; i < clickedDaysnormal.length; i++) {
      const numericValue = clickedDaysnormal[i];
      if (numericValue >= 0 && numericValue < weekdays.length) {
        const weekdayName = weekdays[numericValue];
        exp.push(weekdayName);
      }
    }

    if (isFormValid === true) {
      const res = await fetch(`${Config.apiurl}hotel/room/rate/update`, {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
          rooms: roomVal,
          boards: boardVal,
          rates: rateVal,
          occupancies: occupancyVal,
          type: singleSelectnormal.value,
          mode: tab5,
          operator: tab5 === "create" ? "+" : normalval,
          amount_type: tab5 === "create" ? "percentage" : normalamount,
          rate: tab5 === "create" ? ratecreate : normalrate,
          date_from: moment(datefromnormal).format("DD-MM-YYYY"),
          date_to: moment(datetonormal).format("DD-MM-YYYY"),
          exception_week: exp,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
      if (res.status === 200) {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(true);
        getRate();
        setClickedDaysnormal([]);
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
      }
    }
  };

  
  const BulkupdateDynamic = async (e) => {
    e.preventDefault();
    let isFormValid = true;

    const errors = {};
    if (!basicroom) {
      isFormValid = false;
      errors.basicroom = "Room is required!";
    }
    if (dynamicrate.type) {
      if (!selectedOption) {
        isFormValid = false;
        errors.selectedOption = "This field is required!";
      }
    } else {
      if (!selectedOptionNew) {
        isFormValid = false;
        errors.selectedOptionNew = "This field is required!";
      }
    }
   if(ratelistnew.length>0){
    if (dynamicrateselect.length === 0) {
      isFormValid = false;
      errors.dynamicrateselect = "Rate is required!";
    }
   }

    let basicarray = [];
    for (let i = 0; i < dynamicrateselect.length; i++) {
      const err = {};

      if (!dynamicrateselect[i].change_value) {
        isFormValid = false;

        err.change_value = "This field is required!";
      }
      basicarray.push(err);
    }
    let roomarray = [];

    if (dynamicroomrate.length > 0) {
      for (let i = 0; i < dynamicroomrate.length; i++) {
        const error = {};

        if (!dynamicroomrate[i].value) {
          isFormValid = false;
          error.value = "This field is required!";
          roomarray.push(error);
          break; // Stops the loop if an error is found
        }
        roomarray.push(error);
      }
    } else {
      isFormValid = true;
    }

    if (!dynamicfromdate) {
      isFormValid = false;
      errors.dynamicfromdate = "From date is required!";
    }
    if (!dynamictodate) {
      isFormValid = false;
      errors.dynamictodate = "To date is required!";
    }
    setError5(errors);
    setError6(basicarray);
    setError7(roomarray);

    let sun = "";
    if (clickedDays1.includes(0)) {
      sun = 0;
    } else if (selectedWeekdays1.includes(0)) {
      sun = 1;
    } else {
      sun = 2;
    }

    let mon = "";
    if (clickedDays1.includes(1)) {
      mon = 0;
    } else if (selectedWeekdays1.includes(1)) {
      mon = 1;
    } else {
      mon = 2;
    }

    let tue = "";
    if (clickedDays1.includes(2)) {
      tue = 0;
    } else if (selectedWeekdays1.includes(2)) {
      tue = 1;
    } else {
      tue = 2;
    }

    let wed = "";
    if (clickedDays1.includes(3)) {
      wed = 0;
    } else if (selectedWeekdays1.includes(3)) {
      wed = 1;
    } else {
      wed = 2;
    }

    let thu = "";
    if (clickedDays1.includes(4)) {
      thu = 0;
    } else if (selectedWeekdays1.includes(4)) {
      thu = 1;
    } else {
      thu = 2;
    }

    let fri = "";
    if (clickedDays1.includes(5)) {
      fri = 0;
    } else if (selectedWeekdays1.includes(5)) {
      fri = 1;
    } else {
      fri = 2;
    }

    let sat = "";
    if (clickedDays1.includes(6)) {
      sat = 0;
    } else if (selectedWeekdays1.includes(6)) {
      sat = 1;
    } else {
      sat = 2;
    }

    let exp = [];
    for (let i = 0; i < clickedDays1.length; i++) {
      const numericValue = clickedDays1[i];
      if (numericValue >= 0 && numericValue < weekdays.length) {
        const weekdayName = weekdays[numericValue];
        exp.push(weekdayName);
      }
    }

    let newVal = [];
    for (let i = 0; i < dynamicrateselect.length; i++) {
      let multiarray = {};
      multiarray.rate_id = dynamicrateselect[i].value;
      multiarray.basic_rate = dynamicrateselect[i].basic_rate;
      multiarray.operator = "-";
      multiarray.change_value = dynamicrateselect[i].change_value;
      if(dynamicrateselect[i].change_type){
      multiarray.change_type = dynamicrateselect[i].change_type;
      }
      else{
        multiarray.change_type = "currency";

      }
      newVal.push(multiarray);
    }

    let roomdata = [];
    if (dynamicroomrate.length > 0) {
      for (let i = 0; i < dynamicroomrate.length; i++) {
        for (let j = 0; j < roomtype.length; j++) {
          if (roomtype[j].value === dynamicroomrate[i].room.value) {
            if (roomtype[j].rate) {
              let multiarrayNew = {};
              multiarrayNew.operator = roomtype[j].operator;
              multiarrayNew.value = roomtype[j].rate;
              multiarrayNew.increment_type = roomtype[j].increment_type;
              if (roomtype[j].least_inventory && roomtype[j].least_inventory > 0) {
                multiarrayNew.least_inventory = roomtype[j].least_inventory;
                multiarrayNew.least_inventory_value =
                  roomtype[j].least_inventory_value;
                multiarrayNew.least_inventory_amount_type =
                  roomtype[j].least_inventory_amount_type;
              }
               else {
                multiarrayNew.least_inventory = 0;
                multiarrayNew.least_inventory_value = 0;
                multiarrayNew.least_inventory_amount_type = "currency";
              }
              if (roomtype[j].most_inventory && roomtype[j].most_inventory > 0) {
                multiarrayNew.most_inventory = roomtype[j].most_inventory;
                multiarrayNew.most_inventory_value =
                  roomtype[j].most_inventory_value;
                multiarrayNew.most_inventory_amount_type =
                  roomtype[j].most_inventory_amount_type;
              } else {
                multiarrayNew.most_inventory = 0;
                multiarrayNew.most_inventory_value = 0;
                multiarrayNew.most_inventory_amount_type = "currency";
              }
              multiarrayNew.room_id = roomtype[j].value;
              roomdata.push(multiarrayNew);
            } else {
              let multiarray = {};
              multiarray.operator = dynamicroomrate[i].operator;
              multiarray.value = dynamicroomrate[i].value;
              multiarray.increment_type = dynamicroomrate[i].increment_type;
              if (dynamicroomrate[i].least_inventory && dynamicroomrate[i].least_inventory> 0) {
                multiarray.least_inventory = dynamicroomrate[i].least_inventory;
                multiarray.least_inventory_value =
                  dynamicroomrate[i].least_inventory_value;
                multiarray.least_inventory_amount_type =
                  dynamicroomrate[i].least_inventory_amount_type;
              } else {
                multiarray.least_inventory = 0;
                multiarray.least_inventory_value = 0;
                multiarray.least_inventory_amount_type = "currency";
              }
              if (dynamicroomrate[i].most_inventory && dynamicroomrate[i].most_inventory> 0) {
                multiarray.most_inventory = dynamicroomrate[i].most_inventory;
                multiarray.most_inventory_value =
                  dynamicroomrate[i].most_inventory_value;
                multiarray.most_inventory_amount_type =
                  dynamicroomrate[i].most_inventory_amount_type;
              } else {
                multiarray.most_inventory = 0;
                multiarray.most_inventory_value = 0;
                multiarray.most_inventory_amount_type = "currency";
              }
              multiarray.room_id = dynamicroomrate[i].room.value;

              roomdata.push(multiarray);
            }
          } else {
            if (roomtype[j].rate) {
              let roomExists = roomdata.some(
                (room) => room.room_id === roomtype[j].value
              );
              if (!roomExists) {
                let multiarrayNew = {};
                multiarrayNew.operator = roomtype[j].operator;
                multiarrayNew.value = roomtype[j].rate;
                multiarrayNew.increment_type = roomtype[j].increment_type;
                multiarrayNew.room_id = roomtype[j].value;
                if (roomtype[j].least_inventory && roomtype[j].least_inventory> 0) {
                  multiarrayNew.least_inventory = roomtype[j].least_inventory;
                  multiarrayNew.least_inventory_value =
                    roomtype[j].least_inventory_value;
                  multiarrayNew.least_inventory_amount_type =
                    roomtype[j].least_inventory_amount_type;
                } else {
                  multiarrayNew.least_inventory = 0;
                  multiarrayNew.least_inventory_value = 0;
                  multiarrayNew.least_inventory_amount_type = "currency";
                }
                if (roomtype[j].most_inventory && roomtype[j].most_inventory> 0) {
                  multiarrayNew.most_inventory = roomtype[j].most_inventory;
                  multiarrayNew.most_inventory_value =
                    roomtype[j].most_inventory_value;
                  multiarrayNew.most_inventory_amount_type =
                    roomtype[j].most_inventory_amount_type;
                } else {
                  multiarrayNew.most_inventory = 0;
                  multiarrayNew.most_inventory_value = 0;
                  multiarrayNew.most_inventory_amount_type = "currency";
                }
                roomdata.push(multiarrayNew);
              }
            }
          }
        }
      }
    } else {
      for (let j = 0; j < roomtype.length; j++) {
        if (roomtype[j].rate) {
          let multiarrayNew = {};
          multiarrayNew.operator = roomtype[j].operator;
          multiarrayNew.value = roomtype[j].rate;
          multiarrayNew.increment_type = roomtype[j].increment_type;
          if (roomtype[j].least_inventory && roomtype[j].least_inventory > 0) {
            multiarrayNew.least_inventory = roomtype[j].least_inventory;
            multiarrayNew.least_inventory_value =
              roomtype[j].least_inventory_value;
            multiarrayNew.least_inventory_amount_type =
              roomtype[j].least_inventory_amount_type;
          } else {
            multiarrayNew.least_inventory = 0;
            multiarrayNew.least_inventory_value = 0;
            multiarrayNew.least_inventory_amount_type = "currency";
          }
          if (roomtype[j].most_inventory && roomtype[j].most_inventory> 0) {
            multiarrayNew.most_inventory = roomtype[j].most_inventory;
            multiarrayNew.most_inventory_value =
              roomtype[j].most_inventory_value;
            multiarrayNew.most_inventory_amount_type =
              roomtype[j].most_inventory_amount_type;
          } else {
            multiarrayNew.most_inventory = 0;
            multiarrayNew.most_inventory_value = 0;
            multiarrayNew.most_inventory_amount_type = "currency";
          }
          multiarrayNew.room_id = roomtype[j].value;
          roomdata.push(multiarrayNew);
        }
      }
    }

    let roomdataNew = [];
    const filteredRoomtype = roomtype.filter(
      (data) =>
        !dynamicroomrate.some(
          (dynamicData) => dynamicData.room.value === data.value
        )
    );

    filteredRoomtype.forEach((room) => {
      let multiarrayNew = {};
      multiarrayNew.operator = room.operator;
      multiarrayNew.value = room.rate;
      multiarrayNew.increment_type = room.increment_type;
      if (room.least_inventory && room.least_inventory> 0) {
        multiarrayNew.least_inventory = room.least_inventory;
        multiarrayNew.least_inventory_value = room.least_inventory_value;
        multiarrayNew.least_inventory_amount_type =
          room.least_inventory_amount_type;
      } else {
        multiarrayNew.least_inventory = 0;
        multiarrayNew.least_inventory_value = 0;
        multiarrayNew.least_inventory_amount_type = "currency";
      }
      if (room.most_inventory && room.most_inventory> 0) {
        multiarrayNew.most_inventory = room.most_inventory;
        multiarrayNew.most_inventory_value = room.most_inventory_value;
        multiarrayNew.most_inventory_amount_type =
          room.most_inventory_amount_type;
      } else {
        multiarrayNew.most_inventory = 0;
        multiarrayNew.most_inventory_value = 0;
        multiarrayNew.most_inventory_amount_type = "currency";
      }
      multiarrayNew.room_id = room.value;
      roomdataNew.push(multiarrayNew);
    });

    // for (let i = 0; i < roomtype.length; i++) {
    // let multiarrayNew = {};
    //     multiarrayNew.operator=roomtype[i].operator;
    //     multiarrayNew.value=roomtype[i].rate;
    //     multiarrayNew.increment_type=roomtype[i].increment_type;
    //     multiarrayNew.least_inventory=roomtype[i].least_inventory;
    //     multiarrayNew.least_inventory_value=roomtype[i].least_inventory_value;
    //     multiarrayNew.least_inventory_amount_type=roomtype[i].least_inventory_amount_type;
    //     multiarrayNew.most_inventory=roomtype[i].most_inventory;
    //     multiarrayNew.most_inventory_value=roomtype[i].most_inventory_value;
    //     multiarrayNew.most_inventory_amount_type=roomtype[i].most_inventory_amount_type;
    //     multiarrayNew.room_id=roomtype[i].value;
    //     roomdataNew.push(multiarrayNew);
    //   }

    // if(dynamicroomrate.room.value===roomtype.value){
    //     newRoomData=roomdata;
    // }
    // else{
    //   newRoomData=roomdataNew;
    // }

    let l_inv;
    if(dynamicrate.least_inventory_value && dynamicrate.least_inventory_count > 0){
      l_inv=dynamicrate.least_inventory_value;
    }
    else{
      l_inv=0;
    }

    let m_inv;
    if(dynamicrate.most_inventory_value && dynamicrate.most_inventory_count > 0){
      m_inv=dynamicrate.most_inventory_value;
    }
    else{
      m_inv=0;
    }

    if (isFormValid === true) {
      const res = await fetch(`${Config.apiurl}dynamic-rate/update-test`, {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
          basic_room: basicroom.value,
          type: dynamicrate.type
            ? selectedOption.value
            : selectedOptionNew.value,
            basic_rate_id:ratebar[0].value,
            basic_rate:ratebar[0].basic_rate,
          rates: newVal,
          least_inventory: dynamicrate.least_inventory_count
            ? dynamicrate.least_inventory_count
            : 0,
          least_inventory_value: l_inv,
          least_inventory_amount_type: dynamicrate.least_inventory_amount_type
            ? dynamicrate.least_inventory_amount_type
            : "",
          most_inventory: dynamicrate.most_inventory_count,
          most_inventory_value: m_inv,
          most_inventory_amount_type: dynamicrate.most_inventory_amount_type,
          date_from: moment(dynamicfromdate).format("DD-MM-YYYY"),
          date_to: moment(dynamictodate).format("DD-MM-YYYY"),
          exception_week: exp,
          sunday: sun,
          monday: mon,
          tuesday: tue,
          wednesday: wed,
          thursday: thu,
          friday: fri,
          saturday: sat,
          room_data: roomdata,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200") {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else if (data.reauth === true) {
          handleUnauthorized();
          BulkupdateDynamic();
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          getRate();
          getAll();
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        BulkupdateDynamic();
      } else {
        console.log("error");
      }
      // const data1 = await res.json();
      // const data = data1.data;
      // if (data1.status_code !== "200" || !data1) {
      //   setToastmodal(true);
      //   setToastmessage(data1.message);
      //   setToasttype("error");
      //   setToastlarge(true);
      // } else {
      //   setToastmodal(true);
      //   setToastmessage(data1.message);
      //   setToasttype("success");
      //   setToastlarge(true);
      //   getRate();
      // }
    }
  };

  const onFileChange = (e) => {
    setImportFile(e.target.files[0]);
  };
  
  const DynamicRateImport = async () => {

    let isFormValid=true;
    const errors={};

    if(!importFile){
      isFormValid = false;
      errors.importFile = "This field is required!";
    }
 setFormerrorimport(errors);

 if(isFormValid===true){
  const formData = new FormData();

  formData.append("hotel_id", id);
  formData.append("file", importFile);

  axios
    .post(`${Config.apiurl}hotel/dynamic-rate/import-xl`, formData, {})
    .then((res) => {
      if (res.status_code !== "200" || !res) {
        setToastmodal(true);
        setToastmessage(res.message);
        setToasttype("error");
        setToastlarge(true);
      } else {
        setToastmodal(true);
        setToastmessage(res.message);
        setToasttype("success");
        setToastlarge(true);
        setImport_pop(false);
        setImportFile("");
        getRate();
      }
    });
  }
  };

  const Filter = async () => {
    let roomval1 = [];
    for (let i = 0; i < selectedRoomsFilter.length; i++) {
      roomval1.push(selectedRoomsFilter[i].value);
    }

    let rateval1 = [];
    for (let i = 0; i < selectedRateFilter.length; i++) {
      rateval1.push(selectedRateFilter[i].value);
    }
    let boardval1 = [];
    for (let i = 0; i < selectedBoardFilter.length; i++) {
      boardval1.push(selectedBoardFilter[i].value);
    }
    let occupancyVal1 = [];
    for (let i = 0; i < selectedOccupancyFilter.length; i++) {
      occupancyVal1.push(selectedOccupancyFilter[i].value);
    }

    const res = await fetch(`${Config.apiurl}rates/search`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        rooms: roomval1,
        rates: rateval1,
        boards: boardval1,
        occupancy: occupancyVal1,
        from: Startdatechange,
        to: Enddatechange,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        Filter();
      } else {
        setRate(data.data.rooms);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      Filter();
    } else {
      console.log("error");
    }
    // const data = await res.json();
    // if (res.status === 200) {
    //   console.log("error");
    // } else {
    //   setRate(data.data.rooms);
    // }
  };

  let Startdatechange = moment(dateString1).format("DD-MM-YYYY");
  let Enddatechange = moment(dateString2).format("DD-MM-YYYY");

  const getRate = async (e) => {
    const res = await fetch(`${Config.apiurl}rates/list`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        start_date: Startdatechange,
        end_date: Enddatechange,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data1 = await res.json();
      const data = data1.data;
      if (data1.status_code !== "200" || !data) {
        console.log("error");
      } else if (data.length === 0) {
        setRate([]);
        setLoading(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getRate();
      } else {
        setRate(data.rooms);
        setCurrency(data.currency);
        setLoading(true);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getRate();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;

    // if (data1.status_code !== "200" || !data) {
    //   console.log("error");
    // } else {
    //   if (data.length === 0) {
    //     setRate([]);
    //     setLoading(true);
    //   } else {
    //     setRate(data.rooms);
    //     setCurrency(data.currency);
    //     setLoading(true);
    //   }
    // }
  };
  const handleMealBoardChange = (index, fieldName, value) => {
    const updatedMealBoard = [...selectBoardadd];
    updatedMealBoard[index][fieldName] = value;
    setSelectBoardadd(updatedMealBoard);
  };
  const boardChangePop = (index, fieldName, value) => {
    const updatedMealBoard = [...boardChange];
    updatedMealBoard[index][fieldName] = value;
    setBoardChange(updatedMealBoard);
  };
  const handleMealBoardChangePop = (index, name, value) => {
    const updatedMealBoard = [...selectBoardChange];
    updatedMealBoard[index][name] = value;
    setSelectBoardChange(updatedMealBoard);
  };
  // const handleBoardChangepop = (index, fieldName, value) => {
  //   const updatedMealBoard = [...board];
  //   updatedMealBoard[index][fieldName] = value;
  //   setBoard(updatedMealBoard);
  // };

  const handleSelectAgeChangePop = (fieldName, value) => {
    setSelectagepop({ ...selectagepop, [fieldName]: value });
  };

  const handleSelectAgeChange = (fieldName, value) => {
    setSelectage({ ...selectage, [fieldName]: value });
  };

  // const handleDynamicbasicrate = (i, name, value) => {
  //   const updatedDynamic = [...dynamicrateselect];
  //   updatedDynamic[i][name] = value;
  //   setDynamicrateselect(updatedDynamic);
  // };
  const handleDynamicbasicrate = (index, field, value) => {
    let newDynamicRates = [...dynamicrateselect];
    newDynamicRates[index][field] = value;
  
    if (field === "change_value" || field === "change_type") {
      let ratebarBasicRate = ratebar[0]?.basic_rate || 0;
      let changeValue = parseFloat(newDynamicRates[index].change_value) || 0;
      let changeType = newDynamicRates[index].change_type;
  
      if (changeType === "percentage") {
        newDynamicRates[index].basic_rate = ratebarBasicRate - (ratebarBasicRate * (changeValue / 100));
      } else {
        newDynamicRates[index].basic_rate = ratebarBasicRate - changeValue;
      }
    }
  
    setDynamicrateselect(newDynamicRates);
  };
  
  const handleCalendarIconClick = (calendarId) => {
    const calendar = document.querySelector(`#flatpickr-${calendarId}`);
    if (calendar) {
      const isCalendarOpen = calendar._flatpickr.isOpen;
      const icon = document.querySelector(`#calendar-icon-${calendarId}`);
      if (icon) {
        icon.style.display = isCalendarOpen ? "none" : "inline-block";
      }
      calendar._flatpickr[isCalendarOpen ? "close" : "open"]();
    }
  };

  const handleClickPrev = async () => {
    let first;
    const currentDate = moment().format("DD-MM-YYYY");

    first = moment(dateString1)
      .subtract(1, "M")
      .startOf("month")
      .format("MMM DD, YYYY");
    let firstfull = moment(dateString1)
      .subtract(1, "M")
      .startOf("month")
      .format("DD-MM-YYYY");
    let end = moment(first).endOf("month").format("MMM DD, YYYY");
    let endfull = moment(first).endOf("month").format("DD-MM-YYYY");

    if (
      moment(firstfull, "DD-MM-YYYY").isBefore(
        moment(currentDate, "DD-MM-YYYY")
      )
    ) {
      firstfull = currentDate;
      first = moment(currentDate, "DD-MM-YYYY").format("MMM DD, YYYY");
    }
    setDateString1(first);
    setDateString2(end);

    let roomval1 = [];
    if (selectedRoomsFilter.length > 0) {
      for (let i = 0; i < selectedRoomsFilter.length; i++) {
        let newval = {};
        newval = selectedRoomsFilter[i].value;
        roomval1.push(newval);
      }
    } else {
      for (let i = 0; i < roomtype.length; i++) {
        let newval = {};
        newval = roomtype[i].value;
        roomval1.push(newval);
      }
    }

    let rateval1 = [];
    if (selectedRateFilter.length > 0) {
      for (let i = 0; i < selectedRateFilter.length; i++) {
        let newval = {};
        newval = selectedRateFilter[i].value;
        rateval1.push(newval);
      }
    } else {
      for (let i = 0; i < ratelist.length; i++) {
        let newval = {};
        newval = ratelist[i].value;
        rateval1.push(newval);
      }
    }

    let boardval1 = [];
    if (selectedBoardFilter.length > 0) {
      for (let i = 0; i < selectedBoardFilter.length; i++) {
        let newval = {};
        newval = selectedBoardFilter[i].value;
        boardval1.push(newval);
      }
    } else {
      for (let i = 0; i < board.length; i++) {
        let newval = {};
        newval = board[i].value;
        boardval1.push(newval);
      }
    }

    let occupancyVal1 = [];
    if (selectedOccupancyFilter.length > 0) {
      for (let i = 0; i < selectedOccupancyFilter.length; i++) {
        let newval = {};
        newval = selectedOccupancyFilter[i].value;
        occupancyVal1.push(newval);
      }
    } else {
      for (let i = 0; i < occupancy.length; i++) {
        let newval = {};
        newval = occupancy[i].value;
        occupancyVal1.push(newval);
      }
    }

    const res = await fetch(`${Config.apiurl}rates/search`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        rooms: roomval1,
        rates: rateval1,
        boards: boardval1,
        occupancy: occupancyVal1,
        from: firstfull,
        to: endfull,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        handleClickPrev();
      } else {
        setRate(data.data.rooms);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      handleClickPrev();
    } else {
      console.log("error");
    }
    // const data = await res.json();
    // if (res.status !== 200) {
    //   console.log("error");
    // } else {
    //   setRate(data.data.rooms);
    // }
  };

  console.log(selectedRoomsFilter);
  console.log(roomtype);

  const handleClickNext = async () => {
    let firstnext = moment(dateString1)
      .add(1, "M")
      .startOf("month")
      .format("MMM DD, YYYY");
    let firstnextfull = moment(dateString1)
      .add(1, "M")
      .startOf("month")
      .format("DD-MM-YYYY");
    let endnext = moment(firstnext).endOf("month").format("MMM DD, YYYY");
    let endnextfull = moment(firstnext).endOf("month").format("DD-MM-YYYY");
    setDateString1(firstnext);
    setDateString2(endnext);

    let roomval1 = [];
    if (selectedRoomsFilter.length > 0) {
      for (let i = 0; i < selectedRoomsFilter.length; i++) {
        let newval = {};
        newval = selectedRoomsFilter[i].value;
        roomval1.push(newval);
      }
    } else {
      for (let i = 0; i < roomtype.length; i++) {
        let newval = {};
        newval = roomtype[i].value;
        roomval1.push(newval);
      }
    }

    let rateval1 = [];
    if (selectedRateFilter.length > 0) {
      for (let i = 0; i < selectedRateFilter.length; i++) {
        let newval = {};
        newval = selectedRateFilter[i].value;
        rateval1.push(newval);
      }
    } else {
      for (let i = 0; i < ratelist.length; i++) {
        let newval = {};
        newval = ratelist[i].value;
        rateval1.push(newval);
      }
    }

    let boardval1 = [];
    if (selectedBoardFilter.length > 0) {
      for (let i = 0; i < selectedBoardFilter.length; i++) {
        let newval = {};
        newval = selectedBoardFilter[i].value;
        boardval1.push(newval);
      }
    } else {
      for (let i = 0; i < board.length; i++) {
        let newval = {};
        newval = board[i].value;
        boardval1.push(newval);
      }
    }

    let occupancyVal1 = [];
    if (selectedOccupancyFilter.length > 0) {
      for (let i = 0; i < selectedOccupancyFilter.length; i++) {
        let newval = {};
        newval = selectedOccupancyFilter[i].value;
        occupancyVal1.push(newval);
      }
    } else {
      for (let i = 0; i < occupancy.length; i++) {
        let newval = {};
        newval = occupancy[i].value;
        occupancyVal1.push(newval);
      }
    }

    const res = await fetch(`${Config.apiurl}rates/search`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        rooms: roomval1,
        rates: rateval1,
        boards: boardval1,
        occupancy: occupancyVal1,
        from: firstnextfull,
        to: endnextfull,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        console.log("error");
      } else if (data.reauth === true) {
        handleUnauthorized();
        handleClickNext();
      } else {
        setRate(data.data.rooms);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      handleClickNext();
    } else {
      console.log("error");
    }
    // const data = await res.json();
    // if (res.status !== 200) {
    //   console.log("error");
    // } else {
    //   setRate(data.data.rooms);
    // }
  };

  const handleDayClick = (dayOfWeek) => {
    if (selectedWeekdays.includes(dayOfWeek)) {
      if (clickedDays.includes(dayOfWeek)) {
        setClickedDays((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayOfWeek)
        );
      } else {
        setClickedDays((prevClickedDays) => [...prevClickedDays, dayOfWeek]);
      }
    }
  };

  const handleDayClick1 = (dayOfWeek) => {
    if (selectedWeekdays1.includes(dayOfWeek)) {
      if (clickedDays1.includes(dayOfWeek)) {
        setClickedDays1((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayOfWeek)
        );
      } else {
        setClickedDays1((prevClickedDays) => [...prevClickedDays, dayOfWeek]);
      }
    }
  };

  const handleDayClicknormal = (dayOfWeek) => {
    if (selectedWeekdaysnormal.includes(dayOfWeek)) {
      if (clickedDaysnormal.includes(dayOfWeek)) {
        setClickedDaysnormal((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayOfWeek)
        );
      } else {
        setClickedDaysnormal((prevClickedDays) => [
          ...prevClickedDays,
          dayOfWeek,
        ]);
      }
    }
  };

  const getWeekdays = (startDate, endDate) => {
    const weekdays = [];
    const currentDay = new Date(startDate);
    const lastDay = new Date(endDate);

    while (currentDay <= lastDay) {
      const dayOfWeek = currentDay.getDay();
      if (dayOfWeek >= 0 && dayOfWeek <= 6) {
        weekdays.push(dayOfWeek);
      }
      currentDay.setDate(currentDay.getDate() + 1);
    }

    return weekdays;
  };

  const getWeekdays1 = (startDate, endDate) => {
    const weekdays1 = [];
    const currentDay = new Date(startDate);
    const lastDay = new Date(endDate);

    while (currentDay <= lastDay) {
      const dayOfWeek = currentDay.getDay();
      if (dayOfWeek >= 0 && dayOfWeek <= 6) {
        weekdays1.push(dayOfWeek);
      }
      currentDay.setDate(currentDay.getDate() + 1);
    }

    return weekdays1;
  };

  const getWeekdaysnormal = (startDate, endDate) => {
    const weekdaysnormal = [];
    const currentDay = new Date(startDate);
    const lastDay = new Date(endDate);

    while (currentDay <= lastDay) {
      const dayOfWeek = currentDay.getDay();
      if (dayOfWeek >= 0 && dayOfWeek <= 6) {
        weekdaysnormal.push(dayOfWeek);
      }
      currentDay.setDate(currentDay.getDate() + 1);
    }

    return weekdaysnormal;
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const Singlesave = async () => {
    setSaveddata(rate);
    let newmin = [];
    for (let i = 0; i < rate.length; i++) {
      if (rate[i].flag && rate[i].flag === true) {
        for (let j = 0; j < rate[i].ratelists.length; j++) {
          if (rate[i].ratelists[j].flag && rate[i].ratelists[j].flag === true) {
            for (let k = 0; k < rate[i].ratelists[j].hotel_board.length; k++) {
              if (
                rate[i].ratelists[j].hotel_board[k].flag &&
                rate[i].ratelists[j].hotel_board[k].flag === true
              ) {
                for (
                  let l = 0;
                  l < rate[i].ratelists[j].hotel_board[k].occupancies.length;
                  l++
                ) {
                  if (
                    rate[i].ratelists[j].hotel_board[k].occupancies[l].flag &&
                    rate[i].ratelists[j].hotel_board[k].occupancies[l].flag ===
                      true
                  ) {
                    let multiarray = {};
                    multiarray.room = rate[i].room_id;
                    multiarray.rate = rate[i].ratelists[j].rate_id;
                    multiarray.board =
                      rate[i].ratelists[j].hotel_board[k].board;
                    multiarray.occupancy =
                      rate[i].ratelists[j].hotel_board[k].occupancies[
                        l
                      ].occupancy_id;
                    let newminstay = [];
                    for (
                      let m = 0;
                      m <
                      rate[i].ratelists[j].hotel_board[k].occupancies[l].rates
                        .length;
                      m++
                    ) {
                      let minstayarray = {};
                      if (
                        rate[i].ratelists[j].hotel_board[k].occupancies[l]
                          .rates[m].flag &&
                        rate[i].ratelists[j].hotel_board[k].occupancies[l]
                          .rates[m].flag === true
                      ) {
                        minstayarray.rate =
                          rate[i].ratelists[j].hotel_board[k].occupancies[
                            l
                          ].rates[m].rate;
                        let formattedDate = moment(
                          `${rate[i].ratelists[j].hotel_board[k].occupancies[l].rates[m].year}-${rate[i].ratelists[j].hotel_board[k].occupancies[l].rates[m].month}-${rate[i].ratelists[j].hotel_board[k].occupancies[l].rates[m].date}`
                        ).format("DD-MM-YYYY");
                        minstayarray.date = formattedDate;
                        newminstay.push(minstayarray);
                      }
                    }
                    multiarray.rate_data = newminstay;
                    newmin.push(multiarray);
                  }
                }
              }
            }
          }
        }
      }
    }
    const res = await fetch(`${Config.apiurl}hotel/room/rate/update/${id}`, {
      method: "POST",
      body: JSON.stringify({
        conditions: newmin,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200") {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        Singlesave();
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(true);
        getRate();
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      Singlesave();
    } else {
      console.log("error");
    }
    // const data1 = await res.json();
    // const data = data1.data;
    // if (data1.status_code !== "200" || !data1) {
    //   setToastmodal(true);
    //   setToastmessage(data1.message);
    //   setToasttype("error");
    //   setToastlarge(true);
    // } else {
    //   setToastmodal(true);
    //   setToastmessage(data1.message);
    //   setToasttype("success");
    //   setToastlarge(true);
    //   getRate();
    // }
  };

  const [columnsDisplayed, setColumnsDisplayed] = useState(0);

  const [newextrabed_room, setNewExtrabedRoom] = useState([]);

  useEffect(() => {
    const filteredRooms = extrabed_room.filter(
      (room) => room.extra_bed_status === true
    );
    setNewExtrabedRoom(filteredRooms);
  }, [extrabed_room]);

  useEffect(() => {
    getAll();
    getRate();
    getAllBedtype();
    // getHotelBoard();
  }, [id]);

  useEffect(() => {
    for (let i = 0; i < rate?.length; i++) {
      let ar = {};
      ar.tab = "default";
      ar.click = i === 0;
      tab.push(ar);
    }

    setselectedRoomsFilter(newRoom.map((option) => option));
    setselectedRateFilter(ratelist.map((option) => option));
    setselectedBoardFilter(board.map((option) => option));
    setselectedOccupancyFilter(occupancy.map((option) => option));

    setSelectedrooms(newRoom.map((option) => option));
    setSelectedrate(ratelist.map((option) => option));
    setSelectedboard(board.map((option) => option));
    setSelectedoccupancy(occupancy.map((option) => option));

    setSelectedRoomnormal(newRoom.map((option) => option));
    setSelectedRatenormal(ratelist.map((option) => option));
    setSelectedBoardnormal(board.map((option) => option));
    setSelectedOccupancynormal(occupancy.map((option) => option));

    if (dynamicrateselect.length === 0) {
      // setDynamicrateselect(ratelist.map((option) => option));
      setDynamicrateselect(ratelistnew.map((option) => option));
    }

    if (dynamicrate.length === 0) {
      const defaultValues = {
        least_inventory_amount_type: "currency",
        most_inventory_amount_type: "currency",
      };
      setDynamicrate(defaultValues);
    }
    // if(dynamicrate.length===0){
    // least_inventory_amount_type="currency";
    // most_inventory_amount_type="currency";
    // setDynamicrate({['least_inventory_amount_type']:"currency"
    // ['most_inventory_amount_type']:"currency"})
    // }
    const updatedInputFields = inputFields.map((field, index) => {
      return {
        ...field,
        rooms: newextrabed_room.map((option) => option),
        rate: ratelist.map((option) => option),
        board: board.map((option) => option),
        occupancy: occupancy.map((option) => option),
        condition: [],
      };
    });

    setInputFields(updatedInputFields);

    const initialSelectedOption = SingleOptions.find(
      (option) => option.value === dynamicrate.type
    );
    setSelectedOption(initialSelectedOption);
  }, [
    newextrabed_room.length,
    dynamicrate.length,
    ratelist.length,
    board.length,
    occupancy.length,
    rate.length,
    dynamicrate.type,
  ]);

  useEffect(() => {
    const updatedRoomtype = [...roomtype];
    for (let i = 0; i < updatedRoomtype.length; i++) {
      updatedRoomtype[i]["operator"] = "+";
      updatedRoomtype[i]["increment_type"] = "currency";
      updatedRoomtype[i]["least_inventory_amount_type"] = "currency";
      updatedRoomtype[i]["most_inventory_amount_type"] = "currency";
    }
    setRoomtype(updatedRoomtype);
  }, [roomtype.length]);

  useEffect(() => {
    const getWeekdaysBetweenSelectedDates = (datefrom, dateto) => {
      const weekdays = getWeekdays(datefrom, dateto);
      setSelectedWeekdays(weekdays);
      setClickedDays([]);
    };

    if (datefrom && dateto) {
      getWeekdaysBetweenSelectedDates(datefrom, dateto);
    } else {
      setSelectedWeekdays([]);
    }

    const getWeekdaysBetweenSelectedDates1 = (
      dynamicfromdate,
      dynamictodate
    ) => {
      const weekdays1 = getWeekdays1(dynamicfromdate, dynamictodate);
      setSelectedWeekdays1(weekdays1);
      setClickedDays1([]);
    };

    if (dynamicfromdate && dynamictodate) {
      getWeekdaysBetweenSelectedDates1(dynamicfromdate, dynamictodate);
    } else {
      setSelectedWeekdays1([]);
    }

    const getWeekdaysBetweenSelectedDatesnormal = (
      datefromnormal,
      datetonormal
    ) => {
      const weekdaysnormal = getWeekdaysnormal(datefromnormal, datetonormal);
      setSelectedWeekdaysnormal(weekdaysnormal);
      setClickedDaysnormal([]);
    };

    if (datefromnormal && datetonormal) {
      getWeekdaysBetweenSelectedDatesnormal(datefromnormal, datetonormal);
    } else {
      setSelectedWeekdaysnormal([]);
    }

    const columnNumber =
      window.innerWidth / document.querySelector(".th-element").clientWidth;
    const datas = Math.ceil(columnNumber);
    setColumnsDisplayed(datas);
  }, [
    dateto,
    datefrom,
    dynamictodate,
    dynamicfromdate,
    datetonormal,
    datefromnormal,
  ]);

  const handleBasicRateChange = (e) => {
    const newRatebar = [...ratebar]; 
    newRatebar[0].basic_rate = e.target.value; 
    setRatebar(newRatebar); 
  };

  useEffect(() => {
    if (roomtype) {
      for(let i=0; i<roomtype.length; i++){
        if(roomtype[i].basic_room_status===true){
          setBasicroom(roomtype[i]);
        }
    }
  }
  }, [roomtype.length]);

  // const handleDynamicCurrencyChange = (index, value) => {
  //   const updatedRates = [...dynamicrateselect];
  //   updatedRates[index].change_type = value;
  //   setDynamicrateselect(updatedRates);
  // };

  const handleDynamicCurrencyChange = (index, newChangeType) => {
    let newDynamicRates = [...dynamicrateselect];
    newDynamicRates[index].change_type = newChangeType;
  
    let ratebarBasicRate = ratebar[0]?.basic_rate || 0;
    let changeValue = parseFloat(newDynamicRates[index].change_value) || 0;
  
    if (newChangeType === "percentage") {
      newDynamicRates[index].basic_rate = ratebarBasicRate - (ratebarBasicRate * (changeValue / 100));
    } else {
      newDynamicRates[index].basic_rate = ratebarBasicRate - changeValue;
    }
    setDynamicrateselect(newDynamicRates);
  };


  // const handleAllRateChange = (selectedOptions) => {
  //   setDynamicrateselect(prevSelections => {
  //     const prevSelectionsMap = new Map(prevSelections.map(sel => [sel.value, sel]));
  
  //     const updatedSelections = selectedOptions.map(option => {
  //       const existingSelection = prevSelectionsMap.get(option.value) || {};
  //       return {
  //         ...option,
  //         change_type: existingSelection.change_type || option.change_type || "currency",
  //         operator: existingSelection.operator || option.operator || "-"
  //       };
  //     });
  
  //     return updatedSelections;
  //   });
  // };
  
  const handleAllRateChange = (selectedOptions) => {
    setDynamicrateselect(prevSelections => {
      const prevSelectionsMap = new Map(prevSelections.map(sel => [sel.value, sel]));
  
      const updatedSelections = selectedOptions.map(option => {
        const existingSelection = prevSelectionsMap.get(option.value) || {};
                                      
        return {
          ...existingSelection, 
          ...option,            
          change_type: existingSelection.change_type || option.change_type || "currency",
          operator: existingSelection.operator || option.operator || "-"
        };
      });
  
      return updatedSelections;
    });
  };
  

  // useEffect(() => {
  //   const filteredRateList = ratelist?.filter(rate => rate.basic_rateplan_status === true);
    
  //   const missingRates = filteredRateList.filter(rate => 
  //     !dynamicrateselectnew?.some(selectRate => selectRate.value === rate.value)
  //   );
  
  //   if (dynamicrateselectnew.length === 0 || missingRates.length > 0) {
  //     setRatebar(filteredRateList);
  //   } else {
  //     const uniqueValues = new Set(dynamicrateselectnew?.map(selectRate => selectRate.value));
  
  //     const filteredDynamicRates = dynamicrateselectnew?.filter(selectRate => 
  //       filteredRateList.some(rate => rate.value === selectRate.value)
  //     );
  
  //     setRatebar(filteredDynamicRates);
  //   }
  // }, [dynamicrateselectnew.length, ratelist.length]);
  
  useEffect(() => {
    if (ratelist?.length && dynamicrate) {
      const filteredRateList = ratelist.filter(rate => rate.basic_rateplan_status === true).map((rate, index) => {
        const basic_rateplan_rate = dynamicrate?.basic_rateplan_rate;
        return {
          ...rate,
          basic_rate: basic_rateplan_rate
        };
      });
      setRatebar(filteredRateList);
    }
  }, [ratelist.length, dynamicrate]);
  

  useEffect(() => {
    if (dynamicrateselectnew.length > 0) {
      const updatedDynamicRateSelect = dynamicrateselectnew?.filter((selectRate) =>
        ratelistnew.some((rate) => selectRate.value === rate.value)
      );
      setDynamicrateselect(updatedDynamicRateSelect);
    }
  }, [dynamicrateselectnew?.length, ratelistnew?.length]);


  useEffect(() => {
    const filtered = ratelist
      .filter(rate => rate.basic_rateplan_status===false)
      .map(rate => ({ value: rate.value, label: rate.label }));

    setRatelistnew(filtered);
  }, [ratelist.length,ratelistnew.length]);

  useEffect(() => {
    const validateRates = () => {
      const allRatesPresent = ratelistnew.every(rate => 
        dynamicrateselect.some(selected => selected.value === rate.value)
      );

      const allHaveBasicRate = dynamicrateselect.every(rate => rate.change_value !== "");

      if (!allRatesPresent || !allHaveBasicRate) {
        setImportstatus(false);
      } else {
        setImportstatus(true);
      }
    };
    validateRates();
    
  }, [dynamicrateselect, ratelistnew]);

console.log(dynamicrateselect);
console.log(importstatus);

  useEffect(() => {
    setId(hotel_select[0]?.value);
  }, [hotel_select]);

  useEffect(() => {
    setHotelname(hotel_select[0]?.label);
  }, [hotel_select]);

  const [tabextra, setTabextra] = useState([]);

  useEffect(() => {
    for (let i = 0; i < boardallrate?.length; i++) {
      // let arr = [];
      // for (let j = 0; j < boardallrate[i].types.length; j++) {
      //   console.log( boardallrate[i].types[j]);
      let ar = {};
      //   if(i===0 && j===0){
      //     ar.click=true;
      //   }
      //   else{
      //     ar.click=false;
      //   }
      ar.click = i === 0;
      // arr.push(ar);

      // }
      tabextra.push(ar);
    }
  }, [boardallrate.length]);

  const handleUploadClick = () => {
    setLoadingimport(true);
    DynamicRateImport();
    setTimeout(() => {
      setLoadingimport(false);
    }, 2000); 
  };



  return (
    <>
      <Commonheader />
      <div className="main-content">
        <div className="page-content">
          <div className="">
            <div className="row">
              <div className="col-12">
                {hotel_select.length > 1 ? (
                  <h4 className="" style={{ marginLeft: "10px" }}>
                    {hotelname}
                  </h4>
                ) : (
                  ""
                )}
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title" style={{ color: "#008080" }}>
                    Rates
                  </h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12">
                <div className="card">
                  <div className="db-avail-modify-sec">
                    <div className="db-avail-modify-collapse">
                      <span
                        className="db-modify_toggle_btn"
                        onClick={clickEventHandler}
                      >
                        <span
                          className="bx bxs-chevron-down"
                          style={{ fontSize: "20px" }}
                        ></span>
                      </span>
                      <button
                        className="modify-button"
                        onClick={clickEventHandler}
                      >
                        Modify Rates
                      </button>
                    </div>
                    <div
                      className={
                        active === false
                          ? "db-price-modify-sec-main d-none"
                          : "db-price-modify-sec-main b_b_1"
                      }
                    >
                      <ul
                        className="nav nav-pills price-modify-tabs nav-success mb-3"
                        role="tablist"
                      >
                        <li className="nav-item waves-effect waves-light info-tag-li">
                          <Link
                            className={
                              tab1 === "boardrate"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-bs-toggle="tab"
                            to="#"
                            role="tab"
                            onClick={(e) => setTab1("boardrate")}
                          >
                            Meal Board Rate
                            <span className="info-tag">
                              i
                              <span className="tooltiptext">
                                Applicable for dynamic rate setup.
                              </span>
                            </span>
                          </Link>
                        </li>
                        <li className="nav-item waves-effect waves-light info-tag-li">
                          <Link
                            className={
                              tab1 === "extra" ? "nav-link active" : "nav-link"
                            }
                            data-bs-toggle="tab"
                            to="#"
                            role="tab"
                            onClick={(e) => setTab1("extra")}
                          >
                            Extra Bed Rate{" "}
                            <span className="info-tag">
                              i
                              <span className="tooltiptext">
                                Applicable for dynamic rate setup.
                              </span>
                            </span>
                          </Link>
                        </li>
                        <li className="nav-item waves-effect waves-light info-tag-li">
                          <Link
                            className={
                              tab1 === "dynamic"
                                ? "nav-link active"
                                : "nav-link"
                            }
                            data-bs-toggle="tab"
                            to="#"
                            role="tab"
                            onClick={(e) => setTab1("dynamic")}
                          >
                            Dynamic Rate Setup{" "}
                            <span className="info-tag">
                              i
                              <span className="tooltiptext">
                                Modifying or creating existing rates.
                              </span>
                            </span>
                          </Link>
                        </li>
                        {/* <li className="nav-item waves-effect waves-light info-tag-li">
                          <Link
                            className={
                              tab1 === "normal" ? "nav-link active" : "nav-link"
                            }
                            data-bs-toggle="tab"
                            to="#"
                            role="tab"
                            onClick={(e) => setTab1("normal")}
                          >
                            Normal Rate Setup{" "}
                            <span className="info-tag">
                              i
                              <span className="tooltiptext">
                                Modifying or creating existing rates.
                              </span>
                            </span>
                          </Link>
                        </li> */}
                        <div
                          className={
                            tab1 === "dynamic"
                              ? "upload-btn-wrapper"
                              : "upload-btn-wrapper d-none"
                          }
                          style={{ position: "absolute", right: "10%" }}
                        >
                          <button className="btn-file" onClick={()=>ImportPopup()}>Import</button>
                          {/* <input type="file" name="myfile" /> */}
                        </div>
                      </ul>

                      <div className="tab-content text-muted">
                        {tab1 === "normal" ? (
                          <div
                            className="tab-pane active"
                            id="rate-tab"
                            role="tabpanel"
                          >
                            <div className="row price-modify-filter">
                              <form>
                                <div className="price-top-section">
                                  <div className="row g-3">
                                    <div className="price-modify-filter-head">
                                      <h6>Filter</h6>
                                    </div>
                                    <div className="col-xxl-12 col-md-12 col-sm-12">
                                      <div className="row g-3">
                                        <div className="col-md-3">
                                          <div className="input-group">
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                            >
                                              Rooms:
                                            </label>
                                            <div className="col-md-6">
                                              <MultiSelect
                                                options={roomtype}
                                                value={selectedRoomnormal}
                                                onChange={setSelectedRoomnormal}
                                                labelledBy="Select"
                                                selectionType="counter"
                                                allowCustomValue={true}
                                              />
                                            </div>
                                            <label style={{ color: "red" }}>
                                              {formerror4.selectedRoomnormal}
                                            </label>
                                          </div>
                                        </div>

                                        <div
                                          className="col-md-3"
                                          style={{ right: "6%" }}
                                        >
                                          <div className="input-group">
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                            >
                                              Rate List:
                                            </label>
                                            <div className="col-md-6">
                                              <MultiSelect
                                                options={ratelist}
                                                value={selectedRatenormal}
                                                onChange={setSelectedRatenormal}
                                                labelledBy="Select"
                                                selectionType="counter"
                                                allowCustomValue={true}
                                              />
                                            </div>
                                            <label style={{ color: "red" }}>
                                              {formerror4.selectedRatenormal}
                                            </label>
                                          </div>
                                        </div>
                                        <div
                                          className="col-md-2"
                                          style={{ right: "11%" }}
                                        >
                                          <div className="input-group">
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                            >
                                              Boards:
                                            </label>
                                            <div className="col-md-6">
                                              <MultiSelect
                                                options={board}
                                                value={selectedBoardnormal}
                                                onChange={
                                                  setSelectedBoardnormal
                                                }
                                                labelledBy="Select"
                                                className="multiselect_rate"
                                                selectionType="counter"
                                                allowCustomValue={true}
                                              />
                                            </div>
                                            <label style={{ color: "red" }}>
                                              {formerror4.selectedBoardnormal}
                                            </label>
                                          </div>
                                        </div>
                                        <div
                                          className="col-md-2"
                                          style={{ right: "8%" }}
                                        >
                                          <div className="input-group">
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                              style={{ width: "105px" }}
                                            >
                                              Occupancies:
                                            </label>
                                            <div className="col-md-5">
                                              <MultiSelect
                                                options={occupancy}
                                                value={selectedOccupancynormal}
                                                onChange={
                                                  setSelectedOccupancynormal
                                                }
                                                labelledBy="Select"
                                                className="multiselect_rate"
                                                selectionType="counter"
                                                allowCustomValue={true}
                                              />
                                            </div>
                                            <label style={{ color: "red" }}>
                                              {
                                                formerror4.selectedOccupancynormal
                                              }
                                            </label>
                                          </div>
                                        </div>
                                        <div
                                          className="col-lg-2"
                                          style={{ right: "3%" }}
                                        >
                                          <div className="input-group">
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                              style={{ width: "80px" }}
                                            >
                                              Based On:
                                            </label>
                                            <div
                                              className="col-md-5"
                                              style={{ paddingRight: "5px" }}
                                            >
                                              <Select
                                                placeholder="Select..."
                                                className="form-control multiselect_rate"
                                                data-choices
                                                name="choices-single-default"
                                                id="choices-single-default"
                                                value={singleSelectnormal}
                                                onChange={setSingleSelectnormal}
                                                options={normalSingle}
                                              ></Select>
                                            </div>
                                            <label style={{ color: "red" }}>
                                              {formerror4.singleSelectnormal}
                                            </label>
                                          </div>
                                        </div>
                                        <div
                                          className="col-md-4"
                                          style={{
                                            right: "30px",
                                            marginTop: "30px",
                                          }}
                                        >
                                          <ul
                                            className="rate_modify_normal_tab"
                                            id="rate_modify_normal_tab"
                                          >
                                            <li
                                              className={
                                                tab5 === "create"
                                                  ? "active"
                                                  : ""
                                              }
                                              onClick={(e) => setTab5("create")}
                                              style={{
                                                height: "38px",
                                                width: "115px",
                                                textAlign: "center",
                                              }}
                                            >
                                              Create
                                            </li>
                                            <li
                                              className={
                                                tab5 === "modify"
                                                  ? "active"
                                                  : ""
                                              }
                                              onClick={(e) => setTab5("modify")}
                                              style={{
                                                height: "38px",
                                                width: "260px",
                                                textAlign: "center",
                                              }}
                                            >
                                              Modify Existing Rates
                                            </li>
                                          </ul>
                                        </div>
                                        {tab5 === "create" ? (
                                          <div style={{ marginLeft: "7px" }}>
                                            <input
                                              onKeyDown={(e) =>
                                                symbolsArr.includes(e.key) &&
                                                e.preventDefault()
                                              }
                                              type="number"
                                              className="form-control"
                                              placeholder="Insert rate"
                                              id=""
                                              style={{
                                                width: "100px",
                                                textAlign: "center",
                                              }}
                                              value={ratecreate}
                                              onChange={(e) =>
                                                setRatecreate(e.target.value)
                                              }
                                              onFocus={(e) =>
                                                e.target.addEventListener(
                                                  "wheel",
                                                  function (e) {
                                                    e.preventDefault();
                                                  },
                                                  { passive: false }
                                                )
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <div style={{ marginLeft: "90px" }}>
                                            <div
                                              className="price-modify-period"
                                              style={{ paddingLeft: "23px" }}
                                            >
                                              <select
                                                id="ForminputState"
                                                className="form-select"
                                                onChange={(e) =>
                                                  setNormalval(e.target.value)
                                                }
                                              >
                                                <option value="+" selected="">
                                                  +
                                                </option>
                                                <option value="-">-</option>
                                              </select>
                                            </div>
                                            <div
                                              className="price-modify-period"
                                              style={{ paddingLeft: "0px" }}
                                            >
                                              <span
                                                className="rate_apply_section"
                                                style={{ width: "190px" }}
                                              >
                                                <input
                                                  onKeyDown={(e) =>
                                                    symbolsArr.includes(
                                                      e.key
                                                    ) && e.preventDefault()
                                                  }
                                                  type="number"
                                                  className="form-control"
                                                  placeholder="Insert rate"
                                                  id=""
                                                  value={normalrate}
                                                  onChange={(e) =>
                                                    setNormalrate(
                                                      e.target.value
                                                    )
                                                  }
                                                  onFocus={(e) =>
                                                    e.target.addEventListener(
                                                      "wheel",
                                                      function (e) {
                                                        e.preventDefault();
                                                      },
                                                      { passive: false }
                                                    )
                                                  }
                                                />
                                                <span className="rate_modify_type_section">
                                                  <select
                                                    id="ForminputState"
                                                    className="form-select"
                                                    onChange={(e) =>
                                                      setNormalamount(
                                                        e.target.value
                                                      )
                                                    }
                                                  >
                                                    <option
                                                      value="currency"
                                                      selected
                                                    >
                                                      {currencyname.code}
                                                    </option>
                                                    <option value="percentage">
                                                      %
                                                    </option>
                                                  </select>
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row g-3">
                                  <div className="price-modify-filter-head">
                                    <h6>Apply to period</h6>
                                  </div>
                                  <div className="col-xxl-2 col-sm-4 form-calender-add">
                                    <form action="#">
                                      <div className="input-group">
                                        <Flatpickr
                                          className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                          placeholder="FROM"
                                          id="flatpickr-from"
                                          options={{
                                            mode: "single",
                                            dateFormat: "d M, Y",
                                            minDate: "today",
                                          }}
                                          value={datefromnormal}
                                          onChange={
                                            handleCalendarChangeFromnormal
                                          }
                                        />
                                        <label style={{ color: "red" }}>
                                          {formerror4.datefromnormal}
                                        </label>
                                        <span
                                          className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                          onClick={() =>
                                            handleCalendarIconClick("from")
                                          }
                                        ></span>
                                      </div>
                                    </form>
                                  </div>
                                  <div className="col-xxl-2 col-sm-4 form-calender-add">
                                    <form action="#">
                                      <div className="input-group">
                                        <Flatpickr
                                          className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                          placeholder="TO"
                                          id="flatpickr-to"
                                          options={{
                                            mode: "single",
                                            dateFormat: "d M, Y",
                                            minDate: "today",
                                          }}
                                          value={datetonormal}
                                          onChange={
                                            handleCalendarChangeTonormal
                                          }
                                        />
                                        <label style={{ color: "red" }}>
                                          {formerror4.datetonormal}
                                        </label>
                                        <span
                                          className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                          onClick={() =>
                                            handleCalendarIconClick("to")
                                          }
                                        ></span>
                                      </div>
                                    </form>
                                  </div>
                                  <div className="col-xxl-8 col-sm-12">
                                    <ul className="modify-week-list">
                                      <li
                                        className={`modify-week-list-item card-title ${
                                          selectedWeekdaysnormal.includes(1)
                                            ? "green-bg"
                                            : ""
                                        } ${
                                          clickedDaysnormal.includes(1)
                                            ? "red-bg"
                                            : ""
                                        }`}
                                        onClick={() => handleDayClicknormal(1)}
                                      >
                                        Mon
                                      </li>
                                      <li
                                        className={`modify-week-list-item card-title ${
                                          selectedWeekdaysnormal.includes(2)
                                            ? "green-bg"
                                            : ""
                                        } ${
                                          clickedDaysnormal.includes(2)
                                            ? "red-bg"
                                            : ""
                                        }`}
                                        onClick={() => handleDayClicknormal(2)}
                                      >
                                        Tue
                                      </li>
                                      <li
                                        className={`modify-week-list-item ${
                                          selectedWeekdaysnormal.includes(3)
                                            ? "green-bg"
                                            : ""
                                        } ${
                                          clickedDaysnormal.includes(3)
                                            ? "red-bg"
                                            : ""
                                        }`}
                                        onClick={() => handleDayClicknormal(3)}
                                      >
                                        Wed
                                      </li>
                                      <li
                                        className={`modify-week-list-item ${
                                          selectedWeekdaysnormal.includes(4)
                                            ? "green-bg"
                                            : ""
                                        } ${
                                          clickedDaysnormal.includes(4)
                                            ? "red-bg"
                                            : ""
                                        }`}
                                        onClick={() => handleDayClicknormal(4)}
                                      >
                                        Thu
                                      </li>
                                      <li
                                        className={`modify-week-list-item ${
                                          selectedWeekdaysnormal.includes(5)
                                            ? "green-bg"
                                            : ""
                                        } ${
                                          clickedDaysnormal.includes(5)
                                            ? "red-bg"
                                            : ""
                                        }`}
                                        onClick={() => handleDayClicknormal(5)}
                                      >
                                        Fri
                                      </li>
                                      <li
                                        className={`modify-week-list-item ${
                                          selectedWeekdaysnormal.includes(6)
                                            ? "green-bg"
                                            : ""
                                        } ${
                                          clickedDaysnormal.includes(6)
                                            ? "red-bg"
                                            : ""
                                        }`}
                                        onClick={() => handleDayClicknormal(6)}
                                      >
                                        Sat
                                      </li>
                                      <li
                                        className={`modify-week-list-item ${
                                          selectedWeekdaysnormal.includes(0)
                                            ? "green-bg"
                                            : ""
                                        } ${
                                          clickedDaysnormal.includes(0)
                                            ? "red-bg"
                                            : ""
                                        }`}
                                        onClick={() => handleDayClicknormal(0)}
                                      >
                                        Sun
                                      </li>
                                    </ul>
                                    <div
                                      className="modify-period-submit"
                                      style={{
                                        marginRight: "12px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        className="btn db-save-button"
                                        onClick={BulkupdateNormal}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        ) : tab1 === "extra" ? (
                          <div
                            className="tab-pane active"
                            id="childRate-tab"
                            role="tabpanel"
                          >
                            <div className="row price-modify-filter">
                              <form>
                                <div className="price-top-section">
                                  <div className="row g-3">
                                    <div className="price-modify-filter-head">
                                      <h6>Basic Info</h6>
                                    </div>
                                    <div className="col-xxl-5 col-md-5 col-sm-12">
                                      <ul className="rate-extra-info">
                                        <li className="label-li">
                                          <div className="">
                                            <label className="price-modify-label">
                                              Free accommodation for infants up
                                              to the maximum age:
                                            </label>
                                          </div>
                                        </li>
                                        <li className="text-li">
                                          <div className="">
                                            <input
                                              onKeyDown={(e) =>
                                                symbolsrate.includes(e.key) &&
                                                e.preventDefault()
                                              }
                                              className="form-control extra-child-age-range"
                                              name="max_age_free_extra_bed"
                                              type="number"
                                              value={
                                                extrabed?.max_age_free_extra_bed
                                                  ? extrabed?.max_age_free_extra_bed
                                                  : ""
                                              }
                                              onChange={handlechange}
                                              style={{
                                                width: "60px",
                                                textAlign: "center",
                                              }}
                                              onFocus={(e) =>
                                                e.target.addEventListener(
                                                  "wheel",
                                                  function (e) {
                                                    e.preventDefault();
                                                  },
                                                  { passive: false }
                                                )
                                              }
                                            />
                                          </div>
                                        </li>
                                        <label style={{ color: "red" }}>
                                          {formerror2.max_age_free_extra_bed}
                                        </label>
                                      </ul>
                                    </div>
                                    <div className="col-md-5">
                                      <ul className="rate-extra-info">
                                        <li className="label-li">
                                          <div className="">
                                            <label className="price-modify-label">
                                              Extra bed age limit for a children
                                              :
                                            </label>
                                          </div>
                                        </li>
                                        <li className="text-li">
                                          <div className="">
                                            <input
                                              onKeyDown={(e) =>
                                                symbolsrate.includes(e.key) &&
                                                e.preventDefault()
                                              }
                                              className="form-control extra-child-age-range"
                                              name="extra_bed_child_from"
                                              type="number"
                                              value={
                                                extrabed?.extra_bed_child_from
                                                  ? extrabed?.extra_bed_child_from
                                                  : ""
                                              }
                                              onChange={handlechange}
                                              style={{
                                                width: "60px",
                                                textAlign: "center",
                                              }}
                                              onFocus={(e) =>
                                                e.target.addEventListener(
                                                  "wheel",
                                                  function (e) {
                                                    e.preventDefault();
                                                  },
                                                  { passive: false }
                                                )
                                              }
                                            />
                                          </div>
                                          <label style={{ color: "red" }}>
                                            {formerror2.extra_bed_child_from}
                                          </label>
                                        </li>

                                        <li className="text-li">
                                          <div className="">
                                            <label className="price-modify-label">
                                              to
                                            </label>
                                          </div>
                                        </li>
                                        <li className="text-li">
                                          <div className="">
                                            <input
                                              onKeyDown={(e) =>
                                                symbolsrate.includes(e.key) &&
                                                e.preventDefault()
                                              }
                                              className="form-control extra-child-age-range"
                                              name="extra_bed_child_to"
                                              type="number"
                                              value={
                                                extrabed?.extra_bed_child_to
                                                  ? extrabed?.extra_bed_child_to
                                                  : ""
                                              }
                                              onChange={handlechange}
                                              style={{
                                                width: "60px",
                                                textAlign: "center",
                                              }}
                                              onFocus={(e) =>
                                                e.target.addEventListener(
                                                  "wheel",
                                                  function (e) {
                                                    e.preventDefault();
                                                  },
                                                  { passive: false }
                                                )
                                              }
                                            />
                                          </div>
                                          <div>
                                            <label style={{ color: "red" }}>
                                              {formerror2.extra_bed_child_to}
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-md-1">
                                      <button
                                        type="button"
                                        className="btn db-save-button"
                                        onClick={ChildAgeUpdateExtrabed}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="price-top-section">
                                  <div className="row g-3">
                                    <div className="price-modify-filter-head">
                                      <h6>
                                        Select extra bed available room types
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                                <span className="appendroom"></span>

                                {inputFields.map((data, index) => {
                                  return (
                                    <div
                                      className="new_options-box-in"
                                      id="new_option"
                                      key={index}
                                    >
                                      <div className="col-xxl-12 col-md-12 col-sm-12 price-board-rate-sec">
                                        <div className="row g-3">
                                          <div className="col-lg-3">
                                            <div className="input-group">
                                              <label
                                                className="input-group-text input-joint-color"
                                                id="inputGroup-sizing-default"
                                              >
                                                Rooms :
                                              </label>
                                              <div className="col-md-8">
                                                <MultiSelect
                                                  options={newextrabed_room}
                                                  value={data.rooms}
                                                  onChange={(e) =>
                                                    handleChanges(index, e)
                                                  }
                                                  labelledBy="Select"
                                                  selectionType="counter"
                                                  allowCustomValue={true}
                                                />
                                              </div>
                                              {formerror3.map((err, i) => {
                                                return (
                                                  <>
                                                    {index === i &&
                                                    err &&
                                                    err.rooms ? (
                                                      <label
                                                        style={{ color: "red" }}
                                                      >
                                                        {err.rooms}
                                                      </label>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                );
                                              })}
                                            </div>
                                          </div>

                                          <div className="col-lg-3">
                                            <div className="input-group">
                                              <label
                                                className="input-group-text input-joint-color"
                                                id="inputGroup-sizing-default"
                                              >
                                                Rate List :
                                              </label>
                                              <div className="col-md-8">
                                                <MultiSelect
                                                  options={ratelist}
                                                  value={data.rate}
                                                  onChange={(e) =>
                                                    handleChanges1(index, e)
                                                  }
                                                  labelledBy="Select"
                                                  selectionType="counter"
                                                  allowCustomValue={true}
                                                />
                                              </div>
                                              {formerror3.map((err, i) => {
                                                return (
                                                  <>
                                                    {index === i &&
                                                    err &&
                                                    err.rate ? (
                                                      <label
                                                        style={{ color: "red" }}
                                                      >
                                                        {err.rate}
                                                      </label>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                );
                                              })}
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <div className="input-group">
                                              <label
                                                className="input-group-text input-joint-color"
                                                id="inputGroup-sizing-default"
                                              >
                                                Boards :
                                              </label>
                                              <div className="col-md-8">
                                                <MultiSelect
                                                  options={board}
                                                  value={data.board}
                                                  onChange={(e) =>
                                                    handleChanges2(index, e)
                                                  }
                                                  labelledBy="Select"
                                                  selectionType="counter"
                                                  allowCustomValue={true}
                                                />
                                              </div>
                                              {formerror3.map((err, i) => {
                                                return (
                                                  <>
                                                    {index === i &&
                                                    err &&
                                                    err.board ? (
                                                      <label
                                                        style={{ color: "red" }}
                                                      >
                                                        {err.board}
                                                      </label>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                );
                                              })}
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <div className="input-group">
                                              <label
                                                className="input-group-text input-joint-color"
                                                id="inputGroup-sizing-default"
                                              >
                                                Occupancies :
                                              </label>
                                              <div className="col-md-7">
                                                <MultiSelect
                                                  options={occupancy}
                                                  // options={uniqueRoomOccupancy[0]}
                                                  value={data.occupancy}
                                                  onChange={(e) =>
                                                    handleChanges3(index, e)
                                                  }
                                                  labelledBy="Select"
                                                  selectionType="counter"
                                                  allowCustomValue={true}
                                                />
                                              </div>
                                              {formerror3.map((err, i) => {
                                                return (
                                                  <>
                                                    {index === i &&
                                                    err &&
                                                    err.occupancy ? (
                                                      <label
                                                        style={{ color: "red" }}
                                                      >
                                                        {err.occupancy}
                                                      </label>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                );
                                              })}
                                            </div>
                                          </div>
                                          <div className="col-lg-3">
                                            <div className="input-group">
                                              <label
                                                className="input-group-text input-joint-color"
                                                id="inputGroup-sizing-default"
                                              >
                                                Based On :
                                              </label>
                                              <div className="col-md-7">
                                                <Select
                                                  placeholder="Select..."
                                                  className="form-control multiselect_extra_based"
                                                  data-choices
                                                  name="choices-single-default"
                                                  id="choices-single-default"
                                                  value={data.condition}
                                                  onChange={(e) =>
                                                    handleChanges4(index, e)
                                                  }
                                                  options={SingleOptions1}
                                                ></Select>
                                              </div>
                                              {formerror3.map((err, i) => {
                                                return (
                                                  <>
                                                    {index === i &&
                                                    err &&
                                                    err.condition ? (
                                                      <label
                                                        style={{ color: "red" }}
                                                      >
                                                        {err.condition}
                                                      </label>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                );
                                              })}
                                            </div>
                                          </div>

                                          <div className="col-lg-8">
                                            <div className="extra-rate-beds">
                                              <input
                                                onKeyDown={(e) =>
                                                  symbolsArr.includes(e.key) &&
                                                  e.preventDefault()
                                                }
                                                type="number"
                                                className="form-control extra-rate-input"
                                                placeholder="One Bed Rate"
                                                aria-label="Sizing example input"
                                                aria-describedby="inputGroup-sizing-default"
                                                value={
                                                  extrabedinput[index]
                                                    .extrabedrate1
                                                }
                                                onChange={(e) =>
                                                  handleChangeExtrabedInput(
                                                    index,
                                                    e.target.value,
                                                    "extrabedrate1"
                                                  )
                                                }
                                                onClick={(e) =>
                                                  handleExtraInput(
                                                    index,
                                                    "visible"
                                                  )
                                                }
                                                onFocus={(e) =>
                                                  e.target.addEventListener(
                                                    "wheel",
                                                    function (e) {
                                                      e.preventDefault();
                                                    },
                                                    { passive: false }
                                                  )
                                                }
                                                style={{ width: "118px" }}
                                              />
                                              {formerror3.map((err, i) => {
                                                return (
                                                  <>
                                                    {index === i &&
                                                    err &&
                                                    err.extrabedrate1 ? (
                                                      <label
                                                        style={{ color: "red" }}
                                                      >
                                                        {err.extrabedrate1}
                                                      </label>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                );
                                              })}
                                            </div>

                                            <div
                                              className={
                                                extrabedinput[index].visible ===
                                                false
                                                  ? "extra-rate-beds"
                                                  : "extra-rate-beds d-none"
                                              }
                                            >
                                              <span
                                                className={
                                                  extrabedinput[index]
                                                    .visible === false
                                                    ? "input-group"
                                                    : "input-group d-none"
                                                }
                                              >
                                                <input
                                                  onKeyDown={(e) =>
                                                    symbolsArr.includes(
                                                      e.key
                                                    ) && e.preventDefault()
                                                  }
                                                  type="number"
                                                  className="form-control extra-rate-input"
                                                  placeholder="Two Bed Rate"
                                                  aria-label="Sizing example input"
                                                  aria-describedby="inputGroup-sizing-default"
                                                  value={
                                                    extrabedinput[index]
                                                      .extrabedrate2
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeExtrabedInput(
                                                      index,
                                                      e.target.value,
                                                      "extrabedrate2"
                                                    )
                                                  }
                                                  onClick={(e) =>
                                                    handleExtraInput(
                                                      index,
                                                      "visible1"
                                                    )
                                                  }
                                                  onFocus={(e) =>
                                                    e.target.addEventListener(
                                                      "wheel",
                                                      function (e) {
                                                        e.preventDefault();
                                                      },
                                                      { passive: false }
                                                    )
                                                  }
                                                  style={{ width: "110px" }}
                                                />
                                                <span className="extra_bed_itag_input">
                                                  <span className="info-tag">
                                                    i
                                                    <span className="tooltiptext">
                                                      Added rate of both first
                                                      and second extra beds
                                                    </span>
                                                  </span>
                                                </span>
                                                <span
                                                  className="list-item-delete-in"
                                                  onClick={(e) =>
                                                    handleExtraVisible(
                                                      index,
                                                      "visible"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              </span>
                                            </div>

                                            <div
                                              className={
                                                extrabedinput[index]
                                                  .visible1 === false
                                                  ? "extra-rate-beds"
                                                  : "extra-rate-beds d-none"
                                              }
                                            >
                                              <span
                                                className={
                                                  extrabedinput[index]
                                                    .visible1 === false
                                                    ? "input-group"
                                                    : "input-group d-none"
                                                }
                                              >
                                                <input
                                                  onKeyDown={(e) =>
                                                    symbolsArr.includes(
                                                      e.key
                                                    ) && e.preventDefault()
                                                  }
                                                  type="number"
                                                  className="form-control extra-rate-input"
                                                  placeholder="Three Bed Rate"
                                                  aria-label="Sizing example input"
                                                  aria-describedby="inputGroup-sizing-default"
                                                  value={
                                                    extrabedinput[index]
                                                      .extrabedrate3
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeExtrabedInput(
                                                      index,
                                                      e.target.value,
                                                      "extrabedrate3"
                                                    )
                                                  }
                                                  onClick={(e) =>
                                                    handleExtraInput(
                                                      index,
                                                      "visible2"
                                                    )
                                                  }
                                                  onFocus={(e) =>
                                                    e.target.addEventListener(
                                                      "wheel",
                                                      function (e) {
                                                        e.preventDefault();
                                                      },
                                                      { passive: false }
                                                    )
                                                  }
                                                  style={{ width: "110px" }}
                                                />
                                                <span
                                                  className="extra_bed_itag_input"
                                                  style={{ right: "15px" }}
                                                >
                                                  <span className="info-tag">
                                                    i
                                                    <span className="tooltiptext">
                                                      Added rate of the first,
                                                      second and third extra
                                                      beds
                                                    </span>
                                                  </span>
                                                </span>
                                                <span
                                                  className="list-item-delete-in"
                                                  onClick={(e) =>
                                                    handleExtraVisible(
                                                      index,
                                                      "visible1"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              </span>
                                            </div>

                                            <div
                                              className={
                                                extrabedinput[index]
                                                  .visible2 === false
                                                  ? "extra-rate-beds"
                                                  : "extra-rate-beds d-none"
                                              }
                                            >
                                              <span
                                                className={
                                                  extrabedinput[index]
                                                    .visible2 === false
                                                    ? "input-group"
                                                    : "input-group d-none"
                                                }
                                              >
                                                <input
                                                  onKeyDown={(e) =>
                                                    symbolsArr.includes(
                                                      e.key
                                                    ) && e.preventDefault()
                                                  }
                                                  type="number"
                                                  className="form-control extra-rate-input"
                                                  placeholder="Four Bed Rate"
                                                  aria-label="Sizing example input"
                                                  aria-describedby="inputGroup-sizing-default"
                                                  value={
                                                    extrabedinput[index]
                                                      .extrabedrate4
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeExtrabedInput(
                                                      index,
                                                      e.target.value,
                                                      "extrabedrate4"
                                                    )
                                                  }
                                                  onClick={(e) =>
                                                    handleExtraInput(
                                                      index,
                                                      "visible3"
                                                    )
                                                  }
                                                  onFocus={(e) =>
                                                    e.target.addEventListener(
                                                      "wheel",
                                                      function (e) {
                                                        e.preventDefault();
                                                      },
                                                      { passive: false }
                                                    )
                                                  }
                                                  style={{ width: "110px" }}
                                                />
                                                <span className="extra_bed_itag_input">
                                                  <span className="info-tag">
                                                    i
                                                    <span className="tooltiptext">
                                                      Added rate of the first,
                                                      second, third and fourth
                                                      extra beds
                                                    </span>
                                                  </span>
                                                </span>
                                                <span
                                                  className="list-item-delete-in"
                                                  onClick={(e) =>
                                                    handleExtraVisible(
                                                      index,
                                                      "visible2"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              </span>
                                            </div>

                                            <div
                                              className={
                                                extrabedinput[index]
                                                  .visible3 === false
                                                  ? "extra-rate-beds"
                                                  : "extra-rate-beds d-none"
                                              }
                                            >
                                              <span
                                                className={
                                                  extrabedinput[index]
                                                    .visible3 === false
                                                    ? "input-group"
                                                    : "input-group d-none"
                                                }
                                              >
                                                <input
                                                  onKeyDown={(e) =>
                                                    symbolsArr.includes(
                                                      e.key
                                                    ) && e.preventDefault()
                                                  }
                                                  type="number"
                                                  className="form-control extra-rate-input"
                                                  placeholder="Five Bed Rate"
                                                  aria-label="Sizing example input"
                                                  aria-describedby="inputGroup-sizing-default"
                                                  value={
                                                    extrabedinput[index]
                                                      .extrabedrate5
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeExtrabedInput(
                                                      index,
                                                      e.target.value,
                                                      "extrabedrate5"
                                                    )
                                                  }
                                                  onFocus={(e) =>
                                                    e.target.addEventListener(
                                                      "wheel",
                                                      function (e) {
                                                        e.preventDefault();
                                                      },
                                                      { passive: false }
                                                    )
                                                  }
                                                  style={{ width: "110px" }}
                                                />
                                                <span className="extra_bed_itag_input">
                                                  <span className="info-tag">
                                                    i
                                                    <span className="tooltiptext">
                                                      Added rate of the first,
                                                      second, third, fourth and
                                                      fifth extra beds
                                                    </span>
                                                  </span>
                                                </span>
                                                <span
                                                  className="list-item-delete-in"
                                                  onClick={(e) =>
                                                    handleExtraVisible(
                                                      index,
                                                      "visible3"
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              </span>
                                            </div>

                                            <span
                                              className={
                                                index === inputFields.length - 1
                                                  ? "extrarate-room-link"
                                                  : "extrarate-room-link d-none"
                                              }
                                            >
                                              <Link
                                                className="extra-rate-add"
                                                to="#"
                                                onClick={addInputField}
                                              >
                                                +
                                              </Link>
                                            </span>

                                            <Link
                                              className={
                                                index === 0
                                                  ? "remove_more d-none"
                                                  : "remove_more"
                                              }
                                              to="#"
                                              onClick={(e) =>
                                                removeInputFields(index)
                                              }
                                            >
                                              X
                                            </Link>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <span className="padding"></span>
                                          </div>
                                          {newextrabed_room.length === 0 ? (
                                            <div className="extrabedstatus_rate_msg">
                                              You cannot update the extra bed
                                              rate because no room has the extra
                                              bed status.
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                                <div className="modify-period-submit">
                                  <button
                                    type="button"
                                    className="btn db-save-button"
                                    onClick={BulkupdateExtrabed}
                                  >
                                    Save
                                  </button>
                                </div>
                                <div className="clearfix"></div>
                                <div
                                  className="rate_extra_bed_list"
                                  onClick={(e) => setExtrabedshow(true)}
                                >
                                  Click here to show Extra bed rate
                                </div>
                              </form>
                            </div>
                          </div>
                        ) : tab1 === "boardrate" ? (
                          <div
                            className="tab-pane active"
                            id="boardRate-tab"
                            role="tabpanel"
                          >
                            <div className="row price-modify-filter">
                              <form>
                                <div className="price-top-section">
                                  <div className="row g-3">
                                    <div className="col-md-10">
                                      <div className="row">
                                        <div
                                          className="col-xxl-12 col-md-6 col-sm-12 "
                                          style={{ marginTop: "30px" }}
                                        >
                                          <ul className="rate-extra-info ">
                                            <li className="label-li">
                                              <div className="">
                                                <label className="price-modify-label">
                                                  Meal board rate age limit for
                                                  children :
                                                </label>
                                              </div>
                                            </li>
                                            <li className="text-li">
                                              <div className="">
                                                <input
                                                  onKeyDown={(e) =>
                                                    symbolsrate.includes(
                                                      e.key
                                                    ) && e.preventDefault()
                                                  }
                                                  className="form-control extra-child-age-range"
                                                  type="number"
                                                  style={{ width: "50px" }}
                                                  name="age_from"
                                                  value={selectage.age_from}
                                                  onChange={(e) =>
                                                    handleSelectAgeChange(
                                                      "age_from",
                                                      e.target.value
                                                    )
                                                  }
                                                  onFocus={(e) =>
                                                    e.target.addEventListener(
                                                      "wheel",
                                                      function (e) {
                                                        e.preventDefault();
                                                      },
                                                      { passive: false }
                                                    )
                                                  }
                                                />
                                              </div>
                                              <label style={{ color: "red" }}>
                                                {formerror.age_from}
                                              </label>
                                            </li>
                                            <li className="text-li">
                                              <div className="">
                                                <label className="price-modify-label">
                                                  to
                                                </label>
                                              </div>
                                            </li>
                                            <li className="text-li">
                                              <div className="">
                                                <input
                                                  onKeyDown={(e) =>
                                                    symbolsrate.includes(
                                                      e.key
                                                    ) && e.preventDefault()
                                                  }
                                                  className="form-control extra-child-age-range"
                                                  type="number"
                                                  style={{ width: "50px" }}
                                                  name="age_to"
                                                  value={selectage.age_to}
                                                  onChange={(e) =>
                                                    handleSelectAgeChange(
                                                      "age_to",
                                                      e.target.value
                                                    )
                                                  }
                                                  onFocus={(e) =>
                                                    e.target.addEventListener(
                                                      "wheel",
                                                      function (e) {
                                                        e.preventDefault();
                                                      },
                                                      { passive: false }
                                                    )
                                                  }
                                                />
                                              </div>
                                              <label style={{ color: "red" }}>
                                                {formerror.age_to}
                                              </label>
                                            </li>
                                          </ul>

                                          <button
                                            type="button"
                                            className="btn db-save-button"
                                            onClick={AgeupdatemealBoard}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-xxl-2 col-md-2 col-sm-12">
                                      <div className="price-top-section_popup">
                                        <div className="price-board-rate-head">
                                          {mealboard_new.length > 0 ? (
                                            <button
                                              className="modify-button-board"
                                              type="button"
                                              onClick={tog_animationFlip1}
                                            >
                                              Add more meal boards
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                          {mealboard.length > 0 ? (
                                            <button
                                              className="modify-button-board-new"
                                              type="button"
                                              onClick={tog_animationFlip3}
                                            >
                                              Board rate change
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                          {mealboard.length > 0 ? (
                                            <button
                                              className="modify-button-board_new"
                                              type="button"
                                              onClick={tog_animationFlip2}
                                            >
                                              Modify board rate for a short
                                              period
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="price-top-section">
                                  <div className="row g-3">
                                    <div className="col-xxl-12 col-md-12 col-sm-12">
                                      <div className="price-board-rate-head">
                                        <div className="row">
                                          {mealboard.length > 0 ? (
                                            <div
                                              className="col-md-2 col-xl-1 col-lg-1"
                                              style={{ marginTop: "10px" }}
                                            >
                                              <h6>Adult :</h6>
                                            </div>
                                          ) : (
                                            ""
                                          )}

                                          {mealboard.map((data, i) => {
                                            const rateString =
                                              data.adult_price.toString();
                                            const [integerPart, decimalPart] =
                                              rateString.split(".");
                                            const displayRate =
                                              decimalPart &&
                                              parseFloat(decimalPart) !== 0
                                                ? data.adult_price
                                                : parseInt(integerPart, 10);

                                            return (
                                              <>
                                                <div
                                                  key={i}
                                                  className="col-md-4 col-lg-3 col-xl-2"
                                                  style={{
                                                    right: "4%",
                                                    marginTop: "3px",
                                                  }}
                                                >
                                                  <div className="input-group">
                                                    <label
                                                      className="input-group-text input-joint-color"
                                                      id="inputGroup-sizing-default"
                                                    >
                                                      {data.label}
                                                    </label>

                                                    <input
                                                      onKeyDown={(e) =>
                                                        symbolsArr.includes(
                                                          e.key
                                                        ) && e.preventDefault()
                                                      }
                                                      type="number"
                                                      className="form-control board-price-text"
                                                      aria-label="Sizing example input"
                                                      aria-describedby="inputGroup-sizing-default"
                                                      name="adult_price"
                                                      // value={displayRate}
                                                      value={data.adult_price}
                                                      onFocus={(e) =>
                                                        e.target.addEventListener(
                                                          "wheel",
                                                          function (e) {
                                                            e.preventDefault();
                                                          },
                                                          { passive: false }
                                                        )
                                                      }
                                                      // onChange={(e) => handleMealBoardChange(i, 'adult_price', e.target.value)}
                                                    />
                                                  </div>
                                                  {/* { formerror1.map((err,ind)=>{
                                          return(
                                              <>
                                                    {ind===i && err && err.adult_price?
                                                        <label style={{ color: "red" }}>
                                                              {err.adult_price}
                                                            </label>
                                                            :""}
                                              </>
                                          )
                                        })} */}
                                                </div>
                                              </>
                                            );
                                          })}
                                        </div>
                                      </div>

                                      <div className="price-board-rate-head">
                                        <div className="row">
                                          {mealboard.length > 0 ? (
                                            <div
                                              className="col-md-2 col-xl-1 col-lg-1"
                                              style={{ marginTop: "17px" }}
                                            >
                                              <h6>Child :</h6>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {mealboard.map((data, index) => {
                                            const rateString =
                                              data.child_price.toString();
                                            const [integerPart, decimalPart] =
                                              rateString.split(".");
                                            const displayRate =
                                              decimalPart &&
                                              parseFloat(decimalPart) !== 0
                                                ? data.child_price
                                                : parseInt(integerPart, 10);

                                            return (
                                              <>
                                                <div
                                                  key={index}
                                                  className="col-md-4 col-lg-3 col-xl-2"
                                                  style={{
                                                    right: "4%",
                                                    marginTop: "7px",
                                                  }}
                                                >
                                                  <div className="input-group">
                                                    <label
                                                      className="input-group-text input-joint-color"
                                                      id="inputGroup-sizing-default"
                                                    >
                                                      {data.label}
                                                    </label>
                                                    <input
                                                      onKeyDown={(e) =>
                                                        symbolsArr.includes(
                                                          e.key
                                                        ) && e.preventDefault()
                                                      }
                                                      type="number"
                                                      className="form-control board-price-text"
                                                      aria-label="Sizing example input"
                                                      aria-describedby="inputGroup-sizing-default"
                                                      name="child_price"
                                                      // value={displayRate}
                                                      value={data.child_price}
                                                      onFocus={(e) =>
                                                        e.target.addEventListener(
                                                          "wheel",
                                                          function (e) {
                                                            e.preventDefault();
                                                          },
                                                          { passive: false }
                                                        )
                                                      }
                                                      // onChange={(e) =>handleMealBoardChange(index, 'child_price', e.target.value)}
                                                    />
                                                  </div>

                                                  {/* { formerror1.map((err,i)=>{
                                                                    return(
                                                                        <>
                                                                              {index===i && err && err.child_price?
                                                                                  <label style={{ color: "red" }}>
                                                                                        {err.child_price}
                                                                                      </label>
                                                                                      :""}
                                                                        </>
                                                                    )
                                                                  })} */}
                                                </div>
                                              </>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {modifyboardrate.length > 0 ? (
                                  <div className="row">
                                    {modifyboardrate.map((data, i) => {
                                      const fromdate = moment(
                                        data.from_date
                                      ).format("DD-MM-YYYY");
                                      const todate = moment(
                                        data.to_date
                                      ).format("DD-MM-YYYY");
                                      return (
                                        <>
                                          <div
                                            className="col-md-4 mealboard_list_div"
                                            key={i}
                                            style={{ marginTop: "7px" }}
                                          >
                                            <div
                                              className=""
                                              style={{
                                                marginLeft: "10px",
                                              }}
                                            >
                                              <span className="mealboard_date_list">
                                                From : {fromdate}
                                              </span>
                                              <span
                                                className="mealboard_date_list"
                                                style={{
                                                  marginLeft: "20px",
                                                }}
                                              >
                                                To : {todate}
                                              </span>
                                              <span className="mealboard_list_dlt">
                                                <Link
                                                  className=""
                                                  to=""
                                                  title="Delete"
                                                  onClick={() =>
                                                    tog_mealboard_delete(
                                                      data.modify_rate_id
                                                    )
                                                  }
                                                >
                                                  <img src="/images/trash.png" />
                                                </Link>
                                              </span>

                                              <div style={{ marginTop: "2px" }}>
                                                <span style={{ color: "red" }}>
                                                  Adult Price :{" "}
                                                </span>
                                                {data.boards.map(
                                                  (board, ind) => {
                                                    const rateString =
                                                      board.adult_price.toString();
                                                    const [
                                                      integerPart,
                                                      decimalPart,
                                                    ] = rateString.split(".");
                                                    const displayRate =
                                                      decimalPart &&
                                                      parseFloat(
                                                        decimalPart
                                                      ) !== 0
                                                        ? board.adult_price
                                                        : parseInt(
                                                            integerPart,
                                                            10
                                                          );

                                                    let color;
                                                    switch (ind % 3) {
                                                      case 0:
                                                        color = "#008080";
                                                        break;
                                                      case 1:
                                                        color =
                                                          "rgb(51, 190, 51)";
                                                        break;
                                                      case 2:
                                                        color = "#0085FF";
                                                        break;
                                                      default:
                                                        color = "black"; // fallback color
                                                        break;
                                                    }
                                                    return (
                                                      <>
                                                        <span
                                                          className="mealboard_list_boardname"
                                                          style={{
                                                            color: color,
                                                          }}
                                                          key={ind}
                                                        >
                                                          {board.board_name} :{" "}
                                                          {/* {displayRate} */}
                                                          {board.adult_price}
                                                        </span>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </div>
                                              <div>
                                                <span style={{ color: "red" }}>
                                                  Child Price :{" "}
                                                </span>
                                                {data.boards.map(
                                                  (board, ind) => {
                                                    const rateString =
                                                      board.child_price.toString();
                                                    const [
                                                      integerPart,
                                                      decimalPart,
                                                    ] = rateString.split(".");
                                                    const displayRate =
                                                      decimalPart &&
                                                      parseFloat(
                                                        decimalPart
                                                      ) !== 0
                                                        ? board.child_price
                                                        : parseInt(
                                                            integerPart,
                                                            10
                                                          );

                                                    let color;
                                                    switch (ind % 3) {
                                                      case 0:
                                                        color = "#008080";
                                                        break;
                                                      case 1:
                                                        color =
                                                          "rgb(51, 190, 51)";
                                                        break;
                                                      case 2:
                                                        color = "#0085FF";
                                                        break;
                                                      default:
                                                        color = "black"; // fallback color
                                                        break;
                                                    }
                                                    return (
                                                      <>
                                                        <span
                                                          className="mealboard_list_boardname"
                                                          style={{
                                                            color: color,
                                                          }}
                                                          key={ind}
                                                        >
                                                          {board.board_name} :{" "}
                                                          {/* {displayRate} */}
                                                          {board.child_price}
                                                        </span>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {/* <div className="row g-3">
                            <div className="col-xxl-2 col-sm-4 col-lg-3 col-md-2 form-calender-add">
                            <form action="#">
                                <div className="input-group">
                                <Flatpickr
                                    className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                    placeholder="FROM"
                                    id="flatpickr-from"
                                    options={{ mode: "single", dateFormat: "d M, Y" ,minDate:"today"}}
                                    value={datefrom}
                                    onChange={handleCalendarChangeFrom}
                                />
                                 <label style={{ color: "red" }}>
                                                            {formerror.datefrom}
                                                          </label>
                                <span
                                    className="form_calendar-style fon mdi mdi-calendar-month-outline" onClick={() => handleCalendarIconClick('from')}></span>
                                </div>
                            </form>
                            </div>
                            <div className="col-xxl-2 col-sm-4 col-lg-3 col-md-2 form-calender-add">
                            <form action="#">
                                <div className="input-group">
                                <Flatpickr
                                    className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                    placeholder="TO"
                                    id="flatpickr-to"
                                    options={{ mode: "single", dateFormat: "d M, Y" ,minDate:"today"}}
                                    value={dateto}
                                    onChange={handleCalendarChangeTo}
                                />
                                 <label style={{ color: "red" }}>
                                                            {formerror.dateto}
                                                          </label>
                                <span
                                    className="form_calendar-style fon mdi mdi-calendar-month-outline" onClick={() => handleCalendarIconClick('to')}></span>
                                </div>
                            </form>
                            </div>
                            
                                        <div className="col-xxl-8 col-lg-6 col-md-8 col-sm-5">
                                        <ul className="modify-week-list">
          <li className={`modify-week-list-item card-title ${selectedWeekdays.includes(1) ? "green-bg" : ""} ${clickedDays.includes(1) ? "red-bg" : ""}`}onClick={() => handleDayClick(1)}>
            Mon
          </li>
          <li className={`modify-week-list-item card-title ${selectedWeekdays.includes(2) ? "green-bg" : ""} ${clickedDays.includes(2) ? "red-bg" : ""}`}onClick={() => handleDayClick(2)}>
            Tue
          </li>
          <li className={`modify-week-list-item ${selectedWeekdays.includes(3) ? "green-bg" : ""} ${clickedDays.includes(3) ? "red-bg" : ""}`}onClick={() => handleDayClick(3)}>
            Wed
          </li>
          <li className={`modify-week-list-item ${selectedWeekdays.includes(4) ? "green-bg" : ""} ${clickedDays.includes(4) ? "red-bg" : ""}`} onClick={() => handleDayClick(4)}>
            Thu
          </li>
          <li className={`modify-week-list-item ${selectedWeekdays.includes(5) ? "green-bg" : ""} ${clickedDays.includes(5) ? "red-bg" : ""}`}onClick={() => handleDayClick(5)}>
            Fri
          </li>
          <li className={`modify-week-list-item ${selectedWeekdays.includes(6) ? "green-bg" : ""} ${clickedDays.includes(6) ? "red-bg" : ""}`}onClick={() => handleDayClick(6)}>
            Sat
          </li>
          <li className={`modify-week-list-item ${selectedWeekdays.includes(0) ? "green-bg" : ""} ${clickedDays.includes(0) ? "red-bg" : ""}`}onClick={() => handleDayClick(0)}>
            Sun
          </li>        
              </ul>
                  </div>
                    </div> */}
                              </form>
                            </div>
                          </div>
                        ) : tab1 === "dynamic" ? 
                       
                          <div
                            className="tab-pane active"
                            id="dynamicRate-tab"
                            role="tabpanel"
                          >
                            <div className="row price-modify-filter">
                              <form>
                                <div className="price-top-section">
                                  <div className="row g-3">
                                    <div className="basic-rate-setup-head">
                                      <div className="row g-3">
                                        <div className="col-lg-4">
                                          <div className="input-group">
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                            >
                                              Basic Room Category :
                                            </label>
                                            <div className="col-md-7">
                                            <input
                                                className="form-control"
                                                data-choices
                                                name="choices-single-default"
                                                id="choices-single-default"
                                                value={basicroom?.label}
                                              />
                                              {/* <Select
                                                placeholder="Select..."
                                                className="form-control"
                                                data-choices
                                                name="choices-single-default"
                                                id="choices-single-default"
                                                value={basicroom}
                                                onChange={setBasicroom}
                                                options={newRoom}
                                              /> */}
                                            </div>
                                            <label style={{ color: "red" }}>
                                              {formerror5.basicroom}
                                            </label>
                                          </div>
                                        </div>
                                        {dynamicrate.type ? (
                                          <div className="col-lg-3">
                                            <div className="input-group">
                                              <label
                                                className="input-group-text input-joint-color"
                                                id="inputGroup-sizing-default"
                                              >
                                                Based On :
                                              </label>
                                              <div className="col-md-8">
                                                <Select
                                                  placeholder="Select..."
                                                  className="form-control"
                                                  data-choices
                                                  name="choices-single-default"
                                                  id="choices-single-default"
                                                  value={selectedOption}
                                                  options={SingleOptions}
                                                  onChange={handleSelectChange}
                                                />
                                              </div>
                                              <label style={{ color: "red" }}>
                                                {formerror5.selectedOptions}
                                              </label>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="col-lg-3">
                                            <div className="input-group">
                                              <label
                                                className="input-group-text input-joint-color"
                                                id="inputGroup-sizing-default"
                                              >
                                                Based On :
                                              </label>
                                              <div className="col-md-8">
                                                <Select
                                                  placeholder="Select..."
                                                  className="form-control"
                                                  data-choices
                                                  name="choices-single-default"
                                                  id="choices-single-default"
                                                  value={selectedOptionNew}
                                                  options={SingleOptions}
                                                  onChange={(e) =>
                                                    setSelectedOptionNew(e)
                                                  }
                                                />
                                              </div>
                                              <label style={{ color: "red" }}>
                                                {formerror5.selectedOptionNew}
                                              </label>
                                            </div>
                                          </div>
                                        )}
                                        {ratelistnew.length>0?
                                        <div className="col-lg-3">
                                          <div className="input-group">
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                            >
                                              All Rates :
                                            </label>
                                            <div className="col-md-8">
                                              <MultiSelect
                                                options={ratelistnew}
                                                value={dynamicrateselect}
                                                onChange={handleAllRateChange}
                                                labelledBy="Select"
                                                selectionType="counter"
                                                allowCustomValue={true}
                                              />
                                            </div>
                                            <label style={{ color: "red" }}>
                                              {formerror5.dynamicrateselect}
                                            </label>
                                          </div>
                                        </div>
                                        :""}
                                        <div className="row g-3">
                                          {ratebar.length>0?
                                        <div className="new_basic_field" style={{ marginLeft: "5px" }} >
                                                <span className="basic-rate-title">
                                                  Basic Rate ({ratebar[0]?.label})
                                                  <span className="info-tag">
                                                    i
                                                    <span className="tooltiptext">
                                                      Please enter your basic
                                                      room category room only
                                                      rate.
                                                    </span>
                                                  </span>
                                                </span>
                                                <span
                                                  style={{
                                                    marginTop: "10px",
                                                    color: "#008080",
                                                  }}
                                                >
                                                  :
                                                </span>
                                                <span className="basic-rate-amount">
                                                  <input
                                                    onKeyDown={(e) =>
                                                      symbolsArr.includes(
                                                        e.key
                                                      ) && e.preventDefault()
                                                    }
                                                    type="number"
                                                    className="form-control"
                                                    value={ratebar[0]?.basic_rate}
                                                    name="basic_rate"
                                                    onChange={handleBasicRateChange}
                                                    style={{ height: "32px" }}
                                                    onFocus={(e) =>
                                                      e.target.addEventListener(
                                                        "wheel",
                                                        function (e) {
                                                          e.preventDefault();
                                                        },
                                                        { passive: false }
                                                      )
                                                    }
                                                  />
                                                </span>
                                                {/* <div className="modify-room-sub-section" style={{position:"relative",top:"5px",right:"10px"}}>
                                                      <label
                                                        className="input-group-text input-joint-color rate_new_inv"
                                                        id="inputGroup-sizing-default"
                                                        style={{
                                                          fontSize: "20px",
                                                        }}
                                                      >
                                                        {"-"}
                                                      </label>
                                                      <span className="modify-room-sub-select">
                                                        <span className="modify-rate_section">
                                                          <input
                                                            onKeyDown={(e) =>
                                                              symbolsArr.includes(
                                                                e.key
                                                              ) &&
                                                              e.preventDefault()
                                                            }
                                                            type="number"
                                                            className="form-control"
                                                            placeholder=""
                                                            id="emailidInput"
                                                            value={
                                                              ratebar[0].change_value
                                                            }
                                                            name="change_value"
                                                            onChange={(e)=>handleBasicRateChange(e,"change_value")}
                                                            style={{
                                                              width: "110px",
                                                              marginLeft:
                                                                "38px",
                                                              marginTop:
                                                                "-32px",
                                                            }}
                                                            onFocus={(e) =>
                                                              e.target.addEventListener(
                                                                "wheel",
                                                                function (e) {
                                                                  e.preventDefault();
                                                                },
                                                                {
                                                                  passive: false,
                                                                }
                                                              )
                                                            }
                                                          />
                                                          <span
                                                            className="rate_change_section"
                                                            style={{
                                                              width: "55px",
                                                            }}
                                                          >
                                                            <select
                                                              id="ForminputState"
                                                              className="form-select"
                                                              value={
                                                                ratebar[0].change_type
                                                              }
                                                              // onChange={(e) => handleDynamicCurrencyChange(i, e.target.value)}
                                                              name="change_type"
                                                            >
                                                              <option
                                                                value="currency"
                                                                selected
                                                              >
                                                                {
                                                                  currencyname.code
                                                                }
                                                              </option>
                                                              <option value="percentage">
                                                                %
                                                              </option>
                                                            </select>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div> */}
                                              
                                              </div>
                                              :""}
                                          {dynamicrateselect?.map((value, i) => {
                                            return (
                                              <div
                                                className="new_basic_field_bar"
                                                key={i}
                                              >
                                                <span className="basic-rate-title">
                                                  Basic Rate ({value.label})
                                                  <span className="info-tag">
                                                    i
                                                    <span className="tooltiptext">
                                                      Please enter your basic
                                                      room category room only
                                                      rate.
                                                    </span>
                                                  </span>
                                                </span>
                                                <span
                                                  style={{
                                                    marginTop: "10px",
                                                    color: "#008080",
                                                  }}
                                                >
                                                  :
                                                </span>
                                               
                                                <div className="modify-room-sub-section" style={{position:"relative",top:"5px",left:"5px"}}>
                                                      <label
                                                        className="input-group-text input-joint-color rate_new_inv dynamicrate_changetype_operator"
                                                        id="inputGroup-sizing-default"
                                                       
                                                      >
                                                        {"-"}
                                                      </label>
                                                      <span className="modify-room-sub-select">
                                                        <span className="modify-rate_section">
                                                          <input
                                                            onKeyDown={(e) =>
                                                              symbolsArr.includes(
                                                                e.key
                                                              ) &&
                                                              e.preventDefault()
                                                            }
                                                            type="number"
                                                            className="form-control"
                                                            placeholder=""
                                                            id="emailidInput"
                                                            value={
                                                              value.change_value
                                                            }
                                                            name="change_value"
                                                            onChange={(e) =>
                                                              handleDynamicbasicrate(
                                                                i,
                                                                "change_value",
                                                                e.target.value
                                                              )
                                                            }
                                                            style={{
                                                              width: "110px",
                                                              marginLeft:
                                                                "38px",
                                                              marginTop:
                                                                "-32px",
                                                            }}
                                                            onFocus={(e) =>
                                                              e.target.addEventListener(
                                                                "wheel",
                                                                function (e) {
                                                                  e.preventDefault();
                                                                },
                                                                {
                                                                  passive: false,
                                                                }
                                                              )
                                                            }
                                                          />
                                                          <span
                                                            className="rate_change_section"
                                                            style={{
                                                              width: "55px",
                                                            }}
                                                          >
                                                            <select
                                                              id="ForminputState"
                                                              className="form-select"
                                                              value={
                                                                value.change_type
                                                              }
                                                              onChange={(e) => handleDynamicCurrencyChange(i, e.target.value)}
                                                              name="change_type"
                                                            >
                                                              <option
                                                                value="currency"
                                                                selected
                                                              >
                                                                {
                                                                  currencyname.code
                                                                }
                                                              </option>
                                                              <option value="percentage">
                                                                %
                                                              </option>
                                                            </select>
                                                          </span>
                                                        </span>
                                                      </span>
                                                  </div>
                                                <span className="basic-rate-amount">
                                                  <input
                                                    onKeyDown={(e) =>
                                                      symbolsArr.includes(
                                                        e.key
                                                      ) && e.preventDefault()
                                                    }
                                                    type="number"
                                                    className="form-control"
                                                    value={value.basic_rate}
                                                    name="basic_rate"
                                                    // onChange={(e) =>
                                                    //   handleDynamicbasicrate(
                                                    //     i,
                                                    //     "basic_rate",
                                                    //     e.target.value
                                                    //   )
                                                    // }
                                                    style={{ height: "32px",position:"relative",right:"16px" }}
                                                    onFocus={(e) =>
                                                      e.target.addEventListener(
                                                        "wheel",
                                                        function (e) {
                                                          e.preventDefault();
                                                        },
                                                        { passive: false }
                                                      )
                                                    }
                                                    disabled
                                                  />
                                                </span>
                                                {formerror6.map((err, ind) => {
                                                  return (
                                                    <>
                                                      {i === ind &&
                                                      err &&
                                                      err.change_value ? (
                                                        <label
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        >
                                                          {err.change_value}
                                                        </label>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </>
                                                  );
                                                })}
                                              </div>
                                            );
                                          })}
                                        </div>

                                        <div
                                          className="row"
                                          style={{ marginLeft: "0px" }}
                                        >
                                          {dynamicrateselect.length > 0 ? (
                                            <>
                                              <div className="new_inv_field">
                                                <span
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  You can increase rate when
                                                  total inventory count is very
                                                  less.
                                                </span>
                                                <span className="new_inv_field_sub">
                                                  <label
                                                    className="input-group-text input-joint-color"
                                                    id="inputGroup-sizing-default"
                                                    style={{
                                                      height: "32px",
                                                      borderTopRightRadius:
                                                        "0px",
                                                      borderBottomRightRadius:
                                                        "0px",
                                                      marginLeft: "30px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Last Inv.{" "}
                                                  </label>
                                                  <input
                                                    onKeyDown={(e) =>
                                                      symbolsrate.includes(
                                                        e.key
                                                      ) && e.preventDefault()
                                                    }
                                                    type="number"
                                                    className="input-inventory"
                                                    value={
                                                      dynamicrate.least_inventory_count
                                                    }
                                                    name="least_inventory_count"
                                                    onChange={
                                                      handleChangeDynamicselect
                                                    }
                                                    onFocus={(e) =>
                                                      e.target.addEventListener(
                                                        "wheel",
                                                        function (e) {
                                                          e.preventDefault();
                                                        },
                                                        { passive: false }
                                                      )
                                                    }
                                                  />

                                                  {dynamicrate.least_inventory_count >=
                                                  1 ? (
                                                    <div className="modify-room-sub-section">
                                                      <label
                                                        className="input-group-text input-joint-color rate_new_inv"
                                                        id="inputGroup-sizing-default"
                                                        style={{
                                                          fontSize: "16px",
                                                        }}
                                                      >
                                                        {"+"}
                                                      </label>
                                                      <span className="modify-room-sub-select">
                                                        <span className="modify-rate_section">
                                                          <input
                                                            onKeyDown={(e) =>
                                                              symbolsArr.includes(
                                                                e.key
                                                              ) &&
                                                              e.preventDefault()
                                                            }
                                                            type="number"
                                                            className="form-control"
                                                            placeholder=""
                                                            id="emailidInput"
                                                            value={
                                                              dynamicrate.least_inventory_value
                                                            }
                                                            name="least_inventory_value"
                                                            onChange={
                                                              handleChangeDynamicselect
                                                            }
                                                            style={{
                                                              width: "110px",
                                                              marginLeft:
                                                                "38px",
                                                              marginTop:
                                                                "-32px",
                                                            }}
                                                            onFocus={(e) =>
                                                              e.target.addEventListener(
                                                                "wheel",
                                                                function (e) {
                                                                  e.preventDefault();
                                                                },
                                                                {
                                                                  passive: false,
                                                                }
                                                              )
                                                            }
                                                          />
                                                          <span
                                                            className="rate_change_section"
                                                            style={{
                                                              width: "55px",
                                                            }}
                                                          >
                                                            <select
                                                              id="ForminputState"
                                                              className="form-select"
                                                              value={
                                                                dynamicrate.least_inventory_amount_type
                                                              }
                                                              onChange={
                                                                handleDynamicCurrency
                                                              }
                                                              name="least_inventory_amount_type"
                                                            >
                                                              <option
                                                                value="currency"
                                                                selected
                                                              >
                                                                {
                                                                  currencyname.code
                                                                }
                                                              </option>
                                                              <option value="percentage">
                                                                %
                                                              </option>
                                                            </select>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </span>
                                              </div>

                                              <div
                                                className="new_inv_field"
                                                style={{ marginLeft: "10px" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  You can reduce the rate when
                                                  the total inventory count is
                                                  higher.
                                                </span>
                                                <span className="new_inv_field_sub">
                                                  <label
                                                    className="input-group-text input-joint-color"
                                                    id="inputGroup-sizing-default"
                                                    style={{
                                                      height: "32px",
                                                      borderTopRightRadius:
                                                        "0px",
                                                      borderBottomRightRadius:
                                                        "0px",
                                                      marginLeft: "25px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Max.Inv.
                                                  </label>
                                                  <input
                                                    onKeyDown={(e) =>
                                                      symbolsrate.includes(
                                                        e.key
                                                      ) && e.preventDefault()
                                                    }
                                                    type="number"
                                                    className="input-inventory"
                                                    value={
                                                      dynamicrate.most_inventory_count
                                                    }
                                                    name="most_inventory_count"
                                                    onChange={
                                                      handleChangeDynamicselect
                                                    }
                                                    onFocus={(e) =>
                                                      e.target.addEventListener(
                                                        "wheel",
                                                        function (e) {
                                                          e.preventDefault();
                                                        },
                                                        { passive: false }
                                                      )
                                                    }
                                                  />

                                                  {dynamicrate.most_inventory_count >=
                                                  1 ? (
                                                    <div className="modify-room-sub-section">
                                                      <label
                                                        className="input-group-text input-joint-color rate_new_inv"
                                                        id="inputGroup-sizing-default"
                                                        style={{
                                                          fontSize: "20px",
                                                        }}
                                                      >
                                                        {"-"}
                                                      </label>
                                                      <span className="modify-room-sub-select">
                                                        <span className="modify-rate_section">
                                                          <input
                                                            onKeyDown={(e) =>
                                                              symbolsArr.includes(
                                                                e.key
                                                              ) &&
                                                              e.preventDefault()
                                                            }
                                                            type="number"
                                                            className="form-control"
                                                            placeholder=""
                                                            id="emailidInput"
                                                            value={
                                                              dynamicrate.most_inventory_value
                                                            }
                                                            name="most_inventory_value"
                                                            onChange={
                                                              handleChangeDynamicselect
                                                            }
                                                            style={{
                                                              width: "110px",
                                                              marginLeft:
                                                                "38px",
                                                              marginTop:
                                                                "-32px",
                                                            }}
                                                            onFocus={(e) =>
                                                              e.target.addEventListener(
                                                                "wheel",
                                                                function (e) {
                                                                  e.preventDefault();
                                                                },
                                                                {
                                                                  passive: false,
                                                                }
                                                              )
                                                            }
                                                          />
                                                          <span
                                                            className="rate_change_section"
                                                            style={{
                                                              width: "55px",
                                                            }}
                                                          >
                                                            <select
                                                              id="ForminputState"
                                                              className="form-select"
                                                              value={
                                                                dynamicrate.most_inventory_amount_type
                                                              }
                                                              onChange={
                                                                handleDynamicCurrency
                                                              }
                                                              name="most_inventory_amount_type"
                                                            >
                                                              <option
                                                                value="currency"
                                                                selected
                                                              >
                                                                {
                                                                  currencyname.code
                                                                }
                                                              </option>
                                                              <option value="percentage">
                                                                %
                                                              </option>
                                                            </select>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </span>
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="row g-3"
                                    style={{ marginTop: "0px" }}
                                  >
                                    <div className="col-xxl-2 col-sm-4 form-calender-add">
                                      <form action="#">
                                        <div className="input-group">
                                          <Flatpickr
                                            className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                            placeholder="FROM"
                                            id="flatpickr-from"
                                            options={{
                                              mode: "single",
                                              dateFormat: "d M, Y",
                                            }}
                                            value={new Date(dynamicfromdate)}
                                            onChange={handleCalendarChangeFrom1}
                                          />
                                          <label style={{ color: "red" }}>
                                            {formerror5.dynamicfromdate}
                                          </label>
                                          <span
                                            className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                            onClick={() =>
                                              handleCalendarIconClick("from")
                                            }
                                          ></span>
                                        </div>
                                      </form>
                                    </div>
                                    <div className="col-xxl-2 col-sm-4 form-calender-add">
                                      <form action="#">
                                        <div className="input-group">
                                          <Flatpickr
                                            className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                            placeholder="TO"
                                            id="flatpickr-to"
                                            options={{
                                              mode: "single",
                                              dateFormat: "d M, Y",
                                            }}
                                            value={new Date(dynamictodate)}
                                            onChange={handleCalendarChangeTo1}
                                          />
                                          <label style={{ color: "red" }}>
                                            {formerror5.dynamictodate}
                                          </label>
                                          <span
                                            className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                            onClick={() =>
                                              handleCalendarIconClick("to")
                                            }
                                          ></span>
                                        </div>
                                      </form>
                                    </div>
                                    <div className="col-xxl-6 col-sm-12">
                                      <ul className="modify-week-list">
                                        <li
                                          className={`modify-week-list-item card-title ${
                                            selectedWeekdays1.includes(1)
                                              ? "green-bg"
                                              : ""
                                          } ${
                                            clickedDays1.includes(1)
                                              ? "red-bg"
                                              : ""
                                          }`}
                                          onClick={() => handleDayClick1(1)}
                                        >
                                          Mon
                                        </li>
                                        <li
                                          className={`modify-week-list-item card-title ${
                                            selectedWeekdays1.includes(2)
                                              ? "green-bg"
                                              : ""
                                          } ${
                                            clickedDays1.includes(2)
                                              ? "red-bg"
                                              : ""
                                          }`}
                                          onClick={() => handleDayClick1(2)}
                                        >
                                          Tue
                                        </li>
                                        <li
                                          className={`modify-week-list-item ${
                                            selectedWeekdays1.includes(3)
                                              ? "green-bg"
                                              : ""
                                          } ${
                                            clickedDays1.includes(3)
                                              ? "red-bg"
                                              : ""
                                          }`}
                                          onClick={() => handleDayClick1(3)}
                                        >
                                          Wed
                                        </li>
                                        <li
                                          className={`modify-week-list-item ${
                                            selectedWeekdays1.includes(4)
                                              ? "green-bg"
                                              : ""
                                          } ${
                                            clickedDays1.includes(4)
                                              ? "red-bg"
                                              : ""
                                          }`}
                                          onClick={() => handleDayClick1(4)}
                                        >
                                          Thu
                                        </li>
                                        <li
                                          className={`modify-week-list-item ${
                                            selectedWeekdays1.includes(5)
                                              ? "green-bg"
                                              : ""
                                          } ${
                                            clickedDays1.includes(5)
                                              ? "red-bg"
                                              : ""
                                          }`}
                                          onClick={() => handleDayClick1(5)}
                                        >
                                          Fri
                                        </li>
                                        <li
                                          className={`modify-week-list-item ${
                                            selectedWeekdays1.includes(6)
                                              ? "green-bg"
                                              : ""
                                          } ${
                                            clickedDays1.includes(6)
                                              ? "red-bg"
                                              : ""
                                          }`}
                                          onClick={() => handleDayClick1(6)}
                                        >
                                          Sat
                                        </li>
                                        <li
                                          className={`modify-week-list-item ${
                                            selectedWeekdays1.includes(0)
                                              ? "green-bg"
                                              : ""
                                          } ${
                                            clickedDays1.includes(0)
                                              ? "red-bg"
                                              : ""
                                          }`}
                                          onClick={() => handleDayClick1(0)}
                                        >
                                          Sun
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  {dynamicroomrate.map((data, i) => {
                                    if (
                                      data?.room?.value !== basicroom?.value
                                    ) {
                                      counter += 1;
                                    }
                                    return (
                                      <>
                                        {data?.room?.value !==
                                        basicroom?.value ? (
                                          <div
                                            className="row"
                                            key={i}
                                            style={{ marginTop: "25px" }}
                                          >
                                            <div className="col-xxl-12 col-sm-12 modify-room-sec">
                                              <ul className="modify-room-sub-sec">
                                                <li>
                                                  <div className="\ modify-room-sub-sec-list_1">
                                                    <div
                                                      className="modify-room-sub-no"
                                                      style={{ height: "20px" }}
                                                    >
                                                      {counter}
                                                    </div>
                                                    <div className="modify-room-sub-title_new">
                                                      {data.room.label} (ROOM
                                                      ID: {data.room.key})
                                                    </div>
                                                    <div
                                                      className="new_field_base_rate"
                                                      style={{
                                                        marginTop: "-6px",
                                                      }}
                                                    >
                                                      <div className="new_field_sub_base_rate">
                                                        <div
                                                          className="modify-room-sub-input"
                                                          style={{
                                                            marginRight: "-1px",
                                                          }}
                                                        >
                                                          <select
                                                            id="ForminputState"
                                                            className="form-select"
                                                            value={
                                                              data.operator
                                                            }
                                                            onChange={(e) =>
                                                              handleDynamicSelect(
                                                                i,
                                                                "operator",
                                                                e.target.value
                                                              )
                                                            }
                                                            style={{
                                                              marginRight:
                                                                "25px",
                                                            }}
                                                          >
                                                            <option
                                                              selected=""
                                                              value="+"
                                                            >
                                                              +
                                                            </option>
                                                            <option value="-">
                                                              -
                                                            </option>
                                                          </select>
                                                        </div>

                                                        <div className="modify-room-sub-section">
                                                          <span className="modify-room-sub-select">
                                                            <span className="modify-rate_section">
                                                              <input
                                                                onKeyDown={(
                                                                  e
                                                                ) =>
                                                                  symbolsArr.includes(
                                                                    e.key
                                                                  ) &&
                                                                  e.preventDefault()
                                                                }
                                                                type="text"
                                                                className="form-control"
                                                                placeholder=""
                                                                id="emailidInput"
                                                                value={
                                                                  data.value
                                                                }
                                                                name="value"
                                                                onChange={(e) =>
                                                                  handleChangeDynamic(
                                                                    i,
                                                                    "value",
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                              />
                                                              {formerror7.map(
                                                                (err, ind) => {
                                                                  return (
                                                                    <>
                                                                      {i ===
                                                                        ind &&
                                                                      err &&
                                                                      err.value ? (
                                                                        <label
                                                                          style={{
                                                                            color:
                                                                              "red",
                                                                          }}
                                                                        >
                                                                          {
                                                                            err.value
                                                                          }
                                                                        </label>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                    </>
                                                                  );
                                                                }
                                                              )}
                                                              <span className="rate_change_section">
                                                                <select
                                                                  id="ForminputState"
                                                                  className="form-select"
                                                                  value={
                                                                    data.increment_type
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleDynamicSelect(
                                                                      i,
                                                                      "increment_type",
                                                                      e.target
                                                                        .value
                                                                    )
                                                                  }
                                                                >
                                                                  <option
                                                                    value="currency"
                                                                    selected=""
                                                                  >
                                                                    {
                                                                      currencyname.code
                                                                    }
                                                                  </option>
                                                                  <option value="percentage">
                                                                    %
                                                                  </option>
                                                                </select>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="new_inv_field_room"
                                                      style={{
                                                        marginTop: "-30px",
                                                        marginLeft: "4px",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "12px",
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        You can increase rate
                                                        when total inventory
                                                        count is very less.
                                                      </span>
                                                      <span className="new_inv_field_sub_room">
                                                        <label
                                                          className="input-group-text input-joint-color"
                                                          id="inputGroup-sizing-default"
                                                          style={{
                                                            height: "32px",
                                                            borderTopRightRadius:
                                                              "0px",
                                                            borderBottomRightRadius:
                                                              "0px",
                                                          }}
                                                        >
                                                          {" "}
                                                          Last Inv.
                                                        </label>

                                                        <input
                                                          onKeyDown={(e) =>
                                                            symbolsrate.includes(
                                                              e.key
                                                            ) &&
                                                            e.preventDefault()
                                                          }
                                                          type="number"
                                                          className="input-inventory"
                                                          value={
                                                            data.least_inventory
                                                          }
                                                          name="least_inventory"
                                                          onChange={(e) =>
                                                            handleChangeDynamic(
                                                              i,
                                                              "least_inventory",
                                                              e.target.value
                                                            )
                                                          }
                                                          onFocus={(e) =>
                                                            e.target.addEventListener(
                                                              "wheel",
                                                              function (e) {
                                                                e.preventDefault();
                                                              },
                                                              { passive: false }
                                                            )
                                                          }
                                                        />

                                                        {data.least_inventory >=
                                                        1 ? (
                                                          <div className="modify-room-sub-section">
                                                            <label
                                                              className="input-group-text input-joint-color rate_new_inv"
                                                              id="inputGroup-sizing-default"
                                                              style={{
                                                                fontSize:
                                                                  "16px",
                                                              }}
                                                            >
                                                              {"+"}
                                                            </label>
                                                            <span className="modify-room-sub-select">
                                                              <span className="modify-rate_section">
                                                                <input
                                                                  onKeyDown={(
                                                                    e
                                                                  ) =>
                                                                    symbolsArr.includes(
                                                                      e.key
                                                                    ) &&
                                                                    e.preventDefault()
                                                                  }
                                                                  type="text"
                                                                  className="form-control"
                                                                  placeholder=""
                                                                  id="emailidInput"
                                                                  value={
                                                                    data.least_inventory_value
                                                                  }
                                                                  name="least_inventory_value"
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleChangeDynamic(
                                                                      i,
                                                                      "least_inventory_value",
                                                                      e.target
                                                                        .value
                                                                    )
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "110px",
                                                                    marginLeft:
                                                                      "38px",
                                                                    marginTop:
                                                                      "-32px",
                                                                  }}
                                                                />

                                                                <span
                                                                  className="rate_change_section"
                                                                  style={{
                                                                    width:
                                                                      "55px",
                                                                  }}
                                                                >
                                                                  <select
                                                                    id="ForminputState"
                                                                    className="form-select"
                                                                    value={
                                                                      data.least_inventory_amount_type
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleDynamicSelect(
                                                                        i,
                                                                        "least_inventory_amount_type",
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    }
                                                                  >
                                                                    <option
                                                                      value="currency"
                                                                      selected=""
                                                                    >
                                                                      {
                                                                        currencyname.code
                                                                      }
                                                                    </option>
                                                                    <option value="percentage">
                                                                      %
                                                                    </option>
                                                                  </select>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </span>
                                                    </div>
                                                    <div
                                                      className="new_inv_field_room"
                                                      style={{
                                                        marginTop: "-30px",
                                                        marginLeft: "4px",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "12px",
                                                          fontWeight: "400",
                                                          marginLeft: "-7px",
                                                        }}
                                                      >
                                                        You can reduce the rate
                                                        when the total inventory
                                                        count is higher.
                                                      </span>
                                                      <span className="new_inv_field_sub_room">
                                                        <label
                                                          className="input-group-text input-joint-color"
                                                          id="inputGroup-sizing-default"
                                                          style={{
                                                            height: "32px",
                                                            borderTopRightRadius:
                                                              "0px",
                                                            borderBottomRightRadius:
                                                              "0px",
                                                          }}
                                                        >
                                                          Max.Inv.{" "}
                                                        </label>

                                                        <input
                                                          onKeyDown={(e) =>
                                                            symbolsrate.includes(
                                                              e.key
                                                            ) &&
                                                            e.preventDefault()
                                                          }
                                                          type="number"
                                                          className="input-inventory"
                                                          value={
                                                            data.most_inventory
                                                          }
                                                          name="most_inventory"
                                                          onChange={(e) =>
                                                            handleChangeDynamic(
                                                              i,
                                                              "most_inventory",
                                                              e.target.value
                                                            )
                                                          }
                                                          onFocus={(e) =>
                                                            e.target.addEventListener(
                                                              "wheel",
                                                              function (e) {
                                                                e.preventDefault();
                                                              },
                                                              { passive: false }
                                                            )
                                                          }
                                                        />

                                                        {data.most_inventory >=
                                                        1 ? (
                                                          <div className="modify-room-sub-section">
                                                            <label
                                                              className="input-group-text input-joint-color rate_new_inv"
                                                              id="inputGroup-sizing-default"
                                                              style={{
                                                                fontSize:
                                                                  "20px",
                                                              }}
                                                            >
                                                              {"-"}
                                                            </label>
                                                            <span className="modify-room-sub-select">
                                                              <span className="modify-rate_section">
                                                                <input
                                                                  onKeyDown={(
                                                                    e
                                                                  ) =>
                                                                    symbolsArr.includes(
                                                                      e.key
                                                                    ) &&
                                                                    e.preventDefault()
                                                                  }
                                                                  type="text"
                                                                  className="form-control"
                                                                  placeholder=""
                                                                  id="emailidInput"
                                                                  value={
                                                                    data.most_inventory_value
                                                                  }
                                                                  name="most_inventory_value"
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleChangeDynamic(
                                                                      i,
                                                                      "most_inventory_value",
                                                                      e.target
                                                                        .value
                                                                    )
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "110px",
                                                                    marginLeft:
                                                                      "38px",
                                                                    marginTop:
                                                                      "-32px",
                                                                  }}
                                                                />

                                                                <span
                                                                  className="rate_change_section"
                                                                  style={{
                                                                    width:
                                                                      "55px",
                                                                  }}
                                                                >
                                                                  <select
                                                                    id="ForminputState"
                                                                    className="form-select"
                                                                    value={
                                                                      data.most_inventory_amount_type
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleDynamicSelect(
                                                                        i,
                                                                        "most_inventory_amount_type",
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    }
                                                                  >
                                                                    <option
                                                                      value="currency"
                                                                      selected=""
                                                                    >
                                                                      {
                                                                        currencyname.code
                                                                      }
                                                                    </option>
                                                                    <option value="percentage">
                                                                      %
                                                                    </option>
                                                                  </select>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    );
                                  })}
                                  {roomtype.map((data, i) => {
                                    if (
                                      data.value !== basicroom?.value &&
                                      !dynamicroomrate.some(
                                        (dynamicData) =>
                                          dynamicData?.room?.value ===
                                          data.value
                                      )
                                    ) {
                                      counter += 1;
                                    }
                                    return (
                                      <>
                                        {data.value !== basicroom?.value &&
                                        !dynamicroomrate.some(
                                          (dynamicData) =>
                                            dynamicData?.room?.value ===
                                            data?.value
                                        ) ? (
                                          <div
                                            className="row"
                                            key={i}
                                            style={{ marginTop: "25px" }}
                                          >
                                            <div className="col-xxl-12 col-sm-12 modify-room-sec">
                                              <ul className="modify-room-sub-sec">
                                                <li>
                                                  <div className=" modify-room-sub-sec-list_1">
                                                    <div
                                                      className="modify-room-sub-no"
                                                      style={{ height: "20px" }}
                                                    >
                                                      {counter}
                                                    </div>
                                                    <div className="modify-room-sub-title_new">
                                                      {data.label} (ROOM ID:
                                                      {data.room_key})
                                                    </div>
                                                    <div
                                                      className="new_field_base_rate"
                                                      style={{
                                                        marginTop: "-6px",
                                                      }}
                                                    >
                                                      <div className="new_field_sub_base_rate">
                                                        <div
                                                          className="modify-room-sub-input"
                                                          style={{
                                                            marginRight: "-1px",
                                                          }}
                                                        >
                                                          <select
                                                            id="ForminputState"
                                                            className="form-select"
                                                            name="operator"
                                                            value={
                                                              data.operator
                                                            }
                                                            onChange={(e) =>
                                                              handleChangeInv(
                                                                i,
                                                                "operator",
                                                                e.target.value
                                                              )
                                                            }
                                                            style={{
                                                              marginRight:
                                                                "25px",
                                                            }}
                                                          >
                                                            <option
                                                              value="+"
                                                              selected
                                                            >
                                                              +
                                                            </option>
                                                            <option value="-">
                                                              -
                                                            </option>
                                                          </select>
                                                        </div>
                                                        <div className="modify-room-sub-section">
                                                          <span className="modify-room-sub-select">
                                                            <span className="modify-rate_section">
                                                              <input
                                                                onKeyDown={(
                                                                  e
                                                                ) =>
                                                                  symbolsArr.includes(
                                                                    e.key
                                                                  ) &&
                                                                  e.preventDefault()
                                                                }
                                                                type="text"
                                                                className="form-control"
                                                                placeholder=""
                                                                id="emailidInput"
                                                                name="rate"
                                                                value={
                                                                  data.rate
                                                                }
                                                                onChange={(e) =>
                                                                  handleChangeInv(
                                                                    i,
                                                                    "rate",
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                              />
                                                              {formerror7.map(
                                                                (err, ind) => {
                                                                  return (
                                                                    <>
                                                                      {i ===
                                                                        ind &&
                                                                      err &&
                                                                      err.value ? (
                                                                        <label
                                                                          style={{
                                                                            color:
                                                                              "red",
                                                                          }}
                                                                        >
                                                                          {
                                                                            err.value
                                                                          }
                                                                        </label>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                    </>
                                                                  );
                                                                }
                                                              )}
                                                              <span className="rate_change_section">
                                                                <select
                                                                  id="ForminputState"
                                                                  className="form-select"
                                                                  name="increment_type"
                                                                  value={
                                                                    data.increment_type
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleChangeInv(
                                                                      i,
                                                                      "increment_type",
                                                                      e.target
                                                                        .value
                                                                    )
                                                                  }
                                                                >
                                                                  <option
                                                                    value="currency"
                                                                    selected
                                                                  >
                                                                    {
                                                                      currencyname.code
                                                                    }
                                                                  </option>
                                                                  <option value="percentage">
                                                                    %
                                                                  </option>
                                                                </select>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="new_inv_field_room"
                                                      style={{
                                                        marginTop: "-30px",
                                                        marginLeft: "4px",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "12px",
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        You can increase rate
                                                        when total inventory
                                                        count is very less.
                                                      </span>
                                                      <span className="new_inv_field_sub_room">
                                                        <label
                                                          className="input-group-text input-joint-color"
                                                          id="inputGroup-sizing-default"
                                                          style={{
                                                            height: "32px",
                                                            borderTopRightRadius:
                                                              "0px",
                                                            borderBottomRightRadius:
                                                              "0px",
                                                          }}
                                                        >
                                                          {" "}
                                                          Last Inv.
                                                        </label>
                                                        <input
                                                          onKeyDown={(e) =>
                                                            symbolsrate.includes(
                                                              e.key
                                                            ) &&
                                                            e.preventDefault()
                                                          }
                                                          type="number"
                                                          className="input-inventory"
                                                          name="least_inventory"
                                                          value={
                                                            data.least_inventory
                                                          }
                                                          onChange={(e) =>
                                                            handleChangeInv(
                                                              i,
                                                              "least_inventory",
                                                              e.target.value
                                                            )
                                                          }
                                                          onFocus={(e) =>
                                                            e.target.addEventListener(
                                                              "wheel",
                                                              function (e) {
                                                                e.preventDefault();
                                                              },
                                                              { passive: false }
                                                            )
                                                          }
                                                        />
                                                        {data.least_inventory >=
                                                        1 ? (
                                                          <div className="modify-room-sub-section">
                                                            <label
                                                              className="input-group-text input-joint-color rate_new_inv"
                                                              id="inputGroup-sizing-default"
                                                              style={{
                                                                fontSize:
                                                                  "16px",
                                                              }}
                                                            >
                                                              {"+"}
                                                            </label>
                                                            <span className="modify-room-sub-select">
                                                              <span className="modify-rate_section">
                                                                <input
                                                                  onKeyDown={(
                                                                    e
                                                                  ) =>
                                                                    symbolsArr.includes(
                                                                      e.key
                                                                    ) &&
                                                                    e.preventDefault()
                                                                  }
                                                                  type="text"
                                                                  className="form-control"
                                                                  placeholder=""
                                                                  id="emailidInput"
                                                                  name="least_inventory_value"
                                                                  value={
                                                                    data.least_inventory_value
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleChangeInv(
                                                                      i,
                                                                      "least_inventory_value",
                                                                      e.target
                                                                        .value
                                                                    )
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "110px",
                                                                    marginLeft:
                                                                      "38px",
                                                                    marginTop:
                                                                      "-32px",
                                                                  }}
                                                                />

                                                                <span
                                                                  className="rate_change_section"
                                                                  style={{
                                                                    width:
                                                                      "55px",
                                                                  }}
                                                                >
                                                                  <select
                                                                    id="ForminputState"
                                                                    className="form-select"
                                                                    name="least_inventory_amount_type"
                                                                    value={
                                                                      data.least_inventory_amount_type
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleChangeInv(
                                                                        i,
                                                                        "least_inventory_amount_type",
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    }
                                                                  >
                                                                    <option
                                                                      value="currency"
                                                                      selected
                                                                    >
                                                                      {
                                                                        currencyname.code
                                                                      }
                                                                    </option>
                                                                    <option value="percentage">
                                                                      %
                                                                    </option>
                                                                  </select>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </span>
                                                    </div>
                                                    <div
                                                      className="new_inv_field_room"
                                                      style={{
                                                        marginTop: "-30px",
                                                        marginLeft: "4px",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "12px",
                                                          fontWeight: "400",
                                                          marginLeft: "-7px",
                                                        }}
                                                      >
                                                        You can reduce the rate
                                                        when the total inventory
                                                        count is higher.
                                                      </span>
                                                      <span className="new_inv_field_sub_room">
                                                        <label
                                                          className="input-group-text input-joint-color"
                                                          id="inputGroup-sizing-default"
                                                          style={{
                                                            height: "32px",
                                                            borderTopRightRadius:
                                                              "0px",
                                                            borderBottomRightRadius:
                                                              "0px",
                                                          }}
                                                        >
                                                          Max.Inv.{" "}
                                                        </label>

                                                        <input
                                                          onKeyDown={(e) =>
                                                            symbolsrate.includes(
                                                              e.key
                                                            ) &&
                                                            e.preventDefault()
                                                          }
                                                          type="number"
                                                          className="input-inventory"
                                                          name="most_inventory"
                                                          value={
                                                            data.most_inventory
                                                          }
                                                          onChange={(e) =>
                                                            handleChangeInv(
                                                              i,
                                                              "most_inventory",
                                                              e.target.value
                                                            )
                                                          }
                                                          onFocus={(e) =>
                                                            e.target.addEventListener(
                                                              "wheel",
                                                              function (e) {
                                                                e.preventDefault();
                                                              },
                                                              { passive: false }
                                                            )
                                                          }
                                                        />
                                                        {data.most_inventory >=
                                                        1 ? (
                                                          <div className="modify-room-sub-section">
                                                            <label
                                                              className="input-group-text input-joint-color rate_new_inv"
                                                              id="inputGroup-sizing-default"
                                                              style={{
                                                                fontSize:
                                                                  "20px",
                                                              }}
                                                            >
                                                              {"-"}
                                                            </label>
                                                            <span className="modify-room-sub-select">
                                                              <span className="modify-rate_section">
                                                                <input
                                                                  onKeyDown={(
                                                                    e
                                                                  ) =>
                                                                    symbolsArr.includes(
                                                                      e.key
                                                                    ) &&
                                                                    e.preventDefault()
                                                                  }
                                                                  type="text"
                                                                  className="form-control"
                                                                  placeholder=""
                                                                  id="emailidInput"
                                                                  name="most_inventory_value"
                                                                  value={
                                                                    data.most_inventory_value
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleChangeInv(
                                                                      i,
                                                                      "most_inventory_value",
                                                                      e.target
                                                                        .value
                                                                    )
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "110px",
                                                                    marginLeft:
                                                                      "38px",
                                                                    marginTop:
                                                                      "-32px",
                                                                  }}
                                                                />

                                                                <span
                                                                  className="rate_change_section"
                                                                  style={{
                                                                    width:
                                                                      "55px",
                                                                  }}
                                                                >
                                                                  <select
                                                                    id="ForminputState"
                                                                    className="form-select"
                                                                    name="most_inventory_amount_type"
                                                                    value={
                                                                      data.most_inventory_amount_type
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleChangeInv(
                                                                        i,
                                                                        "most_inventory_amount_type",
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    }
                                                                  >
                                                                    <option
                                                                      value="currency"
                                                                      selected
                                                                    >
                                                                      {
                                                                        currencyname.code
                                                                      }
                                                                    </option>
                                                                    <option value="percentage">
                                                                      %
                                                                    </option>
                                                                  </select>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    );
                                  })}
                                </div>
                                <div className="modify-period-submit">
                                  <button
                                    type="button"
                                    className="btn db-save-button"
                                    onClick={BulkupdateDynamic}
                                  >
                                    Save
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        
                         : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row project-wrapper price-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-availability-type">
                  <div className="card availabilty-filter-card">
                    <div className="card-header">
                      <div className="row align-items-center gy-3">
                        <div className="price-filter-sec">
                          <form>
                            <div className="row g-3">
                              <div className="col-xxl-1 col-sm-6 col-md-1">
                                <h5 className="card-title db-title-header filter-head fw-medium">
                                  Filter
                                </h5>
                              </div>
                              <div className="col-md-3 extrabed-rates-sec">
                                <MultiSelect
                                  options={newRoom}
                                  value={selectedRoomsFilter}
                                  onChange={setselectedRoomsFilter}
                                  labelledBy="Select"
                                  selectionType="counter"
                                  allowCustomValue={true}
                                />
                              </div>
                              <div className=" col-md-2 extrabed-rates-sec">
                                <MultiSelect
                                  options={ratelist}
                                  value={selectedRateFilter}
                                  onChange={setselectedRateFilter}
                                  labelledBy="Select"
                                  selectionType="counter"
                                  allowCustomValue={true}
                                />
                              </div>
                              <div className=" col-md-2  extrabed-rates-sec">
                                <MultiSelect
                                  options={board}
                                  value={selectedBoardFilter}
                                  onChange={setselectedBoardFilter}
                                  labelledBy="Select"
                                  selectionType="counter"
                                  allowCustomValue={true}
                                />
                              </div>
                              <div className="col-md-2 extrabed-rates-sec">
                                <MultiSelect
                                  options={occupancy}
                                  value={selectedOccupancyFilter}
                                  onChange={setselectedOccupancyFilter}
                                  labelledBy="Select"
                                  selectionType="counter"
                                  allowCustomValue={true}
                                />
                              </div>
                              <div className="col-xxl-1 col-md-1 col-sm-4">
                                <button
                                  type="button"
                                  className="btn db-common-filter"
                                  onClick={Filter}
                                  style={{
                                    color: "white",
                                    backgroundColor: "black",
                                  }}
                                >
                                  <span className="bx bx-filter-alt"></span>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="card-header price-bottom-header">
                      <div className="row available_filter_calender">
                        <span className="col-xxl-6 available_filter_calender_sec">
                          {dateString1 === todaydate ? (
                            ""
                          ) : (
                            <button
                              type="button"
                              title="Previous month"
                              aria-pressed="false"
                              className="fc-prev-button btn btn-availability-arrow"
                              onClick={handleClickPrev}
                            >
                              <span className="fa fa-chevron-left"></span>
                            </button>
                          )}
                          <input
                            type="text"
                            value={`${dateString1} - ${dateString2}`}
                            style={{
                              marginTop: "10px",
                              border: "0px",
                              width: "200px",
                            }}
                          />
                          <button
                            type="button"
                            title="Next month"
                            aria-pressed="false"
                            className="fc-next-button btn btn-availability-arrow"
                            onClick={handleClickNext}
                          >
                            <span className="fa fa-chevron-right"></span>
                          </button>
                          <span className="calendar-show-count">
                            {" "}
                            A total of
                            <span className="calendar-date-count">
                              {" "}
                              {arr.length < columnsDisplayed - 4
                                ? arr.length
                                : columnsDisplayed - 4}{" "}
                              days
                            </span>{" "}
                            are displayed on this screen
                          </span>
                        </span>
                        <span className="col-xxl-6 available_update_btn">
                          <button
                            type="button"
                            className="btn db-save-button"
                            onClick={Singlesave}
                          >
                            Save
                          </button>
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <div className="table-responsive">
                        <table className="table room-price-table table-bordered table-nowrap align-middle mb-0">
                          <thead>
                            <tr>
                              <th scope="col" className="stickyclass">
                                <p
                                  className="room-available-filtersec-head"
                                  style={{ width: "200px" }}
                                >
                                  Rooms
                                </p>
                              </th>
                              {arr.map((user, index) => {
                                return (
                                  <th
                                    scope="col"
                                    key={index}
                                    className="th-element"
                                  >
                                    <div
                                      className="room-avail-date"
                                      style={{ width: "70px" }}
                                    >
                                      <span className="avail-month-name">
                                        {dayjs(user).format("MMM")}
                                      </span>
                                      <br></br>
                                      <span className="avail-date-bold">
                                        {dayjs(user).format("DD")}
                                      </span>
                                      <br></br>
                                      <span className="avail-week-name">
                                        {dayjs(user).format("ddd")}
                                      </span>
                                      <br></br>
                                    </div>
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          {loading === true ? (
                            <>
                              {rate.length > 0 ? (
                                rate.map((data, i) => {
                                  return (
                                    <tbody key={i}>
                                      <tr>
                                        <th
                                          colSpan={val + 1}
                                          className="price-inner-table stickyclass"
                                        >
                                          <table className="table room-price-inner-table table-bordered table-nowrap align-middle mb-0">
                                            <tbody>
                                              <tr>
                                                <td colSpan={val + 1}>
                                                  <h6 className="price-sec-room-title stickyclass_11">
                                                    <span
                                                      className="room-default-expand"
                                                      id="room1"
                                                      onClick={(e) =>
                                                        handleClickroom(i)
                                                      }
                                                    >
                                                      <span className="room-price-expanded ri-arrow-down-s-line text-dark"></span>
                                                      {data.room_name}
                                                    </span>
                                                    <span className="default_price_show">
                                                      <ul
                                                        className="price_show_select"
                                                        id="price_show_type"
                                                      >
                                                        {tab.map((ta, idx) => {
                                                          return (
                                                            <div key={idx}>
                                                              {i === idx ? (
                                                                <>
                                                                  <li
                                                                    className={
                                                                      ta.tab ===
                                                                      "default"
                                                                        ? "active"
                                                                        : ""
                                                                    }
                                                                    data-value="default"
                                                                    onClick={(
                                                                      e
                                                                    ) =>
                                                                      handleTab(
                                                                        "default",
                                                                        idx
                                                                      )
                                                                    }
                                                                  >
                                                                    Default View
                                                                  </li>
                                                                  <li
                                                                    className={
                                                                      ta.tab ===
                                                                      "extend"
                                                                        ? "active"
                                                                        : ""
                                                                    }
                                                                    data-value="extend"
                                                                    onClick={(
                                                                      e
                                                                    ) =>
                                                                      handleTab(
                                                                        "extend",
                                                                        idx
                                                                      )
                                                                    }
                                                                  >
                                                                    Extended
                                                                    View
                                                                  </li>
                                                                </>
                                                              ) : (
                                                                ""
                                                              )}
                                                            </div>
                                                          );
                                                        })}
                                                      </ul>
                                                    </span>
                                                    <input
                                                      type="hidden"
                                                      name="list_value"
                                                      id="li_change_value"
                                                      value="default"
                                                    />
                                                    <input
                                                      type="hidden"
                                                      name="list_room_id"
                                                      id="list_room_id"
                                                      value="room1"
                                                    />
                                                  </h6>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </th>
                                      </tr>
                                      {data.ratelists.length > 0 ? (
                                        <>
                                          {tab[i]?.click ? (
                                            <>
                                              {tab[i]?.tab === "extend" ? (
                                                <>
                                                  {data.ratelists.map(
                                                    (rate, ind) => {
                                                      return (
                                                        <>
                                                          {rate.hotel_board.map(
                                                            (board, indx) => {
                                                              return (
                                                                <>
                                                                  {board.occupancies.map(
                                                                    (
                                                                      occupancies,
                                                                      index
                                                                    ) => {
                                                                      return (
                                                                        <tr
                                                                          key={
                                                                            indx
                                                                          }
                                                                          className="room-price-expanded-sec"
                                                                          id="room1_default"
                                                                        >
                                                                          <th
                                                                            className="stickyclass"
                                                                            style={{
                                                                              textAlign:
                                                                                "left",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              className="price-td-rates"
                                                                              style={{
                                                                                paddingLeft:
                                                                                  "20px",
                                                                              }}
                                                                            >
                                                                              {
                                                                                rate.internal_name
                                                                              }{" "}
                                                                              (
                                                                              {
                                                                                currency.code
                                                                              }
                                                                              )
                                                                            </span>
                                                                            <span className="price-td-boards">
                                                                              {" "}
                                                                              {
                                                                                board.name
                                                                              }
                                                                            </span>
                                                                            <span
                                                                              className="price-td-occupancy-in"
                                                                              style={{
                                                                                float:
                                                                                  "right",
                                                                                paddingRight:
                                                                                  "15px",
                                                                              }}
                                                                            >
                                                                              {occupancies.adult +
                                                                                occupancies.child >
                                                                                4 && (
                                                                                <>
                                                                                  {occupancies.adult >
                                                                                    0 && (
                                                                                    <>
                                                                                      <img
                                                                                        src="images/icon/user.png"
                                                                                        alt="Adult"
                                                                                        style={{
                                                                                          paddingLeft:
                                                                                            "1px",
                                                                                        }}
                                                                                      />

                                                                                      (
                                                                                      {
                                                                                        occupancies.adult
                                                                                      }

                                                                                      )
                                                                                    </>
                                                                                  )}{" "}
                                                                                  {
                                                                                    "x"
                                                                                  }
                                                                                  {occupancies.child >
                                                                                    0 && (
                                                                                    <>
                                                                                      <img
                                                                                        src="images/icon/user.png"
                                                                                        alt="Child"
                                                                                        className="child-icon"
                                                                                        style={{
                                                                                          marginLeft:
                                                                                            "2px",
                                                                                        }}
                                                                                      />

                                                                                      (
                                                                                      {
                                                                                        occupancies.child
                                                                                      }

                                                                                      )
                                                                                    </>
                                                                                  )}
                                                                                </>
                                                                              )}

                                                                              {occupancies.adult +
                                                                                occupancies.child <=
                                                                                4 && (
                                                                                <>
                                                                                  {Array.from(
                                                                                    {
                                                                                      length:
                                                                                        occupancies.adult,
                                                                                    },
                                                                                    (
                                                                                      _,
                                                                                      index
                                                                                    ) => (
                                                                                      <img
                                                                                        key={`adult-${index}`}
                                                                                        src="images/icon/user.png"
                                                                                        alt="Adult"
                                                                                        style={{
                                                                                          paddingLeft:
                                                                                            "1px",
                                                                                        }}
                                                                                      />
                                                                                    )
                                                                                  )}
                                                                                  {Array.from(
                                                                                    {
                                                                                      length:
                                                                                        occupancies.child,
                                                                                    },
                                                                                    (
                                                                                      _,
                                                                                      index
                                                                                    ) => (
                                                                                      <img
                                                                                        key={`child-${index}`}
                                                                                        src="images/icon/user.png"
                                                                                        alt="Child"
                                                                                        className="child-icon"
                                                                                        style={{
                                                                                          marginLeft:
                                                                                            "2px",
                                                                                        }}
                                                                                      />
                                                                                    )
                                                                                  )}
                                                                                </>
                                                                              )}
                                                                            </span>
                                                                          </th>
                                                                          {occupancies.rates.map(
                                                                            (
                                                                              occ,
                                                                              indexx
                                                                            ) => {
                                                                              let saveColorClass;
                                                                              if (
                                                                                saveddata.length >
                                                                                0
                                                                              ) {
                                                                                // const isDesiredAvailability = occ.rate === saveddata[i]?.ratelists[ind]?.hotel_board[indx]?.occupancies[index]?.rates[indexx]?.rate;
                                                                                // saveColorClass = isDesiredAvailability ? '' : 'ch_';
                                                                                const isDesiredAvailability =
                                                                                  saveddata[
                                                                                    i
                                                                                  ]
                                                                                    ?.ratelists[
                                                                                    ind
                                                                                  ]
                                                                                    ?.hotel_board[
                                                                                    indx
                                                                                  ]
                                                                                    ?.occupancies[
                                                                                    index
                                                                                  ]
                                                                                    ?.rates[
                                                                                    indexx
                                                                                  ]
                                                                                    ?.flag &&
                                                                                  saveddata[
                                                                                    i
                                                                                  ]
                                                                                    ?.ratelists[
                                                                                    ind
                                                                                  ]
                                                                                    ?.hotel_board[
                                                                                    indx
                                                                                  ]
                                                                                    ?.occupancies[
                                                                                    index
                                                                                  ]
                                                                                    ?.rates[
                                                                                    indexx
                                                                                  ]
                                                                                    ?.flag ===
                                                                                    true;
                                                                                saveColorClass =
                                                                                  isDesiredAvailability
                                                                                    ? "ch_"
                                                                                    : "";
                                                                              }

                                                                              // Convert the number to a string and split it by the decimal point
                                                                              let displayRate;
                                                                              if (
                                                                                occ.rate !==
                                                                                null
                                                                              ) {
                                                                                const rateString =
                                                                                  occ.rate.toString();
                                                                                const [
                                                                                  integerPart,
                                                                                  decimalPart,
                                                                                ] =
                                                                                  rateString.split(
                                                                                    "."
                                                                                  );

                                                                                // Check if decimal part consists of only zeros
                                                                                displayRate =
                                                                                  decimalPart &&
                                                                                  parseFloat(
                                                                                    decimalPart
                                                                                  ) !==
                                                                                    0
                                                                                    ? occ.rate
                                                                                    : parseInt(
                                                                                        integerPart,
                                                                                        10
                                                                                      );
                                                                              } else {
                                                                                displayRate =
                                                                                  occ.rate;
                                                                              }
                                                                              return (
                                                                                <td
                                                                                  key={
                                                                                    indexx
                                                                                  }
                                                                                  className="room-price-td"
                                                                                >
                                                                                  <input
                                                                                    onKeyDown={(
                                                                                      e
                                                                                    ) =>
                                                                                      symbolsArr.includes(
                                                                                        e.key
                                                                                      ) &&
                                                                                      e.preventDefault()
                                                                                    }
                                                                                    type="number"
                                                                                    className={`${saveColorClass} form-control`}
                                                                                    value={
                                                                                      displayRate
                                                                                    }
                                                                                    name="rate"
                                                                                    onChange={(
                                                                                      e
                                                                                    ) =>
                                                                                      handleInputChange(
                                                                                        i,
                                                                                        ind,
                                                                                        indx,
                                                                                        index,
                                                                                        indexx,
                                                                                        e
                                                                                          .target
                                                                                          .value
                                                                                      )
                                                                                    }
                                                                                    style={{
                                                                                      width:
                                                                                        "65px",
                                                                                    }}
                                                                                    onFocus={(
                                                                                      e
                                                                                    ) =>
                                                                                      e.target.addEventListener(
                                                                                        "wheel",
                                                                                        function (
                                                                                          e
                                                                                        ) {
                                                                                          e.preventDefault();
                                                                                        },
                                                                                        {
                                                                                          passive: false,
                                                                                        }
                                                                                      )
                                                                                    }
                                                                                  />
                                                                                </td>
                                                                              );
                                                                            }
                                                                          )}
                                                                          {/* {occupancies.rates.map((occ, indexx) => {
                                                                let saveColorClass;
                                                                if(saveddata.length>0){
                                                                  // const isDesiredAvailability = occ.rate === saveddata[i]?.ratelists[ind]?.hotel_board[indx]?.occupancies[index]?.rates[indexx]?.rate;
                                                                  // saveColorClass = isDesiredAvailability ? '' : 'ch_';
                                                                  const isDesiredAvailability = saveddata[i]?.ratelists[ind]?.hotel_board[indx]?.occupancies[index]?.rates[indexx]?.flag && saveddata[i]?.ratelists[ind]?.hotel_board[indx]?.occupancies[index]?.rates[indexx]?.flag===true;
                                                                  saveColorClass = isDesiredAvailability ? 'ch_' : '';

                                                                  }
                                                                return (
                                                                  <td key={indexx} className="room-price-td">
                                                                    <input onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                                    type="number" className={` ${saveColorClass} form-control`} value={occ.rate?occ.rate:""} name="rate" 
                                                                      onChange={(e) => handleInputChange(i, ind, indx, index, indexx, e.target.value)} style={{width:"65px"}}/>
                                                                  </td>
                                                                );
                                                              })} */}
                                                                        </tr>
                                                                      );
                                                                    }
                                                                  )}
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                          <tr className="span_separate"></tr>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  {data.ratelists.map(
                                                    (rate, ind) => {
                                                      return (
                                                        <>
                                                          {rate.hotel_board.map(
                                                            (board, indx) => {
                                                              return (
                                                                <tr
                                                                  key={indx}
                                                                  className="room-price-expanded-sec"
                                                                  id="room1_default"
                                                                >
                                                                  {board.occupancies.map(
                                                                    (
                                                                      occupancy,
                                                                      index
                                                                    ) => {
                                                                      return (
                                                                        <>
                                                                          {index ===
                                                                          0 ? (
                                                                            <>
                                                                              <th
                                                                                className="stickyclass"
                                                                                style={{
                                                                                  textAlign:
                                                                                    "left",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  className="price-td-rates"
                                                                                  style={{
                                                                                    paddingLeft:
                                                                                      "20px",
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    rate.internal_name
                                                                                  }{" "}
                                                                                  (
                                                                                  {
                                                                                    currency.code
                                                                                  }

                                                                                  )
                                                                                </span>
                                                                                <span className="price-td-boards">
                                                                                  {" "}
                                                                                  {
                                                                                    board.name
                                                                                  }
                                                                                </span>
                                                                                <span
                                                                                  className="price-td-occupancy-in"
                                                                                  style={{
                                                                                    float:
                                                                                      "right",
                                                                                    paddingRight:
                                                                                      "15px",
                                                                                  }}
                                                                                >
                                                                                  {occupancy.adult +
                                                                                    occupancy.child >
                                                                                    4 && (
                                                                                    <>
                                                                                      {occupancy.adult >
                                                                                        0 && (
                                                                                        <>
                                                                                          <img
                                                                                            src="images/icon/user.png"
                                                                                            alt="Adult"
                                                                                            style={{
                                                                                              paddingLeft:
                                                                                                "1px",
                                                                                            }}
                                                                                          />

                                                                                          (
                                                                                          {
                                                                                            occupancy.adult
                                                                                          }

                                                                                          )
                                                                                        </>
                                                                                      )}{" "}
                                                                                      {
                                                                                        "x"
                                                                                      }
                                                                                      {occupancy.child >
                                                                                        0 && (
                                                                                        <>
                                                                                          <img
                                                                                            src="images/icon/user.png"
                                                                                            alt="Child"
                                                                                            className="child-icon"
                                                                                            style={{
                                                                                              marginLeft:
                                                                                                "2px",
                                                                                            }}
                                                                                          />

                                                                                          (
                                                                                          {
                                                                                            occupancy.child
                                                                                          }

                                                                                          )
                                                                                        </>
                                                                                      )}
                                                                                    </>
                                                                                  )}

                                                                                  {occupancy.adult +
                                                                                    occupancy.child <=
                                                                                    4 && (
                                                                                    <>
                                                                                      {Array.from(
                                                                                        {
                                                                                          length:
                                                                                            occupancy.adult,
                                                                                        },
                                                                                        (
                                                                                          _,
                                                                                          index
                                                                                        ) => (
                                                                                          <img
                                                                                            key={`adult-${index}`}
                                                                                            src="images/icon/user.png"
                                                                                            alt="Adult"
                                                                                            style={{
                                                                                              paddingLeft:
                                                                                                "1px",
                                                                                            }}
                                                                                          />
                                                                                        )
                                                                                      )}
                                                                                      {Array.from(
                                                                                        {
                                                                                          length:
                                                                                            occupancy.child,
                                                                                        },
                                                                                        (
                                                                                          _,
                                                                                          index
                                                                                        ) => (
                                                                                          <img
                                                                                            key={`child-${index}`}
                                                                                            src="images/icon/user.png"
                                                                                            alt="Child"
                                                                                            className="child-icon"
                                                                                            style={{
                                                                                              marginLeft:
                                                                                                "2px",
                                                                                            }}
                                                                                          />
                                                                                        )
                                                                                      )}
                                                                                    </>
                                                                                  )}
                                                                                </span>
                                                                              </th>

                                                                              {occupancy.rates.map(
                                                                                (
                                                                                  occ,
                                                                                  indexx
                                                                                ) => {
                                                                                  let saveColorClass;
                                                                                  if (
                                                                                    saveddata.length >
                                                                                    0
                                                                                  ) {
                                                                                    // const isDesiredAvailability = occ.rate === saveddata[i]?.ratelists[ind]?.hotel_board[indx]?.occupancies[index]?.rates[indexx]?.rate;
                                                                                    // saveColorClass = isDesiredAvailability ? '' : 'ch_';
                                                                                    const isDesiredAvailability =
                                                                                      saveddata[
                                                                                        i
                                                                                      ]
                                                                                        ?.ratelists[
                                                                                        ind
                                                                                      ]
                                                                                        ?.hotel_board[
                                                                                        indx
                                                                                      ]
                                                                                        ?.occupancies[
                                                                                        index
                                                                                      ]
                                                                                        ?.rates[
                                                                                        indexx
                                                                                      ]
                                                                                        ?.flag &&
                                                                                      saveddata[
                                                                                        i
                                                                                      ]
                                                                                        ?.ratelists[
                                                                                        ind
                                                                                      ]
                                                                                        ?.hotel_board[
                                                                                        indx
                                                                                      ]
                                                                                        ?.occupancies[
                                                                                        index
                                                                                      ]
                                                                                        ?.rates[
                                                                                        indexx
                                                                                      ]
                                                                                        ?.flag ===
                                                                                        true;
                                                                                    saveColorClass =
                                                                                      isDesiredAvailability
                                                                                        ? "ch_"
                                                                                        : "";
                                                                                  }

                                                                                  // Convert the number to a string and split it by the decimal point
                                                                                  let displayRate;
                                                                                  if (
                                                                                    occ.rate !==
                                                                                    null
                                                                                  ) {
                                                                                    const rateString =
                                                                                      occ.rate.toString();
                                                                                    const [
                                                                                      integerPart,
                                                                                      decimalPart,
                                                                                    ] =
                                                                                      rateString.split(
                                                                                        "."
                                                                                      );

                                                                                    // Check if decimal part consists of only zeros
                                                                                    displayRate =
                                                                                      decimalPart &&
                                                                                      parseFloat(
                                                                                        decimalPart
                                                                                      ) !==
                                                                                        0
                                                                                        ? occ.rate
                                                                                        : parseInt(
                                                                                            integerPart,
                                                                                            10
                                                                                          );
                                                                                  } else {
                                                                                    displayRate =
                                                                                      occ.rate;
                                                                                  }
                                                                                  return (
                                                                                    <td
                                                                                      key={
                                                                                        indexx
                                                                                      }
                                                                                      className="room-price-td"
                                                                                    >
                                                                                      <input
                                                                                        onKeyDown={(
                                                                                          e
                                                                                        ) =>
                                                                                          symbolsArr.includes(
                                                                                            e.key
                                                                                          ) &&
                                                                                          e.preventDefault()
                                                                                        }
                                                                                        type="number"
                                                                                        className={`${saveColorClass} form-control`}
                                                                                        value={
                                                                                          displayRate
                                                                                        }
                                                                                        name="rate"
                                                                                        onChange={(
                                                                                          e
                                                                                        ) =>
                                                                                          handleInputChange(
                                                                                            i,
                                                                                            ind,
                                                                                            indx,
                                                                                            index,
                                                                                            indexx,
                                                                                            e
                                                                                              .target
                                                                                              .value
                                                                                          )
                                                                                        }
                                                                                        style={{
                                                                                          width:
                                                                                            "65px",
                                                                                        }}
                                                                                        onFocus={(
                                                                                          e
                                                                                        ) =>
                                                                                          e.target.addEventListener(
                                                                                            "wheel",
                                                                                            function (
                                                                                              e
                                                                                            ) {
                                                                                              e.preventDefault();
                                                                                            },
                                                                                            {
                                                                                              passive: false,
                                                                                            }
                                                                                          )
                                                                                        }
                                                                                      />
                                                                                    </td>
                                                                                  );
                                                                                }
                                                                              )}
                                                                            </>
                                                                          ) : (
                                                                            ""
                                                                          )}
                                                                        </>
                                                                      );
                                                                    }
                                                                  )}
                                                                </tr>
                                                              );
                                                            }
                                                          )}
                                                          <tr className="span_separate"></tr>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        <tr>
                                          <td
                                            colSpan={100}
                                            className="rate_col_clr"
                                          >
                                            Please add the rate plan to the{" "}
                                            <b>{data.room_name}</b> at the{" "}
                                            <b>{hotelname}</b> Hotel.
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td
                                    style={{ textAlign: "center" }}
                                    colSpan={columnsDisplayed - 3}
                                  >
                                    No data found
                                  </td>
                                </tr>
                              )}
                            </>
                          ) : (
                            <tr>
                              <td
                                style={{ textAlign: "center" }}
                                colSpan={columnsDisplayed - 3}
                              >
                                <Loading />
                              </td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          id="flipModal"
          className="new_modal"
          isOpen={modal_board_add}
          toggle={() => {
            tog_animationFlip1();
          }}
          modalClassName="flip"
          centered
          style={{
            fontFamily: "IBM Plex Sans,sans-serif",
            justifyContent: "center",
          }}
        >
          <ModalBody
            className="modal-body text-center"
            style={{ width: "650px" }}
          >
            <div className="tab-pane active" id="boardRate-tab" role="tabpanel">
              <div
                className="row price-modify-filter"
                style={{ marginTop: "20px" }}
              >
                <form>
                  <button
                    type="button"
                    className="btn btn-link link-success fw-medium text-decoration-none"
                    onClick={() => {
                      tog_animationFlip1();
                    }}
                    style={{ float: "right", bottom: "30px" }}
                  >
                    {" "}
                    <i
                      className="ri-close-line me-1 align-middle"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </button>
                  <div className="col-md-6" style={{ marginLeft: "15%" }}>
                    <div className="input-group">
                      <label
                        className="input-group-text input-joint-color"
                        id="inputGroup-sizing-default"
                      >
                        Meal Boards:
                      </label>
                      <div className="col-md-6">
                        <MultiSelect
                          options={mealboard_new}
                          // options={mealboard_new.filter(
                          //   (option) =>
                          //     !mealboard.some(
                          //       (mealOption) =>
                          //         mealOption.value === option.value
                          //     )
                          // )}

                          value={selectBoardadd}
                          onChange={(val) => {
                            setSelectBoardadd(val);
                          }}
                          labelledBy="Select"
                          className="multiselect_board"
                          selectionType="counter"
                          allowCustomValue={true}
                        />
                      </div>
                    </div>
                  </div>
                </form>
                {selectBoardadd.length > 0 ? (
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className="price-board-rate-head"
                        style={{ marginTop: "25px" }}
                      >
                        <h6>Adult</h6>
                      </div>

                      {selectBoardadd.map((data, i) => {
                        return (
                          <>
                            <div
                              key={i}
                              className="col-lg-3"
                              style={{ width: "70%", marginLeft: "15%" }}
                            >
                              <div className="input-group">
                                <label
                                  className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default"
                                >
                                  {data.label}
                                </label>

                                <input
                                  onKeyDown={(e) =>
                                    symbolsArr.includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  type="number"
                                  className="form-control board-price-text"
                                  aria-label="Sizing example input"
                                  aria-describedby="inputGroup-sizing-default"
                                  name="adult_price"
                                  value={selectBoardadd.adult_price}
                                  onChange={(e) =>
                                    handleMealBoardChange(
                                      i,
                                      "adult_price",
                                      e.target.value
                                    )
                                  }
                                  onFocus={(e) =>
                                    e.target.addEventListener(
                                      "wheel",
                                      function (e) {
                                        e.preventDefault();
                                      },
                                      { passive: false }
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <br></br>
                          </>
                        );
                      })}
                    </div>
                    <div className="col-md-6">
                      <div
                        className="price-board-rate-head"
                        style={{ marginTop: "25px" }}
                      >
                        <h6>Child</h6>
                      </div>
                      {selectBoardadd.map((data, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className="col-lg-3"
                              style={{ width: "70%", marginLeft: "15%" }}
                            >
                              <div className="input-group">
                                <label
                                  className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default"
                                >
                                  {data.label}
                                </label>
                                <input
                                  onKeyDown={(e) =>
                                    symbolsArr.includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  type="number"
                                  className="form-control board-price-text"
                                  aria-label="Sizing example input"
                                  aria-describedby="inputGroup-sizing-default"
                                  name="child_price"
                                  value={selectBoardadd.child_price}
                                  onChange={(e) =>
                                    handleMealBoardChange(
                                      index,
                                      "child_price",
                                      e.target.value
                                    )
                                  }
                                  onFocus={(e) =>
                                    e.target.addEventListener(
                                      "wheel",
                                      function (e) {
                                        e.preventDefault();
                                      },
                                      { passive: false }
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <br></br>
                          </>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <button
                type="button"
                className="btn db-save-button"
                onClick={MealBoardAdd}
              >
                Save
              </button>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          id="flipModal"
          className="new_modal"
          isOpen={modal_board_modify}
          toggle={() => {
            tog_animationFlip2();
          }}
          modalClassName="flip"
          centered
          style={{
            fontFamily: "IBM Plex Sans,sans-serif",
            justifyContent: "center",
          }}
        >
          <ModalBody
            className="modal-body text-center"
            style={{ width: "750px" }}
          >
            <div className="tab-pane active" id="boardRate-tab" role="tabpanel">
              <div className="row price-modify-filter">
                <form>
                  <div className="price-top-section">
                    <button
                      type="button"
                      className="btn btn-link link-success fw-medium text-decoration-none"
                      onClick={() => {
                        tog_animationFlip2();
                      }}
                      style={{ float: "right", bottom: "30px" }}
                    >
                      {" "}
                      <i
                        className="ri-close-line me-1 align-middle"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </button>
                    <div className="col-md-10" style={{ marginTop: "10px" }}>
                      <div className="input-group" style={{ width: "750px" }}>
                        <label
                          className="input-group-text input-joint-color"
                          id="inputGroup-sizing-default"
                        >
                          Meal Boards:
                        </label>
                        <div className="col-md-8">
                          <MultiSelect
                            options={mealboard}
                            value={selectBoardChange}
                            onChange={(val) => {
                              setSelectBoardChange(val);
                            }}
                            labelledBy="Select"
                            selectionType="counter"
                            allowCustomValue={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="price-top-section">
                      <div className="row g-3">
                        <div className="col-xxl-12 col-md-12 col-sm-12">
                          <div className="price-board-rate-head">
                            <div className="row">
                              {selectBoardChange.length > 0 ? (
                                <>
                                  <div
                                    className="col-md-1"
                                    style={{ top: "35px" }}
                                  >
                                    <h6>Adult:</h6>
                                  </div>
                                  <div className="row" style={{ left: "10px" }}>
                                    {selectBoardChange.map((data, i) => {
                                      const rateString =
                                        data.adult_price.toString();
                                      const [integerPart, decimalPart] =
                                        rateString.split(".");
                                      const displayRate =
                                        decimalPart &&
                                        parseFloat(decimalPart) !== 0
                                          ? data.adult_price
                                          : parseInt(integerPart, 10);

                                      return (
                                        <>
                                          <div
                                            key={i}
                                            className="col-md-4"
                                            style={{
                                              marginTop: "3px",
                                              marginLeft: "40px",
                                            }}
                                          >
                                            <div
                                              className="input-group"
                                              style={{ width: "200px" }}
                                            >
                                              <label
                                                className="input-group-text input-joint-color"
                                                id="inputGroup-sizing-default"
                                              >
                                                {data.label}
                                              </label>

                                              <input
                                                onKeyDown={(e) =>
                                                  symbolsArr.includes(e.key) &&
                                                  e.preventDefault()
                                                }
                                                type="number"
                                                className="form-control board-price-text"
                                                aria-label="Sizing example input"
                                                aria-describedby="inputGroup-sizing-default"
                                                name="adult_price"
                                                value={displayRate}
                                                onChange={(e) =>
                                                  handleMealBoardChangePop(
                                                    i,
                                                    "adult_price",
                                                    e.target.value
                                                  )
                                                }
                                                onFocus={(e) =>
                                                  e.target.addEventListener(
                                                    "wheel",
                                                    function (e) {
                                                      e.preventDefault();
                                                    },
                                                    { passive: false }
                                                  )
                                                }
                                              />
                                            </div>
                                            {formerrorPop.map((err, index) => {
                                              return (
                                                <>
                                                  {index === i &&
                                                  err &&
                                                  err.adult_price ? (
                                                    <label
                                                      style={{ color: "red" }}
                                                    >
                                                      {err.adult_price}
                                                    </label>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              );
                                            })}
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="price-board-rate-head">
                            <div className="row">
                              {selectBoardChange.length > 0 ? (
                                <>
                                  <div
                                    className="col-md-1"
                                    style={{ top: "40px" }}
                                  >
                                    <h6>Child:</h6>
                                  </div>
                                  <div className="row" style={{ left: "10px" }}>
                                    {selectBoardChange.map((data, index) => {
                                      const rateString =
                                        data.child_price.toString();
                                      const [integerPart, decimalPart] =
                                        rateString.split(".");
                                      const displayRate =
                                        decimalPart &&
                                        parseFloat(decimalPart) !== 0
                                          ? data.child_price
                                          : parseInt(integerPart, 10);

                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className="col-md-4"
                                            style={{
                                              marginTop: "7px",
                                              marginLeft: "40px",
                                            }}
                                          >
                                            <div
                                              className="input-group"
                                              style={{ width: "200px" }}
                                            >
                                              <label
                                                className="input-group-text input-joint-color"
                                                id="inputGroup-sizing-default"
                                              >
                                                {data.label}
                                              </label>
                                              <input
                                                onKeyDown={(e) =>
                                                  symbolsArr.includes(e.key) &&
                                                  e.preventDefault()
                                                }
                                                type="number"
                                                className="form-control board-price-text"
                                                aria-label="Sizing example input"
                                                aria-describedby="inputGroup-sizing-default"
                                                name="child_price"
                                                value={displayRate}
                                                onChange={(e) =>
                                                  handleMealBoardChangePop(
                                                    index,
                                                    "child_price",
                                                    e.target.value
                                                  )
                                                }
                                                onFocus={(e) =>
                                                  e.target.addEventListener(
                                                    "wheel",
                                                    function (e) {
                                                      e.preventDefault();
                                                    },
                                                    { passive: false }
                                                  )
                                                }
                                              />
                                            </div>
                                            {formerrorPop.map((err, i) => {
                                              return (
                                                <>
                                                  {index === i &&
                                                  err &&
                                                  err.child_price ? (
                                                    <label
                                                      style={{ color: "red" }}
                                                    >
                                                      {err.child_price}
                                                    </label>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              );
                                            })}
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row g-3">
                      <div
                        className="price-board-rate-head"
                        style={{ right: "46%", marginTop: "25px" }}
                      >
                        <h6>Adult</h6>
                      </div>

                      {mealboard.map((data, i) => {
                        return (
                          <>
                            <div
                              key={i}
                              className="col-lg-3"
                              style={{ width: "32%" }}
                            >
                              <div className="input-group">
                                <label
                                  className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default"
                                >
                                  {data.label}
                                </label>

                                <input
                                  onKeyDown={(e) =>
                                    symbolsArr.includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  type="number"
                                  className="form-control board-price-text"
                                  aria-label="Sizing example input"
                                  aria-describedby="inputGroup-sizing-default"
                                  name="adult_price"
                                  value={mealboardpop.adult_price}
                                  onChange={(e) =>
                                    handleMealBoardChangePop(
                                      i,
                                      "adult_price",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              {formerrorPop1.map((err, ind) => {
                                return (
                                  <>
                                    {ind === i && err && err.adult_price ? (
                                      <label style={{ color: "red" }}>
                                        {err.adult_price}
                                      </label>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </>
                        );
                      })}

                      <div
                        className="price-board-rate-head"
                        style={{ right: "46%", marginTop: "30px" }}
                      >
                        <h6>Child</h6>
                      </div>

                      {mealboard.map((data, index) => {
                        return (
                          <div
                            key={index}
                            className="col-lg-3"
                            style={{ width: "32%" }}
                          >
                            <div className="input-group">
                              <label
                                className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default"
                              >
                                {data.label}
                              </label>
                              <input
                                onKeyDown={(e) =>
                                  symbolsArr.includes(e.key) &&
                                  e.preventDefault()
                                }
                                type="number"
                                className="form-control board-price-text"
                                aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-default"
                                name="child_price"
                                value={mealboardpop.child_price}
                                onChange={(e) =>
                                  handleMealBoardChangePop(
                                    index,
                                    "child_price",
                                    e.target.value
                                  )
                                }
                              />
                            </div>

                            {formerrorPop1.map((err, i) => {
                              return (
                                <>
                                  {index === i && err && err.child_price ? (
                                    <label style={{ color: "red" }}>
                                      {err.child_price}
                                    </label>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div> */}
                  </div>
                  {selectBoardChange.length > 0 ? (
                    <div className="row">
                      <div className="col-md-4 form-calender-add">
                        <form action="#">
                          <div className="input-group">
                            <Flatpickr
                              className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                              placeholder="FROM"
                              id="flatpickr-from"
                              options={{
                                mode: "single",
                                dateFormat: "d M, Y",
                                minDate: "today",
                              }}
                              value={fromdatepop}
                              onChange={CalendarChangeFromPop}
                            />
                            <label style={{ color: "red" }}>
                              {formerrorPop1.fromdatepop}
                            </label>
                            <span
                              className="form_calendar-style fon mdi mdi-calendar-month-outline"
                              onClick={() => handleCalendarIconClick("from")}
                            ></span>
                          </div>
                        </form>
                      </div>
                      <div className="col-md-4 form-calender-add">
                        <form action="#">
                          <div className="input-group">
                            <Flatpickr
                              className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                              placeholder="TO"
                              id="flatpickr-to"
                              options={{
                                mode: "single",
                                dateFormat: "d M, Y",
                                minDate: "today",
                              }}
                              value={todatepop}
                              onChange={CalendarChangeToPop}
                            />
                            <label style={{ color: "red" }}>
                              {formerrorPop1.todatepop}
                            </label>
                            <span
                              className="form_calendar-style fon mdi mdi-calendar-month-outline"
                              onClick={() => handleCalendarIconClick("to")}
                            ></span>
                          </div>
                        </form>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div style={{ marginTop: "10px" }}>
                    <button
                      type="button"
                      className="btn db-save-button"
                      onClick={BulkupdatemealBoard}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          id="flipModal"
          className="new_modal"
          isOpen={modal_board_change}
          toggle={() => {
            tog_animationFlip3();
          }}
          modalClassName="flip"
          centered
          style={{
            fontFamily: "IBM Plex Sans,sans-serif",
            justifyContent: "center",
          }}
        >
          <ModalBody
            className="modal-body text-center"
            style={{ width: "750px" }}
          >
            <div className="tab-pane active" id="boardRate-tab" role="tabpanel">
              <div className="row price-modify-filter">
                <form>
                  <div className="price-top-section">
                    <button
                      type="button"
                      className="btn btn-link link-success fw-medium text-decoration-none"
                      onClick={() => {
                        tog_animationFlip3();
                      }}
                      style={{ float: "right", bottom: "30px" }}
                    >
                      {" "}
                      <i
                        className="ri-close-line me-1 align-middle"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </button>
                    <div className="col-md-10" style={{ marginTop: "10px" }}>
                      <div className="input-group" style={{ width: "750px" }}>
                        <label
                          className="input-group-text input-joint-color"
                          id="inputGroup-sizing-default"
                        >
                          Meal Boards:
                        </label>
                        <div className="col-md-8">
                          <MultiSelect
                            options={mealboard}
                            value={boardChange}
                            onChange={(val) => {
                              setBoardChange(val);
                            }}
                            labelledBy="Select"
                            selectionType="counter"
                            allowCustomValue={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="price-top-section">
                      <div className="row g-3">
                        <div className="col-xxl-12 col-md-12 col-sm-12">
                          <div className="price-board-rate-head">
                            <div className="row">
                              {boardChange.length > 0 ? (
                                <>
                                  <div
                                    className="col-md-1"
                                    style={{ top: "35px" }}
                                  >
                                    <h6>Adult:</h6>
                                  </div>
                                  <div className="row" style={{ left: "10px" }}>
                                    {boardChange.map((data, i) => {
                                      const rateString =
                                        data.adult_price.toString();
                                      const [integerPart, decimalPart] =
                                        rateString.split(".");
                                      const displayRate =
                                        decimalPart &&
                                        parseFloat(decimalPart) !== 0
                                          ? data.adult_price
                                          : parseInt(integerPart, 10);

                                      return (
                                        <>
                                          <div
                                            key={i}
                                            className="col-md-4"
                                            style={{
                                              marginTop: "3px",
                                              marginLeft: "40px",
                                            }}
                                          >
                                            <div
                                              className="input-group"
                                              style={{ width: "200px" }}
                                            >
                                              <label
                                                className="input-group-text input-joint-color"
                                                id="inputGroup-sizing-default"
                                              >
                                                {data.label}
                                              </label>

                                              <input
                                                onKeyDown={(e) =>
                                                  symbolsArr.includes(e.key) &&
                                                  e.preventDefault()
                                                }
                                                type="number"
                                                className="form-control board-price-text"
                                                aria-label="Sizing example input"
                                                aria-describedby="inputGroup-sizing-default"
                                                name="adult_price"
                                                value={displayRate}
                                                onChange={(e) =>
                                                  boardChangePop(
                                                    i,
                                                    "adult_price",
                                                    e.target.value
                                                  )
                                                }
                                                onFocus={(e) =>
                                                  e.target.addEventListener(
                                                    "wheel",
                                                    function (e) {
                                                      e.preventDefault();
                                                    },
                                                    { passive: false }
                                                  )
                                                }
                                              />
                                            </div>
                                            {formerrorPop.map((err, index) => {
                                              return (
                                                <>
                                                  {index === i &&
                                                  err &&
                                                  err.adult_price ? (
                                                    <label
                                                      style={{ color: "red" }}
                                                    >
                                                      {err.adult_price}
                                                    </label>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              );
                                            })}
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="price-board-rate-head">
                            <div className="row" style={{ left: "10px" }}>
                              {boardChange.length > 0 ? (
                                <>
                                  <div
                                    className="col-md-1"
                                    style={{ top: "40px" }}
                                  >
                                    <h6>Child:</h6>
                                  </div>
                                  <div className="row">
                                    {boardChange.map((data, index) => {
                                      const rateString =
                                        data.child_price.toString();
                                      const [integerPart, decimalPart] =
                                        rateString.split(".");
                                      const displayRate =
                                        decimalPart &&
                                        parseFloat(decimalPart) !== 0
                                          ? data.child_price
                                          : parseInt(integerPart, 10);

                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className="col-md-4"
                                            style={{
                                              marginTop: "7px",
                                              marginLeft: "40px",
                                            }}
                                          >
                                            <div
                                              className="input-group"
                                              style={{ width: "200px" }}
                                            >
                                              <label
                                                className="input-group-text input-joint-color"
                                                id="inputGroup-sizing-default"
                                              >
                                                {data.label}
                                              </label>
                                              <input
                                                onKeyDown={(e) =>
                                                  symbolsArr.includes(e.key) &&
                                                  e.preventDefault()
                                                }
                                                type="number"
                                                className="form-control board-price-text"
                                                aria-label="Sizing example input"
                                                aria-describedby="inputGroup-sizing-default"
                                                name="child_price"
                                                value={displayRate}
                                                onChange={(e) =>
                                                  boardChangePop(
                                                    index,
                                                    "child_price",
                                                    e.target.value
                                                  )
                                                }
                                                onFocus={(e) =>
                                                  e.target.addEventListener(
                                                    "wheel",
                                                    function (e) {
                                                      e.preventDefault();
                                                    },
                                                    { passive: false }
                                                  )
                                                }
                                              />
                                            </div>
                                            {formerrorPop.map((err, i) => {
                                              return (
                                                <>
                                                  {index === i &&
                                                  err &&
                                                  err.child_price ? (
                                                    <label
                                                      style={{ color: "red" }}
                                                    >
                                                      {err.child_price}
                                                    </label>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              );
                                            })}
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <button
                      type="button"
                      className="btn db-save-button"
                      onClick={MealBoardChange}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          id="flipModal"
          isOpen={modal_animationFlip}
          toggle={() => {
            tog_animationFlip();
          }}
          modalClassName="flip"
          centered
          style={{ fontFamily: "IBM Plex Sans,sans-serif" }}
        >
          <ModalBody
            className="modal-body text-center"
            style={{ height: "150px" }}
          >
            <div className="restr_policy_expand" style={{ marginTop: "10px" }}>
              "This hotel has no boards created. To proceed, please create hotel
              boards. Press the
              <span style={{ color: "#008080" }}> 'Click'</span> button to
              proceed with the creation."
            </div>

            <button
              className="board_create_click"
              style={{ marginTop: "20px" }}
            >
              <Link to="/board-plan"> Click </Link>
            </button>
          </ModalBody>
        </Modal>

        <Modal
          id="flipModal"
          isOpen={import_pop}
          toggle={() => {
            ImportPopup();
          }}
          modalClassName="flip"
          centered
          style={{ fontFamily: "IBM Plex Sans,sans-serif" }}
        >
          <ModalBody
            className="modal-body text-center"
          >
            {importstatus===false?
            <>
            <div className="restr_policy_expand" style={{ marginTop: "10px" }}>
             Please add the rate for the rate plan
            </div>
            {/* <button
              className="offer_disable_pop_cancel" onClick={()=>setImport_pop(false)}>
             Cancel
            </button> */}
             <button
                className="btn btn-link fw-medium text-decoration-none"
                style={{ color: "#FF7979" }}
                onClick={()=>setImport_pop(false)}
              >
                {" "}
                <i className="ri-close-line me-1 align-middle"></i>
                Close{" "}
              </button>
            <button className="offer_disable_pop_yes" onClick={()=>setImport_pop(false)}>
             Ok
            </button>
            </>
            :
            <>
              <div className="col-md-12" style={{marginTop:"5%"}}>
                  <input
                    type="file"
                    className="form-control"
                    placeholder=""
                    id="lastNameinput"
                    onChange={onFileChange}
                  
                  />
                  <label style={{ color: "red" }}>
                      {formerrorimport.importFile}
                    </label>
                
                <div style={{textAlign:"left",marginBottom:"5%"}}>
                <a href="/import-xl-sample-attached.xlsx" className="dynamicrate_import_dwnlod" download>
                    Click here to get a sample file
                </a> 
                </div>
               </div>
               {loadingimport && (
                <div className="d-flex justify-content-center my-3 custom-spinner">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
                )}
              <button
                className="btn btn-link fw-medium text-decoration-none"
                style={{ color: "#FF7979" }}
                onClick={()=>setImport_pop(false)}
              >
                {" "}
                <i className="ri-close-line me-1 align-middle"></i>
                Close{" "}
              </button>
                {importFile?
                <Button
                  style={{
                    backgroundColor: "rgb(51 ,190,51)",
                    border: "rgb(51 ,190,51)",
                  }}
                  onClick={handleUploadClick}>
                  {" "}
                  Upload{" "}
                </Button>
                :
                <Button
                  style={{
                    backgroundColor: "rgb(51 ,190,51)",
                    border: "rgb(51 ,190,51)",
                  }}
                  onClick={DynamicRateImport}>
                  {" "}
                  Upload{" "}
                </Button>
                }
                </>
                } 
          </ModalBody>
        </Modal>

        {toastmodal === true ? (
          <Toastmodal
            setToast={setToastmodal}
            message={toastmessage}
            type={toasttype}
            large={toastlarge}
          />
        ) : (
          ""
        )}

        {mealboard_delete ? (
          <DeleteModal
            modal_animationFlip={mealboard_delete}
            tog_animationFlip={tog_mealboard_delete}
            pageid={pageid}
            DeleteData={DeleteMealboard}
            message={"Board rate"}
          />
        ) : (
          ""
        )}

        {extrabedshow ? (
          <Extrabedlist
            extrabedshow={extrabedshow}
            setExtrabedshow={setExtrabedshow}
            boardallrate={boardallrate}
            extrabedratecurrency={extrabedratecurrency}
            tabextra={tabextra}
            setTabextra={setTabextra}
          />
        ) : (
          ""
        )}
        <Footer />
      </div>
    </>
  );
}

export default Rates;
