import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../Common/Footer";
import Reservationheader from "../../Common/Reservationheader";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Config from "../../Config";
import Toastmodal from "../../Common/Toastmodal";
import Loading from "../../Loading";

function Revisionsingle() {

    let {hotel_id, reservation_id} = useParams();
    let navigate = useNavigate();

  const [list, setList] = useState(null);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [toastmodal, setToastmodal] = useState(false);
  const [revisionStatus, setRevisionStatus] = useState([]);
  const [loader, setLoader] = useState(false);
  const [error,setError] = useState({});
  const [ip, setIp] = useState("");
  const [is_availability_released,setIs_availability_released]=useState(false);
  const [filter, setFilter] = useState({
    hotel: false,
    user: false,
    external: false,
  });

  const handleloyaltyToggle = (isChecked, name) => {
    setFilter({ ...filter, [name]: isChecked });
  };

  const handleCommentsChange = (name, value) => {
    const updatedList = {...list};
    updatedList[name] = value;
    setList(updatedList);
  };

  const fetchPost = async () => {
    const res = await fetch(`${Config.apiurl}revision/view`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: hotel_id,
        reservation_id:reservation_id
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setList(data.data);
        setRevisionStatus(data.data.revision_status);
        setLoader(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        fetchPost();
      } else {
        setLoader(true);
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      fetchPost();
    } else {
      console.log("error");
    }
  };
  
  const Save = async (e) => {
    e.preventDefault();

    let isFormValid = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    const errors = {};
    if(revisionStatus==="cancelled" || revisionStatus==="no_show" || revisionStatus==="modified"){
    if (!list.comments) {
      isFormValid = false;
      errors.comments = "Comments is required!";
    }
  }
  if(revisionStatus==="modified"){
    if(!list.revised_amount){
      isFormValid = false;
      errors.revised_amount= "Amount is required";
    }
  }
  if(revisionStatus==="cancelled"){
    if(!list.reason){
      isFormValid = false;
      errors.reason= "Reason is required";
    }
    
  }
  if(revisionStatus==="cancelled" && filter.external===true){
    if(!list.external_email){
      isFormValid = false;
      errors.external_email= "Email is required";
    } 
    else if (!regex.test(list.external_email)) {
      isFormValid = false;
      errors.external_email = "This is not a valid email format!";
    }
  }
  setError(errors);

  if(isFormValid===true){
    let comment;
    if(revisionStatus==="cancelled" || revisionStatus==="no_show" || revisionStatus ==="modified"){
      comment=list.comments;
    }

    let cancelReason;
    if(revisionStatus==="cancelled"){
      cancelReason=list.reason;
    }

    let userEmail;
    if(revisionStatus==="cancelled"){
      userEmail=filter.user;
    }

    let hotelEmail;
    if(revisionStatus==="cancelled"){
      hotelEmail=filter.hotel;
    }

    let external;
    if(revisionStatus==="cancelled"){
      external=filter.external;
    }
    
    let externalEmail;
    if(revisionStatus==="cancelled" && filter.external===true){
      externalEmail=list.external_email;
    }

    let amount;
    if(revisionStatus==="modified"){
      amount=list.revised_amount;
    }

      const res = await fetch(`${Config.apiurl}revision/submit`,{
          method: "POST",
          body: JSON.stringify({
            hotel_id: hotel_id,
            reservation_id:reservation_id,
            revision_status:revisionStatus,
            revision_comments:comment,
            revised_amount:amount,
            cancel_reason:cancelReason,
            is_availability_released:is_availability_released,
            send_to_customers_mail:userEmail,
            send_to_hotel_mail:hotelEmail,
            send_to_external_email:external,
            external_mail:externalEmail,
            ip_address:ip
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== 200) {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else if (data.reauth === true) {
          handleUnauthorized();
          Save();
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Save();
      } else {
        console.log("error");
      }
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
   
        const response = await fetch(
          "https://widgets.thedirectrate.com/api/ips"
        );
     
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
  
        setIp(data.ip);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    fetchPost();
  }, []);

  return (
    <div className="main-content">
      <Reservationheader />
      <div className="page-content reservation-page-content">
        <div className="">
          {loader===false?
          <Loading/>
          :
          <div className="project-wrapper">
            <div className="row">
               <div className="col-xxl-12 col-sm-12">
              <div className="modify-period-submit">
              <button type="button" className="btn revision_interim_button" onClick={Save}>
                Save
              </button>
              </div>
            </div>
            </div>
              <div className="reservation-main-sec">
               
                <div className="row">
                  <div className="col-xxl-12">
                    <div
                      style={{ overflowX:"auto" }}
                    >
                      <table className="table reservation-list-table reservation-main-table-style">
                        <tr>
                          <th
                            className="serial-number-new"
                            title="Code"
                          >
                            Code
                          </th>
                          <th className="new-head" title="Hotel Name">
                            Hotel Name
                          </th>
                          <th
                            className="new-head"
                            title="Reserved Date"
                          >
                            Reserved Date
                          </th>
                          <th className="new-head" title="Status">
                            Status
                          </th>
                          <th
                            className="new-head"
                            title="Promotional/Agency/Company Code"
                          >
                            Promotional/Agency..
                          </th>
                          <th
                            className="new-head"
                            title="Customer Name"
                          >
                            Customer Name
                          </th>
                          <th className="new-head" title="Check in">
                            Check in
                          </th>
                          <th className="new-head" title="Check out">
                            Check out
                          </th>
                          <th className="new-head" title="Type">
                            Type
                          </th>
                          <th className="new-head" title="No.of Rooms">
                            No.of Rooms
                          </th>
                          
                          <th className="new-head" title="Subtotal">
                            Subtotal
                          </th>
                          <th className="new-head" title="Total">
                            Total
                          </th>
                        </tr>
                          <tr>
                            <td rowSpan="2"
                              className="serial-number-new-td"
                              title={list?.reservation_key}
                            >
                              {list?.reservation_key}
                            </td>
                            <td
                              className="scrollable-cols"
                              title={list?.hotel_name}
                            >
                              {list?.hotel_name}
                            </td>
                            <td
                              className="scrollable-cols"
                              title={list?.reservation_date}
                            >
                              {list?.reservation_date}
                            </td>

                            <td className="tb-head">
                              <select
                                className="form-select"
                                name="condition"
                                style={{
                                  width: "120px",
                                  marginLeft: "5px",
                                  height: "30px",
                                  padding: "5px",
                                }}
                                value={revisionStatus}
                                onChange={(e)=>setRevisionStatus(e.target.value)}
                              >
                                <option value="not_revised">
                                  Not Revised
                                </option>
                                <option value="cancelled">
                                  Cancelled
                                </option>
                                <option value="correct">
                                  Correct
                                </option>
                                <option value="modified">
                                  Modified
                                </option>
                                <option value="no_show">
                                  No Show
                                </option>
                                <option value="repeated">
                                  Repeated
                                </option>
                              </select>
                            </td>
                          
                            <td
                              className="scrollable-cols"
                              title={list?.promocode}
                            >
                              {list?.promocode}
                            </td>
                            <td
                              className="scrollable-cols"
                              title={`${list?.customer_first_name} ${list?.customer_last_name}`}
                            >
                              {list?.customer_first_name}{" "}
                              {list?.customer_last_name}
                            </td>
                            <td
                              className="scrollable-cols"
                              title={list?.check_in}
                            >
                              {list?.check_in}
                            </td>
                            <td
                              className="scrollable-cols"
                              title={list?.check_out}
                            >
                              {list?.check_out}
                            </td>
                            <td
                              className="scrollable-cols"
                              style={{ width: "150px" }}
                            >
                              {list?.room_types?.map(
                                (room, ind) => {
                                  return (
                                    <span
                                      key={ind}
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                      title={room.name}
                                    >
                                      {room.name}
                                      {ind <
                                        list?.room_types.length -
                                          1 && ","}
                                      <br />
                                    </span>
                                  );
                                }
                              )}
                            </td>
                            <td
                              className="scrollable-cols"
                              title={list?.no_of_rooms}
                            >
                              {list?.no_of_rooms}
                            </td>

                            <td
                              className="scrollable-cols"
                              title={list?.sub_total}
                            >
                              {list?.sub_total} {list?.currency.code}
                            </td>
                            <td
                              className="scrollable-cols"
                              title={list?.total}
                            >
                              {list?.total} {list?.currency.code}
                            </td>
                          </tr>
                          {revisionStatus==="cancelled" || revisionStatus==="modified"
                              || revisionStatus==="no_show" ?
                          <tr>
                            <td colspan="10" class="tb-head">
                             
                              Comments:
                              <input type="text" className="revision_comment_box" 
                                value={list?.comments} 
                                onChange={(e) => handleCommentsChange('comments' ,e.target.value)}
                                />
                               <label style={{ color: "red"}} >{error.comments}</label>
                                
                              {revisionStatus==="cancelled"?
                              <>
                              <div>
                               Cancel Reason:<span class="info-tag-new">i</span>
                               <span class="tooltiptext-new">The reason you entered will be the content of the email sent</span>
                              <input type="text" className="revision_cancel_reason" 
                                value={list?.reason} 
                                onChange={(e) => handleCommentsChange('reason' ,e.target.value)}
                                />
                                <label style={{ color: "red"}} >{error.reason}</label>

                            </div>
                            <div className="d-flex justify-content-center">
                            <ul className="modify-open-conditon-list">
                            {revisionStatus==="cancelled" ?
                            <li style={{border:"none"}}>
                            <div
                              className="email-title_revision"
                              style={{ color: "black" }}
                            >
                             Release Availability
                            </div>

                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="formCheck1"
                              value={is_availability_released}
                              checked={
                                is_availability_released === true ? true : false
                              }
                              onChange={(e) =>
                                setIs_availability_released(e.target.checked)
                              }
                              style={{ width: "16px" }}
                            />
                          </li>
                          :""}
                            <li style={{border:"none"}}>
                              <div
                                className="email-title_revision"
                                style={{ color: "black" }}
                              >
                               Sent email to the Hotel
                              </div>

                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="formCheck1"
                                value={filter.hotel}
                                checked={
                                  filter.hotel === true ? true : false
                                }
                                onChange={(e) =>
                                  handleloyaltyToggle(
                                    e.target.checked,
                                    "hotel"
                                  )
                                }
                                style={{ width: "16px" }}
                              />
                            </li>
                            <li style={{border:"none" }}>
                              <div
                                className="email-title_revision"
                                style={{ color: "black" }}
                              >
                               Sent email to the guest
                              </div>

                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="formCheck1"
                                value={filter.user}
                                checked={
                                  filter.user === true ? true : false
                                }
                                onChange={(e) =>
                                  handleloyaltyToggle(
                                    e.target.checked,
                                    "user"
                                  )
                                }
                                style={{ width: "16px" }}
                              />
                            </li>
                            <li style={{border:"none"}}>
                              <div
                                className="email-title_revision"
                                style={{ color: "black" }}
                              >
                               Sent to external email
                              </div>

                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="formCheck1"
                                value={filter.external}
                                checked={
                                  filter.external === true ? true : false
                                }
                                onChange={(e) =>
                                  handleloyaltyToggle(
                                    e.target.checked,
                                    "external"
                                  )
                                }
                                style={{ width: "16px" }}
                              />
                            </li>
                            </ul>
                            {revisionStatus==="cancelled" && filter.external===true?
                            <span>
                            <input type="text" className="revision_email_external" 
                                placeholder="Enter the email"
                                value={list?.external_email} 
                                onChange={(e) => handleCommentsChange('external_email' ,e.target.value)}
                                />
                                <label style={{ color: "red"}} >{error.external_email}</label>
                            </span>
                            :""}
                            </div>
                            </>
                            :""}
                            </td>
                            {revisionStatus==="cancelled" || revisionStatus==="no_show"?
                            <td colspan="4" class="tb-head">
                              {" "}
                              0 {list?.currency.code}
                            </td>
                            :
                            <td colspan="4" class="tb-head">
                            {" "}
                            New Total : 
                            <input type="number" className="revision_new_total"
                              value={list?.revised_amount} 
                              onChange={(e) => handleCommentsChange('revised_amount' ,e.target.value)}
                              /> {list?.currency.code}
                              <label style={{ color: "red"}} >{error.revised_amount}</label>
                          </td>
                            }
                          </tr>
                          :
                          <tr></tr>
                          }
                           
                      </table>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          }
        </div>
      </div>
      {toastmodal === true ? (
          <Toastmodal
            setToast={setToastmodal}
            message={toastmessage}
            type={toasttype}
            large={toastlarge}
          />
        ) : (
          ""
        )}
      <Footer />
    </div>
  );
}

export default Revisionsingle;
