import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Directrateloyaltyheader from "../Common/Directrateloyaltyheader";
import Footer from "../Common/Footer";
import Toastmodal from "../Common/Toastmodal";
import Config from "../Config";

function Directrateloyalclub() {
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [toastmodal, setToastmodal] = useState(false);
  const [error, setError] = useState({});
  const [id, setId] = useState(localStorage.getItem("selected-hotel-id"));

  const [newLang, setNewLang] = useState("");
  const [language, setLanguage] = useState([]);
  const [multi, setMulti] = useState([]);
  const [isMatchingLanguagenew, setisMatchingLanguagenew] = useState("");
  const [club, setClub] = useState([]);
  const [status, setStatus] = useState(false);

  const getlanguage = async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data.data) {
      console.log("error");
    } else {
      setLanguage(data.data);
    }
  };

  const getview = async (e) => {
    const res = await fetch(`${Config.apiurl}admin/global/loyalty-club/view`, {
      method: "GET",

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data.data) {
      console.log("error");
    } else {
      setClub(data.data);
      if (data.data.multi_lang_key) {
        setMulti(data.data.multi_lang_key);
      }
      if (data.data.status) {
        setStatus(data.data.status);
      }
    }
  };

  function handleChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    setClub({ ...club, [name]: value });
  }

  const handleLanguageChangenew = (selectedOption) => {
    setNewLang(selectedOption);

    const updatedLanguages = [...multi];
    if (!updatedLanguages.some((item) => item.lang_code === selectedOption)) {
      updatedLanguages.push({ lang_code: selectedOption, name: "" });
    }
    setMulti(updatedLanguages);
  };

  const handleloyallangChange = (addonValue) => {
    const updatedList = [...multi];
    const matchingIndex = updatedList.findIndex(
      (language) => language.lang_code === newLang
    );
    if (matchingIndex !== -1) {
      updatedList[matchingIndex]["name"] = addonValue;
    } else {
      const newLanguage = { lang_code: newLang };
      newLanguage["name"] = addonValue;
      updatedList.push(newLanguage);
    }
    setMulti(updatedList);
  };

  const Save = async () => {
    let isFormValid = true;

    const errors = {};
    if (!club.club_name) {
      isFormValid = false;
      errors.club_name = "Club name is required!";
    }
    if (!club.club_condition_url) {
      isFormValid = false;
      errors.club_condition_url = "Condition URL is required!";
    }
    if (!club.club_policy_url) {
      isFormValid = false;
      errors.club_policy_url = "Privacy URL is required!";
    }
    setError(errors);

    if (isFormValid === true) {
      if (club.loyalty_club_id) {
        const formData = new FormData();
        formData.append("hotel_id", id);
        formData.append("status", status);
        formData.append("club_name", club.club_name);
        formData.append("club_condition_url", club.club_condition_url);
        formData.append("privacy_policy_url", club.club_policy_url);

        if (club.club_logo_url) {
          formData.append("club_logo_url", club.club_logo_url);
        }
        let count = 0;
        for (let i = 0; i < multi.length; i++) {
          if (multi[i].name) {
            formData.append(
              `multi_lang[${count}][lang_code]`,
              multi[i].lang_code
            );
            formData.append(`multi_lang[${count}][name]`, multi[i].name);
            count += 1;
          }
        }
        axios
          .post(
            `${Config.apiurl}admin/global/loyalty-club/create`,
            formData,
            {}
          )
          .then((res) => {
            if (res.status_code !== "200" || !res) {
              console.log("error");

              setToastmodal(true);
              setToastmessage(res.message);
              setToasttype("error");
              setToastlarge(true);
              getview();
            } else {
              console.log("success");

              setToastmodal(true);
              setToastmessage(res.message);
              setToasttype("success");
              setToastlarge(true);
              getview();
            }
          });
      } else {
        const formData = new FormData();
        formData.append("status", status);
        formData.append("progrm_name", club.program_name);
        formData.append("club_condition_url", club.club_condition_url);
        formData.append("privacy_policy_url", club.club_policy_url);

        if (club.club_logo_url) {
          formData.append("club_logo_url", club.club_logo_url);
        }
        let count = 0;
        for (let i = 0; i < multi.length; i++) {
          if (multi[i].name) {
            formData.append(
              `multi_lang[${count}][lang_code]`,
              multi[i].lang_code
            );
            formData.append(`multi_lang[${count}][name]`, multi[i].name);
            count += 1;
          }
        }
        axios
          .post(`${Config.apiurl}admin/global/loyalty-club/create`, formData, {})
          .then((res) => {
            if (res.status_code !== "200" || !res) {
              console.log("error");

              setToastmodal(true);
              setToastmessage(res.message);
              setToasttype("error");
              setToastlarge(true);
              getview();
            } else {
              console.log("success");

              setToastmodal(true);
              setToastmessage(res.message);
              setToasttype("success");
              setToastlarge(true);
              getview();
            }
          });
      }
    }
  };

  const Update = async () => {
    let isFormValid = true;

    const errors = {};
    if (!club.club_name) {
      isFormValid = false;
      errors.club_name = "Club name is required!";
    }
    if (!club.club_condition_url) {
      isFormValid = false;
      errors.club_condition_url = "Condition URL is required!";
    }
    if (!club.club_policy_url) {
      isFormValid = false;
      errors.club_policy_url = "Privacy URL is required!";
    }
    setError(errors);

    if (isFormValid === true) {
      if (club.loyalty_club_id) {
        const formData = new FormData();
        formData.append("club_id", club.loyalty_club_id);
        formData.append("status", status);
        formData.append("club_name", club.club_name);
        formData.append("club_condition_url", club.club_condition_url);
        formData.append("privacy_policy_url", club.club_policy_url);

        if (club.club_logo_url) {
          formData.append("club_logo_url", club.club_logo_url);
        }
        let count = 0;
        for (let i = 0; i < multi.length; i++) {
          if (multi[i].name) {
            formData.append(
              `multi_lang[${count}][lang_code]`,
              multi[i].lang_code
            );
            formData.append(`multi_lang[${count}][name]`, multi[i].name);
            count += 1;
          }
        }
        axios
          .post(
            `${Config.apiurl}admin/global/loyalty-club/update`,
            formData,
            {}
          )
          .then((res) => {
            if (res.status_code !== "200" || !res) {
              console.log("error");

              setToastmodal(true);
              setToastmessage(res.message);
              setToasttype("error");
              setToastlarge(true);
              getview();
            } else {
              console.log("success");

              setToastmodal(true);
              setToastmessage(res.message);
              setToasttype("success");
              setToastlarge(true);
              getview();
            }
          });
      } else {
        const formData = new FormData();
        formData.append("hotel_id", id);
        formData.append("status", status);
        formData.append("progrm_name", club.program_name);
        formData.append("club_condition_url", club.club_condition_url);
        formData.append("privacy_policy_url", club.club_policy_url);

        if (club.club_logo_url) {
          formData.append("club_logo_url", club.club_logo_url);
        }
        let count = 0;
        for (let i = 0; i < multi.length; i++) {
          if (multi[i].name) {
            formData.append(
              `multi_lang[${count}][lang_code]`,
              multi[i].lang_code
            );
            formData.append(`multi_lang[${count}][name]`, multi[i].name);
            count += 1;
          }
        }
        axios
          .post(`${Config.apiurl}admin/global/loyalty-club/update`, formData, {})
          .then((res) => {
            if (res.status_code !== "200" || !res) {
              console.log("error");

              setToastmodal(true);
              setToastmessage(res.message);
              setToasttype("error");
              setToastlarge(true);
              getview();
            } else {
              console.log("success");

              setToastmodal(true);
              setToastmessage(res.message);
              setToasttype("success");
              setToastlarge(true);
              getview();
            }
          });
      }
    }
  };

  useEffect(() => {
    getlanguage();
    getview();
  }, []);

  useEffect(() => {
    const isMatching = multi?.findIndex(
      (language) => language.lang_code === newLang
    );
    setisMatchingLanguagenew(isMatching);
  }, [newLang]);

  useEffect(() => {
    if (multi?.length > 0) {
      setNewLang(multi[0].lang_code);
    }
  }, [multi?.length]);

  console.log(status);

  return (
    <div>
      <Directrateloyaltyheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="">
              <div className="card">
                <div className="card-body">
                  <div className="live-preview">
                    <div className="page-title-box  align-items-center justify-content-between">
                      <h4 className="db-page-title">Loyalty Club Settings</h4>

                      <div
                        className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                        dir="ltr"
                        style={{ marginTop: "8%", right: "4%" }}
                      >
                        <label
                          className="form-check-label"
                          htmlFor="inlineswitch5"
                          style={{
                            marginRight: "10px",
                            float: "left",
                            marginTop: "5px",
                          }}
                        >
                          Activate
                        </label>

                        <input
                          type="checkbox"
                          className="form-check-input offer_checkbox1"
                          id="inlineswitch5"
                          style={{
                            width: "50px",
                            height: "25px",
                            marginLeft: "0px",
                            paddingLeft: "12px",
                          }}
                          value={status}
                          checked={status === true ? true : false}
                          onChange={(e) => setStatus(e.target.checked)}
                        />
                      </div>
                    </div>
                    <div
                      className="flex-shrink-0 d-flex justify-content-between"
                      style={{ float: "right" }}
                    >
                      {club?.loyalty_club_id ? (
                        <Link
                          type="button"
                          to="#"
                          className="btn db-save-button"
                          style={{ height: "30px" }}
                          onClick={Update}
                        >
                          Save
                        </Link>
                      ) : (
                        <Link
                          type="button"
                          to="#"
                          className="btn db-save-button"
                          style={{ height: "30px" }}
                          onClick={Save}
                        >
                          Save
                        </Link>
                      )}
                    </div>

                    <div className="be_loyalty_level">
                      <span className="padding-5"></span>
                      <div className="row">
                        <div className="col-md-6">
                          <label
                            for="firstNameinput"
                            className="form-label be_form_label"
                          >
                            Club Name{" "}
                            <span className="form-validation-required">*</span>
                            <div class="info-tag-new">i</div>
                            <span className="tooltiptext-new">
                              Name of the club
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="club_name"
                            value={club.club_name}
                            onChange={handleChange}
                          />
                          <label style={{ color: "red" }}>
                            {error.club_name}
                          </label>
                        </div>

                        <div className="col-md-6">
                          <label
                            for="firstNameinput"
                            className="form-label be_form_label"
                          >
                            Club logo URL{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="club_logo_url"
                            value={club.club_logo_url}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-6">
                          <label
                            for="firstNameinput"
                            className="form-label be_form_label"
                          >
                            Club conditions URL{" "}
                            <span className="form-validation-required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="club_condition_url"
                            value={club.club_condition_url}
                            onChange={handleChange}
                          />
                          <label style={{ color: "red" }}>
                            {error.club_condition_url}
                          </label>
                        </div>

                        <div className="col-md-6">
                          <label
                            for="firstNameinput"
                            className="form-label be_form_label"
                          >
                            Privacy Policy URL{" "}
                            <span className="form-validation-required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="club_policy_url"
                            value={club.club_policy_url}
                            onChange={handleChange}
                          />
                          <label style={{ color: "red" }}>
                            {error.club_policy_url}
                          </label>
                        </div>

                        <div className="col-md-6">
                          <div
                            className="d-flex custom-color"
                            style={{ float: "left" }}
                          >
                            <label
                              className="input-group-text input-joint-color"
                              id="inputGroup-sizing-default"
                              style={{
                                width: "130px",
                                borderBottomRightRadius: "0px",
                                borderTopRightRadius: "0px",
                              }}
                            >
                              Add/edit in
                            </label>
                            <select
                              className="form-control custom-selects"
                              data-choices
                              data-choices-search-false
                              id="idPayment"
                              style={{
                                width: "225px",
                                height: "38px",
                                marginLeft: "-1px",
                              }}
                              value={newLang}
                              onChange={(e) =>
                                handleLanguageChangenew(e.target.value)
                              }
                            >
                              <option>Choose language</option>

                              {language.map((lang, inx) => {
                                return (
                                  <option key={inx} value={lang.code}>
                                    {lang.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        {multi?.length > 0 ? (
                          <>
                            {multi?.some(
                              (item) => item.lang_code === newLang
                            ) ? (
                              <div
                                className="col-md-6 translation-fill-section"
                                data-value="1"
                              >
                                <div className="col-md-12">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Club Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={multi[isMatchingLanguagenew]?.name}
                                      onChange={(e) =>
                                        handleloyallangChange(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="col-md-6 translation-fill-section mt-3"
                                data-value="1"
                              >
                                <div className="col-md-12">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Club Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={multi[multi?.length - 1]?.name}
                                      onChange={(e) =>
                                        handleloyallangChange(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
      <Footer />
    </div>
  );
}

export default Directrateloyalclub;
