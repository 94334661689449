import React from "react";
import { Link } from "react-router-dom";

function Offerstep2({
  setTab,
  offer2room,
  boardoffer2,
  ratelistoffer2,
  activeroom,
  activeboard,
  activerate,
  handleChangeroomoffer2,
  handlesingleroomselect,
  handlesingleoccupancyselect,
  handleChangeboardoffer2,
  handlesingleboardselect,
  handleChangerateoffer2,
  handlesinglerateselect,
  nextoffterstep2,
  offer2error,
  availableboard,
  handlebasedon,
  handleChangeroomoffer2all_label,
  errorroom,
}) {
  console.log(availableboard);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="offer-create-section offer-create-wid">
          <div className="offer-create-details">
            <div className="col-md-12">
              <h6 className="offer-step-desc">
                This section define the rooms, occupancies, meal boards, and
                rate plans to which the offer should be applicable. This ensures
                that the offer is targeted to the desired guest preferences and
                can be easily booked according to the chosen parameters.
              </h6>
              <div className="col-md-12 offer-apply-section">
                <div className="col-md-6 offer-apply-sec">
                  <div className="offer-apply-sub">
                    <h6 className="offer-form-title">Rooms & Occupancies</h6>
                    <div className="offer-form-status-section">
                      <p className="offer-form-label">
                        Choose the rooms and occupancies the offer wants to
                        apply.
                      </p>
                      <div className="offer-condition-check">
                        <ul className="offer-roomtypes">
                          <li>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={activeroom}
                              checked={activeroom === true ? true : false}
                              onChange={(e) =>
                                handleChangeroomoffer2(e.target.checked)
                              }
                            />
                            <label className="form-check-label">All</label>
                          </li>
                          {offer2room.map((rooms, index) => {
                            return (
                              <li key={index}>
                                <input
                                  className="form-check-input room1_check"
                                  type="checkbox"
                                  value={rooms.selected}
                                  checked={
                                    rooms.selected && rooms.selected === true
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    handlesingleroomselect(
                                      index,
                                      e.target.checked
                                    )
                                  }
                                />
                                <label className="form-check-label label-media">
                                  {rooms.label}{" "}
                                  <span className="room_id_colors">
                                    (ROOM ID: {rooms.key})
                                  </span>
                                </label>
                                {rooms.selected === true ? (
                                  <>
                                    <div className="offer_room_wise">
                                      <div className="offer_room_wise_base">
                                        Based on:
                                      </div>
                                      <select
                                        className=""
                                        value={rooms.based_on}
                                        onChange={(e) =>
                                          handlebasedon(index, e.target.value)
                                        }
                                      >
                                        <option>Select..</option>
                                        <option value="per_room">
                                          Per Room
                                        </option>
                                        <option value="per_person">
                                          Per Person
                                        </option>
                                      </select>
                                    </div>
                                    {rooms.based_on === "per_person" ? (
                                      <div className="offer_room_wise_per_person">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={rooms.all_label}
                                          checked={
                                            rooms.all_label === true
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleChangeroomoffer2all_label(
                                              index,
                                              e.target.checked
                                            )
                                          }
                                        />
                                        <label className="form-check-label">
                                          All
                                        </label>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}

                                {errorroom.map((err, i) => {
                                  return (
                                    <>
                                      {index === i && err && err.room_name ? (
                                        <label style={{ color: "red" }}>
                                          {err.room_name}
                                        </label>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                })}
                                {rooms.based_on === "per_person" ? (
                                  <ul
                                    className={
                                      rooms.selected === true
                                        ? "offer-roomtypes-list room1_check_show"
                                        : "offer-roomtypes-list room1_check_show d-none"
                                    }
                                  >
                                    {rooms.occupancies.map((occu, indx) => {
                                      return (
                                        <li key={indx}>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="formCheck1"
                                            value={occu.selected}
                                            checked={
                                              occu.selected &&
                                              occu.selected === true
                                                ? true
                                                : false
                                            }
                                            onChange={(e) =>
                                              handlesingleoccupancyselect(
                                                index,
                                                indx,
                                                e.target.checked
                                              )
                                            }
                                          />

                                          <span className="offer-roomtypes-list-occ">
                                            {occu.adult + occu.child > 4 && (
                                              <>
                                                {occu.adult > 0 && (
                                                  <>
                                                    <img
                                                      src="/images/icon/user.png"
                                                      alt="Adult"
                                                      style={{
                                                        paddingLeft: "1px",
                                                      }}
                                                    />
                                                    ({occu.adult})
                                                  </>
                                                )}
                                                {occu.child > 0 && (
                                                  <>
                                                    {" "}
                                                    X{" "}
                                                    <img
                                                      src="/images/user-color.png"
                                                      alt="Child"
                                                      className="child-icon"
                                                      style={{
                                                        marginLeft: "2px",
                                                      }}
                                                    />
                                                    ({occu.child})
                                                  </>
                                                )}
                                              </>
                                            )}

                                            {occu.adult + occu.child <= 4 && (
                                              <>
                                                {Array.from(
                                                  { length: occu.adult },
                                                  (_, indx) => (
                                                    <img
                                                      key={`adult-${indx}`}
                                                      src="/images/icon/user.png"
                                                      alt="Adult"
                                                      style={{
                                                        paddingLeft: "1px",
                                                      }}
                                                    />
                                                  )
                                                )}
                                                {Array.from(
                                                  { length: occu.child },
                                                  (_, indx) => (
                                                    <img
                                                      key={`child-${indx}`}
                                                      src="/images/user-color.png"
                                                      alt="Child"
                                                      className="child-icon"
                                                      style={{
                                                        marginLeft: "2px",
                                                      }}
                                                    />
                                                  )
                                                )}
                                              </>
                                            )}
                                          </span>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </li>
                            );
                          })}
                          <label style={{ color: "red" }}>
                            {offer2error.room}
                          </label>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 offer-apply-sec">
                  <div className="offer-apply-sub">
                    <h6 className="offer-form-title">Meal Boards</h6>
                    <div className="offer-form-status-section">
                      <p className="offer-form-label">
                        Choose the meal boards the offer wants to apply.
                      </p>
                      <div className="offer-condition-check">
                        <ul className="offer-roomtypes">
                          <li>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={activeboard}
                              checked={activeboard === true ? true : false}
                              onChange={(e) =>
                                handleChangeboardoffer2(e.target.checked)
                              }
                            />
                            <label className="form-check-label">All</label>
                          </li>
                          {boardoffer2.map((board, index) => {
                            return (
                              <li key={index}>
                                {availableboard.some(
                                  (item) => item.id === board.value
                                ) ? (
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={board.selected}
                                    checked={
                                      board.selected && board.selected === true
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      handlesingleboardselect(
                                        index,
                                        e.target.checked
                                      )
                                    }
                                  />
                                ) : (
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={board.selected}
                                    checked={
                                      board.selected && board.selected === true
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      handlesingleboardselect(
                                        index,
                                        e.target.checked
                                      )
                                    }
                                    disabled
                                  />
                                )}
                                <label className="form-check-label">
                                  {board.label}
                                </label>
                              </li>
                            );
                          })}

                          <label style={{ color: "red" }}>
                            {offer2error.board}
                          </label>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 offer-apply-sec">
                  <div className="offer-apply-sub">
                    <h6 className="offer-form-title">Rate Plans</h6>
                    <div className="offer-form-status-section">
                      <p className="offer-form-label">
                        Choose the rate plans the offer wants to apply.
                      </p>
                      <div className="offer-condition-check">
                        <ul className="offer-roomtypes">
                          <li>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={activerate}
                              checked={activerate === true ? true : false}
                              onChange={(e) =>
                                handleChangerateoffer2(e.target.checked)
                              }
                            />
                            <label className="form-check-label">All</label>
                          </li>
                          {ratelistoffer2.map((rate, index) => {
                            return (
                              <li key={index}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={rate.selected}
                                  checked={
                                    rate.selected && rate.selected === true
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    handlesinglerateselect(
                                      index,
                                      e.target.checked
                                    )
                                  }
                                />
                                <label className="form-check-label">
                                  {rate.label}
                                </label>
                              </li>
                            );
                          })}

                          <label style={{ color: "red" }}>
                            {offer2error.rate}
                          </label>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab_nav_section">
                <div className="tab_nav_section_btns">
                  <Link
                    className="btn btn-be-form-cancel btn-be-back"
                    to="#"
                    onClick={(e) => nextoffterstep2("prev")}
                  >
                    Prev
                  </Link>
                  <Link
                    className="btn btn-success btn-be-common"
                    to="#"
                    onClick={(e) => nextoffterstep2("next")}
                  >
                    Next
                  </Link>

                  {/* <Link className="btn btn-success btn-be-common" to="#" onClick={(e)=>setTab("step3")} >Next</Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Offerstep2;
