import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

function TempHotelFreequency({ 
  setTab,
hk
}) {

  return (
    <div className="tabcontent">
      <div className="card-body">
        
        <div className="live-preview">
            <div className="be_form_hk_frequency_title">
              <span className="be_form_hk_frequency_label30">
                <label for="firstNameinput" className="form-label be_form_label">
                  Service 
                </label>
              </span>
              <span className="be_form_hk_frequency_label30">
                <label for="lastNameinput" className="form-label be_form_label">
                  Frequency
                
                </label>
              </span>
            </div>
            {hk.map((data, index) => {
                              return (
                                <div key={index}>
            <div className="be_form_hk_frequency">
              <span className="be_form_hk_frequency_label30">
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
              
                  value={data.service.label}
                  name="service_name"
                />

            
              </span>
              <span className="be_form_hk_frequency_label30">
              <input
                  type="text"
                  className="form-control"
                  placeholder=""
              
                  value={data.frequency.label}
                  name="service_name"
                />
               
               
              </span>
              {/* {data?.frequency?.value==="other"?
              <span
                className="be_form_hk_frequency_label30"
                id="hk_frequency_other"
                
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  onChange={(e) => handleChangehk(index, e)}
               value={data.other_value}
               name="other_value"
                  
                />

            
              </span>
              :""}
                                      */}
                                   

            </div>
          
            <div className="clearfix"></div>
            </div>
                              )})}
            <div className="hk_frequency_appendmore"></div>
            <div className="padding-10"></div>
            
          <div className="tab_nav_section">
            <div className="tab_nav_section_btns_form">
              <button
              type="button"
                className="btn btn-be-form-cancel btn-be-back"
                onClick={(e) => setTab("Room-info")}
              >
                Prev
              </button>
              <button
              type="button"
                className="btn btn-success btn-be-common"
                 onClick={(e) => setTab("Tax")}
                
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TempHotelFreequency;
