import React from "react";
import Commonheader from "../../Common/Commonheader";
import { Link } from "react-router-dom";
import { useState } from "react";

function Extrastep3({
  setTab,
  extraStep3,
  handleOptionChangeExtrastep3,
  handlechangeStep3,
  handleExtra3Check,
  numberOfGuests,
  handlePriceChangeStep3,
  currency,
  nextextrastep3,
  extra3error,
}) {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="offer-create-section">
          <div className="offer-create-details">
            <div className="col-md-12">
              <ul className="offer-create--rate-list">
                <li>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input per_room_once_check"
                      type="radio"
                      id="WithoutinlineRadio1"
                      name="free"
                      value={"free"}
                      checked={extraStep3.price_type === "free" ? true : false}
                      onChange={() =>
                        handleOptionChangeExtrastep3("free", "price_type")
                      }
                    />
                    <label>Free</label>
                  </div>
                </li>
                <li>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input per_room_once_check"
                      type="radio"
                      id="WithoutinlineRadio1"
                      name="room_once"
                      value={"room_once"}
                      checked={
                        extraStep3.price_type === "room_once" ? true : false
                      }
                      onChange={() =>
                        handleOptionChangeExtrastep3("room_once", "price_type")
                      }
                    />
                    <label>
                      Per room / once:
                      <span className="extra-label-sub">
                        ex. flowers, chocolate, late checkout,{" "}
                      </span>
                    </label>
                  </div>

                  <div
                    className={
                      extraStep3.price_type === "room_once"
                        ? "per_room_once_show"
                        : "per_room_once_show d-none"
                    }
                  >
                    <span className="extra_freenight_discount_sec">
                      <div className="extra_freenight_discount">
                        <input
                          type="number"
                          className="form-control"
                          value={extraStep3.extra_price}
                          name="extra_price"
                          onChange={handlechangeStep3}
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                        />
                        <span className="offer_freenight_option">
                          {currency.code}
                        </span>
                      </div>
                    </span>
                    <span className="extra-label-sub">
                      You can write price 0
                    </span>
                  </div>
                </li>

                <li>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input per_room_per_night_check"
                      type="radio"
                      id="WithoutinlineRadio1"
                      name="room_per_night"
                      value={"room_per_night"}
                      checked={
                        extraStep3.price_type === "room_per_night"
                          ? true
                          : false
                      }
                      onChange={() =>
                        handleOptionChangeExtrastep3(
                          "room_per_night",
                          "price_type"
                        )
                      }
                    />
                    <label>
                      Per room / per night:
                      <span className="extra-label-sub">
                        ex. cleaning, crib, etc.
                      </span>
                    </label>
                  </div>

                  <div
                    className={
                      extraStep3.price_type === "room_per_night"
                        ? "per_room_per_night_show"
                        : "per_room_per_night_show d-none"
                    }
                  >
                    <span className="extra_freenight_discount_sec">
                      <div className="extra_freenight_discount">
                        <input
                          type="number"
                          className="form-control"
                          value={extraStep3.extra_price}
                          name="extra_price"
                          onChange={handlechangeStep3}
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                        />
                        <span className="offer_freenight_option">
                          {currency.code}
                        </span>
                      </div>
                    </span>
                    <span className="extra-label-sub">
                      You can write price 0
                    </span>
                  </div>

                  <div
                    className={
                      extraStep3.price_type === "room_per_night"
                        ? "per_room_per_night_show extra-left-padding-5 form-check form-check-inline"
                        : "per_room_per_night_show extra-left-padding-5 form-check form-check-inline d-none"
                    }
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="formCheck1"
                      value={extraStep3.extra3Check}
                      checked={extraStep3.extra3Check === true ? true : false}
                      onChange={(e) =>
                        handleExtra3Check(e.target.checked, "extra3Check")
                      }
                    />
                    <label className="offer-step-label">
                      <span className="offer_freeningt_join">
                        Weekends: from friday at 8 pm to monday at 8 am
                      </span>
                    </label>
                  </div>
                </li>

                <li>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input per_person_once_check"
                      type="radio"
                      id="WithoutinlineRadio1"
                      name="inlineRadioOptions"
                      value={"person_once"}
                      checked={
                        extraStep3.price_type === "person_once" ? true : false
                      }
                      onChange={() =>
                        handleOptionChangeExtrastep3(
                          "person_once",
                          "price_type"
                        )
                      }
                    />
                    <label>
                      Per person / once:
                      <span className="extra-label-sub">
                        ex. tickets, tours, etc.
                      </span>
                    </label>
                  </div>

                  <div
                    className={
                      extraStep3.price_type === "person_once"
                        ? "per_person_once_show"
                        : "per_person_once_show d-none"
                    }
                  >
                    <span className="extra_freenight_discount_sec">
                      <div className="extra_freenight_discount">
                        <input
                          type="number"
                          className="form-control"
                          value={extraStep3.extra_price}
                          name="extra_price"
                          onChange={handlechangeStep3}
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                        />
                        <span className="offer_freenight_option">
                          {currency.code}
                        </span>
                      </div>
                    </span>
                    <span className="extra-label-sub">
                      You can write price 0
                    </span>
                  </div>

                  <ul
                    className={
                      extraStep3.price_type === "person_once"
                        ? "offer-create-lists per_person_once_show"
                        : "offer-create-lists per_person_once_show d-none"
                    }
                  >
                    <li>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input "
                          type="radio"
                          id="WithoutinlineRadio2"
                          name="person"
                          value={"person"}
                          checked={
                            extraStep3.price_age_category === "person"
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleOptionChangeExtrastep3(
                              "person",
                              "price_age_category"
                            )
                          }
                        />
                        <label className="offer-step-label">
                          <span className="offer_freeningt_join">
                            Price per person{" "}
                          </span>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input "
                          type="radio"
                          id="WithoutinlineRadio2"
                          name="adult"
                          value={"adult"}
                          checked={
                            extraStep3.price_age_category === "adult"
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleOptionChangeExtrastep3(
                              "adult",
                              "price_age_category"
                            )
                          }
                        />
                        <label className="offer-step-label">
                          <span className="offer_freeningt_join">
                            Price per adult{" "}
                          </span>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input "
                          type="radio"
                          id="WithoutinlineRadio2"
                          name="child"
                          value={"child"}
                          checked={
                            extraStep3.price_age_category === "child"
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleOptionChangeExtrastep3(
                              "child",
                              "price_age_category"
                            )
                          }
                        />
                        <label className="offer-step-label">
                          <span className="offer_freeningt_join">
                            Price per child{" "}
                          </span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>

                <li>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input per_person_per_night_check"
                      type="radio"
                      id="WithoutinlineRadio1"
                      name="person_per_night"
                      value={"person_per_night"}
                      checked={
                        extraStep3.price_type === "person_per_night"
                          ? true
                          : false
                      }
                      onChange={() =>
                        handleOptionChangeExtrastep3(
                          "person_per_night",
                          "price_type"
                        )
                      }
                    />
                    <label>
                      Per person / per night:
                      <span className="extra-label-sub">
                        ex. VIP treatment, etc.
                      </span>
                    </label>
                  </div>

                  <div
                    className={
                      extraStep3.price_type === "person_per_night"
                        ? "per_person_per_night_show"
                        : "per_person_per_night_show d-none"
                    }
                  >
                    <span className="extra_freenight_discount_sec">
                      <div className="extra_freenight_discount">
                        <input
                          type="number"
                          className="form-control"
                          value={extraStep3.extra_price}
                          name="extra_price"
                          onChange={handlechangeStep3}
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                        />
                        <span className="offer_freenight_option">
                          {currency.code}
                        </span>
                      </div>
                    </span>
                    <span className="extra-label-sub">
                      You can write price 0
                    </span>
                  </div>
                  <ul
                    className={
                      extraStep3.price_type === "person_per_night"
                        ? "offer-create-lists per_person_per_night_show"
                        : "offer-create-lists per_person_per_night_show d-none"
                    }
                  >
                    <li>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input "
                          type="radio"
                          id="WithoutinlineRadio2"
                          name="person"
                          value={"person"}
                          checked={
                            extraStep3.price_age_category === "person"
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleOptionChangeExtrastep3(
                              "person",
                              "price_age_category"
                            )
                          }
                        />
                        <label className="offer-step-label">
                          <span className="offer_freeningt_join">
                            Price per person{" "}
                          </span>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input "
                          type="radio"
                          id="WithoutinlineRadio2"
                          name="adult"
                          value={"adult"}
                          checked={
                            extraStep3.price_age_category === "adult"
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleOptionChangeExtrastep3(
                              "adult",
                              "price_age_category"
                            )
                          }
                        />
                        <label className="offer-step-label">
                          <span className="offer_freeningt_join">
                            Price per adult{" "}
                          </span>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input "
                          type="radio"
                          id="WithoutinlineRadio2"
                          name="child"
                          value={"child"}
                          checked={
                            extraStep3.price_age_category === "child"
                              ? true
                              : false
                          }
                          onChange={() =>
                            handleOptionChangeExtrastep3(
                              "child",
                              "price_age_category"
                            )
                          }
                        />
                        <label className="offer-step-label">
                          <span className="offer_freeningt_join">
                            Price per child{" "}
                          </span>
                        </label>
                      </div>
                    </li>
                  </ul>
                  {/* <div className={extraStep3.price_type === 'person_per_night'?"per_person_per_night_show":"per_person_per_night_show d-none"}>
          </div> */}

                  <div
                    className={
                      extraStep3.price_type === "person_per_night"
                        ? "per_person_per_night_show extra-left-padding-5 form-check form-check-inline"
                        : "per_person_per_night_show extra-left-padding-5 form-check form-check-inline d-none"
                    }
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="formCheck1"
                      value={extraStep3.client_preference_status}
                      checked={
                        extraStep3.client_preference_status === true
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        handleExtra3Check(
                          e.target.checked,
                          "client_preference_status"
                        )
                      }
                    />
                    <label className="offer-step-label">
                      <span className="offer_freeningt_join">
                        The client can choose the number of nights he wants to
                        enjoy it
                      </span>
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input no_person_once_check"
                      type="radio"
                      name="inlineRadioOptions"
                      id="WithoutinlineRadio1"
                      value={"person_range_once"}
                      checked={
                        extraStep3.price_type === "person_range_once"
                          ? true
                          : false
                      }
                      onChange={() =>
                        handleOptionChangeExtrastep3(
                          "person_range_once",
                          "price_type"
                        )
                      }
                    />
                    <label>
                      Per number of persons / once:
                      <span className="extra-label-sub">
                        ex. airport shuttle, etc.
                      </span>
                    </label>
                  </div>
                  <div
                    className={
                      extraStep3.price_type === "person_range_once"
                        ? "no_person_once_show no_person_once_tabel_sec"
                        : "no_person_once_show no_person_once_tabel_sec d-none"
                    }
                  >
                    <table className="extra-table-style">
                      <tbody>
                        <tr>
                          <td>
                            <span className="extra-table-label">
                              Total Price:
                            </span>
                          </td>
                          {numberOfGuests.map((pric, index) => {
                            return (
                              <td key={index}>
                                <input
                                  className="form-control extra-table-td"
                                  style={{ width: "60px", textAlign: "center" }}
                                  name="price"
                                  value={pric.price}
                                  onChange={(e) =>
                                    handlePriceChangeStep3(index, e)
                                  }
                                />
                              </td>
                            );
                          })}
                          <td>{currency.code}</td>
                        </tr>
                        <tr>
                          <td>guest</td>
                          {/* <td><span className="extra-table-label-sub">for:</span></td> */}
                          {numberOfGuests.map((guest, index) => {
                            return <td key={index}>{index + 1}</td>;
                          })}
                          {/* <td>guest</td> */}
                        </tr>

                        <tr>
                          <td></td>
                        </tr>
                        <tr>
                          <td colSpan="14" className="extra_table_info_note">
                            You may leave prices empty (not available for that
                            number of people) or set the price to 0
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* <table className="extra-table-style">
              <tr>
                <td><span className="extra-table-label">Total Price:</span></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td><input className="form-control extra-table-td" style={{width:"60px"}}/></td>
                <td>AED</td>
              </tr>
              <tr>
                <td><span className="extra-table-label-sub">for:</span></td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
                <td>8</td>
                <td>9</td>
                <td>10</td>
                <td>11</td>
                <td>12</td>
                <td>guest</td>
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td colspan="14" className="extra_table_info_note">You may leave prices empty (not available for that number of people) or set the price to 0 (free)</td>
              </tr>
            </table> */}
                  </div>
                </li>
                <li>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input once_per_reservation_check"
                      type="radio"
                      name="inlineRadioOptions"
                      id="WithoutinlineRadio1"
                      value={"reserve_once"}
                      checked={
                        extraStep3.price_type === "reserve_once" ? true : false
                      }
                      onChange={() =>
                        handleOptionChangeExtrastep3(
                          "reserve_once",
                          "price_type"
                        )
                      }
                    />
                    <label>
                      Once per reservation:
                      <span className="extra-label-sub">
                        ex.personal shopper{" "}
                      </span>
                    </label>
                  </div>
                  <div
                    className={
                      extraStep3.price_type === "reserve_once"
                        ? "once_per_reservation_show"
                        : "once_per_reservation_show d-none"
                    }
                  >
                    <span className="extra_freenight_discount_sec">
                      <div className="extra_freenight_discount">
                        <input
                          type="number"
                          className="form-control"
                          value={extraStep3.extra_price}
                          name="extra_price"
                          onChange={handlechangeStep3}
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                        />
                        <span className="offer_freenight_option">
                          {currency.code}
                        </span>
                      </div>
                    </span>
                    <span className="extra-label-sub">
                      You can write price 0
                    </span>
                  </div>
                </li>
                <li>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input reservation_per_night_check"
                      type="radio"
                      id="WithoutinlineRadio1"
                      name="reserve_per_night"
                      value={"reserve_per_night"}
                      checked={
                        extraStep3.price_type === "reserve_per_night"
                          ? true
                          : false
                      }
                      onChange={() =>
                        handleOptionChangeExtrastep3(
                          "reserve_per_night",
                          "price_type"
                        )
                      }
                    />
                    <label>
                      One unit per reservation / per night:
                      <span className="extra-label-sub">
                        One unit per reservation / per night
                      </span>
                    </label>
                  </div>

                  <div
                    className={
                      extraStep3.price_type === "reserve_per_night"
                        ? "reservation_per_night_show"
                        : "reservation_per_night_show d-none"
                    }
                  >
                    <span className="extra_freenight_discount_sec">
                      <div className="extra_freenight_discount">
                        <input
                          type="number"
                          className="form-control"
                          value={extraStep3.extra_price}
                          name="extra_price"
                          onChange={handlechangeStep3}
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                        />
                        <span className="offer_freenight_option">
                          {currency.code}
                        </span>
                      </div>
                    </span>
                    <span className="extra-label-sub">
                      You can write price 0
                    </span>
                  </div>
                  <div
                    className={
                      extraStep3.price_type === "reserve_per_night"
                        ? "reservation_per_night_show extra-left-padding-5 form-check form-check-inline"
                        : "reservation_per_night_show extra-left-padding-5 form-check form-check-inline d-none"
                    }
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="formCheck1"
                      value={extraStep3.client_preference_status}
                      checked={
                        extraStep3.client_preference_status === true
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        handleExtra3Check(
                          e.target.checked,
                          "client_preference_status"
                        )
                      }
                    />
                    <label className="offer-step-label">
                      <span className="offer_freeningt_join">
                        The client can choose the number of nights he wants to
                        enjoy it
                      </span>
                    </label>
                  </div>
                </li>
              </ul>
              <label style={{ color: "red" }}>{extra3error.extra_price}</label>
              <div className="tab_nav_section">
                <div className="tab_nav_section_btns">
                  <Link
                    className="btn btn-be-form-cancel btn-be-back"
                    to="#"
                    onClick={(e) => setTab("step2")}
                  >
                    Prev
                  </Link>
                  <Link
                    className="btn btn-success btn-be-common"
                    to="#"
                    onClick={nextextrastep3}
                  >
                    Next
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Extrastep3;
