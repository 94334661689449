import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Commonimagepath from "../../../Commonimagepath";

function Extraeditstep4({
  setTab,
  extra,
  language,
  handlechangeExtra4,
  multilang,
  setMultilang,
  Update,
  image,
  setImage,
  imagechoose,
  optionimage,
}) {
  const [tab1, setTab1] = useState("EN");
  const [popup, setPopup] = useState(false);
  const [tabimg, setTabimage] = useState("upload");

  const handleImageClick = (imageSrc) => {
    setImage(imageSrc);

    setPopup(false);
    console.log(imageSrc);
  };

  const onFileChange = (e) => {
    setPopup(false);
    console.log(e);
    setImage(e.target.files[0]);
    e.target.value = null;
  };

  console.log(image);
  console.log(imagechoose);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="offer-create-section">
          <div className="offer-create-details">
            <div className="col-md-12">
              <div className="offer-content-section">
                <div className="offer-form-status-section">
                  <div className="form-translation-style">
                    <ul>
                      <li
                        className={tab1 === "EN" ? "active" : ""}
                        onClick={(e) => setTab1("EN")}
                      >
                        EN
                      </li>
                      <li
                        className={tab1 === "TRANS" ? "active" : ""}
                        onClick={(e) => setTab1("TRANS")}
                      >
                        Translations
                      </li>
                    </ul>
                  </div>
                  {tab1 === "EN" ? (
                    <>
                      <div className="offer-content-section">
                        <div className="col-md-12">
                          <label>
                            Subtitle :{" "}
                            <span className="extra-label-subhead">
                              Optional, max 50 characters
                            </span>
                            <span className="info-tag">
                              i
                              <span className="tooltiptext">
                                Use this to mention a good reason to buy the
                                extra. Do not repeat words that you have used in
                                the name.
                              </span>
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="subtitle"
                            value={extra.subtitle}
                            onChange={handlechangeExtra4}
                          />
                        </div>
                        <div className="padding-5"></div>
                        <div className="col-md-12">
                          <label>
                            Description :
                            <span className="extra-label-subhead">
                              Optional, max 300 characters
                            </span>
                            <span className="info-tag">
                              i
                              <span className="tooltiptext">
                                Make it attractive and convince. Do not repeat
                                words already used in name or subtitle.
                              </span>
                            </span>
                          </label>
                          <textarea
                            className="form-control"
                            rows="5"
                            name="description"
                            value={extra.description}
                            onChange={handlechangeExtra4}
                          ></textarea>
                        </div>
                        <div className="padding-5"></div>
                        <div className="col-md-12">
                          <label>
                            Conditions :{" "}
                            <span className="extra-label-subhead">
                              Optional, max 300 characters
                            </span>
                            <span className="info-tag">
                              i
                              <span className="tooltiptext">
                                Only if there is a condition or restriction
                                missing.
                              </span>
                            </span>
                          </label>
                          <textarea
                            className="form-control"
                            rows="5"
                            name="condition"
                            value={extra.condition}
                            onChange={handlechangeExtra4}
                          ></textarea>
                        </div>
                        <div className="padding-5"></div>
                        <div className="col-md-12 photo_offer">
                          <label>Photo</label>
                          <div className="form-translation-style_offer">
                            <ul
                              className="price_show_select_offer"
                              id="price_show_type"
                            >
                              <li
                                className={tabimg === "upload" ? "active" : ""}
                                onClick={(e) => setTabimage("upload")}
                              >
                                <button className="photo_offer_pen_choose">
                                  Upload Image
                                </button>
                                <input
                                  className="photo_offer_input"
                                  type="file"
                                  name="image"
                                  onChange={onFileChange}
                                  accept=".jpg, .jpeg, .webp, .png"
                                />
                              </li>

                              <li
                                className={tabimg === "list" ? "active" : ""}
                                onClick={(e) => {
                                  setPopup(!popup);
                                  setTabimage("list");
                                }}
                              >
                                <button className="photo_offer_pen_choose">
                                  Choose From List
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="clearfix"></div>
                          <div>
                            {image ? (
                              image instanceof File ? (
                                <p className="pdf_img_style_img">
                                  {image.name}
                                </p>
                              ) : (
                                <img
                                  className="pdf_img_style_img"
                                  style={{ width: "100px", height: "100px" }}
                                  src={`${Commonimagepath.apiurl}${image}`}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </div>
                          <div></div>

                          {/* <img src="images/deleteone.png" id="suggestionImage" onclick="selectImage()" style={{cursor: "pointer"}}/>
                                        <input type="file" id="fileInput" style={{display: "none"}}></input> */}
                          {/* <div className="">
                                            <input type="file" className="form-control" style={{width:"250px"}} onChange={onFileChange} accept=".jpg, .jpeg, .webp, .png"></input>
                                        </div> */}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="offer-content-section">
                        <div class="col-lg-4" style={{ marginTop: "20px" }}>
                          <div className="input-group">
                            <label
                              className="input-group-text input-joint-color"
                              id="inputGroup-sizing-default"
                              style={{ width: "130px" }}
                            >
                              {" "}
                              Choose Language:
                            </label>
                            <div className="col-md-7 col-select-step3">
                              <MultiSelect
                                options={language}
                                value={multilang}
                                onChange={(e) => setMultilang(e)}
                                labelledBy="Select"
                                selectionType="counter"
                                closeOnChangedValue={true}
                                aria-expanded="true"
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className="appendmore"
                          style={{ marginTop: "20px" }}
                        ></div>
                        {multilang?.length > 0
                          ? multilang?.map((element, index) => {
                              return (
                                <div
                                  key={index}
                                  className="col-md-12 translation-fill-section"
                                  id="lang_ +val"
                                  data-value="1"
                                >
                                  <div className="translation-selected-lang">
                                    {" "}
                                    <p>
                                      <span className="translation-selected-lang-head">
                                        {element.lang_code}
                                      </span>
                                    </p>
                                  </div>
                                  <div class="col-lg-12">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                      >
                                        Subtitle
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={multilang[index]?.subtitle}
                                        onChange={(e) =>
                                          (multilang[index].subtitle =
                                            e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <br></br>
                                  <div class="col-lg-12">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                      >
                                        Description
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={multilang[index]?.description}
                                        onChange={(e) =>
                                          (multilang[index].description =
                                            e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <br></br>
                                  <div class="col-lg-12">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                      >
                                        Conditions
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={multilang[index]?.condition}
                                        onChange={(e) =>
                                          (multilang[index].condition =
                                            e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="tab_nav_section">
              <div className="tab_nav_section_btns">
                <Link
                  className="btn btn-be-form-cancel btn-be-back"
                  to="#"
                  onClick={(e) => setTab("step2")}
                >
                  Prev
                </Link>
                <Link
                  className="btn btn-success btn-be-common"
                  to=""
                  onClick={Update}
                >
                  Update
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        id="flipModal"
        isOpen={popup}
        toggle={() => {
          setPopup(!popup);
        }}
        modalClassName="flip"
        centered
        style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalHeader toggle={() => setPopup(!popup)}></ModalHeader>
        <ModalBody className="modal-body p-5 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#405189,secondary:#f06548"
            style={{ width: "90px", height: "90px" }}
          ></lord-icon>
          {/* <div className="photo_offer_pen">
                <button className='photo_offer_pen_upload'>Upload Image</button>
                <input className='photo_offer_input' type="file" name="image" onChange={onFileChange} accept=".jpg, .jpeg, .webp, .png" />
            </div> */}
          <div className="offer_image_list_ur">
            {optionimage.map((imageSrc, index) => (
              <>
                <h5>{imageSrc.title}</h5>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {imageSrc.images.map((imgs, ind) => {
                    return (
                      <img
                        key={ind}
                        src={`${Commonimagepath.apiurl}${imgs}`}
                        alt={`Image ${ind + 1}`}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          cursor: "pointer",
                          margin: "10px 10px",
                        }}
                        onClick={() => handleImageClick(imgs)}
                      />
                    );
                  })}
                </div>
              </>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Extraeditstep4;
