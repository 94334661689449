import React, { useState } from "react";
import Footer from "../../Common/Footer";
import { Link } from "react-router-dom";
import Commonheader from "../../Common/Commonheader";
import Basicsettingsheader from "../../Common/Basicsettingsheader";

function Hotelboardadd() {
  const [inputFields, setInputFields] = useState([
    {
      fullName: "",
    },
  ]);
  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        board: "",
        adult_price: "",
        child_price:"",
status:"",
      },
    ]);
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };
  return (
    <div id="layout-wrapper">
    <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Hotel Board</h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Basic Settings </Link>
                      </li>
                      <li className="breadcrumb-item active">Hotel Board</li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create New Hotel Boards
                      </h4>
                      <div className="flex-shrink-0">
                        {/* <Link
                          type="button"
                          className="btn db-other-button"
                          to="/hotel-board-list"
                          style={{ marginRight: "5px" }}
                        >
                          Hotel Boards
                        </Link> */}
                        <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target=""
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="col-md-12">
                            <div
                              for="firstNameinput"
                              className="hboard_select-label float-lef"
                            >
                              Board{" "}
                              <span className="form-validation-required">
                                *
                              </span>
                            </div>
                            <div className="hboard_text-label float-left">
                              Adult Price
                            </div>
                            <div className="hboard_text-label float-left">
                              Child Price
                            </div>
                            <div className="hboard_text-label float-left">
                              Status
                            </div>
                            <Link
                              className="add_more"
                              to="#"
                              onClick={addInputField}
                            >
                              <strong className="one_more add_more_btn">
                                +
                              </strong>
                            </Link>
                          </div>
                          <div className="clearfix"></div>
                          {inputFields.map((data, index) => {
                            return (
                              <div
                                className="new_options"
                                id="new_option"
                                key={index}
                              >
                                <select
                                  id="ForminputState"
                                  className="form-select hboard_select"
                                  value={data.board}
                                >
                                  <option>Choose...</option>
                                  <option>Room Only</option>
                                  <option>Breakfast Only</option>
                                  <option>Half Board</option>
                                  <option>Full Board</option>
                                </select>
                                <input
                                  type="number"
                                  onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}

                                  className="form-control hboard_text "
                                  placeholder="Enter ..."
                                  value={data.adult_price}
                                  
                                />
                                <input
                                  type="number"
                                  onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}

                                  value={data.child_price}
                                  className="form-control hboard_text "
                                  placeholder="Enter ..."
                                
                                />
                                <select
                                  id="ForminputState"
                                  className="form-select hboard_select"
                                  value={data.status}
                                >
                                  <option>Choose...</option>
                                  <option>Active</option>
                                  <option>Inactive</option>
                                </select>
                                <Link
                                  className="remove_more"
                                  to="#"
                                  onClick={(e)=>removeInputFields(index)}
                                >
                                  -
                                </Link>
                              </div>
                            );
                          })}
                          <div className="clearfix"></div>
                          <div className="appendmore"></div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default Hotelboardadd;
