import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import Commonheader from "../../../Common/Commonheader";
import Config from "../../../Config";
import Loading from "../../../Loading";
import Select from "react-select";
import { Link } from "react-router-dom";
import Alerts from "../../../Alerts";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import { MultiSelect } from "react-multi-select-component";
import moment, { months } from "moment";
import { UserContext } from "../../../Contexts/UserContext";
import { useAuthHandling } from "../../../Common/useAuthHandling";
import Toastmodal from "../../../Common/Toastmodal";
import ReactPaginate from "react-paginate";
// import usePreventTouchDrag from '../../../Common/usePreventTouchdrag';

function Cancellationpolicyfeature() {
  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [symbolsArrneg] = useState(["e", "E", "+", "."]);
  const [symbolsArrall] = useState(["e", "E", "+", "-"]);

  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");

  const [policy, setPolicy] = useState([]);
  const [be_languagefull, setBe_languagefull] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [pageid, setPageid] = useState("");
  const [paymentmethod, setPaymentmethod] = useState([]);
  const [paymentoption, setPaymentoption] = useState([]);
  const [contract, setContract] = useState([]);
  const [hotelcontract, setHotelContract] = useState([]);
  const [hotelcontractpopup, setHotelContractpopup] = useState([]);
  const [showFullData, setShowFullData] = useState([]);
  const [changeddata, setChangeddata] = useState([]);

  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);

  const [errors1, setErrors1] = useState([{}]);
  const [err, setErrors] = useState({});

  const [popup, setPopup] = useState(false);
  const [add, setAdd] = useState(false);
  const [paginate, setPaginate] = useState([]);
  const [page, setPage] = useState(1);
  const [pageindex, setPageindex] = useState(1);
  const [selectcreate, setSelectCreate] = useState("");
  const [selectcancelpopup, setCancelpopup] = useState("");

  const [newpolicy, setNewpolicy] = useState({
    contract_detail: {},
    default_option: [],
    payment_method: {},
    payment_option: [],
    amount_type: "percentage",
    amount: "",
  });
  const getinfo = async (e) => {
    const res = await fetch(`${Config.apiurl}cancel-policies/list`, {
      method: "POST",
      body: JSON.stringify({
        hotels: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });

    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setPolicy(data.data.data);
        setLoading(true);
        setPaginate(data.data.last_page);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getinfo();
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getinfo();
    } else {
      console.log("error");
    }
  };

  const getpaymentmethod = async (e) => {
    const res = await fetch(`${Config.apiurl}payment-methods`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setPaymentmethod(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getpaymentmethod();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getpaymentmethod();
    }
  };

  // const getcontractpolicy= async (e) => {

  //   const res = await fetch(`${Config.apiurl}hotel/policy-contract/active/list`, {
  //     method:"POST",
  //     body: JSON.stringify({
  //       hotel_id: id,
  //     }),
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       "Content-type": "application/json; charset=UTF-8",
  //     },
  //   });
  //   const data = await res.json();
  //   if (data.status_code !== "200" || !data.data) {
  //     console.log("error");
  //   } else {
  //     setContract(data.data);
  //   }
  // };

  // const gethotelcontractpolicy= async (e) => {

  //   const res = await fetch(`${Config.apiurl}hotel/policy-contract/active/list`, {
  //     method:"POST",
  //     body: JSON.stringify({
  //       hotel_id: id,
  //     }),
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       "Content-type": "application/json; charset=UTF-8",
  //     },
  //   });
  //   const data = await res.json();
  //   if (data.status_code !== "200" || !data.data) {
  //     console.log("error");
  //   } else {
  //     setHotelContract(data.data);
  //     setContract(data.data);

  //   }
  // };

  // const gethotelcontractpolicypopup= async (e) => {

  //   const res = await fetch(`${Config.apiurl}hotel/policy-contract/active-status/list`, {
  //     method:"POST",
  //     body: JSON.stringify({
  //       hotel_id: id,
  //     }),
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       "Content-type": "application/json; charset=UTF-8",
  //     },
  //   });
  //   const data = await res.json();
  //   if (data.status_code !== "200" || !data.data) {
  //     console.log("error");
  //   } else {
  //     setHotelContractpopup(data.data);
  //   }
  // };

  let handleChangees = (i, index, e) => {
    e.preventDefault();
    let newval = [...policy];
    policy[i].hotel_cancel_policies[index][e.target.name] = e.target.value;
    setPolicy(newval);

    let newChange = [...changeddata];
    if (
      !newChange[i] ||
      !newChange[i].hotel_cancel_policies ||
      !newChange[i].hotel_cancel_policies[index]
    ) {
      newChange[i] = newChange[i] || { hotel_cancel_policies: [] };
      newChange[i].hotel_cancel_policies[index] = {};
    }
    newChange[i].hotel_cancel_policies[index] =
      newval[i].hotel_cancel_policies[index];
    newChange[i].hotel_id = newval[i].hotel_id;

    newChange[i].hotel_cancel_policies[index][e.target.name] = e.target.value;
    // policy[i].hotelflag=true;
    // policy[i].hotel_contracts[index].policyflag=
    setChangeddata(newChange);
  };

  let handleChangeesnight = (i, index, e) => {
    let newval = [...policy];
    policy[i].hotel_cancel_policies[index][e.target.name] = e.target.value;
    policy[i].hotel_cancel_policies[index]["amount"] = "";

    let newChange = [...changeddata];
    if (
      !newChange[i] ||
      !newChange[i].hotel_cancel_policies ||
      !newChange[i].hotel_cancel_policies[index]
    ) {
      newChange[i] = newChange[i] || { hotel_cancel_policies: [] };
      newChange[i].hotel_cancel_policies[index] = {};
    }
    newChange[i].hotel_cancel_policies[index] =
      newval[i].hotel_cancel_policies[index];
    newChange[i].hotel_id = newval[i].hotel_id;

    newChange[i].hotel_cancel_policies[index][e.target.name] = e.target.value;
    setChangeddata(newChange);
    setPolicy(newval);
  };

  let handlenew = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setNewpolicy({ ...newpolicy, [name]: value });
  };

  let handleChangeesselectnew = async (e) => {
    setNewpolicy((prevPolicy) => ({ ...prevPolicy, payment_method: e }));

    const res = await fetch(`${Config.apiurl}payment-options/list`, {
      method: "POST",
      body: JSON.stringify({
        payment_method: e.value,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setNewpolicy((prevPolicy) => ({
          ...prevPolicy,
          default_option: data.data,
        }));
      } else if (data.reauth === true) {
        handleUnauthorized();
        handleChangeesselectnew();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      handleChangeesselectnew(e);
    }
  };

  let handleChangeescontactnew = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];

    let newval = { ...newpolicy };

    newval.contract_detail.label =
      selectedOption.getAttribute("data-contract-code");
    newval.contract_detail.description = "";
    newval.contract_detail.value = selectedOption.value;
    newval.hotel_contract_id = selectedOption.getAttribute("data-hotel");

    setNewpolicy(newval);
  };

  let handlepayoptionnew = (e) => {
    setNewpolicy((prevPolicy) => ({ ...prevPolicy, payment_option: e }));
  };

  let closeclear = (e) => {
    setNewpolicy({
      contract_detail: {},
      default_option: [],
      payment_method: {},
      payment_option: [],
      amount_type: "percentage",
      amount: "",
    });
    setErrors({});
    setAdd(false);
    setSelectCreate("");
  };

  let handleChangeescontact = (i, index, e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    let newval = [...policy];
    newval[i].hotel_cancel_policies[index]["hotel_contract"]["label"] =
      selectedOption.getAttribute("data-contract-code");
    newval[i].hotel_cancel_policies[index]["hotel_contract"]["description"] =
      "";
    newval[i].hotel_cancel_policies[index]["hotel_contract"]["value"] =
      selectedOption.value;
    newval[i].hotel_cancel_policies[index]["hotel_contract_id"] =
      selectedOption.getAttribute("data-hotel");
    setPolicy(newval);

    let newChange = [...changeddata];
    if (
      !newChange[i] ||
      !newChange[i].hotel_cancel_policies ||
      !newChange[i].hotel_cancel_policies[index]
    ) {
      newChange[i] = newChange[i] || { hotel_cancel_policies: [] };
      newChange[i].hotel_cancel_policies[index] = {};
    }
    newChange[i].hotel_cancel_policies[index] =
      newval[i].hotel_cancel_policies[index];
    newChange[i].hotel_id = newval[i].hotel_id;

    setChangeddata(newChange);
  };
  let handlepayoption = (i, index, e) => {
    let newval = [...policy];
    policy[i].hotel_cancel_policies[index]["payment_option"] = e;
    setPolicy(newval);

    let newChange = [...changeddata];
    if (
      !newChange[i] ||
      !newChange[i].hotel_cancel_policies ||
      !newChange[i].hotel_cancel_policies[index]
    ) {
      newChange[i] = newChange[i] || { hotel_cancel_policies: [] };
      newChange[i].hotel_cancel_policies[index] = {};
    }
    newChange[i].hotel_cancel_policies[index] =
      policy[i].hotel_cancel_policies[index];
    newChange[i].hotel_id = policy[i].hotel_id;

    setChangeddata(newChange);
  };

  let handleChangeesselect = async (i, index, e) => {
    let newval = [...policy];
    policy[i].hotel_cancel_policies[index]["payment_method"] = e;
    setPolicy(newval);

    const res = await fetch(`${Config.apiurl}payment-options/list`, {
      method: "POST",
      body: JSON.stringify({
        payment_method: e.value,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        let newval = [...policy];
        policy[i].hotel_cancel_policies[index]["default_option"] = data.data;
        policy[i].hotel_cancel_policies[index]["payment_option"] = [];
        setPolicy(newval);

        let newChange = [...changeddata];
        if (
          !newChange[i] ||
          !newChange[i].hotel_cancel_policies ||
          !newChange[i].hotel_cancel_policies[index]
        ) {
          newChange[i] = newChange[i] || { hotel_cancel_policies: [] };
          newChange[i].hotel_cancel_policies[index] = {};
        }
        newChange[i].hotel_cancel_policies[index] =
          policy[i].hotel_cancel_policies[index];
        newChange[i].hotel_id = policy[i].hotel_id;

        setChangeddata(newChange);
      } else if (data.reauth === true) {
        handleUnauthorized();
        handleChangeesselect(i, index, e);
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      handleChangeesselect(i, index, e);
    }
  };

  function tog_animationFlip(c_id, index) {
    if (c_id) {
      setmodal_animationFlip(!modal_animationFlip);
      setPageid(c_id);
    } else {
      setmodal_animationFlip(!modal_animationFlip);

      // const rows = [...policy];
      // rows.splice(index, 1);
      // setPolicy(rows);
    }
  }
  const DeleteData = async (policy_id) => {
    axios
      .post(`${Config.apiurl}hotel/cancel-policy/delete/${policy_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then((res) => {
        if (res.status === "200") {
          setToastmodal(true);
          setToastmessage(res.message);
          setToasttype("success");
          setToastlarge(false);
          setmodal_animationFlip(false);
          getinfo();
          setPageid("");
          setTimeout(() => setAlert(false), 4000);
        } else if (res.reauth === true) {
          handleUnauthorized();
          DeleteData(policy_id);
        } else {
          setToastmodal(true);
          setToastmessage(res.message);
          setToasttype("error");
          setToastlarge(false);
          setmodal_animationFlip(false);
          setPageid("");
          setTimeout(() => setAlert(false), 4000);
        }
      });
  };

  console.log(policy);
  const addInputField = () => {
    setPolicy([
      ...policy,
      {
        contract_detail: {},
        default_option: [],
        payment_method: {},
        payment_option: [],
        amount_type: "percentage",
        amount: "",
      },
    ]);
  };

  const Add = async () => {
    let isFormValid = true;
    const errors = {};
    if (!newpolicy.minimum_hours) {
      isFormValid = false;

      errors.minimum_hours = "This field is required!";
    }
    if (!newpolicy.cancelation_priority) {
      isFormValid = false;

      errors.cancelation_priority = "This field is required!";
    }
    if (!newpolicy.contract_detail?.value) {
      isFormValid = false;

      errors.contract_detail = "This field is required!";
    }
    if (!newpolicy.payment_method?.value) {
      isFormValid = false;

      errors.paymentmethod = "This field is required!";
    }
    if (!newpolicy.is_active) {
      isFormValid = false;

      errors.is_active = "This field is required!";
    }
    if (newpolicy.payment_method && newpolicy.payment_method.flag === true) {
      if (!newpolicy.amount) {
        isFormValid = false;

        errors.amount = "This field is required!";
      }
      if (!newpolicy.amount_type) {
        isFormValid = false;

        errors.amount_type = "This field is required!";
      }
    }
    if (newpolicy.payment_method && newpolicy.payment_method.flag === true) {
      if (newpolicy.default_option.length > 0) {
        if (newpolicy.payment_option.length === 0) {
          isFormValid = false;

          errors.payment_option = "This field is required!";
        }
      }
    }

    setErrors(errors);
    if (isFormValid === true) {
      let arr = [];
      if (newpolicy.payment_option.length > 0) {
        for (let j = 0; j < newpolicy.payment_option?.length; j++) {
          arr.push(newpolicy.payment_option[j].value);
        }
      }
      const res = await fetch(`${Config.apiurl}hotel/cancel-policy/add`, {
        method: "POST",
        body: JSON.stringify({
          hotel_id: policy[selectcreate].hotel_id,
          minimum_hours: newpolicy.minimum_hours,
          contract_id: newpolicy.contract_detail.value,
          hotel_contract_id: newpolicy.hotel_contract_id,
          priority: newpolicy.cancelation_priority,
          payment_method: newpolicy.payment_method.value,
          payment_option: arr,
          amount_type: newpolicy.amount_type,
          amount: newpolicy.amount,
          is_active: newpolicy.is_active,
          previous_days: 0,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code === "200") {
          setAdd(false);
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(false);
          setNewpolicy({
            contract_detail: {},
            default_option: [],
            payment_method: {},
            payment_option: [],
            amount_type: "percentage",
            amount: "",
          });
          setErrors({});
          setSelectCreate("");
          setTimeout(() => setAlert(false), 4000);
          getinfo();
        } else if (data.reauth === true) {
          handleUnauthorized();
          Add();
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(false);
          setTimeout(() => setAlert(false), 4000);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Add();
      }
    }
  };

  const Bulkupdate = async (i) => {
    let poli = [];
    let isFormValid = true;
    for (let i = 0; i < policy.length; i++) {
      const err = {};
      let arr = [];
      for (let j = 0; j < policy[i].hotel_cancel_policies.length; j++) {
        const errors = {};
        if (!policy[i].hotel_cancel_policies[j].minimum_hours) {
          isFormValid = false;

          errors.minimum_hours = "This field is required!";
        }
        if (!policy[i].hotel_cancel_policies[j].cancelation_priority) {
          isFormValid = false;

          errors.cancelation_priority = "This field is required!";
        }
        if (!policy[i].hotel_cancel_policies[j].hotel_contract?.value) {
          isFormValid = false;

          errors.contract_detail = "This field is required!";
        }
        if (!policy[i].hotel_cancel_policies[j].payment_method?.value) {
          isFormValid = false;

          errors.paymentmethod = "This field is required!";
        }
        if (!policy[i].hotel_cancel_policies[j].is_active) {
          isFormValid = false;

          errors.is_active = "This field is required!";
        }
        if (
          policy[i].hotel_cancel_policies[j].payment_method &&
          policy[i].hotel_cancel_policies[j].payment_method.flag === true
        ) {
          if (
            !policy[i].hotel_cancel_policies[j].amount ||
            !policy[i].hotel_cancel_policies[j].amount_type
          ) {
            isFormValid = false;

            errors.amount = "This field is required!";
          }
        }
        console.log(policy[i].hotel_cancel_policies[j]);
        if (
          policy[i].hotel_cancel_policies[j].payment_method &&
          policy[i].hotel_cancel_policies[j].payment_method.flag === true
        ) {
          if (policy[i].hotel_cancel_policies[j].default_option.length > 0) {
            if (
              policy[i].hotel_cancel_policies[j].payment_option.length === 0
            ) {
              isFormValid = false;

              errors.payment_option = "This field is required!";
            }
          }
        }
        arr.push(errors);
        err.hotel_cancel_policies = arr;
      }
      poli.push(err);
    }
    setErrors1(poli);

    if (isFormValid === true) {
      let arre = [];
      if (changeddata?.length > 0) {
        for (let i = 0; i < changeddata.length; i++) {
          if (changeddata[i] !== undefined) {
            let arry = {};
            if (changeddata[i]?.hotel_cancel_policies) {
              arry.hotel_cancel_policies = Object.values(
                changeddata[i]?.hotel_cancel_policies
              );
              arry.hotel_id = changeddata[i].hotel_id;
              arre.push(arry);
            }
          }
        }

        let arr = [];
        if (arre.length > 0) {
          for (let i = 0; i < arre?.length; i++) {
            let pol = {};
            pol.hotel_id = arre[i]?.hotel_id;
            let array = [];
            for (let j = 0; j < arre[i]?.hotel_cancel_policies.length; j++) {
              let polob = {};
              polob.policy_id = arre[i].hotel_cancel_policies[j].policy_id;
              polob.policy_identifier =
                arre[i].hotel_cancel_policies[j].policy_identifier;
              polob.minimum_hours =
                arre[i].hotel_cancel_policies[j].minimum_hours;
              polob.contract_id =
                arre[i].hotel_cancel_policies[j].hotel_contract.value;
              polob.hotel_contract_id =
                arre[i].hotel_cancel_policies[j].hotel_contract_id;
              polob.priority =
                arre[i].hotel_cancel_policies[j].cancelation_priority;
              polob.payment_method =
                arre[i].hotel_cancel_policies[j].payment_method.value;
              polob.amount_type = arre[i].hotel_cancel_policies[j].amount_type;
              polob.amount = arre[i].hotel_cancel_policies[j].amount;
              polob.previous_days =
                arre[i].hotel_cancel_policies[j].previous_days;
              polob.is_active = arre[i].hotel_cancel_policies[j].is_active;
              let option = [];
              for (
                let j = 0;
                j < arre[i]?.hotel_cancel_policies[j]?.payment_option?.length;
                j++
              ) {
                option.push(
                  arre[i]?.hotel_cancel_policies[j]?.payment_option[j]?.value
                );
              }
              polob.payment_option = option;
              array.push(polob);
            }
            pol.policies = array;
            arr.push(pol);
          }
        }

        const res = await fetch(`${Config.apiurl}cancel-policy/update`, {
          method: "POST",
          body: JSON.stringify({
            hotels: arr,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        });
        if (res.status === 200) {
          const data = await res.json();
          if (data.status_code === "200") {
            setToastmodal(true);
            setToastmessage(data.message);
            setToasttype("success");
            setToastlarge(false);
            setTimeout(() => setAlert(false), 4000);
            getinfo();
          } else if (data.reauth === true) {
            handleUnauthorized();
            Bulkupdate(i);
          } else {
            setToastmodal(true);
            setToastmessage(data.message);
            setToasttype("error");
            setToastlarge(false);
            setTimeout(() => setAlert(false), 4000);
          }
        } else if (res.status == 401) {
          handleUnauthorized();
          Bulkupdate(i);
        }
      } else {
        setToastmodal(true);
        setToastmessage("You have made no changes to save");
        setToasttype("success");
        setToastlarge(true);
      }
    }
  };

  const Save = async (i, index) => {
    let poli = [];
    let isFormValid = true;
    let newPolis = [...errors1];
    if (
      newPolis &&
      newPolis[i] &&
      newPolis[i].hotel_cancel_policies &&
      newPolis[i].hotel_cancel_policies[index]
    ) {
      newPolis[i].hotel_cancel_policies[index] = "";
    }

    setErrors1(newPolis);
    const errors = {};
    if (!policy[i].hotel_cancel_policies[index].minimum_hours) {
      isFormValid = false;

      errors.minimum_hours = "This field is required!";
    }
    if (!policy[i].hotel_cancel_policies[index].cancelation_priority) {
      isFormValid = false;

      errors.cancelation_priority = "This field is required!";
    }
    if (!policy[i].hotel_cancel_policies[index].hotel_contract?.value) {
      isFormValid = false;

      errors.contract_detail = "This field is required!";
    }
    if (!policy[i].hotel_cancel_policies[index].payment_method?.value) {
      isFormValid = false;

      errors.paymentmethod = "This field is required!";
    }
    if (!policy[i].hotel_cancel_policies[index].is_active) {
      isFormValid = false;

      errors.is_active = "This field is required!";
    }
    if (
      policy[i].hotel_cancel_policies[index].paymentmethod &&
      policy[i].hotel_cancel_policies[index].paymentmethod.flag === true
    ) {
      if (
        !policy[i].hotel_cancel_policies[index].amount ||
        !policy[i].hotel_cancel_policies[index].amount_type
      ) {
        isFormValid = false;

        errors.amount = "This field is required!";
      }
    }

    if (policy[i].hotel_cancel_policies[index].default_option.length > 0) {
      if (policy[i].hotel_cancel_policies[index].payment_option.length === 0) {
        isFormValid = false;

        errors.payment_option = "This field is required!";
      }
    }
    console.log(errors);
    let newPoli = [...errors1];

    if (newPoli[i] && newPoli[i].hotel_cancel_policies) {
      if (!newPoli[i].hotel_cancel_policies[index]) {
        newPoli[i].hotel_cancel_policies[index] = {}; // Provide default value
      }

      newPoli[i].hotel_cancel_policies[index] = errors;
    } else {
      newPoli[i] = newPoli[i] || {}; // Ensure newPoli[i] is initialized
      newPoli[i].hotel_cancel_policies = newPoli[i].hotel_cancel_policies || []; // Ensure newPoli[i].hotel_cancel_policies is initialized
      newPoli[i].hotel_cancel_policies[index] = errors;
    }
    console.log(newPoli);
    // newPoli[i].hotel_cancel_policies[index] = errors;
    setErrors1(newPoli);

    console.log(errors1);
    if (isFormValid === true) {
      let arr = [];
      if (policy[i].hotel_cancel_policies[index].payment_option.length > 0) {
        for (
          let j = 0;
          j < policy[i].hotel_cancel_policies[index].payment_option?.length;
          j++
        ) {
          arr.push(
            policy[i].hotel_cancel_policies[index].payment_option[j].value
          );
        }
      }
      if (policy[i].hotel_cancel_policies[index].amount_type === null) {
        let newPoli = [...policy];
        newPoli[i].hotel_cancel_policies[index].amount_type = "percentage";
        setPolicy(newPoli);
      }
      const res = await fetch(
        `${Config.apiurl}hotel/cancel-policy/update/${policy[i].hotel_cancel_policies[index].policy_id}`,
        {
          method: "POST",
          body: JSON.stringify({
            hotel_id: policy[i].hotel_id,
            minimum_hours: policy[i].hotel_cancel_policies[index].minimum_hours,
            contract_id:
              policy[i].hotel_cancel_policies[index].hotel_contract.value,
            hotel_contract_id:
              policy[i].hotel_cancel_policies[index].hotel_contract_id,
            priority:
              policy[i].hotel_cancel_policies[index].cancelation_priority,
            payment_method:
              policy[i].hotel_cancel_policies[index].payment_method.value,
            payment_option: arr,
            amount_type: policy[i].hotel_cancel_policies[index].amount_type,
            amount: policy[i].hotel_cancel_policies[index].amount,
            is_active: policy[i].hotel_cancel_policies[index].is_active,
            previous_days: 0,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code === "200") {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(false);
          setTimeout(() => setAlert(false), 4000);
          getinfo();
        } else if (data.reauth == true) {
          handleUnauthorized();
          Save(i, index);
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(false);
          setTimeout(() => setAlert(false), 4000);
        }
      } else if (res.status == 401) {
        handleUnauthorized();
        Save(i, index);
      }
    }
  };

  useEffect(() => {
    getinfo();
    getpaymentmethod();
    // getcontractpolicy();
    // gethotelcontractpolicy();
    // gethotelcontractpolicypopup();
  }, []);

  useEffect(() => {
    getinfo();
  }, [id]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (policy.length > 0) {
      const initialState = policy.map((item, index) => ({
        hotel: index === 0,
      }));
      setShowFullData(initialState);
    }
  }, [policy.length]);

  const handleDropdownClickhotel = (i) => {
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  const Addcancelpolicy = (i) => {
    setAdd(true);
    setSelectCreate(i);
  };

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}cancel-policies/list?page=${currentPage}`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        return data.data.data;
      } else if (data.reauth === true) {
        handleUnauthorized();
        fetchComments(currentPage);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      fetchComments(currentPage);
    }
  };

  const handlePageClick = async (data) => {
    if (data === 1) {
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
      setPolicy(commentsFormServer);
    } else {
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
      setPolicy(commentsFormServer);
    }
  };

  const numericInputRef = useRef(null);
  // usePreventTouchDrag(numericInputRef);
  // const handleTouchStart = (event) => {
  //   console.log("hii");
  //   console.log(event);
  //   if (event.touches) {
  //     console.log("kkk");
  //     event.stopPropagation()
  //     event.preventDefault();
  //   }
  // };

  // const handleTouchStart = (event) => {
  //   if (event.target.tagName === 'INPUT' && event.target.type === 'number' ) {
  //     event.preventDefault();
  //   }
  // };

  return (
    <div className="page-content">
      <Commonheader />
      <div className="container-fluid">
        <div className="row project-wrapper">
          <div className="offer-list-main">
            <div className="offer-list-top">
              <div className="offer-list-title">
                <h6 className="offer-list-head">
                  Featured Cancellation Policies
                </h6>
              </div>
              <div className="offer-list-btn">
                <a
                  type="button"
                  className="btn db-save-button"
                  href="#"
                  onClick={Bulkupdate}
                >
                  Update
                </a>
              </div>
            </div>
            <div className="row">
              {loading === false ? (
                <Loading />
              ) : (
                <>
                  {policy.length > 0 ? (
                    policy.map((poli, i) => {
                      return (
                        <div
                          className={
                            policy.length > 1 ? "row hotel_single_list" : "row"
                          }
                          key={i}
                        >
                          {policy.length > 1 ? (
                            <div className="hotel_service_display hotel_service_display_12">
                              <div className="hk_service_all_hotel">
                                {poli.hotel_name}
                              </div>
                              {showFullData[i]?.hotel === true ? (
                                <span
                                  className="db-modify_toggle_btn"
                                  onClick={() => handleDropdownClickhotel(i)}
                                >
                                  <span
                                    className="bx bxs-chevron-up"
                                    style={{ fontSize: "20px" }}
                                  ></span>
                                </span>
                              ) : (
                                <span
                                  className="db-modify_toggle_btn"
                                  onClick={() => handleDropdownClickhotel(i)}
                                >
                                  <span
                                    className="bx bxs-chevron-down"
                                    style={{ fontSize: "20px" }}
                                  ></span>
                                </span>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            className={
                              showFullData[i]?.hotel === true
                                ? "col-md-12"
                                : "d-none"
                            }
                          >
                            <div className="col-md-12">
                              <div className="card cancelation-policy-head">
                                <div className="cancelation-policy-title">
                                  <div className="col-md-1 cancelation-policy-field">
                                    PL. NO
                                  </div>
                                  <div className="col-md-1 col-lg-1 cancelation-policy-field">
                                    Min Hours{" "}
                                    <span className="info-tag">
                                      i
                                      <span className="tooltiptext">
                                        Minimum hours before arrival to cancel
                                      </span>
                                    </span>
                                  </div>
                                  <div className="col-md-1 col-lg-1 cancelation-policy-field">
                                    Priority{" "}
                                    <span className="info-tag">
                                      i
                                      <span className="tooltiptext">
                                        Policy cancellation priority
                                      </span>
                                    </span>
                                  </div>
                                  <div className="col-md-1 col-lg-1 cancelation-policy-field">
                                    Code{" "}
                                    <span className="info-tag">
                                      i
                                      <span className="tooltiptext">
                                        Cancellation contract
                                        internationalization code
                                      </span>
                                    </span>
                                  </div>
                                  <div className="col-md-2 col-lg-2 cancelation-policy-field">
                                    Mode Of Payment{" "}
                                    <span className="info-tag">
                                      i
                                      <span className="tooltiptext">
                                        Method of payment
                                      </span>
                                    </span>
                                  </div>
                                  <div className="col-md-1 col-lg-1 cancelation-policy-field">
                                    Status
                                  </div>

                                  <div className="col-md-1 col-lg-3 cancelation-policy-field">
                                    Cancellation Message
                                    <div class="info-tag_1">i</div>
                                    <span class="tooltiptext_1">
                                      Attention: the dates that appear have been
                                      updated according to the cancellation
                                      policy as if the checkin was now.
                                    </span>
                                  </div>
                                  <div className="col-md-2 col-lg-2 cancelation-policy-field">
                                    <button
                                      className="get_cancel"
                                      onClick={(e) => {
                                        setPopup(true);
                                        setCancelpopup(i);
                                      }}
                                    >
                                      Get Cancellation Policy
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {poli.hotel_cancel_policies.length > 0 ? (
                              poli.hotel_cancel_policies.map(
                                (polics, index) => {
                                  return (
                                    <div className="col-md-12 " key={index}>
                                      <div className="card cancelation-policy-list">
                                        <div className="cancelation-policy-details ">
                                          <div className="col-md-1 cancelation-policy-field mt-2">
                                            <span className="cp-text">
                                              {polics.policy_identifier}
                                            </span>
                                          </div>
                                          <div className="col-md-1 cancelation-policy-field mt-2">
                                            <input
                                              type="number"
                                              className="form-control offer-restr-textfield_cancel"
                                              value={polics.minimum_hours}
                                              name="minimum_hours"
                                              onChange={(e) =>
                                                handleChangees(i, index, e)
                                              }
                                              onKeyDown={(e) =>
                                                symbolsArrneg.includes(e.key) &&
                                                e.preventDefault()
                                              }
                                              onFocus={(e) =>
                                                e.target.addEventListener(
                                                  "wheel",
                                                  function (e) {
                                                    e.preventDefault();
                                                  },
                                                  { passive: false }
                                                )
                                              }
                                              ref={numericInputRef}
                                            />

                                            {/* {errors1.map((err, index) => {
                                            return (
                                              <>
                                                {index === i && err && err.minimum_hours ? */}
                                            {errors1[i] &&
                                            errors1[i]?.hotel_cancel_policies &&
                                            errors1[i]?.hotel_cancel_policies[
                                              index
                                            ] &&
                                            errors1[i]?.hotel_cancel_policies[
                                              index
                                            ]?.minimum_hours ? (
                                              <label style={{ color: "red" }}>
                                                {
                                                  errors1[i]
                                                    ?.hotel_cancel_policies[
                                                    index
                                                  ]?.minimum_hours
                                                }
                                              </label>
                                            ) : (
                                              ""
                                            )}
                                            {/* </>
                                            )
                                          })}  */}
                                          </div>
                                          <div className="col-md-1 cancelation-policy-field mt-2">
                                            <input
                                              type="number"
                                              className="form-control offer-restr-textfield_cancel"
                                              value={
                                                polics.cancelation_priority
                                              }
                                              name="cancelation_priority"
                                              onChange={(e) =>
                                                handleChangees(i, index, e)
                                              }
                                              onKeyDown={(e) =>
                                                symbolsArr.includes(e.key) &&
                                                e.preventDefault()
                                              }
                                              onFocus={(e) =>
                                                e.target.addEventListener(
                                                  "wheel",
                                                  function (e) {
                                                    e.preventDefault();
                                                  },
                                                  { passive: false }
                                                )
                                              }
                                            />
                                            {/* {errors1.map((err, index) => {
                                            return (
                                              <> */}
                                            {errors1[i] &&
                                            errors1[i]?.hotel_cancel_policies &&
                                            errors1[i]?.hotel_cancel_policies[
                                              index
                                            ] &&
                                            errors1[i]?.hotel_cancel_policies[
                                              index
                                            ]?.cancelation_priority ? (
                                              <label style={{ color: "red" }}>
                                                {
                                                  errors1[i]
                                                    ?.hotel_cancel_policies[
                                                    index
                                                  ]?.cancelation_priority
                                                }
                                              </label>
                                            ) : (
                                              ""
                                            )}

                                            {/* </>
                                            )
                                          })} */}
                                          </div>
                                          <div className="col-md-1 cancelation-policy-field mt-2">
                                            <select
                                              className="form-select offer-create-select1"
                                              value={
                                                polics.hotel_contract.value ||
                                                ""
                                              }
                                              onChange={(e) =>
                                                handleChangeescontact(
                                                  i,
                                                  index,
                                                  e
                                                )
                                              }
                                              name="value"
                                            >
                                              <option>select</option>
                                              {poli.policy_contract.map(
                                                (contract, indx) => {
                                                  return (
                                                    <option
                                                      key={indx}
                                                      name="value"
                                                      value={
                                                        contract.contract_id
                                                      }
                                                      data-contract-code={
                                                        contract.label
                                                      }
                                                      data-hotel={
                                                        contract.value
                                                      }
                                                    >
                                                      {contract.label}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </select>
                                            {/* {errors1.map((err, index) => {
                                            return (
                                              <> */}
                                            {errors1[i] &&
                                            errors1[i]?.hotel_cancel_policies &&
                                            errors1[i]?.hotel_cancel_policies[
                                              index
                                            ] &&
                                            errors1[i]?.hotel_cancel_policies[
                                              index
                                            ]?.contract_detail ? (
                                              <label style={{ color: "red" }}>
                                                {
                                                  errors1[i]
                                                    ?.hotel_cancel_policies[
                                                    index
                                                  ]?.contract_detail
                                                }
                                              </label>
                                            ) : (
                                              ""
                                            )}

                                            {/*                                                
                                              </>
                                            )
                                          })} */}
                                          </div>
                                          <div className="col-md-2 cancelation-policy-field mt-2">
                                            <span className="feature-info"></span>
                                            <Select
                                              className="form-control offer-restr-textfield_select_re"
                                              id="exampleInputUsername1"
                                              options={paymentmethod}
                                              value={polics.payment_method}
                                              name="payment_method"
                                              onChange={(e) =>
                                                handleChangeesselect(
                                                  i,
                                                  index,
                                                  e
                                                )
                                              }
                                            />

                                            {/* {errors1.map((err, index) => {
                                            return (
                                              <> */}
                                            {errors1[i] &&
                                            errors1[i]?.hotel_cancel_policies &&
                                            errors1[i]?.hotel_cancel_policies[
                                              index
                                            ] &&
                                            errors1[i]?.hotel_cancel_policies[
                                              index
                                            ]?.paymentmethod ? (
                                              <label style={{ color: "red" }}>
                                                {
                                                  errors1[i]
                                                    ?.hotel_cancel_policies[
                                                    index
                                                  ]?.paymentmethod
                                                }
                                              </label>
                                            ) : (
                                              ""
                                            )}

                                            {/* </>
                                            )
                                          })} */}
                                            {polics.payment_method.flag ===
                                            true ? (
                                              <>
                                                <span className="cp-mp">
                                                  <select
                                                    className="form-select offer-create-select1 offer-restr-textfield_cancel_select "
                                                    value={polics.amount_type}
                                                    name="amount_type"
                                                    onChange={(e) =>
                                                      handleChangeesnight(
                                                        i,
                                                        index,
                                                        e
                                                      )
                                                    }
                                                  >
                                                    <option
                                                      value={"percentage"}
                                                    >
                                                      %
                                                    </option>
                                                    <option value={"nights"}>
                                                      Nights
                                                    </option>
                                                  </select>
                                                  {polics.amount_type ===
                                                  "nights" ? (
                                                    <input
                                                      type="number"
                                                      className="form-control offer-restr-textfield_cancel"
                                                      value={polics.amount}
                                                      name="amount"
                                                      onChange={(e) =>
                                                        handleChangees(
                                                          i,
                                                          index,
                                                          e
                                                        )
                                                      }
                                                      min={0}
                                                      onKeyDown={(e) =>
                                                        symbolsArr.includes(
                                                          e.key
                                                        ) && e.preventDefault()
                                                      }
                                                      onFocus={(e) =>
                                                        e.target.addEventListener(
                                                          "wheel",
                                                          function (e) {
                                                            e.preventDefault();
                                                          },
                                                          { passive: false }
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    <input
                                                      type="number"
                                                      className="form-control offer-restr-textfield_cancel"
                                                      value={polics.amount}
                                                      name="amount"
                                                      onChange={(e) =>
                                                        handleChangees(
                                                          i,
                                                          index,
                                                          e
                                                        )
                                                      }
                                                      onKeyDown={(e) =>
                                                        symbolsArrall.includes(
                                                          e.key
                                                        ) && e.preventDefault()
                                                      }
                                                      onFocus={(e) =>
                                                        e.target.addEventListener(
                                                          "wheel",
                                                          function (e) {
                                                            e.preventDefault();
                                                          },
                                                          { passive: false }
                                                        )
                                                      }
                                                    />
                                                  )}
                                                  {/* {errors1.map((err, index) => {
                                                  return (
                                                    <> */}

                                                  {/* </>
                                                  )
                                                })} */}
                                                </span>
                                                {errors1[i] &&
                                                errors1[i]
                                                  ?.hotel_cancel_policies &&
                                                errors1[i]
                                                  ?.hotel_cancel_policies[
                                                  index
                                                ] &&
                                                errors1[i]
                                                  ?.hotel_cancel_policies[index]
                                                  ?.amount ? (
                                                  <label
                                                    style={{ color: "red" }}
                                                  >
                                                    {
                                                      errors1[i]
                                                        ?.hotel_cancel_policies[
                                                        index
                                                      ]?.amount
                                                    }
                                                  </label>
                                                ) : (
                                                  ""
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {polics.default_option.length ===
                                            0 ? (
                                              ""
                                            ) : (
                                              <MultiSelect
                                                options={polics.default_option}
                                                value={polics.payment_option}
                                                onChange={(val) => {
                                                  handlepayoption(
                                                    i,
                                                    index,
                                                    val
                                                  );
                                                }}
                                                labelledBy="Select"
                                                className=" offer-restr-textfield_select_multi mt-2"
                                                selectionType="counter"
                                                allowCustomValue={true}
                                                hasSelectAll={false}
                                                id="exampleInputUsername1"
                                              />
                                            )}
                                            {/* {errors1.map((err, index) => {
                                            return (
                                              <> */}

                                            {errors1[i] &&
                                            errors1[i]?.hotel_cancel_policies &&
                                            errors1[i]?.hotel_cancel_policies[
                                              index
                                            ] &&
                                            errors1[i]?.hotel_cancel_policies[
                                              index
                                            ]?.payment_option ? (
                                              <label style={{ color: "red" }}>
                                                {
                                                  errors1[i]
                                                    ?.hotel_cancel_policies[
                                                    index
                                                  ]?.payment_option
                                                }
                                              </label>
                                            ) : (
                                              ""
                                            )}

                                            {/* </>
                                             )
                                           })} */}
                                          </div>
                                          <div className="col-md-1 cancelation-policy-field mt-2">
                                            <span className="cp-text">
                                              <select
                                                id="ForminputState"
                                                className="form-select offer-create-select1"
                                                value={polics.is_active}
                                                onChange={(e) =>
                                                  handleChangees(i, index, e)
                                                }
                                                name="is_active"
                                              >
                                                <option selected>
                                                  Choose...
                                                </option>
                                                <option
                                                  {...(polics.is_active === true
                                                    ? "selected"
                                                    : "")}
                                                  value={true}
                                                >
                                                  Active
                                                </option>
                                                <option
                                                  {...(polics.is_active ===
                                                  false
                                                    ? "selected"
                                                    : "")}
                                                  value={false}
                                                >
                                                  Inactive
                                                </option>
                                              </select>
                                              {/* {errors1.map((err, index) => {
                                              return (
                                                <> */}
                                              {errors1[i] &&
                                              errors1[i]
                                                ?.hotel_cancel_policies &&
                                              errors1[i]?.hotel_cancel_policies[
                                                index
                                              ] &&
                                              errors1[i]?.hotel_cancel_policies[
                                                index
                                              ]?.is_active ? (
                                                <label style={{ color: "red" }}>
                                                  {
                                                    errors1[i]
                                                      ?.hotel_cancel_policies[
                                                      index
                                                    ]?.is_active
                                                  }
                                                </label>
                                              ) : (
                                                ""
                                              )}

                                              {/* </>
                                              )
                                            })} */}
                                            </span>
                                          </div>

                                          <div className="col-md-4 cancelation-policy-field">
                                            <div
                                              className={
                                                polics.hotel_contract
                                                  .description
                                                  ? "policy-list-content policy_content_type_1"
                                                  : "d-none"
                                              }
                                            >
                                              <p
                                                style={{
                                                  background: `${polics.hotel_contract.color_code}`,
                                                }}
                                              >
                                                {
                                                  polics.hotel_contract
                                                    .cancel_type
                                                }
                                              </p>
                                            </div>

                                            <p className="cp-text policy-list-content_msg">
                                              {
                                                polics.hotel_contract
                                                  .description
                                              }
                                            </p>
                                          </div>
                                          <div className="cp-item-actions">
                                            <Link
                                              className="cp-save-icon"
                                              to="#"
                                              title="Save"
                                              onClick={(e) => Save(i, index)}
                                            >
                                              <img
                                                src="/images/save.png"
                                                style={{ width: "20px" }}
                                              />
                                            </Link>
                                            <Link
                                              className="cp-delete-icon"
                                              to="#"
                                              title="Delete"
                                              onClick={() =>
                                                tog_animationFlip(
                                                  polics.policy_id,
                                                  i
                                                )
                                              }
                                            >
                                              <img
                                                src="images/icon/delete.png"
                                                style={{ width: "20px" }}
                                              />
                                            </Link>
                                          </div>
                                        </div>

                                        <div className="col-md-12">
                                          <div
                                            className={
                                              polics.hotel_contract.description
                                                ? "policy_content_type_1_update mt-2"
                                                : "d-none"
                                            }
                                          >
                                            <p className="cancel_policy_update_field">
                                              Updated At :{" "}
                                              {moment(
                                                polics.hotel_contract.updated_at
                                              ).format("DD-MM-YYYY")}
                                            </p>
                                          </div>
                                        </div>
                                        <div class="">
                                          <span
                                            className={
                                              poli.hotel_cancel_policies
                                                .length !==
                                              poli.policy_contract.length
                                                ? index ===
                                                  poli.hotel_cancel_policies
                                                    .length -
                                                    1
                                                  ? "extrarate-room-link_1"
                                                  : "extrarate-room-link d-none"
                                                : "d-none"
                                            }
                                          >
                                            <Link
                                              className="extra-rate-add"
                                              to="#"
                                              onClick={(e) =>
                                                Addcancelpolicy(i)
                                              }
                                            >
                                              +
                                            </Link>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            ) : (
                              <div className="card-body pt-0">
                                <div className="no-content text-center">
                                  No data Found
                                </div>
                                <div class="">
                                  <span className="extrarate-room-link_1">
                                    <Link
                                      className="extra-rate-add"
                                      to="#"
                                      onClick={(e) => Addcancelpolicy(i)}
                                    >
                                      +
                                    </Link>
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <center className="mt-4">No data found</center>
                    </div>
                  )}
                  <div className="card row">
                    {/* <div className="card-body"> */}
                    {paginate > 1 ? (
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={paginate}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "pagination justify-content-end marg_ins"
                        }
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    ) : (
                      ""
                    )}
                    {/* </div> */}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {modal_animationFlip ? (
        <Modal
          id="flipModal"
          isOpen={modal_animationFlip}
          toggle={() => {
            tog_animationFlip();
          }}
          modalClassName="flip"
          centered
          style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
        >
          <ModalBody className="modal-body p-5 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#405189,secondary:#f06548"
              style={{ width: "90px", height: "90px" }}
            ></lord-icon>

            <div className="mt-4 text-center">
              <h4>
                You are about to delete a Featured Cancellation Policies ?
              </h4>
              <p className="text-muted fs-15 mb-4">
                Deleting your Featured Cancellation Policies will remove all of
                your information from our database.
              </p>
            </div>

            <button
              className="btn btn-link link-success fw-medium text-decoration-none"
              onClick={() => {
                tog_animationFlip();
              }}
            >
              {" "}
              <i className="ri-close-line me-1 align-middle"></i>
              Close{" "}
            </button>
            <Button color="danger" onClick={(e) => DeleteData(pageid)}>
              {" "}
              Yes, Delete It{" "}
            </Button>
          </ModalBody>
        </Modal>
      ) : (
        ""
      )}

      <Modal
        id="flipModal"
        isOpen={popup}
        toggle={() => {
          setPopup(false);
        }}
        modalClassName="flip"
        centered
        style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
      >
        <div className="modal-content-new1">
          <ModalBody className="modal-body text-center">
            <div className="restr_policy_expand">
              <table
                className="table offer-policy-table"
                style={{
                  width: "106%",
                  marginLeft: "-40px",
                  marginBottom: "2px",
                  marginTop: "-10px",
                }}
              >
                <tr>
                  <th style={{ width: "50px" }}>ID</th>
                  <th style={{ width: "50px" }}>Type</th>
                  <th>
                    Description<br></br>
                    <p className="policy_th_span_new">
                      For example date and hour appear as if the arrival were
                      today
                    </p>
                  </th>
                </tr>
                {policy[selectcancelpopup]?.policy_contract?.map((hc, ind) => {
                  return (
                    <tr key={ind}>
                      <td>{hc.contract_code}</td>
                      <td>
                        <div className="policy-list-content policy_content_type_1">
                          <p style={{ background: `${hc.color_code}` }}>
                            {hc.contract_type}
                          </p>{" "}
                        </div>
                      </td>

                      <td>
                        <div className="policy_dec_td">{hc.description}</div>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>

            <button
              className="btn btn-link link-success fw-medium text-decoration-none"
              onClick={() => {
                setPopup(false);
              }}
            >
              {" "}
              <i className="ri-close-line me-1 align-middle"></i>
              Close{" "}
            </button>
          </ModalBody>
        </div>
      </Modal>

      <Modal
        id="flipModal"
        isOpen={add}
        modalClassName="flip"
        centered
        style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
      >
        <div className="modal-content-new1">
          <ModalBody className="modal-body text-center">
            <div className="row">
              <div className="col-md-4">
                <label>Min Hours</label>
                <input
                  type="number"
                  className="form-control popup_input"
                  value={newpolicy.minimum_hours}
                  name="minimum_hours"
                  onChange={handlenew}
                  onKeyDown={(e) =>
                    symbolsArrneg.includes(e.key) && e.preventDefault()
                  }
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                />

                <label style={{ color: "red" }}>{err.minimum_hours}</label>
              </div>
              <div className="col-md-4 ">
                <label>Priority</label>
                <input
                  type="number"
                  className="form-control popup_input"
                  value={newpolicy.cancelation_priority}
                  name="cancelation_priority"
                  onChange={handlenew}
                  onKeyDown={(e) =>
                    symbolsArr.includes(e.key) && e.preventDefault()
                  }
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                />

                <label style={{ color: "red" }}>
                  {err.cancelation_priority}
                </label>
              </div>
              <div className="col-md-4">
                <label>Code</label>
                <select
                  className="form-select "
                  value={newpolicy.contract_detail?.value || ""}
                  onChange={(e) => handleChangeescontactnew(e)}
                  name="value"
                >
                  <option>select</option>
                  {/* {policy[selectcreate].policy_contract.map((contract, indx) => {
                    return (
                      <option key={indx}

                        name="value"
                        value={contract.contract_id} 
                        data-contract-code={contract.label} 
                        data-hotel={contract.value}
                      >{contract.label}</option>
                    )
                  })} */}

                  {policy[selectcreate]?.policy_contract
                    ?.filter(
                      (contract) =>
                        !policy[selectcreate]?.hotel_cancel_policies?.some(
                          (cancelPolicy) =>
                            cancelPolicy.contract_id === contract.contract_id
                        )
                    )
                    .map((contract, indx) => (
                      <option
                        key={indx}
                        name="value"
                        value={contract.contract_id}
                        data-contract-code={contract.label}
                        data-hotel={contract.value}
                      >
                        {contract.label}
                      </option>
                    ))}
                </select>

                <label style={{ color: "red" }}>{err.contract_detail}</label>
              </div>

              <div className="col-md-3">
                <label>Mode of Payment</label>
                <Select
                  className="form-control select_feature"
                  id="exampleInputUsername1"
                  options={paymentmethod}
                  value={newpolicy.payment_method}
                  name="payment_method"
                  onChange={(e) => handleChangeesselectnew(e)}
                />

                <label style={{ color: "red" }}>{err.paymentmethod}</label>
              </div>

              {newpolicy.payment_method.flag === true ? (
                <>
                  <div className="col-md-3 ">
                    <label>Type</label>
                    <select
                      className="form-select mt_2"
                      value={newpolicy.amount_type}
                      name="amount_type"
                      onChange={handlenew}
                    >
                      <option value={"percentage"}>%</option>
                      <option value={"nights"}>Nights</option>
                    </select>

                    <label style={{ color: "red" }}>{err.amount_type}</label>
                  </div>

                  <div className="col-md-3 ">
                    <label>Amount</label>
                    {newpolicy.amount_type === "nights" ? (
                      <input
                        type="number"
                        className="form-control popup_input mt_2"
                        value={newpolicy.amount}
                        name="amount"
                        onChange={handlenew}
                        onKeyDown={(e) =>
                          symbolsArr.includes(e.key) && e.preventDefault()
                        }
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                      />
                    ) : (
                      <input
                        type="number"
                        className="form-control popup_input mt_2"
                        value={newpolicy.amount}
                        name="amount"
                        onChange={handlenew}
                        onKeyDown={(e) =>
                          symbolsArrall.includes(e.key) && e.preventDefault()
                        }
                        min={0}
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                      />
                    )}
                    <label style={{ color: "red" }}>{err.amount}</label>
                  </div>
                </>
              ) : (
                ""
              )}

              {newpolicy.default_option.length === 0 ? (
                ""
              ) : (
                <div className="col-md-3 mt-2">
                  <label></label>
                  <MultiSelect
                    options={newpolicy.default_option}
                    value={newpolicy.payment_option}
                    onChange={(val) => {
                      handlepayoptionnew(val);
                    }}
                    labelledBy="Select"
                    selectionType="counter"
                    allowCustomValue={true}
                    hasSelectAll={false}
                    id="exampleInputUsername1"
                  />
                  <label style={{ color: "red" }}>{err.payment_option}</label>
                </div>
              )}
              <div className="col-md-3">
                <label>Status</label>

                <select
                  id="ForminputState"
                  className="form-select"
                  value={newpolicy.is_active}
                  onChange={handlenew}
                  name="is_active"
                >
                  <option selected>Choose...</option>
                  <option
                    {...(newpolicy.is_active === true ? "selected" : "")}
                    value={true}
                  >
                    Active
                  </option>
                  <option
                    {...(newpolicy.is_active === false ? "selected" : "")}
                    value={false}
                  >
                    Inactive
                  </option>
                </select>
                <label style={{ color: "red" }}>{err.is_active}</label>
              </div>
            </div>
            <button
              className="btn btn-link fw-medium text-decoration-none save_btn"
              onClick={Add}
            >
              {" "}
              Save{" "}
            </button>
            <button
              className="btn btn-link link-danger fw-medium text-decoration-none"
              onClick={closeclear}
            >
              {" "}
              <i className="ri-close-line me-1 align-middle"></i>
              Close{" "}
            </button>
          </ModalBody>
        </div>
      </Modal>

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Cancellationpolicyfeature;
