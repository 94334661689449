import React, { useEffect, useState } from "react";
import Header from "../../../Layouts/Header";
import Directrateloyaltysidebar from "./Sidebars/Directrateloyaltysiderbar";
import LZString from 'lz-string';
import CryptoJS from "crypto-js";

function Directrateloyaltyheader() {
  const [showhotel, setShowhotel] = useState(
    localStorage.getItem("selected-hotel") ? true : false
  );
  const [hotel, setHotel] = useState(localStorage.getItem("selected-hotel"));
  const [dashboard, setDashboard] = useState(true);
  const [pagename, setPagename] = useState("directrateloyalty");
  const [user_type, setUser_type] = useState("");

  const secretKey ="fTh76vpd&nxs#94PzAr49Hy6q*!MbLR7";

  useEffect(() => {
    const encryptedString = localStorage.getItem("user_type");
    if (encryptedString) {
      const bytes = CryptoJS.AES.decrypt(encryptedString, secretKey);
      const decryptedUserType = bytes.toString(CryptoJS.enc.Utf8); 
      setUser_type(decryptedUserType); 
    }
  }, []);

  return (
    <div>
      <Header
        setShowhotel={setShowhotel}
        dashboard={dashboard}
        pagename={pagename}
        user_type={user_type}
        showhotel={showhotel}
      />

      <Directrateloyaltysidebar showhotel={showhotel} />
    </div>
  );
}

export default Directrateloyaltyheader;
