import React from "react";
import { Link } from "react-router-dom";

function Commontab({ tab, setTab }) {
  return (
    <ul
      className="nav nav-pills price-modify-tabs nav-success mb-3"
      role="tablist"
      style={{ marginLeft: "6%" }}
    >
      <li className="nav-item waves-effect waves-light info-tag-li">
        <Link
          className={tab === "available" ? "nav-link active" : "nav-link"}
          data-bs-toggle="tab"
          to="#"
          role="tab"
          onClick={(e) => setTab("available")}
        >
          All Available Codes
        </Link>
      </li>
      <li className="nav-item waves-effect waves-light info-tag-li">
        <Link
          className={tab === "create" ? "nav-link active" : "nav-link"}
          data-bs-toggle="tab"
          to="#"
          role="tab"
          onClick={(e) => setTab("create")}
        >
          Create Code
        </Link>
      </li>
      <li className="nav-item waves-effect waves-light info-tag-li">
        <Link
          className={tab === "email" ? "nav-link active" : "nav-link"}
          data-bs-toggle="tab"
          to="#"
          role="tab"
          onClick={(e) => setTab("email")}
        >
          Loyalty/Poststay Email{" "}
        </Link>
      </li>
      <li className="nav-item waves-effect waves-light info-tag-li">
        <Link
          className={tab === "blackout" ? "nav-link active" : "nav-link"}
          data-bs-toggle="tab"
          to="#"
          role="tab"
          onClick={(e) => setTab("blackout")}
        >
          Blackout Days
        </Link>
      </li>
    </ul>
  );
}

export default Commontab;
