import React, { useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import Select from 'react-select';

const Test12 = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };


  const customOptions = [
    { value: 'option1', label: 'Option 1', style: { marginLeft: '10px' } },
    { value: 'option2', label: 'Option 2', style: { marginLeft: '20px' } },
    { value: 'option3', label: 'Option 3', style: { marginLeft: '10px' } },
    // Add more options with styles as needed
  ];
  const ItemRenderer = ({ checked, option, onClick }) => (
    <div
      onClick={() => onClick(option)}
      style={{ display: 'flex', alignItems: 'center', marginLeft: option.style?.marginLeft }}
    >
      <input type="checkbox" checked={checked} readOnly />
      <span style={{ marginLeft: '5px' }}>{option.label}</span>
    </div>
  );


  return (
    // <MultiSelect
    //     //  options={customOptions}
    //   // options={customOptions.map((option) => ({
    //   //   ...option,
    //   //   label: <div style={{ marginLeft: option.style.marginleft }}>
    //   //     {option.label}
    //   //     </div>,
    //   // }))}
    //   // options={customOptions.map((option) => ({
    //   //   ...option,
    //   //   label: (
    //   //     <div style={{ display: 'flex', alignItems: 'center' }}>
    //   //       <input type="checkbox" style={{ marginLeft: option.style.marginleft }} />
    //   //       <div style={{ marginLeft: option.style.marginleft }}>{option.label}</div>
    //   //     </div>
    //   //   ),
    //   // }))}

    //  options={customOptions.map((option) => ({
    //     ...option,
        
    //     label:  <div style={{ marginLeft: option.style.marginleft }}>
    //         {option.label}
    //        </div>,
    //   }))}
    //   value={selectedOptions}
    //   onChange={handleChange}
    //   // getOptionLabel={(option) => (
    //   //   <div style={{ marginLeft: option.style.marginleft }}>{option.label}</div>
    //   // )}
    //   // getOptionValue={(option) => option.value}
    //   closeMenuOnSelect={false}
    
    //   // labelRenderer={labelRenderer}
    //   // renderOption={renderOption}
    //   // valueRenderer={(selected, _options) => selected.label}
    //   // itemRenderer={(selected, _state) => selected.label}
    
    // />

    <MultiSelect
      options={customOptions}
      value={selectedOptions}
      onChange={handleChange}
      labelledBy="Select"
      ItemRenderer={ItemRenderer}
    />
  );
};

export default Test12;