import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Nodatafound from "../../Common/Nodatafound";
import Toastmodal from "../../Common/Toastmodal";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Config from "../../Config";
import { UserContext } from "../../Contexts/UserContext";
import DeleteModal from "../../DeleteModal";
import Loading from "../../Loading";

function Allavailablecode({ tab, setTab }) {
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);

  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState({
    normal_status: true,
    agency_status: true,
    company_status: true,
    loyalty_status: true,
  });
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [loading, setLoading] = useState(true);
  const [promocodid, setpromocodeid] = useState("");
  const [newtype, setNewtype] = useState("");
  const [paginate, setPaginate] = useState([]);
  const [page, setPage] = useState(1);
  const [pageindex, setPageindex] = useState(1);
  const [showFullData, setShowFullData] = useState([]);
  const [changeddata, setChangeddata] = useState([]);
  const [selecthotel, setSelecthotel] = useState([]);

  const handleDropdownClickhotel = (i) => {
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  const handleloyaltyToggle = (isChecked, name) => {
    setFilter({ ...filter, [name]: isChecked });
  };

  const [delete_animationFlip, setDelete_animationFlip] = useState(false);
  function tog_animationFlipDel(ids) {
    setpromocodeid(ids);
    setDelete_animationFlip(!delete_animationFlip);
  }
  const SingleOptions = [
    { value: "normal", label: "Normal" },
    { value: "company", label: "Company" },
    { value: "travel_agency", label: "Agencies" },
    { value: "loyalty", label: "Loyalty" },
  ];

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}private-sale/promo-code/list?page=${currentPage}`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const data = await res.json();
    if (data.status_code === "200") {
      return data.data.data;
    } else if (data.reauth === true) {
      handleUnauthorized();
      fetchComments(currentPage);
    }
  };

  const handlePageClick = async (data) => {
    if (data === 1) {
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
      setList(commentsFormServer);
    } else {
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
      setList(commentsFormServer);
    }
  };

  const fetchPost = async () => {
    const response = await fetch(
      `${Config.apiurl}private-sale/promo-code/list?page=1`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const data = await response.json();
    if (data.status_code === "200") {
      setList(data.data.data);
      setPaginate(data.data.last_page);
      setLoading(false);
    } else if (response.reauth === true) {
      handleUnauthorized();
      fetchPost();
    } else {
      setLoading(false);
    }
  };

  const Filter = async () => {
    const res = await fetch(`${Config.apiurl}private-sale/promo-code/search`, {
      method: "POST",
      body: JSON.stringify({
        hotels: list.length > 1 ? selecthotel : id,
        normal_status: filter.normal_status === true ? 1 : 0,
        agency_status: filter.agency_status === true ? 1 : 0,
        company_status: filter.company_status === true ? 1 : 0,
        loyalty_status: filter.loyalty_status === true ? 1 : 0,
        show_expiry_status: filter.show_expiry_status === true ? 1 : 0,
        promocode: filter.promocode,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(true);
        setList(data.data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        Filter();
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(true);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      Filter();
    } else {
      console.log("error");
    }
  };


  const Delete = async (ids) => {
    const res = await fetch(
      `${Config.apiurl}hotel/private-sale/promo-code/delete`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
          promocode_id: ids,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const data = await res.json();
    if (res.status === 200) {
      setToastmodal(true);
      setToastmessage(data.message);
      setToasttype("success");
      setToastlarge(true);
      tog_animationFlipDel(false);
      fetchPost();
    } else if (res.reauth === true) {
      handleUnauthorized();
      Delete();
    } else {
      console.log("error");
      setToastmodal(true);
      setToastmessage(data.message);
      setToasttype("error");
      setToastlarge(true);
    }
  };

  const Export = async (e) => {
    try {
      const res = await fetch(
        `${Config.apiurl}hotel/private-sale/promo-code/download/search-result`,
        {
          method: "POST",
          body: JSON.stringify({
            hotel_id: list.length > 1 ? selecthotel[0] : id[0],
            normal_status: filter.normal_status === true ? 1 : 0,
            agency_status: filter.agency_status === true ? 1 : 0,
            company_status: filter.company_status === true ? 1 : 0,
            loyalty_status: filter.loyalty_status === true ? 1 : 0,
            show_expiry_status: filter.show_expiry_status === true ? 1 : 0,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );

      if (res.status !== 200) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const contentDisposition = res.headers.get("content-disposition");
      console.log(contentDisposition);
      const fileNameMatch =
        contentDisposition &&
        contentDisposition.match(/filename=(["'])(?:(?=(\\?))\2.)*?\1/);

      const suggestedFileName = fileNameMatch
        ? fileNameMatch[0].substring(10, fileNameMatch[0].length - 1)
        : "promocodelist.xlsx";

      const blob = await res.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([blob]));
      link.download = suggestedFileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleHotelSelection = (hotelId) => {
    const isSelected = selecthotel.includes(hotelId);
    if (isSelected) {
      setSelecthotel(selecthotel.filter((id) => id !== hotelId));
    } else {
      setSelecthotel([...selecthotel, hotelId]);
    }
  };

  useEffect(() => {
    for (let i = 0; i < list?.length; i++) {
      for (let j = 0; j < list[i]?.promocodes?.length; j++) {
        const initialSelectedOption = SingleOptions.find(
          (option) => option.value === list[i]?.promocodes[j]?.type
        );
        setNewtype((prevState) => {
          return {
            ...prevState,
            [i]: initialSelectedOption.label,
          };
        });
      }
    }
  }, [list?.length]);

  useEffect(() => {
    fetchPost();
  }, [id]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (list.length > 0) {
      const initialState = list.map((item, index) => ({
        hotel: index === 0,
      }));
      setShowFullData(initialState);
    }
  }, [list?.length]);

  console.log(id);

  return (
    <div>
      <div className="row project-wrapper" style={{ marginTop: "2%" }}>
        <div className="container-fluid">
          <div className="row dashboard-top-tab-style">
            <div className="col-xxl-12 dashboard-main-style">
              {loading === true ? (
                <Loading />
              ) : (
                <>
                  <div className="row card">
                    <div className="card-header">
                      <div className="row align-items-center gy-3">
                        <div className="col-xxl-12 col-sm-12">
                          <ul className="modify-open-conditon-list">
                            <span
                              style={{
                                color: "black",
                                float: "left",
                                marginTop: "7px",
                              }}
                            >
                              FILTER
                            </span>
                            <li style={{ marginLeft: "15px" }}>
                              <div
                                className="condition-title"
                                style={{ color: "black" }}
                              >
                                Normal
                              </div>

                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="formCheck1"
                                value={filter.normal_status}
                                checked={
                                  filter.normal_status === true ? true : false
                                }
                                onChange={(e) =>
                                  handleloyaltyToggle(
                                    e.target.checked,
                                    "normal_status"
                                  )
                                }
                                style={{ width: "16px" }}
                              />
                            </li>
                            <li style={{ marginLeft: "15px" }}>
                              <div
                                className="condition-title"
                                style={{ color: "black" }}
                              >
                                Agency
                              </div>

                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="formCheck1"
                                value={filter.agency_status}
                                checked={
                                  filter.agency_status === true ? true : false
                                }
                                onChange={(e) =>
                                  handleloyaltyToggle(
                                    e.target.checked,
                                    "agency_status"
                                  )
                                }
                                style={{ width: "16px" }}
                              />
                            </li>
                            <li style={{ marginLeft: "15px" }}>
                              <div
                                className="condition-title"
                                style={{ color: "black" }}
                              >
                                Company
                              </div>

                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="formCheck1"
                                value={filter.company_status}
                                checked={
                                  filter.company_status === true ? true : false
                                }
                                onChange={(e) =>
                                  handleloyaltyToggle(
                                    e.target.checked,
                                    "company_status"
                                  )
                                }
                                style={{ width: "16px" }}
                              />
                            </li>
                            <li style={{ marginLeft: "15px" }}>
                              <div
                                className="condition-title"
                                style={{ color: "black" }}
                              >
                                Loyalty
                              </div>

                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="formCheck1"
                                value={filter.loyalty_status}
                                checked={
                                  filter.loyalty_status === true ? true : false
                                }
                                onChange={(e) =>
                                  handleloyaltyToggle(
                                    e.target.checked,
                                    "loyalty_status"
                                  )
                                }
                                style={{ width: "16px" }}
                              />
                            </li>
                            <li style={{ marginLeft: "15px" }}>
                              <div
                                className="condition-title"
                                style={{ color: "black" }}
                              >
                                Expired
                              </div>

                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="formCheck1"
                                value={filter.show_expiry_status}
                                checked={
                                  filter.show_expiry_status === true
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  handleloyaltyToggle(
                                    e.target.checked,
                                    "show_expiry_status"
                                  )
                                }
                                style={{ width: "16px" }}
                              />
                            </li>
                            <input
                              type="text"
                              className="new_inp_avail_code"
                              value={filter.promocode}
                              onChange={(e) =>
                                handleloyaltyToggle(e.target.value, "promocode")
                              }
                              style={{
                                textAlign: "center",
                                marginLeft: "30px",
                              }}
                            />
                            <span className="info-tag-p-sale">
                              i
                              <span className="tooltiptext">
                                Enter the promocode
                              </span>
                            </span>

                            <button
                              type="button"
                              className="btn db-common-filter"
                              onClick={Filter}
                              style={{
                                color: "white",
                                backgroundColor: "black",
                                marginLeft: "15px",
                                bottom: "3px",
                                height: "35px",
                              }}
                            >
                              <span className="bx bx-filter-alt"></span>
                            </button>
                            <button
                              type="button"
                              className="btn loyalty_import_btn "
                              style={{
                                height: "35px",
                                bottom: "3px",
                                left: "4%",
                              }}
                              onClick={Export}
                            >
                              Export
                            </button>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {list.length > 0 ? (
                    <>
                      {list.map((li, i) => {
                        return (
                          <>
                            <div
                              className={
                                list.length > 1
                                  ? "row hotel_single_list"
                                  : "row"
                              }
                              key={i}
                            >
                              {list.length > 1 ? (
                                <div className="hotel_service_display hotel_service_display_12">
                                  <div className="hotel_service_display_14">
                                    {list.length > 1 ? (
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`hotelCheckbox${i}`}
                                        checked={selecthotel.includes(
                                          li.hotel_id
                                        )}
                                        onChange={() =>
                                          handleHotelSelection(li.hotel_id)
                                        }
                                        style={{
                                          position: "relative",
                                          top: "7px",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <div className="hk_service_all_hotel">
                                      <span
                                        className=""
                                        style={{ marginLeft: "10px" }}
                                      >
                                        {" "}
                                        {pageindex === 1
                                          ? i + 1
                                          : (pageindex - 1) * 10 + i + 1}
                                        .
                                      </span>
                                      <span style={{ marginLeft: "10px" }}>
                                        {li.hotel_name}
                                      </span>
                                    </div>
                                  </div>
                                  {showFullData[i]?.hotel === true ? (
                                    <span
                                      className="db-modify_toggle_btn"
                                      onClick={() =>
                                        handleDropdownClickhotel(i)
                                      }
                                    >
                                      <span
                                        className="bx bxs-chevron-up"
                                        style={{ fontSize: "20px" }}
                                      ></span>
                                    </span>
                                  ) : (
                                    <span
                                      className="db-modify_toggle_btn"
                                      onClick={() =>
                                        handleDropdownClickhotel(i)
                                      }
                                    >
                                      <span
                                        className="bx bxs-chevron-down"
                                        style={{ fontSize: "20px" }}
                                      ></span>
                                    </span>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              <div
                                className={`row ${
                                  showFullData[i]?.hotel === true
                                    ? ""
                                    : "d-none"
                                }`}
                              >
                                {/* <div
                              className="card"
                              style={{
                                border: "1px solid #d2d2d2",
                                marginLeft: "10px",
                              }}
                            >
                              <div className="card-header">
                                <div className="row align-items-center gy-3">
                                  <div className="col-xxl-12 col-sm-12">
                                    <ul className="modify-open-conditon-list">
                                      <span
                                        style={{
                                          color: "black",
                                          float: "left",
                                          marginTop: "7px",
                                        }}
                                      >
                                        FILTER
                                      </span>
                                      <li style={{ marginLeft: "15px" }}>
                                        <div
                                          className="condition-title"
                                          style={{ color: "black" }}
                                        >
                                          Normal
                                        </div>

                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="formCheck1"
                                          value={filter.normal_status}
                                          checked={
                                            filter.normal_status === true
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleloyaltyToggle(
                                              e.target.checked,
                                              "normal_status"
                                            )
                                          }
                                          style={{ width: "16px" }}
                                        />
                                      </li>
                                      <li style={{ marginLeft: "15px" }}>
                                        <div
                                          className="condition-title"
                                          style={{ color: "black" }}
                                        >
                                          Agency
                                        </div>

                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="formCheck1"
                                          value={filter.agency_status}
                                          checked={
                                            filter.agency_status === true
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleloyaltyToggle(
                                              e.target.checked,
                                              "agency_status"
                                            )
                                          }
                                          style={{ width: "16px" }}
                                        />
                                      </li>
                                      <li style={{ marginLeft: "15px" }}>
                                        <div
                                          className="condition-title"
                                          style={{ color: "black" }}
                                        >
                                          Company
                                        </div>

                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="formCheck1"
                                          value={filter.company_status}
                                          checked={
                                            filter.company_status === true
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleloyaltyToggle(
                                              e.target.checked,
                                              "company_status"
                                            )
                                          }
                                          style={{ width: "16px" }}
                                        />
                                      </li>
                                      <li style={{ marginLeft: "15px" }}>
                                        <div
                                          className="condition-title"
                                          style={{ color: "black" }}
                                        >
                                          Loyalty
                                        </div>

                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="formCheck1"
                                          value={filter.loyalty_status}
                                          checked={
                                            filter.loyalty_status === true
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleloyaltyToggle(
                                              e.target.checked,
                                              "loyalty_status"
                                            )
                                          }
                                          style={{ width: "16px" }}
                                        />
                                      </li>
                                      <li style={{ marginLeft: "15px" }}>
                                        <div
                                          className="condition-title"
                                          style={{ color: "black" }}
                                        >
                                          Expired
                                        </div>

                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="formCheck1"
                                          value={filter.show_expiry_status}
                                          checked={
                                            filter.show_expiry_status === true
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleloyaltyToggle(
                                              e.target.checked,
                                              "show_expiry_status"
                                            )
                                          }
                                          style={{ width: "16px" }}
                                        />
                                      </li>
                                      <input
                                        type="text"
                                        className="new_inp_avail_code"
                                        value={filter.promocode}
                                        onChange={(e) =>
                                          handleloyaltyToggle(
                                            e.target.value,
                                            "promocode"
                                          )
                                        }
                                        style={{
                                          textAlign: "center",
                                          marginLeft: "30px",
                                        }}
                                      />
                                      <span className="info-tag-p-sale">
                                        i
                                        <span className="tooltiptext">
                                          Enter the promocode
                                        </span>
                                      </span>

                                      <button
                                        type="button"
                                        className="btn db-common-filter"
                                        onClick={() => Filter(li.hotel_id)}
                                        style={{
                                          color: "white",
                                          backgroundColor: "black",
                                          marginLeft: "15px",
                                          bottom: "3px",
                                          height: "35px",
                                        }}
                                      >
                                        <span className="bx bx-filter-alt"></span>
                                      </button>
                                      <button
                                        type="button"
                                        className="btn loyalty_import_btn "
                                        style={{
                                          height: "35px",
                                          bottom: "3px",
                                          left: "4%",
                                        }}
                                        onClick={() => Export(li.hotel_id)}
                                      >
                                        Export
                                      </button>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                                {li.promocodes?.length > 0 ? (
                                  <div className="card-body pt-0">
                                    <div>
                                      <ul
                                        className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                                        role="tablist"
                                      ></ul>

                                      <div className="">
                                        <table
                                          className="table table-nowrap align-middle"
                                          id="orderTable"
                                        >
                                          <thead
                                            className=""
                                            style={{
                                              backgroundColor: "#008080",
                                              color: "white",
                                            }}
                                          >
                                            <tr className="text-uppercase">
                                              <th className="" data-sort="id">
                                                Code
                                              </th>
                                              <th
                                                className=""
                                                data-sort="customer_name"
                                              >
                                                Associated Offers
                                              </th>
                                              <th
                                                className=""
                                                data-sort="customer_name"
                                              >
                                                Extra Discount
                                              </th>
                                              <th
                                                className=""
                                                data-sort="customer_name"
                                              >
                                                Type
                                              </th>
                                              <th
                                                className=""
                                                data-sort="customer_name"
                                              >
                                                Usage
                                              </th>
                                              <th
                                                className=""
                                                data-sort="customer_name"
                                              >
                                                Expiration Date
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody className="list form-check-all">
                                            {li.promocodes?.map(
                                              (data, index) => {
                                                let formattedDate;
                                                const dateParts =
                                                  data?.expiry_date?.split("-");
                                                if (dateParts) {
                                                  formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
                                                }
                                                return (
                                                  <tr key={index}>
                                                    <td>
                                                      {data?.promocode}
                                                      {data?.common_name ? (
                                                        <div
                                                          style={{
                                                            fontSize: "12px",
                                                            color: "#008080",
                                                          }}
                                                        >
                                                          {data?.common_name}
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </td>
                                                    <td>
                                                      {data?.offers?.map(
                                                        (offer, ind) => {
                                                          return (
                                                            <span
                                                              key={ind}
                                                              style={{
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                            >
                                                              {offer.title}
                                                              {ind <
                                                                data?.offers
                                                                  .length -
                                                                  1 && ","}
                                                              <br />
                                                            </span>
                                                          );
                                                        }
                                                      )}
                                                    </td>

                                                    <td
                                                      style={{
                                                        paddingLeft: "4%",
                                                      }}
                                                    >
                                                      {data.discount}{" "}
                                                      {data.unit_value ===
                                                      "percentage"
                                                        ? "%"
                                                        : ""}
                                                    </td>
                                                    <td>{newtype[i]}</td>
                                                    {data.code_usage > 0 ? (
                                                      <td>
                                                        {data.code_usage} items
                                                        used
                                                      </td>
                                                    ) : (
                                                      <td>Not used</td>
                                                    )}
                                                    <td
                                                      style={{
                                                        paddingLeft: "2%",
                                                      }}
                                                    >
                                                      {formattedDate}
                                                      <Link
                                                        className="offer-duplicate-icon"
                                                        // to={`/code/view/${data.promocode_id}`}
                                                        to={`/code/view/${data.promocode_id}/${li.hotel_id}`}
                                                        title="Edit"
                                                        style={{
                                                          float: "right",
                                                          right: "20%",
                                                        }}
                                                      >
                                                        <i className="ri-pencil-fill fs-16"></i>
                                                      </Link>
                                                      <Link
                                                        className={
                                                          formattedDate
                                                            ? "offer-delete-icon new_dlt_code"
                                                            : "new_dlt_create"
                                                        }
                                                        to=""
                                                        title="Delete"
                                                        onClick={(e) =>
                                                          tog_animationFlipDel(
                                                            data.promocode_id
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src="images/trash.png"
                                                          style={{
                                                            width: "16px",
                                                          }}
                                                        />
                                                      </Link>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <Nodatafound />
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <Nodatafound />
                  )}
                </>
              )}
              {paginate > 1 ? (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={paginate}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={1}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-end"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}

      {delete_animationFlip ? (
        <DeleteModal
          modal_animationFlip={delete_animationFlip}
          tog_animationFlip={tog_animationFlipDel}
          DeleteData={Delete}
          pageid={promocodid}
          message={"Promocode"}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Allavailablecode;
